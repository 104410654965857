/*
 * @Description: 用户组度量工时报表
 * @Author: XuAnjie
 * @Date: 2023-04-10 10:32:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025/03/20
 */
declare var echarts: any;
import { Button, Card, Col, DatePicker, Form, Row, Select, Space, Radio, Table, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getAmsComplaintsWorkHourReport, exportComplaintReportByGroup } from '@/api/reportCenter';
import { userGroupOptionLine, userGroupOptionBar } from '../chartOption';
import { getCountryList } from '@/api/common';
import { debounce } from '@/utils/utils';
import { useHistory } from 'react-router-dom';
import { blobDownloadFile } from '@/constants/common';
import { minvList } from '@/constants/minvList';

/* 列表字段类型定义 */
interface DataType {
  id: string;
  averageProcessingTime: number; // 客诉平均处理时间
  processor: string; // 处理组名称
  totalComplains: number; // 客诉总量
  totalProcessingTime: string; // 客诉总处理时间
}
const defaultSearchParams = {
  createBeginTime: '', // 客诉创建开始时间
  createEndTime: '', // 客诉创建结束时间
  archiveBeginTime: '', // 归档开始时间
  archiveEndTime: '', // 归档结束时间
  country: [], // 国家
  businessLinesList: [], // 业务线
  minvList: [], // 系统型号
  sysSn: '', // 系统SN
};
let searchParams = { ...defaultSearchParams };
const minvOptions = minvList.map((item) => {
  return {
    value: item,
    label: item,
  };
});

const UserGroupWorkReport: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory(); //列表ref
  const { RangePicker } = DatePicker;
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let collapsed = useSelector((state: RootStateOrAny) => state.get('app').get('collapsed'));
  let country = useSelector((state: RootStateOrAny) => state.get('user').get('country'));
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  let lineChart1Ref = useRef(null);
  let [currentLineChart, setCurrentLineChart] = useState<any>(null);
  const [radioValue, setRadioValue] = useState(1);
  const [dataList, setDataList] = useState<DataType[]>([]);
  const [countryList, setCountryList] = useState<any>([]);
  const [dates, setDates] = useState<Moment[]>([]);
  const [archDates, setArchDates] = useState<Moment[]>([]);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  // 售后用户组工时
  let userGroupInitData = {
    xData: [],
    yData: [],
    seriesData: {
      name: t('reportCenter.totalComplaint'),
      data: [],
    },
    seriesDataHandleTime: {
      name: t('kpiReport.handleTime'),
      data: [],
    },
    seriesDataAverageTime: {
      name: t('reportCenter.averageProcessingTime'),
      data: [],
    },
    title: t('reportCenter.afterSalesGroupKPIStatistics'),
  };
  const [chartData, setChartData] = useState<any>(userGroupInitData);

  const columns: ColumnsType<DataType> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      width: language === 'zh' || language === 'de' || language === 'ja' ? 90 : 180,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index;
      },
    },

    /* 处理组名称	 */
    {
      title: t('reportCenter.department'),
      dataIndex: 'processor',
      key: 'processor',
      ellipsis: true,
      align: 'center',
      width: 240,
    },
    /* 客诉总量	 */
    {
      title: t('reportCenter.totalComplaint'),
      dataIndex: 'totalComplains',
      key: 'totalComplains',
      ellipsis: true,
      align: 'center',
      width: 240,
    },
    /* 客诉总处理时间	 */
    {
      title: t('reportCenter.totalProcessingTime'),
      dataIndex: 'totalProcessingTime',
      key: 'totalProcessingTime',
      ellipsis: true,
      align: 'center',
      width: 240,
    },
    /* 客诉平均处理时间	 */
    {
      title: t('reportCenter.averageProcessingTime'),
      dataIndex: 'averageProcessingTime',
      key: 'averageProcessingTime',
      ellipsis: true,
      align: 'center',
      width: 240,
    },
  ];

  // 监听菜单的缩放
  useEffect(() => {
    if (currentLineChart) {
      setTimeout(() => {
        currentLineChart.resize();
      }, 100);
    }
  }, [collapsed]);

  // 监听浏览器页面的缩放
  useEffect(() => {
    const __resizeHandler = debounce(
      () => {
        if (currentLineChart) {
          currentLineChart.resize();
        }
      },
      10,
      false,
    );
    window.addEventListener('resize', __resizeHandler);
    return () => {
      if (currentLineChart) {
        currentLineChart.dispose();
        setCurrentLineChart(null);
      }
      window.removeEventListener('resize', __resizeHandler);
    };
  }, [currentLineChart]);

  useEffect(() => {
    if (currentLineChart) {
      redrawChart(lineChart1Ref, chartData, radioValue);
    }
  }, [chartData]);

  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      seriesData: {
        ...prevData.seriesData,
        name: t('reportCenter.totalComplaint'),
      },
      seriesDataHandleTime: {
        ...prevData.seriesDataHandleTime,
        name: t('kpiReport.handleTime'),
      },
      seriesDataAverageTime: {
        ...prevData.seriesDataAverageTime,
        name: t('reportCenter.averageProcessingTime'),
      },
      title: t('reportCenter.afterSalesGroupKPIStatistics'),
    }));
  }, [language]);
  /**
   * @description: 重绘报表
   * @param {*} chartRef
   * @param {*} data
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const redrawChart = (chartRef, data, type) => {
    let options: any = null;
    if (type == 1) {
      options = userGroupOptionBar(t, chartData, country);
      currentLineChart.setOption(options);
    } else {
      options = userGroupOptionLine(t, chartData, country);
      currentLineChart.setOption(options);
    }
  };
  // 初始化图表数据
  useEffect(() => {
    getCountrysList();
    getAmsComplaintsWorkHourReports();
    initData(lineChart1Ref, userGroupInitData, radioValue);
    return () => {
      searchParams = {
        createBeginTime: '', // 客诉创建开始时间
        createEndTime: '', // 客诉创建结束时间
        archiveBeginTime: '', // 归档开始时间
        archiveEndTime: '', // 归档结束时间
        country: [], // 国家
        businessLinesList: [], // 业务线
        minvList: [], // 系统型号
        sysSn: '', // 系统SN
      };
    };
  }, []);

  const initData = (chartRef, data, type) => {
    if (currentLineChart) {
      currentLineChart.dispose();
      setCurrentLineChart(null);
    }
    const chartInstance = echarts.init(chartRef.current);
    const options = userGroupOptionBar(t, userGroupInitData, country);
    chartInstance.setOption(options);
    setCurrentLineChart(chartInstance);
  };

  /**
   * @description: 获取用户组度量报表数据
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getAmsComplaintsWorkHourReports = () => {
    getAmsComplaintsWorkHourReport({ ...searchParams }).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        userGroupInitData.xData = rep.data.chartData.processor;
        userGroupInitData.seriesData.data = rep.data.chartData.totalComplains;
        userGroupInitData.seriesDataHandleTime.data = rep.data.chartData.totalProcessingTime;
        userGroupInitData.seriesDataAverageTime.data = rep.data.chartData.averageProcessingTime;
        setChartData({ ...userGroupInitData });
        setDataList(rep.data.dataList);
      } else {
        setChartData(userGroupInitData);
      }
    });
  };
  /**
   * @description: 查询图表
   * @param {*} useCallback
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const search = (values) => {
    const { dateRange, archiveDateRange, tempCountryList, businessLine, minvList, sysSn } = values;
    searchParams.createBeginTime = dateRange?.[0] ? moment(dateRange[0]).format('YYYY-MM-DD') : '';
    searchParams.createEndTime = dateRange?.[1] ? moment(dateRange[1]).format('YYYY-MM-DD') : '';
    searchParams.archiveBeginTime = archiveDateRange?.[0] ? moment(archiveDateRange[0]).format('YYYY-MM-DD') : '';
    searchParams.archiveEndTime = archiveDateRange?.[1] ? moment(archiveDateRange[1]).format('YYYY-MM-DD') : '';
    searchParams.country = tempCountryList || [];
    searchParams.businessLinesList = businessLine;
    searchParams.minvList = minvList;
    searchParams.sysSn = sysSn;
    getAmsComplaintsWorkHourReports();
  };

  /**
   * @description: 切换报表类型
   * @param {*} e
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const onChange = (e) => {
    setRadioValue(e.target.value);
    if (e.target.value == 1) {
      redrawChart(lineChart1Ref, userGroupInitData, 1);
    } else {
      redrawChart(lineChart1Ref, userGroupInitData, 2);
    }
  };

  /* 获取国家列表 */
  const getCountrysList = () => {
    getCountryList()
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          setCountryList([...res.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch(() => {
        setCountryList([]);
      });
  };
  /**
   * @description: 返回报表中心
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const goBackReport = () => {
    history.goBack();
  };

  /**
   * @description: 导出报表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const exportExcel = () => {
    setExportLoading(true);
    exportComplaintReportByGroup({
      countryCode: searchForm.getFieldValue('tempCountryList'),
      createBegin: dates?.[0] ? moment(dates[0]).format('YYYY-MM-DD') : '',
      createEnd: dates?.[1] ? moment(dates[1]).format('YYYY-MM-DD') : '',
      archiveBegin: archDates?.[0] ? moment(archDates[0]).format('YYYY-MM-DD') : '',
      archiveEnd: archDates?.[1] ? moment(archDates[1]).format('YYYY-MM-DD') : '',
      businessLinesList: searchForm.getFieldValue('businessLine'),
      minvList: searchForm.getFieldValue('minvList'),
      sysSn: searchForm.getFieldValue('sysSn'),
    })
      .then((res: any) => {
        blobDownloadFile(res);
        setExportLoading(false);
      })
      .catch((error) => {
        setExportLoading(false);
      });
  };

  /**
   * @description: 限制创建时间选择器时间不超过一年
   * @param {Moment} current
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const disabledDate = (current: Moment) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'years') >= 1;
    const tooEarly = dates[1] && dates[1].diff(current, 'years') >= 1;
    return tooEarly || tooLate;
  };

  /**
   * @description: 限制归档时间选择器时间不超过一年
   * @param {Moment} current
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const disabledArchDate = (current: Moment) => {
    if (!archDates || archDates.length === 0) {
      return false;
    }
    const tooLate = archDates[0] && current.diff(archDates[0], 'years') >= 1;
    const tooEarly = archDates[1] && archDates[1].diff(current, 'years') >= 1;
    return tooEarly || tooLate;
  };

  const handleDateChange = (dates: Moment[]) => {
    setDates(dates);
  };

  const handleArchDateChange = (dates: Moment[]) => {
    setArchDates(dates);
  };

  return (
    <>
      <Card className="user-group-card">
        <Form
          form={searchForm}
          name="searchForm"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12}>
            {/* 业务线 */}
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item name="businessLine">
                <Select
                  placeholder={`${t('archivalMaintenance.productLine')}`}
                  options={[
                    // 户用
                    { value: 10, label: t('archivalMaintenance.householdUse') },
                    // 消费者业务
                    { value: 20, label: t('createComplaint.consumerBusiness') },
                    // 工商业
                    { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                    // 大储
                    { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                  ]}
                  allowClear
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            {/* 系统型号 */}
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item name="minvList">
                <Select
                  options={minvOptions}
                  placeholder={`${t('reportCenter.systemModel')}`}
                  allowClear
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            {/* 系统SN */}
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item name="sysSn">
                <Input placeholder={`${t('reportCenter.systemSn')}`} allowClear />
              </Form.Item>
            </Col>
            {/* 国家 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="tempCountryList">
                <Select
                  placeholder={`${t('createComplaint.country')}`}
                  maxTagCount="responsive"
                  allowClear
                  mode="multiple"
                  options={countryList}
                  fieldNames={{ label: 'areaEnglishName', value: 'areaEnglishName' }}
                ></Select>
              </Form.Item>
            </Col>
            {/* 客诉创建时间 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="dateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('complaintList.complaintCreateBegin'), t('complaintList.complaintCreateEnd')]}
                  allowClear
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                  onChange={(date) => handleDateChange(date as moment.Moment[])}
                />
              </Form.Item>
            </Col>
            {/* 归档时间 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="archiveDateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('reportCenter.archiveBeginPla'), t('reportCenter.archiveEndPla')]}
                  allowClear
                  style={{ width: '100%' }}
                  disabledDate={disabledArchDate}
                  onChange={(date) => handleArchDateChange(date as moment.Moment[])}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* 操作按钮 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item>
                <Space size="middle" wrap>
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {/* 导出 */}
                  <Button type="primary" key="search" shape="round" loading={exportLoading} onClick={exportExcel}>
                    {t('common.export')}
                  </Button>
                </Space>
                {/* 返回 */}
                <Button style={{ float: 'right' }} type="primary" key="search" shape="round" onClick={goBackReport}>
                  {t('common.goback')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card className="user-group-card">
        <div className="flex-style">
          <div>
            <span>{t('reportCenter.chartType')}：</span>
            <Radio.Group onChange={onChange} value={radioValue}>
              <Radio value={1}>{t('reportCenter.chartColumn')}</Radio>
              <Radio value={2}>{t('reportCenter.chartLine')}</Radio>
            </Radio.Group>
          </div>
          <div>{t('reportCenter.reportUpdateTypeRunTime')}</div>
        </div>

        <div ref={lineChart1Ref} className="chart-container"></div>
      </Card>
      <Card className="user-group-card">
        <Table
          className="custom-table-flex auto-table"
          columns={columns}
          // loading={tableLoading}
          dataSource={dataList}
          rowKey={(record) => record.processor}
          scroll={{ x: 1000, y: 300 }}
          pagination={false}
        ></Table>
      </Card>
    </>
  );
};
export default memo(UserGroupWorkReport);
