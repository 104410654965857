// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-active {
  position: relative;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
.register-active .center {
  text-align: center;
}
.register-active .center p {
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
}
.register-active .center p strong {
  color: #e8aa0d;
}
.register-active .ant-btn-primary[disabled],
.register-active .ant-btn-primary[disabled]:hover {
  color: #ffffff;
  border-color: #fde580;
  background: #fde580;
  text-shadow: none;
  box-shadow: none;
}
@media (min-width: 0) and (max-width: 640px) {
  .register-active .logo-box {
    padding: 0px 20px;
    margin-top: 15px;
  }
}
@media (min-width: 640px) {
  .register-active .logo-box {
    padding: 0px 20px;
    margin-top: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/Components/RegisterActive/index.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AALA;EAMI,kBAAA;AAEJ;AARA;EAQM,eAAA;EACA,gBAAA;EACA,0BAAA;AAGN;AAbA;EAYQ,cAAA;AAIR;AAhBA;;EAkBI,cAAA;EACA,qBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;AAEJ;AAEA;EACE;IAEI,iBAAA;IACA,gBAAA;EADJ;AACF;AAKA;EACE;IAEI,iBAAA;IACA,gBAAA;EAJJ;AACF","sourcesContent":[".register-active {\n  position: relative;\n  min-height: 100vh;\n  max-width: 100vw;\n  overflow-x: hidden;\n  .center {\n    text-align: center;\n    p {\n      font-size: 20px;\n      font-weight: 700;\n      color: rgba(0, 0, 0, 0.65);\n      strong {\n        color: #e8aa0d;\n      }\n    }\n  }\n  .ant-btn-primary[disabled],\n  .ant-btn-primary[disabled]:hover {\n    color: #ffffff;\n    border-color: #fde580;\n    background: #fde580;\n    text-shadow: none;\n    box-shadow: none;\n  }\n}\n\n@media (min-width: 0) and (max-width: 640px) {\n  .register-active {\n    .logo-box {\n      padding: 0px 20px;\n      margin-top: 15px;\n    }\n  }\n}\n\n@media (min-width: 640px) {\n  .register-active {\n    .logo-box {\n      padding: 0px 20px;\n      margin-top: 15px;\n    }\n  }\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
