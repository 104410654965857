/*
 * @Description: 产品规则维护
 * @Author: XuAnjie
 * @Date: 2023/09/25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/11/09
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { useHistory, useLocation } from 'react-router-dom';
import productMainService from '@/api/reportMaintenance';
import { inputValueLimit } from '@/constants/inputValueLimit';
/* 列表字段类型定义 */
type GithubIssueItem = {
  id: number;
  authCategory: number /* 数据权限类别 */;
  authType: number /* 权限类型（1：逆变器型号；2：sn编码规则；3：国家区域；） */;
  groupName: string /* 用户组名称 */;
  roleName: string /* 角色 */;
};

/* 列表查询参数 */
let searchParams = {
  pageSize: 10 /* 每页显示条数 */,
  pageIndex: 1 /* 当前页 */,
  productId: null /* 项目名称 */,
  matchRule: null,
};
const ReportMaintenance: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const history = useHistory(); //列表ref
  const location = useLocation();
  /* 分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  /* 列表源数据 */
  const [dataList, setDataList] = useState<GithubIssueItem[]>([]);
  /* table的loading */
  const [tableLoading, setTableLoading] = useState<any>(false);
  /* 新增或编辑 */
  const [addOrEdit, setAddOrEdit] = useState<number>(1);
  /* 新增弹窗 */
  const [isModalOpen, setIsModalOpen] = useState(false);
  /* 编辑弹窗的ref */
  const [projectForm] = Form.useForm();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /** 保存编辑保存loading */
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  /* 列表字段 */
  const columns: ColumnsType<GithubIssueItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      key: 'serialNumber',
      width: 160,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 匹配规则	 */
    {
      title: t('productRule.matchRule'),
      dataIndex: 'matchRule',
      width: 180,
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      key: 'option',
      align: 'center',
      width: 250,
      render: (text: any, record: any, index: any) => {
        return (
          <Space key="option" size="middle" wrap className="sn-management-space">
            {/* 编辑 */}
            <a key="edit" onClick={() => displayAddOrEditDialog(2, record)}>
              {t('common.editBtn')}
            </a>
            {/* 删除 */}
            <a
              className="delete-text"
              key="delete"
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              {t('common.deleteBtn')}
            </a>
          </Space>
        );
      },
    },
  ];

  /**
   * @Author: XuAnjie
   * @description: 分页获取产品
   * @return {*}
   */
  const getProductPage = () => {
    setTableLoading(true);
    productMainService
      .getProductRolePage(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data && rep.data.rows) {
          setDataList([...rep.data.rows]);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
        } else {
          setDataList([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pagingInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pagingInit });
        setTableLoading(false);
      });
  };

  /**
   * @description: 新增或编辑部件
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const addOrEditComponents = () => {
    projectForm.validateFields().then((values) => {
      setSaveLoading(true);
      productMainService.editProductRole({ ...values, productId: location.state?.productId }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          setSaveLoading(false);
          getProductPage();
          handleCancel();
        } else {
          setSaveLoading(false);
        }
      });
    });
  };

  /**
   * @Author: XuAnjie
   * @description: 根据分页，初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    searchParams.productId = location.state.productId;
    getProductPage();
    return () => {
      searchParams = {
        pageSize: 10 /* 每页显示条数 */,
        pageIndex: 1 /* 当前页 */,
        productId: null,
        matchRule: null /* 项目名称 */,
      };
    };
  }, []);

  /**
   * @Author: XuAnjie
   * @description: 分页的回调函数
   * @param {Number} currentPage 当前页
   * @param {Number} currentSize 页的总条数
   * @return {*}
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams = { ...searchParams, ...searchForm.getFieldsValue() };
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getProductPage();
  }, []);

  /**
   * @Author: XuAnjie
   * @description:查询列表
   * @param {Object} form 表单
   * @return {*}
   */
  const search = () => {
    searchParams = { ...searchParams, ...searchForm.getFieldsValue() };
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    getProductPage();
  };

  /**
   * @Author: XuAnjie
   * @description: 删除
   * @param {string} id
   * @return {*}
   */
  const handleDelete = (id) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteData'),
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      onOk() {
        productMainService.delProductRule(id).then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('common.deleteSuccess')); /* 操作成功 */
            getProductPage();
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  /**
   * @description: ：新增或编辑的对话框
   * @param {number} type
   * @param {any} record
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const displayAddOrEditDialog = (type: number, record?: any) => {
    setIsModalOpen(true);
    if (type === 1) {
      setAddOrEdit(1);
      projectForm &&
        projectForm.setFieldsValue({
          project: '',
          projectStatus: '',
          invCode: '',
          attachmentName: '',
        });
    } else {
      setAddOrEdit(2);
      projectForm && projectForm.setFieldsValue(record);
    }
  };
  /**
   * @Author: XuAnjie
   * @description: 编辑弹窗取消
   * @return {*}
   */
  const handleCancel = () => {
    if (projectForm) {
      projectForm.resetFields();
    }
    setIsModalOpen(false);
  };
  /**
   * @description: 返回上一级
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const backTo = () => {
    history.push({
      pathname: '/maintenanceManagement/productRuleManagement',
      state: location.state,
    });
  };
  return (
    <>
      <div className="custom-contant project-management">
        <Form
          form={searchForm}
          name="search_form"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
          layout="vertical"
        >
          <Row gutter={12} align={'bottom'}>
            {/* 产品型号 */}
            <Col xs={24} sm={24} md={243} lg={24} xl={24}>
              <span>
                {t('productRule.productLine')}: {location.state?.matchRule}
              </span>
            </Col>
            {/* 匹配规则 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item label={t('productRule.matchRule')} name="matchRule">
                <Input
                  placeholder={`${t('common.pleaseEnter')}`}
                  allowClear
                  maxLength={inputValueLimit.maxCustomerName}
                />
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={8} md={18} lg={18} xl={18}>
              <Form.Item>
                <Space size="middle">
                  {/* 查询 */}
                  <Button type="primary" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {/* 新增 */}
                  <Button type="primary" shape="round" onClick={() => displayAddOrEditDialog(1)}>
                    {t('common.addBtn')}
                  </Button>
                </Space>
                {/* 返回 */}
                <Button style={{ float: 'right' }} type="primary" key="back" shape="round" onClick={backTo}>
                  {t('common.goback')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          loading={tableLoading}
          rowKey={(record) => record.id}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 600 }}
          className="custom-table-flex"
        />
      </div>
      {/* 新增编辑弹窗 */}
      <Modal
        maskClosable={false}
        title={addOrEdit === 1 ? t('common.addBtn') : t('common.editBtn')}
        open={isModalOpen}
        onCancel={handleCancel}
        width="40%"
        footer={[
          /* 取消 */
          <Button key="back" shape="round" onClick={handleCancel}>
            {t('common.cancelBtn')}
          </Button>,
          /* 保存 */
          <Button loading={saveLoading} key="submit" shape="round" type="primary" onClick={addOrEditComponents}>
            {t('common.saveBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form autoComplete="off" layout="vertical" form={projectForm}>
          {/* 匹配规则 */}
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item name="id" hidden></Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('productRule.matchRule')}
                name="matchRule"
                rules={[
                  {
                    required: true,
                    message: `${t('productRule.matchRule')}${t('archivalMaintenance.parmasRequired')}`,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder={`${t('common.pleaseEnter')}`}
                  maxLength={inputValueLimit.maxCustomerName}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ReportMaintenance);
