/*
 * @Description: 客户反馈->客诉详情页
 * @Author: XuAnjie
 * @LastEditors: Summer.Chen
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2024-03-15 11:37:41
 */

import React, { memo, useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.less';
import { getCustomerComplaintDeatil } from '@/api/clientFeedback/complaintsList';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getUrlQuery, ifClient } from '@/utils/utils';
import ComplaintDetails from './components/ComplaintDetails';
import WorkOrderDetails from './components/WorkOrderDetails';
const CustomerComplaintDetails: React.FC = (props) => {
  const { t } = useTranslation();
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  const [updateLogType, setUpdateLogType] = useState('complaintDetails');
  const [isWorkDetailsId, setIsWorkDetailsId] = useState(false);

  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉工单 */
    if (getUrlQuery('workOrderId') != 'null') {
      setIsWorkDetailsId(true);
    } else {
      setIsWorkDetailsId(false);
    }
  }, []);

  const onChange = (key: string) => {
    setUpdateLogType(key);
  };

  return (
    <>
      <Tabs
        activeKey={updateLogType}
        defaultActiveKey="customer"
        type="card"
        onChange={onChange}
        items={[
          {
            label: t('complaintList.customerCowmplaintDetails'),
            key: 'complaintDetails',
            children: updateLogType === 'complaintDetails' ? <ComplaintDetails /> : null,
          },
          !ifClient(roles) && isWorkDetailsId
            ? {
                label: t('complaintList.workOrderDetails'),
                key: 'workOrderDetails',
                children: updateLogType === 'workOrderDetails' ? <WorkOrderDetails /> : null,
              }
            : (null as any),
        ]}
      />
    </>
  );
};

export default memo(CustomerComplaintDetails);
