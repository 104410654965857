/*
 * @Description: 客户反馈->客诉详情页-> 转CRM
 * @Author: Wayne Hu
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2025/03/21
 */

import React, { memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Row, Col, message, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  saveTransferCrmOperate,
  getTransferCrmForm,
  getComponentList,
  getModuleList,
  getContentList,
  getArchiveInfo,
} from '@/api/clientFeedback/complaintsList';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getUrlQuery } from '@/utils/utils';
import PreViewModal from '../PreViewModal';
import FileUpload from '../FileUpload';
import { InfoCircleOutlined } from '@ant-design/icons';
import { inputValueLimit } from '@/constants/inputValueLimit';
const { Option } = Select;
const { TextArea } = Input;

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
  businessLine?: number;
}

const CenterEvaluationTestCases: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  id,
  extendParams,
  nodeData,
  businessLine,
}) => {
  console.log('originData', originData);
  console.log('nodeData', nodeData);
  const { t } = useTranslation();
  let userInfo = useSelector((state: RootStateOrAny) => state.get('user').get('userInfo'));
  if (typeof userInfo === 'string') {
    userInfo = JSON.parse(userInfo);
  }
  const [btnLoading, setBtnLoading] = useState(false);
  /* 附件列表 */
  const [fileLists, setFileLists] = useState([]);
  /* 预览弹窗的开关 */
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  /* 点击文件 */
  const [fileItem, setFileItem] = useState<any>();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /** 部件列表 */
  const [componentList, setComponentList] = useState([]);
  /** 模块列表 */
  const [moduleList, setModuleList] = useState([]);
  /** 内容列表 */
  const [contentList, setContentList] = useState([]);
  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    let key: string = getUrlQuery('key');
    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
    }
    if (nodeData) {
      getHandleForms(nodeData.data.id);
    }
    // 回显归档信息
    getArchiveInfoAndFillForm(id);
  }, []);

  /**
   * @description: 获取去处理带附件表单
   * @param {*} id
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getHandleForms = (id) => {
    getTransferCrmForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        searchForm.setFieldsValue(res.data);
        setFileLists(res.data.amsFileList);
      }
    });
  };

  /**
   * @Author: Stephen
   * @description: 回显归档信息
   * @param {*} complaintsId
   */
  const getArchiveInfoAndFillForm = (complaintsId) => {
    getArchiveInfo({ complaintsId }).then((res: any) => {
      searchForm.setFieldValue('businessLine', businessLine);
      const { componentId, contentId, moduleId, ...rest } = res.data;
      if (!businessLine) return;
      getComponentList({ businessLine }).then((res: any) => {
        setComponentList(res.data);
        if (!componentId) return;
        if (res.code == 200 && res.data) {
          getModuleList({ id: componentId }).then((res: any) => {
            if (!moduleId) return;
            if (res.code == 200 && res.data) {
              setModuleList(res.data);
              getContentList({ id: moduleId }).then((res: any) => {
                if (res.code == 200 && res.data) {
                  setContentList(res.data);
                  searchForm.setFieldsValue({
                    ...rest,
                    businessLine,
                    component: componentId,
                    content: contentId,
                    module: moduleId,
                  });
                }
              });
            }
          });
        }
      });
    });
  };

  /**
   * @description: 转CRM表单保存
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    searchForm.validateFields().then((values) => {
      setBtnLoading(true);
      let transferParams = [];
      if (values.taskAuthData && originData.nextOperateAuthList.length > 1) {
        transferParams = originData.nextOperateAuthList.filter((item) => {
          return item.id == values.taskAuthData;
        });
      } else {
        transferParams = originData.nextOperateAuthList;
      }
      let amsFileLists: any = [];
      values.amsFileList?.forEach((item) => {
        amsFileLists.push({
          blobContainerName: item.response[0].blobContainerName,
          fileFullPath: item.response[0].fileSaveName,
          fileName: item.response[0].fileName,
          fileSize: item.response[0].size,
        });
      });
      saveTransferCrmOperate({
        ...values,
        complaintsId: id,
        ...originData,
        taskAuthData: transferParams ? transferParams[0] : null,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
        amsFileList: amsFileLists,
        businessLine: values.businessLine,
        componentId: values.component,
        moduleId: values.module,
        contentId: values.content,
      })
        .then((res: any) => {
          if (res.code == 200) {
            message.success(t('complaintList.operateSuccess'));
            cancelHanle();
            setBtnLoading(false);
          } else {
            setBtnLoading(false);
          }
        })
        .catch(() => {
          setBtnLoading(false);
        });
    });
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const cancelHanle = () => {
    searchForm.resetFields();
    onClose();
  };

  /**
   * @description: 回显上传的文件
   * @param {*} newFileList
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const handleFileListChange = (newFileList) => {
    searchForm.setFieldValue('amsFileList', newFileList);
    searchForm.validateFields(['amsFileList']);
  };

  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 下载或预览客诉附件
   * @param {string} fileName
   * @return {*}
   */
  const downloadOrPreviewAttachments = (fileItem: any) => {
    setFileItem(fileItem);
    setViewOpen(true);
  };

  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: April
   */
  const handleCancePreview = () => {
    setViewOpen(false);
  };

  /**
   * @Author: Stephen
   * @description: 选择产品线事件
   * @param {*} val
   * @return {*}
   */
  const handleBusinessChange = (val) => {
    searchForm.setFieldValue('component', null);
    searchForm.setFieldValue('module', null);
    searchForm.setFieldValue('content', null);
    if (val) {
      getComponentList({ businessLine: val }).then((res: any) => {
        if (res.code == 200) {
          setComponentList(res.data);
        }
      });
    } else {
      setComponentList([]);
    }
    setModuleList([]);
    setContentList([]);
  };

  /**
   * @Author: Stephen
   * @description: 选择部件事件
   * @param {*} val
   * @return {*}
   */
  const handleComponentChange = (val) => {
    searchForm.setFieldValue('module', null);
    searchForm.setFieldValue('content', null);
    if (val) {
      getModuleList({ id: val }).then((res: any) => {
        if (res.code == 200) {
          setModuleList(res.data);
        }
      });
    } else {
      setModuleList([]);
    }
    setContentList([]);
  };

  /**
   * @Author: Stephen
   * @description: 选择模块事件
   * @param {*} val
   * @return {*}
   */
  const handleModuleChange = (val) => {
    searchForm.setFieldValue('content', null);
    if (val) {
      getContentList({ id: val }).then((res: any) => {
        if (res.code == 200) {
          setContentList(res.data);
        }
      });
    } else {
      setContentList([]);
    }
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData.data.currentStatusShowName}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHanle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHanle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData} loading={btnLoading}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form
          layout="vertical"
          form={searchForm}
          name="complaintDetail"
          autoComplete="off"
          style={{ width: '100%' }}
          disabled={nodeData ? true : false}
          initialValues={{ processUser: nodeData ? null : userInfo.username }}
        >
          {originData && originData.nextOperateAuthList && originData.nextOperateAuthList.length > 1 ? (
            <Row gutter={8} className="row-border">
              {/* 转交给 */}
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  // className="complaint-detail-label"
                  name="taskAuthData"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                  label={
                    <span className="form-item-label" style={{ whiteSpace: 'pre-wrap' }}>
                      {t('complaintList.transferTo')}
                    </span>
                  }
                >
                  <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                    {originData &&
                      originData.nextOperateAuthList &&
                      originData.nextOperateAuthList.length > 0 &&
                      originData.nextOperateAuthList.map((item: any, index) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          {/* 解决方案 */}
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.solutionLabel')}</span>}
                name="solution"
                rules={[{ required: true, message: `${t('complaintList.solutionRequired')}`, whitespace: true }]}
              >
                <TextArea maxLength={inputValueLimit.maxTextArea512} placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 处理人姓名 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('complaintList.handlername')}
                name="processUser"
                rules={[
                  {
                    required: true,
                    message: `${t('complaintList.handlername')}${t('archivalMaintenance.parmasRequired')}`,
                    whitespace: true,
                  },
                ]}
              >
                <Input maxLength={inputValueLimit.maxSnCodingRules} placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 产品线 */}
            <Col span={24}>
              <Form.Item
                label={t('archivalMaintenance.productLine')}
                name="businessLine"
                rules={[
                  {
                    required: true,
                    message: `${t('archivalMaintenance.productLine')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  options={[
                    { value: 10, label: t('archivalMaintenance.householdUse') },
                    { value: 20, label: t('createComplaint.consumerBusiness') },
                    { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                    { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                  ]}
                  onChange={handleBusinessChange}
                  allowClear
                />
              </Form.Item>
            </Col>
            {/* 部件名称 */}
            <Col span={24}>
              <Form.Item
                label={t('archivalMaintenance.component')}
                name="component"
                rules={[
                  {
                    required: true,
                    message: `${t('archivalMaintenance.component')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  options={componentList}
                  fieldNames={{ label: 'component', value: 'id' }}
                  onChange={handleComponentChange}
                  allowClear
                />
              </Form.Item>
            </Col>
            {/* 模块名称 */}
            <Col span={24}>
              <Form.Item
                label={t('archivalMaintenance.module')}
                name="module"
                rules={[
                  {
                    required: true,
                    message: `${t('archivalMaintenance.module')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  options={moduleList}
                  fieldNames={{ label: 'module', value: 'id' }}
                  onChange={handleModuleChange}
                  allowClear
                />
              </Form.Item>
            </Col>
            {/* 内容 */}
            <Col span={24}>
              <Form.Item
                label={t('archivalMaintenance.content')}
                name="content"
                rules={[
                  {
                    required: true,
                    message: `${t('archivalMaintenance.content')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  options={contentList}
                  fieldNames={{ label: 'content', value: 'id' }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          {/* 附件 */}
          {fileLists && fileLists.length > 0 ? null : (
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
                <Form.Item
                  label={
                    <Tooltip title={t('complaintList.tips')}>
                      <span className="form-item-label">
                        {t('complaintList.attachment')} <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  name="amsFileList"
                  valuePropName="fileList"
                >
                  <FileUpload onFileListChange={handleFileListChange} maxNum={5} />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
        {/* 附件列表 */}
        {fileLists && fileLists.length > 0 ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {fileLists.map((item: any, index) => {
                return (
                  <div>
                    <a onClick={() => downloadOrPreviewAttachments(item)}>{item.fileName}</a>
                  </div>
                );
              })}
            </Col>
          </Row>
        ) : null}
        {viewOpen ? <PreViewModal onClosePreview={handleCancePreview} fileData={fileItem} /> : null}
      </Modal>
    </>
  );
};

export default memo(CenterEvaluationTestCases);
