/*
 * @Description:注册成功后->邮箱激活的页面
 * @Author: YuKi
 * @Date: 2022/11/03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/09/13 15:38:52
 */
import React, { memo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, RootStateOrAny } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { sendRegisterActivation, sendRegisterActivationChengdu } from '@/api/login';
import LanguageSelect from '@/components/LanguageSelect';
import './index.less';
import '../Header/index.less';
import { languageSwitch } from '@/constants/common';
import { useDispatch } from 'react-redux';
import { updateLanguage } from '@/actions/app';
const RegisterActive: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  let languageSelectRef = useRef<any>();
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 城市 */
  const [rescode, setRescode] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');

  /**
   * @Author: YuKi
   * @description: 初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    var searchParams = new URLSearchParams(location.search);
    if (searchParams.get('other')?.trim() === '1') {
      let params = {
        licenseNo: searchParams.get('LicenseNo')?.trim() || undefined,
        sysSn: searchParams.get('SysSn')?.trim() || undefined,
        userId: searchParams.get('UserName')?.trim() || undefined,
        userType: searchParams.get('UserType')?.trim() || undefined,
      };
      if (searchParams.get('Lang')?.trim()) {
        let languageCode = languageSwitch(searchParams.get('Lang')?.trim());
        i18n.changeLanguage(languageCode);
        dispatch(updateLanguage(languageCode));
        localStorage.setItem('language', languageCode);
      }
      sendRegisterActivation(params).then((rep: any) => {
        if (rep && rep.code === 200) {
          if (rep.expMsg) {
            setRescode(4);
            setErrorMessage(rep.expMsg);
          } else {
            setRescode(3);
          }
        }
      });
    } else {
      let params = {
        licenseNo: searchParams.get('LicenseNo')?.trim() || undefined,
        sysSn: searchParams.get('SysSn')?.trim() || undefined,
        userId: searchParams.get('UserName')?.trim() || undefined,
        userType: searchParams.get('UserType')?.trim() || undefined,
      };
      if (searchParams.get('Lang')?.trim()) {
        let languageCode = languageSwitch(searchParams.get('Lang')?.trim());
        i18n.changeLanguage(languageCode);
        dispatch(updateLanguage(languageCode));
        localStorage.setItem('language', languageCode);
      } else {
        languageSelectRef.current && languageSelectRef.current.onLangSelectChange('zh');
      }
      sendRegisterActivationChengdu(params).then((rep: any) => {
        if (rep && rep.code === 200) {
          if (rep.expMsg) {
            setRescode(4);
            setErrorMessage(rep.expMsg);
          } else {
            setRescode(3);
          }
        }
      });
    }
  }, []);

  /**
   * @Author: YuKi
   * @description: 返回按钮
   * @return {*}
   */
  const goBackButton = () => {
    window.location.href = '/login';
  };

  return (
    <>
      <div className="register-active">
        <div className="logo-head">
          <ul className="more-info">
            <li key="AlphaESSOfficialWebsite">
              <a href="https://www.alphaess.com/" target="_blank">
                {t('register.AlphaESSOfficialWebsite')}
              </a>
            </li>
            <li className="separate">|</li>
            <li key="contactUs">
              <a href="https://www.alphaess.com/contact-us" target="_blank">
                {t('register.contactUs')}
              </a>
            </li>
          </ul>
          <div className="switch-language">
            <a className="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                style={{ verticalAlign: 'middle' }}
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 1000 1000"
                // enable-background="new 0 0 1000 1000"
              >
                <metadata>Svg Vector Icons : http://www.onlinewebfonts.com/icon</metadata>
                <g>
                  <path d="M500,10C229.4,10,10,229.4,10,500s219.4,490,490,490s490-219.4,490-490S770.6,10,500,10z M500,867.5C297,867.5,132.5,703,132.5,500S297,132.5,500,132.5c203,0,367.5,164.5,367.5,367.5S703,867.5,500,867.5z M441.1,375.3l1.3-20.2l26-10.1h55.7l16.8-15.1l10.4-29l28.5-15.1l37.7-26.5l0.9-6.6c-38.1-17.9-80.3-28.7-125.4-28.7c-79.9,0-152.3,31.5-204.8,82.3l-2.7,9.8l1.3,17.6l22.1,16.4l7.8,16.4l1.3,22.7l-1.3,26.5l19.5,1.3l20.8,7.6c0,0,16.8,5,22,10.1c5.2,5,33.7,22.7,37.6,29c3.9,6.3,23.3,6.3,23.3,6.3c15.6-12.6,0-30.3,0-30.3l-6.5-15.3l-5.2-15.1l2.6-17.6L441.1,375.3z M730.3,573.3l-16.8-20.2h-24.6l-22-7.6l-16.8-26.5l-27.3-21.4l-11.7-24h-28.5l-40.2-7.5l-11.7,3.8l-16.8,18.9l-14.3,10.1l-6.5,48c0,0,0,51.7,0,56.8c0,5,7.8,15.1,14.3,25.2c6.5,10.1,10.4,21.4,15.6,27.7c5.2,6.3,11.6,6.3,20.8,10.1c9.1,3.8,18.1,12.6,22,18.9c3.9,6.3,6.5,30.3,6.5,30.3l5.2,41.7v18c14.8-4.3,29-9.5,42.6-16l18.3-13.4l31.1-18.9l10.8-6.2c24.4-20.2,45.3-43.9,61.8-70.9l2.7-14.9v-25.4l-3.9-15.1L730.3,573.3z" />
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                style={{ verticalAlign: 'middle', marginLeft: '8px' }}
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 1000 1000"
                // enable-background="new 0 0 1000 1000"
              >
                <metadata>Svg Vector Icons : http://www.onlinewebfonts.com/icon</metadata>
                <g>
                  <path d="M990,253.3L494.8,746.7L10,253.3H990z" />
                </g>
              </svg>
            </a>
            <LanguageSelect ref={languageSelectRef} />
          </div>
        </div>
        {/* 公司logo */}
        <div className="logo-box">
          {language === 'zh' ? (
            <img src="/common/loginImages/new_logo.png" width="110" />
          ) : (
            <img src="/common/loginImages/new_logo_en.png" width="110" />
          )}
        </div>
        <div className="center">
          {rescode !== 3 ? (
            <p>
              <img src="/common/svg/loading.gif" />
            </p>
          ) : null}
          {rescode === 4 ? <p>{errorMessage}</p> : null}
          {rescode === 3 ? (
            <>
              <p>
                <img src="/common/svg/register-success.svg" />
              </p>
              <p>{t('register.activeSuccess')}</p>
            </>
          ) : null}
          <div className="center" style={{ marginTop: '10px' }}>
            <Button
              disabled={rescode !== 3}
              size="large"
              type="primary"
              key="goback"
              shape="round"
              onClick={goBackButton}
            >
              {t('register.goLogin')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(RegisterActive);
