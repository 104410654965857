/*
 * @Description: 客户反馈->历史客诉留言组件
 * @Author: XuAnjie
 * @Date: 2023-10-08 13:54:40
 * @LastEditors: XuAnjie
 * @LastEditTime: 2023-12-15 20:59:31
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Comment, Form, Modal, List } from 'antd';
import { fileDownload, fileDownloadBase } from '@/api/common';
import { useTranslation } from 'react-i18next';
import { getClientComplaintComment, getNoClientComplaintComment } from '@/api/clientFeedback/historicalComplaints';
import { blobDownloadFile } from '@/constants/common';
import './index.less';
import { ifClient } from '@/utils/utils';
import { RootStateOrAny, useSelector } from 'react-redux';
function ClientMessages(props: any, ref) {
  const { leaveMessageParams, closeLeaveMessage } = props;
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  const formRef = useRef(null);
  const { t } = useTranslation();
  /* 留言表单 */
  const [leaveForm] = Form.useForm();
  /* 留言列表数据 */
  const [messageList, setMessageList] = useState<any>([]);
  let downloadList: any = [];
  useEffect(() => {
    if (formRef.current) {
      leaveForm.setFieldValue('commentContent', '');
    }
  }, [leaveForm]);

  useEffect(() => {
    if (leaveMessageParams.showDialog) {
      if (ifClient(roles)) {
        getClientComplaintComments();
      } else {
        getNoClientComplaintComments();
      }
    }
  }, [leaveMessageParams.showDialog]);

  useEffect(() => {
    document.addEventListener('click', myListenFunc);
    return () => {
      document.removeEventListener('click', myListenFunc);
    };
  }, [leaveMessageParams.showDialog]);

  const myListenFunc = (event) => {
    if (event.target.className === 'attachment') {
      let name: any = '';

      downloadList.forEach((item) => {
        if (item.attachmentShowName == event.target.innerText) {
          name = item.attachment;
        } else if (item.attachment2ShowName == event.target.innerText) {
          name = item.attachment2;
        } else if (item.attachment3ShowName == event.target.innerText) {
          name = item.attachment3;
        } else if (item.attachment4ShowName == event.target.innerText) {
          name = item.attachment4;
        } else if (item.attachment5ShowName == event.target.innerText) {
          name = item.attachment5;
        }

        if (item.complaintsReplyComment) {
          if (item.complaintsReplyComment.attachmentShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment;
          } else if (item.complaintsReplyComment.attachment2ShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment2;
          } else if (item.complaintsReplyComment.attachment3ShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment3;
          } else if (item.complaintsReplyComment.attachment4ShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment4;
          } else if (item.complaintsReplyComment.attachment5ShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment5;
          }
        }
      });
      let params = {
        blobContainerName: 'COMPLAINTS',
        fileBlobName: name,
      };
      fileDownloadBase(params).then((res: any) => {
        blobDownloadFile(res, event.target.innerText);
      });
    }
  };

  /**
   * @description: 获取客户留言列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getClientComplaintComments = () => {
    getClientComplaintComment({ key: leaveMessageParams.key })
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          setMessageList([...res.data]);
          downloadList = res.data;
        } else {
          setMessageList([]);
          downloadList = [];
        }
      })
      .catch(() => {
        setMessageList([]);
        downloadList = [];
      });
  };

  /**
   * @description: 获取非客户留言内容
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getNoClientComplaintComments = () => {
    getNoClientComplaintComment({ key: leaveMessageParams.key })
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          setMessageList([...res.data]);
          downloadList = res.data;
        } else {
          setMessageList([]);
          downloadList = [];
        }
      })
      .catch(() => {
        setMessageList([]);
        downloadList = [];
      });
  };

  return (
    <>
      <Modal
        maskClosable={false}
        open={leaveMessageParams.showDialog}
        width="60%"
        title={t('complaintList.leaveMessage')}
        onCancel={closeLeaveMessage}
        forceRender
        footer={null}
      >
        {messageList.length > 0 ? (
          <List
            className="comment-list "
            style={{
              height: '300px',
              border: '1px solid #ddd',
              padding: '2px',
              overflowY: 'scroll',
            }}
            header={null}
            itemLayout="horizontal"
            dataSource={messageList}
            renderItem={(item: any, index) => {
              /* 处理外部附件的显示 */
              function handleAttachment(item: any) {
                let str = '';
                if (item.attachmentShowName) {
                  str += '<pre><a class="attachment">' + item.attachmentShowName + '</a></pre><br/>';
                }
                if (item.attachment2ShowName) {
                  str += '<pre><a class="attachment">' + item.attachment2ShowName + '</a></pre><br/>';
                }
                if (item.attachment3ShowName) {
                  str += '<pre><a class="attachment">' + item.attachment3ShowName + '</a></pre><br/>';
                }
                if (item.attachment4ShowName) {
                  str += '<pre><a class="attachment">' + item.attachment4ShowName + '</a></pre><br/>';
                }
                if (item.attachment5ShowName) {
                  str += '<pre><a class="attachment">' + item.attachment5ShowName + '</a></pre>';
                }
                return str;
              }
              /* 处理内部附件的显示 */
              function handleInnerAttachment(item: any) {
                let str = '';
                if (item.attachmentShowName) {
                  str += '<pre><a class="attachment">' + item.attachmentShowName + '</a></pre><br/>';
                }
                if (item.attachment2ShowName) {
                  str += '<pre><a class="attachment">' + item.attachment2ShowName + '</a></pre><br/>';
                }
                if (item.attachment3ShowName) {
                  str += '<pre><a class="attachment">' + item.attachment3ShowName + '</a></pre><br/>';
                }
                if (item.attachment4ShowName) {
                  str += '<pre><a class="attachment">' + item.attachment4ShowName + '</a></pre><br/>';
                }
                if (item.attachment5ShowName) {
                  str += '<pre><a class="attachment">' + item.attachment5ShowName + '</a></pre>';
                }
                return str;
              }
              return (
                <li className="message-replay">
                  <Comment
                    className="coment"
                    author={<span className="innerCommentText">{item.commentCreator}</span>}
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.commentContent + handleAttachment(item),
                        }}
                      ></div>
                    }
                    datetime={<span className="innerCommentText">{item.commentTime}</span>}
                    avatar={null}
                  >
                    {item.complaintsReplyComment && item.complaintsReplyComment.commentContent ? (
                      <Comment
                        className="coment"
                        datetime={<span className="innerCommentText">{item.complaintsReplyComment.commentTime}</span>}
                        avatar={null}
                        actions={[]}
                        author={
                          <span className="innerCommentText">
                            {item.complaintsReplyComment.commentCreator +
                              (item.complaintsReplyComment.replyFrom
                                ? ' ' + `${t('complaintList.reply')}` + ': ' + item.complaintsReplyComment.replyFrom
                                : '')}
                          </span>
                        }
                        content={
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                item.complaintsReplyComment.commentContent +
                                handleInnerAttachment(item.complaintsReplyComment),
                            }}
                          ></div>
                        }
                      ></Comment>
                    ) : null}
                  </Comment>
                </li>
              );
            }}
          ></List>
        ) : (
          <p style={{ border: '1px solid #bbb', textAlign: 'center', color: '#999' }}>{t('singleSysUpdate.noData')}</p>
        )}
      </Modal>
    </>
  );
}
export default forwardRef(ClientMessages);
