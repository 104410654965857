/*
 * @Description: 客户反馈->历史客诉状态
 * @Author: XuAnjie
 * @Date: 2023-10-08 13:54:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025/01/10
 */
import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Comment, Form, Modal, List, Row, Button, Tooltip, Space, Radio } from 'antd';
import { fileDownload } from '@/api/common';
import { useTranslation } from 'react-i18next';
import { blobDownloadFile } from '@/constants/common';
import './index.less';
import {
  getOnSiteServiceRecords,
  getViewComplaintsList,
  exportOnSiteServiceRecords,
} from '@/api/clientFeedback/historicalComplaints';
function clientStatus(props: any, ref) {
  const { statusParams, closeComplaintStatus } = props;
  const formRef = useRef(null);
  const { t } = useTranslation();
  const [previewDialogVisible, setPreviewDialogVisible] = useState<any>(false);
  const [fileImgWidth, setFileImgWidth] = useState<number>(300);
  const [fileImg, setFileImg] = useState<string>('');
  const [viewImg, setViewImg] = useState<boolean>(false);
  const [ImgDown, setImgDown] = useState<any>({
    rep: {},
    name: '',
  });

  const [previewData, setPreviewData] = useState<any>({});
  /* 详情数据 */
  const [complaintsProcessingList, setComplaintsProcessingList] = useState<any>([]);
  const [totalTime, setTotalTime] = useState<any>('');
  let downloadList: any = [];

  useEffect(() => {
    if (statusParams.showDialog) {
      getViewComplaintsList({ key: statusParams.key })
        .then((res: any) => {
          if (res && res.code === 200 && res.data) {
            setComplaintsProcessingList(res.data.procResult);
            setTotalTime(res.data.totleTimeConsuming);
            // downloadList = res.data
          } else {
            setComplaintsProcessingList([]);
            setTotalTime('');
            // downloadList = []
          }
        })
        .catch(() => {
          setComplaintsProcessingList([]);
          setTotalTime('');
          // downloadList = []
        });
    }
  }, [statusParams.showDialog]);

  useEffect(() => {
    document.addEventListener('click', myListenFunc);
    return () => {
      document.removeEventListener('click', myListenFunc);
    };
  }, [statusParams.showDialog]);

  const myListenFunc = (event) => {
    if (event.target.className === 'attachment') {
      let name: any = '';

      downloadList.forEach((item) => {
        if (item.attachmentShowName == event.target.innerText) {
          name = item.attachment;
        } else if (item.attachment2ShowName == event.target.innerText) {
          name = item.attachment2;
        } else if (item.attachment3ShowName == event.target.innerText) {
          name = item.attachment3;
        } else if (item.attachment4ShowName == event.target.innerText) {
          name = item.attachment4;
        } else if (item.attachment5ShowName == event.target.innerText) {
          name = item.attachment5;
        }

        if (item.complaintsReplyComment) {
          if (item.complaintsReplyComment.attachmentShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment;
          } else if (item.complaintsReplyComment.attachment2ShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment2;
          } else if (item.complaintsReplyComment.attachment3ShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment3;
          } else if (item.complaintsReplyComment.attachment4ShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment4;
          } else if (item.complaintsReplyComment.attachment5ShowName == event.target.innerText) {
            name = item.complaintsReplyComment.attachment5;
          }
        }
      });
      let params = {
        blobContainerName: 'COMPLAINTS',
        fileBlobName: name,
      };
      fileDownload(params).then((res: any) => {
        blobDownloadFile(res, event.target.innerText);
      });
    }
  };
  const transferLanguage = (key) => {
    let str = '';
    switch (key) {
      case 'Open':
        str = t('customercomplaint.newCreate');
        break;
      case 'Accepted':
        str = t('customercomplaint.accepted');
        break;
      case 'Processing':
        str = t('customercomplaint.processing');
        break;
      case 'ToBeVerified':
        str = t('customercomplaint.waitVerification');
        break;
      case 'Verification':
        str = t('customercomplaint.verificationing');
        break;
      case 'VerificationCompleted':
        str = t('customercomplaint.verificationCompleted');
        break;
      case 'ReOpen':
        str = t('customercomplaint.reOpen');
        break;
      case 'Completed':
        str = t('customercomplaint.finishCompted');
        break;
      case 'Evaluated':
        str = t('customercomplaint.comment');
        break;
      case 'Archive':
        str = t('customercomplaint.finish');
        break;
      default:
        str = 'Open';
        break;
    }
    return str;
  };

  // 客诉状态-附件预览
  const downloadFileStatus = (item, fileName) => {
    let params = {
      blobContainerName: 'COMPLAINTS',
      fileBlobName: fileName,
    };
    setViewImg(true);
    fileDownload(params).then((res: any) => {
      let imgType = '.bmp.jpg.jpeg.png.gif'; // 可供预览的文件格式
      let indexNum: any = fileName?.lastIndexOf('.');
      let fileSuffix: string = fileName?.substring(indexNum + 1, fileName.length).toLowerCase() || ''; // 获取文件后缀名
      let fileInfo = {
        rep: res,
        name: fileName,
      };
      setImgDown({ ...fileInfo });
      if (imgType.indexOf(fileSuffix) > -1) {
        let src = window.URL.createObjectURL(res.data);
        let img = new Image();
        img.src = src;
        img.onload = function () {
          setFileImgWidth(500);
          setFileImg(src);
          setViewImg(true);
        };
      } else {
        blobDownloadFile(res, fileName);
      }
    });
  };

  // 下载  或者  点击文件名下载
  const downloadHomeOrPostForm = (complaintId, onSiteServiceId) => {
    let params = {
      key: complaintId,
      onSiteServiceId: onSiteServiceId,
    };
    exportOnSiteServiceRecords(params).then((res: any) => {
      blobDownloadFile(res);
    });
    // exportOnSiteServiceRecords(params).then(rep => {
    //   blobDownloadFile(rep)
    // })
  };

  // 预览下载表单
  const previewForm = (complaintId, fileId) => {
    let params = {
      key: complaintId,
      onSiteServiceId: fileId,
    };
    setPreviewDialogVisible(true);
    getOnSiteServiceRecords(params).then((rep: any) => {
      if (rep.code === 200 && rep.data) {
        setPreviewData(rep.data);
        setPreviewDialogVisible(true);
      }
    });
  };
  /**
   * @description: 下载备注的附件
   * @param {*} item
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const downloadRemarkFile = (item) => {
    let params = {
      blobContainerName: 'COMPLAINTS',
      fileBlobName: item.attachmentName,
    };
    fileDownload(params).then((res: any) => {
      blobDownloadFile(res);
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 关闭图片预览窗口
   * @return {*}
   */
  const closePreview = () => {
    setViewImg(false);
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  /* 下载预览图片 */
  const downLoadFile = () => {
    blobDownloadFile(ImgDown.rep, ImgDown.name);
    setViewImg(false);
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  /* 上门邮寄表单预览关闭 */
  const handleClosePreview = () => {
    setPreviewDialogVisible(false);
  };
  return (
    <>
      <Modal
        maskClosable={false}
        open={statusParams.showDialog}
        width="50%"
        className="customer-complaint-status-dialog"
        title={t('complaintList.complaintStatus')}
        onCancel={closeComplaintStatus}
        forceRender
        footer={null}
      >
        <div className="remark-content">
          {complaintsProcessingList.map((item: any, index) => {
            return (
              <ul key={index}>
                <li>
                  {/* 显示当前的处理人 处理时间 中间经过的时间 */}
                  <span>
                    {item.processor}&nbsp;&nbsp;{item.processingTime}
                    {item.timeConsuming !== null ? '[' + item.timeConsuming + ']' : null}
                  </span>
                  {/* 客诉当前的处理状态 */}
                  <span className="status">{transferLanguage(item.currentStatus)}</span>
                  {/* 原因 */}
                  {item.reason ? <p>{item.reason}</p> : null}
                  {item.solution ? <p>{item.solution}</p> : null}
                  {item.onsiteHandler ? <p>{item.onsiteHandler}</p> : null}
                  {/* 附件下载 */}
                  {item.attachmentName ? (
                    <p
                      className="file-load-status"
                      onClick={() => {
                        downloadFileStatus(item, item.attachmentName);
                      }}
                    >
                      {item.attachmentName}
                    </p>
                  ) : null}
                  {item.attachmentName2 ? (
                    <p
                      className="file-load-status"
                      onClick={() => {
                        downloadFileStatus(item, item.attachmentName2);
                      }}
                    >
                      {item.attachmentName2}
                    </p>
                  ) : null}
                  {item.attachmentName3 ? (
                    <p
                      className="file-load-status"
                      onClick={() => {
                        downloadFileStatus(item, item.attachmentName3);
                      }}
                    >
                      {item.attachmentName3}
                    </p>
                  ) : null}
                  {/* 上门表和邮寄表 */}
                  {item.onSiteList.length > 0 ? (
                    <Row style={{ marginTop: '10px' }}>
                      {item.onSiteList.map((element, index) => {
                        return (
                          <div key={index} style={{ margin: 0, padding: 0, width: '100%' }}>
                            {element.service_mode === 1 ? (
                              <>
                                <Button key="doorForm" className="form" size="small" type="text" disabled>
                                  {t('doorMailingForm.door-to-doorForm')}
                                </Button>
                                <Tooltip placement="topLeft" title={element.homeAttachmentName}>
                                  {element.service_mode === 1 ? (
                                    <Button
                                      key="link1"
                                      className="home-attachment-name"
                                      size="small"
                                      type="link"
                                      onClick={() => {
                                        downloadHomeOrPostForm(item.complaintId, element.onSiteServiceId);
                                      }}
                                    >
                                      {element.homeAttachmentName}
                                    </Button>
                                  ) : null}
                                </Tooltip>
                              </>
                            ) : null}

                            {/* 下载&预览 */}
                            {element.service_mode === 1 ? (
                              <div className="download_preview">
                                <Space key="space1">
                                  <Button
                                    key="download"
                                    size="small"
                                    shape="round"
                                    onClick={() => {
                                      downloadHomeOrPostForm(item.complaintId, element.onSiteServiceId);
                                    }}
                                  >
                                    {t('customercomplaint.download')}
                                  </Button>
                                  <Button
                                    key="preview"
                                    size="small"
                                    shape="round"
                                    onClick={() => {
                                      previewForm(item.complaintId, element.onSiteServiceId);
                                    }}
                                  >
                                    {t('customercomplaint.preview')}
                                  </Button>
                                </Space>
                              </div>
                            ) : null}
                            {element.service_mode === 2 ? (
                              <>
                                <Button key="mailingForm" className="form" size="small" type="text" disabled>
                                  {t('doorMailingForm.mailingForm')}
                                </Button>
                                <Tooltip placement="bottom" title={element.homeAttachmentName}>
                                  {element.service_mode === 2 ? (
                                    <Button
                                      key="link2"
                                      className="home-attachment-name"
                                      size="small"
                                      type="link"
                                      onClick={() => {
                                        downloadHomeOrPostForm(item.complaintId, element.onSiteServiceId);
                                      }}
                                    >
                                      {element.homeAttachmentName}
                                    </Button>
                                  ) : null}
                                </Tooltip>
                              </>
                            ) : null}
                            {/* 下载&预览 */}
                            {element.service_mode === 2 ? (
                              <div className="download_preview">
                                <Space key="space2">
                                  <Button
                                    key="download2"
                                    size="small"
                                    shape="round"
                                    onClick={() => {
                                      downloadHomeOrPostForm(item.complaintId, element.onSiteServiceId);
                                    }}
                                  >
                                    {t('customercomplaint.download')}
                                  </Button>
                                  <Button
                                    key="preview2"
                                    size="small"
                                    shape="round"
                                    onClick={() => {
                                      previewForm(item.complaintId, element.onSiteServiceId);
                                    }}
                                  >
                                    {t('customercomplaint.preview')}
                                  </Button>
                                </Space>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </Row>
                  ) : null}
                  {item.attachList ? (
                    //
                    <ul>
                      {item.attachList.map((innerItem, innerIndex) => {
                        <li key={innerIndex}>
                          <p>{innerItem.additionalinfo_content}</p>
                          <p
                            className="file-load-status"
                            onClick={() => {
                              downloadRemarkFile(innerItem);
                            }}
                          >
                            {innerItem.attachmentName}
                          </p>
                        </li>;
                      })}
                    </ul>
                  ) : null}
                </li>
              </ul>
            );
          })}
          <p>
            {t('customercomplaint.totalTime')}&nbsp;{totalTime}
          </p>
        </div>
      </Modal>
      {/* 客诉附件 图片预览和下载 */}
      <Modal
        title={t('complaintList.preview')}
        open={viewImg}
        onCancel={closePreview}
        width="60%"
        maskClosable={false}
        destroyOnClose
        forceRender
        footer={[
          <Button key="close" shape="round" type="primary" onClick={closePreview}>
            {t('complaintList.close')}
          </Button>,
        ]}
      >
        <div style={{ width: '100%', overflow: 'auto' }}>
          <img src={fileImg} width={fileImgWidth} alt="加载失败" style={{ margin: 'auto', display: 'block' }} />
        </div>
      </Modal>
      {/* 上门邮寄表单预览 */}
      <Modal
        maskClosable={false}
        open={previewDialogVisible}
        width="45%"
        className="preview-dialog"
        title={t('doorMailingForm.after-salesServiceFeedbackForm')}
        onCancel={handleClosePreview}
        forceRender
        footer={null}
      >
        <ul>
          <li>
            <label className="red-color">{t('doorMailingForm.initiator')}</label>
            <Tooltip title={previewData.initiator} placement="topLeft">
              <span className="tip">{previewData.initiator}</span>
            </Tooltip>

            <label className="red-color border-left width-time">{t('doorMailingForm.time')}</label>
            <span className="time">{previewData.time}</span>
            <label className="border-left width-installer">{t('doorMailingForm.installer')}</label>

            {previewData.installer && previewData.installer.length > 15 ? (
              <Tooltip title={previewData.installer} placement="topRight">
                <span className="tip">{previewData.installer}</span>
              </Tooltip>
            ) : (
              <span className="tip">{previewData.installer}</span>
            )}
          </li>

          <li className="product-model">
            <Tooltip title={t('doorMailingForm.productModel')} placement="topRight">
              <label>{t('doorMailingForm.productModel')}</label>
            </Tooltip>
            <span>{previewData.productModel}</span>
          </li>
          <li className="solution">
            <Tooltip title={t('doorMailingForm.solution')} placement="topLeft">
              <label>{t('doorMailingForm.solution')}</label>
            </Tooltip>
            <span>{previewData.solution}</span>
          </li>
          <li>
            <label>{t('doorMailingForm.SN')}</label>
            <span>{previewData.sn}</span>
            <label className="border-left">{t('doorMailingForm.inverterSN')}</label>
            <span>{previewData.inventer_sn}</span>
          </li>
          <li className="desc-li">
            <label className="red-color">{t('doorMailingForm.description')}</label>
            <Tooltip title={previewData.description} placement="topLeft">
              <span>{previewData.description}</span>
            </Tooltip>
          </li>
          <li className="solve-li">
            <label className="red-color">{t('doorMailingForm.action')}</label>
            <Tooltip title={previewData.action} placement="topLeft">
              <span>{previewData.action}</span>
            </Tooltip>
          </li>
          <li>
            <h3>{t('doorMailingForm.systemStatus')}</h3>
          </li>
          <li className="pv">
            <Tooltip title={t('doorMailingForm.PV')} placement="topLeft">
              <label>{t('doorMailingForm.PV')}</label>
            </Tooltip>
            <span>
              {t('doorMailingForm.PVInstalledCapacity')}：{previewData.pvInstalledCapacity}
            </span>
          </li>
          <li className="battery">
            <label>{t('doorMailingForm.battery')}</label>
            <ul>
              <li>
                <Tooltip title={t('doorMailingForm.batteryModel')} placement="topLeft">
                  <span className="type">{t('doorMailingForm.batteryModel')}</span>
                </Tooltip>
                <span className="battery_model">{previewData.batteryModel}</span>
              </li>
              <li>
                <span>{t('doorMailingForm.BMSVersion')}</span>
                <span>{previewData.bmsVersion}</span>
              </li>
              <li>
                <Tooltip title={t('doorMailingForm.number')} placement="topLeft">
                  <span className="quantity">{t('doorMailingForm.number')}</span>
                </Tooltip>
                <span className="previewData-number">{previewData.number}</span>
              </li>
            </ul>
          </li>
          <li className="ems">
            <label>{t('doorMailingForm.EMSVersion')}</label>
            <span>{previewData.emsVersion}</span>
          </li>
          <li className="dsp">
            <Tooltip title={t('doorMailingForm.DSPVersion')} placement="topLeft">
              <label>{t('doorMailingForm.DSPVersion')}</label>
            </Tooltip>
            <span>{previewData.dspVersion}</span>
          </li>
          <li className="remark">
            <label>{t('doorMailingForm.remark')}</label>
            <Tooltip title={previewData.remark} placement="topRight">
              <span>{previewData.remark}</span>
            </Tooltip>
          </li>
        </ul>
      </Modal>
    </>
  );
}
export default forwardRef(clientStatus);
