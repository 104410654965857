/*
 * @Description:导入重点客诉
 * @Author: YuKi
 * @Date: 2025/02/05
 */
import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Radio,
  Input,
  message,
  Checkbox,
  InputNumber,
  Select,
  DatePicker,
  Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { associateImportKeyCusCom, editEmphasis, submitImportKeyCusCom } from '@/api/clientFeedback/complaintsList';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { InfoCircleOutlined } from '@ant-design/icons';
import PreViewModal from '../PreViewModal';
import { fileUploadARMS } from '@/api/common';
import { RcFile } from 'antd/lib/upload';
import { config } from '@/constants/config';

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData?: any;
  id: string;
  extendParams?: any;
  ticketNumber?: string;
  /** ImportEmphasisCC导入重点客诉 Edit编辑 */
  operateFormType: string;
  /** 编辑的内容 */
  EditContent?: any;
}

const ImportKeyCusComModal: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  extendParams,
  id,
  ticketNumber,
  operateFormType,
  EditContent,
}) => {
  const { t } = useTranslation();
  /* 类型参数，默认是1，即新增重点客诉 */
  const [type, setType] = useState(1);
  const [cusComClaFicDim, setCusComClaFicDim] = useState<CheckboxValueType[]>([]);
  /* 预览弹窗的开关 */
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  /* 点击文件 */
  const [fileItem, setFileItem] = useState<any>();
  /* 新增和关联表单的ref */
  const [addForm] = Form.useForm();
  const [relatedForm] = Form.useForm();

  useEffect(() => {
    if (operateFormType === 'Edit') {
      const { expectResolveTime, amount, batchQuantity, publicOpinion, amsFileList, ...other } = EditContent;
      const data = expectResolveTime ? moment(expectResolveTime, 'YYYY-MM-DD') : undefined;
      const cusComClaFicDimTemp: CheckboxValueType[] = [];
      if (amount || amount === 0) {
        cusComClaFicDimTemp.push('amount');
      }
      if (batchQuantity || batchQuantity === 0) {
        cusComClaFicDimTemp.push('batchQuantity');
      }
      if (publicOpinion) {
        cusComClaFicDimTemp.push('publicOpinion');
      }
      setCusComClaFicDim(cusComClaFicDimTemp);
      let amsFileListTemp: any = undefined;
      if (amsFileList && amsFileList.length > 0) {
        amsFileListTemp = [
          {
            uid: amsFileList[0].fileFullPath,
            name: amsFileList[0].fileName,
            status: 'done',
            response: [
              {
                blobContainerName: amsFileList[0].blobContainerName,
                fileFullPath: amsFileList[0].fileSaveName,
                fileName: amsFileList[0].fileName,
                fileSize: amsFileList[0].size,
              },
            ],
          },
        ];
      }
      addForm.setFieldsValue({
        ...other,
        amsFileList: amsFileListTemp,
        expectResolveTime: data,
        amount,
        batchQuantity,
        publicOpinion,
        cusComClaFicDim: cusComClaFicDimTemp,
      });
    }
  }, []);

  /**
   * @Author: YuKi
   * @description: 获取关联客诉数据
   * @return {*}
   */
  const getAssociateImportKeyCusCom = (ticketNumber: string) => {
    associateImportKeyCusCom(ticketNumber)
      .then((res: any) => {
        if (res.code == 200) {
          const { expectResolveTime, amount, batchQuantity, publicOpinion, amsFileList, ...other } = res.data;
          const data = expectResolveTime ? moment(expectResolveTime, 'YYYY-MM-DD') : undefined;
          const cusComClaFicDimTemp: CheckboxValueType[] = [];
          if (amount || amount === 0) {
            cusComClaFicDimTemp.push('amount');
          }
          if (batchQuantity || batchQuantity === 0) {
            cusComClaFicDimTemp.push('batchQuantity');
          }
          if (publicOpinion) {
            cusComClaFicDimTemp.push('publicOpinion');
          }
          setCusComClaFicDim(cusComClaFicDimTemp);
          let amsFileListTemp: any = undefined;
          if (amsFileList && amsFileList.length > 0) {
            amsFileListTemp = [
              {
                uid: amsFileList[0].fileFullPath,
                name: amsFileList[0].fileName,
                status: 'done',
                response: [
                  {
                    blobContainerName: amsFileList[0].blobContainerName,
                    fileFullPath: amsFileList[0].fileSaveName,
                    fileName: amsFileList[0].fileName,
                    fileSize: amsFileList[0].size,
                  },
                ],
              },
            ];
          }
          addForm.setFieldsValue({
            ...other,
            amsFileList: amsFileListTemp,
            expectResolveTime: data,
            amount,
            batchQuantity,
            publicOpinion,
            cusComClaFicDim: cusComClaFicDimTemp,
          });
        } else {
          addForm.resetFields();
          setCusComClaFicDim([]);
        }
      })
      .catch(() => {
        addForm.resetFields();
        setCusComClaFicDim([]);
      });
  };

  /**
   * @description: 1、是新增重点客诉 2、是关联重点客诉
   * @return {*}
   * @LastEditors: YuKi
   */
  const saveData = () => {
    if (operateFormType === 'ImportEmphasisCC') {
      if (type === 1) {
        addForm.validateFields().then((values) => {
          if (!cusComClaFicDim.length) {
            return message.error(t('complaintList.customer_complaint_classification_dimension_required'));
          }
          const { expectResolveTime, amsFileList, ...other } = values;
          const date = expectResolveTime ? expectResolveTime.format('YYYY-MM-DD') : undefined;
          let amsFileLists: any = [];
          amsFileList &&
            amsFileList?.forEach((item) => {
              amsFileLists.push({
                blobContainerName: item.response[0].blobContainerName,
                fileFullPath: item.response[0].fileSaveName,
                fileName: item.response[0].fileName,
                fileSize: item.response[0].size,
              });
            });
          submitImportKeyCusCom({
            amsFileList: amsFileLists,
            ticketNumber,
            type,
            expectResolveTime: date,
            ...other,
            complaintsId: id,
            ...originData,
            processInstanceId: extendParams.processInstanceId,
            taskInstanceId: extendParams.taskInstanceId,
          }).then((res: any) => {
            if (res.code == 200) {
              message.success(t('complaintList.operateSuccess'));
              cancelHandle();
            }
          });
        });
      } else if (type === 2) {
        relatedForm.validateFields().then((values) => {
          const obj = {
            ...values,
            type,
            complaintsId: id,
            ...originData,
            processInstanceId: extendParams.processInstanceId,
            taskInstanceId: extendParams.taskInstanceId,
          };
          if (addForm.getFieldsValue()) {
            const { expectResolveTime, amsFileList, ...other } = addForm.getFieldsValue();
            const date = expectResolveTime ? expectResolveTime.format('YYYY-MM-DD') : undefined;
            let amsFileLists: any = [];
            amsFileList &&
              amsFileList?.forEach((item) => {
                amsFileLists.push({
                  blobContainerName: item.response[0].blobContainerName,
                  fileFullPath: item.response[0].fileSaveName,
                  fileName: item.response[0].fileName,
                  fileSize: item.response[0].size,
                });
              });
            Object.assign(obj, {
              expectResolveTime: date,
              amsFileList: amsFileLists,
              ...other,
            });
          }
          submitImportKeyCusCom({ ...obj }).then((res: any) => {
            if (res.code == 200) {
              message.success(t('complaintList.operateSuccess'));
              cancelHandle();
            }
          });
        });
      }
    } else if (operateFormType === 'Edit') {
      addForm.validateFields().then((values) => {
        if (!cusComClaFicDim.length) {
          return message.error(t('complaintList.customer_complaint_classification_dimension_required'));
        }
        const { expectResolveTime, amsFileList, ...other } = values;
        const date = expectResolveTime ? expectResolveTime.format('YYYY-MM-DD') : undefined;
        let amsFileLists: any = [];
        amsFileList &&
          amsFileList?.forEach((item) => {
            amsFileLists.push({
              blobContainerName: item.response[0].blobContainerName,
              fileFullPath: item.response[0].fileSaveName,
              fileName: item.response[0].fileName,
              fileSize: item.response[0].size,
            });
          });
        editEmphasis({
          amsFileList: amsFileLists,
          expectResolveTime: date,
          ...other,
          id,
        }).then((res: any) => {
          if (res.code == 200) {
            message.success(t('complaintList.operateSuccess'));
            cancelHandle();
          }
        });
      });
    }
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: YuKi
   */
  const cancelHandle = () => {
    addForm.resetFields();
    relatedForm.resetFields();
    onClose();
  };
  /**
   * @Author: YuKi
   * @description:设置如何upload组件将 event 的值转换成字段值
   * @param {any} e
   * @return {*}
   */
  const normFile = (e: any) => {
    if (e.file.error) {
      return [];
    } else {
      return e.fileList;
    }
  };

  /**
   * @Author: YuKi
   * @description: 新增/编辑弹窗->上传文件前的勾子
   * @param {any} option
   * @return {*}
   */
  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / Math.pow(1024, 2) < 80;
    if (!isLt2M) {
      message.error(t('prodSuggestion.addProdSuggestion.fileSizeLimit'));
      return Upload.LIST_IGNORE;
    }
    return isLt2M;
  };

  /**
   * @Author: YuKi
   * @description: 自定义自己图片的上传实现
   * @param {any} options
   * @return {*}
   */
  const handleUpload = (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append('blobContainerName', 'FILES');
    formData.append('fileList', options.file);
    let fileList: any = [{}];
    fileUploadARMS(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          fileList[0].status = 'done';
          fileList[0].url = config.downUrl + `${res.data[0].blobContainerName}/${res.data[0].fileSaveName}`;
          fileList[0].fileName = res.data[0].fileSaveName;
          fileList[0].fileSaveName = res.data[0].fileSaveName;
          fileList[0].blobContainerName = res.data[0].blobContainerName;
          fileList[0].size = res.data[0].fileSize;
          onSuccess(fileList);
        } else {
          onError('上传失败');
        }
      })
      .catch((error) => {
        onError('上传失败');
      });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载或预览客诉附件
   * @param {string} fileName
   * @return {*}
   */
  const downloadOrPreviewAttachments = (file: any) => {
    setFileItem(file.response[0]);
    setViewOpen(true);
  };
  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: April
   */
  const handleCancePreview = () => {
    setViewOpen(false);
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || t('common.editBtn')}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHandle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHandle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            <Button key="save" shape="round" type="primary" onClick={saveData}>
              {t('remoteControlMenu.saveBtn')}
            </Button>
          </Row>,
        ]}
      >
        {/* 类型（1-新建；2-关联） */}
        {operateFormType === 'ImportEmphasisCC' && (
          <div style={{ marginBottom: '16px' }}>
            <span>{t('common.type') + '：'}</span>
            <Radio.Group
              onChange={(e) => {
                setType(e.target.value);
                setCusComClaFicDim([]);
                if (type === 1) {
                  addForm.resetFields();
                } else if (type === 2) {
                  addForm.resetFields();
                  relatedForm.resetFields();
                }
              }}
              value={type}
            >
              <Radio value={1}>{t('complaintList.new_key_customer_complaints')}</Radio>
              <Radio value={2}>{t('complaintList.related_key_customer_complaints')}</Radio>
            </Radio.Group>
          </div>
        )}
        <Form layout="vertical" form={relatedForm} autoComplete="off">
          {/* 重点客诉编号 */}
          {type === 2 && operateFormType === 'ImportEmphasisCC' && (
            <Form.Item
              name="ticketNumber"
              label={t('complaintList.key_customer_complaint_number')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
                {
                  max: 128,
                  transform: (val) => val.trim(),
                },
              ]}
            >
              <Input
                onBlur={(e) => {
                  if (e.target.value && e.target.value.trim()) {
                    getAssociateImportKeyCusCom(e.target.value);
                  }
                }}
              />
            </Form.Item>
          )}
        </Form>
        <Form
          layout="vertical"
          form={addForm}
          autoComplete="off"
          initialValues={{ expertGroupType: 'EMS软件', grade: 'S' }}
        >
          {/* 重点客诉标题 */}
          <Form.Item
            name="title"
            label={t('complaintList.key_customer_complaint_titles')}
            rules={[
              {
                required: true,
                whitespace: true,
              },
              {
                max: 128,
                transform: (val) => val.trim(),
              },
            ]}
          >
            <Input disabled={type === 2} />
          </Form.Item>
          {/* 背景描述 */}
          <Form.Item
            label={t('complaintList.background_description')}
            name="background"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <TextArea disabled={type === 2} autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={1024} />
          </Form.Item>
          {/* 问题详情 */}
          <Form.Item
            label={t('complaintList.question_details')}
            name="question"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <TextArea disabled={type === 2} autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={1024} />
          </Form.Item>
          {/* 客诉分类维度 */}
          <Form.Item
            required
            name="cusComClaFicDim"
            label={t('complaintList.customer_complaint_classification_dimension')}
          >
            <Checkbox.Group disabled={type === 2} onChange={(val) => setCusComClaFicDim(val)} style={{ width: '100%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {/* 涉及金额 */}
                <div style={{ display: 'flex', width: '100%' }}>
                  <Checkbox value="amount" style={{ flex: 'none', height: '60px' }}>
                    {t('complaintList.amount_involved_yuan')}
                  </Checkbox>
                  {cusComClaFicDim.includes('amount') && (
                    <Form.Item
                      name="amount"
                      rules={[
                        {
                          required: true,
                          type: 'number',
                          message: `${t('complaintList.amount_involved_yuan_required')}`,
                        },
                      ]}
                    >
                      <InputNumber
                        disabled={type === 2}
                        style={{ minWidth: '150px' }}
                        controls={false}
                        precision={2}
                        max={10000000}
                        min={0}
                      />
                    </Form.Item>
                  )}
                </div>
                {/* 批次数量 */}
                <div style={{ display: 'flex', width: '100%' }}>
                  <Checkbox value="batchQuantity" style={{ flex: 'none', height: '60px' }}>
                    {t('complaintList.batch_quantity_piece')}
                  </Checkbox>
                  {cusComClaFicDim.includes('batchQuantity') && (
                    <Form.Item
                      name="batchQuantity"
                      rules={[
                        {
                          required: true,
                          type: 'number',
                          message: `${t('complaintList.batch_quantity_piece_required')}`,
                        },
                      ]}
                    >
                      <InputNumber disabled={type === 2} style={{ minWidth: '150px' }} controls={false} precision={0} />
                    </Form.Item>
                  )}
                </div>
              </div>
              {/* 舆论影响/其他 */}
              <div style={{ display: 'flex', width: '100%' }}>
                <Checkbox value="publicOpinion" style={{ flex: 'none', height: '60px' }}>
                  {t('complaintList.public_opinion_influence_other')}
                </Checkbox>
                {cusComClaFicDim.includes('publicOpinion') && (
                  <Form.Item
                    style={{ flex: '1' }}
                    name="publicOpinion"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `${t('complaintList.public_opinion_influence_other_required')}`,
                      },
                      {
                        max: 1024,
                        transform: (val) => val.trim(),
                        message: `${t('complaintList.public_opinion_influence_other_length')}`,
                      },
                    ]}
                  >
                    <Input disabled={type === 2} />
                  </Form.Item>
                )}
              </div>
            </Checkbox.Group>
          </Form.Item>
          {/* 影响区域范围 */}
          <Form.Item
            name="impactArea"
            label={t('complaintList.influence_area_range')}
            rules={[
              {
                required: true,
                whitespace: true,
              },
              {
                max: 1024,
                transform: (val) => val.trim(),
              },
            ]}
          >
            <Input disabled={type === 2} />
          </Form.Item>
          <Row gutter={12} align="bottom">
            {/* 涉及产品型号(多型号以;分割) */}
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="minv"
                label={t('complaintList.involved_product_model_multiple_models_separated_by')}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                  {
                    max: 1024,
                    transform: (val) => val.trim(),
                  },
                ]}
              >
                <Input disabled={type === 2} />
              </Form.Item>
            </Col>
            {/* 涉及产品SN(多个SN以;分割) */}
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="sysSn"
                label={t('complaintList.involved_product_sn_multiple_sn_separated_by')}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                  {
                    max: 1024,
                    transform: (val) => val.trim(),
                  },
                ]}
              >
                <Input disabled={type === 2} />
              </Form.Item>
            </Col>
            {/* 涉及专家组 */}
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="expertGroupType"
                label={t('complaintList.involved_expert_group')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  disabled={type === 2}
                  style={{ width: '100% ' }}
                  options={[
                    { value: 'EMS软件', label: 'EMS软件' },
                    { value: 'BMS软件', label: 'BMS软件' },
                    { value: '硬件', label: '硬件' },
                    { value: '结构', label: '结构' },
                    { value: '工厂质量', label: '工厂质量' },
                    { value: '户用产品线', label: '户用产品线' },
                    { value: '工商业产品线', label: '工商业产品线' },
                    { value: '大储产品线', label: '大储产品线' },
                    { value: '中试', label: '中试' },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 客诉类型 */}
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="grade"
                label={t('complaintList.complaint_type')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  disabled={type === 2}
                  style={{ width: '100% ' }}
                  options={[
                    { value: 'S', label: t('complaintList.grade_S') },
                    { value: 'A', label: t('complaintList.grade_A') },
                    { value: 'B', label: t('complaintList.grade_B') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 期望解决时间 */}
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={t('complaintList.expected_solution_time')}
                name="expectResolveTime"
                rules={[{ required: true }]}
              >
                <DatePicker disabled={type === 2} style={{ width: '100%' }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t('complaintList.attachment')}
            name="amsFileList"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="eightFile1"
              className="avatar-uploader"
              maxCount={1}
              customRequest={(option) => handleUpload(option)}
              beforeUpload={beforeUpload}
              showUploadList={{
                showRemoveIcon: type !== 2,
                showDownloadIcon: true,
              }}
              onDownload={(file: any) => downloadOrPreviewAttachments(file)}
            >
              <Button disabled={type === 2}> {t('complaintList.chooseFile')}</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      {viewOpen ? <PreViewModal onClosePreview={handleCancePreview} fileData={fileItem} /> : null}
    </>
  );
};

export default memo(ImportKeyCusComModal);
