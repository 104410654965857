/*
 * @Description:
 * @Author: Victor
 * @Date: 2023/02/07
 * @LastEditors: XuAnjie
 * @LastEditTime: 2023-11-17 17:16:35
 */
import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import HistoricalComplaintsClient from './HistoricalComplaintsClient';
import HistoricalComplaintsNotClient from './HistoricalComplaintsNotClient';
import { useHistory, useLocation } from 'react-router-dom';
import { ifClient } from '@/utils/utils';

const updateLogManagement: React.FC = () => {
  const { t } = useTranslation();
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {}, []);

  useEffect(() => {}, []);
  return <>{ifClient(roles) ? <HistoricalComplaintsClient /> : <HistoricalComplaintsNotClient />}</>;
};
export default memo(updateLogManagement);
