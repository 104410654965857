import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RouterType } from '@/router/router.type';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { setToken, hasToken, getToken } from '@/utils/localStoreUtil';
import { useTranslation } from 'react-i18next';
import { updateUserName, updateUserInfo, updateRoles, updateCountry } from '@/actions/user';
import { updateToken, updateCountryList } from '@/actions/app';
import { getUrlSearchParams } from '@/utils/utils';
import { config } from '@/constants/config.js';
import { languageSwitch } from '@/constants/common';
import { updateLanguage } from '@/actions/app';
import { getCountryList } from '@/api/common';
/* 路由白名单 */
let whiteNameList: any = [
  '/login', // 登录
  '/register', // 注册
  '/newRegisterSecond', // 注册第二步
  '/shareRegister', // 分享用户注册
  '/forgetPassword', // 忘记密码
  '/resetPassword', // 重置密码
  '/forgetUserName', // 忘记用户名
  '/RegisterActive', // 注册成功激活
  '/RegisterStep', // 注册成功激活
  '/forgetUserNameSecond', // 忘记用户名
  '/UpdateRemoteUpgrade', // 开启远程升级
  '/vppRegister', // Vpp用户注册
  '/vppAssociatedSystem', // Vpp用户关联系统
];

/* 解析当前路由表，转化为单一层次的数组 */
function reBuildPermission(routes: any, tempArr: any) {
  for (let i = 0; i < routes.length; i++) {
    if (!routes[i].children) {
      if (routes[i].path) {
        tempArr.push({ ...routes[i] });
      }
    } else {
      let tmp: any = { ...routes[i] };
      delete tmp.children;
      if (tmp.path) {
        tempArr.push({ ...tmp });
        reBuildPermission(routes[i].children, tempArr);
      }
    }
  }
  return tempArr;
}

const renderRoutes = (routes: RouterType[], extraProps = {}, switchProps = {}) => {
  const countryData = () => {
    getCountryList().then((rep: any) => {
      if (rep && rep.code == 200 && rep.data) {
        dispatch(updateCountryList([...rep.data]));
      }
    });
  };
  let permissionList: any = reBuildPermission(routes, []);
  let countryList = useSelector((state: RootStateOrAny) => state.get('app').get('countryList'));
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  // 获取充电桩类型数据
  if (countryList.length === 0 && getToken()) {
    countryData();
  }
  const setUserInfo = (currentToken: string, userInfo: any) => {
    if (!currentToken || !userInfo) {
      return;
    }
    if (currentToken === 'null' || userInfo === 'null') {
      return;
    }
    let userInfoData: any = JSON.parse(userInfo);
    setToken(currentToken);
    dispatch(updateToken(currentToken));
    dispatch(updateUserName(userInfoData.username));
    dispatch(updateCountry(userInfoData.country));
    dispatch(updateUserInfo(userInfoData));
    dispatch(updateRoles(userInfoData.roles));
    let languageCode: any = '';
    languageCode = languageSwitch(userInfoData.languageCode);
    i18n.changeLanguage(languageCode);
    dispatch(updateLanguage(languageCode));
    localStorage.setItem('language', languageCode);
  };
  const AlphaCloudSSO = useSelector((state: RootStateOrAny) => state.get('app').get('AlphaCloudSSO'));
  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route) =>
        route.path ? (
          <Route
            key={route.key}
            path={route.path}
            exact={false}
            strict={false}
            render={(props) => {
              let pathname = props.location.pathname;
              const AlphaCloudToken = localStorage.getItem('AlphaCloudToken');
              if (AlphaCloudToken !== 'null' && AlphaCloudToken !== getToken()) {
                setUserInfo(getUrlSearchParams('AlphaCloudToken') as string, getUrlSearchParams('userInfo'));
              }
              /* 有重定向时 */
              if (route.redirect) {
                return <Redirect to={route.redirect} />;
              }
              let mainPage: string = localStorage.getItem('mainPage') as string;
              if (pathname == '/login' && hasToken() && permissionList.length > 0) {
                for (let item of permissionList) {
                  if (mainPage && mainPage.indexOf(item.path) > -1) {
                    return <Redirect to={mainPage} />;
                  }
                }
              }

              //白名单
              if (whiteNameList.includes(pathname) || permissionList.length == 0) {
                return returnRender(route, props, extraProps);
              }
              if (
                window.location.pathname == '/innerStatic/customerComplaintDetails/index' &&
                !localStorage.getItem('token')
              ) {
                return (
                  <Redirect
                    to={
                      '/login?redirect=' +
                      '/innerStatic/customerComplaintDetails/index&&key=' +
                      getUrlSearchParams('key')
                    }
                  />
                );
              }

              for (let per of permissionList) {
                if (per.path === pathname && !whiteNameList.includes(pathname)) {
                  if (typeof route.component == 'function') {
                    if (!route.component()) {
                      return returnRender(per, props, extraProps);
                    }
                  }
                  return returnRender(route, props, extraProps);
                }
              }
              if (!hasToken()) {
                return <Redirect key={'/login'} to={'/login' + (props.location.search ? props.location.search : '')} />;
              }
              return <Redirect to="/404" />;
            }}
          />
        ) : null,
      )}
      <Redirect to={'/login' + (AlphaCloudSSO === '1' ? location.search : '')} />
    </Switch>
  ) : null;
};

const returnRender = (route: RouterType, props: any, extraProps = {}) => {
  return route.render ? (
    route.render({ ...props, ...extraProps, route: route })
  ) : (
    <route.component {...props} {...extraProps} route={route} />
  );
};

export default renderRoutes;
