/*
 * @Description:攻坚组去处理弹窗
 * @Author: YuKi
 * @Date: 2025/02/05
 */
import React, { memo, useEffect } from 'react';
import { Button, Modal, Form, Row, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { getHandleForm, saveHandle } from '@/api/clientFeedback/complaintsList';
import TextArea from 'antd/lib/input/TextArea';

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
  processingId: string;
  /** 表单类型 */
  operateFormType: string;
}

const AttackHandleModal: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  extendParams,
  id,
  nodeData,
  operateFormType,
  processingId,
}) => {
  const { t } = useTranslation();
  /* 新增和关联表单的ref */
  const [addForm] = Form.useForm();

  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    if (nodeData || operateFormType === 'NoForm') {
      const id = nodeData?.data?.id || processingId;
      getHandleForms(id);
    }
  }, []);

  /**
   * @description: 获取去处理表单
   * @param {*} id
   * @return {*}
   * @LastEditors: YuKi
   */
  const getHandleForms = (id) => {
    getHandleForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        addForm.setFieldsValue(res.data);
      }
    });
  };

  /**
   * @description:立即执行
   * @return {*}
   * @LastEditors: YuKi
   */
  const saveData = () => {
    addForm.validateFields().then((values) => {
      saveHandle({
        ...values,
        complaintsId: id,
        ...originData,
        taskAuthData: originData.nextOperateAuthList ? originData.nextOperateAuthList[0] : null,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          onClose();
        }
      });
    });
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: YuKi
   */
  const cancelHandle = () => {
    addForm.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData?.data?.currentStatusShowName}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHandle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHandle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form layout="vertical" form={addForm} autoComplete="off">
          {/* 问题初步描述 */}
          <Form.Item
            label={t('complaintList.problem_description')}
            name="reason"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <TextArea
              disabled={nodeData || operateFormType === 'NoForm'}
              autoSize={{ minRows: 3, maxRows: 5 }}
              showCount
              maxLength={1024}
            />
          </Form.Item>
          {/* 临时解决方案 */}
          <Form.Item
            label={t('complaintList.temporary_solution')}
            name="solution"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <TextArea
              disabled={nodeData || operateFormType === 'NoForm'}
              autoSize={{ minRows: 3, maxRows: 5 }}
              showCount
              maxLength={1024}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default memo(AttackHandleModal);
