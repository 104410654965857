// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-class {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 15px;
}
.my-class div {
  overflow: hidden;
  word-wrap: break-word;
  margin-top: 5px;
}
.svg-container {
  width: 100%;
  height: 300px;
  position: relative;
  overflow-x: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClientList/CustomerComplaintDetails/components/MermaidChart/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;AACF;AAPA;EAQI,gBAAA;EACA,qBAAA;EACA,eAAA;AAEJ;AACA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;AACF","sourcesContent":[".my-class {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  flex-direction: column;\n  padding: 0 15px;\n  div {\n    overflow: hidden;\n    word-wrap: break-word;\n    margin-top: 5px;\n  }\n}\n.svg-container {\n  width: 100%;\n  height: 300px;\n  position: relative;\n  overflow-x: auto;\n}\n.svg-container svg {\n  // position: absolute;\n  // height: 1000px;\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
