/*
 * @Description: 客户反馈->客诉详情页->工单详情
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2025/03/19
 */

import React, { memo, useState, useEffect } from 'react';
import { Form, Row, Col, Steps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { getAmsWorkOrderDetail, getAmsWorkOrderProcessList } from '@/api/clientFeedback/complaintsList';
import { getUrlQuery } from '@/utils/utils';
import './index.less';

/*工单详情字段 */
type WorkDetailItem = {
  DefectDescription: string; // 故障描述
  ServiceDescription: string; // 服务描述
  Note: string; // 备注
  appointmenttimeto: string; // 预约时间to
  AppointmentTimeFrom: string; // 预约时间from
  SystemStatus: number; // 主状态
  SubStatus: number; // 子状态
  Priority: number; // 优先级
  ServiceCenter: string; // 外部站点
  Owner: string; // 负责人
  InternalEngineer: string; //内部工程师
  ExternalEngineer: string; //外部工程师
  ClosedBy: string; // 工单关闭人
  ServiceStartTime: string; //开始服务时间
  CompletedOn: string; // 完成服务时间
  TimeClosed: string; // 工单关闭时间
  ScrejectDate: string; // 站点拒单时间
  PrimaryIncidentEstimatedDuration: string; // 关键服务项工时预估
  ServiceHour: number; // 实际服务工时
  Casecategory: string; // 服务类型
  SetOffTime: string; // 首次出发时间
  DispatchedTime: string; // 首次派单时间
  solutionproposal: string;
  // 处理人工号
};

const workOrderDetails: React.FC = (props) => {
  const { t } = useTranslation();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const [workDetails, setWorkDetails] = useState<WorkDetailItem>();
  const [workSteps, setWorkSteps] = useState<any>([]);

  /**
   * @description: 获取工单步骤条
   * @param {*} key
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getAmsWorkOrderProcessLists = (key) => {
    getAmsWorkOrderProcessList({ ro: key }).then((res: any) => {
      if (res.code == 200 && res.data) {
        setWorkSteps(res.data);
      }
    });
  };

  /**
   * @description: 获取工单详情
   * @param {*} key
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getAmsWorkOrderDetails = (key) => {
    getAmsWorkOrderDetail({ ro: key }).then((res: any) => {
      if (res.code == 200 && res.data) {
        setWorkDetails(res.data[0]);
      }
    });
  };
  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    let key: string = getUrlQuery('key');
    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
      getAmsWorkOrderDetails(key);
      getAmsWorkOrderProcessLists(key);
    }
  }, []);

  const getStatusName = (type) => {
    let name: string = '';
    switch (type) {
      case 10:
        name = t('complaintList.create');
        break;
      case 20:
        name = t('complaintList.assign');
        break;
      case 30:
        name = t('complaintList.inService');
        break;
      case 40:
        name = t('complaintList.serviceCompleted');
        break;
      case 50:
        name = t('complaintList.workOrderClosed');
        break;

      default:
        break;
    }
    return name;
  };

  const getSubStatusName = (type) => {
    let name: string = '';
    switch (type) {
      case 10:
        name = t('complaintList.pendingDiagnosis');
        break;
      case 20:
        name = t('complaintList.pendingAssignment');
        break;
      case 30:
        name = t('complaintList.pendingReassignment');
        break;
      case 40:
        name = t('complaintList.pendingSiteAcceptance');
        break;
      case 50:
        name = t('complaintList.pendingAssignmentToEngineer');
        break;
      case 60:
        name = t('complaintList.pendingDeparture');
        break;
      case 70:
        name = t('complaintList.serviceCommencement');
        break;
        break;
      case 80:
        name = t('complaintList.onSiteService');
        break;
      case 90:
        name = t('complaintList.waitingArc');
        break;
        break;
      case 100:
        name = t('complaintList.workOrderClosed');
        break;
      case 110:
        name = t('complaintList.cancel');
        break;

      default:
        break;
    }
    return name;
  };

  const getComplaintType = (type) => {
    let name: string = '';
    switch (type) {
      case 10:
        name = t('complaintList.systemError');
        break;
      case 20:
        name = t('complaintList.monitoringDataAnomaly');
        break;
      case 30:
        name = t('complaintList.technicalConsulting');
        break;
      case 40:
        name = t('complaintList.complaintTypeoTher');
        break;
      default:
        break;
    }
    return name;
  };

  return (
    <>
      <Form
        layout="vertical"
        form={searchForm}
        name="complaintDetail"
        autoComplete="off"
        style={{ width: '100%' }}
        className=""
      >
        <Row gutter={8} className="row-border">
          {/* 故障描述 */}
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Row gutter={8} className="row-border">
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.faultDescription')}</span>}>
                  <Tooltip placement="top" title={workDetails?.DefectDescription}>
                    <div className="text-ellipsis">{workDetails?.DefectDescription}</div>
                  </Tooltip>
                </Form.Item>
              </Col>
              {/* 服务描述 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.serviceDescription')}</span>}>
                  <Tooltip placement="top" title={workDetails?.ServiceDescription}>
                    <div className="text-ellipsis">{workDetails?.ServiceDescription}</div>
                  </Tooltip>
                </Form.Item>
              </Col>
              {/* 预约时间 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.appointment')}</span>}>
                  {workDetails?.AppointmentTimeFrom}
                </Form.Item>
              </Col>
              {/* 预约时间 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.appointment')}</span>}>
                  {workDetails?.appointmenttimeto}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8} className="row-border">
              {/* 主状态 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.mainState')}</span>}>
                  {getStatusName(workDetails?.SystemStatus)}
                </Form.Item>
              </Col>
              {/* 子状态 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.substate')}</span>}>
                  {getSubStatusName(workDetails?.SubStatus)}
                </Form.Item>
              </Col>
              {/* 优先级 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.priority')}</span>}>
                  {workDetails?.Priority}
                </Form.Item>
              </Col>
              {/* 外部站点 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.externalSite')}</span>}>
                  {workDetails?.ServiceCenter}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8} className="row-border">
              {/* 负责人 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.principal')}</span>}>
                  {workDetails?.Owner}
                </Form.Item>
              </Col>
              {/* 内部工程师 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.inHouseEngineer')}</span>}>
                  {workDetails?.InternalEngineer}
                </Form.Item>
              </Col>
              {/* 外部工程师 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.externalEngineer')}</span>}>
                  {workDetails?.ExternalEngineer}
                </Form.Item>
              </Col>
              {/* 服务类型 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.serviceType')}</span>}>
                  {getComplaintType(workDetails?.Casecategory)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8} className="row-border">
              {/* 首次派单时间 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.firstOrderDispatchTime')}</span>}>
                  {workDetails?.DispatchedTime}
                </Form.Item>
              </Col>
              {/* 首次出发时间 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.firstDepartureTime')}</span>}>
                  {workDetails?.SetOffTime}
                </Form.Item>
              </Col>
              {/* 开始服务时间 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.startServiceTime')}</span>}>
                  {workDetails?.ServiceStartTime}
                </Form.Item>
              </Col>
              {/* 完成服务时间 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.completionServiceTime')}</span>}>
                  {workDetails?.CompletedOn}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8} className="row-border">
              {/* 工单关闭时间 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.workOrderClosingTime')}</span>}>
                  {workDetails?.TimeClosed}
                </Form.Item>
              </Col>
              {/* 工单关闭人 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.ticketClosePerson')}</span>}>
                  {workDetails?.ClosedBy}
                </Form.Item>
              </Col>

              {/* 站点拒单时间 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.siteRejectionTime')}</span>}>
                  {workDetails?.ScrejectDate}
                </Form.Item>
              </Col>
              {/* 关键服务型工时预估 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item
                  label={<span className="form-item-label">{t('complaintList.estimatedHoursForKeyServiceItems')}</span>}
                >
                  {workDetails?.PrimaryIncidentEstimatedDuration}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8} className="row-border">
              {/* 实际服务工时 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.actualServiceHours')}</span>}>
                  {workDetails?.ServiceHour}
                </Form.Item>
              </Col>
              {/* 建议方案 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.proposal')}</span>}>
                  <Tooltip placement="top" title={workDetails?.solutionproposal}>
                    <div className="text-ellipsis">{workDetails?.solutionproposal}</div>
                  </Tooltip>
                </Form.Item>
              </Col>
              {/* 备注 */}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Form.Item label={<span className="form-item-label">{t('complaintList.remark')}</span>}>
                  <Tooltip placement="top" title={workDetails?.Note}>
                    <div className="text-ellipsis">{workDetails?.Note}</div>
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="steps-style">
            <Steps current={workSteps.length} direction="vertical">
              {workSteps &&
                workSteps.map((item: any, index) => {
                  return (
                    <Steps.Step
                      title={item.name}
                      description={
                        <div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              {t('complaintList.beforeMainStatusChange')}：{getStatusName(item.preStatus)}{' '}
                            </div>
                            <div style={{ marginLeft: '5px' }}>
                              {t('complaintList.afterMainStatusChange')}：{getStatusName(item.nextStatus)}{' '}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              {t('complaintList.beforeSubStatusChange')}：{getSubStatusName(item.preSubStatus)}
                            </div>
                            <div style={{ marginLeft: '5px' }}>
                              {t('complaintList.afterSubStatusChange')}：{getSubStatusName(item.nextSubStatus)}
                            </div>
                          </div>
                          <div>
                            {t('complaintList.handler')}： {item.userId}
                          </div>
                        </div>
                      }
                    />
                  );
                })}
            </Steps>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default memo(workOrderDetails);
