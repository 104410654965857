/*
 * @Description: 组织树Action
 * @Author: Summer.Chen
 * @Date: 2024-04-09 16:13:16
 * @LastEditTime: 2024-04-20 14:59:16
 * @LastEditors: Summer.Chen
 */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Space, Dropdown, Modal, message } from 'antd';
import EitOrgComponentModal from './editOrgComponentModal';
import EditMembComponentModal from './editMembComponentModal';
import type { DataNode } from 'antd/es/tree';
import { ModalProps } from 'antd/lib/modal';
import {
  MoreOutlined,
  AppstoreAddOutlined,
  UserAddOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { treeDataType, modalParamsType } from './../../config';
import { useTranslation } from 'react-i18next';
import { deleteOrg } from '@/api/authorityManagement';

const { confirm } = Modal;

const OrgTreeActionComponent: React.FC<
  DataNode &
    treeDataType & {
      initData: (notGetMembers?: boolean) => void;
      getMembers: (deptId: string, hasMemberPerms: boolean) => void;
      companyId: string;
    }
> = forwardRef((props, ref): React.ReactElement => {
  // 翻译
  const { t } = useTranslation();
  //父组件调取子组件方法
  useImperativeHandle(ref, () => ({
    newOrg,
  }));
  //数据
  const nodeData = props;

  // 组织弹框传递的参数 type 1新增 2编辑
  const [orgParams, setOrgParams] = useState({
    deptId: '',
    open: false,
    title: t('authorityManagement.newOrganization'),
    type: 1,
    parentId: '',
    companyId: '',
    hasMemberPerms: false,
  });

  // 成员弹框传递的参数 type 1新增 2编辑
  const [orgMem, setMemParams] = useState({
    deptId: '',
    open: false,
    title: t('authorityManagement.newTeamMember'),
    type: 1,
    parentId: '',
    deptName: '',
    hasMemberPerms: false,
  });

  //组织的操作action
  const newOrg = (data: DataNode & treeDataType) => {
    setOrgParams({
      deptId: data.deptId as string,
      open: true,
      title: t('authorityManagement.newOrganization'),
      type: 1,
      parentId: data.deptId as string,
      companyId: nodeData.companyId,
      hasMemberPerms: data.hasMemberPerms as boolean,
    });
  };

  const newMem = (data: DataNode & treeDataType) => {
    setMemParams({
      deptId: data.deptId as string,
      open: true,
      title: t('authorityManagement.newTeamMember'),
      type: 1,
      parentId: '',
      deptName: data.deptName as string,
      hasMemberPerms: data.hasMemberPerms as boolean,
    });
  };

  const delOrg = (data: DataNode & treeDataType) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      icon: <ExclamationCircleOutlined />,
      content: t('authorityManagement.deleteOrganizationPrompt'),
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      onOk() {
        deleteOrg({ deptId: data.deptId }).then((rep: any) => {
          if (rep && rep.code == 200) {
            message.success(t('common.deleteSuccess'));
            //刷新组织树
            nodeData.initData(true);
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  const editOrg = (data: DataNode & treeDataType) => {
    setOrgParams({
      deptId: data.deptId as string,
      open: true,
      title: t('authorityManagement.editOrganization'),
      type: 2,
      parentId: data.parentId,
      companyId: '',
      hasMemberPerms: data.hasMemberPerms as boolean,
    });
  };

  //org弹框的参数
  let orgModalProps: ModalProps & modalParamsType = {
    ...orgParams,
    destroyOnClose: true,
    onCancel: () => {
      setOrgParams({
        ...orgParams,
        open: false,
      });
    },
    width: '45%',
    cancelButtonProps: { shape: 'round' },
    okButtonProps: { shape: 'round' },
    initData: () => {
      nodeData.initData();
    },
    okText: t('common.confirmBtn'),
    cancelText: t('common.cancelBtn'),
  };
  // 成员弹框的参数
  let memModalProps: ModalProps & modalParamsType = {
    ...orgMem,
    destroyOnClose: true,
    onCancel: () => {
      setMemParams({
        ...orgMem,
        open: false,
      });
    },
    width: '45%',
    cancelButtonProps: { shape: 'round' },
    okButtonProps: { shape: 'round' },
    initData: (deptId?: string, hasMemberPerms?: boolean) => {
      nodeData.getMembers(deptId as string, hasMemberPerms as boolean);
    },
    okText: t('common.confirmBtn'),
    cancelText: t('common.cancelBtn'),
  };

  // 组织树的action
  const actionConst = (data: DataNode & treeDataType) => {
    return {
      //新增组织
      0: (
        <AppstoreAddOutlined
          title={t('authorityManagement.newOrganization') as string}
          onClick={() => {
            newOrg(data);
          }}
        />
      ),
      //新增组员
      1: (
        <UserAddOutlined
          title={t('authorityManagement.newTeamMember') as string}
          onClick={() => {
            newMem(data);
          }}
        />
      ),
      //删除组织
      2: (
        <DeleteOutlined
          title={t('authorityManagement.deleteOrganization') as string}
          onClick={() => {
            delOrg(data);
          }}
        />
      ),
      //编辑组织
      3: (
        <EditOutlined
          title={t('authorityManagement.editOrganization') as string}
          onClick={() => {
            editOrg(data);
          }}
        />
      ),
    };
  };
  return (
    <>
      {/* 组织权限的编辑 */}
      <EitOrgComponentModal {...orgModalProps} />
      {/* 成员编辑 */}
      <EditMembComponentModal {...memModalProps} />
      {nodeData.title}
      {nodeData.hasOrgOperate === 3 ? (
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Space
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {actionConst(nodeData)[0]}
                    {actionConst(nodeData)[1]}
                    {actionConst(nodeData)[2]}
                    {actionConst(nodeData)[3]}
                  </Space>
                ),
              },
            ],
          }}
          placement="bottom"
        >
          <MoreOutlined className="ml8" />
        </Dropdown>
      ) : (
        <Space
          className="ml8"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {nodeData.hasOrgOperate === 1 ? actionConst(nodeData)[0] : null}
          {nodeData.hasMemberPerms ? actionConst(nodeData)[1] : null}
          {nodeData.hasOrgOperate === 2 ? (
            <>
              {actionConst(nodeData)[2]} {actionConst(nodeData)[3]}
            </>
          ) : null}
        </Space>
      )}
    </>
  );
});

export default OrgTreeActionComponent;
