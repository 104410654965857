/*
 * @Description: 客户反馈->客诉详情页-> 转中试
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2024/12/10 11:36:19
 */

import React, { memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Row, Col, message, Select, DatePicker, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { saveTransferTest, getTestForm, getFormInfo } from '@/api/clientFeedback/complaintsList';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { tinymceFormatLanguage } from '@/utils';
import { fileUpload } from '@/api/common';
import { config } from '@/constants/config.js';
import moment from 'moment';

const tinyKey = '0990dde4fa3e0f907ddaba1e7cf78810421a434e14f1bcc56d46fcc009e711f2';
const { TextArea } = Input;
const { Option } = Select;

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
}

/* 问题现象描述富文本内容 */
let tinyContent: any = '';
/* 问题出现时的现场工况富文本内容 */
let tinyContent2: any = '';
/* 研发分析结果和对应解决方案富文本内容 */
let tinyContent3: any = '';

const CenterEvaluationTestCases: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  id,
  extendParams,
  nodeData,
}) => {
  const { t } = useTranslation();
  const [editorNow, setEditorNow] = useState<any>('');

  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const { TextArea } = Input;

  useEffect(() => {
    if (nodeData) {
      getTestForms(nodeData.data.id);
    } else {
      getFormInfos();
    }
  }, []);

  /**
   * @description: 获取表单初始内容
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getFormInfos = () => {
    getFormInfo({ key: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        res.data.feedbackDate = moment(res.data.feedbackDate);
        searchForm.setFieldsValue(res.data);
      }
    });
  };

  /**
   * @description: 获取中试表单
   * @param {*} id
   * @LastEditors: XuAnjie
   */
  const getTestForms = (id) => {
    getTestForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        res.data.feedbackDate = moment(res.data.feedbackDate);
        searchForm.setFieldsValue(res.data);
      }
    });
  };

  /**
   * @description: 监听富文本变化
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const handleEditorChange = (content: any, editor: any) => {
    tinyContent = content;
  };

  /**
   * @description: 监听富文本变化
   * @LastEditors: Xuanjie
   */
  const handleEditorChangeWorkSite = (content: any, editor: any) => {
    tinyContent2 = content;
  };

  /**
   * @description: 监听富文本变化
   * @LastEditors: Xuanjie
   */
  const handleEditorChangeSolutions = (content: any, editor: any) => {
    tinyContent3 = content;
  };

  /**
   * @description: 保存转中试表单
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    if (!tinyContent) {
      return message.error(t('complaintList.descriptionOfProblemRequired'));
    }
    if (!tinyContent2) {
      return message.error(t('complaintList.workingConditionsOnSite') + t('archivalMaintenance.parmasRequired'));
    }
    if (!tinyContent3) {
      return message.error(t('complaintList.analysisResultsSolutions') + t('archivalMaintenance.parmasRequired'));
    }
    searchForm.validateFields().then((values) => {
      let transferParams = [];
      if (values.taskAuthData && originData.nextOperateAuthList.length > 1) {
        transferParams = originData.nextOperateAuthList.filter((item) => {
          return item.id == values.taskAuthData;
        });
      } else {
        transferParams = originData.nextOperateAuthList;
      }
      saveTransferTest({
        complaintsId: id,
        ...values,
        problemDescription: tinyContent,
        problemScenario: tinyContent2,
        problemSolution: tinyContent3,
        ...originData,
        taskAuthData: transferParams ? transferParams[0] : null,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHanle();
        }
      });
    });
  };

  /**
   * @description: 取消操作
   * @LastEditors: XuAnjie
   */
  const cancelHanle = () => {
    searchForm.resetFields();
    onClose();
  };

  /**
   * @description: 富文本上传图片
   * @LastEditors: Xuanjie
   */
  const tinymceUploadImage = (blobInfo, succFun, failFun) => {
    const formData = new FormData();
    // 服务端接收文件的参数名，文件数据，文件名
    formData.append('blobContainerName', 'FILES');
    formData.append('fileList', blobInfo.blob(), blobInfo.filename());
    fileUpload(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          let uploadUrl = '';
          if (window.location.origin.indexOf('localhost') != -1) {
            uploadUrl = config.downUrl + 'files/';
          } else {
            uploadUrl = config.downUrl + 'files/';
          }
          succFun(uploadUrl + res.data[0].fileSaveName);
        } else {
          failFun(t('createComplaint.fileUploadError'));
        }
      })
      .catch(() => {
        failFun(t('createComplaint.fileUploadError'));
      });
  };

  return (
    <>
      {/* 客诉进度列表 */}
      <Modal
        title={(originData && originData.operationNameShow) || nodeData.data.currentStatusShowName}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHanle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHanle}>
              {t('common.cancelBtn')}
            </Button>
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form
          layout="vertical"
          form={searchForm}
          name="complaintDetail"
          autoComplete="off"
          style={{ width: '100%' }}
          disabled={nodeData ? true : false}
          className=""
        >
          <Row gutter={8} className="row-border">
            {/* 客户所属地 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.customerLocation')}</span>}
                name="customerLocation"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('complaintList.customerLocationRequired')}`, whitespace: true },
                ]}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 产品名称 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.productSpecifications')}</span>}
                name="minv"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('complaintList.productSpecificationsRequired')}`, whitespace: true },
                ]}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 不良数量 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.defectQuantity')}</span>}
                name="defectQuantity"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.defectQuantityRequired')}` }]}
              >
                <InputNumber style={{ width: '100%' }} placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            {/* 项目(客户) 名称 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.customerName')}</span>}
                name="customerName"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.customerNameRequired')}`, whitespace: true }]}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 产品SN */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.productSN')}</span>}
                name="systemSn"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.productSNRequired')}`, whitespace: true }]}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 处理人 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                // className="complaint-detail-label"
                name="handler"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}`, whitespace: true }]}
                label={<span className="form-item-label">{t('complaintList.complaintListHandler')}</span>}
              >
                <Input placeholder={`${t('complaintList.complaintListHandlerRequired')}`} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            {/* 客户反馈日期 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="feedbackDate"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.feedbackDateRequired')}` }]}
                label={
                  <span className="form-item-label" style={{ whiteSpace: 'pre-wrap' }}>
                    {t('complaintList.feedbackDate')}
                  </span>
                }
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            {/* 客诉编号 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                // className="complaint-detail-label"
                name="ticketNumber"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}`, whitespace: true }]}
                label={<span className="form-item-label">{t('complaintList.complaintNumber')}</span>}
              >
                <Input placeholder={`${t('complaintList.complaintNumberRequired')}`} />
              </Form.Item>
            </Col>
            {/* 是否安装 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                // className="complaint-detail-label"
                name="isInstalled"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.install(Yes/No)Required')}` }]}
                label={<span className="form-item-label">{t('complaintList.install(Yes/No)')}</span>}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  style={{ width: '100%' }}
                  options={[
                    { value: 1, label: t('complaintList.Yes') },
                    { value: 0, label: t('complaintList.No') },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            {/* 运行时长 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                // className="complaint-detail-label"
                name="runTime"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                label={<span className="form-item-label">{t('complaintList.runTime')}</span>}
              >
                <InputNumber style={{ width: '100%' }} placeholder={`${t('complaintList.runTimeRequired')}`} />
              </Form.Item>
            </Col>
            {/* 投诉时间 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                // className="complaint-detail-label"
                name="complaintArea"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.complaintAreaRequired')}` }]}
                label={
                  <span className="form-item-label" style={{ whiteSpace: 'pre-wrap' }}>
                    {t('complaintList.complaintArea')}
                  </span>
                }
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  style={{ width: '100%' }}
                  options={[
                    { value: '0', label: t('complaintList.priorToWarehouseShipment') },
                    { value: '1', label: t('complaintList.afterwarehouseShipment') },
                    { value: '2', label: t('complaintList.beforeInstallation') },
                    { value: '3', label: t('complaintList.afterInstallation') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 是否属于常规使用场景 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="generalUse"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: `${t('complaintList.commomUseState')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
                label={<span className="form-item-label">{t('complaintList.commomUseState')}</span>}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  style={{ width: '100%' }}
                  options={[
                    { value: 1, label: t('complaintList.Yes') },
                    { value: 0, label: t('complaintList.No') },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* 是否属于平台化/系列产品功能 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="productFunction"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: `${t('complaintList.platProductFeatures')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
                label={<span className="form-item-label">{t('complaintList.platProductFeatures')}</span>}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  style={{ width: '100%' }}
                  options={[
                    { value: 1, label: t('complaintList.Yes') },
                    { value: 0, label: t('complaintList.No') },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          {originData &&
          originData.transferType &&
          originData.nextOperateAuthList &&
          originData.nextOperateAuthList.length > 1 ? (
            <Row gutter={8} className="row-border">
              {/* 转交给 */}
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  name="taskAuthData"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                  label={
                    <span className="form-item-label" style={{ whiteSpace: 'pre-wrap' }}>
                      {t('complaintList.transferTo')}
                    </span>
                  }
                >
                  <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                    {originData.nextOperateAuthList &&
                      originData.nextOperateAuthList.length > 0 &&
                      originData.nextOperateAuthList.map((item: any, index) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          {/* 问题现象描述	 */}
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={t('complaintList.descriptionOfProblem')} required name="problemDescription">
                <Editor
                  apiKey={tinyKey}
                  tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                  onInit={(content, editor) => {
                    // editorCheck = editor
                    setEditorNow(editor);
                  }}
                  disabled={nodeData}
                  scriptLoading={{ async: true }} // 异步加载
                  onEditorChange={handleEditorChange}
                  init={{
                    min_height: 300,
                    language: tinymceFormatLanguage(language),
                    menubar: false, // 顶部菜单栏
                    resize: false, // 右下角调整大小
                    statusbar: false, // 底部状态栏
                    object_resizing: false, // 禁止设置媒体大小
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    default_link_target: '_blank',
                    plugins:
                      'autoresize print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave',
                    toolbar:
                      'undo redo bold italic  image | formatselect |  alignleft aligncenter alignright alignjustify outdent indent ',
                    images_upload_handler: (blobInfo, succFun, failFun) => {
                      return tinymceUploadImage(blobInfo, succFun, failFun);
                    },
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* 问题出现时的现场工况 */}
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={t('complaintList.workingConditionsOnSite')} required name="problemScenario">
                <Editor
                  apiKey={tinyKey}
                  tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                  onInit={(content, editor) => {
                    // editorCheck = editor
                    setEditorNow(editor);
                  }}
                  disabled={nodeData}
                  scriptLoading={{ async: true }} // 异步加载
                  onEditorChange={handleEditorChangeWorkSite}
                  init={{
                    min_height: 300,
                    language: tinymceFormatLanguage(language),
                    menubar: false, // 顶部菜单栏
                    resize: false, // 右下角调整大小
                    statusbar: false, // 底部状态栏
                    object_resizing: false, // 禁止设置媒体大小
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    default_link_target: '_blank',
                    plugins:
                      'autoresize print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave',
                    toolbar:
                      'undo redo bold italic  image | formatselect |  alignleft aligncenter alignright alignjustify outdent indent ',
                    images_upload_handler: (blobInfo, succFun, failFun) => {
                      return tinymceUploadImage(blobInfo, succFun, failFun);
                    },
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* 	研发分析结果和对应解决方案 */}
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={t('complaintList.analysisResultsSolutions')} required name="problemSolution">
                <Editor
                  apiKey={tinyKey}
                  tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                  onInit={(content, editor) => {
                    // editorCheck = editor
                    setEditorNow(editor);
                  }}
                  disabled={nodeData}
                  scriptLoading={{ async: true }} // 异步加载
                  onEditorChange={handleEditorChangeSolutions}
                  init={{
                    min_height: 300,
                    language: tinymceFormatLanguage(language),
                    menubar: false, // 顶部菜单栏
                    resize: false, // 右下角调整大小
                    statusbar: false, // 底部状态栏
                    object_resizing: false, // 禁止设置媒体大小
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    default_link_target: '_blank',
                    plugins:
                      'autoresize print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave',
                    toolbar:
                      'undo redo bold italic  image | formatselect |  alignleft aligncenter alignright alignjustify outdent indent ',
                    images_upload_handler: (blobInfo, succFun, failFun) => {
                      return tinymceUploadImage(blobInfo, succFun, failFun);
                    },
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(CenterEvaluationTestCases);
