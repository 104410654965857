/*
 * @Description: 内容分页列表
 * @Author: Wayne Hu
 * @Date: 2024/12/06 13:56:03
 */

import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getContentPage,
  addContent,
  deleteContent,
  editContent,
  refreshData,
  getResponsiblePartyList,
} from '@/api/clientFeedback/archivalMaintenance';

/* 列表字段类型定义 */
type ContentColItem = {
  /** 主键 */
  id: number;
  /** 问题内容 */
  content: number;
  /** 问题归属 */
  problemCauses: string;
  /** 责任归属方 */
  responsibleParty: string;
  /** 创建人 */
  createAccount: string;
  /** 创建时间 */
  createDatetime: string;
};

/* 列表查询参数 */
let searchParams = {
  /** 每页条数 */
  pageSize: 10,
  /** 当前页 */
  pageIndex: 1,
  /** 关键字 */
  key: '',
  /** 模块ID */
  moduleId: '',
};

let beforePageParams = {
  /** 页码 */
  pageIndex: 1,
  /** 部件ID */
  componentId: '',
  /** 模块 */
  module: '',
  /** 所属产品线 */
  businessLine: 10,
};

const contentManagement: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const { Option } = Select;
  const location = useLocation<any>();
  const history = useHistory();
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  const [dataList, setDataList] = useState<ContentColItem[]>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [addOrEdit, setAddOrEdit] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [responsiblePartyList, setResponsiblePartyList] = useState<any>([]);

  /**
   * @Author: Wayne Hu
   * @description: 获取分页列表
   */
  const getComponentPages = () => {
    setTableLoading(true);
    if (searchForm) {
      searchParams.key = searchForm.getFieldValue('key');
    }
    getContentPage(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data && rep.data.rows) {
          setDataList([...rep.data.rows]);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
        } else {
          setDataList([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pagingInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pagingInit });
        setTableLoading(false);
      });
  };

  /**
   * @Author: Wayne Hu
   * @description: 新增或编辑内容
   */
  const addOrEditComponents = () => {
    contentForm
      .validateFields()
      .then((values) => {
        // 获取选中的责任归属方
        const responsibleParty = responsiblePartyList.filter((item) => item.key === values.responsiblePartyId);
        let obj = {
          ...values,
          moduleId: searchParams.moduleId,
          responsibleParty: responsibleParty[0] && responsibleParty[0].value ? responsibleParty[0].value : undefined,
        };
        if (addOrEdit === 1) {
          addContent(obj).then((res: any) => {
            if (res.code == 200) {
              message.success(t('common.addSuccess'));
              searchParams.pageIndex = 1;
              searchParams.pageSize = 10;
              getComponentPages();
              handleCancel();
            }
          });
        } else {
          editContent({ ...obj }).then((res: any) => {
            if (res.code == 200) {
              message.success(t('common.saveSuccess'));
              getComponentPages();
              handleCancel();
            }
          });
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (location.state && location.state.moduleId) {
      // 模块id
      searchParams.moduleId = location.state.moduleId;
      // 暂存上一个页面的参数
      beforePageParams.componentId = location.state.componentId;
      beforePageParams.module = location.state.module;
      beforePageParams.pageIndex = location.state.pageIndex;
      beforePageParams.businessLine = location.state.businessLine;
    }
    getComponentPages();
    getResponsiblePartyLists(beforePageParams.businessLine);
  }, []);

  /**
   * @Author: Wayne Hu
   * @description: 返回上一个页面
   */
  const backTo = () => {
    history.push({
      pathname: '/innerStatic/MoudleMaintenance/index',
      state: {
        pageIndex: beforePageParams.pageIndex,
        componentId: beforePageParams.componentId,
        module: beforePageParams.module,
        businessLine: beforePageParams.businessLine,
      },
    });
  };

  /**
   * @Author: Wayne Hu
   * @description: 获取责任归属方列表
   * @param {*} businessLine
   */
  const getResponsiblePartyLists = (businessLine) => {
    getResponsiblePartyList(businessLine)
      .then((res: any) => {
        if (res.code == 200 && res.data) {
          setResponsiblePartyList([...res.data]);
        } else {
          setResponsiblePartyList([]);
        }
      })
      .catch((err) => {
        setResponsiblePartyList([]);
      });
  };

  /**
   * @Author: Wayne Hu
   * @description: 分页回调
   * @param {*} useCallback
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getComponentPages();
  }, []);

  /**
   * @Author: Wayne Hu
   * @description: 按钮查询分页列表
   */
  const search = () => {
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    getComponentPages();
  };

  /**
   * @Author: Wayne Hu
   * @description: 删除指定行
   * @param {*} id
   */
  const handleDelete = (id) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteData'),
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      onOk() {
        deleteContent({ id: id }).then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('common.deleteSuccess'));
            getComponentPages();
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  /**
   * @Author: Wayne Hu
   * @description: 打开新增或编辑弹窗
   * @param {number} type 弹窗类型 1:新增 2:编辑
   * @param {any} record
   */
  const displayAddOrEditDialog = (type: number, record?: any) => {
    setAddOrEdit(type);
    if (type === 1) {
      contentForm && contentForm.resetFields();
    } else {
      contentForm && contentForm.setFieldsValue(record);
    }
    setIsModalOpen(true);
  };

  /**
   * @Author: Wayne Hu
   * @description: 关闭弹窗
   */
  const handleCancel = () => {
    if (contentForm) {
      contentForm.resetFields();
    }
    setIsModalOpen(false);
  };

  /**
   * @Author: YuKi
   * @description: 同步数据
   * @param {string} id
   */
  const refresh = (id?: string) => {
    refreshData({ contentId: id ?? undefined }).then((rep: any) => {
      if (rep.code == 200) {
        message.success(t('complaintList.operateSuccess'));
      }
    });
  };

  /* 列表字段 */
  const columns: ColumnsType<ContentColItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      width: 140,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 问题内容 */
    {
      title: t('archivalMaintenance.questionContent'),
      dataIndex: 'content',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 问题归属 */
    {
      title: t('archivalMaintenance.problemAttribution'),
      dataIndex: 'problemCauses',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 责任归属方 */
    {
      title: t('responsiblePartyMaintenance.responsibleParty'),
      dataIndex: 'responsibleParty',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 创建人 */
    {
      title: t('common.creator'),
      dataIndex: 'createAccount',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 创建时间 */
    {
      title: t('common.createTime'),
      dataIndex: 'createDatetime',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      align: 'center',
      width: 340,
      render: (text: any, record: any, index: any) => {
        return (
          <Space size="middle" wrap className="sn-management-space">
            {/* 编辑 */}
            <a onClick={() => displayAddOrEditDialog(2, record)}>{t('common.editBtn')}</a>
            {/* 刷新 */}
            <a onClick={() => refresh(record.id)}>{t('complaintList.refresh')}</a>
            {/* 删除 */}
            <a
              className="delete-text"
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              {t('common.deleteBtn')}
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="custom-contant project-management">
        <Form
          form={searchForm}
          name="search_form"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
          layout="vertical"
        >
          <Row gutter={12} align={'bottom'}>
            {/* 部件名称 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item label={t('complaintList.keyword')} name="key">
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <Row>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  <Form.Item>
                    <Space size="middle">
                      {/* 查询 */}
                      <Button type="primary" shape="round" htmlType="submit">
                        {t('common.searchBtn')}
                      </Button>
                      {/* 新增 */}
                      <Button type="primary" shape="round" onClick={() => displayAddOrEditDialog(1)}>
                        {t('common.addBtn')}
                      </Button>
                      {/* 刷新 */}
                      <Button type="primary" shape="round" onClick={() => refresh()}>
                        {t('complaintList.refresh')}
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>

                <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: 'right' }}>
                  <Button type="primary" shape="round" onClick={backTo}>
                    {t('common.goback')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <Table
          columns={columns}
          loading={tableLoading}
          rowKey={(record) => record.id}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 1030, y: 'calc(100vh - 350px)' }}
          className="custom-table-flex"
        />
      </div>

      {/* 新增/编辑弹窗 */}
      <Modal
        maskClosable={false}
        title={addOrEdit === 1 ? t('common.addBtn') : t('common.editBtn')}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button shape="round" onClick={handleCancel}>
            {t('common.cancelBtn')}
          </Button>,
          <Button shape="round" type="primary" onClick={addOrEditComponents}>
            {t('common.saveBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form autoComplete="off" layout="vertical" form={contentForm}>
          {/* 问题内容 */}
          <Form.Item
            label={t('archivalMaintenance.questionContent')}
            name="content"
            rules={[
              {
                required: true,
                message: `${t('archivalMaintenance.content')}${t('archivalMaintenance.parmasRequired')} `,
                whitespace: true,
              },
              {
                validator: (rule, value) => {
                  if (value && value.trim() && value.trim().length > 64) {
                    return Promise.reject(t('common.maxlengLimit', { maxLength: 64 }));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          {/* 问题归属 */}
          <Form.Item
            label={t('archivalMaintenance.problemAttribution')}
            name="problemCauses"
            rules={[
              {
                required: true,
                message: `${t('archivalMaintenance.problemAttribution')}${t('archivalMaintenance.parmasRequired')}`,
              },
            ]}
          >
            <Select>
              <Option value="质量端问题">{t('complaintList.massEndProblem')}</Option>
              <Option value="研发端问题">{t('complaintList.technicalSideProblem')}</Option>
              <Option value="咨询">{t('complaintList.consult')}</Option>
              <Option value="第三方问题">{t('complaintList.thirdPartyIssues')}</Option>
            </Select>
          </Form.Item>

          {/* 责任方归属 */}
          <Form.Item
            label={t('responsiblePartyMaintenance.responsibleParty')}
            name="responsiblePartyId"
            rules={[
              {
                required: true,
                message: `${t('responsiblePartyMaintenance.responsiblePartySelect')}`,
              },
            ]}
          >
            <Select
              allowClear
              placeholder={`${t('common.pleaseChoose')}`}
              options={responsiblePartyList}
              fieldNames={{ label: 'value', value: 'key' }}
            />
          </Form.Item>

          {/* id */}
          {addOrEdit === 2 ? (
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
    </>
  );
};

export default memo(contentManagement);
