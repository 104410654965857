/*
 * @Description: 模块维护页面
 * @Author: Wayne Hu
 * @Date: 2024/12/06 13:56:03
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getModulePageList,
  addModule,
  deleteModule,
  editModule,
  refreshData,
} from '@/api/clientFeedback/archivalMaintenance';

/* 列表字段类型定义 */
type SecTableColumnsItem = {
  /** 行ID */
  id: string;
  /** 模块名称 */
  module: string;
  /** 创建人 */
  createAccount: string;
  /** 创建时间 */
  createDatetime: string;
  /** 部件ID */
  componentId: string;
};

/* 列表查询参数 */
let searchParams = {
  /** 每页条数 */
  pageSize: 10,
  /** 当前页 */
  pageIndex: 1,
  /** 模块名称 */
  module: '',
  /** 部件ID */
  componentId: '',
};
/** 前页面查询参数暂存 */
let beforeParams = {
  moduleId: '',
  component: '',
  businessLine: '',
  pageIndex: 1,
};

const ModuleMaintenance: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const history = useHistory();
  const location = useLocation();
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  const [dataList, setDataList] = useState<SecTableColumnsItem[]>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [addOrEdit, setAddOrEdit] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addEditForm] = Form.useForm();
  const [searchForm] = Form.useForm();

  /**
   * @Author: Wayne Hu
   * @description: 跳转至内容维护页面
   * @param {*} record
   */
  const linkToContentPage = (record) => {
    if (history.location.pathname.indexOf('/innerStatic') < 0) {
      localStorage.setItem('pathname', history.location.pathname);
    }
    history.push({
      pathname: '/innerStatic/ContentMaintenance/index',
      state: {
        moduleId: record.id,
        pageIndex: pagingRecord.pageIndex,
        module: searchParams.module,
        componentId: searchParams.componentId,
        businessLine: beforeParams.businessLine,
      },
    });
  };

  /**
   * @Author: Wayne Hu
   * @description: 返回前一个页面
   */
  const backTo = () => {
    history.push({
      pathname: '/maintenanceManagement/archivalMaintenance',
      state: {
        pageIndex: beforeParams.pageIndex,
        component: beforeParams.component,
        businessLine: beforeParams.businessLine,
      },
    });
  };

  /**
   * @Author: Wayne Hu
   * @description: 获取模块分页列表
   * @return {*}
   */
  const getModulePage = () => {
    setTableLoading(true);
    getModulePageList(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data && rep.data.rows) {
          setDataList([...rep.data.rows]);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
        } else {
          setDataList([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pagingInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pagingInit });
        setTableLoading(false);
      });
  };

  /**
   * @Author: Wayne Hu
   * @description: 新增或编辑模块
   */
  const addOrEditComponents = () => {
    if (addOrEdit === 1) {
      addEditForm.validateFields().then((values) => {
        addModule({ ...values }).then((res: any) => {
          if (res.code == 200) {
            message.success(t('complaintList.operateSuccess'));
            getModulePage();
            handleCancel();
          }
        });
      });
    } else {
      addEditForm.validateFields().then((values) => {
        editModule({ ...values }).then((res: any) => {
          if (res.code == 200) {
            message.success(t('complaintList.operateSuccess'));
            getModulePage();
            handleCancel();
          }
        });
      });
    }
  };

  useEffect(() => {
    // 如果从1->2
    if (location.state && location.state.moduleId) {
      searchParams.componentId = location.state.moduleId;
      // 参数暂存
      beforeParams.moduleId = location.state.moduleId;
      beforeParams.component = location.state.component;
      beforeParams.businessLine = location.state.businessLine;
      beforeParams.pageIndex = location.state.pageIndex;
    }
    // 如果从3->2
    if (location.state && location.state.componentId) {
      searchParams.componentId = location.state.componentId;
      searchParams.module = location.state.module;
      searchParams.pageIndex = location.state.pageIndex;
      searchForm.setFieldValue('module', location.state.module);
      beforeParams.businessLine = location.state.businessLine;
    }

    getModulePage();
    return () => {
      searchParams = {
        pageSize: 10,
        pageIndex: 1,
        module: '',
        componentId: '',
      };
    };
  }, []);

  /**
   * @Author: Wayne Hu
   * @description: 分页回调函数
   * @param {*} useCallback
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams = { ...searchParams, ...searchForm.getFieldsValue() };
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getModulePage();
  }, []);

  /**
   * @Author: Wayne Hu
   * @description: 按钮查询分页列表
   */
  const search = () => {
    searchParams = { ...searchParams, ...searchForm.getFieldsValue() };
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    getModulePage();
  };

  /**
   * @Author: Wayne Hu
   * @description: 删除选中的行
   * @param {*} id
   */
  const handleDelete = (id) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteData'),
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      onOk() {
        deleteModule({ id: id }).then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('common.deleteSuccess'));
            getModulePage();
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  /**
   * @Author: Wayne Hu
   * @description: 打开新增或编辑弹窗
   * @param {number} type 弹窗类型 1:新增 2:编辑
   * @param {any} record
   */
  const displayAddOrEditDialog = (type: number, record?: any) => {
    setIsModalOpen(true);
    if (type === 1) {
      setAddOrEdit(1);
      addEditForm && addEditForm.resetFields();
      addEditForm.setFieldValue('componentId', searchParams.componentId);
    } else {
      setAddOrEdit(2);
      addEditForm &&
        addEditForm.setFieldsValue({
          id: record.id,
          module: record.module,
          componentId: record.componentId,
        });
    }
  };

  /**
   * @Author: Wayne Hu
   * @description: 关闭弹窗
   */
  const handleCancel = () => {
    if (addEditForm) {
      addEditForm.resetFields();
    }
    setIsModalOpen(false);
  };

  /**
   * @Author: Wayne Hu
   * @description: 同步历史数据(全部)
   */
  const refreshAll = () => {
    refreshData({}).then((rep: any) => {
      if (rep.code == 200) {
        message.success(t('complaintList.operateSuccess'));
      }
    });
  };

  /**
   * @Author: Wayne Hu
   * @description: 同步历史数据(单个)
   * @param {*} id
   */
  const refresh = (id) => {
    refreshData({ moduleId: id }).then((rep: any) => {
      if (rep.code == 200) {
        message.success(t('complaintList.operateSuccess'));
      }
    });
  };

  /* 列表字段 */
  const columns: ColumnsType<SecTableColumnsItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      width: 140,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 模块名称 */
    {
      title: t('archivalMaintenance.module'),
      dataIndex: 'module',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 创建人 */
    {
      title: t('common.creator'),
      dataIndex: 'createAccount',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 创建时间 */
    {
      title: t('common.createTime'),
      dataIndex: 'createDatetime',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      align: 'center',
      width: 340,
      render: (text: any, record: any, index: any) => {
        return (
          <Space size="middle" wrap className="sn-management-space">
            {/* 编辑 */}
            <a onClick={() => displayAddOrEditDialog(2, record)}>{t('common.editBtn')}</a>
            {/* 内容维护 */}
            <a
              onClick={() => {
                linkToContentPage(record);
              }}
            >
              {t('archivalMaintenance.contentMaintenance')}
            </a>
            {/* 刷新 */}
            <a
              onClick={() => {
                refresh(record.id);
              }}
            >
              {t('complaintList.refresh')}
            </a>
            {/* 删除 */}
            <a
              className="delete-text"
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              {t('common.deleteBtn')}
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="custom-contant project-management">
        <Form
          form={searchForm}
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
          layout="vertical"
        >
          <Row gutter={12} align={'bottom'}>
            {/* 部件名称 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item label={t('archivalMaintenance.module')} name="module">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <Row>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  <Form.Item>
                    <Space size="middle">
                      <Button type="primary" shape="round" htmlType="submit">
                        {t('common.searchBtn')}
                      </Button>
                      <Button type="primary" shape="round" onClick={() => displayAddOrEditDialog(1)}>
                        {t('common.addBtn')}
                      </Button>
                      <Button type="primary" shape="round" onClick={() => refreshAll()}>
                        {t('complaintList.refresh')}
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: 'right' }}>
                  <Button type="primary" shape="round" onClick={backTo}>
                    {t('common.goback')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        {/* 表格 */}
        <Table
          columns={columns}
          loading={tableLoading}
          rowKey={'id'}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 600, y: window.innerHeight - 350 }}
          className="custom-table-flex"
        />
      </div>

      {/* 新增/编辑弹窗 */}
      <Modal
        maskClosable={false}
        title={addOrEdit === 1 ? t('common.addBtn') : t('common.editBtn')}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button shape="round" onClick={handleCancel}>
            {t('common.cancelBtn')}
          </Button>,
          <Button shape="round" type="primary" onClick={addOrEditComponents}>
            {t('common.saveBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form autoComplete="off" layout="vertical" form={addEditForm}>
          {/* 模块名称 */}
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={t('archivalMaintenance.module')}
                name="module"
                rules={[
                  {
                    required: true,
                    message: `${t('archivalMaintenance.module')}${t('archivalMaintenance.parmasRequired')}`,
                    whitespace: true,
                  },
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > 64) {
                        return Promise.reject(t('common.maxlengLimit', { maxLength: 64 }));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {addOrEdit === 2 ? (
              <Col span={24}>
                {/* 模块ID */}
                <Form.Item label={'模块ID'} name="id" hidden={true}>
                  <Input disabled />
                </Form.Item>
              </Col>
            ) : null}

            <Col span={24}>
              {/* 部件ID */}
              <Form.Item label={'部件ID'} name="componentId" hidden={true}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ModuleMaintenance);
