/*
 * @Description:专家组去处理弹窗
 * @Author: YuKi
 * @Date: 2025/02/05
 */
import React, { memo, useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col, message, DatePicker, Tooltip, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { emphasisFinish, getHandleForm, saveHandle, updateEightReport } from '@/api/clientFeedback/complaintsList';
import TextArea from 'antd/lib/input/TextArea';
import { InfoCircleOutlined } from '@ant-design/icons';
import FileUpload from '../FileUpload';
import PreViewModal from '../PreViewModal';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';
import { fileUploadARMS } from '@/api/common';
import { config } from '@/constants/config';

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
  processingId: string;
  /** 表单类型 */
  operateFormType: string;
  /** 是否可以编辑8d报告 */
  isEditEightReport: boolean;
  /** 为了区分在用户在操作栏点击时可以上传8d报告 */
  isOperate: boolean;
}

const ExpertHandleModal: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  extendParams,
  id,
  nodeData,
  operateFormType,
  processingId,
  isEditEightReport,
  isOperate,
}) => {
  const { t } = useTranslation();
  /* 附件列表 */
  const [fileLists, setFileLists] = useState([]);
  /* 预览弹窗的开关 */
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  /* 点击文件 */
  const [fileItem, setFileItem] = useState<any>();
  /* 新增和关联表单的ref */
  const [addForm] = Form.useForm();

  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    if (nodeData || operateFormType === 'ToHandleWithEight' || operateFormType == 'NoFormFinish') {
      const id = nodeData?.data?.id || processingId;
      getHandleForms(id);
    }
  }, []);

  /**
   * @description: 获取去处理表单
   * @param {*} id
   * @return {*}
   * @LastEditors: YuKi
   */
  const getHandleForms = (id) => {
    getHandleForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        const { dateInfo, amsFileList, eightFile, ...other } = res.data;
        const data = dateInfo ? moment(dateInfo, 'YYYY-MM-DD') : undefined;
        let eightFileTemp: any = undefined;
        if (eightFile && eightFile.length > 0) {
          eightFileTemp = [
            {
              uid: eightFile[0].fileFullPath,
              name: eightFile[0].fileName,
              status: 'done',
              response: [
                {
                  blobContainerName: eightFile[0].blobContainerName,
                  fileFullPath: eightFile[0].fileSaveName,
                  fileName: eightFile[0].fileName,
                  fileSize: eightFile[0].size,
                },
              ],
            },
          ];
        }
        addForm.setFieldsValue({ ...other, dateInfo: data, eightFile: eightFileTemp });
        setFileLists(amsFileList);
      }
    });
  };

  /**
   * @description: 1、立即执行
   * @return {*}
   * @LastEditors: YuKi
   */
  const saveData = () => {
    // if判断顺序不能乱
    if (operateFormType == 'NoFormFinish') {
      emphasisFinish({
        complaintsId: id,
        ...originData,
        taskAuthData: originData.nextOperateAuthList ? originData.nextOperateAuthList[0] : null,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          onClose();
        }
      });
    } else if (!isOperate && isEditEightReport && operateFormType === 'ToHandleWithEight') {
      addForm.validateFields().then((values) => {
        const { eightFile } = values;
        let eightFileTemp: any = [];
        eightFile &&
          eightFile?.forEach((item) => {
            eightFileTemp.push({
              blobContainerName: item.response[0].blobContainerName,
              fileFullPath: item.response[0].fileSaveName,
              fileName: item.response[0].fileName,
              fileSize: item.response[0].size,
            });
          });

        updateEightReport({
          eightFile: eightFileTemp,
          processingId: extendParams.processingId,
        }).then((res: any) => {
          if (res.code == 200) {
            message.success(t('complaintList.operateSuccess'));
            onClose();
          }
        });
      });
    } else {
      addForm.validateFields().then((values) => {
        const { dateInfo, amsFileList, eightFile, ...other } = values;
        const date = dateInfo ? dateInfo.format('YYYY-MM-DD') : undefined;
        let amsFileLists: any = [];
        let eightFileTemp: any = [];
        amsFileList &&
          amsFileList?.forEach((item) => {
            amsFileLists.push({
              blobContainerName: item.response[0].blobContainerName,
              fileFullPath: item.response[0].fileSaveName,
              fileName: item.response[0].fileName,
              fileSize: item.response[0].size,
            });
          });

        eightFile &&
          eightFile?.forEach((item) => {
            eightFileTemp.push({
              blobContainerName: item.response[0].blobContainerName,
              fileFullPath: item.response[0].fileSaveName,
              fileName: item.response[0].fileName,
              fileSize: item.response[0].size,
            });
          });

        saveHandle({
          eightFile: eightFileTemp,
          isEightReport: false,
          amsFileList: fileLists && fileLists.length > 0 ? fileLists : amsFileLists,
          dateInfo: date,
          ...other,
          complaintsId: id,
          ...originData,
          taskAuthData: originData.nextOperateAuthList ? originData.nextOperateAuthList[0] : null,
          processInstanceId: extendParams.processInstanceId,
          taskInstanceId: extendParams.taskInstanceId,
        }).then((res: any) => {
          if (res.code == 200) {
            message.success(t('complaintList.operateSuccess'));
            onClose();
          }
        });
      });
    }
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: YuKi
   */
  const cancelHandle = () => {
    addForm.resetFields();
    onClose();
  };

  /**
   * @description: 回显FileUpload组件上传的文件
   * @param {*} newFileList
   * @return {*}
   * @LastEditors: YuKi
   */
  const handleFileListChange = (newFileList) => {
    addForm.setFieldValue('amsFileList', newFileList);
    addForm.validateFields(['amsFileList']);
  };

  /**
   * @Author: YuKi
   * @description:设置如何upload组件将 event 的值转换成字段值
   * @param {any} e
   * @return {*}
   */
  const normFile = (e: any) => {
    if (e.file.error) {
      return [];
    } else {
      return e.fileList;
    }
  };

  /**
   * @Author: YuKi
   * @description: 新增/编辑弹窗->上传文件前的勾子
   * @param {any} option
   * @return {*}
   */
  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / Math.pow(1024, 2) < 80;
    if (!isLt2M) {
      message.error(t('prodSuggestion.addProdSuggestion.fileSizeLimit'));
      return Upload.LIST_IGNORE;
    }
    return isLt2M;
  };

  /**
   * @Author: YuKi
   * @description: 自定义自己图片的上传实现
   * @param {any} options
   * @return {*}
   */
  const handleUpload = (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append('blobContainerName', 'FILES');
    formData.append('fileList', options.file);
    let fileList: any = [{}];
    fileUploadARMS(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          fileList[0].status = 'done';
          fileList[0].url = config.downUrl + `${res.data[0].blobContainerName}/${res.data[0].fileSaveName}`;
          fileList[0].fileName = res.data[0].fileSaveName;
          fileList[0].fileSaveName = res.data[0].fileSaveName;
          fileList[0].blobContainerName = res.data[0].blobContainerName;
          fileList[0].size = res.data[0].fileSize;

          onSuccess(fileList);
        } else {
          onError('上传失败');
        }
      })
      .catch((error) => {
        onError('上传失败');
      });
  };

  /**
   * @Author: YuKi
   * @description:  下载或预览客诉附件
   * @param {any} fileItem
   * @return {*}
   */
  const downloadOrPreviewAttachments = (fileItem: any) => {
    setFileItem(fileItem);
    setViewOpen(true);
  };
  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: YuKi
   */
  const handleCancePreview = () => {
    setViewOpen(false);
  };

  const isUpdateEightFile = () => {
    if (operateFormType === 'NoFormFinish') {
      return true;
    } else if (isOperate) {
      return false;
    } else if (isEditEightReport) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData?.data?.currentStatusShowName}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHandle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHandle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ||
            (isEditEightReport && operateFormType === 'ToHandleWithEight') ||
            operateFormType == 'NoFormFinish' ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form layout="vertical" form={addForm} autoComplete="off">
          {/* 问题初步描述 */}
          <Form.Item
            label={t('complaintList.problem_description')}
            name="reason"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <TextArea
              disabled={nodeData || operateFormType === 'ToHandleWithEight' || operateFormType == 'NoFormFinish'}
              autoSize={{ minRows: 3, maxRows: 5 }}
              showCount
              maxLength={1024}
            />
          </Form.Item>
          {/* 临时解决方案 */}
          <Form.Item
            label={t('complaintList.temporary_solution')}
            name="solution"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <TextArea
              disabled={nodeData || operateFormType === 'ToHandleWithEight' || operateFormType == 'NoFormFinish'}
              autoSize={{ minRows: 3, maxRows: 5 }}
              showCount
              maxLength={1024}
            />
          </Form.Item>

          {/* 方案上线时间 */}
          <Form.Item label={t('complaintList.plan_launch_time')} name="dateInfo" rules={[{ required: true }]}>
            <DatePicker
              disabled={nodeData || operateFormType === 'ToHandleWithEight' || operateFormType == 'NoFormFinish'}
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
            />
          </Form.Item>

          {/* 附件列表 */}
          {nodeData || operateFormType === 'ToHandleWithEight' || operateFormType == 'NoFormFinish' ? (
            fileLists && fileLists.length > 0 ? (
              <Row gutter={8}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item label={<span>{t('complaintList.attachment')}</span>}>
                    {fileLists.map((item: any, index) => {
                      return (
                        <div>
                          <a onClick={() => downloadOrPreviewAttachments(item)}>{item.fileName}</a>
                        </div>
                      );
                    })}
                  </Form.Item>
                </Col>
              </Row>
            ) : null
          ) : (
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
                <Form.Item
                  label={
                    <Tooltip title={t('complaintList.tips')}>
                      <span>
                        {t('complaintList.attachment')} <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  name="amsFileList"
                  valuePropName="fileList"
                >
                  <FileUpload onFileListChange={handleFileListChange} maxNum={5} />
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* 8D附件 */}
          {operateFormType === 'ToHandleWithEight' || operateFormType == 'NoFormFinish' ? (
            <Form.Item
              label={t('complaintList.8d_attachment')}
              name="eightFile"
              valuePropName="fileList"
              rules={[{ required: true }]}
              getValueFromEvent={normFile}
            >
              <Upload
                name="eightFile1"
                className="avatar-uploader"
                maxCount={1}
                customRequest={(option) => handleUpload(option)}
                beforeUpload={beforeUpload}
                showUploadList={{
                  showRemoveIcon: false,
                  showDownloadIcon: true,
                }}
                onDownload={(file: any) => downloadOrPreviewAttachments(file.response[0])}
              >
                <Button disabled={isUpdateEightFile()}>{t('complaintList.chooseFile')}</Button>
              </Upload>
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
      {viewOpen ? <PreViewModal onClosePreview={handleCancePreview} fileData={fileItem} /> : null}
    </>
  );
};

export default memo(ExpertHandleModal);
