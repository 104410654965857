// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-style {
  width: 80%;
  height: 400px;
  margin: 0 auto 15px;
}
.custom-contant .ant-card {
  margin-top: 20px;
  min-height: 100px;
}
.chart-container {
  width: 100%;
  height: 400px;
  margin: 0 auto 15px;
}
.user-group-card {
  margin-top: 10px;
}
.flex-style {
  display: flex;
  justify-content: space-between;
}
.export-position {
  position: absolute !important;
  top: -58px;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ReportCenter/index.less"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;AACF;AACA;EAEI,gBAAA;EACA,iBAAA;AAAJ;AAGA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AADF;AAGA;EACE,gBAAA;AADF;AAGA;EACE,aAAA;EACA,8BAAA;AADF;AAGA;EACE,6BAAA;EACA,UAAA;EACA,QAAA;AADF","sourcesContent":[".chart-style {\n  width: 80%;\n  height: 400px;\n  margin: 0 auto 15px;\n}\n.custom-contant {\n  .ant-card {\n    margin-top: 20px;\n    min-height: 100px;\n  }\n}\n.chart-container {\n  width: 100%;\n  height: 400px;\n  margin: 0 auto 15px;\n}\n.user-group-card {\n  margin-top: 10px;\n}\n.flex-style {\n  display: flex;\n  justify-content: space-between;\n}\n.export-position {\n  position: absolute !important;\n  top: -58px;\n  right: 0;\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
