/*
 * @Description: SN 编码规则
 * @Author: Summer.Chen
 * @Date: 2024-04-08 16:16:43
 * @LastEditTime: 2024-04-25 09:27:08
 * @LastEditors: Summer.Chen
 */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Row, Col, Input, Space, Button, Table, Popconfirm, Modal, Radio, RadioChangeEvent, message } from 'antd';
import { setCol } from '../../config';
import { useTranslation } from 'react-i18next';
import { querySnCodeAuthByDeptId, addSnCodeAuth, delSNCodeRulesOrInverterModel } from '@/api/authorityManagement';
let snCode = '';
const SNCodingrules: React.FC<any> = forwardRef((props, ref): React.ReactElement => {
  //父组件调取子组件方法
  useImperativeHandle(ref, () => ({
    refHandler,
  }));
  const refHandler = (activeKey: string) => {
    if (activeKey === '2') {
      if (SNRulesType === 1 || SNRulesType === 3) {
        formFinish({});
      }
    }
  };
  //是否显示控制范围
  const [isShowCheckRadio, setIsShowCheckRadio] = useState(true);
  let { item, open, type, activeKey, radioChange } = props;
  const [SNRulesType, setSNRulesType] = useState(1);
  //创建表单
  const [form] = Form.useForm();
  //创建搜索表单
  const [formSearch] = Form.useForm();
  //表格数据
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  //表头及定义action
  const columns = [
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      render: (text: string, record: any, index: number) => {
        return (page.pageIndex - 1) * page.pageSize + (index + 1);
      },
    },
    {
      title: t('upsModeConfig.snCodingRules'),
      dataIndex: 'authName',
      key: 'v',
    },
    {
      title: t('common.operation'),
      dataIndex: 'username',
      key: 'username',
      render: (text: string, record: any) => {
        return (
          <Popconfirm
            placement="rightTop"
            title={t('authorityManagement.areYouSureYouWantToDeleteThisRecord')}
            onConfirm={() => {
              confirm(record);
            }}
            okText={t('common.confirmBtn')}
            cancelButtonProps={{ shape: 'round' }}
            okButtonProps={{ shape: 'round' }}
            cancelText={t('common.cancelBtn')}
          >
            <Button type="text" danger disabled={type === 1}>
              {t('common.deleteBtn')}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];
  // 删除确认回调
  const confirm = (record) => {
    delSNCodeRulesOrInverterModel(record).then((rep) => {
      if (rep && rep.code === 200) {
        message.success(t('common.operateSuccess'));
        if (tableData.length === 1 && page.pageIndex > 1) {
          setPage({
            ...page,
            pageIndex: page.pageIndex - 1,
          });
        } else {
          initData();
        }
        setIsOpen(false);
      }
    });
  };
  // 分页
  const [page, setPage] = useState({ pageIndex: 1, pageSize: 10, total: 1 });
  const [tableLoading, setTableLoading] = useState(false);
  const pageChange = (current: number) => {
    setPage({
      ...page,
      pageIndex: current,
    });
  };
  //内部弹框控制
  const [isOpen, setIsOpen] = useState(false);
  //内部弹框确认
  const handleSNOk = () => {
    form.submit();
  };
  //内部弹框取消
  const handleSNCancel = () => {
    form.setFieldValue('snCodeRule', '');

    setIsOpen(false);
  };
  //范围选择
  const radioOnChange = (event: RadioChangeEvent) => {
    setSNRulesType(event.target.value);
  };
  const formFinish = (values) => {
    addSnCodeAuth({
      deptId: item.deptId,
      authCategory: SNRulesType,
      snCodeRule: values.snCodeRule ? values.snCodeRule.trim() : null,
    }).then((rep) => {
      if (rep && rep.code === 200) {
        message.success(t('common.operateSuccess'));
        //重置值
        snCode = '';
        formSearch.setFieldValue('snCode', '');
        // 区分目前的在全部还是指定
        if (page.pageIndex === 1) {
          initData();
        } else {
          setPage({
            ...page,
            pageIndex: 1,
          });
        }

        handleSNCancel();
      }
    });
  };
  const initData = () => {
    setTableLoading(true);
    querySnCodeAuthByDeptId({ deptId: item.deptId, ...page, snCode: formSearch.getFieldValue('snCode') })
      .then((rep: any) => {
        //初始化值
        if (rep && rep.data) {
          setIsShowCheckRadio(Boolean(rep.data.isViewChoose));
        }
        if (rep && rep.code == 200 && rep.data && rep.data.sysDeptAuthList && rep.data.sysDeptAuthList.rows) {
          setTableData((rep.data.sysDeptAuthList.rows || []).filter((item) => item.authCategory === 2));
          setSNRulesType(
            rep.data.sysDeptAuthList.rows.some((item) => item.authCategory === 2) || SNRulesType === 2 ? 2 : 1,
          );
          setPage({
            ...page,
            total: rep.data.sysDeptAuthList.total ?? 1,
          });
        } else {
          setTableData([]);
          setSNRulesType(1);
        }
        if (rep.data && !Boolean(rep.data.isViewChoose)) {
          setSNRulesType(2);
        } else if (
          rep.code !== 200 ||
          !rep.data.sysDeptAuthList ||
          !rep.data.sysDeptAuthList.rows ||
          !rep.data.sysDeptAuthList.rows.length
        ) {
          setSNRulesType(3);
        }
        setTableLoading(false);
      })
      .catch((error) => {
        setSNRulesType(3);
      });
  };
  useEffect(() => {
    if (activeKey !== '2') {
      return;
    }
    if (!item.deptId) {
      return;
    }
    if (!open) {
      setSNRulesType(3);
      formSearch.setFieldValue('snCode', '');
      return;
    }
    snCode = '';
    initData();
  }, [open, page.pageIndex, activeKey]);

  //设置全部还是指定
  useEffect(() => {
    if (SNRulesType === 1 || SNRulesType === 3) {
      radioChange(true);
    } else if (SNRulesType === 2) {
      radioChange(false);
    }
  }, [SNRulesType]);
  return (
    <section>
      <Modal
        title={t('authorityManagement.addedSNEncodingRules')}
        open={isOpen}
        onOk={handleSNOk}
        destroyOnClose
        cancelButtonProps={{ shape: 'round' }}
        okButtonProps={{ shape: 'round' }}
        onCancel={handleSNCancel}
        okText={t('common.confirmBtn')}
        cancelText={t('common.cancelBtn')}
      >
        <Form layout={'vertical'} form={form} onFinish={formFinish} initialValues={{ snCodeRule: '' }}>
          <Form.Item
            label={t('upsModeConfig.snCodingRules')}
            name="snCodeRule"
            rules={[
              {
                required: true,
                // message: t('upsModeConfig.pleaseInputSnCodeRule') as string,
              },
              ({}) => ({
                validator(_, value) {
                  if (value && (value.trim() === '' || value.trim().length < 2 || value.trim().length > 7)) {
                    return Promise.reject(new Error(t('upsModeConfig.pleaseInputSnCodeRule') as string));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              allowClear
              placeholder={t('upsModeConfig.pleaseInputSnCodeRule') as string}
              minLength={2}
              maxLength={7}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Row gutter={[30, 30]}>
        {isShowCheckRadio ? (
          <Col span={24}>
            <div className="ant-form-item-label" style={{ marginBottom: '13px' }}>
              {t('authorityManagement.selectionRange')}
            </div>
            <br />
            <Radio.Group onChange={radioOnChange} value={SNRulesType} disabled={type === 1}>
              <Radio value={1}>{t('common.all')}</Radio>
              <Radio value={2}>{t('authorityManagement.specify')}</Radio>
              <Radio value={3}>{t('authorityManagement.nothing')}</Radio>
            </Radio.Group>
          </Col>
        ) : null}

        {SNRulesType === 2 ? (
          <>
            <Col span={24}>
              <Form layout={'vertical'} form={formSearch}>
                <Row gutter={15}>
                  <Col {...setCol({ sm: 24, xs: 24, md: 10, lg: 10 })}>
                    <Form.Item label={t('upsModeConfig.snCodingRules')} name="snCode">
                      <Input
                        placeholder={t('upsModeConfig.pleaseInputSnCodeRule') as string}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          snCode = event.target.value;
                        }}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col {...setCol({ sm: 24, xs: 24, md: 6, lg: 6 })}>
                    <Form.Item label={<span>&nbsp;</span>} name="area">
                      <Space>
                        <Button
                          shape="round"
                          type="primary"
                          onClick={() => {
                            if (page.pageIndex === 1) {
                              initData();
                            } else {
                              setPage({
                                ...page,
                                pageIndex: 1,
                              });
                            }
                          }}
                        >
                          {t('common.searchBtn')}
                        </Button>
                        <Button
                          shape="round"
                          type="primary"
                          disabled={type === 1}
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          {t('common.addBtn')}
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={24}>
              <Table
                dataSource={tableData}
                size="small"
                columns={columns}
                loading={tableLoading}
                rowKey={(record: any) => record.key}
                pagination={{
                  showQuickJumper: true,
                  defaultCurrent: 1,
                  current: page.pageIndex,
                  total: page.total,
                  pageSize: page.pageSize,
                  onChange: pageChange,
                }}
                className="custom-table-flex"
              />
            </Col>
          </>
        ) : null}
      </Row>
    </section>
  );
});

export default SNCodingrules;
