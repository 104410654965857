/*
 * @Description:敏感词维护
 * @Author: Stephen
 * @Date: 2025/01/15
 */
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useSetState } from 'ahooks';
import { Button, Col, Form, Input, Modal, Row, Select, Space, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import {
  addSensitiveWord,
  delSensitiveWord,
  editSensitiveWord,
  getSensitiveWord,
} from '@/api/sensitiveWordMaintenance';
import { inputValueLimit } from '@/constants/inputValueLimit';

/** 弹窗状态 */
interface IModalState {
  /** 是否打开弹窗 */
  open: boolean;
  /** 主键 */
  id?: number | undefined;
  /** 标题 */
  title?: 'addBtn' | 'editBtn' | 'viewBtn';
}
/** 查询表单 */
interface ISearchForm {
  /** 类型 */
  type?: number;
  /** 内容 */
  word?: string;
}
/** 新增/编辑表单 */
interface ISubmitForm {
  /** 类型 */
  type?: number;
  /** 内容 */
  words?: string[];
}
/** 分页参数类型 */
interface IPageRecord {
  /** 每页条数 */
  pageSize: number;
  /** 当前页 */
  pageIndex: number;
  /** 总条数 */
  total: number;
}
/** 敏感词分页数据请求参数 */
interface IGetSensitiveWordPageParams {
  /** 类型: 0--软屏蔽，1--硬屏蔽 */
  type?: number;
  /** 内容 */
  word?: string;
  /** 当前页 */
  pageIndex?: number;
  /** 每页条数 */
  pageSize?: number;
}
/** 敏感词分页响应数据 */
export interface IGetSensitiveWordPageRes {
  /** 类型 */
  type?: number;
  /** 内容 */
  word?: string;
  /** 主键 */
  id?: number;
  /** 创建时间 */
  createTime?: string;
  /** 创建人 */
  createAccount?: string;
}
let searchParams: IGetSensitiveWordPageParams = { pageIndex: 1, pageSize: 10 };

const SensitiveWordMaintenance = () => {
  const { t } = useTranslation('');
  const [searchForm] = useForm<ISearchForm>();
  const [submitForm] = useForm<ISubmitForm>();
  const [modalState, setModalState] = useSetState<IModalState>({ open: false });
  const [pageRecord, setPageRecord] = useSetState<IPageRecord>({ pageSize: 10, pageIndex: 1, total: 0 });
  const [data, setData] = useState<IGetSensitiveWordPageRes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { confirm } = Modal;

  /**
   * @Author: Stephen
   * @description: 获取敏感词分页数据
   * @return {*}
   */
  const getSensitiveWordList = () => {
    setLoading(true);
    getSensitiveWord(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data && rep.data.rows) {
          setData([...rep.data.rows]);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPageRecord({ ...paging });
        } else {
          setData([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPageRecord({ ...pagingInit });
        }
        setLoading(false);
      })
      .catch((err) => {
        setData([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPageRecord({ ...pagingInit });
        setLoading(false);
      });
  };

  useEffect(() => {
    getSensitiveWordList();
  }, []);

  /** 分页参数改变处理函数 */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams.pageIndex = currentPage;
    searchParams.pageSize = currentSize;
    getSensitiveWordList();
  }, []);

  /**
   * @Author: Stephen
   * @description: 关闭弹窗
   */
  const handleCancel = () => {
    setModalState({ open: false });
    submitForm.resetFields();
  };

  /**
   * @Author: Stephen
   * @description: 查询
   * @param {IGetSensitiveWordPageParams} value
   */
  const handleSearch = (value: IGetSensitiveWordPageParams) => {
    searchParams = { ...value, pageIndex: 1, pageSize: 10 };
    getSensitiveWordList();
  };

  /**
   * @Author: Stephen
   * @description: 保存新增或编辑
   * @param {ISubmitForm} value
   */
  const handleSave = (value: ISubmitForm) => {
    modalState.id
      ? editSensitiveWord({ type: value.type, word: value.words?.[0], id: modalState.id }).then((res: any) => {
          if (res && res.code === 200) {
            message.success(t('common.saveSuccess'));
            handleCancel();
            getSensitiveWordList();
          }
        })
      : addSensitiveWord(value).then((res: any) => {
          if (res && res.code === 200) {
            message.success(t('common.saveSuccess'));
            handleCancel();
            searchParams.pageIndex = 1;
            getSensitiveWordList();
          }
        });
  };

  /**
   * @Author: Stephen
   * @description: 点击删除事件
   * @param {number} id
   */
  const handleDelete = (id: number) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      content: t('common.deleteData'),
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      async onOk() {
        await delSensitiveWord(id);
        message.success(t('common.deleteSuccess'));
        getSensitiveWordList();
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  const columns: ColumnsType<IGetSensitiveWordPageRes> = [
    /** 序号 */
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      align: 'center',
      width: 160,
      render: (_, __, index) => {
        return index + (pageRecord.pageIndex! - 1) * pageRecord.pageSize + 1;
      },
    },
    /** 类型： 0--软屏蔽，1--硬屏蔽 */
    {
      title: t('sensitiveWordMaintenance.type'),
      width: 160,
      dataIndex: 'type',
      render: (type: number) => {
        return type === 0 ? t('sensitiveWordMaintenance.softShielding') : t('sensitiveWordMaintenance.hardShielding');
      },
    },
    /** 内容 */
    {
      title: t('sensitiveWordMaintenance.content'),
      width: 200,
      dataIndex: 'word',
      ellipsis: true,
    },
    /** 创建时间 */
    {
      title: t('common.createTime'),
      width: 160,
      dataIndex: 'createTime',
    },
    /** 创建人 */
    {
      title: t('common.creator'),
      width: 160,
      dataIndex: 'createAccount',
    },
    /** 操作 */
    {
      title: t('common.operation'),
      width: 160,
      align: 'center',
      render: (_, record) => {
        return (
          <Space size="middle" wrap className="sn-management-space">
            {/* 查看 */}
            <a
              onClick={() => {
                setModalState({ open: true, title: 'viewBtn' });
                submitForm.setFieldsValue({ type: record.type, words: [record.word ?? ''] });
              }}
            >
              {t('common.viewBtn')}
            </a>
            {/* 编辑 */}
            <a
              onClick={() => {
                setModalState({ open: true, title: 'editBtn', id: record.id });
                submitForm.setFieldsValue({ type: record.type, words: [record.word ?? ''] });
              }}
            >
              {t('common.editBtn')}
            </a>
            {/* 删除 */}
            <a onClick={() => handleDelete(record.id!)} style={{ color: 'red' }}>
              {t('common.deleteBtn')}
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Form form={searchForm} layout="vertical" onFinish={handleSearch}>
        <Row gutter={16} align="bottom">
          {/* 类型 */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item label={t('sensitiveWordMaintenance.type')} name="type">
              <Select
                options={[
                  // 软屏蔽
                  { label: t('sensitiveWordMaintenance.softShielding'), value: 0 },
                  // 硬屏蔽
                  { label: t('sensitiveWordMaintenance.hardShielding'), value: 1 },
                ]}
                placeholder={t('common.pleaseChoose')}
                allowClear
              />
            </Form.Item>
          </Col>
          {/* 内容 */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item label={t('sensitiveWordMaintenance.content')} name="word">
              <Input placeholder={`${t('common.pleaseEnter')}`} allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Space>
              {/* 查询 */}
              <Form.Item>
                <Button type="primary" shape="round" htmlType="submit">
                  {t('common.searchBtn')}
                </Button>
              </Form.Item>
              {/* 新增 */}
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => setModalState({ open: true, title: 'addBtn', id: undefined })}
                >
                  {t('common.addBtn')}
                </Button>
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        rowKey="id"
        scroll={{ x: 1000 }}
        dataSource={data}
        loading={loading}
        pagination={{
          current: searchParams.pageIndex,
          pageSize: searchParams.pageSize,
          total: pageRecord.total,
          onChange: pageChange,
        }}
      />
      <Modal
        open={modalState.open}
        title={t(`common.${modalState.title}`)}
        maskClosable={false}
        onCancel={handleCancel}
        okText={t('common.saveBtn')}
        cancelText={t('common.cancelBtn')}
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
        onOk={() => submitForm.validateFields().then(handleSave)}
        footer={
          <>
            <Button shape="round" onClick={handleCancel}>
              {t('common.cancelBtn')}
            </Button>
            {modalState.title !== 'viewBtn' && (
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                onClick={() => submitForm.validateFields().then(handleSave)}
              >
                {t('common.saveBtn')}
              </Button>
            )}
          </>
        }
      >
        <Form form={submitForm} layout="vertical" initialValues={{ words: [undefined] }}>
          {/* 类型 */}
          <Form.Item
            label={t('sensitiveWordMaintenance.type')}
            name="type"
            rules={[{ required: true, message: `${t('sensitiveWordMaintenance.type')}${t('common.required')}` }]}
          >
            <Select
              options={[
                // 软屏蔽
                { label: t('sensitiveWordMaintenance.softShielding'), value: 0 },
                // 硬屏蔽
                { label: t('sensitiveWordMaintenance.hardShielding'), value: 1 },
              ]}
              placeholder={t('common.pleaseChoose')}
              allowClear
              disabled={modalState.title === 'viewBtn'}
            />
          </Form.Item>
          {/* 内容 */}
          <Form.List name="words">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div
                    key={field.key}
                    style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '12px', gap: '12px' }}
                  >
                    <Form.Item
                      {...field}
                      label={index === 0 ? t('sensitiveWordMaintenance.content') : ''}
                      style={{ width: '100%', marginBottom: '12px' }}
                      rules={[
                        {
                          required: true,
                          message: `${t('sensitiveWordMaintenance.content')}${t('common.required')}`,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`${t('common.pleaseEnter')}`}
                        disabled={modalState.title === 'viewBtn'}
                        maxLength={inputValueLimit.maxInputValueLeng}
                      />
                    </Form.Item>
                    {modalState.title === 'addBtn' && (
                      <Form.Item style={{ marginBottom: '12px' }}>
                        {index === 0 ? (
                          <Button type="primary" shape="circle" onClick={() => add()} icon={<PlusOutlined />} />
                        ) : (
                          <Button
                            type="primary"
                            shape="circle"
                            onClick={() => remove(field.name)}
                            icon={<MinusOutlined />}
                          />
                        )}
                      </Form.Item>
                    )}
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default SensitiveWordMaintenance;
