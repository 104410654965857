/* 配置管理->消息发布管理 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message, Select, DatePicker, Collapse } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { RootStateOrAny, useSelector } from 'react-redux';
import { config } from '@/constants/config';
import {
  delMsgConfiguration,
  editMsgConfiguration,
  getMsgConfigurationDetail,
  getMsgConfigurationPage,
  getMsgType,
} from '@/api/configurationManagement/pushMessageMag';
import moment from 'moment';
const { Option } = Select;

/* 列表字段类型定义 */
type GithubIssueItem = {
  /** id */
  id: string;
  /** 内容 */
  lgContent: string;
  /** 类型 */
  typeContent: string;
  /** 创建人 */
  createAccount: string;
  /** 创建时间 */
  createDatetime: string;
};

const { Panel } = Collapse;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

type IAddOrEditItem = {
  /** 语言code */
  lgCode: string;
  /** 语言名称 */
  content: string;
};
type IAddOrEditParams = {
  lgList: IAddOrEditItem[];
  messageType: string;
  id?: string;
  caseStatus: string;
};
const initLanguage: IAddOrEditItem[] = [];
config.languageList?.forEach((item) => {
  initLanguage.push({
    lgCode: item.value,
    content: '',
  });
});
let editId = '';
/* 列表查询参数 */
let searchParams = {
  pageSize: 10 /* 每页显示条数 */,
  pageIndex: 1 /* 当前页 */,
  content: '' /* 逆变器规格 */,
  createBegin: '' /* 逆变器规格 */,
  createEnd: '' /* 逆变器规格 */,
};
const ItalyConnectRuleConfig: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  /* 分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 列表源数据 */
  const [dataList, setDataList] = useState<GithubIssueItem[]>([]);
  /* table的loading */
  const [tableLoading, setTableLoading] = useState(false);
  /* 新增编辑弹窗打开关闭flag */
  const [isAEOpen, setIsAEOpen] = useState(false);
  /* 安规列表 */
  const [safeList, setSafeList] = useState<any>([]);
  /* 编辑弹窗的ref */
  const [aEForm] = Form.useForm();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const [activeCollapse, setActiveCollapse] = useState<string | string[]>(['0']);
  /* 新增编辑弹窗-> 区分新增编辑标志*/
  const [addOrEdit, setAddOrEdit] = useState<string>('');
  /* 列表字段 */
  const columns: ColumnsType<GithubIssueItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      key: 'serialNumber',
      width: language === 'zh' || language === 'de' || language === 'ja' ? 90 : 180,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 内容 */
    {
      title: t('notificationContentTemplateManagement.content'),
      dataIndex: 'lgContent',
      width: 500,
      align: 'center',
      ellipsis: true,
    },
    /* 类型 */
    {
      title: t('common.type'),
      dataIndex: 'typeContent',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /* 客诉状态 */
    {
      title: t('complaintList.complaintStatus'),
      dataIndex: 'caseStatus',
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (_, record: any) => [
        <Space key="status">
          {record.caseStatus == 'CREATED' ? <span>{t('complaintList.created')}</span> : null}
          {record.caseStatus == 'PROCESSING' ? <span>{t('complaintList.customerComplaintProcessing')}</span> : null}
          {record.caseStatus == 'ARRANGED_VISIT' ? <span>{t('complaintList.haveDoorToDoorProcessing')}</span> : null}
          {record.caseStatus == 'ARRANGED_SPARE_PARTS' ? <span>{t('complaintList.hasBeenArranged')}</span> : null}
          {record.caseStatus == 'DELIVERY' ? <span>{t('complaintList.lab_to_shipped')}</span> : null}
          {record.caseStatus == 'SHIPPED' ? <span>{t('complaintList.lab_has_Shipped')}</span> : null}
          {record.caseStatus == 'APPOINTMENT_VISIT' ? <span>{t('complaintList.makeVisitHome')}</span> : null}
          {record.caseStatus == 'CLOSED' ? <span>{t('complaintList.close')}</span> : null}
          {record.caseStatus == 'COMPLAINT_EVALUATE' ? <span>{t('complaintList.evaluateTip')}</span> : null}
        </Space>,
      ],
    },
    /* 创建人 */
    {
      title: t('common.creator'),
      dataIndex: 'createAccount',
      width: 120,
      align: 'center',
      ellipsis: true,
    },
    /* 创建时间 */
    {
      title: t('common.createTime'),
      dataIndex: 'createDatetime',
      width: 180,
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      key: 'option',
      align: 'center',
      width: 240,
      render: (_, record) => [
        <Space size="middle" key="space" wrap>
          {/* 查看 */}
          <a key="view" onClick={() => aEButton('view', record)} style={{ color: '#20b965' }}>
            {t('common.viewBtn')}
          </a>
          {/* 编辑 */}
          <a key="edit" onClick={() => aEButton('edit', record)}>
            {t('common.editBtn')}
          </a>
          {/* 删除 */}
          <a key="delete" className="delete-text" onClick={() => deleteClick(record.id)}>
            {t('common.deleteBtn')}
          </a>
        </Space>,
      ],
    },
  ];

  /**
   * @Author: XuAnjie
   * @description: 获取列表
   * @return {*}
   */
  const getMsgConfigurationPages = () => {
    setTableLoading(true);
    getMsgConfigurationPage(searchParams)
      .then((rep: any) => {
        if (rep && rep.code == 200 && rep.data.rows) {
          setDataList(rep.data.rows);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
          setTableLoading(false);
        } else {
          setDataList([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pagingInit });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setDataList([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pagingInit });
        setTableLoading(false);
      });
  };

  /* 获取类型源数据 */
  const getMsgTypes = () => {
    getMsgType().then((rep: any) => {
      if (rep.code == 200) {
        setSafeList(rep.data);
      }
    });
  };
  /**
   * @Author: XuAnjie
   * @description: 根据分页，初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    searchParams = {
      pageSize: 10 /* 每页显示条数 */,
      pageIndex: 1 /* 当前页 */,
      content: '' /* 逆变器规格 */,
      createBegin: '' /* 逆变器规格 */,
      createEnd: '' /* 逆变器规格 */,
    };
    getMsgConfigurationPages();
  }, []);

  /* 切换语言回调 */
  useEffect(() => {
    getMsgConfigurationPages();
  }, [language]);
  /**
   * @Author: XuAnjie
   * @description: 分页的回调函数
   * @param {Number} currentPage 当前页
   * @param {Number} currentSize 页的总条数
   * @return {*}
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getMsgConfigurationPages();
  }, []);

  /**
   * @Author: XuAnjie
   * @description:查询列表
   * @param {Object} form 表单
   * @return {*}
   */
  const search = (values) => {
    const { createTime, ...remainingProps } = values;
    searchParams = {
      ...remainingProps,
      pageIndex: 1,
      pageSize: 10,
      createBegin: values.createTime && values.createTime[0] ? moment(values.createTime[0]).format('YYYY-MM-DD') : '',
      createEnd: values.createTime && values.createTime[1] ? moment(values.createTime[1]).format('YYYY-MM-DD') : '',
    };
    getMsgConfigurationPages();
  };

  /**
   * @Author: XuAnjie
   * @description: 删除
   * @param {string} id 序号
   * @return {*}
   */
  const deleteClick = (id) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteData'),
      onOk() {
        delMsgConfiguration(id).then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('common.operateSuccess')); /* 操作成功 */
            getMsgConfigurationPages();
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  /* 获取详情 */
  const getMsgConfigurationDetails = (id: string) => {
    getMsgConfigurationDetail(id).then((rep: any) => {
      if (rep.code == 200 && rep.data) {
        aEForm.setFieldsValue(rep.data);
        rep.data.lgList.forEach((item) => {
          aEForm.setFieldValue(item.lgCode + 'content', item.content);
        });
      }
    });
  };

  /**
   * @Author: XuAnjie
   * @description: 新增/编辑弹窗->打开
   * @param {String} flag 'add':新增，'edit':编辑
   * @param {*} record 编辑的行数据
   * @return {*}
   */
  const aEButton = (flag, record?) => {
    setAddOrEdit(flag);
    getMsgTypes();
    if (flag == 'view') {
      getMsgConfigurationDetails(record.id);
    } else if (flag == 'edit') {
      editId = record.id;
      getMsgConfigurationDetails(record.id);
    }
    setIsAEOpen(true);
  };

  /**
   * @description: 手风琴切换
   * @param {string} key
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const onCollapseChange = (key: string | string[]) => {
    setActiveCollapse(key);
  };

  /* 保存 */
  const handleFinish = (data) => {
    let noticeLists: IAddOrEditItem[] = [];
    noticeLists = initLanguage;
    noticeLists?.map((item) => {
      item.content = data[item.lgCode + 'content'] ? data[item.lgCode + 'content'] : '';
    });

    if (validLgContent(noticeLists)) {
      return false;
    }
    const params: IAddOrEditParams = {
      lgList: noticeLists,
      messageType: data.messageType,
      caseStatus: data.caseStatus,
    };
    if (addOrEdit === 'edit') {
      params.id = editId;
    }
    editMsgConfiguration(params).then((rep: any) => {
      if (rep.code == 200) {
        message.success(t('common.operateSuccess')); /* 操作成功 */
        handleCancel();
        getMsgConfigurationPages();
      }
    });
  };

  /* 关闭弹窗 */
  const handleCancel = () => {
    aEForm.resetFields();
    setIsAEOpen(false);
  };

  /* 校验多语言内容必填 */
  const validLgContent = (arr) => {
    let flag = false;
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element.content === '' || element.content === undefined) {
        message.error(t('pushMessageMag.multilingualContentRequired'));
        flag = true;
        setActiveCollapse([index.toString()]);
        break;
      }
    }
    return flag;
  };

  return (
    /*客户列表*/
    <>
      <div className="custom-contant">
        <Form
          form={searchForm}
          name="search_form"
          layout="vertical"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12} align="bottom">
            {/* 日期 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="createTime" label={t('common.startDate') + '-' + t('common.endDate')}>
                <RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            {/* 内容 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="content" label={t('notificationContentTemplateManagement.content')}>
                <Input
                  allowClear
                  placeholder={`${t('notificationContentTemplateManagement.content')}`}
                  maxLength={256}
                />
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={12} sm={12} md={6} lg={6} xl={4} className="padding-tope-style">
              <Form.Item>
                <Space size="middle" wrap>
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {/* 新增 */}
                  <Button type="primary" key="add" shape="round" onClick={() => aEButton('add')}>
                    {t('common.addBtn')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          loading={tableLoading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 1120, y: 'calc(100vh - 230px)' }}
          className="custom-table-flex auto-table"
        />
      </div>
      {/* 新增编辑弹窗 */}
      <Modal
        title={addOrEdit === 'add' ? t('common.addBtn') : t('common.editBtn')}
        open={isAEOpen}
        onCancel={handleCancel}
        width="60%"
        maskClosable={false}
        footer={[
          /* 提交 */
          <Row gutter={12} justify="end">
            <Button shape="round" onClick={handleCancel}>
              {t('common.cancelBtn')}
            </Button>
            {!Boolean(addOrEdit === 'view') && (
              <Button
                key="submit"
                shape="round"
                type="primary"
                onClick={() => aEForm.validateFields().then(handleFinish)}
              >
                {t('common.submitBtn')}
              </Button>
            )}
          </Row>,
        ]}
        forceRender
      >
        <Form
          name="aEForm"
          autoComplete="off"
          layout="vertical"
          form={aEForm}
          onFinish={handleFinish}
          disabled={addOrEdit === 'view'}
        >
          <Row gutter={12}>
            {/* 类型 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="messageType"
                label={t('common.type')}
                rules={[{ required: true, message: `${t('common.type')}${t('archivalMaintenance.parmasRequired')}` }]}
              >
                <Select
                  placeholder={t('common.pleaseChoose')}
                  options={safeList?.map((it) => ({ value: it.msgType, label: it.lgContent }))}
                />
              </Form.Item>
            </Col>
            {/* 客诉状态 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="caseStatus"
                label={t('complaintList.complaintStatus')}
                rules={[
                  {
                    required: true,
                    message: `${t('complaintList.complaintStatus')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
              >
                <Select placeholder={t('common.pleaseChoose')} allowClear>
                  <Option value="CREATED">{t('complaintList.created')}</Option>
                  <Option value="PROCESSING">{t('complaintList.customerComplaintProcessing')}</Option>
                  <Option value="ARRANGED_VISIT">{t('complaintList.haveDoorToDoorProcessing')}</Option>
                  <Option value="ARRANGED_SPARE_PARTS">{t('complaintList.hasBeenArranged')}</Option>
                  <Option value="DELIVERY">{t('complaintList.lab_to_shipped')}</Option>
                  <Option value="SHIPPED">{t('complaintList.lab_has_Shipped')}</Option>
                  <Option value="APPOINTMENT_VISIT">{t('complaintList.makeVisitHome')}</Option>
                  <Option value="CLOSED">{t('complaintList.close')}</Option>
                  <Option value="COMPLAINT_EVALUATE">{t('complaintList.evaluateTip')}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ backgroundColor: 'white' }}>
              <Collapse
                activeKey={activeCollapse}
                expandIconPosition="end"
                bordered={false}
                onChange={onCollapseChange}
                style={{ backgroundColor: 'white' }}
                accordion={true}
              >
                {config.languageList.map((item, index) => {
                  return (
                    <Panel header={item.label} key={String(index)} forceRender={true}>
                      <Form.Item name={item.value + 'content'}>
                        <TextArea
                          placeholder={`${t('common.pleaseEnter')}`}
                          allowClear
                          maxLength={1024}
                          showCount={true}
                        />
                      </Form.Item>
                    </Panel>
                  );
                })}
              </Collapse>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ItalyConnectRuleConfig);
