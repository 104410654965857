/*
 * @Description: 客户反馈->客诉留言组件
 * @Author: XuAnjie
 * @Date: 2023-10-08 13:54:40
 * @LastEditors: XuAnjie
 * @LastEditTime: 2024-04-16 15:50:49
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Comment, Button, Form, message, Upload, UploadProps, UploadFile, Tooltip, Modal, List, Row } from 'antd';
import { fileDownload, fileUploadARMS } from '@/api/common';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { getComment, replyComment, submitComment } from '@/api/clientFeedback/complaintsList';
import { Editor } from '@tinymce/tinymce-react';
import { blobDownloadFile } from '@/constants/common';
import { getUrlQuery, ifClient } from '@/utils/utils';
import { tinymceFormatLanguage } from '@/utils';
import { RootStateOrAny, useSelector } from 'react-redux';
import './index.less';
import { config } from '@/constants/config.js';

function ClientMessages(props: any, ref) {
  const { leaveMessageParams, closeLeaveMessage, onValueChange, complaintDetail } = props;
  const userName = useSelector((state: RootStateOrAny) => state.get('user').get('userName'));
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  const formRef = useRef(null);
  const { t } = useTranslation();
  /* 留言表单 */
  const [leaveForm] = Form.useForm();
  /* 回复留言表单 */
  const [replyForm] = Form.useForm();
  /* 留言内容 */
  const [leaveMessage, setLeaveMessage] = useState<string>('');
  /* 回复留言的内容 */
  const [replyText, setReplyText] = useState<string>('');
  /* 显示回复留言富文本弹窗 */
  const [showReply, setShowReply] = useState<boolean>(false);
  /* 留言的key值 */
  const [messageKey, setMessageKey] = useState<string>('');
  /* 留言列表数据 */
  const [messageList, setMessageList] = useState<any>([]);
  /* 文件上传列表 */
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  /* 回复留言文件上传列表 */
  const [replyFileList, setReplyFileList] = useState<UploadFile[]>([]);
  /* 回复按钮是否禁用,默认不禁用 */
  const [replyButtonDisable, setReplyButtonDisable] = useState<boolean>(false);
  /* 留言按钮是否禁用，默认不禁用 */
  const [leaveMessagedisable, setLeaveMessagedisable] = useState<boolean>(false);
  /* 当前用户的语言 */
  const language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 回复留言的标识 */
  const [Aflag, setAFlag] = useState<boolean>(false);
  let downloadList: any = [];
  useEffect(() => {
    if (formRef.current) {
      leaveForm.setFieldValue('commentContent', '');
    }
  }, [leaveForm]);

  useEffect(() => {
    if (leaveMessageParams.showDialog) {
      getComment({ key: leaveMessageParams.key })
        .then((res: any) => {
          if (res && res.code === 200 && res.data && res.data.length > 0) {
            setMessageList([...res.data]);
            downloadList = res.data;
          } else {
            setMessageList([]);
            downloadList = [];
          }
        })
        .catch(() => {
          setMessageList([]);
          downloadList = [];
        });
    }
  }, [leaveMessageParams.showDialog, Aflag]);

  useEffect(() => {
    document.addEventListener('click', myListenFunc);
    return () => {
      document.removeEventListener('click', myListenFunc);
    };
  }, [leaveMessageParams.showDialog, Aflag]);

  const myListenFunc = (event) => {
    if (event.target.className === 'attachment') {
      let name: any = '';
      downloadList.map((obj) => {
        obj.commentFileList?.map((item) => {
          if (item.fileName == event.target.innerText) {
            name = item.fileFullPath;
          }
        });
        obj.replyFileList?.map((item) => {
          if (item.fileName == event.target.innerText) {
            name = item.fileFullPath;
          }
        });
      });
      let params = {
        blobContainerName: 'FILES',
        fileBlobName: name,
      };
      fileDownload(params).then((res: any) => {
        blobDownloadFile(res, event.target.innerText);
      });
    }
  };

  /* 处理富文本中的内容变化 */
  const handleEditorChange = (content, editor) => {
    setLeaveMessage(content);
  };
  /* 提交留言 */
  const submitLeaveMessage = () => {
    let key: string = getUrlQuery('key');
    leaveForm.validateFields().then((values) => {
      let paramsObj: any = {
        key: key,
        commentContent: leaveMessage,
        amsFileList: [],
      };
      values.fileList?.map((item) => {
        return paramsObj.amsFileList?.push({
          blobContainerName: 'FILES',
          fileFullPath: item.response[0].fileSaveName,
          fileName: item.response[0].fileName,
          fileSize: item.size,
          type: item.type,
        });
      });

      if (leaveMessage) {
        setLeaveMessagedisable(true);
        submitComment(paramsObj)
          .then((res: any) => {
            setLeaveMessagedisable(false);
            if (res && res.code === 200) {
              message.success(t('complaintList.leaveMessageSuccess'));
              closeLeaveMessage();
              setLeaveMessage('');
              leaveForm.setFieldValue('fileList', []);
              getComment({ key: leaveMessageParams.key })
                .then((res: any) => {
                  if (res && res.code === 200 && res.data && res.data.length > 0) {
                    setMessageList([...res.data]);
                    downloadList = res.data;
                    onValueChange(true);
                  } else {
                    setMessageList([]);
                    downloadList = [];
                    onValueChange(false);
                  }
                })
                .catch(() => {
                  setMessageList([]);
                  downloadList = [];
                  onValueChange(false);
                });
            }
          })
          .catch(() => {
            setLeaveMessagedisable(false);
          });
      } else {
        message.error(t('complaintList.pleaseInputLeaveMessage'));
      }
    });
  };
  /* 将upload组件的event值转为字段值 */
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  /* 处理附件上传 */
  const handleUpload = (options: any) => {
    const { onSuccess, onError } = options;
    const formData = new FormData();
    formData.append('blobContainerName', 'FILES');
    formData.append('fileList', options.file);
    let fileList: any = [{}];
    fileUploadARMS(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          fileList[0].status = 'done';
          fileList[0].url = config.downUrl + `${res.data[0].blobContainerName}/${res.data[0].fileSaveName}`;
          fileList[0].fileName = res.data[0].fileSaveName;
          fileList[0].fileSaveName = res.data[0].fileSaveName;
          onSuccess(fileList);
        } else {
          onError(message.success(t('complaintList.fileUploadFailed')));
        }
      })
      .catch(() => {
        onError(message.success(t('complaintList.fileUploadFailed')));
      });
  };
  /* 文件上传前的钩子函数，用来判断单个文件的大小 */
  const beforeUpload = (file: RcFile) => {
    const fileSizeLimit = file.size / Math.pow(1024, 2) < 80;
    if (!fileSizeLimit) {
      message.error(t('createComplaint.fileSizeLimit'));
      return Upload.LIST_IGNORE;
    }
    return fileSizeLimit;
  };
  /* 处理上传文件变动 */
  const handleChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList];
    setFileList(newFileList);
  };
  /* 显示回复留言窗口 */
  const replyMessage = (messageKeyParams) => {
    if (messageKeyParams === messageKey) {
      setShowReply(!showReply);
    } else {
      setShowReply(true);
    }
    setMessageKey(messageKeyParams);
  };
  /* 确认回复留言 */
  const replyLeaveMessage = () => {
    replyForm &&
      replyForm.validateFields().then((values: any) => {
        let paramsObj: any = {
          replyContent: replyText,
          key: messageKey,
          amsFileList: [],
        };
        values.replyFileList?.map((item) => {
          return paramsObj.amsFileList.push({
            blobContainerName: 'FILES',
            fileFullPath: item.response[0].fileSaveName,
            fileName: item.response[0].fileName,
            fileSize: item.size,
            type: item.type,
          });
        });
        if (replyText) {
          setReplyButtonDisable(true);
          replyComment(paramsObj)
            .then((res: any) => {
              setReplyButtonDisable(false);
              if (res && res.code === 200) {
                message.success(t('complaintList.replySuccessfully'));
                setShowReply(false);
                setReplyFileList([]);
                setReplyText('');
                setMessageKey('');
                getComment({ key: leaveMessageParams.key })
                  .then((res: any) => {
                    if (res && res.code === 200 && res.data && res.data.length > 0) {
                      setMessageList([...res.data]);
                      downloadList = res.data;
                    } else {
                      setMessageList([]);
                      downloadList = [];
                    }
                  })
                  .catch(() => {
                    setMessageList([]);
                    downloadList = [];
                  });
                setAFlag(true);
              }
            })
            .catch(() => {
              setReplyButtonDisable(false);
              setAFlag(false);
            });
        } else {
          message.error(t('complaintList.replyContentRequired'));
        }
      });
  };
  /* 处理回复留言内容变化 */
  const handleReplyMessageChange = (content, editor) => {
    setReplyText(content);
  };
  /* 处理上传文件变动 */
  const handleReplyChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList];
    setReplyFileList(newFileList);
  };

  return (
    <>
      <Modal
        maskClosable={false}
        open={leaveMessageParams.showDialog}
        width="60%"
        title={ifClient(roles) ? t('complaintList.clientMessage') : t('complaintList.leaveMessage')}
        onCancel={closeLeaveMessage}
        forceRender
        footer={null}
      >
        {!complaintDetail.closedStatusUpdatedOn && (
          <Form layout="vertical" autoComplete="off" ref={formRef} form={leaveForm}>
            <Form.Item name="commentContent">
              <div>
                <Editor
                  inline={false}
                  apiKey="0990dde4fa3e0f907ddaba1e7cf78810421a434e14f1bcc56d46fcc009e711f2"
                  initialValue=""
                  tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                  id="commentContent"
                  onEditorChange={handleEditorChange}
                  scriptLoading={{ async: true }} // 异步加载
                  init={{
                    height: 250,
                    language: tinymceFormatLanguage(language), //多语言处理
                    placeholder: `${t('complaintList.messageContent')}`,
                    plugins: 'formatpainter',
                    toolbar:
                      'undo redo | formatselect bold italic | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | outdent indent formatpainter ',
                    menubar: 'edit format',
                    font_formats:
                      'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n;宋体=宋体;黑体=黑体;仿宋=仿宋;微软雅黑=微软雅黑;楷体-GB2312=楷体-GB2312',
                    menu: {
                      edit: {
                        title: 'Edit',
                        items: 'undo redo | cut copy paste pastetext | selectall',
                      },
                      format: {
                        title: 'Format',
                        items: 'bold italic underline strikethrough superscript subscript | formats | removeformat',
                      },
                    },
                    branding: false,
                    resize: false, // 右下角调整大小
                    statusbar: false, // 底部状态栏
                    object_resizing: false, // 禁止设置媒体大小
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    default_link_target: '_blank',
                  }}
                />
              </div>
            </Form.Item>

            <Form.Item
              label={
                <Tooltip title={t('complaintList.attention')}>
                  <span>
                    {t('complaintList.annex')} &nbsp;
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              }
              name="fileList"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                showUploadList={true}
                maxCount={5}
                multiple={true}
                onChange={handleChange}
                fileList={fileList}
                customRequest={(option) => handleUpload(option)}
                beforeUpload={beforeUpload}
              >
                <Button key="choose" shape="round" type="primary" disabled={fileList.length >= 5}>
                  {t('complaintList.chooseFile')}
                </Button>
              </Upload>
            </Form.Item>
          </Form>
        )}

        {!complaintDetail.closedStatusUpdatedOn && (
          <Row justify="end">
            <Button
              key="submit"
              style={{ margin: '4px' }}
              type="primary"
              shape="round"
              disabled={leaveMessagedisable}
              onClick={submitLeaveMessage}
            >
              {t('complaintList.submit')}
            </Button>
          </Row>
        )}

        {messageList.length > 0 ? (
          <List
            className="comment-list "
            style={{
              height: '500px',
              border: '1px solid #ddd',
              padding: '2px',
              overflowY: 'scroll',
            }}
            header={null}
            itemLayout="horizontal"
            dataSource={messageList}
            renderItem={(item: any, index) => {
              /* 处理外部附件的显示 */
              function handleAttachment(item: any) {
                let str = '';
                item.map((obj) => {
                  return (str += '<pre><a class="attachment">' + obj.fileName + '</a></pre>');
                });
                return str;
              }
              /* 处理内部附件的显示 */
              function handleInnerAttachment(item: any) {
                let str = '';
                item.map((obj) => {
                  return (str += '<pre><a class="attachment">' + obj.fileName + '</a></pre>');
                });
                return str;
              }
              /* 处理回复显示 */
              function handleReplyAndRevoke(item: any) {
                if (!item.replyContent) {
                  return [
                    <div>
                      {/* 回复留言的文字 */}
                      <span
                        key="replyTo"
                        style={{ color: '#E8AA0D', cursor: 'pointer' }}
                        className="innerCommentText"
                        onClick={() => replyMessage(item.commentId)}
                      >
                        {t('complaintList.reply')}
                      </span>
                      {showReply && messageKey === item.commentId ? (
                        <Form
                          layout="vertical"
                          autoComplete="off"
                          name="replyForm"
                          form={replyForm}
                          style={{ width: '100%' }}
                        >
                          <Form.Item name="commentContent">
                            <div>
                              <Editor
                                inline={false}
                                apiKey="0990dde4fa3e0f907ddaba1e7cf78810421a434e14f1bcc56d46fcc009e711f2"
                                initialValue=""
                                tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                                id={'replyMessageEditor' + item.commentId}
                                onEditorChange={handleReplyMessageChange}
                                scriptLoading={{ async: true }}
                                init={{
                                  height: 220,
                                  placeholder: `${t('complaintList.reply')}` + ': ' + item.commentCreator,
                                  language: tinymceFormatLanguage(language),
                                  plugins: 'formatpainter',
                                  toolbar:
                                    'undo redo | formatselect bold italic | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | outdent indent formatpainter ',
                                  menubar: 'edit format',
                                  font_formats:
                                    'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n;宋体=宋体;黑体=黑体;仿宋=仿宋;微软雅黑=微软雅黑;楷体-GB2312=楷体-GB2312',
                                  menu: {
                                    edit: {
                                      title: 'Edit',
                                      items: 'undo redo | cut copy paste pastetext | selectall',
                                    },
                                    format: {
                                      title: 'Format',
                                      items:
                                        'bold italic underline strikethrough superscript subscript | formats | removeformat',
                                    },
                                  },
                                  branding: false,
                                  resize: false, // 右下角调整大小
                                  statusbar: false, // 底部状态栏
                                  object_resizing: false, // 禁止设置媒体大小
                                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                  default_link_target: '_blank',
                                }}
                              />
                            </div>
                          </Form.Item>
                          <Form.Item
                            label={
                              <Tooltip title={t('complaintList.attention')}>
                                <span>
                                  {t('complaintList.annex')} &nbsp;
                                  <InfoCircleOutlined />
                                </span>
                              </Tooltip>
                            }
                            name="replyFileList"
                            valuePropName="replyFileList"
                            getValueFromEvent={normFile}
                          >
                            <Upload
                              showUploadList={true}
                              maxCount={5}
                              multiple={true}
                              onChange={handleReplyChange}
                              fileList={replyFileList}
                              customRequest={(option) => handleUpload(option)}
                              beforeUpload={beforeUpload}
                            >
                              <Button
                                key="chooseFile"
                                shape="round"
                                type="primary"
                                disabled={replyFileList.length >= 5}
                              >
                                {t('complaintList.chooseFile')}
                              </Button>
                            </Upload>
                          </Form.Item>
                          <Row>
                            <Button
                              key="submit2"
                              type="default"
                              shape="round"
                              disabled={replyButtonDisable}
                              style={{ margin: '0 auto' }}
                              onClick={replyLeaveMessage}
                            >
                              {t('complaintList.submit')}
                            </Button>
                          </Row>
                        </Form>
                      ) : null}
                    </div>,
                  ];
                }
              }
              return (
                <li className="message-replay">
                  <Comment
                    className="coment"
                    actions={handleReplyAndRevoke(item)}
                    author={<span className="innerCommentText">{item.commentCreator}</span>}
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.commentContent + handleAttachment(item.commentFileList),
                        }}
                      ></div>
                    }
                    datetime={<span className="innerCommentText">{item.commentDatetime}</span>}
                    avatar={null}
                  >
                    {item.replyContent ? (
                      <Comment
                        className="coment"
                        datetime={<span className="innerCommentText">{item.replyDatetime}</span>}
                        avatar={null}
                        actions={[]}
                        author={
                          <span className="innerCommentText">
                            {item.replyAccount +
                              (item.replyAccount
                                ? ' ' + `${t('complaintList.reply')}` + ': ' + item.commentCreator
                                : '')}
                          </span>
                        }
                        content={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.replyContent + handleInnerAttachment(item.replyFileList),
                            }}
                          ></div>
                        }
                      ></Comment>
                    ) : null}
                  </Comment>
                </li>
              );
            }}
          ></List>
        ) : (
          <p style={{ border: '1px solid #bbb', textAlign: 'center', color: '#999' }}>{t('singleSysUpdate.noData')}</p>
        )}
      </Modal>
    </>
  );
}
export default forwardRef(ClientMessages);
