// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-style {
  overflow-y: scroll;
  max-height: 800px;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClientList/CustomerComplaintDetails/components/WorkOrderDetails/index.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;AACA;EACE,gBAAA;EACA,uBAAA;AACF","sourcesContent":[".steps-style {\n  overflow-y: scroll;\n  max-height: 800px;\n}\n.text-ellipsis {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
