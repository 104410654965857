/*
 * @Description:
 * @Author: XuAnjie
 * @Date: 2022-11-04 14:40:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-09-04 14:14:21
 */
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { IResponse } from '@/utils/request.type';
import { message as Message } from 'antd';
import { getToken, removeToken } from '@/utils/localStoreUtil';
import { convertLangage } from '@/utils';
import moment from 'moment';
import { config } from '@/constants/config';
const excludeBaseUrlList = [
  '/geocoding/v3',
  '/timezone/v1',
  '/v1/current_time',
  '/chengdu/base/user/sendRegisterActivation',
];

const request = axios.create({
  // 若需要vite代理，这里就不要设置
  baseURL: '/api',
  timeout: 50 * 1000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    platform: 'AK9D8H',
  },
});

/* 在http请求发送给后端前，将请求参数中的字符串前后空格去掉 */
function requestDataRemoveSpaces(paramsObj: any, method) {
  for (let key in paramsObj) {
    if (typeof paramsObj[key] === 'string') {
      if (method === 'get') {
        if (paramsObj[key].trim().indexOf('[') > -1 || paramsObj[key].trim().indexOf(']') > -1) {
          paramsObj[key] = encodeURIComponent(paramsObj[key].trim());
        } else {
          paramsObj[key] = paramsObj[key].trim();
        }
      } else {
        paramsObj[key] = paramsObj[key].trim();
      }
    }
    if (typeof paramsObj[key] === 'object' && paramsObj[key] !== null && !(paramsObj[key] instanceof File)) {
      requestDataRemoveSpaces(paramsObj[key], method);
    }
  }
}

// 添加一个请求的拦截
request.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    /* 登录、首页公告以及获取用户协议不需要加token */
    if (
      getToken() &&
      config.url !== '/web/myMsg/getHomeMsg' &&
      config.url !== '/stable/user/login' &&
      config.url !== '/stable/user/getUserAgreement'
    ) {
      config.headers['Authorization'] = getToken();
    }
    config.headers['language'] = convertLangage();
    config.headers['operationDate'] = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    requestDataRemoveSpaces(config.data || config.params, config.method);
    if (excludeBaseUrlList.includes(config.url as string)) {
      config.baseURL = '';
    }
    return config;
  },
  (error: AxiosError) => {
    Message.error(error.message);
    return Promise.reject(error);
  },
);

// 响应拦截
request.interceptors.response.use(
  (rep: AxiosResponse<IResponse<any>>) => {
    let httpCode: number = rep.status;
    switch (httpCode) {
      case 200:
        if (rep.config.responseType && rep.config.responseType === 'blob') {
          return rep;
        } else if (rep.config.responseType == 'arraybuffer') {
          let binary = '';
          let params: any = rep.data;
          const bytes = new Uint8Array(params);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          return binary;
        } else if (rep.data && rep.data.code === '000000') {
          // decode(rep.data)
          return rep.data as any;
        } else {
          if (rep.data.code === 6084 || rep.data.code === 6085 || rep.data.code === 6123) {
            // decode(rep.data)
            return rep.data as any;
          }
          if (!document.querySelector('.alpha-cloud-SSO')) {
            Message.error(rep.data.msg);
          } else if (
            document.querySelector('.alpha-cloud-SSO') &&
            rep.data.code !== 6069 &&
            rep.data.code !== 6070 &&
            rep.data.code !== 6071
          ) {
            // dataType:10 通知父弹出通知信息
            window.parent.postMessage({ dataType: 10, message: rep.data.msg }, config.alphaCloudSSO[location.origin]);
          }

          if (
            (rep.data.code === 6069 || rep.data.code === 6070 || rep.data.code === 6071) &&
            window.location.pathname !== '/login'
          ) {
            if (document.querySelector('.alpha-cloud-SSO')) {
              // 通知cloud6.0退出登录
              window.parent.postMessage({ dataType: 8 }, config.alphaCloudSSO[location.origin]);
            } else {
              window.location.href = '/login' + window.location.search;
              removeToken();
            }

            return;
          } else {
            // decode(rep.data)
            return rep.data as any;
          }
        }
      case 500:
        Message.error('服务器错误');
        return;
    }
  },
  (error: AxiosError) => {
    if (error && error.response) {
      if (error.response.config && error.response.config.url == '/api/Message/QueryMsgNotice') {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
    Message.error(error.message);
    return Promise.reject(error);
  },
);

export default request;
