/*
 * @Description: 客户反馈->客诉详情页-> （客户）客诉流程图
 * @Author: Wayne Hu
 * @LastEditors: XuAnjie
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2023-12-15 15:37:50
 */

import React, { memo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';
import { RootStateOrAny, useSelector } from 'react-redux';

interface MermaidChartProps {
  data: any;
  onOpen: (e) => void;
}

type infoParams = {
  name: string;
  info?: infoTypes;
};

type infoTypes = {
  time?: string;
  infos?: string;
  startTime?: string;
  endTime?: string;
};
const CustomerClientStatus: React.FC<MermaidChartProps> = ({ data, onOpen }) => {
  const { t } = useTranslation();
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));

  const Rectangle = (props: infoParams) => {
    const { name, info } = props;
    return (
      <div className="rectangle">
        <div>{name}</div>
        {info?.infos && <div>{info?.infos}</div>}
        {info?.startTime && info.endTime ? (
          <div>
            {info.startTime}-{info.endTime}
          </div>
        ) : null}
        {info?.time && <div>{info?.time}</div>}
      </div>
    );
  };

  const Line = () => {
    return <div className="line"></div>;
  };

  return (
    <>
      <div className="container">
        <Rectangle name={t('complaintList.newCreate')} info={{ time: data.createServerDatetime }} />
        {data.statusUocip ? (
          <>
            <Line />
            <Rectangle name={t('complaintList.customerComplaintProcessing')} info={{ time: data.statusUocip }} />
          </>
        ) : null}
        {/* 已安排上门处理 */}
        {data.statusUowoip ? (
          <>
            <Line />
            <Rectangle
              name={t('complaintList.haveDoorToDoorProcessing')}
              info={{ time: data.statusUowoip, infos: t('complaintList.workOrderGenerated') + ': ' + data.workNo }}
            />
          </>
        ) : null}
        {/* 已安排备品发货 */}
        {data.sparePartsDeliveryTime ? (
          <>
            <Line />
            <Rectangle
              name={t('complaintList.hasBeenArranged')}
              info={{
                time: data.sparePartsDeliveryTime,
                infos: t('complaintList.sparePartsOrderGeneration') + ': ' + data.afterSaleOrderNumber,
              }}
            />
          </>
        ) : null}
        {/*  待发货*/}
        {(data.awaitingShipmentTime && !roles.includes('customer')) ||
        (roles.includes('customer') && data.partsObject == 10 && data.awaitingShipmentTime) ? (
          <>
            <Line />
            <Rectangle name={t('complaintList.lab_to_shipped')} info={{ time: data.awaitingShipmentTime }} />
          </>
        ) : null}
        {/* 已发货 */}
        {(data.shippedTime && !roles.includes('customer')) ||
        (roles.includes('customer') && data.partsObject == 10 && data.shippedTime) ? (
          <>
            <Line />
            <Rectangle
              name={t('complaintList.lab_has_Shipped')}
              info={{ time: data.shippedTime, infos: t('complaintList.shipmentNumber') + ': ' + data.trackingNumber }}
            />
          </>
        ) : null}
        {/* 预约上门时间 */}
        {data.appointmentTime ? (
          <>
            <Line />
            <Rectangle name={t('complaintList.makeAnAppointment')} info={{ time: data.appointmentTime }} />
          </>
        ) : null}
        {data.closedStatusUpdatedOn ? (
          <>
            <Line />
            <Rectangle name={t('complaintList.closed')} info={{ time: data.closedStatusUpdatedOn }} />
          </>
        ) : null}
      </div>
    </>
  );
};

export default memo(CustomerClientStatus);
