import { config } from '@/constants/config.js';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

export function nomuch(e) {
  e.value = e.value.replace(/[^\d.]/g, ''); //清除"数字"和"."以外的字符
  e.value = e.value.replace(/^\./g, ''); //验证第一个字符是数字而不是
  e.value = e.value.replace(/\.{2,}/g, '.'); //只保留第一个. 清除多余的
  e.value = e.value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  e.value = e.value.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3'); //只能输入一个小数
  e.value = e.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
}
export function weekDayFormat(time) {
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()];
}
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

// 格式化时间
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {Sting} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

export function param(json) {
  if (!json) return '';
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return '';
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    }),
  ).join('&');
}

export function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') +
      '"}',
  );
}

export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function objectMerge(target, source) {
  /* Merges two  objects,giving the last one precedence */
  if (typeof target !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

export const pickerOptions = [
  {
    text: '今天',
    onClick(picker) {
      const end = new Date();
      const start = new Date(new Date().toDateString());
      end.setTime(start.getTime());
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近一周',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    },
  },
];

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
export function debounceSelf(fun: any, delay: number) {
  let timer: any = null; //借助闭包

  return function () {
    if (timer) {
      clearTimeout(timer); //进入该分支语句，说明当前正在一个计时过程中，并且又触发了相同事件。所以要取消当前的计时，重新开始计时
      timer = setTimeout(fun, delay);
    } else {
      timer = setTimeout(fun, delay); // 进入该分支说明当前并没有在计时，那么就开始一个计时
    }
  };
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;
    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

export function createUniqueString() {
  const timestamp = +new Date() + '';
  const randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

export function energyUnit(value) {
  if (value < 10000) {
    const uu = value / 1000;
    return uu.toFixed(1) + 'kWh';
  } else if (value < 10000000 && value >= 10000) {
    const uu = value / 1000;
    return uu.toFixed(1) + 'MWh';
  }
}

export function pointOne(value) {
  const pp = value;
  return pp != undefined ? pp.toFixed(1) : 0;
}
// 获取url中的参数值
export function getUrlQuery(params: string) {
  let regExp = new RegExp('(^|&)' + params + '=([^&]*)(&|$)');
  let r = document.location.search.substr(1).match(regExp);
  if (r && r[2]) {
    return r[2];
  } else {
    return '';
  }
}
//全屏
export function goFullScreen(id) {
  let element = document.getElementById(id);
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
}
// 退出全屏
export function exitFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}
//
export function fullScreenChange(id, callBack) {
  let fullScreen = false;
  if (window.addEventListener) {
    document.getElementById(id).addEventListener(
      'fullscreenchange',
      function () {
        fullScreen =
          !!document.fullscreenElement ||
          !!document.msFullscreenElement ||
          !!document.mozFullScreenElement ||
          !!document.webkitFullscreenElement ||
          false;
        if (typeof callBack === 'function') {
          callBack(fullScreen);
        }
      },
      false,
    );
  } else {
    // 兼容IE
    window.attachEvent('fullscreenchange', function () {
      fullScreen =
        !!document.fullscreenElement ||
        !!document.msFullscreenElement ||
        !!document.mozFullScreenElement ||
        !!document.webkitFullscreenElement ||
        false;
      if (typeof callBack === 'function') {
        callBack(fullScreen);
      }
    });
  }
}
/**
 * @description: 添加绑定事件兼容IE
 * @Author: Summer.Chen
 * @Date: 2023-06-12 16:46:43
 * @return {*}
 * @LastEditTime: Do not edit
 * @LastEditors: Summer.Chen
 * @param {*} objectelement
 * @param {*} eventname
 * @param {*} eventhandler
 */
export function addEventHandler(objectelement: any, eventname: string, eventhandler: any) {
  if (!objectelement) {
    return;
  }
  if (objectelement.attachEvent) {
    //ie浏览器
    objectelement.attachEvent('on' + eventname, function (event: any) {
      eventhandler(event);
    });
  } else if (objectelement.addEventListener) {
    //非ie浏览器
    objectelement.addEventListener(
      eventname,
      function (event: any) {
        eventhandler(event);
      },
      false,
    );
  }
}
// 小数点不足，0补齐
export function sgToFixed(num: number, digit = 2) {
  //num是数值；digit是保留有效数字位数
  if ((num || 0).toString().indexOf('.') == -1) {
    return num;
  }

  return num.toFixed(digit);
}
// 判断时间段是否重叠
export function overlap(timeSegments: any) {
  let ret = false;
  let i = 0;
  while (!ret && i < timeSegments.length - 1) {
    let seg1 = timeSegments[i];
    let seg2 = timeSegments[i + 1];
    let range1 = moment.range(moment(seg1[0], 'HH:mm'), moment(seg1[1], 'HH:mm'));
    let range2 = moment.range(moment(seg2[0], 'HH:mm'), moment(seg2[1], 'HH:mm'));
    if (range1.overlaps(range2)) {
      ret = true;
    }
    i++;

    return ret;
  }
}
export function searchParent(map: any[], key: any, field?: string) {
  let t: any = [];
  field = field ? field : 'key';
  for (let i = 0; i < map.length; i++) {
    const e: any = map[i];
    if (e[field] === key) {
      //若查询到对应的节点，则直接返回
      t.push(e);
      break;
    } else if (e.children && e.children.length !== 0) {
      //判断是否还有子节点，若有对子节点进行循环调用
      let p = searchParent(e.children, key, field);
      //若p的长度不为0，则说明子节点在该节点的children内，记录此节点，并终止循环
      if (p.length !== 0) {
        p.unshift(e);
        t = p;
        break;
      }
    }
  }
  return t;
}
/**
 * @description: 遍历给表格表头添加固定宽度
 * @param {any} columns
 * @return {*}
 * @LastEditors: XuAnjie
 */
export function calculatedColumns(columns: any) {
  const calculated = columns.map((column) => ({
    ...column,
    // 你可以根据你的需求调整这个公式
    width: 240,
  }));
  return calculated;
}

/**
 * @description: 遍历给表格表头添加固定宽度
 * @param {any} columns
 * @return {*}
 * @LastEditors: XuAnjie
 */
export function ifClient(roles) {
  if (roles.includes('reseller') || roles.includes('installer') || roles.includes('customer')) {
    return true;
  } else {
    return false;
  }
}
/**
 * @description:获取url 参数值
 * @param {any} params
 * @return {*}
 * @LastEditors: Summer.Chen
 */

export function getUrlSearchParams(params) {
  let queryString = window.location.search;
  let langParams: any = '';
  if (new URLSearchParams(queryString)) {
    return (langParams = new URLSearchParams(queryString).get(params));
  }
}

export function getProContentHeight(className: string) {
  window.parent.postMessage(
    { dataType: 1, height: document.querySelector(className)?.scrollHeight },
    config.alphaCloudSSO[location.origin],
  );
}

/**
 * @description: 传入任意数量的数组进行比较
 * @param {*} data1
 * @param {*} data2
 * @return {*}
 * @LastEditors: XuAnjie
 */
export function unifiedDividingLine(...data) {
  let max = 0;
  // 遍历所有传入的数组
  data.forEach((arr) => {
    const arrMax = Math.max(...arr);
    if (arrMax > max) {
      max = arrMax;
    }
  });
  // 判断最大值是否为10的倍数，如果不是，则将其调整为最接近的10的倍数
  if (max % 10 !== 0) {
    max = Math.ceil(max / 10) * 10;
  }
  return max;
}
// 将分钟数转换为天和小时数
export function convertMinutesToDaysAndHours(minutes: number) {
  const hours = Math.floor(minutes / 60); // 计算小时数
  const days = Math.floor(hours / 24); // 计算天数
  const hoursInDays = hours % 24; // 小时数中的剩余部分
  const leftMin = minutes % 60;
  return { days, hours: hoursInDays, leftMin };
}
//moment 日期转换
export const formatDateParams = (data: any, formatDate: string = 'YYYY-MM-DD') => {
  let tempParams = {};
  for (const key in data) {
    if (moment.isMoment(data[key])) {
      tempParams[key] = moment(data[key]).format(formatDate);
    } else {
      tempParams[key] = data[key];
    }
  }
  return tempParams;
};
export enum ComplaintListStatus {
  /** 户用客诉列表 */
  userComplaintListPage = 1,
  /** 工商业客诉列表  */
  listIndustrialAndCommercialCustomerComplaintsPage = 2,
  /** 消费者客诉列表*/
  consumerComplaintList = 3,
}
// 判断当前页面是否是工商业
export const isIndustryAndCommerce = (): number => {
  if (location.href.indexOf('listIndustrialAndCommercialCustomerComplaintsPage') > -1) {
    return ComplaintListStatus.listIndustrialAndCommercialCustomerComplaintsPage;
  } else if (location.href.indexOf('consumerComplaintList') > -1) {
    return ComplaintListStatus.consumerComplaintList;
  } else {
    return ComplaintListStatus.userComplaintListPage;
  }
};
// 将小时数转换为天和小时数
export function convertHoursToDaysAndHours(hours: number) {
  const days = Math.floor(hours / 24); // 计算天数
  hours = hours % 24; // 小时数中的剩余部分
  return { days, hours };
}
// 系统语言格式转换为第三方的格式
export function convertSysLanFormatToThirdPartyFormat(lang: string): string {
  if (!lang) {
    return 'en-US';
  }
  return config.languageList.filter((item) => item.simple === lang)[0].value;
}
// 组织树父节点
export function getParents(tree: any[], nodeId: string | number) {
  let path = [];
  function findNode(node, id, parents) {
    if (node.tempMenuId === id) {
      path = parents.slice(); // 复制父节点路径
      return true;
    }
    if (node.children) {
      for (let child of node.children) {
        if (findNode(child, id, parents.concat(node))) {
          return true;
        }
      }
    }
    return false;
  }
  findNode({ children: tree }, nodeId, []);
  return path;
}
