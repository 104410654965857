/*
 * @Description: 客户反馈->客诉详情页-> 转中试之后去处理
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2025/02/07
 */

import React, { memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, message, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { saveHandle, getHandleForm } from '@/api/clientFeedback/complaintsList';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getUrlQuery } from '@/utils/utils';
import { Editor } from '@tinymce/tinymce-react';
import { tinymceFormatLanguage } from '@/utils';
import FileUpload from '../FileUpload';
import { config } from '@/constants/config';
import { fileUpload } from '@/api/common';
import { InfoCircleOutlined } from '@ant-design/icons';
import PreViewModal from '../PreViewModal';

const { Option } = Select;

/* 富文本内容 */
let tinyContent: any = '';

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
}

const CenterEvaluationTestCases: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  id,
  extendParams,
  nodeData,
}) => {
  const { t } = useTranslation();
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));

  /* 附件列表 */
  const [fileLists, setFileLists] = useState([]);
  /* 预览弹窗的开关 */
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  /* 点击文件 */
  const [fileItem, setFileItem] = useState<any>();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();

  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    let key: string = getUrlQuery('key');
    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
    }
    if (nodeData) {
      getHandleForms(nodeData.data.id);
    }
  }, []);

  /**
   * @description: 获取中试去处理表单
   * @param {*} id
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getHandleForms = (id) => {
    getHandleForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        searchForm.setFieldsValue(res.data);
        setFileLists(res.data.amsFileList);
      }
    });
  };

  /**
   * @description: 带附件表单保存
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    searchForm.validateFields().then((values) => {
      if (!tinyContent) {
        return message.error(t('complaintList.describeInfoRequired'));
      }
      let amsFileLists: any = [];
      values.amsFileList?.forEach((item) => {
        amsFileLists.push({
          blobContainerName: item.response[0].blobContainerName,
          fileFullPath: item.response[0].fileSaveName,
          fileName: item.response[0].fileName,
          fileSize: item.response[0].size,
        });
      });
      let transferParams = [];
      if (values.taskAuthData && originData.nextOperateAuthList.length > 1) {
        transferParams = originData.nextOperateAuthList.filter((item) => {
          return item.id == values.taskAuthData;
        });
      } else {
        transferParams = originData.nextOperateAuthList;
      }
      saveHandle({
        complaintsId: id,
        amsFileList: amsFileLists,
        describeInfo: tinyContent,
        ...originData,
        taskAuthData: transferParams ? transferParams[0] : null,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHanle();
        }
      });
    });
  };

  /**
   * @description: 回显上传的文件
   * @param {*} newFileList
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const handleFileListChange = (newFileList) => {
    searchForm.setFieldValue('amsFileList', newFileList);
    searchForm.validateFields(['amsFileList']);
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const cancelHanle = () => {
    searchForm.resetFields();
    onClose();
  };

  /**
   * @description: 监听富文本变化
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const handleEditorChange = (content: any, editor: any) => {
    tinyContent = content;
  };

  /**
   * @description: 富文本上传
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const tinymceUploadImage = (blobInfo, succFun, failFun) => {
    const formData = new FormData();
    // 服务端接收文件的参数名，文件数据，文件名
    formData.append('blobContainerName', 'FILES');
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    fileUpload(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          let uploadUrl = '';
          if (window.location.origin.indexOf('localhost') != -1) {
            uploadUrl = config.downUrl + 'files/';
          } else {
            uploadUrl = config.downUrl + 'files/';
          }
          succFun(uploadUrl + res.data[0].fileSaveName);
        } else {
          failFun(t('createComplaint.fileUploadError'));
        }
      })
      .catch(() => {
        failFun(t('createComplaint.fileUploadError'));
      });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载或预览客诉附件
   * @param {string} fileName
   * @return {*}
   */
  const downloadOrPreviewAttachments = (fileItem: any) => {
    setFileItem(fileItem);
    setViewOpen(true);
  };

  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: April
   */
  const handleCancePreview = () => {
    setViewOpen(false);
  };
  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData.data.currentStatusShowName}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHanle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHanle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form
          layout="vertical"
          form={searchForm}
          name="complaintDetail"
          autoComplete="off"
          style={{ width: '100%' }}
          className=""
          disabled={nodeData ? true : false}
        >
          {originData && originData.nextOperateAuthList && originData.nextOperateAuthList.length > 1 ? (
            <Row gutter={8} className="row-border">
              {/* 转交给 */}
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  // className="complaint-detail-label"
                  name="taskAuthData"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                  label={
                    <span className="form-item-label" style={{ whiteSpace: 'pre-wrap' }}>
                      {t('complaintList.transferTo')}
                    </span>
                  }
                >
                  <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                    {originData.nextOperateAuthList &&
                      originData.nextOperateAuthList.length > 0 &&
                      originData.nextOperateAuthList.map((item: any, index) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          {/* 描述 */}
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.description')}</span>}
                name="describeInfo"
                required
              >
                <Editor
                  apiKey={config.tinyKey}
                  tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                  disabled={nodeData}
                  scriptLoading={{ async: true }} // 异步加载
                  onEditorChange={handleEditorChange}
                  init={{
                    min_height: 200,
                    language: tinymceFormatLanguage(language),
                    menubar: false, // 顶部菜单栏
                    resize: false, // 右下角调整大小
                    statusbar: false, // 底部状态栏
                    object_resizing: false, // 禁止设置媒体大小
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    default_link_target: '_blank',
                    plugins:
                      'autoresize print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave',
                    toolbar:
                      'undo redo bold italic  image | formatselect |  alignleft aligncenter alignright alignjustify outdent indent ',
                    images_upload_handler: (blobInfo, succFun, failFun) => {
                      return tinymceUploadImage(blobInfo, succFun, failFun);
                    },
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* 上传附件 */}
          {fileLists && fileLists.length > 0 ? null : (
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
                <Form.Item
                  label={
                    <Tooltip title={t('complaintList.tips')}>
                      <span>
                        {t('complaintList.attachment')} <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  name="amsFileList"
                  valuePropName="fileList"
                >
                  <FileUpload onFileListChange={handleFileListChange} maxNum={5} />
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* 附件列表 */}
          {fileLists && fileLists.length > 0 ? (
            <Row gutter={8} className="row-border">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {fileLists.map((item: any, index) => {
                  return (
                    <div>
                      <a onClick={() => downloadOrPreviewAttachments(item)}>{item.fileName}</a>
                    </div>
                  );
                })}
              </Col>
            </Row>
          ) : null}
        </Form>
        {viewOpen ? <PreViewModal onClosePreview={handleCancePreview} fileData={fileItem} /> : null}
      </Modal>
    </>
  );
};

export default memo(CenterEvaluationTestCases);
