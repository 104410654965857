/*
 * @Description: 定义质量客诉统计报表、客诉关闭率报表表头类
 * @Author: Summer.Chen
 * @Date: 2024-03-15 15:01:34
 * @LastEditTime: 2024-03-27 11:09:03
 * @LastEditors: Summer.Chen
 */
import React from 'react';
import DOMPurify from 'dompurify';
import { convertHoursToDaysAndHours } from '@/utils/utils';
import { Tooltip } from 'antd';
import i18n from '@/i18n/i18n';
//枚举数据类型
const complaintStatusType = {
  quality_closed: i18n.t('common.closed'),
  quality_processing: i18n.t('common.processing'),
};
const handlerEllipsis = (htmlStr: string): string => {
  //超过2行添加省略号
  const moreLine = /(\r\n|\n|\r){1,}/.test(htmlStr);

  return moreLine ? '...' : '';
};
//质量客诉统计报表
export const getQualityColumns = ({ pageSize = 15, pageIndex = 1 }) => {
  return [
    {
      title: i18n.t('common.serialNumber'),
      dataIndex: 'index',
      key: 'index',
      width: 70,
      render: (text: any, record: any, index: any) => {
        return (pageIndex - 1) * pageSize + index + 1;
      },
    },
    {
      title: i18n.t('common.handler'),
      dataIndex: 'processUser',
      key: 'processUser',
    },
    {
      title: i18n.t('qualityStatisticalReport.RD_QCCaseClosingTime'),
      dataIndex: 'finishTime',
      key: 'finishTime',
      render: (text: number) => {
        //小时转为天和小时
        const { days, hours } = convertHoursToDaysAndHours(Number(text));
        return (
          <span>
            {days ? days + 'days' : null} {hours ? hours.toFixed(2) + 'hr' : null}
          </span>
        );
      },
    },

    {
      title: i18n.t('qualityStatisticalReport.caseClosedStatus'),
      dataIndex: 'complaintStatus',
      key: 'complaintStatus',
      render: (text: string) => {
        return <span>{complaintStatusType[text]}</span>;
      },
    },
    {
      title: i18n.t('qualityStatisticalReport.qualityControlProcessDescription'),
      dataIndex: 'describeInfo',
      ellipsis: { showTitle: false },
      key: 'describeInfo',
      render: (text: string) => {
        const jsxElement = (
          <div
            className="content-html dg-html"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text || '') }}
          ></div>
        );
        return text ? (
          <Tooltip color="#fff" placement="topLeft" title={jsxElement} overlayClassName="reset-style">
            {jsxElement}
            {handlerEllipsis(text)}
          </Tooltip>
        ) : null;
      },
    },
  ];
};
// 客诉关闭率报表
export const getCustomerColumns = ({ pageSize = 15, pageIndex = 1 }) => {
  return [
    {
      title: i18n.t('common.serialNumber'),
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: any) => {
        return (pageIndex - 1) * pageSize + index + 1;
      },
    },
    {
      title: i18n.t('common.area'),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: i18n.t('common.middleOfficeHandler'),
      dataIndex: 'middleOfficeUser',
      key: 'middleOfficeUser',
    },
    {
      title: i18n.t('closureRateReport.customerComplaintCreationTime'),
      dataIndex: 'createDatetime',
      key: 'createDatetime',
    },
    {
      title: i18n.t('closureRateReport.customerComplaintCompletionTime'),
      dataIndex: 'closeDateTime',
      key: 'closeDateTime',
    },
    {
      title: i18n.t('closureRateReport.customerComplaintFiler'),
      dataIndex: 'archiveUser',
      key: 'archiveUser',
    },
  ];
};
