/*
 * @Description:忘记用户名
 * @Author: YuKi
 * @Date: 2022/11/03
 * @LastEditors: XuAnjie
 * @LastEditTime: 2023-08-04 17:51:42
 */
import React, { memo, useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { forgetUserName } from '@/api/login';
import { inputValueLimit } from '@/constants/inputValueLimit';
import { useSelector, RootStateOrAny } from 'react-redux';
import Header from '../Header';
import './index.less';
const { Option } = Select;
const ForgetUserName: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory(); //列表ref
  /* 忘记密码表单 */
  const [forgetUsernameForm] = Form.useForm();

  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let [isUseEffectFirst, setIsUseEffectFirst] = useState(true);

  /**
   * @Author: YuKi
   * @description: 根据语言切换动态切换样式
   * @return {*}
   */
  useEffect(() => {
    if (isUseEffectFirst) {
      setIsUseEffectFirst(false);
      return;
    }
    let tempError = forgetUsernameForm.getFieldsError();
    for (let index = 0; index < tempError.length; index++) {
      const element = tempError[index];
      if (element.errors && element.errors.length > 0) {
        forgetUsernameForm.validateFields(element.name);
      }
    }
  }, [language]);

  /**
   * @Author: YuKi
   * @description: 忘记密码表单提交
   * @param {ILoginSubmit} values
   * @return {*}
   */
  const onFinish = (values: any) => {
    forgetUserName(values)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          history.push({
            pathname: '/forgetUserNameSecond',
            state: {
              email: rep.data.email,
            },
          });
        }
      })
      .catch((err) => {});
  };

  /**
   * @Author: YuKi
   * @description: 返回按钮
   * @return {*}
   */
  const goBackButton = () => {
    window.location.href = '/login';
  };

  return (
    <>
      <div className="forget-username-container">
        <Header flag="forget-username" />
        {/* 公司logo */}
        <div className="logo-box">
          {language === 'zh' ? (
            <img src="/common/loginImages/new_logo.png" width="110" />
          ) : (
            <img src="/common/loginImages/new_logo_en.png" width="110" />
          )}
        </div>
        <div className="forget-username-form">
          <Form
            name="forget-username-from"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            size="large"
            form={forgetUsernameForm}
            initialValues={{ userType: 'customer' }}
          >
            <Row gutter={8}>
              <h2 className="username-forget-username">{t('forgetName.forgotusername')}</h2>
            </Row>

            <Row>
              {/* 用户类别 */}
              <Col span={24}>
                <Form.Item
                  label={t('forgetName.usertype')}
                  name="userType"
                  validateTrigger="onChange"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {/* 终端用户 */}
                    <Option value="customer" key="customer">
                      {t('common.customer')}
                    </Option>
                    {/* 安装商 */}
                    <Option value="installer" key="installer">
                      {t('common.installer')}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              {/* SN（终端用户） */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.userType !== currentValues.userType}
              >
                {({ getFieldValue }) =>
                  getFieldValue('userType') === 'customer' ? (
                    <Col span={24}>
                      <Form.Item
                        name="sysSn"
                        label={t('common.SNKey')}
                        validateTrigger="onBlur"
                        rules={[
                          { required: true, message: `${t('common.snIsRequired')}`, whitespace: true },
                          {
                            validator: (rule, value) => {
                              if (value && value.trim() && value.trim().length > inputValueLimit.maxSn) {
                                return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxSn }));
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>

              {/* License（安装商）*/}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.userType !== currentValues.userType}
              >
                {({ getFieldValue }) =>
                  getFieldValue('userType') === 'installer' ? (
                    <Col span={24}>
                      <Form.Item
                        name="licNo"
                        label={t('forgetName.license')}
                        validateTrigger="onBlur"
                        rules={[
                          { required: true, message: `${t('forgetName.licenseIsRequired')}`, whitespace: true },
                          {
                            validator: (rule, value) => {
                              if (value && value.trim() && value.trim().length > inputValueLimit.maxLicense) {
                                return Promise.reject(
                                  t('common.maxlengLimit', { maxLength: inputValueLimit.maxLicense }),
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>
            </Row>

            {/* 按钮 */}
            <Row gutter={18} style={{ justifyContent: 'space-around' }}>
              {/* 返回 */}
              <Col span={8}>
                <Form.Item>
                  <Button key="goback" className="go-back-style" shape="round" htmlType="button" onClick={goBackButton}>
                    {t('common.goback')}
                  </Button>
                </Form.Item>
              </Col>
              {/* 提交 */}
              <Col span={8}>
                <Form.Item>
                  <Button
                    className="forget-sumbit-style"
                    style={{ width: '100%' }}
                    key="submitBtn"
                    type="primary"
                    shape="round"
                    htmlType="submit"
                  >
                    {t('common.submitBtn')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {/* 联系我们 和 隐私政策 */}
            <Row gutter={8}>
              <Col span={24} className="more-message">
                <a href="https://www.alphaess.com/contact-us" target="_blank">
                  {t('forgetPwd.imprint')}
                </a>
                &nbsp;&nbsp;
                <a href="https://www.alphaess.com/enPrivacyPolicy.html" target="_blank">
                  {t('forgetPwd.privacypolicy')}
                </a>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default memo(ForgetUserName);
