/*
 * @Description: 客户反馈->客诉列表页
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/06/06 16:52:38
 * @LastEditTime: 2024/09/03 15:58:58
 */

import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Form, Input, Table, Row, Col, Space, Select, SelectProps, DatePicker, Tooltip, Tag } from 'antd';
import { fileDownload } from '@/api/common';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { blobDownloadFile } from '@/constants/common';
import { getCountryList } from '@/api/common';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './index.less';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getUrlQuery } from '@/utils/utils';
import {
  getNoClientComplaints,
  exportNoClientComplaints,
  getRoleList,
} from '@/api/clientFeedback/historicalComplaints';

/* 客诉列表table 列定义 */
type ComplaintListItem = {
  contactNumber?: number; //联系方式
  creatorRole?: any; //创建人角色
  description?: any;
  handleProcessingRemark?: any;
  installationTimeStatus?: any; //安装时间状态
  isBeta?: number;
  processMethodKey?: any; //处理方式
  recipientName?: any; //接单人姓名
  recipientNumber?: any; //接单人工号
  waitReceipt?: number; //待收货
  waitShipped?: number; //待发货
  withdraw?: any; //用户删除
  id: number; // 客诉单号
  area?: string; // 客诉所属区域
  processingPriority?: any; //处理优先级
  attachment?: string; // 附件1 名称
  attachment2?: string; // 附件2 名称
  attachment3?: string; // 附件3 名称
  attachment4?: string; // 附件4 名称
  attachment5?: string; // 附件4 名称
  attachment6?: string; // 附件4 名称
  complaintsType?: string; // 客诉问题类型
  createtime?: any; // 创建时间
  creator?: string; // 创建人
  key: string; //
  mbattime?: string; // 电池性能标准到期时间
  minvtime?: string; // 逆变器标准质保到期时间
  postCode?: string; // 邮编
  processingRate?: string; // 客诉进度
  processorGroup?: string; // 当前处理人所在部门/处理方组
  recipient?: string; // 接受处理人/处理方
  currentProcessor?: string;
  status?: number; // 客诉状态
  sysSn: string; // 系统SN
  title?: string; // 标题
  updatetime?: any; // 最后更新时间
  warrantyStatus?: any; // 质保状态
  isCreator: boolean; //是否当前人自己创建的客诉，如果是，则有删除权限
};

/* 客诉列表查询参数 */
let searchParams: any = {
  areas: '', //国家
  cpType: '', //问题类型
  creator: '', //客诉创建人
  creatorRole: '', //客诉创建人所属角色
  complaintStatus: '', // 客诉状态，以','号隔开
  createBegin: '', // 查询开始时间
  createEnd: '', // 查询结束时间
  keyword: '', // 关键字
  pageIndex: 1, // 当前查询页
  pageSize: 10, // 每页显示的数据条数
  processingRate: '', // 客诉进度
  sequence: 'desc', // 排序方式 asc正序 desc倒序
  sortBy: 'updatetime', // 排序字段 createtime,updatetime 创建时间和最后更新时间
  postCodes: '', //邮编
  processMethodKey: '', //处理方式
  processor: '', //当前处理人
  sysLicense: '', //license
  sysSn: '', //sn
  waitReceipt: undefined, //待收货
  waitShipped: undefined, //待发货
};

let postCodeArr: any = [];
const historicalComplaints: React.FC = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const { t } = useTranslation();
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let userIcdcId = useSelector((state: RootStateOrAny) => state.get('user').get('userIcdcId'));
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  const history = useHistory();
  /* 客诉列表的分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  /* 列表源数据,默认为空 */
  const [dataList, setDataList] = useState<ComplaintListItem[]>([]);
  /* 表格数据加载动画 */
  const [tableLoading, setTableLoading] = useState(false);
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 导出按钮的loading */
  const [exportLoading, setExportLoading] = useState(false);
  /* 国家列表源数据 */
  const [countryList, setCountryList] = useState<any>([]);
  /* 获取角色 */
  const [userRoleList, setUserRoleList] = useState<any>([]);

  /* 客诉状态数据源 */
  const options: SelectProps['options'] = [
    { label: t('complaintList.all'), value: '-1' },
    { label: t('complaintList.newCreate'), value: '0' },
    { label: t('complaintList.accepted'), value: '1' },
    { label: t('complaintList.processing'), value: '2' },
    { label: t('complaintList.waitVerification'), value: '3' },
    { label: t('complaintList.verificationing'), value: '4' },
    { label: t('complaintList.verificationCompleted'), value: '5' },
    { label: t('complaintList.reOpen'), value: '6' },
    { label: t('complaintList.completeOk'), value: '7' },
    { label: t('complaintList.comment'), value: '8' },
    { label: t('complaintList.finish'), value: '9' },
  ];

  /* 客诉类型数据源 */
  const typeOptions: SelectProps['options'] = [
    { label: t('complaintList.inverter'), value: 'title_inverter' },
    { label: t('complaintList.battery'), value: 'title_battery' },
    { label: t('complaintList.ammeter'), value: 'lab_meter' },
    { label: t('createComplaint.Backup_Box'), value: 'Backup_Box' },
    { label: t('createComplaint.EMS'), value: 'EMS' },
    { label: t('createComplaint.Monitoring'), value: 'lab_monitoring' },
    { label: t('complaintList.app'), value: 'App' },
    { label: t('complaintList.other'), value: 'lab_other' },
  ];
  /* 客诉进度数据源 */
  const processingRateOptions: SelectProps['options'] = [
    { label: t('complaintList.lab_installer_todoor'), value: 'lab_installer_todoor' },
    { label: t('complaintList.lab_installer_has_todoor'), value: 'lab_installer_has_todoor' },
    { label: t('complaintList.lab_sales_todoor'), value: 'lab_sales_todoor' },
    { label: t('complaintList.lab_sales_has_todoor'), value: 'lab_sales_has_todoor' },
    { label: t('complaintList.lab_to_replaced'), value: 'lab_to_replaced' },
    { label: t('complaintList.lab_has_replaced'), value: 'lab_has_replaced' },
    { label: t('complaintList.lab_to_shipped'), value: 'lab_to_shipped' },
    { label: t('complaintList.lab_has_Shipped'), value: 'lab_has_Shipped' },
    { label: t('complaintList.lab_to_pickedup'), value: 'lab_to_pickedup' },
    { label: t('complaintList.lab_has_pickedup'), value: 'lab_has_pickedup' },
    { label: t('complaintList.lab_to_repaired'), value: 'lab_to_repaired' },
    { label: t('complaintList.lab_has_repaired'), value: 'lab_has_repaired' },
    { label: t('complaintList.lab_bill_to_settled'), value: 'lab_bill_to_settled' },
    { label: t('complaintList.lab_confirmed_bill'), value: 'lab_confirmed_bill' },
    { label: t('complaintList.lab_wait_rd_analysis_data'), value: 'lab_wait_rd_analysis_data' },
  ];
  const solveOptions: SelectProps['options'] = [
    { label: t('complaintList.completed'), value: 'completed' },
    { label: t('complaintList.dropComplaintsProcessing'), value: 'title_battery' },
    { label: t('complaintList.dropComplaintsLogisticsDelivery'), value: 'logisticsDelivery' },
    { label: t('complaintList.dropComplaintsOnSiteSupport'), value: 'onSiteSupport' },
    { label: t('complaintList.dropComplaintsParcelPost'), value: 'parcelPost' },
    { label: t('complaintList.dropComplaintsWaitingRetrieved'), value: 'waitingRetrieved' },
  ];

  /* 列表字段 */
  const columns: ColumnsType<ComplaintListItem> = [
    /* 序号 */
    {
      title: t('complaintList.serialNumber2'),
      key: 'serialNumber',
      width: language === 'zh' || language === 'de' || language === 'ja' ? 90 : 180,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + index + 1;
      },
    },
    /* 用户删除 */
    {
      title: t('complaintList.userDelete'),
      key: 'withdraw',
      dataIndex: 'withdraw',
      width: 150,
      render: (_, record) => {
        return record.withdraw == '1' ? <span style={{ color: 'red' }}>{t('complaintList.deleted')}</span> : null;
      },
    },
    /* 处理方式 */
    {
      title: t('complaintList.allowToGoToClientsHomeLabel'),
      key: 'processMethodKey',
      dataIndex: 'processMethodKey',
      width: 150,
      render: (_, record) => [
        <Space key="processMethodKey">
          {record.processMethodKey == 'processing' ? <span>{t('complaintList.dropComplaintsProcessing')}</span> : null}
          {record.processMethodKey == 'logisticsDelivery' ? (
            <span>{t('complaintList.dropComplaintsLogisticsDelivery')}</span>
          ) : null}
          {record.processMethodKey == 'onSiteSupport' ? (
            <span>{t('complaintList.dropComplaintsOnSiteSupport')}</span>
          ) : null}
          {record.processMethodKey == 'parcelPost' ? <span>{t('complaintList.dropComplaintsParcelPost')}</span> : null}
          {record.processMethodKey == 'waitingRetrieved' ? (
            <span>{t('complaintList.dropComplaintsWaitingRetrieved')}</span>
          ) : null}
          {record.processMethodKey == 'completed' ? <span>{t('complaintList.completed')}</span> : null}
        </Space>,
      ],
    },
    /* 客诉单号 */
    {
      title: t('complaintList.number'),
      key: 'id',
      dataIndex: 'id',
      width: language === 'sp' || language === 'it' || language === 'en' ? 260 : 200,
      align: 'center',
      render: (_, record) => [
        <Space key="cpId">
          {record.id ? (
            <Tooltip title={record.id}>
              <a
                key="complaintTitle"
                className="customer-comp-list-ellipsis"
                onClick={(event) => jumpToCustomerComplaintDetails(event, record.key)}
              >
                {record.processingPriority == '-1' ? <Tag color="default">{t('complaintList.low')}</Tag> : null}
                {record.processingPriority == '0' ? <Tag color="warning">{t('complaintList.normal')}</Tag> : null}
                {record.processingPriority == '1' ? <Tag color="error">{t('complaintList.high')}</Tag> : null}
                {record.id}
              </a>
            </Tooltip>
          ) : null}
        </Space>,
      ],
    },
    /* 标题 */
    {
      title: t('complaintList.title'),
      key: 'title',
      dataIndex: 'title',
      width: 140,
      align: 'center',
      ellipsis: true,
      render: (_, record) => [
        <Space key="title">
          {record.title ? (
            <Tooltip title={record.title}>
              <a
                key="complaintTitle"
                className="customer-comp-list-ellipsis"
                onClick={(event) => jumpToCustomerComplaintDetails(event, record.key)}
              >
                {record.title}
              </a>
            </Tooltip>
          ) : null}
        </Space>,
      ],
    },
    /* 创建时间 */
    {
      title: t('complaintList.createtime'),
      key: 'createtime',
      dataIndex: 'createtime',
      width: 200,
      align: 'center',
      sorter: true,
    },
    /* 创建人 */
    {
      title: t('complaintList.creator'),
      key: 'creator',
      dataIndex: 'creator',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /* 角色 */
    {
      title: t('complaintList.roleName'),
      key: 'creatorRole',
      dataIndex: 'creatorRole',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /* 接单人工号 */
    {
      title: t('complaintList.handlingManualNumbers'),
      key: 'recipientNumber',
      dataIndex: 'recipientNumber',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /* 接单人姓名 */
    {
      title: t('complaintList.handlerName'),
      key: 'recipientName',
      dataIndex: 'recipientName',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /* 系统SN */
    {
      title: t('complaintList.sysSn'),
      key: 'sysSn',
      dataIndex: 'sysSn',
      width: 200,
      align: 'center',
    },
    /* 邮编 */
    {
      title: t('complaintList.postCode'),
      key: 'postCode',
      dataIndex: 'postCode',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /* 问题类型 */
    {
      title: t('complaintList.complaintsType'),
      key: 'complaintsType',
      dataIndex: 'complaintsType',
      width: 180,
      align: 'center',
      render: (_, record) => [
        <Space key="cpType">
          {record.complaintsType == 'title_inverter' ? <span>{t('complaintList.inverter')}</span> : null}
          {record.complaintsType == 'title_battery' ? <span>{t('complaintList.battery')}</span> : null}
          {record.complaintsType == 'lab_meter' ? <span>{t('complaintList.meter')}</span> : null}
          {record.complaintsType == 'Backup_Box' ? <span>{t('complaintList.Backup_Box')}</span> : null}
          {record.complaintsType == 'EMS' ? <span>{t('complaintList.ems')}</span> : null}
          {record.complaintsType == 'lab_monitoring' ? <span>{t('complaintList.monitor')}</span> : null}
          {record.complaintsType == 'APP' ? <span>{t('complaintList.app')}</span> : null}
          {record.complaintsType == 'lab_other' ? <span>{t('complaintList.other')}</span> : null}
        </Space>,
      ],
    },
    /* 客诉进度 */
    {
      title: t('complaintList.processingRate'),
      key: 'processingRate',
      dataIndex: 'processingRate',
      width: language === 'zh' || language === 'ja' ? 160 : 300,
      align: 'center',
      render: (_, record) => [
        <Space key="processingRate">
          {record.processingRate == 'lab_installer_todoor' ? (
            <span>{t('complaintList.lab_installer_todoor')}</span>
          ) : null}
          {record.processingRate == 'lab_installer_has_todoor' ? (
            <span>{t('complaintList.lab_installer_has_todoor')}</span>
          ) : null}
          {record.processingRate == 'lab_sales_todoor' ? <span>{t('complaintList.lab_sales_todoor')}</span> : null}
          {record.processingRate == 'lab_sales_has_todoor' ? (
            <span>{t('complaintList.lab_sales_has_todoor')}</span>
          ) : null}
          {record.processingRate == 'lab_to_replaced' ? <span>{t('complaintList.lab_to_replaced')}</span> : null}
          {record.processingRate == 'lab_has_replaced' ? <span>{t('complaintList.lab_has_replaced')}</span> : null}
          {record.processingRate == 'lab_to_shipped' ? <span>{t('complaintList.lab_to_shipped')}</span> : null}
          {record.processingRate == 'lab_has_Shipped' ? <span>{t('complaintList.lab_has_Shipped')}</span> : null}
          {record.processingRate == 'lab_to_pickedup' ? <span>{t('complaintList.lab_to_pickedup')}</span> : null}
          {record.processingRate == 'lab_has_pickedup' ? <span>{t('complaintList.lab_has_pickedup')}</span> : null}
          {record.processingRate == 'lab_to_repaired' ? <span>{t('complaintList.lab_to_repaired')}</span> : null}
          {record.processingRate == 'lab_has_repaired' ? <span>{t('complaintList.lab_has_repaired')}</span> : null}
          {record.processingRate == 'lab_bill_to_settled' ? (
            <span>{t('complaintList.lab_bill_to_settled')}</span>
          ) : null}
          {record.processingRate == 'lab_confirmed_bill' ? <span>{t('complaintList.lab_confirmed_bill')}</span> : null}
          {record.processingRate == 'lab_wait_rd_analysis_data' ? (
            <span>{t('complaintList.lab_wait_rd_analysis_data')}</span>
          ) : null}
        </Space>,
      ],
    },
    /* 待发货 */
    {
      title: t('complaintList.lab_to_shipped'),
      key: 'waitShipped',
      dataIndex: 'waitShipped',
      width: 150,
      align: 'center',
      render: (_, record) => [
        <Space key="waitShipped">
          {record.waitShipped == 0 ? <span className="newCreate">{t('common.no')}</span> : null}
          {record.waitShipped == 1 ? <span className="accepted">{t('common.yes')}</span> : null}
        </Space>,
      ],
    },
    /* 待收货 */
    {
      title: t('complaintList.lab_to_received'),
      key: 'waitReceipt',
      dataIndex: 'waitReceipt',
      width: 150,
      align: 'center',
      render: (_, record) => [
        <Space key="waitReceipt">
          {record.waitReceipt == 0 ? <span className="newCreate">{t('common.no')}</span> : null}
          {record.waitReceipt == 1 ? <span className="accepted">{t('common.yes')}</span> : null}
        </Space>,
      ],
    },
    /* 客诉状态 */
    {
      title: t('complaintList.complaintStatus'),
      key: 'status',
      dataIndex: 'status',
      width: language === 'zh' || language === 'it' ? 150 : 260,
      align: 'center',
      render: (_, record) => [
        <Space key="status">
          {record.status == 0 ? <span className="newCreate ">{t('customercomplaint.newCreate')}</span> : null}
          {record.status == 1 ? <span className="accepted ">{t('customercomplaint.accepted')}</span> : null}
          {record.status == 2 ? <span className="processing ">{t('complaintList.processing')}</span> : null}
          {record.status == 3 ? <span className="waitVerification ">{t('complaintList.waitVerification')}</span> : null}
          {record.status == 4 ? <span className="verificationing ">{t('complaintList.verificationing')}</span> : null}
          {record.status == 5 ? (
            <span className="completeverification ">{t('customercomplaint.completeverification')}</span>
          ) : null}
          {record.status == 6 ? <span className="reopen ">{t('complaintList.reOpen')}</span> : null}
          {record.status == 7 ? <span className="completeOk ">{t('complaintList.completeOk')}</span> : null}
          {record.status == 8 ? <span className="comment ">{t('complaintList.comment')}</span> : null}
          {record.status == 9 ? <span className="finish ">{t('complaintList.finish')}</span> : null}
        </Space>,
      ],
    },
    /* 质保状态 */
    {
      title: t('complaintList.warrantyStatus'),
      key: 'warrantyStatus',
      dataIndex: 'warrantyStatus',
      width: 150,
      align: 'center',
      render: (_, record) => [
        <Space key="warrantyStatus">
          {record.warrantyStatus == 0 ? (
            <span className="finish statusClass">{t('createComplaint.normal')}</span>
          ) : null}
          {record.warrantyStatus == 1 ? (
            <span className="warningRed statusClass">{t('createComplaint.abnormalOverWarranty')}</span>
          ) : null}
        </Space>,
      ],
    },
    /* 接受处理人/处理方 */
    {
      title: t('complaintList.recipient'),
      key: 'recipient',
      dataIndex: 'currentProcessor',
      width: 150,
      align: 'center',
    },
    /* 当前处理人所在部门/处理方组 */
    {
      title: t('complaintList.processorGroup'),
      key: 'processorGroup',
      dataIndex: 'processorGroup',
      width: language === 'sp' ? 200 : 170,
      align: 'center',
    },
    /* 国家/地区 */
    {
      title: t('complaintList.area'),
      key: 'area',
      dataIndex: 'area',
      width: 160,
      align: 'center',
    },
    /* 最后更新时间 */
    {
      title: t('complaintList.lastUpdatetime'),
      key: 'updatetime',
      dataIndex: 'updatetime',
      width: 250,
      align: 'center',
      sorter: true,
    },
    /* 逆变器标准质保到期时间 */
    {
      title: t('complaintList.minvtime'),
      key: 'minvtime',
      dataIndex: 'minvtime',
      width: language === 'zh' || language === 'ja' ? 200 : 450,
      align: 'center',
      render: (_, record) => [
        <Space key="minvtime">
          {record.minvtime && moment(record.minvtime).isBefore(new Date(), 'days') ? (
            <span style={{ backgroundColor: '#FF0000', color: '#ffffff', padding: '6px 10px', borderRadius: '4px' }}>
              {record.minvtime}
            </span>
          ) : (record.warrantyStatus === '1' || record.warrantyStatus === '2') && record.mbattime ? (
            <span style={{ backgroundColor: '#b8b8b8', color: '#ffffff', padding: '6px 10px', borderRadius: '4px' }}>
              {record.minvtime}
            </span>
          ) : (
            <span>{record.minvtime}</span>
          )}
        </Space>,
      ],
    },
    /* 电池性能标准到期时间 */
    {
      title: t('complaintList.mbattime'),
      key: 'mbattime',
      dataIndex: 'mbattime',
      width: language === 'zh' || language === 'ja' ? 190 : 480,
      align: 'center',
      render: (_, record) => [
        <Space key="mbattime">
          {record.mbattime && moment(record.mbattime).isBefore(new Date(), 'days') ? (
            <span style={{ backgroundColor: '#FF0000', color: '#ffffff', padding: '6px 10px', borderRadius: '4px' }}>
              {record.mbattime}
            </span>
          ) : (record.warrantyStatus === '1' || record.warrantyStatus === '2') && record.mbattime ? (
            <span style={{ backgroundColor: '#b8b8b8', color: '#ffffff', padding: '6px 10px', borderRadius: '4px' }}>
              {record.minvtime}
            </span>
          ) : (
            <span>{record.mbattime}</span>
          )}
        </Space>,
      ],
    },
    /* 安装时间状态 */
    {
      title: t('complaintList.installDateStatus'),
      key: 'installationTimeStatus',
      dataIndex: 'installationTimeStatus',
      width: language === 'de' || language === 'it' || language === 'en' || language === 'sp' ? 320 : 190,
      align: 'center',
      render: (_, record) => [
        <Space key="installationTimeStatus">
          {record.installationTimeStatus == 0 ? <span className="statusClass undefined">NA</span> : null}
          {record.installationTimeStatus == 1 ? (
            <span className="statusClass notConfirm">{t('complaintList.notConfirm')}</span>
          ) : null}
          {record.installationTimeStatus == 2 ? (
            <span className="statusClass waitConfirm">{t('complaintList.waitConfirm')}</span>
          ) : null}
          {record.installationTimeStatus == 3 ? (
            <span className="statusClass hasConfirm">{t('complaintList.hasConfirm')}</span>
          ) : null}
        </Space>,
      ],
    },
    /* 附件 */
    {
      title: t('complaintList.attachment'),
      key: 'option',
      align: 'center',
      width: 350,
      render: (_, record) => [
        <Space wrap key="option">
          {record.attachment ? (
            <a key="attachment" onClick={() => downloadAttachment(record.attachment)}>
              {t('complaintList.attachment1')}
            </a>
          ) : null}
          {record.attachment2 ? (
            <a key="attachment2" onClick={() => downloadAttachment(record.attachment2)}>
              {t('complaintList.attachment2')}
            </a>
          ) : null}
          {record.attachment3 ? (
            <a key="attachment3" onClick={() => downloadAttachment(record.attachment3)}>
              {t('complaintList.attachment3')}
            </a>
          ) : null}
          {record.attachment4 ? (
            <a key="attachment4" onClick={() => downloadAttachment(record.attachment4)}>
              {t('complaintList.attachment4')}
            </a>
          ) : null}
          {record.attachment5 ? (
            <a key="attachment5" onClick={() => downloadAttachment(record.attachment5)}>
              {t('complaintList.attachment5')}
            </a>
          ) : null}
          {record.attachment6 ? (
            <a key="attachment6" onClick={() => downloadAttachment(record.attachment6)}>
              {t('complaintList.attachment6')}
            </a>
          ) : null}
        </Space>,
      ],
    },
  ];

  useEffect(() => {
    getCountryLists(); /* 获取国家列表 */
    getRoleLists(); /* 获取用户角色列表 */
    // pageChange(pagingRecord.pageIndex, pagingRecord.pageSize)

    document.addEventListener('keydown', searchListBtn, false);
    return () => {
      window.removeEventListener('keydown', searchListBtn, false);
    };
  }, []);

  /* 页面挂载的时候，先判断url中是否有参数传入，如果有参数传入则说明是从其他页面跳转过来的，需要请求对应查询条件的数据，如果没有参数传入，则默认请求客诉列表的第一页数据 */
  useEffect(() => {
    if (document.location.search) {
      searchParams.areas = getUrlQuery('areas') || '';
      searchParams.cpType = getUrlQuery('cpType') || '';
      searchParams.creator = getUrlQuery('creator') || '';
      searchParams.creatorRole = getUrlQuery('creatorRole') || '';
      searchParams.complaintStatus = getUrlQuery('complaintStatus') || '';
      searchParams.createBegin = getUrlQuery('createBegin') || '';
      searchParams.createEnd = getUrlQuery('createEnd') || '';
      searchParams.keyword = getUrlQuery('keyword') || '';
      searchParams.pageIndex = parseInt(getUrlQuery('pageIndex')) || 1;
      searchParams.pageSize = parseInt(getUrlQuery('pageSize')) || 10;
      searchParams.processingRate = getUrlQuery('processingRate') || '';
      searchParams.sequence = getUrlQuery('sequence') || 'desc';
      searchParams.sortBy = getUrlQuery('sortBy') || 'updatetime';
      searchParams.postCodes = getUrlQuery('postCodes') || '';
      searchParams.processMethodKey = getUrlQuery('processMethodKey') || '';
      searchParams.processor = getUrlQuery('processor') || '';
      searchParams.sysLicense = getUrlQuery('sysLicense') || '';
      searchParams.sysSn = getUrlQuery('sysSn') || '';
      searchParams.waitReceipt = undefined;
      searchParams.waitShipped = undefined;

      if (searchForm) {
        searchForm.setFieldsValue({
          keyword: searchParams.keyword || null,
          processingRate: searchParams.processingRate || null,
          complaintStatus: searchParams.complaintStatus ? searchParams.complaintStatus.split(',') : [],
          dateRange: [
            searchParams.createBegin ? moment(searchParams.createBegin, 'YYYY-MM-DD') : null,
            searchParams.createEnd ? moment(searchParams.createEnd, 'YYYY-MM-DD') : null,
          ],
          areas: searchParams.areas ? searchParams.areas.split(',') : [],
          cpType: searchParams.cpType || null,
          creator: searchParams.creator || null,
          creatorRole: searchParams.creatorRole || null,
          postCodes: searchParams.postCodes || null,
          processMethodKey: searchParams.processMethodKey || null,
          processor: searchParams.processor || null,
          sysLicense: searchParams.sysLicense || null,
          sysSn: searchParams.sysSn || null,
          waitReceipt: searchParams.waitReceipt || null,
          waitShipped: searchParams.waitShipped || null,
        });
      }
      getComplaintList();
    } else {
      getComplaintList();
    }
    return () => {
      searchParams = {
        areas: '', //国家
        cpType: '', //问题类型
        creator: '', //客诉创建人
        creatorRole: '', //客诉创建人所属角色
        complaintStatus: '', // 客诉状态，以','号隔开
        createBegin: '', // 查询开始时间
        createEnd: '', // 查询结束时间
        keyword: '', // 关键字
        pageIndex: 1, // 当前查询页
        pageSize: 10, // 每页显示的数据条数
        processingRate: '', // 客诉进度
        sequence: 'desc', // 排序方式 asc正序 desc倒序
        sortBy: 'updatetime', // 排序字段 createtime,updatetime 创建时间和最后更新时间
        postCodes: '', //邮编
        processMethodKey: '', //处理方式
        processor: '', //当前处理人
        sysLicense: '', //license
        sysSn: '', //sn
        waitReceipt: null, //待收货
        waitShipped: null, //待发货
      };
      searchForm.resetFields();
    };
  }, []);

  /* 获取角色*/
  const getRoleLists = () => {
    getRoleList()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setUserRoleList([...rep.data]);
        } else {
          setUserRoleList([]);
        }
      })
      .catch((err) => {
        setUserRoleList([]);
      });
  };

  /* 获取国家列表数据 */
  const getCountryLists = () => {
    getCountryList()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setCountryList([...rep.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch((err) => {
        setCountryList([]);
      });
  };

  /* 处理邮编输入 */
  const handlePostCode = (event) => {
    let value = event.target.value;
    postCodeArr = [];
    if (value === null || value === undefined || value === '') {
      postCodeArr = [];
    } else if (value.indexOf(',') == -1) {
      postCodeArr.push(value);
    } else {
      postCodeArr = value.split(',');
      postCodeArr = postCodeArr.filter((item) => item !== '');
    }
    searchParams.postCodes = postCodeArr;
  };

  const searchListBtn = () => {
    let key: any = window.event ? window.event.keyCode : '';
    if (key == 13) {
      searchParams.pageIndex = 1;
      searchParams.keyword = searchForm.getFieldsValue().keyword ? searchForm.getFieldsValue().keyword.trim() : '';
      searchParams.processingRate = searchForm.getFieldsValue().processingRate
        ? searchForm.getFieldsValue().processingRate.trim()
        : '';
      if (typeof searchForm.getFieldsValue().complaintStatus === 'string') {
        if (searchForm.getFieldsValue().complaintStatus == '') {
          searchParams.complaintStatus = '';
        }
      } else {
        if (searchForm.getFieldsValue().complaintStatus instanceof Array) {
          searchParams.complaintStatus =
            searchForm.getFieldsValue().complaintStatus.length > 0
              ? searchForm.getFieldsValue().complaintStatus.join(',')
              : '';
        }
      }
      if (typeof searchForm.getFieldsValue().areas === 'string') {
        if (searchForm.getFieldsValue().areas == '') {
          searchParams.areas = '';
        }
      } else {
        if (searchForm.getFieldsValue().areas instanceof Array) {
          searchParams.areas =
            searchForm.getFieldsValue().areas.length > 0 ? searchForm.getFieldsValue().areas.join(',') : '';
        }
      }
      searchParams.createBegin =
        searchForm.getFieldsValue().dateRange && searchForm.getFieldsValue().dateRange[0]
          ? moment(searchForm.getFieldsValue().dateRange[0]).format('YYYY-MM-DD')
          : '';
      searchParams.createEnd =
        searchForm.getFieldsValue().dateRange && searchForm.getFieldsValue().dateRange[1]
          ? moment(searchForm.getFieldsValue().dateRange[1]).format('YYYY-MM-DD')
          : '';
      getComplaintList();
    }
  };

  /* 下载客诉附件  */
  const downloadAttachment = (fileName?: string) => {
    let params = {
      blobContainerName: 'COMPLAINTS',
      fileBlobName: fileName,
    };
    fileDownload(params).then((res: any) => {
      blobDownloadFile(res, fileName);
    });
  };

  /* 获取当前用户的客诉列表 */
  const getComplaintList = () => {
    setTableLoading(true);
    getNoClientComplaints(searchParams)
      .then((res: any) => {
        setTableLoading(false);
        if (res && res.code === 200 && res.data && res.data.rows && res.data.rows.length > 0) {
          setDataList([...res.data.rows]);
          let pageInfo = {
            pageIndex: res.data.pageIndex,
            pageSize: res.data.pageSize,
            total: res.data.total,
          };
          setPagingRecord({ ...pageInfo });
        } else {
          setDataList([]);
          let pageInfo = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pageInfo });
        }
      })
      .catch((err) => {
        setTableLoading(false);
        setDataList([]);
        let pageInfo = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pageInfo });
      });
  };

  /* 客诉列表分页查询 */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    let values: any = searchForm.getFieldsValue();
    searchParams.keyword = values.keyword && values.keyword.trim() ? values.keyword.trim() : '';
    searchParams.processor = values.processor && values.processor.trim() ? values.processor.trim() : '';
    if (typeof values.complaintStatus === 'string') {
      if (values.complaintStatus == '') {
        searchParams.complaintStatus = '';
      }
    } else {
      if (values.complaintStatus instanceof Array) {
        searchParams.complaintStatus = values.complaintStatus.length > 0 ? values.complaintStatus.join(',') : '';
      }
    }
    if (typeof values.areas === 'string') {
      if (values.areas == '') {
        searchParams.areas = '';
      }
    } else {
      if (values.areas instanceof Array) {
        searchParams.areas = values.areas.length > 0 ? values.areas.join(',') : '';
      }
    }
    searchParams.cpType = values.cpType && values.cpType.trim() ? values.cpType.trim() : '';
    searchParams.creator = values.creator && values.creator.trim() ? values.creator.trim() : '';
    searchParams.sysLicense = values.sysLicense && values.sysLicense.trim() ? values.sysLicense.trim() : '';
    searchParams.sysSn = values.sysSn && values.sysSn.trim() ? values.sysSn.trim() : '';
    searchParams.processingRate =
      values.processingRate && values.processingRate.trim() ? values.processingRate.trim() : '';
    searchParams.processMethodKey =
      values.processMethodKey && values.processMethodKey.trim() ? values.processMethodKey.trim() : '';
    searchParams.createBegin =
      values.dateRange && values.dateRange[0] ? moment(values.dateRange[0]).format('YYYY-MM-DD') : '';
    searchParams.createEnd =
      values.dateRange && values.dateRange[1] ? moment(values.dateRange[1]).format('YYYY-MM-DD') : '';
    searchParams.waitShipped = values.waitShipped && values.waitShipped.trim() ? values.waitShipped.trim() : '';
    searchParams.waitReceipt = values.waitReceipt && values.waitReceipt.trim() ? values.waitReceipt.trim() : '';
    searchParams.creatorRole = values.creatorRole && values.creatorRole.trim() ? values.creatorRole.trim() : '';
    searchParams.postCodes = values.postCodes && values.postCodes.trim() ? values.postCodes.trim() : '';
    getComplaintList();
  }, []);

  /* 修改每页显示的数据量 */
  const sizeChange = (current, pageSize) => {
    searchParams.pageSize = pageSize;
    getComplaintList();
  };

  /* 后端排序查询功能 */
  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.field === 'updatetime' && sorter.order === 'descend') {
      searchParams.sortBy = 'updatetime';
      searchParams.sequence = 'desc';
    } else if (sorter.field === 'updatetime' && sorter.order === 'ascend') {
      searchParams.sortBy = 'updatetime';
      searchParams.sequence = 'asc';
    } else if (sorter.field === 'createtime' && sorter.order === 'descend') {
      searchParams.sortBy = 'createtime';
      searchParams.sequence = 'desc';
    } else if (sorter.field === 'createtime' && sorter.order === 'ascend') {
      searchParams.sortBy = 'createtime';
      searchParams.sequence = 'asc';
    } else {
      searchParams.sortBy = 'updatetime';
      searchParams.sequence = 'desc';
    }
    getComplaintList();
  };

  /* 按钮查询客诉列表  */
  const search = useCallback((values: any) => {
    let paging = {
      pageIndex: 1,
      pageSize: 10,
      total: pagingRecord.total,
    };
    setPagingRecord({ ...paging });

    searchParams.keyword = values.keyword && values.keyword.trim() ? values.keyword.trim() : '';
    searchParams.processor = values.processor && values.processor.trim() ? values.processor.trim() : '';
    if (typeof values.complaintStatus === 'string') {
      if (values.complaintStatus == '') {
        searchParams.complaintStatus = '';
      }
    } else {
      if (values.complaintStatus instanceof Array) {
        searchParams.complaintStatus = values.complaintStatus.length > 0 ? values.complaintStatus.join(',') : '';
      }
    }
    if (typeof values.areas === 'string') {
      if (values.areas == '') {
        searchParams.areas = '';
      }
    } else {
      if (values.areas instanceof Array) {
        searchParams.areas = values.areas.length > 0 ? values.areas.join(',') : '';
      }
    }
    searchParams.cpType = values.cpType && values.cpType.trim() ? values.cpType.trim() : '';
    searchParams.creator = values.creator && values.creator.trim() ? values.creator.trim() : '';
    searchParams.sysLicense = values.sysLicense && values.sysLicense.trim() ? values.sysLicense.trim() : '';
    searchParams.sysSn = values.sysSn && values.sysSn.trim() ? values.sysSn.trim() : '';
    searchParams.processingRate =
      values.processingRate && values.processingRate.trim() ? values.processingRate.trim() : '';
    searchParams.processMethodKey =
      values.processMethodKey && values.processMethodKey.trim() ? values.processMethodKey.trim() : '';
    searchParams.createBegin =
      values.dateRange && values.dateRange[0] ? moment(values.dateRange[0]).format('YYYY-MM-DD') : '';
    searchParams.createEnd =
      values.dateRange && values.dateRange[1] ? moment(values.dateRange[1]).format('YYYY-MM-DD') : '';
    searchParams.waitShipped = values.waitShipped && values.waitShipped.trim() ? values.waitShipped.trim() : '';
    searchParams.waitReceipt = values.waitReceipt && values.waitReceipt.trim() ? values.waitReceipt.trim() : '';
    searchParams.creatorRole = values.creatorRole && values.creatorRole.trim() ? values.creatorRole.trim() : '';
    searchParams.postCodes = values.postCodes && values.postCodes.trim() ? values.postCodes.trim() : '';

    getComplaintList();
  }, []);

  /* 列表数据导出 */
  const exportList = () => {
    setExportLoading(true);
    let params: any = {
      keyword: searchForm.getFieldsValue().keyword || '',
      complaintStatus: searchForm.getFieldsValue().complaintStatus || '',
      cpType: searchForm.getFieldsValue().cpType || '',
      createBegin:
        searchForm.getFieldsValue().dateRange && searchForm.getFieldsValue().dateRange[0]
          ? moment(searchForm.getFieldsValue().dateRange[0]).format('YYYY-MM-DD')
          : '',
      createEnd:
        searchForm.getFieldsValue().dateRange && searchForm.getFieldsValue().dateRange[1]
          ? moment(searchForm.getFieldsValue().dateRange[1]).format('YYYY-MM-DD')
          : '',
      creator: searchForm.getFieldsValue().creator || '',
      creatorRole: searchForm.getFieldsValue().creatorRole || '',
      postCodes: searchForm.getFieldsValue().postCodes || '',
      processingRate: searchForm.getFieldsValue().processingRate || '',
      processMethodKey: searchForm.getFieldsValue().processMethodKey || '',
      processor: searchForm.getFieldsValue().processor || '',
      sysLicense: searchForm.getFieldsValue().sysLicense || '',
      sysSn: searchForm.getFieldsValue().sysSn || '',
      waitReceipt: searchForm.getFieldsValue().waitReceipt || '',
      waitShipped: searchForm.getFieldsValue().waitShipped || '',
      language: 'zh-CN',
      sequence: 'desc',
      sortBy: 'updatetime',
    };
    if (typeof searchForm.getFieldsValue().complaintStatus === 'string') {
      if (searchForm.getFieldsValue().complaintStatus == '') {
        params.complaintStatus = '';
      }
    } else {
      if (searchForm.getFieldsValue().complaintStatus instanceof Array) {
        params.complaintStatus =
          searchForm.getFieldsValue().complaintStatus.length > 0
            ? searchForm.getFieldsValue().complaintStatus.join(',')
            : '';
      }
    }
    if (typeof searchForm.getFieldsValue().areas === 'string') {
      if (searchForm.getFieldsValue().areas == '') {
        params.areas = '';
      }
    } else {
      if (searchForm.getFieldsValue().areas instanceof Array) {
        params.areas = searchForm.getFieldsValue().areas.length > 0 ? searchForm.getFieldsValue().areas.join(',') : '';
      }
    }
    exportNoClientComplaints(params)
      .then((res) => {
        blobDownloadFile(res);
        setExportLoading(false);
      })
      .catch((error) => {
        setExportLoading(false);
      });
  };

  /* 点击客诉列表中的某一条数据，跳转至客诉的详情页  */
  const jumpToCustomerComplaintDetails = (event, id: any) => {
    let paramsStr: any = '';
    Object.keys(searchParams).forEach((key) => {
      paramsStr += '&' + key + '=' + searchParams[key];
    });
    if (history.location.pathname.indexOf('/innerStatic') < 0) {
      localStorage.setItem('pathname', history.location.pathname);
    }
    if (event && event.ctrlKey) {
      window.open(`/innerStatic/historicalComplaintsDetails/index?key=${id + paramsStr}`, '_blank');
    } else {
      history.push({
        pathname: '/innerStatic/historicalComplaintsDetails/index',
        search: 'key=' + id + paramsStr,
      });
    }
  };

  return (
    /* 客诉列表 */
    <>
      <div className="custom-contant">
        <Form
          form={searchForm}
          name="searchForm"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12}>
            {/* 关键字 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="keyword">
                <Input allowClear maxLength={128} placeholder={`${t('complaintList.keyword')}`} />
              </Form.Item>
            </Col>
            {/* 处理方 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="processor">
                <Input allowClear maxLength={128} placeholder={`${t('complaintList.recipient')}`} />
              </Form.Item>
            </Col>
            {/* 服务商Id */}
            {/* <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="servicerId">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={'All'}
                  options={processingRateOptions}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation()
                    }
                  }}
                />
              </Form.Item>
            </Col> */}
            {/* 客诉状态 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="complaintStatus">
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`${t('complaintList.complaintStatus')}`}
                  options={options}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {/* 客诉类型 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="cpType">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={'客诉类型'}
                  options={typeOptions}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {/* 国家/地区 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="areas">
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`${t('complaintList.area')}`}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countryList &&
                    countryList.length > 0 &&
                    countryList.map((item: any, index: any) => {
                      return (
                        <Option value={item.areaEnglishName} key={index}>
                          {`${item.areaEnglishName}[${item.areaFirstName}]`}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* 创建人 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="creator">
                <Input allowClear maxLength={64} placeholder={`${t('complaintList.creator')}`} />
              </Form.Item>
            </Col>
            {/* License */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="sysLicense">
                <Input allowClear maxLength={64} placeholder={'License'} />
              </Form.Item>
            </Col>
            {/* SN */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="sysSn">
                <Input allowClear maxLength={128} placeholder={`${t('complaintList.sysSn')}`} />
              </Form.Item>
            </Col>
            {/* 客诉进度 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="processingRate">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`${t('complaintList.processingRate')}`}
                  options={processingRateOptions}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {/* 处理方式 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="processMethodKey">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`${t('complaintList.allowToGoToClientsHomeLabel')}`}
                  options={solveOptions}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {/* 查询时间 */}
            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="padding-tope-style">
              <Form.Item name="dateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('complaintList.complaintCreateBegin'), t('complaintList.complaintCreateEnd')]}
                  allowClear
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            {/* 选择是否待发货 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="waitShipped">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`${t('complaintList.choose_lab_to_shipped')}`}
                  options={[
                    { value: '1', label: t('common.yes') },
                    { value: '0', label: t('common.no') },
                  ]}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {/* 是否待收货 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="waitReceipt">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`${t('complaintList.choose_lab_to_received')}`}
                  options={[
                    { value: '1', label: t('common.yes') },
                    { value: '0', label: t('common.no') },
                  ]}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {/* 用户角色 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="creatorRole">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`${t('complaintList.userRole')}`}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  }}
                >
                  {userRoleList &&
                    userRoleList.length > 0 &&
                    userRoleList.map((item: any, index: any) => {
                      return (
                        <Option value={item.rolename} key={item.id}>
                          {item.rolename}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* 邮编 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="padding-tope-style">
              <Form.Item name="postCodes">
                <Input
                  allowClear
                  maxLength={128}
                  onChange={handlePostCode}
                  placeholder={`${t('complaintList.SeparateMultiplePostCodeWithSemicolons')}`}
                />
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className="padding-tope-style">
              <Form.Item>
                <Space size="middle" wrap key="operation">
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {userIcdcId != 1 ? (
                    <>
                      {/* 导出 */}
                      <Button
                        type="primary"
                        key="export"
                        shape="round"
                        htmlType="button"
                        onClick={exportList}
                        loading={exportLoading}
                      >
                        {t('common.export')}
                      </Button>
                    </>
                  ) : null}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          sortDirections={['ascend', 'descend']}
          onChange={(pagination, filters, sorter, extra) => {
            handleTableChange(pagination, filters, sorter, extra);
          }}
          columns={columns}
          loading={tableLoading}
          rowKey={(record: any) => record.id}
          pagination={{
            showQuickJumper: true,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            showSizeChanger: true,
            onShowSizeChange: sizeChange,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 3220, y: window.innerHeight - 350 }}
          className="custom-table-flex"
        />
      </div>
    </>
  );
};

export default memo(historicalComplaints);
