import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Row, Select, Space, Tooltip } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import '../../index.less';
import { authorize, getCode } from '@/api/login';
import { useInterval } from 'ahooks';
import { setToken } from '@/utils/localStoreUtil';
import { updateToken } from '@/actions/app';
import Header from '../Header';

interface IMFAVerifyProps {
  data: any;
  handleMFA: () => void;
  getUserInfoPage: (currentToken: string) => void;
}
interface IState {
  MFAList: { value: number; label: string }[];
}
interface IFormItem {
  code: string;
  methodType: number;
}
const MFAVerify = (props: IMFAVerifyProps) => {
  const { data, handleMFA, getUserInfoPage } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<IFormItem>();
  const methodType = useWatch('methodType', form);
  const [count, setCount] = useState(0);
  const [interval, setInterval] = useState<number | undefined>();
  const [state, setState] = useState<IState>({ MFAList: [] });
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));

  useEffect(() => {
    const arr: { value: number; label: string }[] = [];
    data?.mfaCfs?.map((item) => {
      if (item.methodType === 1 && item.enable) {
        arr.push({ value: 1, label: t('yhxxsz.googleVerify') });
      }
      if (item.methodType === 2 && item.enable) {
        arr.push({ value: 2, label: t('register.mailboxVerification') });
      }
      setState({ MFAList: arr });
      form.setFieldValue('methodType', arr[0].value);
    });
  }, [language]);

  /**
   * @Author: Mars
   * @description: 获取验证码监听
   * @return {*}
   */
  const handleGetCode = (type: number) => {
    getCode({ code: data.code, method_type: type }).then((rep) => {
      if (rep.code === '000000') {
        setInterval(1000);
        setCount(60);
      } else if (rep.code === '300051') {
        message.error(rep.message);
        setTimeout(() => {
          handleMFA();
        }, 1000);
      } else {
        message.error(rep.message);
      }
    });
  };

  const clear = useInterval(() => {
    setCount(count - 1);
    if (count === 0) {
      clear();
      setInterval(undefined);
    }
  }, interval);
  const onFinish = () => {
    form.validateFields().then((values) => {
      const params: any = {
        mfa_code: values.code.trim(),
        method_type: values.methodType,
        code: data.code,
        grant_type: 'mfa',
        client_id: data.clientId,
      };
      authorize(params).then((rep) => {
        if (rep.code === '000000') {
          setToken(rep.data.access_token);
          dispatch(updateToken(rep.data.access_token));
          getUserInfoPage(rep.data.access_token);
        } else if (rep.code === '116141') {
          message.error(rep.message);
          setTimeout(() => {
            handleMFA();
          }, 1000);
        } else {
          message.error(rep.message);
        }
      });
    });
  };

  return (
    <>
      <div className="login-container">
        <Form form={form} layout="vertical" name="login-from" className="login-from mfa-form">
          {/* logo */}
          <Row>
            {language === 'zh' ? (
              <img src="/common/loginImages/new_logo.png" width="110" />
            ) : (
              <img src="/common/loginImages/new_logo_en.png" width="110" />
            )}
          </Row>
          <h3 style={{ color: 'rgba(75, 73, 72, 0.88)', padding: '40px 0', textAlign: 'center', fontWeight: 600 }}>
            {t('yhxxsz.multiFactorAuthenticate')}
          </h3>
          <Form.Item name="methodType" rules={[{ required: true }]}>
            <Select
              size="large"
              options={state.MFAList}
              onChange={(value) => {
                form.resetFields();
                form.setFieldsValue({ methodType: value });
              }}
            />
          </Form.Item>
          {/* 验证码 */}
          <Form.Item
            name="code"
            rules={[
              { required: true, message: `${t('yhxxsz.verificationCodeRequired')}`, whitespace: true },
              { transform: (val) => val.trim() },
            ]}
          >
            <Space.Compact style={{ width: '100%' }}>
              <Input placeholder={`${t('yhxxsz.verificationCode')}`} style={{ height: '40px', fontSize: '16px' }} />
              {methodType === 2 && (
                <Button
                  style={{ height: '40px', fontSize: '16px' }}
                  type="primary"
                  disabled={Boolean(interval)}
                  onClick={() => {
                    handleGetCode(2);
                  }}
                >
                  {interval ? (
                    count
                  ) : (
                    <Tooltip title={t('yhxxsz.getVerificationCode')}>
                      <SendOutlined />
                    </Tooltip>
                  )}
                </Button>
              )}
            </Space.Compact>
          </Form.Item>
          <Form.Item>
            <Button shape="round" type="primary" size="large" block onClick={onFinish}>
              {t('common.next')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default MFAVerify;
