/*
 * @Description:
 * @Author: XuAnjie
 * @Date: 2024-03-22 13:56:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/11/19
 */
import { memo } from 'react';
import { ComplaintListStatus, isIndustryAndCommerce } from '@/utils/utils';

export const goCustomerComplaintDetails = (searchParams: any, record: any, history: any) => {
  if (localStorage.getItem('messageKey')) {
    localStorage.setItem('messageKey', '');
  }
  let paramsStr: any = '';
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key] !== undefined) {
      paramsStr += '&' + key + '=' + searchParams[key];
    }
  });
  if (history.location.pathname.indexOf('/innerStatic') < 0) {
    localStorage.setItem('pathname', history.location.pathname);
  }
  let urlParams = '';
  switch (isIndustryAndCommerce()) {
    case ComplaintListStatus.consumerComplaintList:
      urlParams = '&consumerComplaintList=1';
      break;
    case ComplaintListStatus.listIndustrialAndCommercialCustomerComplaintsPage:
      urlParams = '&listIndustrialAndCommercialCustomerComplaintsPage=1';
      break;
    default:
      urlParams = '&userComplaintListPage=1';
      break;
  }
  history.push({
    pathname: '/innerStatic/customerComplaintDetails/index',
    search: 'key=' + record.id + '&workOrderId=' + record.workOrderId + paramsStr + urlParams,
  });
};
