/*
 * @Description: 国家地区组件
 * @Author: Summer.Chen
 * @Date: 2024-04-08 16:16:43
 * @LastEditTime: 2024-04-24 17:34:57
 * @LastEditors: Summer.Chen
 */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import type { TransferDirection } from 'antd/es/transfer';
import { queryCountryAuthByDeptId, editCountryPerm } from '@/api/authorityManagement';
import { useTranslation } from 'react-i18next';
import { Radio, Transfer, Row, Col, Form, message } from 'antd';
import { useDispatch } from 'react-redux';
import { updateIsSetting } from '@/actions/app';
// 定义数据类型
interface RecordType {
  key: string;
  title: string;
}

const CountryArea: React.FC<any> = forwardRef((props, ref): React.ReactElement => {
  const [showCheckRadio, setCheckRadio] = useState(true);
  const [form] = Form.useForm();
  let { item, open, type, activeKey } = props;
  const { t } = useTranslation();
  const [transferData, setTransferData] = useState<RecordType[]>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const dispatch = useDispatch();
  const onChange = (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    form.setFieldValue('countryName', nextTargetKeys);
    setTargetKeys(nextTargetKeys);
  };
  //父组件调取子组件方法
  useImperativeHandle(ref, () => ({
    refHandler,
  }));
  const refHandler = (activeKey: string) => {
    if (activeKey === '1') {
      form.submit();
    }
  };
  const sumbitForm = (values) => {
    let countryName: string[] = [];
    const authCategory = form.getFieldValue('authCategory');
    if (authCategory === 2) {
      countryName = values.countryName;
    }
    editCountryPerm({ authCategory, countryName, deptId: item.deptId }).then((rep) => {
      if (rep && rep.code == 200) {
        queryData();
        // dispatch(updateIsSetting(true));
        message.success(t('common.operateSuccess'));
      }
    });
  };
  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  const queryData = () => {
    queryCountryAuthByDeptId({ deptId: item.deptId })
      .then((rep: any) => {
        if (rep && rep.data) {
          dispatch(updateIsSetting(Boolean(rep.data.isSetting)));
          setCheckRadio(Boolean(rep.data.isViewChoose));
        }
        // 临时存放过滤的数据
        let tempArr: {}[] = [];
        //回显数据
        if (rep && rep.code == 200 && rep.data && rep.data.selfArea) {
          // 过滤掉脏数据
          tempArr = rep.data.selfArea.filter((filterItem) => filterItem.authCategory === 2);
          setTargetKeys(tempArr.map((selfAreaItem) => selfAreaItem.authName));
          form.setFieldValue(
            'countryName',
            tempArr.map((selfAreaItem) => selfAreaItem.authName),
          );
          if (rep.data.selfArea.some((selfAreaItem) => selfAreaItem.authCategory === 2)) {
            form.setFieldValue('authCategory', 2);
          } else if (rep.data.selfArea.some((selfAreaItem) => selfAreaItem.authCategory === 1)) {
            form.setFieldValue('authCategory', 1);
          }
        } else {
          form.setFieldValue('authCategory', 1);
          setTargetKeys([]);
          form.setFieldValue('countryName', []);
        }
        if (rep && rep.code == 200 && rep.data && rep.data.leftArea) {
          // 设置穿梭框左边的数据
          setTransferData(
            [...rep.data.leftArea, ...tempArr].map((leftAreaItem) => ({
              key: leftAreaItem.authName,
              title: leftAreaItem.authName,
            })),
          );
        } else {
          setTransferData([]);
        }
        if (rep && rep.data && !rep.data.isViewChoose) {
          form.setFieldValue('authCategory', 2);
        } else if (rep.code !== 200 || !rep.data.selfArea || !rep.data.selfArea.length) {
          form.setFieldValue('authCategory', 3);
        }
      })
      .catch((error) => {
        // 初始化
        form.setFieldValue('authCategory', 3);
        dispatch(updateIsSetting(false));
      });
  };
  const filterOption = (inputValue: string, option: RecordType) => option.title.indexOf(inputValue) > -1;
  useEffect(() => {
    if (activeKey !== '1') {
      return;
    }
    if (!item.deptId) {
      return;
    }
    if (!open) {
      return;
    }
    queryData();
  }, [open, activeKey]);
  const initData = () => {};
  return (
    <section>
      <Row gutter={[30, 30]}>
        <Form form={form} onFinish={sumbitForm} layout={'vertical'}>
          {showCheckRadio ? (
            <Col span={24}>
              <Form.Item name="authCategory" label={t('authorityManagement.selectionRange')}>
                <Radio.Group disabled={type === 1}>
                  <Radio value={1}>{t('common.all')}</Radio>
                  <Radio value={2}>{t('authorityManagement.specify')}</Radio>
                  <Radio value={3}>{t('authorityManagement.nothing')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          ) : null}

          <Col span={24}>
            {/* 穿梭框 */}
            <Form.Item
              noStyle
              label={t('common.area')}
              shouldUpdate={(prevValues, curValues) => prevValues.authCategory !== curValues.authCategory}
            >
              {({ getFieldValue }) => {
                const authCategory: number = getFieldValue('authCategory');

                return authCategory === 2 ? (
                  <Form.Item
                    name="countryName"
                    label=""
                    rules={[
                      {
                        required: true,
                        message: t('common.pleaseSelectCountry_Region') as string,
                      },
                    ]}
                  >
                    <Transfer
                      showSearch
                      listStyle={{
                        width: 250,
                        height: 300,
                      }}
                      disabled={type === 1}
                      dataSource={transferData}
                      filterOption={filterOption}
                      targetKeys={targetKeys}
                      selectedKeys={selectedKeys}
                      onChange={onChange}
                      onSelectChange={onSelectChange}
                      render={(item) => item.title}
                      oneWay={true}
                    />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    </section>
  );
});

export default CountryArea;
