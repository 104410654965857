/*
 * @Description:同步结果
 * @Author: April
 * @Date: 2023-04-10 10:32:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025/03/20
 */

import { getSyncResultPage, getSyncResult, retrySync } from '@/api/syncResult';
import { inputValueLimit } from '@/constants/inputValueLimit';
import { Button, Col, Form, Input, Row, Select, Space, Table, TableColumnsType, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

// 列表字段类型定义
interface DataType {
  id: string;
  apiType: string;
  params: string;
  requestError: string;
  requestErrorFull: string;
  requestHeadParms: number;
  requestMethod: string;
  requestStatus: string;
  requestUrl: string;
  responseParams: string;
}

/* 列表查询参数 */
let searchParams: any = {
  pageSize: 10 /* 每页显示条数 */,
  pageIndex: 1 /* 当前页 */,
  apiType: '' /* 接口类型 */,
  requestStatus: '' /* 同步结果 */,
};

const sysDataMigration: React.FC = () => {
  const { t } = useTranslation();

  /* table的loading */
  const [tableLoading, setTableLoading] = useState(false);
  const [detailTableLoading, setDetailTableLoading] = useState(false);
  /* 列表源数据 */
  const [dataList, setDataList] = useState<DataType[]>();
  /* 嵌套子表格的数据源 */
  const [subDataList, setSubDataList] = useState<DataType[]>();
  /* 提交表单的ref */
  const [searchForm] = Form.useForm();
  /* 分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  const [expandedRowKeys, setExpandedRowKeys] = useState(['']);

  /* 主表格列 */
  const columns: ColumnsType<DataType> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      width: 120,
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /** 客诉单号 */
    {
      title: t('complaintList.number'),
      dataIndex: 'ticketNumber',
      width: 200,
      ellipsis: true,
      align: 'left',
    },
    /* 接口类型 */
    {
      title: t('synResult.interfaceType'),
      dataIndex: 'apiType',
      key: 'apiType',
      ellipsis: true,
      width: 200,
    },
    /** SN */
    {
      title: 'SN',
      dataIndex: 'sysSn',
      width: 200,
      ellipsis: true,
    },
    /* 请求方式 */
    {
      title: t('synResult.requestMethod'),
      dataIndex: 'requestMethod',
      key: 'requestMethod',
      ellipsis: true,
      width: 200,
      align: 'left',
    },
    /** 客诉标题 */
    {
      title: t('createComplaint.ComplaintTitle'),
      dataIndex: 'title',
      width: 200,
      ellipsis: true,
    },
    /* 请求参数 */
    {
      title: t('synResult.requestParams'),
      dataIndex: 'params',
      key: 'params',
      ellipsis: true,
      width: 200,
      align: 'left',
    },
    /* 请求状态 */
    {
      title: t('synResult.requestStatus'),
      dataIndex: 'syncResult',
      key: 'syncResult',
      ellipsis: true,
      width: 200,
      align: 'left',
      render: (_, record: any) => {
        return record.syncResult ? <span>{t('synResult.success')}</span> : <span>{t('synResult.fail')}</span>;
      },
    },
    /* 操作 */
    {
      title: t('common.operation'),
      key: 'option',
      align: 'center',
      width: 120,
      render: (text: any, record: any, index: any) => {
        return (
          <Space key="option" size="middle" wrap>
            {/* 同步 */}
            {!record.syncResult ? (
              <a
                style={{
                  color: '#FABF00',
                }}
                key="edit"
                onClick={() => {
                  handleSync(record.id);
                }}
              >
                {t('synResult.retry')}
              </a>
            ) : null}
          </Space>
        );
      },
    },
  ];
  /* 嵌套子表格列 */
  const expandedRowRender = (expanded) => {
    const detailColumns: TableColumnsType<DataType> = [
      /* 同步状态 */
      {
        title: t('synResult.requestStatus'),
        dataIndex: 'syncStatus',
        key: 'syncStatus',
        ellipsis: true,
        width: 200,
        align: 'left',
        render: (_, record: any) => {
          return record.syncStatus ? (
            <span>{t('synResult.completeSync')}</span>
          ) : (
            <span>{t('synResult.waitSync')}</span>
          );
        },
      },
      /* 请求状态 */
      {
        title: t('synResult.requestStatus'),
        dataIndex: 'requestStatus',
        key: 'requestStatus',
        ellipsis: true,
        width: 150,
        align: 'left',
      },
      /* 响应参数 */
      {
        title: t('synResult.reponseParams'),
        dataIndex: 'responseParams',
        key: 'responseParams',
        ellipsis: true,
        width: 200,
        align: 'left',
      },
      /* 错误日志 */
      {
        title: t('synResult.errorLog'),
        dataIndex: 'requestError',
        key: 'requestError',
        ellipsis: true,
        width: 200,
        align: 'left',
      },
      /* 完整错误日志 */
      {
        title: t('synResult.allErrorLog'),
        dataIndex: 'requestErrorFull',
        key: 'requestErrorFull',
        ellipsis: true,
        width: 200,
        align: 'left',
      },
    ];

    return (
      <Table
        columns={detailColumns}
        dataSource={subDataList}
        pagination={false}
        className="detail-table-flex"
        scroll={{ x: 1300 }}
        loading={detailTableLoading}
        rowKey="id"
      />
    );
  };
  /**
   * @description: 根据分页，初始化列表数据
   * @return {*}
   * @LastEditors: April
   */
  useEffect(() => {
    pageChange(pagingRecord.pageIndex, pagingRecord.pageSize);
  }, []);

  /**
   * @description: 分页的回调函数
   * @param {*} useCallback
   * @return {*}
   * @LastEditors: April
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;

    let params: any = searchForm.getFieldsValue();
    searchParams = { ...searchParams, ...params };
    getTableData();
  }, []);
  /**
   * @description: 获取列表数据
   * @return {*}
   * @LastEditors: April
   */
  const getTableData = () => {
    setTableLoading(true);
    getSyncResultPage(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setDataList(rep.data.rows);
          let pageInit = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...pageInit });
        } else {
          setDataList([]);
          let pageInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pageInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pageInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pageInit });
        setTableLoading(false);
      });
  };

  /**
   * @description: 查询列表
   * @param {*} useCallback
   * @return {*}
   * @LastEditors: April
   */
  const search = () => {
    let paging = {
      pageIndex: 1,
      pageSize: 10,
      total: pagingRecord.total,
    };
    setPagingRecord({ ...paging });
    searchParams.apiType =
      searchForm.getFieldsValue().apiType && searchForm.getFieldsValue().apiType.trim()
        ? searchForm.getFieldsValue().apiType.trim()
        : '';
    searchParams.requestStatus = searchForm.getFieldsValue().requestStatus || '';
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    searchParams.ticketNumber = searchForm.getFieldValue('ticketNumber');
    searchParams.sysSn = searchForm.getFieldValue('sysSn');
    searchParams.title = searchForm.getFieldValue('title');
    getTableData();
  };

  /**
   * @description: 点击+号调用获取数据
   * @param {*} val
   * @param {*} record
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const handleExpand = (val, record) => {
    if (val) {
      dataList &&
        dataList.map((item: any) => {
          if (item.id == record.id) {
            setSubDataList(item.syncResultList);
          }
        });
    } else {
      setSubDataList([]);
    }
  };
  /**
   * @description: 展开行时:点击新的子表格收起原展开的子表格
   * @param {*} rows
   * @return {*}
   * @LastEditors: April
   */
  const expandRows = (rows) => {
    const arrTemp = [''];
    setExpandedRowKeys(arrTemp.concat(rows[rows.length - 1]));
  };
  /**
   * @description: 重新同步
   * @param {*} key
   * @return {*}
   * @LastEditors: April
   */
  const handleSync = (key) => {
    retrySync({ key }).then((res: any) => {
      if (res && res.code === 200) {
        message.success(t('common.operateSuccess'));
        getTableData();
        setExpandedRowKeys(['']);
      }
    });
  };
  /**
   * @description: 获取嵌套子表格内容
   * @param {*} key
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getSyncResults = (key) => {
    getSyncResult({ key: key })
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          setSubDataList(res.data);
        } else {
          setSubDataList([]);
        }
        setDetailTableLoading(false);
      })
      .catch(() => {
        setSubDataList([]);
        setDetailTableLoading(false);
      });
  };

  return (
    <>
      <div className="custom-contant sysData-migration">
        <Form
          form={searchForm}
          name="searchForm"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search"
          layout="vertical"
        >
          <Row gutter={12} align={'bottom'}>
            {/* 接口类型 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={4}>
              <Form.Item
                label={t('synResult.interfaceType')}
                name="apiType"
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.length > inputValueLimit.maxSn) {
                        return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxSn }));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 同步结果 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={4}>
              <Form.Item label={t('synResult.syncResult')} name="requestStatus">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`${t('common.pleaseEnter')}`}
                  options={[
                    { value: '1', label: t('synResult.success') },
                    { value: '0', label: t('synResult.fail') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 客诉单号 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={4}>
              <Form.Item label={t('complaintList.number')} name="ticketNumber">
                <Input allowClear placeholder={`${t('common.pleaseEnter')}`} maxLength={inputValueLimit.maxSn} />
              </Form.Item>
            </Col>
            {/* SN */}
            <Col xs={24} sm={12} md={6} lg={6} xl={4}>
              <Form.Item label="SN" name="sysSn">
                <Input allowClear placeholder={`${t('common.pleaseEnter')}`} maxLength={inputValueLimit.maxSn} />
              </Form.Item>
            </Col>
            {/* 关键词 */}
            <Col xs={24} sm={12} md={6} lg={6} xl={4}>
              <Form.Item label={t('createComplaint.keyword_ci')} name="title">
                <Input allowClear placeholder={`${t('common.pleaseEnter')}`} maxLength={inputValueLimit.maxSn} />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={4}>
              <Form.Item>
                {/* 查询 */}
                <Space>
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          className="custom-table-flex auto-table"
          columns={columns}
          loading={tableLoading}
          dataSource={dataList}
          expandable={{ expandedRowRender }}
          onExpand={handleExpand}
          onExpandedRowsChange={(expandedRows) => expandRows(expandedRows)}
          expandedRowKeys={expandedRowKeys}
          rowKey={(record: any) => record.id}
          scroll={{ x: 1300, y: 'calc(100vh - 250px)' }}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
        ></Table>
      </div>
    </>
  );
};
export default memo(sysDataMigration);
