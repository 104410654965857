/*
 * @Description:数据权限编辑弹框
 * @Author: Summer.Chen
 * @Date: 2024-04-08 13:34:32
 * @LastEditTime: 2024-04-25 09:45:16
 * @LastEditors: Summer.Chen
 */
import type { TableRowSelection } from 'antd/es/table/interface';
import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Table, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ModalProps } from 'antd/lib/modal';
import { setCol } from '../../config';
import { queryOrgMemberMenuRightTree } from '@/api/authorityManagement';
import { wordLimit } from '@/constants/wordLimit';
import { modalParamsType } from '../../config';
import { editOrgUserMenu } from '@/api/authorityManagement';
import { useTranslation } from 'react-i18next';
import { convertSysLanFormatToThirdPartyFormat, getParents } from '@/utils/utils';
import { RootStateOrAny, useSelector } from 'react-redux';
const splitDelimiter: string = '__';
const dateTimeStamp = Date.now();
interface DataType {
  key: React.ReactNode;
  name: string;
  age: number;
  address: string;
  children?: DataType[];
}
// 存储数据
let systemIdList: string[] = [];
let systemList: { systemId: string; count: boolean }[] = [];
const EditMembMenuRightComponentModal: React.FC<ModalProps & modalParamsType> = (props): React.ReactElement => {
  const { deptId, initData, open, onCancel, record } = props;
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let formatLang = convertSysLanFormatToThirdPartyFormat(language);
  //创建表单
  const [form] = Form.useForm();
  //
  const { t } = useTranslation();
  // 设置布局
  const getCol = setCol({ sm: 24, xs: 12, md: 12, lg: 12 });
  // 表头
  const columns: ColumnsType<DataType> = [
    {
      title: t('menuManage.menuName'),
      dataIndex: 'menuTitle',
      key: 'menuId',
      width: '50px',
      render: (text: string | null, record: any) => {
        return record && record.menuNameDic
          ? record.menuNameDic[formatLang]
            ? record.menuNameDic[formatLang]
            : text
          : text;
      },
    },
    {
      title: t('common.serialNumber'),
      dataIndex: 'address',
      width: '30%',
      key: 'address',
      render: (text: string, record: any, index: number) => {
        return index + 1;
      },
    },
  ];
  //手动选中和取消复选框
  const selectedRowKeysHandler = (source: any, selected: boolean, selectedRowKeyList: React.Key[]) => {
    source.forEach((element) => {
      //选择父级和子集
      if (selected && !selectedRowKeys.includes(element.tempMenuId)) {
        selectedRowKeyList.push(element.tempMenuId);
        //取消父级和子集
      } else if (!selected && selectedRowKeys.includes(element.tempMenuId)) {
        selectedRowKeyList.splice(selectedRowKeyList.indexOf(element.tempMenuId), 1);
      }
      if (element.children) {
        selectedRowKeysHandler(element.children, selected, selectedRowKeyList);
      }
    });
  };
  // rowSelection objects indicates the need for row selection
  const rowSelection: TableRowSelection<DataType> & { tempMenuId: React.Key } = {
    onSelect: (record, selected) => {
      selectedRowKeysHandler([record], selected, selectedRowKeys);
      const parentNodes = getParents(tableData, record.tempMenuId);
      for (let i = parentNodes.length - 1; i >= 0; i--) {
        if (!parentNodes[i].tempMenuId) {
          continue;
        }
        if (parentNodes[i].children && parentNodes[i].children.length) {
          let count = 0;
          parentNodes[i].children.forEach((item) => {
            // 有一个选中就取父节点
            if (selected) {
              if (selectedRowKeys.includes(item.tempMenuId) && !selectedRowKeys.includes(parentNodes[i].tempMenuId)) {
                selectedRowKeys.push(parentNodes[i].tempMenuId);
              }
              // 全部取消则取消相应父节点
            } else if (!selected) {
              if (!selectedRowKeys.includes(item.tempMenuId)) {
                count++;
              }
              if (count === parentNodes[i].children.length && selectedRowKeys.includes(parentNodes[i].tempMenuId)) {
                selectedRowKeys.splice(selectedRowKeys.indexOf(parentNodes[i].tempMenuId), 1);
              }
            }
          });
        }
      }
      setSelectedRowKeys([...selectedRowKeys]);
      form.setFieldValue('menuIds', selectedRowKeys);
    },
    hideSelectAll: true,
  };
  let defaultSelectedRowKeys: string[] = [];
  // 菜单权限配置
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // 表格数据
  const [tableData, setTableData] = useState<any>([]);

  // 提交表单
  const submitForm = (values: any) => {
    editOrgUserMenu({
      deptId,
      userId: record?.userId,
      ...values,
      menuIds: values.menuIds
        .filter((key: string) => !systemIdList.includes(key))
        .map((key: string) => key.split(splitDelimiter)[1]),
    }).then((rep: any) => {
      if (rep && rep.code === 200) {
        message.success(t('common.operateSuccess'));
        //刷新右侧成员列表
        initData();
        onCancel();
      }
    });
  };
  // 递归获取默认选中的table的MenuId
  const recursionGetMenuId = (
    list: any[],
    keys: string[],
    systemId: string,
    systemList: { systemId: string; count: boolean }[],
  ) => {
    list.map((item) => {
      if (item.systemId) {
        systemId = item.systemId;
        item.tempMenuId = systemId + splitDelimiter + item.menuId + splitDelimiter + dateTimeStamp;
      } else {
        item.tempMenuId = systemId + splitDelimiter + item.menuId;
      }
      if (item.parentId === null || item.parentId === undefined) {
        item.parentId = systemId;
      }
      systemList.forEach((systemItem) => {
        if (!item.systemId && systemItem.systemId === systemId && item.isSelected) {
          systemItem.count = true;
        }
      });
      if (item.isSelected) {
        keys.push(item.tempMenuId);
      }
      if (item.children) {
        recursionGetMenuId(item.children, keys, systemId, systemList);
      }
    });
    return keys;
  };
  // 初始化各个值
  const variValueInit = () => {
    systemIdList = [];
    systemList = [];
    setTableData([]);
    form.setFieldValue('menuIds', []);
    defaultSelectedRowKeys = [];
  };
  useEffect(() => {
    if (!deptId) {
      return;
    }
    if (!open) {
      variValueInit();
      return;
    }

    form.setFieldsValue({
      deptName: record?.deptName,
      username: record?.username,
    });
    queryOrgMemberMenuRightTree({ deptId, userId: record?.userId }).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        let temArr = rep.data.map((item) => {
          systemIdList.push(item.systemId + splitDelimiter + item.systemId + splitDelimiter + dateTimeStamp);
          systemList.push({ systemId: item.systemId, count: false });
          return {
            menuTitle: item.systemName,
            systemId: item.systemId,
            menuId: item.systemId,
            children: item.menuTreeList,
          };
        });
        recursionGetMenuId(temArr, defaultSelectedRowKeys, '', systemList);
        systemList.forEach((item, index) => {
          if (item.count) {
            defaultSelectedRowKeys.push(systemIdList[index]);
          }
        });
        form.setFieldValue('menuIds', defaultSelectedRowKeys);
        setSelectedRowKeys(defaultSelectedRowKeys);
        setTableData(temArr);
      }
    });
  }, [props.open]);

  return (
    <>
      <Modal
        {...props}
        onOk={() => {
          form.submit();
        }}
      >
        <Form layout={'vertical'} onFinish={submitForm} form={form} className="ant-form-item-mb-8">
          <Row
            gutter={25}
            onClick={(event) => {
              event.stopPropagation(); // 阻止事件冒泡
            }}
          >
            {/* 组织名称 */}
            <Col {...getCol}>
              <Form.Item
                name="deptName"
                label={t('authorityManagement.orgName')}
                rules={[
                  {
                    required: true,
                    message: t('authorityManagement.pleaseEnterOrganizationName') as string,
                  },
                ]}
              >
                <Input disabled maxLength={wordLimit.nameInput} />
              </Form.Item>
            </Col>
            {/* 用户名 */}
            <Col {...getCol}>
              <Form.Item
                name="username"
                label={t('userGroupManage.username')}
                rules={[
                  {
                    required: true,
                    message: t('userGroupManage.pleaseEnterUserName') as string,
                  },
                ]}
              >
                <Input
                  disabled
                  maxLength={wordLimit.nameInput}
                  placeholder={t('userGroupManage.pleaseEnterUserName') as string}
                />
              </Form.Item>
            </Col>
            {/* 菜单权限配置 */}
            <Col {...setCol({ sm: 24, xs: 24, md: 24, lg: 24 })}>
              <Form.Item
                name="menuIds"
                label={t('authorityManagement.menuPermissionConfiguration')}
                rules={[{ required: true, message: t('authorityManagement.pleaseSelectMenu') as string }]}
              >
                <Table
                  rowKey="tempMenuId"
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 480 }}
                  rowSelection={{
                    checkStrictly: true,
                    ...rowSelection,
                    selectedRowKeys,
                  }}
                  dataSource={tableData}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EditMembMenuRightComponentModal;
