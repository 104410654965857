/*
 * @Description:权限菜单->菜单翻译管理
 * @Author: YuKi
 * @Date: 2022/11/03
 * @LastEditors: YuKi
 * @LastEditTime: 2023/07/28
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message, Card, Tree } from 'antd';
import { getMenuTree } from '@/api/menuPermissions/menuManage';
import {
  getMenuTranslate,
  refreshMenuAuthority,
  getMenuTranslateEdit,
  editRemoteModel,
} from '@/api/menuPermissions/menuTranslation';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { inputValueLimit } from '@/constants/inputValueLimit';
import type { DataNode } from 'antd/es/tree';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, RootStateOrAny } from 'react-redux';
import './index.less';
const { Search } = Input;

let defaultData: DataNode[] = [];
let treeList: { key: React.Key; nameObj: string }[] = [];

/**
 * @Author: YuKi
 * @description: 左侧菜单->根据查询条件的key，获取其父节点的key,然后将其展开
 * @param {React} key
 * @param {DataNode} tree
 * @return {*}
 */
const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

/**
 * @Author: YuKi
 * @description: 左侧菜单->获取树形结构原始数据
 * @param {DataNode} data
 * @return {*}
 */
const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node: any = data[i];
    const { key } = node;
    treeList.push({ key, nameObj: node.nameObj as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

/* 列表字段类型定义 */
type GithubIssueItem = {
  key: number;
  menuTitle: string /* 菜单标题 */;
  menuName: number /* 菜单名称 */;
};

/* 列表查询参数 */
let searchParams: any = {
  menuName: undefined /* 	菜单名称 */,
  parentKey: undefined /* 父级key */,
};
let editKey = '';
const MenuTranslation: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  /* 左侧菜单->树形展开节点 */
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  /* 左侧菜单->是否自动展开父节点 */
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  /* 左侧菜单->源数据 */
  const [treeDataList, setTreeDataList] = useState<any>([]);
  /* 左侧菜单->搜索内容 */
  const [searchValue, setSearchValue] = useState('');
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 编辑弹窗里面的展示表单的ref */
  const [showform] = Form.useForm();
  /* 编辑弹窗里面的编辑表单的ref */
  const [aEForm] = Form.useForm();

  /* 新增编辑弹窗-> 区分新增编辑标志*/
  const [aEFlag, setAEFlag] = useState<string>('add');
  /* 列表源数据 */
  const [dataList, setDataList] = useState<GithubIssueItem[]>([]);
  /* table的loading */
  const [tableLoading, setTableLoading] = useState(false);
  /* 新增编辑弹窗打开关闭flag */
  const [isAEOpen, setIsAEOpen] = useState<any>(false);
  /* 刷新权限按钮loading */
  const [refPermLoading, setRefPermLoading] = useState<any>(false);
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let [isUseEffectFirst, setIsUseEffectFirst] = useState(true);
  /* 列表表头 */
  const [columns, setColumns] = useState<ColumnsType<GithubIssueItem>>([
    /* 菜单标题 */
    {
      title: t('menuTranslation.menuTitle'),
      key: 'menuTitle',
      dataIndex: 'menuTitle',
      width: 160,
      ellipsis: true,
      align: 'center',
      fixed: 'left',
    },
    /* 序号 */
    {
      title: t('common.serialNumber'),
      key: 'serialNumber',
      width: 160,
      align: 'center',
      render: (text, record, index) => `${index + 1}`,
    },
    /* 菜单名称 */
    {
      title: t('menuTranslation.menuName'),
      key: 'menuName',
      dataIndex: 'menuName',
      width: 160,
      ellipsis: true,
      align: 'center',
    },
    /* 操作 */
    {
      title: t('common.operation'),
      key: 'option',
      align: 'center',
      width: 100,
      fixed: 'right',
      render: (_, record) => [
        <Space size="middle" key="space" wrap>
          {/* 编辑 */}
          <a key="edit" onClick={() => aEButton('edit', record)}>
            {t('common.editBtn')}
          </a>
        </Space>,
      ],
    },
  ]);
  /* 编辑的语言数据回显 */
  const [langArr, setLangArr] = useState<any>([]);

  /**
   * @Author: YuKi
   * @description: 根据语言切换动态切换样式
   * @return {*}
   */
  useEffect(() => {
    if (isUseEffectFirst) {
      setIsUseEffectFirst(false);
      return;
    }
    let tempError = searchForm.getFieldsError();
    for (let index = 0; index < tempError.length; index++) {
      const element = tempError[index];
      if (element.errors && element.errors.length > 0) {
        searchForm.validateFields(element.name);
      }
    }

    let tempColumns: any = columns;
    let arr: any = [
      /* 菜单标题 */
      {
        title: t('menuTranslation.menuTitle'),
        key: 'menuTitle',
        dataIndex: 'menuTitle',
        width: 160,
        ellipsis: true,
        align: 'center',
        fixed: 'left',
      },
      /* 序号 */
      {
        title: t('common.serialNumber'),
        key: 'serialNumber',
        width: 160,
        align: 'center',
        render: (text, record, index) => `${index + 1}`,
      },
      /* 菜单名称 */
      {
        title: t('menuTranslation.menuName'),
        key: 'menuName',
        dataIndex: 'menuName',
        width: 160,
        ellipsis: true,
        align: 'center',
      },
    ];

    tempColumns.splice(
      tempColumns.length - 1,
      1,
      ...[
        {
          title: t('common.operation'),
          key: 'option',
          align: 'center',
          width: 100,
          fixed: 'right',
          render: (_, record) => [
            <Space size="middle" key="space" wrap>
              {/* 编辑 */}
              <a key="edit" onClick={() => aEButton('edit', record)}>
                {t('common.editBtn')}
              </a>
            </Space>,
          ],
        },
      ],
    );
    tempColumns.splice(0, 3, ...arr);
    setColumns([...tempColumns]);
  }, [language]);

  /**
   * @Author: YuKi
   * @description: 初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    searchParams = {
      menuName: undefined /* 	菜单名称 */,
      parentKey: undefined /* 父级key */,
    };
    getUserMenus(); /* 获取左侧菜单数据 */
    getListInformation(); /* 获取右侧列表数据 */
  }, []);

  /**
   * @Author: YuKi
   * @description: 左侧菜单->获取左侧树形数据
   * @return {*}
   */
  const getUserMenus = () => {
    getMenuTree()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          defaultData = [...rep.data];
          generateList(defaultData);
          setTreeDataList([...rep.data]);
        } else {
          defaultData = [];
          setTreeDataList([]);
        }
      })
      .catch((err) => {
        defaultData = [];
        setTreeDataList([]);
      });
  };

  /**
   * @Author: YuKi
   * @description: 左侧菜单->展开/收起节点时触发
   * @param {React} newExpandedKeys
   * @return {*}
   */
  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  /**
   * @Author: YuKi
   * @description: 左侧菜单->搜索时，找到与搜索内容相对应的key,并展开父节点
   * @param {React} e
   * @return {*}
   */
  const onTreeSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      const newExpandedKeys = treeList
        .map((item: any) => {
          if (item.nameObj.indexOf(value) > -1) {
            return getParentKey(item.key, defaultData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      setExpandedKeys(newExpandedKeys as React.Key[]);
      setSearchValue(value);
      setAutoExpandParent(true);
    } else {
      setExpandedKeys([]);
      setAutoExpandParent(true);
    }
  };

  /**
   * @Author: YuKi
   * @description: 左侧菜单->搜索时，找到对应的搜索内容，并高亮提示
   * @return {*}
   */
  useEffect(() => {
    const loop = (data: DataNode[]): DataNode[] =>
      data.map((item: any) => {
        const strTitle = item.nameObj as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const nameObj =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return { nameObj, key: item.key, children: loop(item.children) };
        }

        return {
          nameObj,
          key: item.key,
        };
      });
    setTreeDataList(loop(defaultData));
  }, [searchValue]);

  /**
   * @Author: YuKi
   * @description: 左侧菜单->点击节点时触发，并且根据节点的key查询右侧内容
   * @param {any} key
   * @return {*}
   */
  const onLeftTreeSelect = (key: any) => {
    searchParams.parentKey = key[0];
    searchParams.menuName = undefined;
    searchForm.resetFields();
    getListInformation();
  };

  /**
   * @Author: YuKi
   * @description: 获取列表数据
   * @return {*}
   */
  const getListInformation = () => {
    setTableLoading(true);
    getMenuTranslate(searchParams)
      .then((rep: any) => {
        let arr: any = [];
        let tempColumns: any = [
          /* 菜单标题 */
          {
            title: t('menuTranslation.menuTitle'),
            key: 'menuTitle',
            dataIndex: 'menuTitle',
            width: 160,
            ellipsis: true,
            align: 'center',
            fixed: 'left',
          },
          /* 序号 */
          {
            title: t('common.serialNumber'),
            key: 'serialNumber',
            width: 160,
            align: 'center',
            render: (text, record, index) => `${index + 1}`,
          },
          /* 菜单名称 */
          {
            title: t('menuTranslation.menuName'),
            key: 'menuName',
            dataIndex: 'menuName',
            width: 160,
            ellipsis: true,
            align: 'center',
          },
          /* 操作 */
          {
            title: t('common.operation'),
            key: 'option',
            align: 'center',
            width: 100,
            fixed: 'right',
            render: (_, record) => [
              <Space size="middle" key="space" wrap>
                {/* 编辑 */}
                <a key="edit" onClick={() => aEButton('edit', record)}>
                  {t('common.editBtn')}
                </a>
              </Space>,
            ],
          },
        ];
        if (rep && rep.code === 200 && rep.data && rep.data.lgType) {
          for (let index = 0; index < rep.data.lgType.length; index++) {
            const element = rep.data.lgType[index];
            let obj = {
              title: element.lgCode,
              key: element.lgCode,
              dataIndex: element.lgCode,
              width: 160,
              ellipsis: true,
              align: 'center',
            };
            arr.push(obj);
          }
          tempColumns.splice(3, 0, ...arr);
        }
        if (rep && rep.code === 200 && rep.data && rep.data.data) {
          setDataList([...rep.data.data]);
        } else {
          setDataList([]);
        }
        setColumns([...tempColumns]);
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        setColumns([
          /* 菜单标题 */
          {
            title: t('menuTranslation.menuTitle'),
            key: 'menuTitle',
            dataIndex: 'menuTitle',
            width: 160,
            ellipsis: true,
            align: 'center',
            fixed: 'left',
          },
          /* 序号 */
          {
            title: t('common.serialNumber'),
            key: 'serialNumber',
            width: 160,
            align: 'center',
            render: (text, record, index) => `${index + 1}`,
          },
          /* 菜单名称 */
          {
            title: t('menuTranslation.menuName'),
            key: 'menuName',
            dataIndex: 'menuName',
            width: 160,
            ellipsis: true,
            align: 'center',
          },
          /* 操作 */
          {
            title: t('common.operation'),
            key: 'option',
            align: 'center',
            width: 100,
            fixed: 'right',
            render: (_, record) => [
              <Space size="middle" key="space" wrap>
                {/* 编辑 */}
                <a key="edit" onClick={() => aEButton('edit', record)}>
                  {t('common.editBtn')}
                </a>
              </Space>,
            ],
          },
        ]);
        setTableLoading(false);
      });
  };

  /**
   * @Author: YuKi
   * @description:查询列表
   * @param {Object} form 表单
   * @return {*}
   */
  const search = useCallback((values: any) => {
    searchParams.menuName = values.menuName && values.menuName.trim() ? values.menuName.trim() : undefined;
    getListInformation();
  }, []);

  /**
   * @Author: YuKi
   * @description: 新增/编辑弹窗->打开
   * @param {String} flag 'add':新增，'edit':编辑
   * @param {*} record 编辑的行数据
   * @return {*}
   */
  const aEButton = (flag, record?) => {
    setAEFlag(flag);
    if (flag === 'edit') {
      let params = {
        key: record.key,
      };
      getMenuTranslateEdit(params).then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          showform.setFieldsValue({
            menuTitle: rep.data.menuTitle || '',
          });
          if (rep.data.data) {
            setLangArr([...rep.data.data]);
            rep.data.data.forEach((element) => {
              aEForm.setFieldsValue({
                [element.lgCode]: element.lgName || '',
              });
            });
          }
          editKey = record.key;
          setIsAEOpen(true);
        }
      });
    }
  };

  /**
   * @Author: YuKi
   * @description: 编辑弹窗确认
   * @return {*}
   */
  const handleAEOk = () => {
    aEForm
      .validateFields()
      .then((values) => {
        if (aEFlag === 'edit') {
          let params: any = {};
          params.key = editKey;
          let data: any = [];
          Object.keys(values).forEach((key) => {
            let obj = {
              lgCode: key,
              lgName: values[key],
            };
            data.push(obj);
          });
          params.data = data;
          editRemoteModel(params)
            .then((rep: any) => {
              if (rep && rep.code === 200) {
                setIsAEOpen(false);
                aEForm.resetFields();
                showform.resetFields();
                getListInformation();
                editKey = '';
                message.success(t('common.editSuccess')); /* 操作成功 */
              }
            })
            .catch((err) => {});
        }
      })
      .catch((errorInfo) => {});
  };

  /**
   * @Author: YuKi
   * @description: 新增编辑弹窗取消
   * @return {*}
   */
  const handleAECancel = () => {
    aEForm.resetFields();
    setIsAEOpen(false);
  };

  /**
   * @Author: YuKi
   * @description: 刷新权限
   * @return {*}
   */
  const refPermClick = () => {
    confirm({
      title: t('common.tip'),
      closable: true,
      icon: <ExclamationCircleOutlined />,
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      content: t('menuTranslation.confirmRefresh'),
      onOk() {
        setRefPermLoading(true);
        refreshMenuAuthority()
          .then((rep: any) => {
            if (rep && rep.code === 200) {
              message.success(t('common.operateSuccess')); /* 操作成功 */
              getListInformation();
            }
            setRefPermLoading(false);
          })
          .catch((error) => {
            setRefPermLoading(false);
          });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  return (
    <div className="menTranslation">
      <Row>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <Card title={t('menuManage.menuList')} headStyle={{ textAlign: 'center' }}>
            <Search
              className="search-tree"
              style={{ marginBottom: 8 }}
              placeholder={`${t('menuManage.pleaseEnterTheMenuName')}`}
              onChange={onTreeSearchChange}
            />
            <Tree
              rootClassName="menu-translation-tree"
              fieldNames={{ title: 'nameObj', key: 'key', children: 'children' }}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              treeData={treeDataList}
              onSelect={onLeftTreeSelect}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={19} xl={19}>
          <Card>
            <Form
              form={searchForm}
              name="search_form"
              autoComplete="off"
              style={{ width: '100%' }}
              onFinish={search}
              className="custom-search in-line-style"
              layout="vertical"
            >
              <Row gutter={12} align="bottom">
                {/* 菜单名称/标题 */}
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <Form.Item label={t('menuTranslation.nameOrTitle')} name="menuName">
                    <Input allowClear />
                  </Form.Item>
                </Col>
                {/* 操作按钮 */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item>
                    <Space size="middle" wrap>
                      {/* 查询 */}
                      <Button type="primary" key="search" shape="round" htmlType="submit">
                        {t('common.searchBtn')}
                      </Button>
                      {/* 重置查询 */}
                      <Button
                        type="primary"
                        key="resetSearchBtn"
                        shape="round"
                        onClick={() => {
                          searchParams.menuName = undefined;
                          searchParams.parentKey = '';
                          searchForm.resetFields();
                          getListInformation();
                        }}
                      >
                        {t('common.resetSearchBtn')}
                      </Button>
                      {/* 刷新权限 */}
                      <Button
                        type="primary"
                        loading={refPermLoading}
                        shape="round"
                        key="refreshPermission"
                        onClick={() => refPermClick()}
                      >
                        {t('menuTranslation.refreshPermission')}
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Table
              columns={columns}
              loading={tableLoading}
              rowKey="key"
              pagination={false}
              dataSource={dataList}
              scroll={{ x: 600, y: window.innerHeight - 335 }}
              className="custom-table-flex"
            />
          </Card>
        </Col>
      </Row>
      {/* 新增编辑弹窗 */}
      <Modal
        maskClosable={false}
        title={aEFlag === 'add' ? t('common.addBtn') : t('common.editBtn')}
        open={isAEOpen}
        onCancel={handleAECancel}
        width="60%"
        footer={[
          /* 取消 */
          <Button key="cancel" shape="round" onClick={handleAECancel}>
            {t('common.cancelBtn')}
          </Button>,
          /* 提交 */
          <Button key="submit" shape="round" type="primary" onClick={handleAEOk}>
            {t('common.submitBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form name="showform" autoComplete="off" layout="vertical" form={showform}>
          <Row gutter={12}>
            {/* 菜单标题 */}
            <Col span={24}>
              <Form.Item label={t('menuTranslation.menuTitle')} name="menuTitle">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form name="aEForm" autoComplete="off" layout="vertical" form={aEForm}>
          <Row gutter={12}>
            {langArr &&
              langArr.length > 0 &&
              langArr.map((elem, index) => {
                return (
                  <Col span={24} key={index}>
                    <Form.Item
                      label={elem.lgCode}
                      name={elem.lgCode}
                      rules={[
                        {
                          required: true,
                          message: `${t('menuTranslation.pleaseEnter', { lgCode: elem.lgCode })}`,
                          whitespace: true,
                        },
                        {
                          validator: (rule, value) => {
                            if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                              return Promise.reject(
                                t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                );
              })}
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(MenuTranslation);
