/*
 * @Description: 客户反馈->客诉详情页-> 转产品
 * @Author: Wayne Hu
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2024/06/24 13:20:38
 */

import React, { memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Row, Col, message, Radio, Space, Select, DatePicker, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { saveTransferProduct, getProductForm } from '@/api/clientFeedback/complaintsList';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getUrlQuery } from '@/utils/utils';

import { Editor } from '@tinymce/tinymce-react';
import { tinymceFormatLanguage } from '@/utils';
import FileUpload from '../FileUpload';
import { fileUpload } from '@/api/common';
import moment from 'moment';
import PreViewModal from '../PreViewModal';
import { InfoCircleOutlined } from '@ant-design/icons';
import { config } from '@/constants/config.js';
const tinyKey = '0990dde4fa3e0f907ddaba1e7cf78810421a434e14f1bcc56d46fcc009e711f2';
const { Option } = Select;

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
}

/* 富文本内容 */
let tinyContent: any = '';

const NewDemandRegistration: React.FC<ChildProps> = ({ isOpen, onClose, originData, id, extendParams, nodeData }) => {
  const { t } = useTranslation();
  const [editorNow, setEditorNow] = useState<any>('');
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 附件列表 */
  const [fileLists, setFileLists] = useState([]);
  /* 预览弹窗的开关 */
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  /* 点击文件 */
  const [fileItem, setFileItem] = useState<any>();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const { TextArea } = Input;

  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    let key: string = getUrlQuery('key');
    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
    }
    if (nodeData) {
      getProductForms(nodeData.data.id);
    }
  }, []);

  /**
   * @description: 获取去处理带附件表单
   * @param {*} id
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getProductForms = (id) => {
    getProductForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        res.data.expectedCompletionTime = moment(res.data.expectedCompletionTime);
        searchForm.setFieldsValue(res.data);
        setFileLists(res.data.amsFileList);
      }
    });
  };

  /**
   * @description: 监听富文本变化
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const handleEditorChange = (content: any, editor: any) => {
    tinyContent = content;
  };

  /**
   * @description: 保存转产品表单
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    searchForm.validateFields().then((values) => {
      if (!tinyContent) {
        return message.error(t('complaintList.describeInfoRequired'));
      }
      let amsFileLists: any = [];

      values.amsFileList &&
        values.amsFileList?.forEach((item) => {
          amsFileLists.push({
            blobContainerName: item.response[0].blobContainerName,
            fileFullPath: item.response[0].fileSaveName,
            fileName: item.response[0].fileName,
            fileSize: item.response[0].size,
          });
        });
      let transferParams = [];
      if (values.taskAuthData && originData.nextOperateAuthList.length > 1) {
        transferParams = originData.nextOperateAuthList.filter((item) => {
          return item.id == values.taskAuthData;
        });
      } else {
        transferParams = originData.nextOperateAuthList;
      }
      values.expectedCompletionTime = moment(values.expectedCompletionTime).format('YYYY-MM-DD');
      saveTransferProduct({
        complaintsId: id,
        ...values,
        amsFileList: amsFileLists,
        describeInfo: tinyContent,
        ...originData,
        taskAuthData: transferParams ? transferParams[0] : null,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHanle();
        }
      });
    });
  };

  /**
   * @description: 回显FileUpload组件上传的文件
   * @param {*} newFileList
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const handleFileListChange = (newFileList) => {
    searchForm.setFieldValue('amsFileList', newFileList);
    searchForm.validateFields(['amsFileList']);
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const cancelHanle = () => {
    searchForm.resetFields();
    onClose();
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载或预览客诉附件
   * @param {string} fileName
   * @return {*}
   */
  const downloadOrPreviewAttachments = (fileItem: any) => {
    setFileItem(fileItem);
    setViewOpen(true);
  };

  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: April
   */
  const handleCancePreview = () => {
    setViewOpen(false);
  };

  /**
   * @description: 监听富文本变化
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const tinymceUploadImage = (blobInfo, succFun, failFun) => {
    const formData = new FormData();
    // 服务端接收文件的参数名，文件数据，文件名
    formData.append('blobContainerName', 'FILES');
    formData.append('fileList', blobInfo.blob(), blobInfo.filename());
    fileUpload(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          let uploadUrl = '';
          if (window.location.origin.indexOf('localhost') != -1) {
            uploadUrl = config.downUrl + 'files/';
          } else {
            uploadUrl = config.downUrl + 'files/';
          }
          succFun(uploadUrl + res.data[0].fileSaveName);
        } else {
          failFun(t('createComplaint.fileUploadError'));
        }
      })
      .catch(() => {
        failFun(t('createComplaint.fileUploadError'));
      });
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData.data.currentStatusShowName}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHanle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHanle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form
          layout="horizontal"
          form={searchForm}
          name="complaintDetail"
          autoComplete="off"
          style={{ width: '100%' }}
          className=""
          disabled={nodeData ? true : false}
        >
          {originData && originData.nextOperateAuthList && originData.nextOperateAuthList.length > 1 ? (
            <Row gutter={8}>
              {/* 转交给 */}
              <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                <Form.Item
                  name="taskAuthData"
                  validateTrigger="onBlur"
                  labelCol={{ span: 8 }}
                  rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                  label={
                    <span className="form-item-label" style={{ whiteSpace: 'pre-wrap' }}>
                      {t('complaintList.transferTo')}
                    </span>
                  }
                >
                  <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                    {originData.nextOperateAuthList &&
                      originData.nextOperateAuthList.length > 0 &&
                      originData.nextOperateAuthList.map((item: any, index) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          <Row gutter={8}>
            {/* 国家 */}
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <Form.Item
                labelCol={{ span: 8 }}
                label={<span className="form-item-label">{t('complaintList.CountryOrRegion')}</span>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}`, whitespace: true }]}
                name="country"
              >
                <Input placeholder={`${t('complaintList.CountryOrRegionPlaceholder')}`} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            {/* 产品类型 */}
            <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{ textAlign: 'left' }}>
              <Form.Item
                labelCol={{ span: 8 }}
                label={<span className="form-item-label">{t('complaintList.productType')}</span>}
                rules={[{ required: true }]}
                name="productType"
                initialValue={'1'}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={'1'}>{t('projectManagement.InverterModel')}</Radio>
                    <Radio value={'2'}>{t('complaintList.cloudPlatform')}</Radio>
                    <Radio value={'3'}>{t('complaintList.batteryModel')}</Radio>
                    <Radio value={'4'}>{t('complaintList.other')}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            {/* 需求类型 */}
            <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{ textAlign: 'left' }}>
              <Form.Item
                labelCol={{ span: 8 }}
                label={<span className="form-item-label">{t('complaintList.requirementType')}</span>}
                rules={[{ required: true }]}
                name="requirementType"
                initialValue={'1'}
              >
                <Radio.Group>
                  <Radio value={'1'}>{t('complaintList.newFunction')}</Radio>
                  <Radio value={'2'}>{t('complaintList.optimization')}</Radio>
                  <Radio value={'3'}>{t('complaintList.defect')}</Radio>
                  <Radio value={'4'}>{t('complaintList.other')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            {/* 优先级 */}
            <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{ textAlign: 'left' }}>
              <Form.Item
                labelCol={{ span: 8 }}
                label={<span className="form-item-label">{t('complaintList.priority')}</span>}
                rules={[{ required: true }]}
                name="priority"
                initialValue={1}
              >
                <Radio.Group>
                  <Radio value={1}>{t('complaintList.high')}</Radio>
                  <Radio value={2}>{t('complaintList.higher')}</Radio>
                  <Radio value={3}>{t('complaintList.low')}</Radio>
                  <Radio value={4}>{t('complaintList.lower')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            {/* 概要 */}
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <Form.Item
                labelCol={{ span: 8 }}
                label={<span className="form-item-label">{t('complaintList.summary')}</span>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}`, whitespace: true }]}
                name="summary"
              >
                <Input placeholder={`${t('complaintList.summaryPlaceholder')}`} />
              </Form.Item>
            </Col>
          </Row>
          {/* 描述 */}
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <Form.Item
                labelCol={{ span: 8 }}
                label={<span className="form-item-label">{t('complaintList.description')}</span>}
                name="describeInfo"
                required
              >
                <Editor
                  apiKey={tinyKey}
                  tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                  onInit={(content, editor) => {
                    setEditorNow(editor);
                  }}
                  disabled={nodeData}
                  scriptLoading={{ async: true }} // 异步加载
                  onEditorChange={handleEditorChange}
                  init={{
                    min_height: 200,
                    language: tinymceFormatLanguage(language),
                    menubar: false, // 顶部菜单栏
                    resize: false, // 右下角调整大小
                    statusbar: false, // 底部状态栏
                    object_resizing: false, // 禁止设置媒体大小
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    default_link_target: '_blank',
                    plugins:
                      'autoresize print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave',
                    toolbar:
                      'undo redo bold italic  image | formatselect |  alignleft aligncenter alignright alignjustify outdent indent ',
                    images_upload_handler: (blobInfo, succFun, failFun) => {
                      return tinymceUploadImage(blobInfo, succFun, failFun);
                    },
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            {/* 预期影响 */}
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <Form.Item
                labelCol={{ span: 8 }}
                label={<span className="form-item-label">{t('complaintList.expectedImpact')}</span>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}`, whitespace: true }]}
                name="expectedImpact"
              >
                <TextArea placeholder={`${t('complaintList.expectedImpactPlaceholder')}`} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            {/* 期望完成时间 */}
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <Form.Item
                labelCol={{ span: 8 }}
                label={<span className="form-item-label">{t('complaintList.expectedCompletionTime')}</span>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                name="expectedCompletionTime"
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          {fileLists && fileLists.length > 0 ? null : (
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{ textAlign: 'left' }}>
                <Form.Item
                  label={
                    <Tooltip title={t('createComplaint.warnThree')}>
                      <span className="form-item-label">
                        {t('complaintList.workingConditionsOnSite')}&nbsp;
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  name="amsFileList"
                  valuePropName="fileList"
                  labelCol={{ span: 8 }}
                >
                  <FileUpload onFileListChange={handleFileListChange} maxNum={3} />
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* 附件列表 */}
          {fileLists && fileLists.length > 0 ? (
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                {fileLists.map((item: any, index) => {
                  return (
                    <div>
                      <a onClick={() => downloadOrPreviewAttachments(item)}>{item.fileName}</a>
                    </div>
                  );
                })}
              </Col>
            </Row>
          ) : null}
        </Form>

        {viewOpen ? <PreViewModal onClosePreview={handleCancePreview} fileData={fileItem} /> : null}
      </Modal>
    </>
  );
};

export default memo(NewDemandRegistration);
