/*
 * @Description:项目管理
 * @Author: April
 * @Date: 2023/09/25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025/01/20
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Form,
  Input,
  Table,
  Row,
  Col,
  Space,
  message,
  Select,
  Tooltip,
  Radio,
  Upload,
  UploadProps,
  UploadFile,
} from 'antd';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getProjectInfoPage,
  getMinvsList,
  insertProject,
  deleteProject,
  updateProject,
  fileUpload,
  getGroupListByType,
  bindUserGroup,
  getInvSpecSn,
} from '@/api/projectManagement';
import { RcFile } from 'antd/lib/upload';
const { Option } = Select;

// import './index.less'
/* 列表字段类型定义 */
type GithubIssueItem = {
  id: number;
  authCategory: number /* 数据权限类别 */;
  authType: number /* 权限类型（1：逆变器型号；2：sn编码规则；3：国家区域；） */;
  groupName: string /* 用户组名称 */;
  roleName: string /* 角色 */;
};

/* 列表查询参数 */
let searchParams = {
  pageSize: 10 /* 每页显示条数 */,
  pageIndex: 1 /* 当前页 */,
  project: '' /* 项目名称 */,
};
let minv: string = '';
let invSpecName: string = '';

let editId = '';
const projectManagement: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const location = useLocation();
  /* 分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  /* 列表源数据 */
  const [dataList, setDataList] = useState<GithubIssueItem[]>([]);
  /* table的loading */
  const [tableLoading, setTableLoading] = useState<any>(false);
  /* 新增或编辑 */
  const [addOrEdit, setAddOrEdit] = useState<number>(1);
  const [detailData, setDetailData] = useState({ id: '' });
  /* 新增弹窗 */
  const [isModalOpen, setIsModalOpen] = useState(false);
  /* 关联用户组弹窗打开关闭flag */
  const [isUsergroupModelOpen, setIsUsergroupModelOpen] = useState<any>(false);
  /* 编辑弹窗的ref */
  const [projectForm] = Form.useForm();
  /* 关联用户组弹窗的ref */
  const [userGroupForm] = Form.useForm();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 新增编辑弹窗-> 区分新增编辑标志*/
  const [addOrEditFlag, setAddOrEditFlag] = useState<string>('add');
  // 逆变器型号name
  const [invSpec, setInvSpc] = useState<any>('');
  // 用户组列表
  const [userGroupList, setUserGroupList] = useState<any>([]);
  /* 文件上传列表 */
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileFlag, setFileFlag] = useState<any>(false);
  const [minvSourceList, setMinvSourceList] = useState<any>([]);
  const [bindId, setBindId] = useState<any>('');
  const [attachmentName, setAttachmentName] = useState<any>('');
  /* 列表字段 */
  const columns: ColumnsType<GithubIssueItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      key: 'serialNumber',
      width: 160,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 项目 */
    {
      title: t('projectManagement.project'),
      key: 'project',
      dataIndex: 'project',
      width: 180,
      align: 'center',
      ellipsis: true,
    },
    /* 逆变器型号 */
    {
      title: t('projectManagement.InverterModel'),
      key: 'invSpec',
      dataIndex: 'invSpec',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 创建人 */
    {
      title: t('common.creator'),
      key: 'createAccount',
      dataIndex: 'createAccount',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 创建时间 */
    {
      title: t('common.createTime'),
      key: 'createDatetime',
      dataIndex: 'createDatetime',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 状态 */
    {
      title: t('common.status'),
      key: 'projectStatus',
      dataIndex: 'projectStatus',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (text: any, record: any, index: any) => {
        return (
          <>
            {record.projectStatus === 1 ? <span>{t('projectManagement.closeCase')}</span> : ''}
            {record.projectStatus === 0 ? <span>{t('projectManagement.noCloseCase')}</span> : ''}
          </>
        );
      },
    },
    /* 用户组名称 */
    {
      title: t('projectManagement.usergroupName'),
      key: 'groupName',
      dataIndex: 'groupName',
      width: 120,
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      key: 'option',
      align: 'center',
      width: 250,
      render: (text: any, record: any, index: any) => {
        return (
          <Space key="option" size="middle" wrap className="sn-management-space">
            {/* 编辑 */}
            <a key="edit" onClick={() => displayAddOrEditDialog(2, record)}>
              {t('common.editBtn')}
            </a>
            {/* 关联用户组 */}
            <a
              key="associate"
              onClick={() => {
                handleOpenGroup(record);
              }}
            >
              {t('projectManagement.associatedUsergroups')}
            </a>
            {/* 删除 */}
            <a
              className="delete-text"
              key="delete"
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              {t('common.deleteBtn')}
            </a>
          </Space>
        );
      },
    },
  ];

  /**
   * @Author: April
   * @description: 获取列表数据
   * @return {*}
   */
  const getListInformation = () => {
    if (searchForm) {
      searchParams.project = searchForm.getFieldValue('project');
    }
    setTableLoading(true);
    getProjectInfoPage(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data && rep.data.rows) {
          setDataList([...rep.data.rows]);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
        } else {
          setDataList([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pagingInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pagingInit });
        setTableLoading(false);
      });
  };

  /**
   * @Author: April
   * @description: 根据分页，初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    if (location && location.state && location.state.curPageIndex && location.state.curPageSize) {
      pageChange(location.state.curPageIndex, location.state.curPageSize);
    } else {
      searchParams = {
        pageSize: 10 /* 每页显示条数 */,
        pageIndex: 1 /* 当前页 */,
        project: '' /* 项目名称 */,
      };
      pageChange(1, 10);
    }
    getMinvList();
    return () => {
      minv = '';
      invSpecName = '';
    };
  }, []);

  /**
   * @Author: April
   * @description: 分页的回调函数
   * @param {Number} currentPage 当前页
   * @param {Number} currentSize 页的总条数
   * @return {*}
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getListInformation();
  }, []);

  /**
   * @Author: April
   * @description:查询列表
   * @param {Object} form 表单
   * @return {*}
   */
  const search = useCallback((values: any) => {
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    getListInformation();
  }, []);

  /**
   * @Author: April
   * @description: 删除
   * @param {string} id
   * @return {*}
   */
  const handleDelete = (id) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteData'),
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      onOk() {
        deleteProject({ id: id }).then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('common.deleteSuccess')); /* 操作成功 */
            getListInformation();
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  /**
   * @description: ：新增或编辑的对话框
   * @param {number} type
   * @param {any} record
   * @return {*}
   * @LastEditors: April
   */
  const displayAddOrEditDialog = (type: number, record?: any) => {
    setIsModalOpen(true);
    if (type === 1) {
      minv = '';
      invSpecName = '';
      setAddOrEdit(1);
      setFileFlag(false);
      projectForm &&
        projectForm.setFieldsValue({
          project: '',
          projectStatus: 0,
          invCode: '',
          attachmentName: '',
          snList: [
            {
              sysSn: undefined,
            },
          ],
        });
    } else {
      setAddOrEdit(2);
      projectForm && projectForm.setFieldsValue(record);
      // minvSourceList.map((item) => {
      //   if (item.key == record.invCode) {
      //     setInvSpc(item.name)
      //   }
      // })
      getInvSpecSns(record.invSpecId);
      if (projectForm.getFieldsValue().projectStatus == 1) {
        setFileFlag(true);
        projectForm.setFieldsValue({ file: record.amsFileList });
        let fileList: any = [];
        record.amsFileList.map((item) => {
          fileList.push({
            name: item.fileName,
            status: 'done',
            ...item,
          });
        });

        setFileList(fileList);
      } else {
        setFileFlag(false);
      }
      setDetailData({ id: record.id });
    }
  };
  /**
   * @description: ：新增或编辑的对话框_确定
   * @return {*}
   * @LastEditors: April
   */
  const handleSubmit = () => {
    if (addOrEdit === 1) {
      projectForm.validateFields().then(
        (params: any) => {
          params.amsFileList = [];
          params?.file?.fileList.map((item) => {
            params.amsFileList.push({
              blobContainerName: 'FILES',
              fileFullPath: item.response[0].fileSaveName,
              fileName: item.response[0].fileName,
              fileSize: item.size,
            });
          });
          params.invSpec = invSpecName;
          params.minv = minv;
          insertProject(params).then(
            (res: any) => {
              if (res && res.code === 200) {
                setIsModalOpen(false);
                message.success(t('common.operateSuccess'));
                projectForm.resetFields();
                setFileList([]);
                searchParams.pageIndex = 1;
                getListInformation();
              }
            },
            () => {},
          );
        },
        (error) => {},
      );
    } else {
      let params = {
        ...projectForm.getFieldsValue(),
        id: detailData.id,
        invSpec: invSpecName,
        minv: minv,
      };
      params.amsFileList = [];
      if (params.file && params.file.fileList) {
        params.file.fileList.map((item) => {
          if (item.lastModified) {
            params.amsFileList.push({
              blobContainerName: 'FILES',
              fileFullPath: item.response[0].fileSaveName,
              fileName: item.response[0].fileName,
              fileSize: item.size,
            });
          } else {
            params.amsFileList.push({
              blobContainerName: 'FILES',
              fileFullPath: item.fileFullPath,
              fileName: item.fileName,
              fileSize: item.fileSize,
            });
          }
        });
      } else {
        params.file?.map((item) => {
          params.amsFileList.push({
            blobContainerName: 'FILES',
            fileFullPath: item.fileFullPath,
            fileName: item.fileName,
            fileSize: item.fileSize,
          });
        });
      }
      if (params.amsFileList.length == 0) {
        projectForm.setFieldsValue({ file: null });
      }
      projectForm.validateFields().then(
        () => {
          updateProject(params).then((res: any) => {
            if (res && res.code === 200) {
              if (params.projectStatus == 0) {
                setFileList([]);
                projectForm.setFieldsValue({ file: null });
              }
              setIsModalOpen(false);
              message.success(t('common.operateSuccess'));
              getListInformation();
            }
          });
        },
        (error) => {},
      );
    }
  };
  /**
   * @Author: April
   * @description: 编辑弹窗取消
   * @return {*}
   */
  const handleCancel = () => {
    if (projectForm) {
      projectForm.resetFields();
    }

    setFileList([]);
    setFileFlag(false);
    setIsModalOpen(false);
  };
  /* 文件上传前的钩子函数，用来判断单个文件的大小 */
  const beforeUpload = (file: RcFile) => {
    const fileSizeLimit = file.size / Math.pow(1024, 2) < 80;
    if (!fileSizeLimit) {
      message.error(t('createComplaint.fileSizeLimit'));
      return Upload.LIST_IGNORE;
    }
    return fileSizeLimit;
  };
  const handleRemove = (file) => {
    setFileList([]);
  };
  /* 文件变动的处理函数 */
  const handleChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList];
    setFileList(newFileList);
  };

  /* 处理附件上传 */
  const handleUpload = (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append('blobContainerName', 'PROJECT');
    formData.append('fileList', options.file);
    let fileList: any = [{}];

    fileUpload(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          fileList[0].status = 'done';
          fileList[0].url =
            'https://alphatest.blob.core.windows.net/' + `${res.data[0].blobContainerName}/${res.data[0].fileSaveName}`;
          fileList[0].fileName = res.data[0].fileName;
          fileList[0].fileSaveName = res.data[0].fileSaveName;
          fileList[0].suffix = res.data[0].suffix;
          setAttachmentName(res.data[0].fileSaveName);
          onSuccess(fileList);
        } else {
          onError(t('applicationVersionManagement.fileUploadError'));
        }
      })
      .catch(() => {
        onError(t('applicationVersionManagement.fileUploadError'));
      });
  };
  const handleRadioChange = (e) => {
    if (e.target.value == 1) {
      setFileFlag(true);
    } else {
      setFileFlag(false);
    }
  };
  /* 关联用户组-打开 */
  const handleOpenGroup = (record) => {
    getUserGroupList();
    setIsUsergroupModelOpen(true);
    setBindId(record.id);
    userGroupForm.setFieldsValue({ userGroup: record.groupName });
  };
  /* 关联用户组-取消 */
  const handleUsergroupCancel = () => {
    setIsUsergroupModelOpen(false);
  };
  /* 关联用户组-确定 */
  const handleUsergroupOk = () => {
    let param: any = {};
    param.groupName = userGroupForm.getFieldsValue() && userGroupForm.getFieldsValue().userGroup;
    param.id = bindId;
    userGroupList &&
      userGroupList.forEach((item) => {
        if (item.groupName == userGroupForm.getFieldValue('userGroup')) {
          param.userGroupId = item.id;
        }
      });
    userGroupForm.validateFields().then(
      (params) => {
        bindUserGroup(param).then((res: any) => {
          if (res && res.code === 200) {
            message.success(t('common.operateSuccess'));
            setIsUsergroupModelOpen(false);
            getListInformation();
          }
        });
      },
      (error) => {},
    );
  };
  /* 获取逆变器型号列表 */
  const getMinvList = () => {
    getMinvsList()
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          setMinvSourceList([...res.data]);
        } else {
          setMinvSourceList([]);
        }
      })
      .catch(() => {
        setMinvSourceList([]);
      });
  };
  /* 获取用户组列表 */
  const getUserGroupList = () => {
    getGroupListByType({ groupType: 3 })
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          setUserGroupList([...res.data]);
        } else {
          setUserGroupList([]);
        }
      })
      .catch(() => {
        setUserGroupList([]);
      });
  };
  /* 获取逆变器型号名称 */
  const handleMinvChange = (value, option) => {
    setInvSpc(option.name);
    getInvSpecSns(value);
  };

  /**
   * @description: 获取SN识别规则列表
   * @param {*} val
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getInvSpecSns = (val) => {
    getInvSpecSn({ key: val }).then((res: any) => {
      if (res && res.code === 200 && res.data) {
        minv = res.data.minv;
        invSpecName = res.data.invSpec;
        if (res.data.snList.length > 0) {
          projectForm.setFieldValue('snList', res.data.snList);
        }
      }
    });
  };

  /* 自定义校验规则 */
  const handleIdValidator = (rule, value, callback) => {
    let currentIndex = Number(rule.field.split('.')[1]);
    let currentRule = rule.field.split('.')[0];
    if (currentRule == 'snList') {
      let snList = projectForm.getFieldValue('snList');
      if (value != '' && value != undefined && value != null && value.trim() != '') {
        if (value.trim().length != 7) {
          callback(t('inverterTypeNumberConfiguration.seven'));
        } else if (snList.length > 1) {
          snList.forEach((item, index) => {
            if (item && item.sysSn == value && currentIndex !== index) {
              callback(t('inverterTypeNumberConfiguration.SNRulesNotAllowRepeat'));
            }
          });
        }
      }
    }
    callback();
  };
  return (
    /*项目管理*/
    <>
      <div className="custom-contant project-management">
        <Form
          form={searchForm}
          name="search_form"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
          layout="vertical"
        >
          <Row gutter={12} align={'bottom'}>
            {/* 项目名称 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item label={t('projectManagement.projectName')} name="project">
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item>
                <Space size="middle">
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {/* 新增 */}
                  <Button type="primary" key="add" shape="round" onClick={() => displayAddOrEditDialog(1)}>
                    {t('common.addBtn')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          loading={tableLoading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 600, y: window.innerHeight - 350 }}
          className="custom-table-flex"
        />
      </div>
      {/* 新增编辑弹窗 */}
      <Modal
        maskClosable={false}
        title={addOrEdit === 1 ? t('common.addBtn') : t('common.editBtn')}
        open={isModalOpen}
        onCancel={handleCancel}
        width="40%"
        footer={[
          /* 取消 */
          <Button key="back" shape="round" onClick={handleCancel}>
            {t('common.cancelBtn')}
          </Button>,
          /* 保存 */
          <Button key="submit" shape="round" type="primary" onClick={handleSubmit}>
            {t('common.saveBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form autoComplete="off" layout="vertical" form={projectForm}>
          {/* 项目名称 */}
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={t('projectManagement.projectName')}
                name="project"
                rules={[
                  { required: true, message: `${t('projectManagement.enterProjectName')}`, whitespace: true },
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > 64) {
                        return Promise.reject(t('common.maxlengLimit', { maxLength: 64 }));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>

          {/* 逆变器型号 */}
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={t('projectManagement.InverterModel')}
                name="invSpecId"
                rules={[{ required: true, message: `${t('projectManagement.selectInverterModel')}` }]}
              >
                <Select
                  onChange={handleMinvChange}
                  allowClear
                  placeholder={`${t('projectManagement.InverterModel')}`}
                  showSearch
                  filterOption={(input, option: any) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {minvSourceList &&
                    minvSourceList.length > 0 &&
                    minvSourceList.map((item: any, index) => {
                      return (
                        <Option value={item.key} key={item.key} name={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>{' '}
            </Col>
          </Row>

          {/* SN识别规则列表 */}
          <Row gutter={6}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.List name="snList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row key={'snList' + index} className="dynamic-form">
                        <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                          <Form.Item
                            {...field}
                            label={t('inverterTypeNumberConfiguration.SNRules') + (index + 1)}
                            name={[field.name, 'sysSn']}
                            rules={[
                              { required: true, message: `${t('inverterTypeNumberConfiguration.SNRulesRequired')}` },
                              { validator: handleIdValidator },
                            ]}
                          >
                            <Input
                              placeholder={`${t('inverterTypeNumberConfiguration.pleaseEnterPrix')}`}
                              id={'sysSn' + field.name}
                              maxLength={7}
                              className="disabled-style"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={2} xl={2}>
                          <Space>
                            <Button
                              size="small"
                              style={{ marginTop: '32px', backgroundColor: '#87ca16', color: '#ffffff' }}
                              shape="circle"
                              onClick={() => {
                                add();
                              }}
                            >
                              +
                            </Button>
                            {index > 0 ? (
                              <Button
                                size="small"
                                style={{ marginTop: '32px', backgroundColor: '#ff0000', color: '#ffffff' }}
                                shape="circle"
                                onClick={() => {
                                  remove(field.name);
                                }}
                              >
                                -
                              </Button>
                            ) : null}
                          </Space>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          {/* 是否结案 */}
          <Row gutter={12}>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Form.Item
                name="projectStatus"
                label={t('projectManagement.isCloseCase')}
                rules={[{ required: true, message: `${t('projectManagement.selectIsCloseCase')}` }]}
              >
                <Radio.Group onChange={handleRadioChange}>
                  <Radio value={1}>{t('common.yes')}</Radio>
                  <Radio value={0}>{t('common.no')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {/* 附件 */}
          {fileFlag ? (
            <Row gutter={12}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Form.Item
                  label={
                    <Tooltip title={t('createComplaint.warnThree')}>
                      <span>
                        {t('createComplaint.Attachment')} <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  rules={[{ required: true, message: `${t('projectManagement.pleaseSelectAttachment')}` }]}
                  name="file"
                >
                  <Upload
                    maxCount={3}
                    showUploadList={true}
                    onChange={handleChange}
                    fileList={fileList}
                    customRequest={(option) => handleUpload(option)}
                    onRemove={handleRemove}
                    beforeUpload={beforeUpload}
                  >
                    <Button shape="round" disabled={fileList.length >= 3}>
                      {t('projectManagement.selectAttachment')}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </Form>
      </Modal>

      {/* 关联用户组 */}
      <Modal
        maskClosable={false}
        title={t('projectManagement.associatedUsergroups')}
        open={isUsergroupModelOpen}
        onCancel={handleUsergroupCancel}
        width="40%"
        footer={[
          /* 取消 */
          <Button key="back" shape="round" onClick={handleUsergroupCancel}>
            {t('common.cancelBtn')}
          </Button>,
          /* 保存 */
          <Button key="submit" shape="round" type="primary" onClick={handleUsergroupOk}>
            {t('common.saveBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form autoComplete="off" layout="vertical" form={userGroupForm}>
          {/* 用户组 */}
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={t('projectManagement.userGroup')}
                name="userGroup"
                rules={[{ required: true, message: `${t('projectManagement.selectUserGroup')}` }]}
              >
                <Select allowClear placeholder={`${t('projectManagement.selectUserGroup')}`}>
                  {userGroupList &&
                    userGroupList.length > 0 &&
                    userGroupList.map((item: any, index) => {
                      return (
                        <Option value={item.groupName} key={item.id}>
                          {item.groupName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(projectManagement);
