/*
 * @Description: 客户反馈->客诉详情页
 * @Author: Wayne Hu
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2025/03/17
 */

import React, { memo, useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, Row, Col, message, Rate, List, Dropdown } from 'antd';
import { fileDownload } from '@/api/common';
import { useTranslation } from 'react-i18next';
import { blobDownloadFile } from '@/constants/common';
import { ComplaintListStatus, isIndustryAndCommerce } from '@/utils/utils';
import {
  getCustomerComplaintDeatil,
  getAmsComplaintProcessList,
  getAmsComplaintOperateList,
  getComment,
  evaluateComplaint,
} from '@/api/clientFeedback/complaintsList';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleFilled, RightOutlined, SmileOutlined } from '@ant-design/icons';
import ClientMessages from '../ClientMessages';
import ClientMessagesChoose from '../ClientMessagesChoose';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getUrlQuery } from '@/utils/utils';
import CenterEvaluationTestCases from '../CenterEvaluationTestCases';
import QualityCustomerComplaints from '../QualityCustomerComplaints';
import NewDemandRegistration from '../NewDemandRegistration';
import TransferModal from '../TransferModal';
import TransferCrmModal from '../TransferCrmModal';
import TransferOperationNew from '../TransferOperationNew';
import BackModal from '../BackModal';
import ArchiveModal from '../ArchiveModal';
import HandleModal from '../HandleModal';
import HandleWithFileModal from '../HandleWithFileModal';
import MermaidChart from '../MermaidChart';
import CustomerClientStatus from '../CustomerClientStatus';
import ClientStatus from '../ClientStatus';
import CenterHandleWithFileModal from '../CenterHandleWithFileModal';
import QualityHandleWithFileModal from '../QualityHandleWithFileModal';
import { ifClient } from '@/utils/utils';
import { config } from '@/constants/config';
import TransferCountry from '../TransferCountry';
import { getToken } from '@/utils/localStoreUtil';
import ImportKeyCusComModal from '../ImportKeyCusComModal';

interface ProcessList {
  [propName: string]: any;
}
/* 客户评价内容 */
type CustomerReviews = {
  content?: string; // 评价内容
  createServerDatetime?: string; // 创建时间
  isCoustomerReply?: number; // 是否客户重新评价 0-未评价 1-已评价
  isDelete?: number; // 是否删除
  isSevriceReply?: number; // 是否客服追评 0-未评价 1-已评价
  satisfactionWithProcessingResults?: any;
  satisfactionWithResolutionTime?: any;
  satisfactionWithServiceAttitude?: any;
  serviceContect?: string; // 客服回复内容
  updatetime?: string; // 更新时间
};

/* 客诉权限按钮 */
type BtnAuth = {
  canEdit: boolean; // 是否可以编辑
  canEvaluate: boolean; // 是否可以评价
  canUrgingProcessing: boolean; // 是否可以催促
  canComment: boolean; // 是否可以留言
  checkCanCustomerReviews: boolean; // 是否可以重新评价
  hasComment: boolean; // 是否有留言详情
};

/* 客诉详情字段 */
type ComplaintDetail = {
  id: number; // 客诉单号
  key?: string; // 主键
  area?: string; // 客诉所属区域
  attachmentName?: string; // 附件文件名
  attachment2Name?: string; // 附件2文件名
  attachment3Name?: string; // 附件3文件名
  attachment4Name?: string; // 附件4文件名
  attachment5Name?: string; // 附件5文件名
  attachment6Name?: string; // 附件6文件名
  attachmentShowName?: string; // 附件文件名
  attachment2ShowName?: string; // 附件2文件名
  attachment3ShowName?: string; // 附件3文件名
  attachment4ShowName?: string; // 附件4文件名
  attachment5ShowName?: string; // 附件5文件名
  attachment6ShowName?: string; // 附件6文件名
  businessLine: number; // 业务线
  complaintsType?: string; // 客诉类型
  contactNumber?: string; // 联系电话
  createServerDatetime?: string; // 创建时间
  creator?: string; // 创建人
  currentProcessor?: string; // 当前处理人
  description?: string; // 客诉描述
  email?: string; // 邮箱
  onsiteHandler?: string; // 现场处理人
  postCode?: string; // 邮编
  receiveTime?: string; // 接受时间
  recipient?: string; // 接受处理人
  sysLicense?: string; // 系统License
  sysSn: string; // 系统SN
  status?: any;
  title: string; // 客诉标题
  updatetime: string; // 最后更新时间
  viewComplaintsProgress?: boolean; // 是否显示查看客诉进度按钮
  complaintsProcessingList?: ProcessList[]; // 客诉处理流程
  customerReviews?: CustomerReviews; // 评价内容
  historicalComplaintsList: ProcessList[]; // 历史客诉
  systemSn: string;
  country: string;
  stateProvince: string;
  city: string;
  address: string;
  license: string;
  postcode: string;
  customerPhone: string;
  customerEmail: string;
  lastupdateDatetime: string;
  ticketNumber: string;
  crmCaseCreatedBy: string;
  crmCaseCreatedTime: string;
  createAccount: string;
  createDatetime: string;
  questionType: number;
  caseCategory: number;
  amsFileList: any;
  evaluationContent: string;
  satisfactionWithProcessingResults: any;
  satisfactionWithResolutionTime: any;
  satisfactionWithServiceAttitude: any;
  emphasisTitle: string;
};

let key: string;
let messageData: any = [];
const CustomerComplaintDetails: React.FC = (props) => {
  const { t } = useTranslation();
  const evaluateForm = useRef<any>();
  const history = useHistory();
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  const userName = useSelector((state: RootStateOrAny) => state.get('user').get('userName'));
  /* 显示评价窗口 */
  const [showEvaluate, setShowEvaluate] = useState(false);
  /* 评价类型 */
  const [evaluateType, setEvaluateType] = useState<number>(1);
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const { TextArea } = Input;
  /* 客诉详情是数据 */
  const [complaintDetail, setComplaintDetail] = useState<ComplaintDetail>();
  const [fileImgWidth, setFileImgWidth] = useState<number>(300);
  const [fileImg, setFileImg] = useState<string>('');
  const [viewImg, setViewImg] = useState<boolean>(false);

  /* 显示更多历史客诉 */
  const [viewHistory, setViewHistory] = useState<boolean>(false);
  /* 显示留言窗口 */
  const [leaveMessage, setLeaveMessage] = useState<boolean>(false);

  const [operateType, setOperateType] = useState<any>();
  /* 操作类型 */
  const [operateFormType, setOperateFormType] = useState<any>();
  const [ImgDown, setImgDown] = useState<any>({
    rep: {},
    name: '',
  });
  const [btnsAuth, setBtnsAuth] = useState<BtnAuth>({
    canEdit: true, // 是否可以编辑
    canEvaluate: true, // 是否可以评价
    canUrgingProcessing: true, // 是否可以催促
    canComment: true, // 是否可以留言
    checkCanCustomerReviews: true, // 是否可以重新评价
    hasComment: true, // 是否有留言详情
  });

  const [tipsWordOpen, setTipsWordOpen] = useState<boolean>(false);
  const [operateOpen, setOperateOpen] = useState<boolean>(false);
  const [backTime, setBackTime] = useState<number>(5);
  /* 显示客诉状态 */
  const [complaintStatus, setComplaintStatus] = useState<boolean>(false);
  /* 客诉状态数据  */
  const [complaintStatusData, setComplaintStatusData] = useState<any>([]);
  /* 操作列表 */
  const [operateList, setOperateList] = useState<any>([]);
  const [extendParams, setExtendParams] = useState<any>({});
  /* 控制客诉状态流程图的重新加载 */
  const [showProcessStatus, setShowProcessStatus] = useState(false);
  /* 控制客户客诉状态流程图的重新加载 */
  const [showCustomerProcess, setShowCustomerProcess] = useState(false);
  /* 客诉状态节点数据 */
  const [nodeData, setNodeData] = useState<any>();
  /* 是否显示 查看留言 */
  const [messageFlag, setMessageFlag] = useState<boolean>(false);
  /* 是否显示 评价 */
  const [canEvaluate, setCanEvaluate] = useState<boolean>(false);

  /**
   * @description: 获取客诉操作列表
   * @param {*} params
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getOperationLists = (params) => {
    getAmsComplaintOperateList({
      complaintId: params.id,
      processInstanceId: params.processInstanceId,
      processorGroupId: params.processorGroupId,
      taskInstanceId: params.taskInstanceId,
    })
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          messageData.push(...res.data);
          setOperateList(messageData);
        } else {
          setOperateList([]);
        }
      })
      .catch(() => {
        setOperateList([]);
      });
  };

  /**
   * @description: 获取客诉详情和操作列表
   * @param {*} id
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getCustomerComplaintDeatils = (id) => {
    messageData = [];
    getCustomerComplaintDeatil({ ro: id }).then((res: any) => {
      if (res && res.code === 200 && res.data) {
        setComplaintDetail({ ...res.data });
        setShowCustomerProcess(true);
        setExtendParams({ ...res.data });
        if (res.data.createAccount == userName && res.data.closedStatusUpdatedOn) {
          setCanEvaluate(true);
          if (
            res.data.satisfactionWithProcessingResults ||
            res.data.satisfactionWithResolutionTime ||
            res.data.satisfactionWithServiceAttitude
          ) {
            setCanEvaluate(false);
          } else {
            setCanEvaluate(true);
          }
        }
        if (!res.data.closedStatusUpdatedOn) {
          if (roles.includes('reseller') || roles.includes('installer') || roles.includes('customer')) {
            let data: any = [
              {
                operationNameShow: t('complaintList.clientMessage'),
                formType: '留言',
              },
            ];
            messageData.push(...data);
          } else {
            let data: any = [
              {
                operationNameShow: t('complaintList.leaveMessage'),
                formType: '留言',
              },
            ];
            messageData.push(...data);
          }
        }
        if (res.data.taskInstanceId && res.data.id && res.data.processInstanceId && res.data.processorGroupId) {
          getOperationLists({ ...res.data });
        } else {
          if (!res.data.closedStatusUpdatedOn) {
            setOperateList(messageData);
          } else {
            setOperateList([]);
          }
        }
      }
    });
  };
  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    if (localStorage.getItem('messageKey')) {
      key = localStorage.getItem('messageKey') as any;
    } else {
      key = getUrlQuery('key');
    }

    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
      /* 获取当前客诉详情 */
      getCustomerComplaintDeatils(key);
      getAmsComplaintProcess(key);
      getComment({ key: key })
        .then((res: any) => {
          if (res && res.code === 200) {
            if (res.data.length === 0) {
              setMessageFlag(false);
            } else {
              setMessageFlag(true);
            }
          } else {
            setMessageFlag(false);
          }
        })
        .catch(() => {
          setMessageFlag(false);
        });
    }
  }, []);

  /**
   * @description: 获取客诉流程状态
   * @param {*} id
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getAmsComplaintProcess = (id) => {
    getAmsComplaintProcessList({ ro: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        setShowProcessStatus(true);
        setComplaintStatusData(res.data[0]);
      }
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载或预览客诉附件
   * @param {string} fileName
   */
  const downloadOrPreviewAttachments = (fileName: string | undefined) => {
    let params = {
      blobContainerName: 'FILES',
      fileBlobName: fileName,
    };
    fileDownload(params).then((res: any) => {
      let imgType = '.bmp.jpg.jpeg.png.gif'; // 可供预览的文件格式
      let indexNum: any = fileName?.lastIndexOf('.');
      let fileSuffix: string = fileName?.substring(indexNum + 1, fileName.length).toLowerCase() || ''; // 获取文件后缀名
      let fileInfo = {
        rep: res,
        name: fileName,
      };
      setImgDown({ ...fileInfo });
      if (imgType.indexOf(fileSuffix) > -1) {
        let src = window.URL.createObjectURL(res.data);
        let img = new Image();
        img.src = src;
        img.onload = function () {
          setFileImgWidth(500);
          setFileImg(src);
          setViewImg(true);
        };
      } else {
        blobDownloadFile(res, fileName);
      }
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 关闭图片预览窗口
   */
  const closePreview = () => {
    setViewImg(false);
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  /* 下载预览图片 */
  const downLoadFile = () => {
    blobDownloadFile(ImgDown.rep, ImgDown.name);
    setViewImg(false);
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  /* 关闭历史客诉窗口 */
  const cancelViewHistory = () => {
    setViewHistory(false);
  };

  /* 处理评价 */
  const handleEvaluate = (type: number) => {
    setShowEvaluate(true);
    setEvaluateType(type);
  };

  /* 催促处理客诉 */
  const urgeHandleComplaint = (val: any) => {
    if (val.formType == '留言') {
      openLeaveMessage();
    } else {
      setOperateType(val);
      setOperateFormType(val.formType);
      setOperateOpen(true);
    }
  };

  /* 打开留言窗口 */
  const openLeaveMessage = () => {
    setLeaveMessage(true);
  };

  /* 转化url参数内容 */
  const parseUrlParams = (item: any) => {
    let arrayTemp: any = document.location.search.substring(1).split('&');
    let newArray: any = [];

    for (let i = 0; i < arrayTemp.length; i++) {
      if (arrayTemp[i].indexOf('key=') < 0) {
        newArray.push(arrayTemp[i]);
      }
    }
    if (item && item.key) {
      newArray.push('key=' + item.key);
      return newArray.join('&');
    } else {
      return newArray.join('&');
    }
  };

  /* 取消留言/留言成功后关闭窗口 */
  const handleClose = () => {
    setLeaveMessage(false);
    let key: string = getUrlQuery('key');
  };

  /* 处理从留言组件传递过来的值 */
  const handleValueChange = (value) => {
    setMessageFlag(value);
  };

  /* 从客诉详情跳转至客诉列表页面 */
  const backToComplaintList = () => {
    const params = new URLSearchParams(document.location.search);
    const result: any = {};
    params &&
      params.forEach((value, key) => {
        if (value) {
          result[key] = value;
        }
      });
    if (result.caseStatusList) {
      result.caseStatusList = result.caseStatusList.split(',').map(Number);
    }
    if (result.countryList) {
      result.countryList = result.countryList.split(',');
    }
    if (result.hasWorkOrder) {
      if (result.hasWorkOrder === 'true') {
        result.hasWorkOrder = true;
      } else {
        result.hasWorkOrder = false;
      }
    }
    let url = '';
    switch (isIndustryAndCommerce()) {
      case ComplaintListStatus.consumerComplaintList:
        url = '/clientListAfterSales/consumerComplaintList';
        break;
      case ComplaintListStatus.listIndustrialAndCommercialCustomerComplaintsPage:
        url = '/clientListAfterSales/listIndustrialAndCommercialCustomerComplaintsPage';
        break;
      default:
        url = '/clientListAfterSales/userComplaintListPage';
        break;
    }
    history.push({
      pathname: !roles.includes('dasw') ? '/clientList/index' : url,
      state: result,
    });
  };

  /* 关闭评价窗口 */
  const closeEvaluate = () => {
    setShowEvaluate(false);
  };

  /* 处理评价分数变化 */
  const handleRateChange = (event, type) => {
    if (type === 1) {
      if (event === 0) {
        evaluateForm && evaluateForm.current.setFieldValue('satisfactionWithProcessingResults', 1);
      }
    }
    if (type === 2) {
      if (event === 0) {
        evaluateForm && evaluateForm.current.setFieldValue('satisfactionWithResolutionTime', 1);
      }
    }
    if (type === 3) {
      if (event === 0) {
        evaluateForm && evaluateForm.current.setFieldValue('satisfactionWithServiceAttitude', 1);
      }
    }
  };

  /* 提交评价 */
  const submitEvaluate = (values) => {
    let key: string = getUrlQuery('key');
    evaluateForm &&
      evaluateForm.current.validateFields().then((values) => {
        let paramsObj: any = {
          key: key,
          evaluationContent: values.content || '',
          satisfactionWithProcessingResults: values.satisfactionWithProcessingResults || 0,
          satisfactionWithResolutionTime: values.satisfactionWithResolutionTime || 0,
          satisfactionWithServiceAttitude: values.satisfactionWithServiceAttitude || 0,
        };
        if (evaluateType === 1) {
          /* 首次评价 */
          evaluateComplaint(paramsObj).then((res: any) => {
            if (res && res.code === 200) {
              message.success(t('complaintList.evaluationSuccess'));
              setShowEvaluate(false);
              setEvaluateType(1);
              getCustomerComplaintDeatils(key);
            }
          });
        }
      });
  };
  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: April
   */
  const handleCanceTip = () => {
    setTipsWordOpen(false);
  };

  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: April
   */
  const handleCanceOperate = () => {
    setOperateOpen(false);
    getCustomerComplaintDeatils(key);
    getAmsComplaintProcess(key);
    setNodeData('');
    setOperateFormType('');
  };
  /* 留言关闭窗口 */
  const handleStatusClose = () => {
    setComplaintStatus(false);
  };

  /**
   * @description: 回显对应的表单
   * @param {*} type
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const handleOnOpen = (data) => {
    if (data.data.currentStatus !== 'Complete') {
      setNodeData(data);
      setOperateType('');
      setOperateFormType(data.data.formType);
      setOperateOpen(true);
    }
  };

  const linkToAlphaCloud = (val) => {
    const token = getToken();
    const params = '?sn=' + val.target.text + '&token=' + token;
    if (!roles.includes('customer')) {
      window.open(config.alphaCloud + params, '_blank');
    }
  };

  return (
    <>
      <Form layout="vertical" form={searchForm} name="complaintDetail" autoComplete="off" style={{ width: '100%' }}>
        <Row style={{ float: 'right' }}>
          {/* 返回客诉列表页 */}
          <a onClick={backToComplaintList} className="color-label">
            {t('complaintList.back')}
          </a>
        </Row>
        <Row gutter={8} className="row-border">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.title')}</span>}
            >
              <div>
                <span>{complaintDetail?.title}</span>
                <br />
                <p>
                  <span>
                    {t('complaintList.complaintNumber')}: {complaintDetail?.ticketNumber}
                  </span>
                  &nbsp;&nbsp;
                  <span>
                    {t('complaintList.creator')}: {complaintDetail?.createAccount}
                  </span>
                  &nbsp;&nbsp;
                  <span>
                    {t('complaintList.createTime')}: {complaintDetail?.createServerDatetime}
                  </span>
                </p>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8} className="row-border">
          {/* SN */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.sysSn')}</span>}
            >
              <a className="color-label" onClick={linkToAlphaCloud}>
                {complaintDetail?.systemSn}
              </a>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 国家 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.area')}</span>}
            >
              <span>{complaintDetail?.country}</span>
            </Form.Item>
          </Col>
          {/* 省/州 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('createComplaint.province')}</span>}
            >
              <span>{complaintDetail?.stateProvince}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 城市 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('createComplaint.city')}</span>}
            >
              <span>{complaintDetail?.city}</span>
            </Form.Item>
          </Col>
          {/* 详细地址 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('createComplaint.detailAddress')}</span>}
            >
              <span>{complaintDetail?.address}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 客诉类型 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.complaintsType')}</span>}
            >
              {complaintDetail?.caseCategory == 10 ? <span>{t('complaintList.systemError')}</span> : ''}
              {complaintDetail?.caseCategory == 20 ? <span>{t('complaintList.monitoringDataAnomaly')}</span> : ''}
              {complaintDetail?.caseCategory == 30 ? <span>{t('complaintList.technicalConsulting')}</span> : ''}
              {complaintDetail?.caseCategory == 40 ? <span>{t('complaintList.complaintTypeoTher')}</span> : ''}
            </Form.Item>
          </Col>
          {/* license */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.license')}</span>}
            >
              <span>{complaintDetail?.license}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 邮编 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.postCode')}</span>}
            >
              <span>{complaintDetail?.postcode}</span>
            </Form.Item>
          </Col>
          {/* 电话 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.contactNumber')}</span>}
            >
              <span>{complaintDetail?.customerPhone}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 邮箱 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.email')}</span>}
            >
              <span>{complaintDetail?.customerEmail}</span>
            </Form.Item>
          </Col>
          {/* 重点客诉标题 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.key_customer_complaint_titles')}</span>}
            >
              <span style={{ color: 'red' }}>{complaintDetail?.emphasisTitle}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 最后更新时间	 */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.lastUpdatetime')}</span>}
            >
              <span>{complaintDetail?.lastupdateDatetime}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 客诉描述 */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.description')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{complaintDetail?.description}</div>
            </Form.Item>
          </Col>
        </Row>

        {/* 客诉状态 */}
        <Row gutter={8} className="row-border">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.complaintStatus')}</span>}
            >
              {ifClient(roles) ? (
                // showProcessStatus ?
                showCustomerProcess ? (
                  <CustomerClientStatus data={complaintDetail} onOpen={handleOnOpen} />
                ) : null
              ) : // : null
              showProcessStatus ? (
                <MermaidChart data={complaintStatusData} onOpen={handleOnOpen} />
              ) : null}
            </Form.Item>
          </Col>
        </Row>

        {/* 附件列表 */}
        {complaintDetail?.amsFileList && complaintDetail?.amsFileList.length > 0 ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {complaintDetail?.amsFileList.map((item, index) => {
                return (
                  <div key={index}>
                    <a onClick={() => downloadOrPreviewAttachments(item.fileFullPath)}>{item.fileName}</a>
                  </div>
                );
              })}
            </Col>
          </Row>
        ) : null}

        {/* 评价内容*/}
        {complaintDetail?.satisfactionWithProcessingResults ||
        complaintDetail?.satisfactionWithResolutionTime ||
        complaintDetail?.satisfactionWithServiceAttitude ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                className="complaint-detail-label"
                label={<span className="form-item-label">{t('complaintList.commentContent')}</span>}
              >
                <span>{t('complaintList.resolveResultSatisfy')}: </span>
                <Rate
                  disabled
                  count={5}
                  value={complaintDetail?.satisfactionWithProcessingResults}
                  character={<SmileOutlined />}
                />
                <br />
                <span>{t('complaintList.useTimeSatisfy')}: </span>
                <Rate
                  disabled
                  count={5}
                  value={complaintDetail?.satisfactionWithResolutionTime}
                  character={<SmileOutlined />}
                />
                <br />
                <span>{t('complaintList.serviceStaisfy')}: </span>
                <Rate
                  disabled
                  count={5}
                  value={complaintDetail?.satisfactionWithServiceAttitude}
                  character={<SmileOutlined />}
                />
                <br />
              </Form.Item>
            </Col>
            {complaintDetail?.evaluationContent ? (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextArea
                  style={{ resize: 'none' }}
                  rows={3}
                  readOnly
                  bordered={false}
                  value={complaintDetail?.evaluationContent}
                />
              </Col>
            ) : null}
          </Row>
        ) : null}
        {/* 客服回复 */}
        {complaintDetail?.customerReviews?.serviceContect ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                className="complaint-detail-label"
                label={<span className="form-item-label">{t('complaintList.Servicetel')}</span>}
              >
                <p>{complaintDetail?.customerReviews?.serviceContect}</p>
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        {/* 查看留言 */}
        {messageFlag ? (
          <Row className="row-border" gutter={8}>
            <a style={{ margin: '6px' }} onClick={openLeaveMessage}>
              {t('complaintList.checkMessage')}
            </a>
          </Row>
        ) : null}

        <br />
        <Row gutter={2}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <ul className="operation-btns">
              {/* 评价 */}
              {canEvaluate ? (
                <li>
                  <Button key="evaluate" shape="round" style={{ width: '100%' }} onClick={() => handleEvaluate(1)}>
                    {t('complaintList.evaluate')}
                  </Button>
                </li>
              ) : null}
              &nbsp;
              {/* 操作列表 */}
              {operateList && operateList.length > 0 ? (
                <li>
                  <Dropdown
                    menu={{
                      items: operateList.map((it) => ({
                        key: it.key,
                        label: it.operationNameShow,
                        onClick: () => urgeHandleComplaint(it),
                        icon: <RightOutlined />,
                      })),
                    }}
                    placement="top"
                    trigger={['click']}
                  >
                    <Button key="operate" shape="round" style={{ width: '100%' }}>
                      {t('complaintList.operate')}
                    </Button>
                  </Dropdown>
                </li>
              ) : null}
            </ul>
          </Col>
        </Row>
      </Form>

      {/* 客诉附件 图片预览和下载 */}
      <Modal
        title={t('complaintList.preview')}
        open={viewImg}
        onCancel={closePreview}
        width="60%"
        maskClosable={false}
        destroyOnClose
        forceRender
        footer={[
          <Button key="download" shape="round" onClick={downLoadFile}>
            {t('complaintList.download')}
          </Button>,
          <Button key="close" shape="round" type="primary" onClick={closePreview}>
            {t('complaintList.close')}
          </Button>,
        ]}
      >
        <div style={{ width: '100%', overflow: 'auto' }}>
          <img src={fileImg} width={fileImgWidth} alt="加载失败" style={{ margin: 'auto', display: 'block' }} />
        </div>
      </Modal>

      {/* 查看更多历史客诉 */}
      <Modal
        title={t('complaintList.historyComplaint')}
        open={viewHistory}
        onCancel={cancelViewHistory}
        width="50%"
        destroyOnClose
        maskClosable={false}
        footer={null}
      >
        <List
          header={null}
          footer={null}
          style={{ height: '500px', width: '100%', overflowY: 'scroll', border: '1px solid #dddddd' }}
          dataSource={complaintDetail?.historicalComplaintsList}
          renderItem={(item, index) => (
            <List.Item key={item.key} style={{ borderBottom: '1px solid #dddddd', padding: '8px 4px' }}>
              <a
                className="wrap-a"
                href={'/innerStatic/customerComplaintDetails/index?' + parseUrlParams(item)}
                target="_blank"
              >
                {item.title}
              </a>
            </List.Item>
          )}
        />
      </Modal>

      {/* 客诉评价 */}
      <Modal
        title={t('complaintList.evaluate')}
        open={showEvaluate}
        width="50%"
        onCancel={closeEvaluate}
        destroyOnClose
        maskClosable={false}
        footer={[
          <Button key="cancel" shape="round" onClick={closeEvaluate}>
            {t('complaintList.cancel')}
          </Button>,
          <Button key="submit" shape="round" type="primary" onClick={submitEvaluate}>
            {t('complaintList.submit')}
          </Button>,
        ]}
      >
        <Form
          ref={evaluateForm}
          initialValues={{
            satisfactionWithProcessingResults: 1,
            satisfactionWithResolutionTime: 1,
            satisfactionWithServiceAttitude: 1,
            content: '',
          }}
        >
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="satisfactionWithProcessingResults"
                label={t('complaintList.feedBackValueSatisfaction')}
                required
              >
                <Rate
                  count={5}
                  allowClear
                  character={<SmileOutlined />}
                  onChange={(e) => {
                    handleRateChange(e, 1);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="satisfactionWithResolutionTime"
                label={t('complaintList.feedBackValueSatisfaction1')}
                required
              >
                <Rate
                  count={5}
                  allowClear
                  character={<SmileOutlined />}
                  onChange={(e) => {
                    handleRateChange(e, 2);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="satisfactionWithServiceAttitude"
                label={t('complaintList.feedBackValueSatisfaction2')}
                required
              >
                <Rate
                  count={5}
                  allowClear
                  character={<SmileOutlined />}
                  onChange={(e) => {
                    handleRateChange(e, 3);
                  }}
                />
              </Form.Item>
              <Form.Item name="content">
                <TextArea placeholder={`${t('complaintList.pleaseInput')}`} rows={5} maxLength={512}></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* 客诉留言 */}
      {leaveMessage === true ? (
        roles.includes('reseller') || roles.includes('installer') || roles.includes('customer') ? (
          <ClientMessages
            leaveMessageParams={{
              key: getUrlQuery('key') || complaintDetail?.key,
              showDialog: leaveMessage,
              canComment: btnsAuth.canComment,
            }}
            complaintDetail={complaintDetail}
            closeLeaveMessage={handleClose}
            onValueChange={handleValueChange}
          ></ClientMessages>
        ) : (
          <ClientMessagesChoose
            leaveMessageParams={{
              key: getUrlQuery('key') || complaintDetail?.key,
              showDialog: leaveMessage,
              canComment: btnsAuth.canComment,
            }}
            complaintDetail={complaintDetail}
            closeLeaveMessage={handleClose}
            onValueChange={handleValueChange}
          ></ClientMessagesChoose>
        )
      ) : null}
      {/* 没有权限-提示弹窗 */}
      <Modal
        title={t('complaintList.tip')}
        open={tipsWordOpen}
        width="30%"
        maskClosable={false}
        footer={false}
        onCancel={handleCanceTip}
      >
        <div style={{ margin: '25px auto', textAlign: 'center' }}>
          <ExclamationCircleFilled />
          &nbsp;
          <span>{t('customercomplaint.noSNRight')} :</span>
          <strong>{backTime}</strong>
        </div>
      </Modal>

      {complaintStatus ? (
        <ClientStatus
          statusParams={{
            key: getUrlQuery('key') || complaintDetail?.key,
            showDialog: complaintStatus,
          }}
          closeComplaintStatus={handleStatusClose}
        ></ClientStatus>
      ) : null}

      {/* 点击操作列表 */}
      <div style={{ margin: '5px auto' }}>
        {/* 转质控 */}
        {operateFormType == 'TransferQuality' ? (
          <QualityCustomerComplaints
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 转中试 */}
        {operateFormType == 'TransferTest' ? (
          <CenterEvaluationTestCases
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 转产品需求 */}
        {operateFormType == 'TransferDemand' ? (
          <NewDemandRegistration
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 去处理或者转研发 */}
        {operateFormType == 'ToHandle' ? (
          <HandleModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 带附件区处理或者转研发 */}
        {operateFormType == 'ToHandleWithFile' ? (
          <HandleWithFileModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 转移 */}
        {operateFormType == 'TransferOperate' ? (
          <TransferModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
            detailData={complaintDetail}
          />
        ) : null}
        {/* 退回 */}
        {operateFormType == 'Back' ? (
          <BackModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 转CRM */}
        {operateFormType == 'ToCrm' ? (
          <TransferCrmModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
            businessLine={complaintDetail?.businessLine}
          />
        ) : null}
        {/* 转运营 ps：没有了，不需要迁移了 */}
        {operateFormType == 'TransferBusiness' ? (
          <TransferOperationNew
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 质控去处理 */}
        {operateFormType == 'QualityToHandle' ? (
          <QualityHandleWithFileModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 中试去处理（带附件） */}
        {operateFormType == 'ReasonToHandleWithFile' ? (
          <CenterHandleWithFileModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 归档 */}
        {operateFormType == 'Archive' ? (
          <ArchiveModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
            businessLine={complaintDetail?.businessLine}
          />
        ) : null}
        {/* 转国内 */}
        {operateFormType == 'TransferCountry' ? (
          <TransferCountry
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={key}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 导入重点客诉 */}
        {operateFormType == 'ImportEmphasisCC' ? (
          <ImportKeyCusComModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            operateFormType="ImportEmphasisCC"
            id={key}
            extendParams={extendParams}
            ticketNumber={complaintDetail?.ticketNumber}
          />
        ) : null}
      </div>
    </>
  );
};

export default memo(CustomerComplaintDetails);
