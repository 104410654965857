/*
 * @Description: 在localStorage中获取指定的信息
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/09/06 17:12:28
 * @LastEditTime: 2023/09/08 17:15:12
 */
const TOKEN_KEY = 'token';
/* 获取token */
export const getToken = () => localStorage.getItem(TOKEN_KEY);
/* 设置token */
export const setToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);
/* 移除token */
export const removeToken = () => {
  let isNewVersion: any = localStorage.getItem('isNewVersion');
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem('jwt_token');
  localStorage.clear();
  localStorage.setItem('isNewVersion', isNewVersion);
};
/* 判断是否有token */
export const hasToken = (): boolean => {
  let token = getToken();
  return undefined !== token && null !== token && '' !== token;
};
export const getUserName = () => localStorage.getItem('userName');
export const setJWTToken = (jwt_token: string) => localStorage.setItem('jwt_token', jwt_token);
export const getJWTToken = () => localStorage.getItem('jwt_token');
export const getCountry = () => localStorage.getItem('country');
export const getUserInfo = () => localStorage.getItem('userInfo');
export const getCurrentItem = () => localStorage.getItem('currentItem');
export const getUserRoles = () => localStorage.getItem('roles');
export const getSnSearch = () => localStorage.getItem('snSearch');
export const getUserId = () => localStorage.getItem('userId');
export const getLocalSn = () => localStorage.getItem('localSn');
export const setRightOption = (option: object) => localStorage.setItem('rightOption', JSON.stringify(option));
export const getRightOption = () => {
  let option: any;
  let rightOption = localStorage.getItem('rightOption');
  if (rightOption) {
    option = JSON.parse(rightOption);
  } else {
    option = {};
  }
  return option;
};
export const getSnLoading = () => localStorage.getItem('snLoading');
export const setSnLoading = (snLoading: any) => localStorage.setItem('snLoading', snLoading);

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取当前登录者的组织ID
 * @return {*}
 */
export const getLoginCoId = () => {
  return localStorage.getItem('loginCoId');
};
/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 设置当前登录者的组织ID
 * @param {string} loginCoId
 * @return {*}
 */
export const setLoginCoId = (loginCoId: string) => {
  return localStorage.setItem('loginCoId', loginCoId);
};
/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取当前登录者的用户类型
 * @return {*}
 */
export const getUserType = () => {
  return localStorage.getItem('userType');
};
/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 设置当前登录者的用户类型
 * @param {string} userType
 * @return {*}
 */
export const setUserType = (userType: string) => {
  return localStorage.setItem('userType', userType);
};
