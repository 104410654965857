/*
 * @Description: 客户反馈->客诉详情页-> 中试测试用例评估表
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2024/12/10 11:25:10
 */

import React, { memo, useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { fileDownload } from '@/api/common';
import { useTranslation } from 'react-i18next';
import { blobDownloadFile } from '@/constants/common';

interface ChildProps {
  isOpen?: boolean;
  onClosePreview: () => void;
  fileData: any;
}

const CenterEvaluationTestCases: React.FC<ChildProps> = ({ isOpen, onClosePreview, fileData }) => {
  const { t } = useTranslation();
  const [ImgDown, setImgDown] = useState<any>({
    rep: {},
    name: '',
  });
  const [fileImgWidth, setFileImgWidth] = useState<number>(300);
  const [fileImg, setFileImg] = useState<string>('');
  const [viewImg, setViewImg] = useState<boolean>(false);

  useEffect(() => {
    downloadOrPreviewAttachments(fileData.fileName);
  }, []);

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载或预览客诉附件
   * @param {string} fileName
   * @return {*}
   */
  const downloadOrPreviewAttachments = (fileName: string | undefined) => {
    let params = {
      blobContainerName: 'FILES',
      fileBlobName: fileName,
    };
    fileDownload(params).then((res: any) => {
      let imgType = '.bmp.jpg.jpeg.png.gif'; // 可供预览的文件格式
      let indexNum: any = fileName?.lastIndexOf('.');
      let fileSuffix: string = fileName?.substring(indexNum + 1, fileName.length).toLowerCase() || ''; // 获取文件后缀名
      let fileInfo = {
        rep: res,
        name: fileName,
      };
      setImgDown({ ...fileInfo });

      if (imgType.indexOf(fileSuffix) > -1) {
        let src = window.URL.createObjectURL(res.data);
        let img = new Image();
        img.src = src;
        img.onload = function () {
          setFileImgWidth(500);
          setFileImg(src);
          setViewImg(true);
        };
      } else {
        blobDownloadFile(res, fileName);
        closePreview();
      }
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 关闭图片预览窗口
   * @return {*}
   */
  const closePreview = () => {
    onClosePreview();
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  /* 下载预览图片 */
  const downLoadFile = () => {
    blobDownloadFile(ImgDown.rep, ImgDown.name);
    closePreview();
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  return (
    <>
      {/* 客诉附件 图片预览和下载 */}
      <Modal
        title={t('complaintList.preview')}
        open={viewImg}
        onCancel={closePreview}
        width="60%"
        maskClosable={false}
        destroyOnClose
        forceRender
        footer={[
          <Button key="download" shape="round" onClick={downLoadFile}>
            {t('complaintList.download')}
          </Button>,
          <Button key="close" shape="round" type="primary" onClick={closePreview}>
            {t('complaintList.close')}
          </Button>,
        ]}
      >
        <div style={{ width: '100%', overflow: 'auto' }}>
          <img src={fileImg} width={fileImgWidth} alt="加载失败" style={{ margin: 'auto', display: 'block' }} />
        </div>
      </Modal>
    </>
  );
};

export default memo(CenterEvaluationTestCases);
