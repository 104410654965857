import nation from '../../public/common/js/nation';
export default {
  punctuationFormatting(value, country) {
    if (nation.includes(country) && value) {
      if (typeof value != 'string') {
        value = String(value);
      }
      return value.replace(/\./g, '?').replace(/,/g, '.').replace(/\?/g, ',');
    } else {
      return value;
    }
  },
  commaFormatting(value, country) {
    if (nation.includes(country) && value) {
      if (typeof value != 'string') {
        value = String(value);
      }
      return value.replace(/,/g, '.');
    } else {
      return value;
    }
  },
};
