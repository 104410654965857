// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-list {
  max-height: 45vh;
  overflow-y: hidden;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}
.message-list:hover {
  overflow: auto;
}
.message-list li {
  padding: 8px 5px;
  border-bottom: 1px solid #dcdfe6;
  color: #313131;
}
.message-list li > div {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 16px;
}
.message-list li > div span:last-child {
  margin-left: 8px;
}
.message-list li > p {
  font-weight: 100;
  font-size: 12px;
}
.coment {
  border: 1px solid #dddddd;
  padding: 6px;
  border-radius: 6px;
  margin: 6px;
}
.innerCommentText {
  font-size: 16px !important;
  color: #000000;
}
.message-replay .ant-comment-actions li {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClientList/HistoricalComplaintsDetails/components/ClientMessages/index.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AACF;AACE;EACE,cAAA;AACJ;AARA;EAWI,gBAAA;EACA,gCAAA;EACA,cAAA;AAAJ;AAEI;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AAAN;AAHI;EAMI,gBAAA;AAAR;AAII;EACE,gBAAA;EACA,eAAA;AAFN;AAOA;EACE,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;AALF;AAQA;EACE,0BAAA;EACA,cAAA;AANF;AASA;EAEI,WAAA;AARJ","sourcesContent":[".message-list {\n  max-height: 45vh;\n  overflow-y: hidden;\n  border: 1px solid #dcdfe6;\n  border-radius: 5px;\n\n  &:hover {\n    overflow: auto;\n  }\n\n  li {\n    padding: 8px 5px;\n    border-bottom: 1px solid #dcdfe6;\n    color: #313131;\n\n    & > div {\n      font-weight: 500;\n      margin-bottom: 5px;\n      font-size: 16px;\n\n      span:last-child {\n        margin-left: 8px;\n      }\n    }\n\n    & > p {\n      font-weight: 100;\n      font-size: 12px;\n    }\n  }\n}\n\n.coment {\n  border: 1px solid #dddddd;\n  padding: 6px;\n  border-radius: 6px;\n  margin: 6px;\n}\n\n.innerCommentText {\n  font-size: 16px !important;\n  color: #000000;\n}\n\n.message-replay {\n  .ant-comment-actions li {\n    width: 100%;\n  }\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
