/*
 * @Description:组织权限管理
 * @Author: Summer.Chen
 * @Date: 2024-04-07 11:11:37
 * @LastEditTime: 2024/06/21 16:44:35
 * @LastEditors: Please set LastEditors
 */
import React, { ChangeEvent, ChangeEventHandler, ReactNode, useEffect, useState } from 'react';
import { Row, Col, Card, Input, Form, Button, Space, Table, Dropdown, message, Modal } from 'antd';

import {
  setCol,
  userInfoListColumns,
  dropdownButtonList,
  modalParamsType,
  teamMembers,
  teamMembersEntity,
  IBindUserState,
} from './../config';
import { useTranslation } from 'react-i18next';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import OrgTree from './components/orgTree';
import { ModalProps } from 'antd/lib/modal';
import { queryListMemberList, deleteTeamMember } from '@/api/authorityManagement';
import EditMembComponentModal from './components/editMembComponentModal';
import EditMembMenuRightComponentModal from './components/editMembMenuRightComponentModal';
import { RootStateOrAny, useSelector } from 'react-redux';
import BindUserModal from './components/bindUserModal';
const textAlign = { textAlign: 'center' };
let hasMemberRight: boolean = false;
const OrganizationRightsManagement: React.FC<any> = (props): React.ReactElement => {
  let userInfo = useSelector((state: RootStateOrAny) => state.get('user').get('userInfo'));
  try {
    if (typeof userInfo === 'string') {
      userInfo = JSON.parse(userInfo);
    }
  } catch (error) {
    userInfo = { roles: [] };
  }
  const { t } = useTranslation();
  //创建Form
  const [form] = Form.useForm();
  const [orgMemMenuRight, setOrgMemMenuRight] = useState({
    deptId: '',
    open: false,
    title: t('authorityManagement.newTeamMember'),
    type: 1,
    parentId: '',
    deptName: '',
    record: teamMembersEntity,
  });
  // 成员弹框传递的参数 type 1新增 2编辑 3 查看
  const [orgMem, setMemParams] = useState({
    deptId: '',
    open: false,
    title: t('authorityManagement.newTeamMember'),
    type: 1,
    parentId: '',
    deptName: '',
    record: teamMembersEntity,
  });
  const [userState, setUserState] = useState<IBindUserState>({ open: false, userType: '', id: '' });
  //分页及搜索条件
  const [searchParams, setSearchParams] = useState({ deptId: '', pageIndex: 1, pageSize: 10, username: '' });
  //分页总条数
  const [total, setPageTotal] = useState(1);

  // 表格数据
  const [dataList, setDataList] = useState<any>([]);
  // tableLoading
  const [tableLoading, setTableLoading] = useState(false);
  // 删除组员
  const deleteMember = (member: teamMembers) => {
    Modal.confirm({
      title: t('common.tip'),
      closable: true,
      icon: <ExclamationCircleOutlined />,
      content: t('authorityManagement.removeGroupMember'),
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      onOk() {
        deleteTeamMember({ userId: member.userId, deptId: member.deptId }).then((rep: any) => {
          if (rep && rep.code == 200) {
            message.success(t('common.deleteSuccess'));
            if (dataList.length === 1 && searchParams.pageIndex > 1) {
              setSearchParams({
                ...searchParams,
                pageIndex: searchParams.pageIndex - 1,
              });
            } else {
              getMembers();
            }
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };
  //第一次加载
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  //表格按钮操作
  const handleMenuClick: MenuProps['onClick'] = (event: any) => {
    const { record } = event.item.props;
    switch (event.key) {
      case '1':
        //查看
        setMemParams({
          deptId: record.deptId,
          open: true,
          title: t('common.viewBtn'),
          type: 3,
          parentId: '',
          deptName: record.deptName,
          record,
        });
        break;
      case '2':
        //编辑成员
        setMemParams({
          deptId: record.deptId,
          open: true,
          title: t('authorityManagement.editMembers'),
          type: 2,
          parentId: '',
          deptName: record.deptName,
          record,
        });
        break;
      case '3':
        //删除
        deleteMember(record);
        break;
      case '4':
        //编辑菜单权限
        setOrgMemMenuRight({
          deptId: record.deptId,
          open: true,
          title: t('authorityManagement.editMenuPermissions'),
          type: 1,
          parentId: '',
          deptName: record.deptName,
          record,
        });
        break;
      case '5':
        //绑定用户
        setUserState({
          open: true,
          id: record.userId,
          userType: record.userType,
        });
        break;
    }
  };
  // 表格的操作
  const tableAction = () => {
    return [
      {
        title: t('common.operation'),
        key: 'Action',
        render: (text: string, record: any) => {
          return (
            <Dropdown.Button
              buttonsRender={(buttons: React.ReactNode[]) => {
                return [
                  <Button shape="round">{t('common.options')}</Button>,
                  <Button shape="round">
                    <DownOutlined />
                  </Button>,
                ];
              }}
              menu={{
                //编辑权限
                items: dropdownButtonList(t, record).slice(
                  0,
                  (userInfo && userInfo.roles && userInfo.roles.includes('admin')) ||
                    (hasMemberRight &&
                      ((userInfo.userId !== record.userId && record.isDeptAdmin === 1) ||
                        (record.hasSuperPerms === 1 && userInfo.userId !== record.userId) ||
                        record.isDeptAdmin === 0))
                    ? record.userType === 'servicer'
                      ? 4
                      : 5
                    : 1,
                ),
                onClick: handleMenuClick,
              }}
            >
              {t('common.options')}
            </Dropdown.Button>
          );
        },
      },
    ];
  };

  //弹框关闭重新获取数据
  const callBackGetMembers = (deptId: string, hasMemberPerms: boolean, notGetMembers?: boolean) => {
    // hasMemberRight = hasMemberPerms;

    if (notGetMembers) {
      setSearchParams({
        ...searchParams,
        pageIndex: 1,
        deptId: '',
      });
      setPageTotal(1);
      setDataList([]);
      return;
    }
    if (deptId && searchParams.deptId === deptId) {
      if (searchParams.pageIndex === 1) {
        getMembers();
        return;
      }
      setSearchParams({
        ...searchParams,
        pageIndex: 1,
      });
    }
    if (deptId && searchParams.deptId !== deptId) {
      setSearchParams({
        ...searchParams,
        deptId,
        pageIndex: 1,
      });
    }
  };
  // 获取组员
  const getMembers = () => {
    if (searchParams.deptId === 'All') {
      return;
    }
    if (!searchParams.deptId) {
      return;
    }
    setTableLoading(true);
    queryListMemberList({ ...searchParams, username: form.getFieldValue('username') }).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        setDataList(rep.data.rows || []);
        setSearchParams({
          ...searchParams,
        });
        setPageTotal(rep.data.total);
      }
      setTableLoading(false);
    });
  };
  // 成员弹框的参数
  let memModalProps: ModalProps & modalParamsType = {
    ...orgMem,
    destroyOnClose: true,
    onCancel: () => {
      setMemParams({
        ...orgMem,
        open: false,
      });
    },
    width: '45%',
    cancelButtonProps: { shape: 'round' },
    okButtonProps: { shape: 'round', disabled: orgMem.type === 3 },
    initData: (deptId?: string, hasMemberPerms?: boolean) => {
      callBackGetMembers(deptId as string, hasMemberPerms as boolean);
    },
  };
  // 组员权限编辑弹框参数
  let memMenuRightModalProps: ModalProps & modalParamsType = {
    ...orgMemMenuRight,
    destroyOnClose: true,
    onCancel: () => {
      setOrgMemMenuRight({
        ...orgMemMenuRight,
        open: false,
      });
    },
    width: '45%',
    cancelButtonProps: { shape: 'round' },
    okButtonProps: { shape: 'round', disabled: orgMemMenuRight.type === 3 },
    initData: () => {
      getMembers();
    },
    okText: t('common.confirmBtn'),
    cancelText: t('common.cancelBtn'),
  };
  //点击组织树回掉函数
  const treeClickCallback = (ReactNode: ReactNode, event): void => {
    hasMemberRight = event.node.hasMemberPerms;
    setSearchParams({
      ...searchParams,
      deptId: event.node.deptId,
      pageIndex: 1,
    });
  };
  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }
    getMembers();
  }, [searchParams.pageIndex, searchParams.deptId]);
  return (
    <>
      {}
      <BindUserModal {...userState} onCancel={() => setUserState({ ...userState, open: false })} />
      {/* 组员编辑查看弹框 */}
      <EditMembComponentModal {...memModalProps} />
      {/* 组员菜单权限配置 */}
      <EditMembMenuRightComponentModal {...memMenuRightModalProps} />
      <Row gutter={20}>
        {/* 组织权限  */}
        <Col {...setCol({})}>
          <Card title={t('authorityManagement.orgPermissions')} headStyle={textAlign as {}}>
            <OrgTree treeClickCallback={treeClickCallback} getMembers={callBackGetMembers} />
          </Card>
        </Col>
        {/* 用户信息 */}
        <Col {...setCol({ xs: 24, sm: 24, md: 17, lg: 17 })}>
          <Card title={t('authorityManagement.userInfo')} headStyle={textAlign as {}}>
            <Form layout={'vertical'} form={form}>
              <Space>
                <Form.Item label={t('common.realPower.userName')} name="username">
                  <Input allowClear placeholder={t('userGroupManage.pleaseEnterUserName') as string} />
                </Form.Item>
                <Form.Item label={<span>&nbsp;</span>}>
                  <Button
                    type="primary"
                    shape={'round'}
                    onClick={() => {
                      if (searchParams.pageIndex !== 1) {
                        setSearchParams({
                          ...searchParams,
                          pageIndex: 1,
                        });
                      } else {
                        getMembers();
                      }
                    }}
                  >
                    {t('common.searchBtn')}
                  </Button>
                </Form.Item>
              </Space>
            </Form>
            <Table
              dataSource={dataList}
              columns={userInfoListColumns(t, searchParams).concat(tableAction())}
              loading={tableLoading}
              size={'small'}
              rowKey={(record: any) => record.key}
              pagination={{
                showQuickJumper: true,
                defaultCurrent: 1,
                current: searchParams.pageIndex,
                total,
                pageSize: searchParams.pageSize,
                onChange: (page: number) => {
                  setSearchParams({
                    ...searchParams,
                    pageIndex: page,
                  });
                },
              }}
              className="custom-table-flex"
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default OrganizationRightsManagement;
