/*
 * @Description:客诉分析报表
 * @Author: April
 * @Date: 2023-04-10 10:32:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/12/10 11:10:59
 */
declare var echarts: any;
import { debounce } from '@/utils/utils';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useRef } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import { comOption } from '../chartOption';
import { getComplaintsAnalysis } from '@/api/reportCenter';
import { getCountryList } from '@/api/common';
import '../../index.less';
import { useHistory } from 'react-router-dom';

let searchParams: any = {
  createBeginTime: '', // 客诉创建开始时间
  createEndTime: '', // 客诉创建结束时间
  archiveBeginTime: '', // 归档开始时间
  archiveEndTime: '', // 归档结束时间
  country: [], // 国家
  businessLine: 10,
};
const ComplaintAnalysiReport: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory(); //列表ref
  const { RangePicker } = DatePicker;
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let collapsed = useSelector((state: RootStateOrAny) => state.get('app').get('collapsed'));
  let country = useSelector((state: RootStateOrAny) => state.get('user').get('country'));
  /* 国家列表数据 */
  const [countryList, setCountryList] = useState<any>([]);
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  let lineChartRef = useRef(null);
  let [currentLineChart, setCurrentLineChart] = useState<any>(null);

  let data1: any = {
    yData: [
      t('reportCenter.customerComplaintsTotalNum'),
      t('reportCenter.beFiledCustomerComplaintsNum'),
      t('reportCenter.filedCustomerComplaintsNum'),
      t('reportCenter.transRDCustomerComplaintsNum'),
      t('reportCenter.qualityComplaintsNum'),
      t('reportCenter.operationCustomerComplaintsNum'),
    ],
    seriesData: {
      name: 'value',
      data: [],
    },
    title: t('reportCenter.complaintAnalysisReport'),
  };

  const [chartData, setChartData] = useState<any>(data1);

  // 监听菜单的缩放
  useEffect(() => {
    if (currentLineChart) {
      setTimeout(() => {
        currentLineChart.resize();
      }, 100);
    }
  }, [collapsed]);

  // 监听语言重新渲染
  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      yData: [
        t('reportCenter.customerComplaintsTotalNum'),
        t('reportCenter.beFiledCustomerComplaintsNum'),
        t('reportCenter.filedCustomerComplaintsNum'),
        t('reportCenter.transRDCustomerComplaintsNum'),
        t('reportCenter.qualityComplaintsNum'),
        t('reportCenter.operationCustomerComplaintsNum'),
      ],
      title: t('reportCenter.complaintAnalysisReport'),
    }));
  }, [language]);
  useEffect(() => {
    if (currentLineChart) {
      redrawChart(lineChartRef, chartData);
    }
  }, [chartData, language]);

  // 监听浏览器页面的缩放
  useEffect(() => {
    const __resizeHandler = debounce(
      () => {
        if (currentLineChart) {
          currentLineChart.resize();
        }
      },
      10,
      false,
    );
    window.addEventListener('resize', __resizeHandler);
    return () => {
      if (currentLineChart) {
        currentLineChart.dispose();
        setCurrentLineChart(null);
      }
      window.removeEventListener('resize', __resizeHandler);
    };
  }, [currentLineChart]);

  // 初始化图表数据
  useEffect(() => {
    getCountrysList();
    getCompAnalysisData();
    initData(lineChartRef, chartData);
  }, []);

  /* 初始化图表 */
  const initData = (chartRef, data) => {
    if (currentLineChart) {
      currentLineChart.dispose();
      setCurrentLineChart(null);
    }
    const chartInstance = echarts.init(chartRef.current);
    const options = comOption(t, data, country);
    chartInstance.setOption(options);
    setCurrentLineChart(chartInstance);
  };

  const redrawChart = (chartRef, data) => {
    const options = comOption(t, data, country);
    currentLineChart.setOption(options);
  };

  /* 获取客诉分析数据 */
  const getCompAnalysisData = () => {
    let obj: any = {
      ...data1,
      seriesData: {
        name: 'value',
        data: [],
      },
    };
    getComplaintsAnalysis(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          for (let key in rep.data) {
            if (rep.data.hasOwnProperty(key)) {
              let value = rep.data[key];
              obj.seriesData.data.push(value);
            }
          }
          setChartData(obj);
        } else {
          setChartData(obj);
        }
      })
      .catch((err) => {
        setChartData(obj);
      });
  };
  /* 查询图表 */
  const search = useCallback((values: any) => {
    searchParams.createBeginTime =
      values.dateRange && values.dateRange[0] ? moment(values.dateRange[0]).format('YYYY-MM-DD') : '';
    searchParams.createEndTime =
      values.dateRange && values.dateRange[1] ? moment(values.dateRange[1]).format('YYYY-MM-DD') : '';
    searchParams.archiveBeginTime =
      values.archiveDateRange && values.archiveDateRange[0]
        ? moment(values.archiveDateRange[0]).format('YYYY-MM-DD')
        : '';
    searchParams.archiveEndTime =
      values.archiveDateRange && values.archiveDateRange[1]
        ? moment(values.archiveDateRange[1]).format('YYYY-MM-DD')
        : '';
    let countryArr: any = [];
    if (values.tempCountryList && values.tempCountryList.length > 0) {
      for (let i = 0; i < values.tempCountryList.length; i++) {
        countryArr.push(values.tempCountryList[i]);
      }
    } else {
      countryArr = [];
    }
    searchParams.country = [...countryArr];
    searchParams.businessLine = values.businessLine;
    getCompAnalysisData();
  }, []);

  /* 获取国家列表 */
  const getCountrysList = () => {
    getCountryList()
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          setCountryList([...res.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch(() => {
        setCountryList([]);
      });
  };
  /* 处理国家列表数据 */
  const handleCountryList = () => {
    if (countryList.length > 0) {
      let tempList: any = [];
      for (let i = 0; i < countryList.length; i++) {
        tempList.push({
          label: countryList[i].areaEnglishName + '[' + countryList[i].areaFirstName + ']',
          value: countryList[i].areaEnglishName,
        });
      }
      return tempList;
    } else {
      return [];
    }
  };

  /**
   * @description: 返回报表中心
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const goBackReport = () => {
    history.goBack();
  };
  return (
    <>
      <Card>
        <Form
          form={searchForm}
          name="searchForm"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12}>
            {/* 客诉创建时间 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="dateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('complaintList.complaintCreateBegin'), t('complaintList.complaintCreateEnd')]}
                  allowClear
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            {/* 归档时间 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="archiveDateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('reportCenter.archiveBeginPla'), t('reportCenter.archiveEndPla')]}
                  allowClear
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              {/* 国家 */}
              <Form.Item name="tempCountryList">
                <Select
                  placeholder={`${t('createComplaint.country')}`}
                  maxTagCount="responsive"
                  allowClear
                  mode="multiple"
                  options={handleCountryList()}
                ></Select>
              </Form.Item>
            </Col>
            {/* 产品线 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item name="businessLine" initialValue={10}>
                <Select
                  placeholder={`${t('archivalMaintenance.productLine')}`}
                  options={[
                    { value: 10, label: t('archivalMaintenance.householdUse') },
                    { value: 20, label: t('createComplaint.consumerBusiness') },
                    { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                    { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* 操作按钮 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item>
                <Space size="middle" wrap>
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                </Space>
                {/* 返回 */}
                <Button style={{ float: 'right' }} type="primary" key="search" shape="round" onClick={goBackReport}>
                  {t('common.goback')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div ref={lineChartRef} className="chart-style"></div>
      </Card>
    </>
  );
};
export default memo(ComplaintAnalysiReport);
