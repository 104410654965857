/*
 * @Description:退回弹窗
 * @Author: YuKi
 * @Date: 2025/02/12
 */

import React, { memo, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { saveBackOperate, getHandleForm } from '@/api/clientFeedback/complaintsList';
import TextArea from 'antd/lib/input/TextArea';

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
}

const BackModal: React.FC<ChildProps> = ({ isOpen, onClose, originData, id, extendParams, nodeData }) => {
  const { t } = useTranslation();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    if (nodeData) {
      getHandleForms(nodeData.data.id);
    }
  }, []);

  /**
   * @description: 获取去处理表单
   * @param {*} id
   * @return {*}
   * @LastEditors: YuKi
   */
  const getHandleForms = (id) => {
    getHandleForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        searchForm.setFieldsValue(res.data);
      }
    });
  };

  /**
   * @description: 退回表单保存
   * @return {*}
   * @LastEditors: YuKi
   */
  const saveData = () => {
    searchForm.validateFields().then((values) => {
      saveBackOperate({
        complaintsId: id,
        ...values,
        ...originData,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHanle();
        }
      });
    });
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: YuKi
   */
  const cancelHanle = () => {
    searchForm.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData.data.currentStatusShowName}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHanle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHanle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form layout="vertical" form={searchForm} name="complaintDetail" autoComplete="off">
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={<span className="form-item-label">{t('complaintList.reason')}</span>} name="reason">
                <TextArea disabled={nodeData} autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={1024} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(BackModal);
