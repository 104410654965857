/*
 * @Description:列表
 * @Author: YuKi
 * @Date: 2024/11/09
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Form, Input, Table, Row, Col, Space, Tooltip, Tag, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getEmphasisPage } from '@/api/clientFeedback/complaintsList';
import { useHistory, useLocation } from 'react-router-dom';
import { fileDownload } from '@/api/common';
import { blobDownloadFile } from '@/constants/common';

interface ICustomerComplaintListProps {
  type: number;
}
/* 列表字段类型定义 */
type GithubIssueItem = {
  id: number;
  ticketNumber: string /* 客诉单号 */;
  title: string /* 重点客诉标题 */;
  grade: string /* 重点客诉类型 */;
  createAccount: string /* 创建人 */;
  createServerDatetime: string /* 创建时间 */;
  delayFlag: number /* 是否延期 0否 */;
  aduit8D: number /* 8D评审 0待*/;
  preSolution: string /* 初步方案 有内容就是有 */;
  finalSolution: string /* 最终方案 有内容就是有 */;
  amsFileList: any /* 附件 */;
};

/* 列表查询参数 */
let searchParams = {
  pageSize: 10 /* 每页显示条数 */,
  pageIndex: 1 /* 当前页 */,
  keyword: undefined /* 关键词 */,
  title: undefined /* 客诉标题 */,
  ticketNumber: undefined /* 客诉单号 */,
  grade: undefined /* 重点客诉类型 */,
  impactArea: undefined, // 涉及国家/区域
  delayFlag: undefined /* 是否延期*/,
  sysSn: undefined /* 涉及SN */,
};

const CustomerComplaintList: React.FC<ICustomerComplaintListProps> = ({ type }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  /* 分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 列表源数据 */
  const [dataList, setDataList] = useState<GithubIssueItem[]>([]);
  /* table的loading */
  const [tableLoading, setTableLoading] = useState(false);
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();

  /* 列表字段 */
  const columns: ColumnsType<GithubIssueItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      key: 'serialNumber',
      width: language === 'zh' || language === 'ja' ? 90 : 180,
      align: 'center',
      fixed: 'left',
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 重点客诉编号 */
    {
      title: t('complaintList.key_customer_complaint_number'),
      key: 'ticketNumber',
      dataIndex: 'ticketNumber',
      width: language === 'sp' || language === 'it' || language === 'en' ? 260 : 230,
      align: 'center',
      fixed: 'left',
      render: (_, record) => [
        <Space key="id">
          {record.ticketNumber ? (
            <Tooltip title={record.ticketNumber}>
              <a
                key="complaintTitle"
                className="customer-comp-list-ellipsis"
                onClick={() => jumpToCustomerComplaintDetails(record)}
              >
                {record.ticketNumber}
              </a>
            </Tooltip>
          ) : null}
        </Space>,
      ],
    },
    /* 重点客诉标题 */
    {
      title: t('complaintList.key_customer_complaint_titles'),
      key: 'title',
      dataIndex: 'title',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (_, record) => {
        return (
          <a
            key="complaintTitle"
            className="customer-comp-list-ellipsis"
            onClick={() => jumpToCustomerComplaintDetails(record)}
          >
            {record.title}
          </a>
        );
      },
    },
    /* 重点客诉类型 */
    {
      title: t('complaintList.key_customer_complaint_type'),
      key: 'grade',
      dataIndex: 'grade',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (grade) => {
        switch (grade) {
          case 'S':
            return t('complaintList.grade_S');
          case 'A':
            return t('complaintList.grade_A');
          case 'B':
            return t('complaintList.grade_B');
          default:
            break;
        }
      },
    },
    /* 涉及SN */
    {
      title: t('complaintList.involved_sn'),
      key: 'sysSn',
      dataIndex: 'sysSn',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 涉及金额（/元） */
    {
      title: t('complaintList.amount_involved_yuan'),
      key: 'amount',
      dataIndex: 'amount',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 批次数量（/个） */
    {
      title: t('complaintList.batch_quantity_piece'),
      key: 'batchQuantity',
      dataIndex: 'batchQuantity',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 影响区域范围 */
    {
      title: t('complaintList.influence_area_range'),
      key: 'impactArea',
      dataIndex: 'impactArea',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 是否延期 */
    {
      title: t('complaintList.is_delay'),
      key: 'delayFlag',
      dataIndex: 'delayFlag',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (delayFlag) => {
        return delayFlag ? <Tag color="error"> {t('common.yes')}</Tag> : <Tag> {t('common.no')}</Tag>;
      },
    },
    /* 初步方案 */
    {
      title: t('complaintList.initial_plan'),
      key: 'preSolution',
      dataIndex: 'preSolution',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (preSolution) => {
        return preSolution ? (
          <Tag color="success"> {t('complaintList.have')}</Tag>
        ) : (
          <Tag> {t('complaintList.none')}</Tag>
        );
      },
    },
    /* 最终方案 */
    {
      title: t('complaintList.final_solution'),
      key: 'finalSolution',
      dataIndex: 'finalSolution',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (finalSolution) => {
        return finalSolution ? (
          <Tag color="success"> {t('complaintList.have')}</Tag>
        ) : (
          <Tag> {t('complaintList.none')}</Tag>
        );
      },
    },
    /* 8D评审 */
    {
      title: t('complaintList.eight_d_review'),
      key: 'aduit8D',
      dataIndex: 'aduit8D',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (aduit8D) => {
        return aduit8D ? (
          <Tag color="success"> {t('complaintList.reviewed')}</Tag>
        ) : (
          <Tag> {t('complaintList.awaitingReview')}</Tag>
        );
      },
    },
    /* 创建时间 */
    {
      title: t('common.createTime'),
      key: 'createServerDatetime',
      dataIndex: 'createServerDatetime',
      width: 180,
      align: 'center',
      ellipsis: true,
    },
    /* 创建人 */
    {
      title: t('common.creator'),
      key: 'createAccount',
      dataIndex: 'createAccount',
      width: 120,
      align: 'center',
      ellipsis: true,
    },
    /* 附件 */
    {
      title: t('complaintList.attachment'),
      key: 'option',
      align: 'center',
      width: 350,
      render: (_, record: any) => [
        <Space wrap key="attach">
          {record.amsFileList
            ? record.amsFileList.map((item, index) => {
                let num = Number(index) + 1;
                return (
                  <a key={index} onClick={() => downloadAttachment(item.fileFullPath)}>
                    {t('complaintList.attachment') + num}
                  </a>
                );
              })
            : null}
        </Space>,
      ],
    },
  ];

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载客诉附件
   * @param {*} fileName
   * @return {*}
   */
  const downloadAttachment = (fileName?: string) => {
    let params = {
      blobContainerName: 'FILES',
      fileBlobName: fileName,
    };
    fileDownload(params).then((res: any) => {
      blobDownloadFile(res, fileName);
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 点击客诉列表中的某一条数据，跳转至客诉的详情页
   * @return {*}
   */
  const jumpToCustomerComplaintDetails = (record: any) => {
    let paramsStr: any = '';
    Object.keys(searchParams).forEach((key) => {
      if (searchParams[key] !== undefined) {
        paramsStr += '&' + key + '=' + searchParams[key];
      }
    });
    if (history.location.pathname.indexOf('/innerStatic') < 0) {
      localStorage.setItem('pathname', history.location.pathname);
    }
    history.push({
      pathname: '/innerStatic/emphasisComplaintListDetails/index',
      search: 'key=' + record.id + paramsStr + '&type=' + type,
    });
  };

  /**
   * @Author: YuKi
   * @description: 获取列表数据
   * @return {*}
   */
  const getListInformation = () => {
    if (searchForm) {
      searchParams.keyword = searchForm.getFieldValue('keyword');
      searchParams.title = searchForm.getFieldValue('title');
      searchParams.ticketNumber = searchForm.getFieldValue('ticketNumber');
      searchParams.grade = searchForm.getFieldValue('grade');
      searchParams.impactArea = searchForm.getFieldValue('impactArea');
      searchParams.delayFlag = searchForm.getFieldValue('delayFlag');
      searchParams.sysSn = searchForm.getFieldValue('sysSn');
    }
    setTableLoading(true);
    getEmphasisPage({ ...searchParams, type })
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data && rep.data.rows) {
          setDataList([...rep.data.rows]);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
        } else {
          setDataList([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pagingInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pagingInit });
        setTableLoading(false);
      });
  };

  /**
   * @Author: YuKi
   * @description: 根据分页，初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    searchParams = {
      pageSize: 10 /* 每页显示条数 */,
      pageIndex: 1 /* 当前页 */,
      keyword: undefined /* 关键词 */,
      title: undefined /* 客诉标题 */,
      ticketNumber: undefined /* 客诉单号 */,
      grade: undefined /* 重点客诉类型 */,
      impactArea: undefined, // 国家列表
      delayFlag: undefined /* 是否延期 */,
      sysSn: undefined /* 涉及SN */,
    };
    if (location.state) {
      const { delayFlag, ...other } = location.state as any;
      searchParams = { ...searchParams, ...location.state };
      searchForm.setFieldsValue({ ...other, delayFlag: delayFlag ? Number(delayFlag) : undefined });
    }
    pageChange(pagingRecord.pageIndex, pagingRecord.pageSize);
  }, []);

  /**
   * @Author: YuKi
   * @description: 分页的回调函数
   * @param {Number} currentPage 当前页
   * @param {Number} currentSize 页的总条数
   * @return {*}
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getListInformation();
  }, []);

  /**
   * @Author: YuKi
   * @description:查询列表
   * @param {Object} form 表单
   * @return {*}
   */
  const search = useCallback(() => {
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    getListInformation();
  }, []);

  return (
    <>
      <div className="custom-contant">
        <Form
          form={searchForm}
          name="search_form"
          layout="vertical"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12} align={'bottom'}>
            {/* 关键词 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="keyword">
                <Input allowClear placeholder={`${t('complaintList.keyword')}`} />
              </Form.Item>
            </Col>
            {/* 客诉标题 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="title">
                <Input allowClear placeholder={`${t('complaintList.title')}`} />
              </Form.Item>
            </Col>
            {/* 客诉单号 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="ticketNumber">
                <Input allowClear placeholder={`${t('complaintList.number')}`} />
              </Form.Item>
            </Col>
            {/* 重点客诉类型 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="grade">
                <Select
                  allowClear
                  placeholder={t('complaintList.key_customer_complaint_type')}
                  options={[
                    {
                      value: 'S',
                      label: t('complaintList.grade_S'),
                    },
                    {
                      value: 'A',
                      label: t('complaintList.grade_A'),
                    },
                    {
                      value: 'B',
                      label: t('complaintList.grade_B'),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 国家 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="impactArea">
                <Input allowClear placeholder={`${t('complaintList.influence_area_range')}`} />
              </Form.Item>
            </Col>
            {/* 是否延期 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="delayFlag">
                <Select
                  allowClear
                  placeholder={t('complaintList.is_delay')}
                  options={[
                    {
                      value: 1,
                      label: t('common.yes'),
                    },
                    {
                      value: 0,
                      label: t('common.no'),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 涉及SN */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="sysSn">
                <Input allowClear placeholder={`${t('complaintList.involved_sn')}`} />
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item>
                <Space size="middle">
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          loading={tableLoading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 1160, y: 'calc(100vh - 350px)' }}
          className="custom-table-flex auto-table"
        />
      </div>
    </>
  );
};

export default memo(CustomerComplaintList);
