/*
 * @Description: 登录注册的头部
 * @Author: YuKi
 * @Date: 2023/03/01
 * @LastEditors: Wayne Hu
 * @LastEditTime: 2023/09/06 09:45:25
 */
import React, { memo } from 'react';
import '../../index.less';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '@/components/LanguageSelect';
import './index.less';
interface IProperties {
  flag: string;
}

const Header: React.FC<IProperties> = (props): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      <div className="logo-head">
        <ul className="more-info">
          <li key="AlphaESSOfficialWebsite">
            <a href="https://www.alphaess.com/" target="_blank">
              {t('register.AlphaESSOfficialWebsite')}
            </a>
          </li>
          <li className="separate">|</li>
          <li key="contactUs">
            <a href="https://www.alphaess.com/contact-us" target="_blank">
              {t('register.contactUs')}
            </a>
          </li>
        </ul>
        {/* <div id="scroll-info" className="scroll-info">
          <span className="scroll-not" id="scroll-item">
            {homeMsg}
          </span>
        </div> */}
        <div className="switch-language">
          <a className="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              style={{ verticalAlign: 'middle' }}
              // xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 1000 1000"
              // enable-background="new 0 0 1000 1000"
            >
              <metadata>Svg Vector Icons : http://www.onlinewebfonts.com/icon</metadata>
              <g>
                <path d="M500,10C229.4,10,10,229.4,10,500s219.4,490,490,490s490-219.4,490-490S770.6,10,500,10z M500,867.5C297,867.5,132.5,703,132.5,500S297,132.5,500,132.5c203,0,367.5,164.5,367.5,367.5S703,867.5,500,867.5z M441.1,375.3l1.3-20.2l26-10.1h55.7l16.8-15.1l10.4-29l28.5-15.1l37.7-26.5l0.9-6.6c-38.1-17.9-80.3-28.7-125.4-28.7c-79.9,0-152.3,31.5-204.8,82.3l-2.7,9.8l1.3,17.6l22.1,16.4l7.8,16.4l1.3,22.7l-1.3,26.5l19.5,1.3l20.8,7.6c0,0,16.8,5,22,10.1c5.2,5,33.7,22.7,37.6,29c3.9,6.3,23.3,6.3,23.3,6.3c15.6-12.6,0-30.3,0-30.3l-6.5-15.3l-5.2-15.1l2.6-17.6L441.1,375.3z M730.3,573.3l-16.8-20.2h-24.6l-22-7.6l-16.8-26.5l-27.3-21.4l-11.7-24h-28.5l-40.2-7.5l-11.7,3.8l-16.8,18.9l-14.3,10.1l-6.5,48c0,0,0,51.7,0,56.8c0,5,7.8,15.1,14.3,25.2c6.5,10.1,10.4,21.4,15.6,27.7c5.2,6.3,11.6,6.3,20.8,10.1c9.1,3.8,18.1,12.6,22,18.9c3.9,6.3,6.5,30.3,6.5,30.3l5.2,41.7v18c14.8-4.3,29-9.5,42.6-16l18.3-13.4l31.1-18.9l10.8-6.2c24.4-20.2,45.3-43.9,61.8-70.9l2.7-14.9v-25.4l-3.9-15.1L730.3,573.3z" />
              </g>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              style={{ verticalAlign: 'middle', marginLeft: '8px' }}
              // xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 1000 1000"
              // enable-background="new 0 0 1000 1000"
            >
              <metadata>Svg Vector Icons : http://www.onlinewebfonts.com/icon</metadata>
              <g>
                <path d="M990,253.3L494.8,746.7L10,253.3H990z" />
              </g>
            </svg>
          </a>
          <LanguageSelect languageWidth={'50px'} />
        </div>
      </div>
    </>
  );
};

export default memo(Header);
