/*
 * @Description: 客诉关闭率报表
 * @Author: Summer.Chen
 * @Date: 2024-03-13 15:46:24
 * @LastEditTime: 2024/12/10 13:14:05
 * @LastEditors: Please set LastEditors
 */
import React, { useState, useEffect, memo } from 'react'; //引入依赖
import { Input, Card, Button, Form, Select, Row, Col, DatePicker, Space, Table, InputNumber, Tooltip } from 'antd';
import { RootStateOrAny, useSelector } from 'react-redux';
import { colGroup } from './../../config';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getCustomerColumns } from './../columns';
import { countryType } from '@/types';
import { getCustomerComplaintReport, exportComplaintsCloseRate } from '@/api/reportCenter';
import { formatDateParams } from '@/utils/utils';
import { blobDownloadFile } from '@/constants/common';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const CustomerComplaintClosureRateReport: React.FC = () => {
  const countryList = useSelector((state: RootStateOrAny) => state.get('app').get('countryList'));
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [pageParams, setPageParams] = useState({
    pageIndex: 1,
    pageSize: 10,
    total: 1,
  });
  const [passedParams, setPassedParams] = useState({
    closeDateTime: '',
    complaintsTakeTime: 1,
    country: [],
    createDatetime: '',
    middleOfficeUser: '',
    time: Date.now(),
    businessLine: 10,
  });
  const [closeData, setCloseData] = useState({
    closedNum: 0,
    closedRate: 0,
    noClosedNum: 0,
  });
  const { t } = useTranslation();
  //查询数据
  const searchData = (): void => {
    const formData = form.getFieldsValue(true);
    setPageParams({
      ...pageParams,
      pageIndex: 1,
    });
    setPassedParams({
      createDatetime: formData?.archiveDate?.[0],
      closeDateTime: formData?.archiveDate?.[1],
      complaintsTakeTime: formData?.complaintsTakeTime ?? 0,
      country: formData?.country,
      middleOfficeUser: formData?.middleOfficeUser,
      time: Date.now(),
      businessLine: formData.businessLine,
    });
  };
  const [tableData, setTableData] = useState([]);
  // 控制表格loading
  const [tableLoading, setTableLoading] = useState(false);
  const [form] = Form.useForm();
  //导出excel
  const exportReport = () => {
    //开启按钮loading
    setDisabledBtn(true);
    const formData = form.getFieldsValue(true);
    const tempParams = {
      createDatetime: formData?.archiveDate?.[0],
      closeDateTime: formData?.archiveDate?.[1],
      complaintsTakeTime: formData?.complaintsTakeTime ?? 0,
      country: formData?.country,
      middleOfficeUser: formData?.middleOfficeUser,
    };
    exportComplaintsCloseRate(formatDateParams(tempParams, 'YYYY-MM-DD'))
      .then((res) => {
        //下载文件
        blobDownloadFile(res);
        setDisabledBtn(false);
      })
      .catch((error) => {
        console.error(error);
        setDisabledBtn(false);
      });
  };
  //监听数变化请求接口
  useEffect(() => {
    setTableLoading(true);
    getCustomerComplaintReport({ ...formatDateParams(passedParams, dateFormat), ...pageParams }).then((rep: any) => {
      if (rep && rep.data && rep.code == 200 && rep.data.complaintsCloseList) {
        setTableData(rep.data.complaintsCloseList.rows);
        setPageParams({
          ...pageParams,
          total: rep.data.complaintsCloseList.total || 1,
        });
        setCloseData({ ...rep.data });
      }
      setTableLoading(false);
    });
  }, [passedParams, pageParams.pageIndex]);
  return (
    <Card
      title={t('closureRateReport.closureRateReportTitle')}
      extra={
        <Button
          shape={'round'}
          type={'primary'}
          onClick={() => {
            history.go(-1);
          }}
        >
          {t('common.goback')}
        </Button>
      }
    >
      {/*搜索条件*/}
      <Form form={form}>
        <Row gutter={20}>
          {/*国家地区*/}
          <Col {...colGroup(24, 24, 5, 5, 5)}>
            <Form.Item name="country">
              <Select
                mode="multiple"
                allowClear
                maxTagCount="responsive"
                placeholder={t('common.pleaseSelectCountry_Region')}
                options={countryList.map((item: countryType) => {
                  let tempObj = { label: '', value: '' };
                  tempObj.label = item.areaEnglishName + '(' + item.areaFirstName + ')';
                  tempObj.value = item.areaEnglishName;
                  return tempObj;
                })}
              ></Select>
            </Form.Item>
          </Col>
          {/*中台处理人*/}
          <Col {...colGroup(24, 24, 5, 5, 5)}>
            <Form.Item name="middleOfficeUser">
              <Input placeholder={t('closureRateReport.selectMiddleOfficeHandler') as string} />
            </Form.Item>
          </Col>
          {/*客诉创建时间*/}
          <Col {...colGroup(24, 24, 10, 10, 10)}>
            <Form.Item name="archiveDate">
              <RangePicker
                className="width-100-percent"
                placeholder={[t('complaintList.complaintCreateBegin'), t('complaintList.complaintCreateEnd')]}
                format={dateFormat}
              />
            </Form.Item>
          </Col>
          {/*关闭率维度*/}
          <Col {...colGroup(24, 24, 2, 2, 2)}>
            <Form.Item name="complaintsTakeTime" initialValue={1}>
              <InputNumber min={0} precision={0} className="width-100-percent" controls={false} />
            </Form.Item>
          </Col>
          {/*关闭率维度说明*/}
          <Col {...colGroup(24, 24, 2, 2, 2)}>
            <Form.Item>
              <Tooltip placement="topLeft" title={<span>{t('closureRateReport.closeRateStatisticalTips')}</span>}>
                <span>/{t('common.day')}</span>
                <InfoCircleOutlined className="ml8" />
              </Tooltip>
            </Form.Item>
          </Col>
          {/* 产品线 */}
          <Col {...colGroup(24, 24, 5, 5, 5)}>
            <Form.Item name="businessLine" initialValue={10}>
              <Select
                placeholder={`${t('archivalMaintenance.productLine')}`}
                options={[
                  { value: 10, label: t('archivalMaintenance.householdUse') },
                  { value: 20, label: t('createComplaint.consumerBusiness') },
                  { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                  { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        {/*按钮*/}
        <Space>
          <Button type="primary" shape={'round'} onClick={searchData}>
            {' '}
            {t('common.searchBtn')}{' '}
          </Button>
          <Button loading={disabledBtn} type="primary" shape={'round'} onClick={exportReport}>
            {' '}
            {t('common.export')}{' '}
          </Button>
        </Space>
      </Form>
      {/*数据展示*/}
      <Table
        dataSource={tableData}
        columns={getCustomerColumns(pageParams)}
        loading={tableLoading}
        scroll={{ y: window.innerHeight - 520 }}
        pagination={{
          ...pageParams,
          showQuickJumper: true,
          showSizeChanger: false,
          current: pageParams.pageIndex,
          onChange: (page: number) => {
            setPageParams({
              ...pageParams,
              pageIndex: page,
            });
          },
        }}
      />
      {/*关闭数据*/}
      <p>
        <strong>
          {t('closureRateReport.numberClosuresPerDay', { days: passedParams.complaintsTakeTime ?? 0 })}：
          {closeData.closedNum} &nbsp;&nbsp;&nbsp;
          {t('closureRateReport.numberUnclosedItemsOneDay', { days: passedParams.complaintsTakeTime ?? 0 })} ：
          {closeData.noClosedNum} &nbsp;&nbsp;&nbsp;
          {t('closureRateReport.oneDayClosingRate', { days: passedParams.complaintsTakeTime ?? 0 })}：
          {(Number(closeData.closedRate) * 100).toFixed(2) + '%'}
        </strong>
        <br />
        <span className="red-star">{t('closureRateReport.closureRateReportTips')}</span>
      </p>
    </Card>
  );
};
export default memo(CustomerComplaintClosureRateReport);
