/*
 * @Description:权限菜单->菜单管理
 * @Author: YuKi
 * @Date: 2022/11/03
 * @LastEditors: April
 * @LastEditTime: 2023-08-02 19:45:24
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Form,
  TreeSelect,
  Input,
  Table,
  Row,
  Col,
  Space,
  message,
  Card,
  Select,
  Tooltip,
  Tree,
  InputNumber,
} from 'antd';
import {
  getMenuTree,
  getMenuAndSubMenus,
  deleteMenuById,
  addMenu,
  modifyMenuById,
  getMenuDetailsById,
} from '@/api/menuPermissions/menuManage';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { inputValueLimit } from '@/constants/inputValueLimit';
import type { DataNode } from 'antd/es/tree';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.less';
import { useSelector, RootStateOrAny } from 'react-redux';
const { Search } = Input;
const { TextArea } = Input;

let treeList: { key: React.Key; nameObj: string }[] = [];

/**
 * @Author: YuKi
 * @description: 左侧菜单->根据查询条件的key，获取其父节点的key,然后将其展开
 * @param {React} key
 * @param {DataNode} tree
 * @return {*}
 */
const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

/**
 * @Author: YuKi
 * @description: 左侧菜单->获取树形结构原始数据
 * @param {DataNode} data
 * @return {*}
 */
const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node: any = data[i];
    const { key } = node;
    treeList.push({ key, nameObj: node.nameObj as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

/* 列表字段类型定义 */
type GithubIssueItem = {
  key: string;
  alwaysShow: number /* 是否展示子级菜单（0：否；1：是） */;
  componentUrl: string /* 指向前端路径 */;
  isDisplay: number /* 是否显示（0：不显示；1：显示；） */;
  menuName: string /* 菜单名称 */;
  menuTitle: string /* 	菜单标题（中文菜单名称） */;
  menuType: number /* 菜单类型(0:目录；1：页面；2：外部链接) */;
  menuUrl: string /* 路由 */;
  remarks: string /* 备注 */;
};

/* 列表查询参数 */
let searchParams: any = {
  key: '' /* 	菜单名称 */,
  menuName: '' /* 父级key */,
  menuUrl: '' /* 路由 */,
};
let editId = '';
const MenuManage: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  /* 左侧菜单->树形展开节点 */
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  /* 左侧菜单->是否自动展开父节点 */
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  /* 左侧菜单->源数据 */
  const [treeDataList, setTreeDataList] = useState<any>([]);
  /* 左侧菜单->搜索内容 */
  const [searchValue, setSearchValue] = useState('');
  /* 编辑弹窗的ref */
  const [aEForm] = Form.useForm();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 新增编辑弹窗-> 区分新增编辑标志*/
  const [aEFlag, setAEFlag] = useState<string>('add');
  /* 列表源数据 */
  const [dataList, setDataList] = useState<GithubIssueItem[]>([]);
  /* 菜单列表源数据 */
  const [defaultData, setDefaultData] = useState<DataNode[]>([]);
  /* table的loading */
  const [tableLoading, setTableLoading] = useState(false);
  /* 新增编辑弹窗打开关闭flag */
  const [isAEOpen, setIsAEOpen] = useState<any>(false);
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let [isUseEffectFirst, setIsUseEffectFirst] = useState(true);

  /**
   * @Author: YuKi
   * @description: 根据语言切换动态切换样式
   * @return {*}
   */
  useEffect(() => {
    if (isUseEffectFirst) {
      setIsUseEffectFirst(false);
      return;
    }
    let tempError = searchForm.getFieldsError();
    for (let index = 0; index < tempError.length; index++) {
      const element = tempError[index];
      if (element.errors && element.errors.length > 0) {
        searchForm.validateFields(element.name);
      }
    }
  }, [language]);

  /**
   * @Author: YuKi
   * @description: 初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    searchParams = {
      key: '' /* 	菜单名称 */,
      menuName: '' /* 父级key */,
      menuUrl: '' /* 路由 */,
    };
    getUserMenus(); /* 获取左侧菜单数据 */
    getListInformation(); /* 获取右侧列表 */
  }, []);

  /**
   * @Author: YuKi
   * @description: 左侧菜单->获取左侧树形数据
   * @return {*}
   */
  const getUserMenus = () => {
    getMenuTree()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setDefaultData([...rep.data]);
          generateList([...rep.data]);
          setTreeDataList([...rep.data]);
        } else {
          setDefaultData([]);
          setTreeDataList([]);
        }
      })
      .catch((err) => {
        setDefaultData([]);
        setTreeDataList([]);
      });
  };

  /**
   * @Author: YuKi
   * @description: 左侧菜单->展开/收起节点时触发
   * @param {React} newExpandedKeys
   * @return {*}
   */
  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  /**
   * @Author: YuKi
   * @description: 左侧菜单->搜索时，找到与搜索内容相对应的key,并展开父节点
   * @param {React} e
   * @return {*}
   */
  const onTreeSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      const newExpandedKeys = treeList
        .map((item: any) => {
          if (item.nameObj.indexOf(value) > -1) {
            return getParentKey(item.key, defaultData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      setExpandedKeys(newExpandedKeys as React.Key[]);
      setSearchValue(value);
      setAutoExpandParent(true);
    } else {
      setExpandedKeys([]);
      setAutoExpandParent(true);
    }
  };

  /**
   * @Author: YuKi
   * @description: 左侧菜单->搜索时，找到对应的搜索内容，并高亮提示
   * @return {*}
   */
  useEffect(() => {
    const loop = (data: DataNode[]): DataNode[] =>
      data.map((item: any) => {
        const strTitle = item.nameObj as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const nameObj =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return { nameObj, key: item.key, children: loop(item.children) };
        }

        return {
          nameObj,
          key: item.key,
        };
      });
    setTreeDataList(loop(defaultData));
  }, [searchValue]);

  /**
   * @Author: YuKi
   * @description: 左侧菜单->点击节点时触发，并且根据节点的key查询右侧内容
   * @param {any} key
   * @return {*}
   */
  const onLeftTreeSelect = (key: any) => {
    searchParams.key = key[0];
    searchParams.menuName = undefined;
    searchParams.menuUrl = undefined;
    searchForm.resetFields();
    getListInformation();
  };

  /* 列表字段 */
  const columns: ColumnsType<GithubIssueItem> = [
    /* 菜单标题 */
    {
      title: t('menuManage.menuTitle'),
      key: 'menuTitle',
      dataIndex: 'menuTitle',
      width: 160,
      ellipsis: true,
      align: 'center',
      fixed: 'left',
    },
    /* 序号 */
    {
      title: t('common.serialNumber'),
      key: 'serialNumber',
      width: 160,
      align: 'center',
      render: (text, record, index) => `${index + 1}`,
    },
    /* 菜单名称 */
    {
      title: t('menuManage.menuName'),
      key: 'menuName',
      dataIndex: 'menuName',
      width: 160,
      ellipsis: true,
      align: 'center',
    },
    /* 类型 */
    {
      title: t('menuManage.type'),
      key: 'menuType',
      dataIndex: 'menuType',
      width: 160,
      ellipsis: true,
      align: 'center',
      render: (menuType) => {
        if (menuType === 0) {
          /* 目录 */
          return (
            <Tooltip key="directory">
              <span>{t('menuManage.directory')}</span>
            </Tooltip>
          );
        } else if (menuType === 1) {
          /* 页面 */
          return (
            <Tooltip key="page">
              <span>{t('menuManage.page')}</span>
            </Tooltip>
          );
        } else if (menuType === 2) {
          /* 外部链接 */
          return (
            <Tooltip key="externalLink">
              <span>{t('menuManage.externalLink')}</span>
            </Tooltip>
          );
        }
      },
    },
    /* 菜单路由path */
    {
      title: t('menuManage.menuRoutePath'),
      key: 'menuUrl',
      dataIndex: 'menuUrl',
      width: 220,
      align: 'center',
      ellipsis: true,
    },
    /* 组件路径 */
    {
      title: t('menuManage.componentPath'),
      key: 'componentUrl',
      dataIndex: 'componentUrl',
      width: 180,
      align: 'center',
      ellipsis: true,
    },
    /* 是否显示 */
    {
      title: t('menuManage.whetherToShow'),
      key: 'isDisplay',
      dataIndex: 'isDisplay',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (isDisplay) => {
        if (isDisplay === 0) {
          /* 隐藏 */
          return (
            <Tooltip key="hide">
              <span>{t('menuManage.hide')}</span>
            </Tooltip>
          );
        } else if (isDisplay === 1) {
          /* 显示 */
          return (
            <Tooltip key="show">
              <span>{t('menuManage.show')}</span>
            </Tooltip>
          );
        }
      },
    },
    /* 备注 */
    {
      title: t('common.remarks'),
      key: 'remarks',
      dataIndex: 'remarks',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      key: 'option',
      align: 'center',
      width: 160,
      fixed: 'right',
      render: (_, record) => [
        <Space size="middle" key="space">
          {/* 编辑 */}
          <a key="edit" onClick={() => aEButton('edit', record)}>
            {t('common.editBtn')}
          </a>
          {/* 删除 */}
          <a key="delete" className="delete-text" onClick={() => deleteClick(record)}>
            {t('common.deleteBtn')}
          </a>
        </Space>,
      ],
    },
  ];

  /**
   * @Author: YuKi
   * @description: 获取列表数据
   * @return {*}
   */
  const getListInformation = () => {
    setTableLoading(true);
    getMenuAndSubMenus(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setDataList([...rep.data]);
        } else {
          setDataList([]);
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        setTableLoading(false);
      });
  };

  /**
   * @Author: YuKi
   * @description:查询列表
   * @param {Object} form 表单
   * @return {*}
   */
  const search = useCallback((values: any) => {
    searchParams.menuName = values.menuName && values.menuName.trim() ? values.menuName.trim() : undefined;
    searchParams.menuUrl = values.menuUrl && values.menuUrl.trim() ? values.menuUrl.trim() : undefined;
    getListInformation();
  }, []);

  /**
   * @Author: YuKi
   * @description: 新增/编辑弹窗->打开
   * @param {String} flag 'add':新增，'edit':编辑
   * @param {*} record 编辑的行数据
   * @return {*}
   */
  const aEButton = (flag, record?) => {
    setAEFlag(flag);
    if (flag === 'edit') {
      getMenuDetailsById({ key: record.key }).then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          aEForm.setFieldsValue({
            alwaysShow: rep.data.alwaysShow || 0,
            componentUrl: rep.data.componentUrl || '',
            icon: rep.data.icon || '',
            isDisplay: rep.data.isDisplay || 0,
            isDisplayNew: rep.data.isDisplayNew || 0,
            menuName: rep.data.menuName || '',
            menuTitle: rep.data.menuTitle || '',
            menuType: rep.data.menuType || 0,
            menuUrl: rep.data.menuUrl || '',
            menuUrlName: rep.data.menuUrlName || '',
            parentKey: rep.data.parentKey || '',
            remarks: rep.data.remarks || '',
            sort: rep.data.sort || 0,
            componentUrlNew: rep.data.componentUrlNew || '',
            menuUrlNew: rep.data.menuUrlNew || '',
          });
        }
      });
      editId = record.key;
    }
    setIsAEOpen(true);
  };

  /**
   * @Author: YuKi
   * @description: 编辑弹窗确认
   * @return {*}
   */
  const handleAEOk = () => {
    aEForm
      .validateFields()
      .then((values) => {
        if (aEFlag === 'add') {
          addMenu(values)
            .then((rep: any) => {
              if (rep && rep.code === 200) {
                setIsAEOpen(false);
                aEForm.resetFields();
                getListInformation();
                getUserMenus();
                message.success(t('common.operateSuccess')); /* 操作成功 */
              }
            })
            .catch((err) => {});
        }
        if (aEFlag === 'edit') {
          modifyMenuById({ ...values, key: editId })
            .then((rep: any) => {
              if (rep && rep.code === 200) {
                setIsAEOpen(false);
                aEForm.resetFields();
                getListInformation();
                getUserMenus();
                editId = '';
                message.success(t('common.operateSuccess')); /* 操作成功 */
              }
            })
            .catch((err) => {});
        }
      })
      .catch((errorInfo) => {});
  };

  /**
   * @Author: YuKi
   * @description: 新增编辑弹窗取消
   * @return {*}
   */
  const handleAECancel = () => {
    aEForm.resetFields();
    setIsAEOpen(false);
  };

  /**
   * @Author: YuKi
   * @description: 删除
   * @param {string} record
   * @return {*}
   */
  const deleteClick = (record) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteData'),
      onOk() {
        let params = {
          key: record.key,
        };
        deleteMenuById(params).then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('common.deleteSuccess')); /* 操作成功 */
            getListInformation();
            getUserMenus();
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  return (
    <div className="menuManage">
      <Row>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <Card title={t('menuManage.menuList')} headStyle={{ textAlign: 'center' }}>
            <Search
              className="search-tree"
              style={{ marginBottom: 8 }}
              placeholder={`${t('menuManage.pleaseEnterTheMenuName')}`}
              onChange={onTreeSearchChange}
            />
            <Tree
              rootClassName="menu-manage-tree"
              fieldNames={{ title: 'nameObj', key: 'key', children: 'children' }}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              treeData={treeDataList}
              onSelect={onLeftTreeSelect}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={19} xl={19}>
          <Card>
            <Form
              form={searchForm}
              name="search_form"
              autoComplete="off"
              style={{ width: '100%' }}
              onFinish={search}
              className="custom-search in-line-style"
              layout="vertical"
            >
              <Row gutter={12} align="bottom">
                {/* 菜单名称/标题 */}
                <Col xs={24} sm={12} md={10} lg={10} xl={5}>
                  <Form.Item label={t('menuManage.nameOrTitle')} name="menuName">
                    <Input allowClear />
                  </Form.Item>
                </Col>
                {/* 菜单路由name */}
                <Col xs={24} sm={12} md={10} lg={10} xl={5}>
                  <Form.Item label={t('menuManage.menuRoutePath')} name="menuUrl">
                    <Input allowClear />
                  </Form.Item>
                </Col>
                {/* 操作按钮 */}
                <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                  <Form.Item>
                    <Space size="small">
                      {/* 查询 */}
                      <Button type="primary" key="search" shape="round" htmlType="submit">
                        {t('common.searchBtn')}
                      </Button>
                      {/* 重置查询 */}
                      <Button
                        type="primary"
                        key="resetSearchBtn"
                        shape="round"
                        onClick={() => {
                          searchParams.menuName = undefined;
                          searchParams.menuUrl = undefined;
                          searchForm.resetFields();
                          searchParams.key = '';
                          getListInformation();
                        }}
                      >
                        {t('common.resetSearchBtn')}
                      </Button>
                      {/* 新增 */}
                      <Button type="primary" key="add" shape="round" onClick={() => aEButton('add')}>
                        {t('common.addBtn')}
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Table
              columns={columns}
              loading={tableLoading}
              rowKey="key"
              pagination={false}
              dataSource={dataList}
              scroll={{ x: 600, y: window.innerHeight - 380 }}
            />
          </Card>
        </Col>
      </Row>

      {/* 新增/编辑弹窗 */}
      <Modal
        maskClosable={false}
        title={aEFlag === 'add' ? t('common.addBtn') : t('common.editBtn')}
        open={isAEOpen}
        onCancel={handleAECancel}
        width="60%"
        footer={[
          /* 取消 */
          <Button key="back" shape="round" onClick={handleAECancel}>
            {t('common.cancelBtn')}
          </Button>,
          /* 提交 */
          <Button key="submit" shape="round" type="primary" onClick={handleAEOk}>
            {t('common.submitBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form
          name="aEForm"
          autoComplete="off"
          layout="vertical"
          form={aEForm}
          initialValues={{ isDisplay: 1, sort: 0, alwaysShow: 1 }}
        >
          <Row gutter={12}>
            {/* 菜单目录 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="menuType"
                label={t('menuManage.menuList')}
                validateTrigger="onChange"
                rules={[{ required: true, message: `${t('menuManage.menuTypeRequired')}` }]}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  allowClear
                  options={[
                    { value: 0, label: t('menuManage.directory') },
                    { value: 1, label: t('menuManage.page') },
                    { value: 2, label: t('menuManage.externalLink') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 父级菜单 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label={t('menuManage.parentmMenu')} name="parentKey">
                <TreeSelect
                  showSearch
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder={`${t('common.pleaseChoose')}`}
                  allowClear
                  filterTreeNode={(inputValue: string, treeNode: any) => {
                    return treeNode.nameObj.indexOf(inputValue) > -1;
                  }}
                  fieldNames={{ label: 'nameObj', value: 'key', children: 'children' }}
                  treeData={defaultData}
                />
              </Form.Item>
            </Col>
            {/* 菜单名称 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.menuName')}
                name="menuName"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('menuManage.menuNameIsRequired')}`, whitespace: true },
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 菜单标题 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.menuTitle')}
                name="menuTitle"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('menuManage.titleIsRequired')}`, whitespace: true },
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 菜单路由path新版 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.menuRoutePathNew')}
                name="menuUrlNew"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('menuManage.routePathIsRequired')}`, whitespace: true },
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 菜单路由path旧版 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.menuRoutePath')}
                name="menuUrl"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('menuManage.routePathIsRequired')}`, whitespace: true },
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 菜单路由name */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.menuRouteName')}
                name="menuUrlName"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('menuManage.routeNameIsRequired')}`, whitespace: true },
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 组件路径新版 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label={t('menuManage.componentPathNew')} name="componentUrlNew" validateTrigger="onBlur">
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 组件路径旧版 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.componentPath')}
                name="componentUrl"
                validateTrigger="onBlur"
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>

            {/* 菜单图标 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.menuIcon')}
                name="icon"
                validateTrigger="onBlur"
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 是否显示旧版 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.whetherToShow')}
                name="isDisplay"
                validateTrigger="onChange"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    { value: 1, label: t('menuManage.show') },
                    { value: 0, label: t('menuManage.hide') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 是否显示新版 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label={t('menuManage.WheToDisplayNewVersion')} name="isDisplayNew" validateTrigger="onChange">
                <Select
                  options={[
                    { value: 1, label: t('menuManage.show') },
                    { value: 0, label: t('menuManage.hide') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 排序 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.sort')}
                name="sort"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: `${t('menuManage.sortRequired')}`,
                  },
                  {
                    validator: (rule, value) => {
                      if ((value || value === 0) && value < 0) {
                        return Promise.reject(t('menuManage.sortMin'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} step={1} precision={0} />
              </Form.Item>
            </Col>
            {/* 是否展示子级菜单 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('menuManage.whetherToShowSubmenu')}
                name="alwaysShow"
                validateTrigger="onChange"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    { value: 1, label: t('common.yes') },
                    { value: 0, label: t('common.no') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 备注 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={t('common.remarks')}
                name="remarks"
                validateTrigger="onBlur"
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > inputValueLimit.maxTextValueLeng) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxTextValueLeng }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(MenuManage);
