/*
 * @Description: 归档维护页面-第一层
 * @Author: Wayne Hu
 * @Date: 2024/12/06 13:56:03
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getComponentPage,
  addComponents,
  deleteComponent,
  editComponent,
  refreshData,
} from '@/api/clientFeedback/archivalMaintenance';

/* 列表字段类型定义 */
type TableColumnsItem = {
  /** 行ID */
  id: string;
  /** 部件名称 */
  component: string;
  /** 创建人 */
  createAccount: string;
  /** 创建时间 */
  createDatetime: string;
  /** 产品线 */
  businessLine: number;
};

/* 列表查询参数 */
let searchParams = {
  /** 每页显示的条数 */
  pageSize: 10,
  /** 当前页 */
  pageIndex: 1,
  /** 部件名称 */
  component: '',
  /** 所属产品线 */
  businessLine: 10,
};

const ArchivalMaintenance: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const history = useHistory();
  const location = useLocation();
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  const [dataList, setDataList] = useState<TableColumnsItem[]>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [addOrEdit, setAddOrEdit] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectForm] = Form.useForm();
  const [searchForm] = Form.useForm();

  /* 列表字段 */
  const columns: ColumnsType<TableColumnsItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      width: 140,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 部件名称 */
    {
      title: t('archivalMaintenance.component'),
      dataIndex: 'component',
      width: 180,
      align: 'center',
      ellipsis: true,
    },

    /* 创建人 */
    {
      title: t('common.creator'),
      dataIndex: 'createAccount',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 创建时间 */
    {
      title: t('common.createTime'),
      dataIndex: 'createDatetime',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      align: 'center',
      width: 350,
      render: (text: any, record: any, index: any) => {
        return (
          <Space size="middle" wrap className="sn-management-space">
            {/* 编辑 */}
            <a onClick={() => displayAddOrEditDialog(2, record)}>{t('common.editBtn')}</a>
            {/* 模块维护 */}
            <a
              onClick={() => {
                jumpToModuleMaintenance(record);
              }}
            >
              {t('archivalMaintenance.moudleMaintenance')}
            </a>
            {/* 刷新 */}
            <a
              onClick={() => {
                refresh(record.id);
              }}
            >
              {t('complaintList.refresh')}
            </a>
            {/* 删除 */}
            <a
              className="delete-text"
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              {t('common.deleteBtn')}
            </a>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    // 如果是从别的页面跳转过来,并且传递了参数,就先解析传递的参数,然后查询分页列表
    if (location.state && location.state.businessLine) {
      searchForm.setFieldsValue(location.state);
      searchParams = { ...searchParams, pageIndex: location.state.pageIndex || 1, ...searchForm.getFieldsValue() };
    }
    getComponentPages();
    return () => {
      searchParams = {
        pageSize: 10,
        pageIndex: 1,
        component: '',
        businessLine: 10,
      };
    };
  }, []);

  /**
   * @Author: Wayne Hu
   * @description: 跳转至模块维护页面
   * @param {*} record
   */
  const jumpToModuleMaintenance = (record) => {
    if (history.location.pathname.indexOf('/innerStatic') < 0) {
      localStorage.setItem('pathname', history.location.pathname);
    }
    history.push({
      pathname: '/innerStatic/MoudleMaintenance/index',
      state: {
        moduleId: record.id,
        component: searchForm.getFieldValue('component') || '',
        businessLine: searchForm.getFieldValue('businessLine'),
        pageIndex: pagingRecord.pageIndex,
      },
    });
  };

  /**
   * @Author: Wayne Hu
   * @description: 获取列表数据
   */
  const getComponentPages = () => {
    setTableLoading(true);
    getComponentPage(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data && rep.data.rows) {
          setDataList([...rep.data.rows]);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
        } else {
          setDataList([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pagingInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pagingInit });
        setTableLoading(false);
      });
  };

  /**
   * @Author: Wayne Hu
   * @description: 提交新增或编辑的表单
   */
  const addOrEditComponents = () => {
    if (addOrEdit === 1) {
      projectForm.validateFields().then((values) => {
        addComponents(values).then((res: any) => {
          if (res.code == 200) {
            message.success(t('complaintList.operateSuccess'));
            getComponentPages();
            handleCancel();
          }
        });
      });
    } else {
      projectForm.validateFields().then((values) => {
        editComponent({ ...values }).then((res: any) => {
          if (res.code == 200) {
            message.success(t('complaintList.operateSuccess'));
            getComponentPages();
            handleCancel();
          }
        });
      });
    }
  };

  /**
   * @Author: Wayne Hu
   * @description: 分页回调
   * @param {*} useCallback
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams = { ...searchParams, ...searchForm.getFieldsValue() };
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getComponentPages();
  }, []);

  /**
   * @Author: Wayne Hu
   * @description: 按钮查询分页列表
   */
  const search = () => {
    searchParams = { ...searchParams, ...searchForm.getFieldsValue() };
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    getComponentPages();
  };

  /**
   * @Author: Wayne Hu
   * @description: 删除指定数据
   * @param {*} id 行id
   */
  const handleDelete = (id) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteData'),
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      onOk() {
        deleteComponent({ id: id }).then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('common.deleteSuccess'));
            getComponentPages();
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  /**
   * @Author: Wayne Hu
   * @description: 打开新增或编辑弹窗
   * @param {number} type 弹窗类型 1-新增 2-编辑
   * @param {any} record
   */
  const displayAddOrEditDialog = (type: number, record?: any) => {
    setIsModalOpen(true);
    if (type === 1) {
      setAddOrEdit(1);
      projectForm && projectForm.resetFields();
    } else {
      setAddOrEdit(2);
      projectForm &&
        projectForm.setFieldsValue({
          id: record.id,
          component: record.component,
          businessLine: record.businessLine,
        });
    }
  };

  /**
   * @Author: Wayne Hu
   * @description: 关闭弹窗
   */
  const handleCancel = () => {
    if (projectForm) {
      projectForm.resetFields();
    }
    setIsModalOpen(false);
  };

  /**
   * @Author: Wayne Hu
   * @description: 同步历史数据(列表外部)
   */
  const refreshAll = () => {
    refreshData({}).then((rep: any) => {
      if (rep.code == 200) {
        message.success(t('complaintList.operateSuccess'));
      }
    });
  };

  /**
   * @Author: Wayne Hu
   * @description: 同步历史数据(列表内部)
   * @param {*} id
   */
  const refresh = (id) => {
    refreshData({ componentId: id }).then((rep: any) => {
      if (rep.code == 200) {
        message.success(t('complaintList.operateSuccess'));
      }
    });
  };

  return (
    <>
      <div className="custom-contant project-management">
        <Form
          form={searchForm}
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
          layout="vertical"
        >
          <Row gutter={12} align={'bottom'}>
            {/* 部件名称 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item label={t('archivalMaintenance.component')} name="component">
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 产品线 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item label={t('archivalMaintenance.productLine')} name="businessLine" initialValue={10}>
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  options={[
                    { value: 10, label: t('archivalMaintenance.householdUse') },
                    { value: 20, label: t('createComplaint.consumerBusiness') },
                    { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                    { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item>
                <Space size="middle">
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {/* 新增 */}
                  <Button type="primary" key="add" shape="round" onClick={() => displayAddOrEditDialog(1)}>
                    {t('common.addBtn')}
                  </Button>
                  {/* 同步 */}
                  <Button type="primary" key="sync" shape="round" onClick={() => refreshAll()}>
                    {t('complaintList.refresh')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Table
          columns={columns}
          loading={tableLoading}
          rowKey={(record) => record.id}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 600, y: window.innerHeight - 350 }}
          className="custom-table-flex"
        />
      </div>

      {/* 新增/编辑弹窗 */}
      <Modal
        maskClosable={false}
        title={addOrEdit === 1 ? t('common.addBtn') : t('common.editBtn')}
        open={isModalOpen}
        onCancel={handleCancel}
        width="40%"
        footer={[
          /* 取消 */
          <Button shape="round" onClick={handleCancel}>
            {t('common.cancelBtn')}
          </Button>,
          /* 保存 */
          <Button shape="round" type="primary" onClick={addOrEditComponents}>
            {t('common.saveBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form autoComplete="off" layout="vertical" form={projectForm}>
          <Row gutter={12}>
            {/* 产品线 */}
            <Col span={24}>
              <Form.Item
                label={t('archivalMaintenance.productLine')}
                name="businessLine"
                rules={[
                  {
                    required: true,
                    message: `${t('archivalMaintenance.productLine')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  allowClear
                  options={[
                    { value: 10, label: t('archivalMaintenance.householdUse') },
                    { value: 20, label: t('createComplaint.consumerBusiness') },
                    { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                    { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 部件名称 */}
            <Col span={24}>
              <Form.Item
                label={t('archivalMaintenance.component')}
                name="component"
                rules={[
                  {
                    required: true,
                    message: `${t('archivalMaintenance.component')}${t('archivalMaintenance.parmasRequired')}`,
                    whitespace: true,
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.trim() && value.trim().length > 64) {
                        return Promise.reject(t('common.maxlengLimit', { maxLength: 64 }));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {addOrEdit === 2 ? (
              <Col span={24}>
                <Form.Item label={'ID'} hidden name="id">
                  <Input />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ArchivalMaintenance);
