/* 绑定用户弹窗 */
import { Input, Modal, Table, message, Select } from 'antd';
import React from 'react';
import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getGroupListByType, getUserBindGroup, bindRole, deleteBindUserGroup } from '@/api/authorityManagement';

type TableItem = {
  id: string;
  groupType: number;
  groupName: string;
};

interface IBindUserProps {
  open: boolean;
  userType: string;
  id: string;
  /** 关闭回调 */
  onCancel: () => void;
}

const BindUserModal = (props: IBindUserProps) => {
  const { open, id, onCancel, userType } = props;
  const { t } = useTranslation();
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));

  const columns: ColumnsType<TableItem> = [
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      key: 'index',
      width: language === 'zh' || language === 'de' || language === 'ja' ? 90 : 180,
      render: (text: any, record: any, index: any) => {
        return index + 1;
      },
    },
    {
      title: t('emailDeliveringRules.userGroupName'),
      dataIndex: 'groupName',
      width: 220,
    },
  ];
  // table的loading
  const [tableLoading, setTableLoading] = useState(false);
  const [dataList, setDataList] = useState<TableItem[]>();

  // 分页
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  /* 查询表单 */
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  /** 保存后台返回的已选中的用户组Keys */
  const [tempSelectedKeys, setTempSelectedKeys] = useState<string[]>([])

  /**
   * @Author: xuanjie
   * @description: 根据分页，初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    if (open) {
      userGroupList();
    }
  }, [open]);

  /**
   * @description: 获取角色列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const userGroupList = () => {
    let groupType: number = 0;
    if (userType === 'dasw') {
      groupType = 1
    }
    if (userType === 'dev') {
      groupType = 0
    }
    getGroupListByType(groupType)
      .then((rep: any) => {
        if (rep.code == 200) {
          setDataList(rep.data);
          getUserBindGroups();
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
        } else {
          let paging = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...paging });
        }
      })
      .catch((err) => {
        let paging = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...paging });
      });
  };

  /**
   * @description: 已选择的角色
   * @param {*} val
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getRolesChoosed = (val) => {
    setSelectedRowKeys(val);
  };

  /**
   * @Author: Wayne Hu
   * @description: 获取已绑定的用户组
   * @return {*}
   */
  const getUserBindGroups = () => {
    getUserBindGroup(id).then((rep: any) => {
      if (rep.code == 200 && rep.data.length > 0) {
        const keys: string[] = [];
        rep.data.forEach((item) => {
          if (item.groupId) {
            keys.push(item.groupId);
          }
        });
        setSelectedRowKeys(keys);
        setTempSelectedKeys(keys);
      } else {
        setSelectedRowKeys([]);
        setTempSelectedKeys([]);
      }
    });
  };

  const handleSave = () => {
    if (selectedRowKeys.length === 0) {
      deleteBindUserGroup({ userId: id, groupId: tempSelectedKeys }).then((res) => {
        if (res && res.code === 200) {
          message.success(t('common.operateSuccess'));
          setSelectedRowKeys([])
          setTempSelectedKeys([])
          onCancel()
        }
      })
    } else {
      bindRole({
        userId: id,
        groupId: selectedRowKeys,
      }).then((rep: any) => {
        if (rep && rep.code == 200) {
          message.success(t('common.operateSuccess'));
          setSelectedRowKeys([]);
          onCancel();
        }
      });
    }

  };

  return (
    <>
      <Modal
        title={t('complaintList.bindUserGroup')}
        open={open}
        onCancel={() => onCancel()}
        width={800}
        onOk={() => handleSave()}
        cancelButtonProps={{ shape: 'round' }}
        okButtonProps={{ shape: 'round' }}
      >
        <Table
          className="auto-table"
          key="infoList"
          loading={tableLoading}
          columns={columns}
          rowSelection={{
            type: 'checkbox',
            onChange: getRolesChoosed,
            selectedRowKeys: selectedRowKeys,
            preserveSelectedRowKeys: true,
          }}
          scroll={{ x: 600, y: 'calc(100vh - 470px)' }}
          dataSource={dataList}
          rowKey={(record) => record.id}
          pagination={false}
        />
      </Modal>
    </>
  );
};
export default memo(BindUserModal);
