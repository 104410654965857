/*
 * @Description:客诉进度更新弹窗
 * @Author: YuKi
 * @Date: 2025/02/12
 */

import React, { memo, useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col, message, Timeline, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { addEmphasisProgress, getEmphasisProgress } from '@/api/clientFeedback/complaintsList';
import TextArea from 'antd/lib/input/TextArea';

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  id: string;
  isShowForm: boolean;
}

const EmphasisProgressModal: React.FC<ChildProps> = ({ isOpen, onClose, id, isShowForm }) => {
  const { t } = useTranslation();
  /* 查询表单的ref */
  const [addForm] = Form.useForm();
  /* 附件列表 */
  const [timelineItems, setTimelineItems] = useState<any>([]);

  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    getEmphasisProgress({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        setTimelineItems(res.data);
      }
    });
  }, []);

  /**
   * @description: 保存表单
   * @return {*}
   * @LastEditors: YuKi
   */
  const saveData = () => {
    addForm.validateFields().then((values) => {
      addEmphasisProgress({
        id,
        ...values,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHanle();
        }
      });
    });
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: YuKi
   */
  const cancelHanle = () => {
    addForm.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        title={t('complaintList.customer_complaint_progress_update')}
        open={isOpen}
        width="800px"
        maskClosable={false}
        onCancel={cancelHanle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHanle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {isShowForm && (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            )}
          </Row>,
        ]}
      >
        {isShowForm && (
          <Form layout="vertical" form={addForm} name="complaintDetail" autoComplete="off">
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {/* 进度内容 */}
                <Form.Item
                  label={t('complaintList.progress_content')}
                  name="emphasisProgress"
                  rules={[{ required: true, whitespace: true }]}
                >
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={1024} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {timelineItems && timelineItems.length > 0 ? (
          <Timeline>
            {timelineItems.map((item) => (
              <Timeline.Item>
                <div>
                  <div>{item.createServerDatetime}</div>
                  <div>
                    <span>{t('common.creator')}&nbsp;&nbsp;</span>
                    <span> {item.createAccount}</span>
                  </div>
                  <div>{item.emphasisProgress}</div>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Modal>
    </>
  );
};

export default memo(EmphasisProgressModal);
