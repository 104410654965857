/*
 * @Description: 客户反馈->历史客诉详情页
 * @Author: Wayne Hu
 * @LastEditors: Summer.Chen
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2023-12-22 10:04:57
 */
import React, { memo, useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, Row, Col, Rate, List, Table } from 'antd';
import { fileDownloadBase } from '@/api/common';
import { useTranslation } from 'react-i18next';
import { blobDownloadFile } from '@/constants/common';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleFilled, SmileOutlined } from '@ant-design/icons';
import './index.less';
import ClientMessages from './components/ClientMessages';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getUrlQuery, ifClient } from '@/utils/utils';
import { ColumnsType } from 'antd/lib/table';
import { calculatedColumns } from '@/utils/utils';
import {
  viewComplaintsList,
  viewComplaint,
  getClientComplaintComment,
  getNoClientComplaintComment,
  getHandleProcessingRateList,
} from '@/api/clientFeedback/historicalComplaints';
import ClientStatus from './components/ClientStatus';

interface ProcessList {
  [propName: string]: any;
}
/* 客户评价内容 */
type CustomerReviews = {
  content?: string; // 评价内容
  createtime?: string; // 创建时间
  isCoustomerReply?: number; // 是否客户重新评价 0-未评价 1-已评价
  isDelete?: number; // 是否删除
  isSevriceReply?: number; // 是否客服追评 0-未评价 1-已评价
  satisfaction?: number; // 客户满意度（1，2，3，4，5）
  satisfaction1?: number; // 客户打分1
  satisfaction2?: number; // 客户打分2
  satisfaction3?: number; // 客户打分3
  satisfaction4?: number; // 客户打分4
  serviceContect?: string; // 客服回复内容
  updatetime?: string; // 更新时间
};

/* 客诉进度列表 字段定义 */
type ComplaintListItem = {
  id?: any; // 主键
  processingRate?: string; // 客诉进度
  createDatetime?: string; // 时间
  handleProcessingRemark?: string; // 备注
};

/* 客诉详情字段 */
type ComplaintDetail = {
  id: number; // 客诉单号
  key?: string; // 主键
  area?: string; // 客诉所属区域
  attachmentName?: string; // 附件文件名
  attachment2Name?: string; // 附件2文件名
  attachment3Name?: string; // 附件3文件名
  attachment4Name?: string; // 附件4文件名
  attachment5Name?: string; // 附件5文件名
  attachment6Name?: string; // 附件6文件名
  attachmentShowName?: string; // 附件文件名
  attachment2ShowName?: string; // 附件2文件名
  attachment3ShowName?: string; // 附件3文件名
  attachment4ShowName?: string; // 附件4文件名
  attachment5ShowName?: string; // 附件5文件名
  attachment6ShowName?: string; // 附件6文件名
  complaintsType?: string; // 客诉类型
  contactNumber?: string; // 联系电话
  createtime?: string; // 创建时间
  creator?: string; // 创建人
  currentProcessor?: string; // 当前处理人
  description?: string; // 客诉描述
  email?: string; // 邮箱
  onsiteHandler?: string; // 现场处理人
  postCode?: string; // 邮编
  receiveTime?: string; // 接受时间
  recipient?: string; // 接受处理人
  sysLicense?: string; // 系统License
  sysSn: string; // 系统SN
  status?: any;
  title: string; // 客诉标题
  updatetime: string; // 最后更新时间
  viewComplaintsProgress?: boolean; // 是否显示查看客诉进度按钮
  complaintsProcessingList?: ProcessList[]; // 客诉处理流程
  customerReviews?: CustomerReviews; // 评价内容
  historicalComplaintsList: ProcessList[]; // 历史客诉
};

const historicalComplaintsDetails: React.FC = (props) => {
  const { t } = useTranslation();
  const [complaintKey, setComplaintKey] = useState<string>(getUrlQuery('key'));
  const evaluateForm = useRef<any>();
  const history = useHistory();
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  /* 显示评价窗口 */
  const [showEvaluate, setShowEvaluate] = useState(false);
  /* 评价类型 */
  const [evaluateType, setEvaluateType] = useState<number>(1);
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const { TextArea } = Input;
  const { confirm } = Modal;
  /* 客诉详情是数据 */
  const [complaintDetail, setComplaintDetail] = useState<ComplaintDetail>();
  const [fileImgWidth, setFileImgWidth] = useState<number>(300);
  const [fileImg, setFileImg] = useState<string>('');
  const [viewImg, setViewImg] = useState<boolean>(false);

  /* 查看客诉进度 */
  const [viewProcessDialog, setViewProcessDialog] = useState<boolean>(false);
  /* 客诉进度列表加载Loading */
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  /* 客诉进度列表数据源 */
  const [complaintProcessList, setComplaintProcessList] = useState<ComplaintListItem[]>([]);
  /* 显示更多历史客诉 */
  const [viewHistory, setViewHistory] = useState<boolean>(false);
  /* 显示留言窗口 */
  const [leaveMessage, setLeaveMessage] = useState<boolean>(false);
  const [messageFlag, setMessageFlag] = useState<boolean>(false);
  /* 显示客诉状态 */
  const [complaintStatus, setComplaintStatus] = useState<boolean>(false);

  const [ImgDown, setImgDown] = useState<any>({
    rep: {},
    name: '',
  });

  const [showInnerOperation, setShowInnerOperation] = useState<boolean>(false);
  const [tipsWordOpen, setTipsWordOpen] = useState<boolean>(false);
  const [backTime, setBackTime] = useState<number>(5);

  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    let key: string = complaintKey;
    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
      if (ifClient(roles)) {
        viewComplaintsList({ key: key }).then((res: any) => {
          if (res && res.code === 200 && res.data) {
            setComplaintDetail({ ...res.data });
          }
        });
      } else {
        /* 获取当前客诉详情 */
        viewComplaint({ key: key }).then((res: any) => {
          if (res && res.code === 200 && res.data) {
            setComplaintDetail({ ...res.data });
          }
        });
      }

      if (ifClient(roles)) {
        getClientComplaintComments(key);
      } else {
        getNoClientComplaintComments(key);
      }
    }
  }, [complaintKey]);

  /**
   * @description: 获取非客户留言列表
   * @param {*} key
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getNoClientComplaintComments = (key) => {
    getNoClientComplaintComment({ key: key })
      .then((res: any) => {
        if (res && res.code === 200) {
          if (res.data == null) {
            setMessageFlag(false);
          } else {
            setMessageFlag(true);
          }
        } else {
          setMessageFlag(false);
        }
      })
      .catch(() => {
        setMessageFlag(false);
      });
  };

  /**
   * @description: 获取客户留言列表
   * @param {*} key
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getClientComplaintComments = (key) => {
    getClientComplaintComment({ key: key })
      .then((res: any) => {
        if (res && res.code === 200) {
          if (res.data == null) {
            setMessageFlag(false);
          } else {
            setMessageFlag(true);
          }
        } else {
          setMessageFlag(false);
        }
      })
      .catch(() => {
        setMessageFlag(false);
      });
  };

  const transferLanguage = (status: any) => {
    let str = '';
    switch (status) {
      case 'Open':
        str = t('complaintList.newCreate');
        break;
      case 'Accepted':
        str = 'complaintList.accepted';
        break;
      case 'Processing':
        str = t('complaintList.processing');
        break;
      case 'ToBeVerified':
        str = t('complaintList.waitVerification');
        break;
      case 'Verification':
        str = t('complaintList.verificationing');
        break;
      case 'VerificationCompleted':
        str = t('complaintList.verificationCompleted');
        break;
      case 'ReOpen':
        str = t('complaintList.reOpen');
        break;
      case 'Completed':
        str = t('complaintList.finishCompted');
        break;
      case 'Evaluated':
        str = t('complaintList.comment');
        break;
      case 'Archive':
        str = t('complaintList.finish');
        break;
      default:
        str = 'Open';
        break;
    }
    return str;
  };
  // 显示更多客诉历史
  const showMoreHistory = () => {
    setViewHistory(true);
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载或预览客诉附件
   * @param {string} fileName
   * @return {*}
   */
  const downloadOrPreviewAttachments = (fileName: string | undefined) => {
    let params = {
      blobContainerName: 'COMPLAINTS',
      fileBlobName: fileName,
    };
    fileDownloadBase(params).then((res: any) => {
      let imgType = '.bmp.jpg.jpeg.png.gif'; // 可供预览的文件格式
      let indexNum: any = fileName?.lastIndexOf('.');
      let fileSuffix: string = fileName?.substring(indexNum + 1, fileName.length).toLowerCase() || ''; // 获取文件后缀名
      let fileInfo = {
        rep: res,
        name: fileName,
      };
      setImgDown({ ...fileInfo });
      if (imgType.indexOf(fileSuffix) > -1) {
        let src = window.URL.createObjectURL(res.data);
        let img = new Image();
        img.src = src;
        img.onload = function () {
          setFileImgWidth(500);
          setFileImg(src);
          setViewImg(true);
        };
      } else {
        blobDownloadFile(res, fileName);
      }
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 关闭图片预览窗口
   * @return {*}
   */
  const closePreview = () => {
    setViewImg(false);
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  /* 下载预览图片 */
  const downLoadFile = () => {
    blobDownloadFile(ImgDown.rep, ImgDown.name);
    setViewImg(false);
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  /* 关闭历史客诉窗口 */
  const cancelViewHistory = () => {
    setViewHistory(false);
  };

  /* 从客诉详情跳转至客诉编辑页面 */
  const jumpToEditPage = () => {
    if (history.location.pathname.indexOf('/innerStatic') < 0) {
      localStorage.setItem('pathname', history.location.pathname);
    }
    history.push({
      pathname: '/innerStatic/editCustomerComplaint/index',
      search: document.location.search && document.location.search.substring(1),
    });
  };

  /* 打开留言窗口 */
  const openLeaveMessage = () => {
    setLeaveMessage(true);
  };
  /* 打开客诉状态窗口 */
  const openComplaintStatus = () => {
    setComplaintStatus(true);
  };

  /* 转化url参数内容 */
  const parseUrlParams = (item: any) => {
    let arrayTemp: any = document.location.search.substring(1).split('&');
    let newArray: any = [];

    for (let i = 0; i < arrayTemp.length; i++) {
      if (arrayTemp[i].indexOf('key=') < 0) {
        newArray.push(arrayTemp[i]);
      }
    }
    if (item && item.key) {
      newArray.push('key=' + item.key);
      return newArray.join('&');
    } else {
      return newArray.join('&');
    }
  };

  /* 从客诉详情跳转至客诉列表页面 */
  const backToComplaintList = () => {
    history.push({
      pathname: '/historicalComplaints/index',
      search: document.location.search && document.location.search.substring(1),
    });
  };

  /* 查看客诉进度 */
  const viewProcess = () => {
    setViewProcessDialog(true);
    setTableLoading(true);
    getHandleProcessingRateList({ key: getUrlQuery('key') })
      .then((res: any) => {
        setTableLoading(false);
        if (res && res.code === 200 && res.data) {
          setComplaintProcessList([...res.data]);
        } else {
          setComplaintProcessList([...res.data]);
        }
      })
      .catch(() => {
        setTableLoading(false);
      });
  };

  /* 关闭客诉进度查看窗口 */
  const closeProcessingDialog = () => {
    setViewProcessDialog(false);
    setComplaintProcessList([]);
  };

  /* 留言关闭窗口 */
  const handleClose = () => {
    setLeaveMessage(false);
  };

  /* 留言关闭窗口 */
  const handleStatusClose = () => {
    setComplaintStatus(false);
  };
  /* 处理评价分数变化 */
  const handleRateChange = (event, type) => {
    if (type === 1) {
      if (event === 0) {
        evaluateForm && evaluateForm.current.setFieldValue('satisfaction', 1);
      }
    }
    if (type === 2) {
      if (event === 0) {
        evaluateForm && evaluateForm.current.setFieldValue('satisfaction1', 1);
      }
    }
    if (type === 3) {
      if (event === 0) {
        evaluateForm && evaluateForm.current.setFieldValue('satisfaction2', 1);
      }
    }
  };

  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: April
   */
  const handleCanceTip = () => {
    setTipsWordOpen(false);
  };
  const columns: ColumnsType<ComplaintListItem> = [
    /* 客诉进度 */
    {
      title: t('complaintList.processingRate'),
      key: 'processingRate',
      dataIndex: 'processingRate',
      align: 'center',
      width: '40%',
      ellipsis: true,
      render: (_, record) => [
        <div>
          {record.processingRate == 'lab_installer_todoor' ? (
            <span>{t('complaintList.lab_installer_todoor')}</span>
          ) : null}
          {record.processingRate == 'lab_installer_has_todoor' ? (
            <span>{t('complaintList.lab_installer_has_todoor')}</span>
          ) : null}
          {record.processingRate == 'lab_sales_todoor' ? <span>{t('complaintList.lab_sales_todoor')}</span> : null}
          {record.processingRate == 'lab_sales_has_todoor' ? (
            <span>{t('complaintList.lab_sales_has_todoor')}</span>
          ) : null}
          {record.processingRate == 'lab_to_replaced' ? <span>{t('complaintList.lab_to_replaced')}</span> : null}
          {record.processingRate == 'lab_has_replaced' ? <span>{t('complaintList.lab_has_replaced')}</span> : null}
          {record.processingRate == 'lab_to_shipped' ? <span>{t('complaintList.lab_to_shipped')}</span> : null}
          {record.processingRate == 'lab_has_Shipped' ? <span>{t('complaintList.lab_has_Shipped')}</span> : null}
          {record.processingRate == 'lab_to_pickedup' ? <span>{t('complaintList.lab_to_pickedup')}</span> : null}
          {record.processingRate == 'lab_has_pickedup' ? <span>{t('complaintList.lab_has_pickedup')}</span> : null}
          {record.processingRate == 'lab_to_repaired' ? <span>{t('complaintList.lab_to_repaired')}</span> : null}
          {record.processingRate == 'lab_has_repaired' ? <span>{t('complaintList.lab_has_repaired')}</span> : null}
          {record.processingRate == 'lab_bill_to_settled' ? (
            <span>{t('complaintList.lab_bill_to_settled')}</span>
          ) : null}
          {record.processingRate == 'lab_confirmed_bill' ? <span>{t('complaintList.lab_confirmed_bill')}</span> : null}
          {record.processingRate == 'lab_wait_rd_analysis_data' ? (
            <span>{t('complaintList.lab_wait_rd_analysis_data')}</span>
          ) : null}
        </div>,
      ],
    },
    /* 时间 */
    {
      title: t('complaintList.time'),
      key: 'createDatetime',
      dataIndex: 'createDatetime',
      align: 'center',
      width: '25%',
      ellipsis: true,
    },
    /* 备注 */
    {
      title: t('complaintList.remark'),
      key: 'handleProcessingRemark',
      dataIndex: 'handleProcessingRemark',
      align: 'center',
      ellipsis: true,
      render: (_, record) => {
        if (record.handleProcessingRemark) {
          return (
            <div className="content-break" dangerouslySetInnerHTML={{ __html: record.handleProcessingRemark }}></div>
          );
        } else {
          return '';
        }
      },
    },
  ];

  return (
    <>
      <Form layout="vertical" form={searchForm} name="complaintDetail" autoComplete="off" style={{ width: '100%' }}>
        <Row style={{ float: 'right' }}>
          {/* 返回客诉列表页 */}
          <a onClick={backToComplaintList} className="color-label">
            {t('complaintList.back')}
          </a>
        </Row>
        <Row gutter={8} className="row-border">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.title')}</span>}
            >
              <div>
                <span>{complaintDetail?.title}</span>
                <br />
                <p>
                  <span>
                    {t('complaintList.complaintNumber')}: {complaintDetail?.id}{' '}
                  </span>
                  &nbsp;&nbsp;
                  <span>
                    {t('complaintList.creator')}: {complaintDetail?.creator}
                  </span>
                  &nbsp;&nbsp;
                  <span>
                    {t('complaintList.createTime')}: {complaintDetail?.createtime}
                  </span>
                </p>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8} className="row-border">
          {/* sn */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.sysSn')}</span>}
            >
              <span>{complaintDetail?.sysSn}</span>
            </Form.Item>
          </Col>
          {/* 历史客诉 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.historyComplaint')}</span>}
            >
              {complaintDetail?.historicalComplaintsList && complaintDetail?.historicalComplaintsList?.length >= 2 ? (
                <a onClick={showMoreHistory} className="color-label" style={{ float: 'right' }}>
                  {t('complaintList.more')}
                </a>
              ) : null}
              <p>
                <span>
                  {complaintDetail?.historicalComplaintsList && complaintDetail?.historicalComplaintsList?.length > 0
                    ? complaintDetail?.historicalComplaintsList[0].description.length > 30
                      ? complaintDetail?.historicalComplaintsList[0].description.substring(0, 27) + '...'
                      : complaintDetail?.historicalComplaintsList[0].description
                    : ''}
                </span>
              </p>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 国家地区 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.area')}</span>}
            >
              <span>{complaintDetail?.area}</span>
            </Form.Item>
          </Col>
          {/* 邮编 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.postCode')}</span>}
            >
              <span>{complaintDetail?.postCode}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 问题类型 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.complaintsType')}</span>}
            >
              {complaintDetail?.complaintsType == 'title_inverter' ? <span>{t('complaintList.inverter')}</span> : ''}
              {complaintDetail?.complaintsType == 'title_battery' ? <span>{t('complaintList.battery')}</span> : ''}
              {complaintDetail?.complaintsType == 'lab_meter' ? <span>{t('complaintList.meter')}</span> : ''}
              {complaintDetail?.complaintsType == 'Backup_Box' ? <span>{t('complaintList.Backup_Box')}</span> : ''}
              {complaintDetail?.complaintsType == 'EMS' ? <span>{t('complaintList.ems')}</span> : ''}
              {complaintDetail?.complaintsType == 'lab_monitoring' ? <span>{t('complaintList.monitor')}</span> : ''}
              {complaintDetail?.complaintsType == 'APP' ? <span>{t('complaintList.app')}</span> : ''}
              {complaintDetail?.complaintsType == 'lab_other' ? <span>{t('complaintList.other')}</span> : ''}
            </Form.Item>
          </Col>
          {/* 许可证号 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.license')}</span>}
            >
              <span>{complaintDetail?.sysLicense}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 邮箱 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.email')}</span>}
            >
              <span>{complaintDetail?.email}</span>
            </Form.Item>
          </Col>
          {/* 电话 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.contactNumber')}</span>}
            >
              <span>{complaintDetail?.contactNumber}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 最后更新时间 */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.lastUpdatetime')}</span>}
            >
              <span>{complaintDetail?.updatetime}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 描述 */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.description')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{complaintDetail?.description}</div>
            </Form.Item>
          </Col>
        </Row>
        {complaintDetail?.recipient ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* 接收方 */}
              <Form.Item
                className="complaint-detail-label"
                label={<span className="form-item-label">{t('complaintList.receiver')}</span>}
              >
                <span>{complaintDetail?.recipient}</span>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* 接收时间 */}
              <Form.Item
                className="complaint-detail-label"
                label={<span className="form-item-label">{t('complaintList.receiveTime')}</span>}
              >
                <span>{complaintDetail?.receiveTime}</span>
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        {complaintDetail?.onsiteHandler ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* 处理方 */}
              <Form.Item
                className="complaint-detail-label"
                label={<span className="form-item-label">{t('complaintList.handler')}</span>}
              >
                <span>{complaintDetail?.currentProcessor}</span>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* 实际处理人 */}
              <Form.Item
                className="complaint-detail-label"
                label={<span className="form-item-label">{t('complaintList.realHandler')}</span>}
              >
                <span>{complaintDetail?.onsiteHandler}</span>
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        <Row gutter={8} className="row-border">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {/* 客诉状态 */}
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.complaintStatus')}</span>}
            >
              {/* 点击查看详情（客诉状态） */}
              {ifClient(roles) ? null : (
                <a onClick={openComplaintStatus} className="color-label" style={{ float: 'right' }}>
                  {t('complaintList.clickFindDetail')}
                </a>
              )}

              <br />
              {/* {complaintDetail?.viewComplaintsProgress ? (
                // 查看客诉进度
                <a onClick={viewProcess} className="color-label" style={{ float: 'right' }}>
                  {t('complaintList.viewComplaintProcess')}
                </a>
              ) : null} */}
              <ul className="steps">
                {complaintDetail?.complaintsProcessingList?.map((item, index) => {
                  return (
                    <li key={index}>
                      {/* 当前处理状态 */}
                      {item.currentStatus ? <span>{transferLanguage(item.currentStatus)}</span> : <span>&nbsp;</span>}
                      {/* 处理人 */}
                      {item.processor ? <span>{item.processor}</span> : <span>&nbsp;</span>}
                      {/* 开始处理时间 */}
                      {item.processingTime ? <span>{item.processingTime}</span> : <span>&nbsp;</span>}
                      {/* 处理花费 */}
                      {index > 0 ? <span className="line-contact">{item.timeConsuming}</span> : null}
                    </li>
                  );
                })}
              </ul>
            </Form.Item>
          </Col>
        </Row>

        {complaintDetail?.customerReviews &&
        (complaintDetail?.customerReviews.satisfaction ||
          complaintDetail?.customerReviews.satisfaction1 ||
          complaintDetail?.customerReviews.satisfaction2 ||
          complaintDetail?.customerReviews.content) ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                className="complaint-detail-label"
                label={<span className="form-item-label">{t('complaintList.commentContent')}</span>}
              >
                <span>{t('complaintList.resolveResultSatisfy')}: </span>
                <Rate
                  disabled
                  count={5}
                  value={complaintDetail?.customerReviews?.satisfaction}
                  character={<SmileOutlined />}
                />
                <br />
                <span>{t('complaintList.useTimeSatisfy')}: </span>
                <Rate
                  disabled
                  count={5}
                  value={complaintDetail?.customerReviews?.satisfaction1}
                  character={<SmileOutlined />}
                />
                <br />
                <span>{t('complaintList.serviceStaisfy')}: </span>
                <Rate
                  disabled
                  count={5}
                  value={complaintDetail?.customerReviews?.satisfaction2}
                  character={<SmileOutlined />}
                />
                <br />
              </Form.Item>
            </Col>
            {complaintDetail?.customerReviews?.content ? (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextArea
                  style={{ resize: 'none' }}
                  rows={3}
                  readOnly
                  bordered={false}
                  value={complaintDetail?.customerReviews?.content}
                />
              </Col>
            ) : null}
          </Row>
        ) : null}
        {/* 客服回复 */}
        {complaintDetail?.customerReviews?.serviceContect ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                className="complaint-detail-label"
                label={<span className="form-item-label">{t('complaintList.Servicetel')}</span>}
              >
                <p>{complaintDetail?.customerReviews?.serviceContect}</p>
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        {/* 查看留言 */}
        {messageFlag ? (
          <Row className="row-border" gutter={8}>
            <a style={{ margin: '6px' }} onClick={openLeaveMessage}>
              {t('complaintList.checkMessage')}
            </a>
          </Row>
        ) : null}

        {complaintDetail?.attachmentShowName ||
        complaintDetail?.attachment2ShowName ||
        complaintDetail?.attachment3ShowName ||
        complaintDetail?.attachment4ShowName ||
        complaintDetail?.attachment5ShowName ||
        complaintDetail?.attachment6ShowName ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                className="complaint-detail-label"
                label={<span className="form-item-label">{t('complaintList.attachmentDownload')}</span>}
              >
                {complaintDetail?.attachmentShowName ? (
                  <>
                    <a onClick={() => downloadOrPreviewAttachments(complaintDetail?.attachmentName)}>
                      {complaintDetail?.attachmentShowName}
                    </a>
                    <br />
                  </>
                ) : null}
                {complaintDetail?.attachment2ShowName ? (
                  <>
                    <a onClick={() => downloadOrPreviewAttachments(complaintDetail?.attachment2Name)}>
                      {complaintDetail?.attachment2ShowName}
                    </a>
                    <br />
                  </>
                ) : null}
                {complaintDetail?.attachment3ShowName ? (
                  <>
                    <a onClick={() => downloadOrPreviewAttachments(complaintDetail?.attachment3Name)}>
                      {complaintDetail?.attachment3ShowName}
                    </a>
                    <br />
                  </>
                ) : null}
                {complaintDetail?.attachment4ShowName ? (
                  <>
                    <a onClick={() => downloadOrPreviewAttachments(complaintDetail?.attachment4Name)}>
                      {complaintDetail?.attachment4ShowName}
                    </a>
                    <br />
                  </>
                ) : null}
                {complaintDetail?.attachment5ShowName ? (
                  <>
                    <a onClick={() => downloadOrPreviewAttachments(complaintDetail?.attachment5Name)}>
                      {complaintDetail?.attachment5ShowName}
                    </a>
                    <br />
                  </>
                ) : null}
                {complaintDetail?.attachment6ShowName ? (
                  <>
                    <a onClick={() => downloadOrPreviewAttachments(complaintDetail?.attachment6Name)}>
                      {complaintDetail?.attachment6ShowName}
                    </a>
                    <br />
                  </>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
        ) : null}
      </Form>

      {/* 客诉进度列表 */}
      <Modal
        title={t('complaintList.processingRate')}
        open={viewProcessDialog}
        onCancel={closeProcessingDialog}
        width="55%"
        destroyOnClose
        maskClosable={false}
        footer={null}
      >
        <Table
          rowKey="id"
          rowClassName={(record, index) => {
            if (index === complaintProcessList.length - 1) {
              return 'last-row';
            } else {
              return '';
            }
          }}
          bordered
          columns={calculatedColumns(columns)}
          loading={tableLoading}
          pagination={false}
          dataSource={complaintProcessList}
          scroll={{ y: 500 }}
          className="custom-table-flex"
        />
      </Modal>

      {/* 客诉附件 图片预览和下载 */}
      <Modal
        title={t('complaintList.preview')}
        open={viewImg}
        onCancel={closePreview}
        width="60%"
        maskClosable={false}
        destroyOnClose
        forceRender
        footer={[
          <Button key="download" shape="round" onClick={downLoadFile}>
            {t('complaintList.download')}
          </Button>,
          <Button key="close" shape="round" type="primary" onClick={closePreview}>
            {t('complaintList.close')}
          </Button>,
        ]}
      >
        <div style={{ width: '100%', overflow: 'auto' }}>
          <img src={fileImg} width={fileImgWidth} alt="加载失败" style={{ margin: 'auto', display: 'block' }} />
        </div>
      </Modal>

      {/* 查看更多历史客诉 */}
      <Modal
        title={t('complaintList.historyComplaint')}
        open={viewHistory}
        onCancel={cancelViewHistory}
        width="50%"
        destroyOnClose
        maskClosable={false}
        footer={null}
      >
        <List
          header={null}
          footer={null}
          style={{ height: '500px', width: '100%', overflowY: 'scroll', border: '1px solid #dddddd' }}
          dataSource={complaintDetail?.historicalComplaintsList}
          renderItem={(item, index) => (
            <List.Item key={item.key} style={{ borderBottom: '1px solid #dddddd', padding: '8px 4px' }}>
              {document.querySelector('.alpha-cloud-SSO') ? (
                <a
                  className="wrap-a"
                  onClick={() => {
                    setViewHistory(false);
                    setComplaintKey(item.key);
                  }}
                >
                  {item.title}
                </a>
              ) : (
                <a
                  className="wrap-a"
                  href={'/innerStatic/historicalComplaintsDetails/index' + parseUrlParams(item)}
                  target={'_blank'}
                >
                  {item.title}
                </a>
              )}
            </List.Item>
          )}
        />
      </Modal>
      {/* 客诉留言 */}
      {leaveMessage ? (
        <ClientMessages
          leaveMessageParams={{
            key: getUrlQuery('key') || complaintDetail?.key,
            showDialog: leaveMessage,
          }}
          closeLeaveMessage={handleClose}
        ></ClientMessages>
      ) : null}
      {complaintStatus ? (
        <ClientStatus
          statusParams={{
            key: getUrlQuery('key') || complaintDetail?.key,
            showDialog: complaintStatus,
          }}
          closeComplaintStatus={handleStatusClose}
        ></ClientStatus>
      ) : null}

      {/* 没有权限-提示弹窗 */}
      <Modal
        title={t('complaintList.tip')}
        open={tipsWordOpen}
        width="30%"
        maskClosable={false}
        footer={false}
        onCancel={handleCanceTip}
      >
        <div style={{ margin: '25px auto', textAlign: 'center' }}>
          <ExclamationCircleFilled />
          &nbsp;
          <span>{t('customercomplaint.noSNRight')} :</span>
          <strong>{backTime}</strong>
        </div>
      </Modal>
    </>
  );
};

export default memo(historicalComplaintsDetails);
