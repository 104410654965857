/*
 * @Description: 客户反馈->客诉详情页-> 客诉归档
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2025/03/21
 */

import React, { memo, useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, Row, Col, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getComponentList, getModuleList, getContentList } from '@/api/clientFeedback/complaintsList';
import { saveArchive, getArchiveInfo } from '@/api/clientFeedback/complaintsList';
const { Option } = Select;

interface ProcessList {
  [propName: string]: any;
}

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
  businessLine: any;
}

const ArchiveModal: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  id,
  extendParams,
  nodeData,
  businessLine,
}) => {
  const { t } = useTranslation();
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  /* 获取部件列表 */
  const [componentsList, setComponentsList] = useState<any>([]);
  /* 获取模块列表 */
  const [ModuleList, setModuleList] = useState([]);
  /* 获取问题内容列表 */
  const [contentList, setContentList] = useState([]);
  const [showAssign, setShowAssign] = useState(false);

  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const { TextArea } = Input;

  useEffect(() => {
    getArchiveInfos(id);
    if (extendParams) {
      searchForm.setFieldsValue({ caseSolution: extendParams.caseSolution });
    }
  }, []);

  /**
   * @description: 保存归档
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    searchForm.validateFields().then((values) => {
      saveArchive({
        complaintsId: id,
        ...originData,
        ...values,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHanle();
        }
      });
    });
  };

  /**
   * @description: 回显归档归责数据
   * @param {*} param1
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getArchiveInfos = (key) => {
    getArchiveInfo({ complaintsId: key }).then((res: any) => {
      if (res.code == 200 && res.data) {
        setShowAssign(true);
        searchForm.setFieldValue('businessLine', businessLine);
        const { componentId, contentId, moduleId, departmentList, ...rest } = res.data;
        // searchForm.setFieldsValue({ ...res.data, departmentList: (res.data.departmentList ?? []).join('、') });
        if (!businessLine) return;
        getComponentList({ businessLine }).then((res: any) => {
          if (res.code == 200 && res.data) {
            setComponentsList(res.data);
            if (!componentId) return;
            getModuleList({ id: componentId }).then((res: any) => {
              if (res.code == 200 && res.data) {
                setModuleList(res.data);
                if (!moduleId) return;
                getContentList({ id: moduleId }).then((res: any) => {
                  if (res.code == 200 && res.data) {
                    setContentList(res.data);
                    searchForm.setFieldsValue({
                      ...rest,
                      componentId,
                      contentId,
                      moduleId,
                      departmentList: (departmentList ?? []).join('、'),
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  };

  /**
   * @description: 获取部件列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getComponentLists = (e) => {
    getComponentList({ businessLine: e }).then((res: any) => {
      if (res.code == 200 && res.data) {
        setComponentsList(res.data);
      }
    });
    searchForm.setFieldValue('componentId', null);
    searchForm.setFieldValue('moduleId', null);
    searchForm.setFieldValue('contentId', null);
  };

  /**
   * @description: 获取模块列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getModuleLists = (val) => {
    if (val) {
      getModuleList({ id: val }).then((res: any) => {
        if (res.code == 200 && res.data) {
          setModuleList(res.data);
        }
      });
    } else {
      setModuleList([]);
    }
    setContentList([]);
    searchForm.setFieldValue('moduleId', null);
    searchForm.setFieldValue('contentId', null);
  };

  /**
   * @description: 获取内容列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getContentLists = (val) => {
    if (val) {
      getContentList({ id: val }).then((res: any) => {
        if (res.code == 200 && res.data) {
          setContentList(res.data);
        }
      });
    }
    searchForm.setFieldValue('contentId', null);
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const cancelHanle = () => {
    searchForm.resetFields();
    setShowAssign(false);
    onClose();
  };

  /**
   * @description: 根据产品线获取部件名称
   * @param {*} e
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const handleComponentLists = (e) => {
    searchForm.resetFields(['componentId']);
    if (e) {
      getComponentLists(e);
    } else {
      setComponentsList([]);
    }
    setModuleList([]);
    setContentList([]);
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData.data.currentStatusShowName}
        open={isOpen}
        width="30%"
        maskClosable={false}
        onCancel={cancelHanle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHanle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        {nodeData ? (
          <Form
            layout="vertical"
            form={searchForm}
            name="complaintDetail"
            autoComplete="off"
            style={{ width: '100%' }}
            className=""
            disabled={nodeData ? true : false}
          >
            <Row gutter={8} className="row-border">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="component"
                  label={<span className="form-item-label">{t('archivalMaintenance.component')}</span>}
                >
                  <TextArea />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="module"
                  label={<span className="form-item-label">{t('archivalMaintenance.module')}</span>}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="content"
                  label={<span className="form-item-label">{t('archivalMaintenance.content')}</span>}
                >
                  <Input />
                </Form.Item>
              </Col>
              {/* 责任归属方 */}
              <Col span={24}>
                <Form.Item
                  label={<span className="form-item-label">{t('responsiblePartyMaintenance.responsibleParty')}</span>}
                  name="responsibleParty"
                >
                  <Input />
                </Form.Item>
              </Col>
              {/* 责任归属部门 */}
              <Col span={24}>
                <Form.Item
                  label={<span className="form-item-label">{t('responsiblePartyMaintenance.departmentList')}</span>}
                  name="departmentList"
                >
                  <Input />
                </Form.Item>
              </Col>
              {/* 问题原因 */}
              <Col span={24}>
                <Form.Item
                  label={<span className="form-item-label">{t('archivalMaintenance.problemCauses')}</span>}
                  name="problemCauses"
                >
                  <TextArea allowClear />
                </Form.Item>
              </Col>
              {/* 处理结果 */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="processResult"
                  label={<span className="form-item-label">{t('complaintList.resolveResult')}</span>}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form
            layout="vertical"
            form={searchForm}
            name="complaintDetail"
            autoComplete="off"
            style={{ width: '100%' }}
            className=""
            disabled={nodeData ? true : false}
          >
            <Row gutter={8} className="row-border">
              {/* 客诉解决方案 */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name={'caseSolution'}
                  label={<span className="form-item-label">{t('archivalMaintenance.complaintSolution')}</span>}
                >
                  <TextArea disabled />
                </Form.Item>
              </Col>
              {/* 处理结果 */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="processResult"
                  label={<span className="form-item-label">{t('complaintList.resolveResult')}</span>}
                >
                  <Input maxLength={128} />
                </Form.Item>
              </Col>
              {/* 产品线 */}
              <Col span={24}>
                <Form.Item
                  label={<span className="form-item-label">{t('archivalMaintenance.productLine')}</span>}
                  name="businessLine"
                  rules={[
                    {
                      required: true,
                      message: `${t('archivalMaintenance.productLine')}${t('archivalMaintenance.parmasRequired')}`,
                    },
                  ]}
                >
                  <Select
                    placeholder={`${t('common.pleaseChoose')}`}
                    allowClear
                    onChange={handleComponentLists}
                    options={[
                      { value: 10, label: t('archivalMaintenance.householdUse') },
                      { value: 20, label: t('createComplaint.consumerBusiness') },
                      { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                      { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                    ]}
                  />
                </Form.Item>
              </Col>
              {/* 部件名称 */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name={'componentId'}
                  rules={[
                    {
                      required: true,
                      message: `${t('archivalMaintenance.component')}${t('archivalMaintenance.parmasRequired')}`,
                    },
                  ]}
                  label={<span className="form-item-label">{t('archivalMaintenance.component')}</span>}
                >
                  <Select allowClear placeholder={`${t('common.pleaseChoose')}`} onChange={getModuleLists}>
                    {componentsList &&
                      componentsList.length > 0 &&
                      componentsList.map((item: any, index) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {item.component}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              {/* 模块名称 */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.componentId !== currentValues.componentId}
                >
                  {({ getFieldValue }) =>
                    getFieldValue('componentId') !== undefined ? (
                      <Form.Item
                        name="moduleId"
                        label={<span className="form-item-label">{t('archivalMaintenance.module')}</span>}
                        rules={[
                          {
                            required: true,
                            message: `${t('archivalMaintenance.module')}${t('archivalMaintenance.parmasRequired')}`,
                          },
                        ]}
                      >
                        <Select allowClear placeholder={`${t('common.pleaseChoose')}`} onChange={getContentLists}>
                          {ModuleList &&
                            ModuleList.length > 0 &&
                            ModuleList.map((item: any, index) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.module}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
              {/* 内容 */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.moduleId !== currentValues.moduleId}
                >
                  {({ getFieldValue }) =>
                    getFieldValue('moduleId') !== undefined && getFieldValue('componentId') !== undefined ? (
                      <Form.Item
                        name="contentId"
                        label={<span className="form-item-label">{t('archivalMaintenance.content')}</span>}
                        rules={[
                          {
                            required: true,
                            message: `${t('archivalMaintenance.content')}${t('archivalMaintenance.parmasRequired')}`,
                          },
                        ]}
                      >
                        <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                          {contentList &&
                            contentList.length > 0 &&
                            contentList.map((item: any, index) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.content}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default memo(ArchiveModal);
