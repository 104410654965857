/*
 * @Description:注册成功后的页面
 * @Author: YuKi
 * @Date: 2022/11/03
 * @LastEditors: YuKi
 * @LastEditTime: 2023/08/22
 */
import React, { memo, useState, useRef, useEffect, useCallback } from 'react';
import { Button, Checkbox, Tooltip, Form, Input, Row, Col, Select, Modal, DatePicker, Spin, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { findWindows } from 'windows-iana';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getLanguageTypeList, getCountryList } from '@/api/common';
import {
  getTimeZones,
  getInstallTimeBySysSn,
  getStateOrState,
  checkSysSn,
  checkUsername,
  register,
  getUserAgreement,
  getAbstractTimeZoneByAddress,
} from '@/api/login';
import { inputValueLimit } from '@/constants/inputValueLimit';
import { emailReg } from '@/constants/regRules';
import { useSelector, RootStateOrAny } from 'react-redux';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import Header from '../Header';
import { encrypt, languageLongSwitch } from '@/constants/common';
import './index.less';
let timeInterval: any = null;
const { Option } = Select;
const Register: React.FC = () => {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const history = useHistory(); //列表ref
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 注册表单的ref */
  const [registerForm] = Form.useForm();
  const [informationConfirmedForm] = Form.useForm();

  /* 语言列表源数据 */
  const [langTypeList, setLangTypeList] = useState<any>([]);
  /* 国家列表源数据 */
  const [countryList, setCountryList] = useState<any>([]);
  /* 时区列表源数据 */
  const [timeZonesList, setTimeZonesList] = useState<any>([]);
  /* 安装日期flag:1代表初始状态禁用安装日期组件，且不提示，2代表安装商已经注册sn,安装日期禁用且提示，3代表sn没有被注册过 */
  const [insDateFlag, setInsDateFlag] = useState<any>(1);
  let [isUseEffectFirst, setIsUseEffectFirst] = useState(true);
  /* 服务条款弹窗->标识 */
  const [isProvicyOpen, setIsProvicyOpen] = useState(false);
  /* 服务条款弹窗->标识 */
  const [informationConfirmedFlag, setInformationConfirmedFlag] = useState(false);
  /* 服务条款弹窗-> 标题*/
  const [provicyTitle, setProvicyTitle] = useState<any>('');
  /* 服务条款弹窗-> 内容*/
  const [provicyContent, setProvicyContent] = useState<any>('');
  // 获取时区
  const [timeZone, setTimeZone] = useState({ countryName: '', proviceName: '', cityName: '' });
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  //
  const [isLoadingTimeZone, setIsLoadingTimeZone] = useState(false);
  /**
   * @description:监听国家、省市、城市，变化  获取时区
   * @Author: Summer.Chen
   * @Date: 2023-05-24 17:33:52
   * @return {*}
   * @LastEditTime: Do not edit
   * @LastEditors: Summer.Chen
   */
  useEffect(() => {
    if (isFirstLoading) {
      setIsFirstLoading(false);
      return;
    }
    if (registerForm.getFieldValue('userType') !== 'customer') {
      return;
    }
    // 防抖
    if (timeInterval) {
      clearTimeout(timeInterval);
      timeInterval = null;
    }
    timeInterval = setTimeout(() => {
      setIsLoadingTimeZone(true);
      getAbstractTimeZoneByAddress(timeZone.cityName + ',' + timeZone.proviceName + ',' + timeZone.countryName)
        .then((rep: any) => {
          if (rep && rep.data) {
            let microsoftTimeZone = findWindows(rep.data.timezone_location);
            registerForm.setFieldValue('timezone', microsoftTimeZone.length ? microsoftTimeZone[0] : '');
            if (microsoftTimeZone && microsoftTimeZone.length > 0 && timeZonesList && timeZonesList.length > 0) {
              for (let i = 0; i < timeZonesList.length; i++) {
                if (microsoftTimeZone[0] === timeZonesList[i].standardName) {
                  informationConfirmedForm.setFieldValue('timezone', timeZonesList[i].displayName);
                  break;
                }
              }
            }
          }
          setIsLoadingTimeZone(false);
        })
        .catch((error) => {
          setIsLoadingTimeZone(false);
        });
    }, 1000);
  }, [timeZone]);
  /**
   * @Author: YuKi
   * @description: 根据语言切换动态切换样式
   * @return {*}
   */
  useEffect(() => {
    if (isUseEffectFirst) {
      setIsUseEffectFirst(false);
      return;
    }
    let tempError = registerForm.getFieldsError();
    for (let index = 0; index < tempError.length; index++) {
      const element = tempError[index];
      if (element.errors && element.errors.length > 0) {
        registerForm.validateFields(element.name);
      }
    }
  }, [language]);

  /**
   * @description:城市名字变化
   * @Author: Summer.Chen
   * @Date: 2023-05-24 17:46:53
   * @return {*}
   * @LastEditTime: Do not edit
   * @LastEditors: Summer.Chen
   */
  const cityChange = (e) => {
    setTimeZone({
      ...timeZone,
      cityName: e.target.value,
    });
  };
  /**
   * @Author: YuKi
   * @description: 获取语言列表数据
   * @return {*}
   */
  const getLanguageTypeLists = () => {
    getLanguageTypeList()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setLangTypeList([...rep.data]);
        } else {
          setLangTypeList([]);
        }
      })
      .catch((err) => {
        setLangTypeList([]);
      });
  };

  /**
   * @Author: YuKi
   * @description: 获取国家列表数据
   * @return {*}
   */
  const getCountryLists = () => {
    getCountryList()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setCountryList([...rep.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch((err) => {
        setCountryList([]);
      });
  };

  /**
   * @Author: YuKi
   * @description: 获取时区列表
   * @return {*}
   */
  const getTimeZone = () => {
    getTimeZones()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setTimeZonesList([...rep.data]);
        } else {
          setTimeZonesList([]);
        }
      })
      .catch((err) => {
        setTimeZonesList([]);
      });
  };

  /**
   * @Author: YuKi
   * @description: 初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    getLanguageTypeLists(); /*获取语言列表数据  */
    getCountryLists(); /* 获取国家列表 */
    getTimeZone(); /* 获取时区列表 */
    getProvicyItem(); /* 获取用户协议 */

    let languageCode = languageLongSwitch(language);
    registerForm.setFieldValue('languageCode', languageCode);
    return () => {
      timeInterval = null;
    };
  }, []);

  async function onFinish(values: any) {
    let checkSysSnFlag = true;
    let checkUsernameFlag = true;
    if (values.userType === 'customer') {
      /* 檢查SN */
      let checkSysSnResult: any = await checkSysSn({ sysSn: values.sysSn });
      if (!(checkSysSnResult && checkSysSnResult.code === 200)) {
        checkSysSnFlag = false;
      }
    }

    /* 检查用户名 */
    if (checkSysSnFlag) {
      let checkUsernameResult: any = await checkUsername({ username: values.userName });
      if (!(checkUsernameResult && checkUsernameResult.code === 200)) {
        checkUsernameFlag = false;
      }
    }

    if (checkSysSnFlag && checkUsernameFlag) {
      if (values.userType === 'customer') {
        setInformationConfirmedFlag(true);
      } else {
        registerSubmit();
      }
    }
  }

  const registerSubmit = () => {
    let values = registerForm.getFieldsValue();
    let dataList = {
      ...values,
      countryId: values.countryCode,
      allowAutoUpdate: values.allowAutoUpdate ? 1 : 0,
      installDate: moment(values.installDate).format('YYYY-MM-DD'),
      password: encrypt(values.password.trim(), values.userName.trim()),
      confirmPassword: encrypt(values.confirmPassword.trim(), values.userName.trim()),
    };
    register(dataList).then((rep: any) => {
      if (rep && rep.code === 200) {
        history.push({
          pathname: '/newRegisterSecond',
          state: {
            email: values.userName,
          },
        });
      }
    });
  };

  /**
   * @Author: YuKi
   * @description: 注册时根据sn获取安装时间
   * @return {*}
   */
  const syncUpdate = () => {
    if (registerForm.getFieldValue('sysSn') && registerForm.getFieldValue('sysSn').trim()) {
      getInstallTimeBySysSn({ sysSn: registerForm.getFieldValue('sysSn') })
        .then((rep: any) => {
          if (rep && rep.code === 200) {
            if (rep.data) {
              setInsDateFlag(2);
              registerForm.setFieldValue('installDate', moment(rep.data, 'YYYY-MM-DD'));
            } else {
              setInsDateFlag(3);
              registerForm.setFieldValue('installDate', '');
            }
          }
        })
        .catch((err) => {});
    }
  };

  /**
   * @Author: YuKi
   * @description: 国家/地区选中值处理
   * @param {string} value
   * @return {*}
   */
  const handleAreaChange = (value: string, option) => {
    if (option && option.children) {
      informationConfirmedForm.setFieldValue('countryCode', option.children);
    } else {
      informationConfirmedForm.setFieldValue('countryCode', '');
    }

    registerForm.resetFields(['stateCode', 'cityCode']);
    countryList.forEach((item: any) => {
      if (item.areaId == value) {
        setTimeZone({
          countryName: item.areaEnglishName,
          proviceName: '',
          cityName: '',
        });
      }
    });
  };

  /**
   * @Author: YuKi
   * @description: 时区选中的时候，给弹窗里面的内容赋值
   * @param {string} value
   * @param {*} option
   * @return {*}
   */
  const handleTimezoneChange = (value: string, option) => {
    if (option && option.children) {
      informationConfirmedForm.setFieldValue('timezone', option.children);
    } else {
      informationConfirmedForm.setFieldValue('timezone', '');
    }
  };

  /**
   * @Author: YuKi
   * @description: 省/州失焦
   * @return {*}
   */
  const onStateChange = (e) => {
    setTimeZone({
      ...timeZone,
      proviceName: e.target.value,
    });
  };

  /**
   * @Author: YuKi
   * @description: 安装日期时间处理
   * @param {*} current
   * @return {*}
   */
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current.valueOf() > Date.now() ||
        current.valueOf() < moment(moment(new Date()).add(-5, 'years'), 'YYYY-MM-DD').valueOf())
    );
  };

  /**
   * @Author: YuKi
   * @description: 返回按钮
   * @return {*}
   */
  const goBackButton = () => {
    window.history.back();
  };

  const getProvicyItem = () => {
    getUserAgreement().then((rep: any) => {
      if (rep.code === 200 && rep.data) {
        let description = rep.data;
        setProvicyTitle(description.split('\n')[0]);
        setProvicyContent(description);
      }
    });
  };

  const handleProvicyCancel = () => {
    setIsProvicyOpen(false);
  };

  /**
   * @Author: YuKi
   * @description: 用户名市区焦点的时候的时候，给弹窗里面的内容赋值
   * @param {string} value
   * @param {*} option
   * @return {*}
   */
  const userNameChange = (e) => {
    informationConfirmedForm.setFieldValue('userName', e.target.value);
  };

  return (
    /*注册*/
    <>
      <div className="register-container">
        <Header flag="register" />
        {/* 公司logo */}
        <div className="logo-box">
          {language === 'zh' ? (
            <img src="/common/loginImages/new_logo.png" width="110" />
          ) : (
            <img src="/common/loginImages/new_logo_en.png" width="110" />
          )}
        </div>
        <div className="regist-form">
          <Form
            form={registerForm}
            name="registerForm"
            layout={'vertical'}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ userType: 'customer', languageCode: 'zh-CN', allowAutoUpdate: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            {/* 用户注册 */}
            <Row gutter={8}>
              <h2 className="username-register">{t('register.userRegister')}</h2>
            </Row>
            <Row gutter={8}>
              {/* 用户类别 */}
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={t('register.usertype')}
                  name="userType"
                  validateTrigger="onChange"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {/* 终端用户 */}
                    <Option value="customer" key="customer">
                      {t('common.customer')}
                    </Option>
                    {/* 安装商 */}
                    <Option value="installer" key="installer">
                      {t('common.installer')}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* 系统SN（终端用户） */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.userType !== currentValues.userType}
              >
                {({ getFieldValue }) =>
                  getFieldValue('userType') === 'customer' ? (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        name="sysSn"
                        label={t('common.SNKey')}
                        validateTrigger="onBlur"
                        rules={[
                          { required: true, message: `${t('common.snIsRequired')}`, whitespace: true },
                          {
                            validator: (rule, value) => {
                              if (value && value.trim() && value.trim().length > inputValueLimit.maxSn) {
                                return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxSn }));
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <Input allowClear placeholder={`${t('common.pleaseEntertheEystemSN')}`} onBlur={syncUpdate} />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>
              {/* SN校验码（终端用户） */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.userType !== currentValues.userType}
              >
                {({ getFieldValue }) =>
                  getFieldValue('userType') === 'customer' ? (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        name="snCheckCode"
                        label={t('register.snCheckCode')}
                        validateTrigger="onBlur"
                        rules={[
                          { required: true, message: `${t('register.snCheckRequired')}`, whitespace: true },
                          {
                            validator: (rule, value) => {
                              if (value && value.trim() && value.trim().length > inputValueLimit.maxSnCheckRequired) {
                                return Promise.reject(
                                  t('common.maxlengLimit', { maxLength: inputValueLimit.maxSnCheckRequired }),
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <Input allowClear placeholder={`${t('register.snCheckRequired')}`} />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>
              {/* License（安装商）*/}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.userType !== currentValues.userType}
              >
                {({ getFieldValue }) =>
                  getFieldValue('userType') === 'installer' ? (
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Form.Item
                        name="licenseNo"
                        label={t('register.license')}
                        validateTrigger="onBlur"
                        rules={[
                          { required: true, message: `${t('register.licenseIsRequired')}`, whitespace: true },
                          {
                            validator: (rule, value) => {
                              if (value && value.trim() && value.trim().length > inputValueLimit.maxLicense) {
                                return Promise.reject(
                                  t('common.maxlengLimit', { maxLength: inputValueLimit.maxLicense }),
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <Input allowClear placeholder={`${t('register.pleaseEnterLicense')}`} />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>
              {/* 用户名 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('register.username')}
                  name="userName"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: `${t('register.usernameRequired')}`, whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (
                          value &&
                          value.trim() &&
                          (value.trim().length < inputValueLimit.minEmail ||
                            value.trim().length > inputValueLimit.maxEmail)
                        ) {
                          return Promise.reject(t('register.usernameLimitLength_4_64'));
                        } else if (value && value.trim() && !emailReg.test(value.trim())) {
                          return Promise.reject(t('common.emailStyleError'));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input onBlur={userNameChange} allowClear placeholder="someone@example.com" />
                </Form.Item>
              </Col>
              {/* 邮编 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('common.postCode')}
                  name="postcode"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: `${t('common.postcodeIsRequired')}`, whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (
                          value &&
                          value.trim() &&
                          (value.trim().length < inputValueLimit.minPostcode ||
                            value.trim().length > inputValueLimit.maxPostcode)
                        ) {
                          return Promise.reject(t('common.postcodeLengthLimit'));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input allowClear placeholder={`${t('common.pleaseEnterZipCode')}`} />
                </Form.Item>
              </Col>
              {/* 密码 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('register.password')}
                  name="password"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: `${t('register.pwdIsRequired')}`, whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (value && value.trim() && (value.trim().length < 4 || value.trim().length > 20)) {
                          return Promise.reject(t('register.pwdLengthLimit'));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password allowClear placeholder={`${t('register.pleaseEnterPassword')}`} />
                </Form.Item>
              </Col>
              {/* 确认密码 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('register.confirmpassword')}
                  name="confirmPassword"
                  validateTrigger="onBlur"
                  dependencies={['password']}
                  rules={[
                    { required: true, message: `${t('resetPwd.confirmPwdIsRequired')}`, whitespace: true },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.trim()) {
                          if (
                            !getFieldValue('password') ||
                            !getFieldValue('password').trim() ||
                            getFieldValue('password').trim() !== value.trim()
                          ) {
                            return Promise.reject(t('resetPwd.confirmPwdIsError'));
                          } else if (
                            (value.trim().length < 4 || value.trim().length > 20) &&
                            getFieldValue('password') &&
                            getFieldValue('password').trim()
                          ) {
                            return Promise.reject(t('register.pwdLengthLimit'));
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password allowClear placeholder={`${t('register.pleaseEnterConfirmPassword')}`} />
                </Form.Item>
              </Col>
              {/* 语言 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="languageCode" label={t('common.language')} rules={[{ required: true }]}>
                  <Select>
                    {langTypeList &&
                      langTypeList.length > 0 &&
                      langTypeList.map((item: any, index) => {
                        return (
                          <Option value={item.lgCode} key={index}>
                            {item.lgName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              {/* 联系人 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('register.linkman')}
                  name="linkman"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: `${t('register.linkmanIsRequired')}`, whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (value && value.trim() && value.trim().length > inputValueLimit.maxContactName) {
                          return Promise.reject(
                            t('common.maxlengLimit', { maxLength: inputValueLimit.maxContactName }),
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input allowClear placeholder={`${t('register.pleaseEnterLinkMan')}`} />
                </Form.Item>
              </Col>
              {/* 国家/地区 */}
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  name="countryCode"
                  label={t('common.area')}
                  validateTrigger="onChange"
                  rules={[{ required: true, message: `${t('register.countryIsRequired')}` }]}
                >
                  <Select
                    showSearch
                    placeholder={`${t('common.pleaseSelectCountry_Region')}`}
                    onChange={handleAreaChange}
                    filterOption={(input, option) =>
                      (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {countryList &&
                      countryList.length > 0 &&
                      countryList.map((item: any, index) => {
                        return (
                          <Option value={item.areaId} key={index}>
                            {`${item.areaEnglishName}[${item.areaFirstName}]`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              {/* 省/州 */}
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={t('register.province')}
                  name="stateCode"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                          return Promise.reject(
                            t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    onChange={onStateChange}
                    allowClear
                    placeholder={`${t('register.pleaseSelectProvince_State')}`}
                  />
                </Form.Item>
              </Col>
              {/* 城市 */}
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={t('register.city')}
                  name="cityCode"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (value && value.trim() && value.trim().length > inputValueLimit.maxInputValueLeng) {
                          return Promise.reject(
                            t('common.maxlengLimit', { maxLength: inputValueLimit.maxInputValueLeng }),
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input onChange={cityChange} allowClear placeholder={`${t('register.pleaseSelectCity')}`} />
                </Form.Item>
              </Col>
              {/* 详细地址 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={
                    <Space wrap>
                      {t('common.address')}
                      <Tooltip title={t('register.addressTips')}>
                        <span>
                          <InfoCircleOutlined />
                        </span>
                      </Tooltip>
                    </Space>
                  }
                  name="address"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: `${t('register.pleaseEnterYourAddress')}`, whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (value && value.trim() && value.trim().length > inputValueLimit.maxAddressDetail) {
                          return Promise.reject(
                            t('common.maxlengLimit', { maxLength: inputValueLimit.maxAddressDetail }),
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input allowClear placeholder={`${t('register.pleaseEnterYourAddress')}`} />
                </Form.Item>
              </Col>
              {/* 电话 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={
                    <Space wrap>
                      {t('common.cellPhone')}
                      <Tooltip title={t('register.cellPhoneTips')}>
                        <span>
                          <InfoCircleOutlined />
                        </span>
                      </Tooltip>
                    </Space>
                  }
                  name="cellphone"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: `${t('common.phoneNumberIsRequired')}`, whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (
                          value &&
                          value.trim() &&
                          (value.trim().length < inputValueLimit.minTelephone ||
                            value.trim().length > inputValueLimit.maxTelephone)
                        ) {
                          return Promise.reject(t('common.phoneStyleIsError'));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input allowClear placeholder={`${t('common.pleaseEnterYourPhoneNumber')}`} />
                </Form.Item>
              </Col>
              {/* 时区 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.userType !== currentValues.userType || prevValues.timezone !== currentValues.timezone
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue('userType') === 'customer' ? (
                      <Spin spinning={isLoadingTimeZone} size="small">
                        <Form.Item
                          name="timezone"
                          label={t('register.systimezone')}
                          rules={[{ required: true, message: `${t('register.timezoneIsRequired')}` }]}
                        >
                          <Select
                            showSearch
                            allowClear
                            onChange={handleTimezoneChange}
                            disabled={isLoadingTimeZone}
                            placeholder={`${t('register.pleaseSelectTimeZone')}`}
                            filterOption={(input, option) =>
                              (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          >
                            {timeZonesList &&
                              timeZonesList.length > 0 &&
                              timeZonesList.map((item: any, index) => {
                                return (
                                  <Option value={item.standardName} key={index}>
                                    {item.displayName}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Spin>
                    ) : null
                  }
                </Form.Item>
              </Col>
              {/* 安装日期 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.userType !== currentValues.userType}
                >
                  {({ getFieldValue }) =>
                    getFieldValue('userType') === 'customer' ? (
                      <Form.Item
                        name="installDate"
                        label={
                          <Space wrap>
                            {t('register.installationDate')}
                            {insDateFlag === 2 ? (
                              <Tooltip title={t('register.eleFeePer')}>
                                <span>
                                  <InfoCircleOutlined />
                                </span>
                              </Tooltip>
                            ) : null}
                          </Space>
                        }
                        rules={[{ required: true, message: `${t('register.install_dateRequired')}` }]}
                      >
                        <DatePicker
                          allowClear
                          style={{ width: '100%' }}
                          placeholder={`${t('register.selectDate')}`}
                          disabledDate={disabledDate}
                          disabled={insDateFlag === 1 || insDateFlag === 2}
                        />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
              {/* 是否允许自动更新 */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.userType !== currentValues.userType}
              >
                {({ getFieldValue }) =>
                  getFieldValue('userType') === 'customer' ? (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item name="allowAutoUpdate" valuePropName="checked">
                        <Checkbox className="checked-style">
                          {t('register.autoupload')}
                          <Tooltip title={t('register.allowUpdates')}>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>
              {/* 隐私条款 */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="primaryPolicy"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(new Error(`${t('register.pleaseCheckPrivacyPolicy')}`)),
                    },
                  ]}
                >
                  <Checkbox className="checked-style">
                    <div>
                      {t('register.AgreeTerms')}
                      <a onClick={() => setIsProvicyOpen(true)}>{t('register.TermsOfService')}</a>
                      {t('register.and')}
                      <a href="https://www.alphaess.com/enPrivacyPolicy.html" target="_blank">
                        《{t('register.PrivacyPolicy')}》
                      </a>
                    </div>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item>
                  {/* 返回 */}
                  <Button style={{ width: '100%' }} key="goback" shape="round" htmlType="button" onClick={goBackButton}>
                    {t('common.goback')}
                  </Button>
                </Form.Item>
              </Col>
              {/* 提交 */}
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <Form.Item>
                  <Button style={{ width: '100%' }} type="primary" key="submit" htmlType="submit" shape="round">
                    {t('common.submitBtn')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Modal
          title={provicyTitle}
          open={isProvicyOpen}
          onOk={handleProvicyCancel}
          onCancel={handleProvicyCancel}
          style={{ width: '50%', minWidth: '200px', top: '20%' }}
          maskClosable={false}
          footer={[
            /* 提交 */
            <Button key="confirmBtn" shape="round" type="primary" onClick={handleProvicyCancel}>
              {t('common.confirmBtn')}
            </Button>,
          ]}
        >
          <div className="provicy-style">
            <pre>{provicyContent}</pre>
          </div>
        </Modal>
        <Modal
          title={t('register.infConfirmedTitle')}
          open={informationConfirmedFlag}
          wrapClassName="information-confirmed-form"
          style={{ width: '30%', minWidth: '200px', top: '20%' }}
          maskClosable={false}
          onCancel={() => setInformationConfirmedFlag(false)}
          footer={[
            /* 提交 */
            <Row justify="center">
              <Button key="confirmOKBtn" shape="round" type="primary" onClick={registerSubmit}>
                {t('common.ok')}
              </Button>
            </Row>,
          ]}
        >
          <Form
            form={informationConfirmedForm}
            name="informationConfirmedForm"
            layout={'vertical'}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
          >
            <Row gutter={8}>
              {/* 用户名 */}
              <Col span={24}>
                <Form.Item name="userName" label={t('register.username')}>
                  <TextArea autoSize disabled />
                </Form.Item>
              </Col>
              {/* 国家 */}
              <Col span={24}>
                <Form.Item name="countryCode" label={t('common.area')}>
                  <TextArea autoSize disabled />
                </Form.Item>
              </Col>
              {/* 时区 */}
              <Col span={24}>
                <Form.Item name="timezone" label={t('register.systimezone')}>
                  <TextArea autoSize disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <span className="information-tip">{t('register.infConfirmedTip')}</span>
        </Modal>
      </div>
    </>
  );
};

export default memo(Register);
