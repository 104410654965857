/** 业务线状态 */
export enum BusinessLineStatus {
  /** 户用储能 */
  householdEnergyStorage = 10,
  /** 消费者业务 */
  consumerBusiness = 20,
  /** 工商业储能 */
  industrialAndCommercialEnergyStorage = 30,
  /** 大项目储能 */
  largeProjectEnergyStorage = 40,
}

/* 分页获取责任归属方 接口传参 */
export interface listSearch {
  pageIndex: number /* 当前页 */;
  pageSize: number /* 每页显示条数 */;
  sysSn?: string /* sysSn */;
  businessLine?: BusinessLineStatus /* 业务线 */;
}

/* 新增编辑 接口传参 */
export interface listUpdate {
  id?: string /* 序号 */;
  businessLine: string /* 业务线 */;
  snList: string /* sysSn */;
}
