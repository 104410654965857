/*
 * @Description: 客户反馈->客诉详情页-> （非客户）客诉流程图
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2025/02/11
 */

import React, { memo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';
import * as d3 from 'd3';

interface MermaidChartProps {
  data: any;
  onOpen: (e) => void;
}

const MermaidChart: React.FC<MermaidChartProps> = ({ data, onOpen }) => {
  const { t } = useTranslation();
  const svgRef = useRef(null);
  const [treeWidth, setTreeWidth] = useState(0);
  const [treeHeight, setTreeHeight] = useState(0);

  useEffect(() => {
    const svgEd = d3.select(svgRef.current);
    svgEd.selectAll('*').remove(); // 清空<svg>容器中的内容
    // 创建一个树状布局
    const treeLayout = d3.tree().size([200]).nodeSize([150, 300]);

    // 将树形数据转换为层次结构数据
    const root = d3.hierarchy(data);
    const treeData = treeLayout(root);

    const lastNode = treeData.descendants()[treeData.descendants().length - 1];
    const lastNodeY = lastNode.y;
    // 获取最大的横坐标
    const maxX = d3.max(root.descendants(), (d) => d.x);
    setTreeWidth(lastNodeY);
    setTreeHeight(maxX);

    const svg = d3
      .select(svgRef.current)
      .append('g')
      .attr('transform', `translate(50, ${maxX < 100 ? maxX + 100 : maxX + 100})`); // 设置图表的偏移量

    // 创建连线生成器
    const linkGenerator = function (d) {
      return 'M' + d.source.y + ',' + d.source.x + 'V' + d.target.x + 'H' + d.target.y;
    };

    // // 创建连线生成器
    // const linkGeneratorLast = function (d) {
    //   const isLastNode = d.data === lastNode.data
    //   if (d.data.isChange) {
    //     const sourceX = d.x
    //     const sourceY = d.y
    //     /*
    //        M ${sourceY},${sourceX}表示将路径的起点移动到源点的位置。
    //        V ${cornerY}表示垂直绘制一条直线到拐弯点的纵坐标。
    //        H ${cornerX}表示水平绘制一条直线到拐弯点的横坐标。
    //        V ${targetY},${targetX}表示垂直绘制一条直线到目标点的位置。
    //     */

    // 绘制连线
    svg
      .selectAll('.link')
      .data(treeData.links())
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('fill', 'none')
      .attr('stroke', 'gray')
      .attr('stroke-width', 1)
      .attr('id', function (d, i) {
        return 'link-' + i; // 为每条连线添加唯一的ID
      })
      .attr('d', linkGenerator);

    // 添加连线上的文字
    svg
      .selectAll('.link-text')
      .data(treeData.links())
      .enter()
      .append('text')
      .attr('class', 'link-text')
      .attr('dy', -20) // 文字相对于连线的纵向偏移量
      .append('textPath')
      .attr('xlink:href', function (d, i) {
        return '#link-' + i; // 根据连线的索引为每个文本路径创建唯一的ID
      })
      .style('text-anchor', 'middle') // 设置文本的水平对齐方式为居中
      .style('dominant-baseline', 'middle') // 设置文本的垂直对齐方式为居中
      .attr('startOffset', '68%') // 文本路径的起始位置偏移量
      .style('fill', '#31313e') // 设置文本的颜色为红色
      .text(function (d) {
        return convertSeconds(d.target.data.finishTime); // 返回连线目标节点的文本内容
      });

    // 绘制节点
    const nodes = svg
      .selectAll('.node')
      .data(treeData.descendants())
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => `translate(${d.y},${d.x})`);

    nodes.append('rect').attr('width', 180).attr('height', 100).attr('x', -50).attr('y', -50).attr('fill', '#d9edf7');

    nodes
      .append('foreignObject')
      .attr('width', 180)
      .attr('height', 100)
      .attr('x', -50)
      .attr('y', -50)
      .html(
        (d) => `<div class="my-class"'>   
      <div>${d.data.currentStatusShowName}</div>
      <div>${d.data.processUser}</div>
      <div>${d.data.processingTime}</div></div>`,
      );

    nodes.on('click', handleClick);
  }, [data]);

  const handleClick = (event, d) => {
    // Handle the click event here
    onOpen(d);
  };

  /**
   * @description: 将秒换算成分钟，小时，天，月
   * @param {*} seconds
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const convertSeconds = (seconds) => {
    const oneMinute = 60;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const oneMonth = oneDay * 30; // 假设每个月为30天

    if (seconds < oneMinute) {
      return seconds + 'sec';
    } else if (seconds < oneHour) {
      const minutes = seconds / oneMinute;
      return formatValue(minutes) + 'min';
    } else if (seconds < oneDay) {
      const hours = seconds / oneHour;
      return formatValue(hours) + 'hr';
    } else {
      const days = seconds / oneDay;
      return formatValue(days) + 'days';
    }
  };

  /**
   * @description: 格式化值，如果没有小数则不显示小数，如果有则保留两位
   * @param {*} value
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const formatValue = (value) => {
    if (Number.isInteger(value)) {
      return value.toString();
    } else {
      return value.toFixed(2);
    }
  };

  return (
    <>
      <div className="svg-container">
        <svg ref={svgRef} width={treeWidth + 400} height={treeHeight + 200}></svg>
      </div>
    </>
  );
};

export default memo(MermaidChart);
