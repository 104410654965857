/*
 * @Description:数据权限编辑弹框
 * @Author: Summer.Chen
 * @Date: 2024-04-08 13:34:32
 * @LastEditTime: 2024-05-07 19:33:54
 * @LastEditors: Summer.Chen
 */
import type { TableRowSelection } from 'antd/es/table/interface';
import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Radio, Select, Table, Switch, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ModalProps } from 'antd/lib/modal';
import { setCol } from './../../config';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { queryOrganizeMenu, queryAdminList } from '@/api/authorityManagement';
import { RootStateOrAny, useSelector } from 'react-redux';
import { wordLimit } from '@/constants/wordLimit';
import { config } from '@/constants/config';
import { emailReg } from '@/constants/regRules';
import { modalParamsType, getinitialValues } from './../../config';
import { addOrg, editOrg, queryOrgDetail } from '@/api/authorityManagement';
import { encrypt, decrypt } from '@/constants/common';
import { useTranslation } from 'react-i18next';
import { convertSysLanFormatToThirdPartyFormat, getParents } from '@/utils/utils';
const splitDelimiter: string = '__';
const dateTimeStamp = Date.now();
interface DataType {
  key: React.ReactNode;
  name: string;
  age: number;
  address: string;
  children?: DataType[];
}
// 存储数据
let systemIdList: string[] = [];
let setDeptAdmin = false;
let storeValues: any = {};
let systemList: { systemId: string; count: boolean }[] = [];
const EitOrgComponentModal: React.FC<ModalProps & modalParamsType> = (props): React.ReactElement => {
  const { type, initData, deptId, open, parentId, onCancel, companyId } = props;
  const [isOrgAdmin, setIsOrgAdmin] = useState<boolean>(setDeptAdmin);
  const initialValues = getinitialValues(type === 1 ? 1 : null);
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let formatLang = convertSysLanFormatToThirdPartyFormat(language);
  //菜单权限配置选中像
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { t } = useTranslation();
  //创建表单
  const [form] = Form.useForm();
  const menuId: string[] = [];
  //获取国家列表
  const countryList = useSelector((state: RootStateOrAny) => state.get('app').get('countryList'));
  // 设置布局
  const getCol = setCol({ sm: 24, xs: 12, md: 12, lg: 12 });
  // 表头
  const columns: ColumnsType<DataType> = [
    {
      title: t('menuManage.menuName'),
      dataIndex: 'menuTitle',
      key: 'menuId',
      width: '50px',
      render: (text: string | null, record: any) => {
        return record && record.menuNameDic
          ? record.menuNameDic[formatLang]
            ? record.menuNameDic[formatLang]
            : text
          : text;
      },
    },
    {
      title: t('common.serialNumber'),
      dataIndex: 'address',
      width: '30%',
      key: 'address',
      render: (text: string, record: any, index: number) => {
        return index + 1;
      },
    },
  ];

  //手动选中和取消复选框
  const selectedRowKeysHandler = (source: any, selected: boolean, selectedRowKeyList: React.Key[]) => {
    source.forEach((element) => {
      //选择父级和子集
      if (selected && !selectedRowKeys.includes(element.tempMenuId)) {
        selectedRowKeyList.push(element.tempMenuId);
        //取消父级和子集
      } else if (!selected && selectedRowKeys.includes(element.tempMenuId)) {
        selectedRowKeyList.splice(selectedRowKeyList.indexOf(element.tempMenuId), 1);
      }
      if (element.children) {
        selectedRowKeysHandler(element.children, selected, selectedRowKeyList);
      }
    });
  };
  // rowSelection objects indicates the need for row selection
  const rowSelection: TableRowSelection<DataType> & { tempMenuId: React.Key } = {
    onSelect: (record, selected) => {
      selectedRowKeysHandler([record], selected, selectedRowKeys);
      const parentNodes = getParents(tableData, record.tempMenuId);
      for (let i = parentNodes.length - 1; i >= 0; i--) {
        if (!parentNodes[i].tempMenuId) {
          continue;
        }
        if (parentNodes[i].children && parentNodes[i].children.length) {
          let count = 0;
          parentNodes[i].children.forEach((item) => {
            // 有一个选中就取父节点
            if (selected) {
              if (selectedRowKeys.includes(item.tempMenuId) && !selectedRowKeys.includes(parentNodes[i].tempMenuId)) {
                selectedRowKeys.push(parentNodes[i].tempMenuId);
              }
              // 全部取消则取消相应父节点
            } else if (!selected) {
              if (!selectedRowKeys.includes(item.tempMenuId)) {
                count++;
              }
              if (count === parentNodes[i].children.length && selectedRowKeys.includes(parentNodes[i].tempMenuId)) {
                selectedRowKeys.splice(selectedRowKeys.indexOf(parentNodes[i].tempMenuId), 1);
              }
            }
          });
        }
      }
      setSelectedRowKeys([...selectedRowKeys]);
      form.setFieldValue('menuIds', selectedRowKeys);
    },
    hideSelectAll: true,
  };
  let defaultSelectedRowKeys: string[] = [];
  // const [defaultSelectedRowKeys,setDefaultSelectedRowKeys] = useState<string[]>([])
  // 表格数据
  const [tableData, setTableData] = useState<any>([]);
  //管理员下拉列表
  const [adminList, setAdminList] = useState<{ id: string; name: string }[]>([]);
  // 提交表单
  const submitForm = (values: any) => {
    let fun: (any) => Promise<Object>;
    if (type === 1) {
      fun = addOrg;
      values.parentId = parentId == 'All' ? '' : parentId;
      values.companyId = companyId;
    } else if (type === 2) {
      fun = editOrg;
      values.deptId = deptId;
      values.setDeptAdmin = setDeptAdmin;
    } else {
      return;
    }

    fun({
      ...values,
      menuIds: values.menuIds
        .filter((key: string) => !systemIdList.includes(key))
        .map((key: string) => key.split(splitDelimiter)[1]),
      userpwd: encrypt(values?.userpwd?.trim(), values?.username?.trim()),
    }).then((rep: any) => {
      if (rep && rep.code === 200) {
        message.success(t('common.operateSuccess'));
        //刷新右侧成员列表
        initData();
        onCancel();
      }
    });
  };
  // 递归获取默认选中的table的MenuId
  const recursionGetMenuId = (
    list: any[],
    keys: string[],
    systemId: string,
    systemList: { systemId: string; count: boolean }[],
  ) => {
    list.map((item) => {
      if (item.systemId) {
        systemId = item.systemId;
        item.tempMenuId = systemId + splitDelimiter + item.menuId + splitDelimiter + dateTimeStamp;
      } else {
        item.tempMenuId = systemId + splitDelimiter + item.menuId;
      }
      if (item.parentId === null || item.parentId === undefined) {
        item.parentId = systemId;
      }
      systemList.forEach((systemItem) => {
        if (!item.systemId && systemItem.systemId === systemId && item.isSelected) {
          systemItem.count = true;
        }
      });
      if (item.isSelected) {
        keys.push(item.tempMenuId);
      }
      if (item.children) {
        recursionGetMenuId(item.children, keys, systemId, systemList);
      }
    });
    return keys;
  };
  useEffect(() => {
    if (!deptId) {
      return;
    }
    if (!open) {
      // 重置数据
      systemIdList = [];
      systemList = [];
      defaultSelectedRowKeys = [];
      setSelectedRowKeys([]);
      return;
    }

    //组织新增
    if (type === 1) {
      queryOrganizeMenu({ deptId: deptId === 'All' ? '' : deptId }).then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          let temArr = rep.data.map((item) => {
            systemIdList.push(item.systemId + splitDelimiter + item.systemId + splitDelimiter + dateTimeStamp);
            systemList.push({ systemId: item.systemId, count: false });
            return {
              menuTitle: item.systemName,
              systemId: item.systemId,
              menuId: item.systemId,
              children: item.menuTreeList,
              parentId: 0,
            };
          });
          recursionGetMenuId(temArr, defaultSelectedRowKeys, '', systemList);
          systemList.forEach((item, index) => {
            if (item.count) {
              defaultSelectedRowKeys.push(systemIdList[index]);
            }
          });
          setSelectedRowKeys(defaultSelectedRowKeys);
          form.setFieldsValue({ ...initialValues, menuIds: defaultSelectedRowKeys });
          setTableData(temArr);
        }
      });
    }
    queryAdminList({}).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        setAdminList(rep.data);
      } else {
        setAdminList([]);
      }
    });
    setDeptAdmin = false;
    //组织编辑
    if (type === 2) {
      setIsOrgAdmin(false);
      queryOrgDetail({ deptId }).then((rep: any) => {
        if (rep && rep.code == 200 && rep.data) {
          if (rep.data.menuList) {
            let temArr = rep.data.menuList.map((item) => {
              systemIdList.push(item.systemId + splitDelimiter + item.systemId + splitDelimiter + dateTimeStamp);
              systemList.push({ systemId: item.systemId, count: false });
              return {
                parentId: 0,
                menuTitle: item.systemName,
                menuId: item.systemId,
                systemId: item.systemId,
                children: item.menuTreeList,
              };
            });
            recursionGetMenuId(temArr, defaultSelectedRowKeys, '', systemList);
            systemList.forEach((item, index) => {
              if (item.count) {
                defaultSelectedRowKeys.push(systemIdList[index]);
              }
            });
            form.setFieldValue('menuIds', defaultSelectedRowKeys);
            setSelectedRowKeys(defaultSelectedRowKeys);
            setTableData(temArr);
          } else {
            setTableData([]);
          }
          storeValues = {
            ...rep.data,
            userpwd: decrypt(rep?.data?.userpwd?.trim() ?? '', rep?.data?.username?.trim() ?? ''),
          };
          form.setFieldsValue({ ...storeValues, addUserFlag: null });
        }
      });
    }
  }, [props.open]);
  //设置管理员
  const setOrgAdminBtn = (value: boolean) => {
    setDeptAdmin = value;
    setIsOrgAdmin(value);
    if (!value) {
      form.setFieldValue('addUserFlag', null);
      const menuIds = form.getFieldValue('menuIds');
      form.setFieldsValue({ ...storeValues, menuIds });
    }
  };
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal
        {...props}
        onOk={(event) => {
          form.submit();
        }}
      >
        <Form layout={'vertical'} onFinish={submitForm} form={form} className="ant-form-item-mb-8">
          <Row
            gutter={25}
            onClick={(event) => {
              event.stopPropagation(); // 阻止事件冒泡
            }}
          >
            {/* 组织名称 */}
            <Col {...getCol}>
              <Form.Item
                name="deptName"
                label={t('authorityManagement.orgName')}
                rules={[
                  {
                    required: true,
                    message: t('authorityManagement.pleaseEnterOrganizationName') as string,
                  },
                ]}
              >
                <Input
                  allowClear
                  maxLength={wordLimit.nameInput}
                  placeholder={t('authorityManagement.pleaseEnterOrganizationName') as string}
                />
              </Form.Item>
            </Col>
            {/* 设置管理员 */}
            <Col {...getCol}>
              <Form.Item
                name="addUserFlag"
                label={
                  <>
                    <span>
                      {' '}
                      <span className="cus-red-star">*</span>
                      {t('authorityManagement.setUpAdministrator')}{' '}
                    </span>{' '}
                    {type === 2 ? <Switch size="small" onChange={setOrgAdminBtn} /> : null}
                  </>
                }
                rules={[
                  ({}) => ({
                    validator(_, value) {
                      if (type === 2 && isOrgAdmin && value === null) {
                        return Promise.reject(new Error(t('authorityManagement.pleaseSelectAdministrator') as string));
                      } else if (type === 1 && value === null) {
                        return Promise.reject(new Error(t('authorityManagement.pleaseSelectAdministrator') as string));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Radio.Group
                  disabled={type === 2 && !isOrgAdmin}
                  onChange={(event) => {
                    if (isOrgAdmin) {
                      form.setFieldsValue({
                        userType: null,
                        languagecode: null,
                        additionalFields: '',
                        additionalFields2: '',
                        countryId: '',
                        deptName: form.getFieldValue('deptName'),
                        email: '',
                        remark: '',
                        userId: null,
                        username: '',
                        userpwd: '',
                      });
                    }
                  }}
                >
                  <Radio value={1}>{t('authorityManagement.createAdministratorAccount')}</Radio>
                  <Radio value={0}>{t('authorityManagement.selectAdministrator')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {/* 管理员 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('userGroupManage.admin')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || userType === null || userType === undefined ? (
                    <Form.Item
                      name="username"
                      label={t('userGroupManage.admin')}
                      rules={[
                        {
                          required: true,
                          message: t('authorityManagement.pleaseEnterAdministrator') as string,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        disabled={type === 2 && !isOrgAdmin && form.getFieldValue('addUserFlag') === null}
                        maxLength={wordLimit.nameInput}
                        placeholder={t('authorityManagement.pleaseEnterAdministrator') as string}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item
                noStyle
                label={t('userGroupManage.admin')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 0 ? (
                    <Form.Item
                      name="userId"
                      label={t('userGroupManage.admin')}
                      rules={[
                        {
                          required: true,
                          message: t('authorityManagement.pleaseSelectAdministrator') as string,
                        },
                      ]}
                    >
                      <Select
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        allowClear
                        showSearch
                        placeholder={t('authorityManagement.pleaseSelectAdministrator') as string}
                        disabled={type === 2 && !isOrgAdmin}
                        options={adminList.map((item) => {
                          return { label: item.name, value: item.id };
                        })}
                      ></Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 选择角色 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('complaintList.roleName')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');

                  return userType === 1 || userType === null || userType === undefined ? (
                    <Form.Item
                      name="userType"
                      label={t('complaintList.roleName')}
                      rules={[
                        {
                          required: true,
                          message: t('register.userRoleRequired') as string,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder={t('register.userRoleRequired') as string}
                        options={[
                          { label: 'dasw', value: 'dasw' },
                          { label: 'servicer', value: 'servicer' },
                          { label: 'dev', value: 'dev' },
                        ]}
                      ></Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 密码 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('userGroupManage.password')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || userType === null || userType === undefined ? (
                    <Form.Item
                      name="userpwd"
                      label={t('userGroupManage.password')}
                      rules={[
                        {
                          required: true,
                          message: t('userGroupManage.pleaseEnterPassword') as string,
                        },
                      ]}
                    >
                      <Input.Password
                        allowClear
                        maxLength={wordLimit.nameInput}
                        placeholder={t('userGroupManage.pleaseEnterPassword') as string}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 邮箱 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('common.email')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || userType === null || userType === undefined ? (
                    <Form.Item
                      name="email"
                      label={t('common.email')}
                      rules={[
                        {
                          required: true,
                          message: t('licenseManagement.emailRequired') as string,
                        },
                        {
                          pattern: emailReg,
                          message: t('authorityManagement.enterCorrectFormatYourEmailAddress') as string,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        maxLength={wordLimit.nameInput}
                        placeholder={t('register.pleaseEnterEmailAddress') as string}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 国家 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('common.area')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || userType === null || userType === undefined ? (
                    <Form.Item
                      name="countryId"
                      label={t('common.area')}
                      rules={[
                        {
                          required: true,
                          message: t('common.pleaseSelectCountry_Region') as string,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder={t('common.pleaseSelectCountry_Region') as string}
                        options={countryList.map((item) => {
                          let obj = { label: '', value: '' };
                          obj.label = item.areaEnglishName + '(' + item.areaFirstName + ')';
                          obj.value = item.areaId;
                          return obj;
                        })}
                      ></Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 语言 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('yhxxsz.language')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || userType === null || userType === undefined ? (
                    <Form.Item
                      name="languagecode"
                      label={t('yhxxsz.language')}
                      rules={[
                        {
                          required: true,
                          message: t('common.pleaseSelectLanguage') as string,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        options={config.languageList}
                        placeholder={t('common.pleaseSelectLanguage') as string}
                      ></Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 售后账号工号 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('authorityManagement.afterSalesAccountNumber')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || userType === null || userType === undefined ? (
                    <Form.Item
                      name="additionalFields"
                      label={t('authorityManagement.afterSalesAccountNumber')}
                      rules={[
                        {
                          required: true,
                          message: t('authorityManagement.afterSalesAccountNumberRequired') as string,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        maxLength={wordLimit.nameInput}
                        placeholder={t('authorityManagement.pleaseEnterAfterSalesAccountNumber') as string}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 售后账号姓名 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('authorityManagement.afterSalesAccountName')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || userType === null || userType === undefined ? (
                    <Form.Item
                      name="additionalFields2"
                      label={t('authorityManagement.afterSalesAccountName')}
                      rules={[
                        {
                          required: true,
                          message: t('authorityManagement.afterSalesAccountNameRequired') as string,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        maxLength={wordLimit.nameInput}
                        placeholder={t('authorityManagement.pleaseEnterAfterSalesAccountName') as string}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 描述 */}
            <Col {...setCol({ sm: 24, xs: 24, md: 24, lg: 24 })}>
              <Form.Item
                name="remark"
                label={t('notificationContentTemplateManagement.description')}
                rules={[
                  {
                    required: true,
                    message: t('complaintList.describeInfoRequired') as string,
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={t('complaintList.describeInfoRequired') as string}
                  allowClear
                  rows={2}
                  showCount
                  maxLength={wordLimit.nameInput}
                />
              </Form.Item>
            </Col>
            {/* 菜单权限配置 */}
            <Col {...setCol({ sm: 24, xs: 24, md: 24, lg: 24 })}>
              <Form.Item
                name="menuIds"
                label={t('authorityManagement.menuPermissionConfiguration')}
                rules={[{ required: true, message: t('authorityManagement.pleaseSelectMenu') as string }]}
              >
                <Table
                  rowKey="tempMenuId"
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 480 }}
                  rowSelection={{
                    checkStrictly: true,
                    ...rowSelection,
                    selectedRowKeys,
                  }}
                  dataSource={tableData}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default EitOrgComponentModal;
