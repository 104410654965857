/*
 * @Description: 客诉列表
 * @Author: Victor
 * @Date: 2023/02/07
 * @LastEditors: Summer.Chen
 * @LastEditTime: 2024-03-15 15:29:13
 */
import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import CustomeComplaintPedding from './components/CustomeComplaintPedding';
import CustomeComplaintBeArchived from './components/CustomeComplaintBeArchived';
import CustomeComplaintAll from './components/CustomeComplaintAll';
import CustomeComplaintCrm from './components/CustomeComplaintCrm';
import BulkArchiving from './components/BulkArchiving';
import { useHistory, useLocation } from 'react-router-dom';
import { getCountryList } from '@/api/common';

const updateLogManagement: React.FC = () => {
  const { t } = useTranslation();
  const [updateLogType, setUpdateLogType] = useState('');
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  const history = useHistory();
  const location = useLocation();
  /* 国家列表源数据 */
  const [countryList, setCountryList] = useState<any>([]);

  useEffect(() => {
    if (roles.includes('installer') || roles.includes('reseller') || roles.includes('customer')) {
      setUpdateLogType('All');
    } else {
      setUpdateLogType('Pedding');
    }
    getCountryLists();
    let params: any = location.state;
    if (params) {
      switch (params.type) {
        case '1':
          setUpdateLogType('Pedding');
          break;
        case '2':
          setUpdateLogType('Archived');
          break;
        case '3':
          setUpdateLogType('All');
          break;
        case '4':
          setUpdateLogType('CRM');
          break;

        default:
          setUpdateLogType('All');
          break;
      }
    }
  }, []);

  /**
   * @description:
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getCountryLists = () => {
    getCountryList()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setCountryList([...rep.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch((err) => {
        setCountryList([]);
      });
  };
  /**
   * @description: 改变Tab
   * @param {string} key
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const onChange = (key: string) => {
    let state = '';
    history.replace({ ...history.location, state });
    setUpdateLogType(key);
  };
  //待处理
  let complaintPedding: any = {};
  {
    roles && !roles.includes('installer') && !roles.includes('customer') && !roles.includes('reseller')
      ? (complaintPedding = {
          label: t('complaintList.waitingHandler'),
          key: 'Pedding',
          children: updateLogType === 'Pedding' ? <CustomeComplaintPedding countryList={countryList} /> : null,
        })
      : [];
  }
  //待归档
  let complaintBeArchived: any = {};
  {
    roles && roles.includes('dasw')
      ? (complaintBeArchived = {
          label: t('complaintList.waitingArc'),
          key: 'Archived',
          children: updateLogType === 'Archived' ? <CustomeComplaintBeArchived countryList={countryList} /> : null,
        })
      : [];
  }
  //待归档
  let complaintAll: any = {};
  {
    complaintAll = {
      label: t('complaintList.allError'),
      key: 'All',
      children: updateLogType === 'All' ? <CustomeComplaintAll countryList={countryList} /> : null,
    };
  }
  //待CRM处理
  let complaintCRM: any = {};
  {
    roles && roles.includes('dasw')
      ? (complaintCRM = {
          label: t('complaintList.beforeCrmHandle'),
          key: 'CRM',
          children: updateLogType === 'CRM' ? <CustomeComplaintCrm countryList={countryList} /> : null,
        })
      : [];
  }
  //批量归档
  let bulkArchiving: any = {};
  {
    roles && roles.includes('dasw')
      ? (bulkArchiving = {
          label: t('complaintList.bulkArchiving'),
          key: 'bulkArchiving',
          children: updateLogType === 'bulkArchiving' ? <BulkArchiving /> : null,
        })
      : [];
  }

  return (
    <>
      <Tabs
        activeKey={updateLogType}
        defaultActiveKey="All"
        type="card"
        onChange={onChange}
        items={[complaintPedding, complaintBeArchived, complaintAll, complaintCRM, bulkArchiving]}
      />
    </>
  );
};
export default memo(updateLogManagement);
