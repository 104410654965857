/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/05/09 13:12:09
 * @LastEditTime: 2025/02/10
 */

import Login from '@/pages/Login';
import ForgetPassword from '@/pages/Login/Components/ForgetPassword';
import ForgetUserName from '@/pages/Login/Components/ForgetUserName';
import ForgetUserNameSecond from '@/pages/Login/Components/ForgetUserNameSecond';
import Register from '@/pages/Login/Components/Register';
import RegisterActive from '@/pages/Login/Components/RegisterActive';
import ResetPassword from '@/pages/Login/Components/ResetPassword';
import ShareRegister from '@/pages/Login/Components/ShareRegister';
import React from 'react';
import NewRgisterSecond from '@/pages/Login/Components/NewRegisterSecond';
import NoPage from '@/pages/NoPage';
import VppRegister from '@/pages/Login/Components/VppRegister';
import VppAssociatedSystem from '@/pages/Login/Components/VppAssociatedSystem';
import { config } from '@/constants/config.js';
import customerComplaintDetails from '@/pages/ClientList/CustomerComplaintDetails';
import emphasisComplaintListDetails from '@/pages/ClientList/EmphasisComplaintList/components/Details';
import historicalComplaintsDetails from '@/pages/ClientList/HistoricalComplaintsDetails';
import MoudleMaintenance from '@/pages/MaintenanceManagement/ArchivalMaintenance/MoudleMaintenance';
import ContentMaintenance from '@/pages/MaintenanceManagement/ArchivalMaintenance/ContentMaintenance';
import SysModelAnalysisReport from '@/pages/ReportCenter/Components/SysModelAnalysisReport';
import ComplaintAnalysiReport from '@/pages/ReportCenter/Components/ComplaintAnalysiReport';
import ArchiveAnalysisReports from '@/pages/ReportCenter/Components/ArchiveAnalysisReports';
import UserGroupWorkReport from '@/pages/ReportCenter/Components/UserGroupWorkReport';
import ProductRule from '@/pages/MaintenanceManagement/ProductRuleManagement/components/ProductRule';
import SysInstallNumber from '@/pages/ReportCenter/Components/SysInstallNumber';
import QualityCustomerComplaintStatisticalReport from '@/pages/ReportCenter/Components/QualityCustomerComplaintStatisticalReport';
import CustomerComplaintClosureRateReport from '@/pages/ReportCenter/Components/CustomerComplaintClosureRateReport';
import menu from '@/pages/Login/menu';
export function initLangage() {
  let lang = navigator.language;
  if (localStorage.getItem('language')) {
    return localStorage.getItem('language');
  } else {
    if (lang) {
      lang = lang.substring(0, 2).toLowerCase(); // 截取前两位字符，并转化为小写
      return lang;
    } else {
      return 'en';
    }
  }
}

/**
 * @description: 转换语言拼接后缀
 * @param {*} language
 * @return {*}
 * @LastEditors: Victor
 */
export function convertLangage() {
  let getLang = localStorage.getItem('language') || navigator.language; // 获取浏览器的语言环境，兼容IE的写法
  let result = '';
  switch (getLang) {
    case 'zh':
      result = 'zh-CN';
      break;
    case 'en':
      result = 'en-US';
      break;
    case 'de':
      result = 'de-DE';
      break;
    case 'ja':
      result = 'ja-JP';
      break;
    case 'it':
      result = 'it-IT';
      break;
    case 'nl':
      result = 'nl-NL';
      break;
    case 'sp':
      result = 'sp-SP';
      break;
    case 'el':
      result = 'el-GR';
      break;
    case 'fr':
      result = 'fr-FR';
      break;
    default:
      result = 'zh-CN';
  }
  return result;
}

// 富文本语言国际化
export function tinymceFormatLanguage(language) {
  let lang = '';
  switch (language) {
    case 'zh':
      lang = 'zh-Hans';
      break;
    case 'en':
      lang = 'en_GB';
      break;
    case 'de':
      lang = 'de';
      break;
    case 'ja':
      lang = 'ja';
      break;
    case 'it':
      lang = 'it';
      break;
    case 'nl':
      lang = 'nl';
      break;
    case 'sp':
      lang = 'sp';
      break;
    case 'el':
      lang = 'el';
      break;
    case 'fr':
      lang = 'fr';
      break;
    default:
      lang = 'zh-Hans';
  }
  return lang;
}

/**
 * @description: 模板类型key转换name
 * @return {*}
 * @LastEditors: Victor
 */
export function convertTemplateName(type: any) {
  let result = '';
  switch (type) {
    case 'Email':
      result = 'emailNotification';
      break;
    case 'SMS':
      result = 'smsNotification';
      break;
    case 'WeChat':
      result = 'weChatPush';
      break;
    case 'App':
      result = 'appNotification';
      break;
    default:
      result = 'appNotification';
  }
  return result;
}

/* 处理后端返回的菜单数据 */
export function handleMenuTree(arrTree: any, level: number, breadName?: string) {
  const res: any = [];
  let innerLevel: number = level;
  if (arrTree === '' || arrTree.length === 0) {
    return [];
  }
  arrTree.forEach((route) => {
    const tmp = { ...route };
    const name = innerLevel === 1 ? tmp.menuName : breadName;
    if ((tmp.componentUrl == null || tmp.componentUrl == '') && innerLevel === 1) {
      const routeItem = {
        key: tmp.menuUrl,
        path: tmp.menuUrl,
        name: tmp.menuName,
        alwaysShow: tmp.alwaysShow,
        icon: tmp.icon,
        id: tmp.id,
        menuNameDic: { ...tmp.menuNameDic },
        isShow: tmp.isDisplay === 1 ? true : false,
        title: tmp.menuTitle,
        menuType: tmp.menuType,
        component: React.lazy(() => import('@/layout')),
        children: tmp.children && tmp.children.length > 0 ? handleMenuTree(tmp.children, innerLevel + 1, name) : [],
      };
      res.push(routeItem);
    } else {
      if (tmp.menuType !== 2) {
        const routeItem = {
          path: tmp.menuUrl,
          key: tmp.menuUrl,
          name: tmp.menuName,
          alwaysShow: tmp.alwaysShow,
          icon: tmp.icon,
          id: tmp.id,
          menuNameDic: { ...tmp.menuNameDic },
          isShow: tmp.isDisplay === 1 ? true : false,
          title: tmp.menuTitle,
          menuType: tmp.menuType,
          component: () => {
            if (tmp.componentUrl == null || tmp.componentUrl === '') {
              return null;
            } else {
              return React.createElement(require('@/pages' + tmp.componentUrl.replace('@/pages', '')).default);
            }
          },
          children: tmp.children && tmp.children.length > 0 ? handleMenuTree(tmp.children, innerLevel + 1, name) : [],
        };
        if (routeItem.menuType === 0 || (routeItem.menuType === 1 && routeItem.component != null)) {
          res.push(routeItem);
        }
      } else {
        const routeItem = {
          path: tmp.menuUrl,
          key: tmp.menuUrl,
          name: tmp.menuName,
          alwaysShow: tmp.alwaysShow,
          icon: tmp.icon,
          id: tmp.id,
          menuNameDic: { ...tmp.menuNameDic },
          isShow: tmp.isDisplay === 1 ? true : false,
          title: tmp.menuTitle,
          menuType: tmp.menuType,
          component: null,
          children: tmp.children && tmp.children.length > 0 ? handleMenuTree(tmp.children, innerLevel + 1, name) : [],
        };
        if (routeItem.menuType === 2) {
          res.push(routeItem);
        }
      }
    }
  });
  return res;
}

export function searchMainMenu(arrTree: any, mainPageUrl: string) {
  if (arrTree === '' || arrTree.length === 0) {
    return false;
  }
  for (let i = 0; i < arrTree.length; i++) {
    if (arrTree[i].path === mainPageUrl) {
      return true;
    } else {
      if (arrTree[i].children && arrTree[i].children.length > 0) {
        return searchMainMenu(arrTree[i].children, mainPageUrl);
      }
    }
  }
}

export function searchFirstLeafNode(arrTree: any) {
  for (let i = 0; i < arrTree.length; i++) {
    if (arrTree[i].children.length === 0) {
      return arrTree[i].path;
    } else {
      return searchFirstLeafNode(arrTree[i].children);
    }
  }
}

/* 获取路由表 */
export function getRouters() {
  let queryString = window.location.search;
  let urlParams: any = '';
  if (new URLSearchParams(queryString)) {
    urlParams = new URLSearchParams(queryString).get('isNewVersion');
  }
  if (urlParams) {
    localStorage.setItem('isNewVersion', urlParams);
  }
  let isNewVersion = localStorage.getItem('isNewVersion');
  if (isNewVersion && isNewVersion === '0') {
    return (window.location.href = config.oldVersionUrl);
  }
  let backMenu: any = [];

  if (location.href.indexOf('AlphaCloudSSO') > -1) {
    localStorage.setItem('routers', JSON.stringify(menu.menu));
    backMenu = handleMenuTree(menu.menu, 1, '');
  } else {
    if (localStorage.getItem('routers')) {
      backMenu = handleMenuTree(JSON.parse(localStorage.getItem('routers')), 1, '');
    } else {
      backMenu = [];
    }
  }

  let routers = [...outerRouters, ...innerRouters, ...backMenu];
  return routers;
}

/* 外部静态页面 */
export const outerRouters = [
  /* 登录页OK */
  {
    path: '/login',
    component: Login,
    key: '/login',
    isShow: false,
    noPermission: true,
    menuname: 'login',
  },
  /* 注册页OK */
  {
    path: '/register',
    component: Register,
    key: '/register',
    isShow: false,
    noPermission: true,
    menuname: 'register',
  },
  /* 注册提交后跳转的页面 OK */
  {
    path: '/newRegisterSecond',
    component: NewRgisterSecond,
    key: '/newRegisterSecond',
    isShow: false,
    noPermission: true,
    menuname: 'newRegisterSecond',
  },
  /* 分享用户注册页面 OK */
  {
    path: '/shareRegister',
    component: ShareRegister,
    key: '/shareRegister',
    isShow: false,
    noPermission: true,
    menuname: 'shareRegister',
  },
  /* VPP用户注册页 */
  {
    path: '/vppRegister',
    component: VppRegister,
    key: '/vppRegister',
    isShow: false,
    noPermission: true,
    menuname: 'vppRegister',
  },
  /* vpp绑定设备 */
  {
    path: '/vppAssociatedSystem',
    component: VppAssociatedSystem,
    key: '/vppAssociatedSystem',
    isShow: false,
    noPermission: true,
    menuname: 'vppAssociatedSystem',
  },
  /* 忘记密码 OK */
  {
    path: '/forgetPassword',
    component: ForgetPassword,
    key: '/forgetPassword',
    isShow: false,
    noPermission: true,
    menuname: 'forgetPassword',
  },
  /* 重置密码 OK */
  {
    path: '/resetPassword',
    component: ResetPassword,
    key: '/resetPassword',
    isShow: false,
    noPermission: true,
    menuname: 'resetPassword',
  },
  /* 忘记用户名 OK */
  {
    path: '/forgetUserName',
    component: ForgetUserName,
    key: '/forgetUserName',
    isShow: false,
    noPermission: true,
    menuname: 'forgetUserName',
  },
  /* 注册成功-激活页-旧版 */
  {
    path: '/RegisterActive',
    component: RegisterActive,
    key: '/RegisterActive',
    isShow: false,
    noPermission: true,
    menuname: 'RegisterActive',
  },
  /* 注册成功-激活页-新版 */
  {
    path: '/RegisterStep',
    component: RegisterActive,
    key: '/RegisterActive',
    isShow: false,
    noPermission: true,
    menuname: 'RegisterActive',
  },
  /* 忘记用户名提交后跳转的页面 OK */
  {
    path: '/forgetUserNameSecond',
    component: ForgetUserNameSecond,
    key: '/forgetUserNameSecond',
    isShow: false,
    noPermission: true,
    menuname: 'forgetUserNameSecond',
  },
];

/* 内部跳转的静态页面 */
export const innerRouters = [
  {
    path: '/innerStatic',
    key: '/innerStatic',
    breadcrumbName: '',
    component: React.lazy(() => import('@/layout')),
    isShow: false,
    icon: '',
    hideChildrenInMenu: false,
    menuType: 0,
    id: 'innerStatic',
    title: 'innerStatic',
    menuname: 'innerStatic',
    children: [
      /* 重点客诉详情页 */
      {
        path: '/innerStatic/emphasisComplaintListDetails/index',
        key: '/innerStatic/emphasisComplaintListDetails/index',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'emphasisComplaintListDetails',
        title: '',
        component: emphasisComplaintListDetails,
        menuNameDic: {
          'zh-CN': '重点客诉详情',
          'en-US': 'Details of key customer complaints',
          'de-DE': 'Einzelheiten zu den wichtigsten Kundenbeschwerden',
          'it-IT': 'Dettagli dei principali reclami dei clienti',
          'ja-JP': '主な顧客苦情の詳細',
          'nl-NL': 'Details van belangrijke klachten van klanten',
          'sp-SP': 'Detalles de las principales quejas de los clientes',
          'el-GR': 'Λεπτομέρειες βασικών παραπόνων πελατών',
          'fr-FR': 'Détails des principales plaintes des clients',
        },
      },
      /* 客诉详情页 */
      {
        path: '/innerStatic/customerComplaintDetails/index',
        key: '/innerStatic/customerComplaintDetails/index',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'customerComplaintDetails',
        title: '',
        component: customerComplaintDetails,
        menuNameDic: {
          'zh-CN': '客诉详情',
          'en-US': 'Customer Complaint Details',
          'de-DE': 'Details zur Kundenbeschwerde',
          'it-IT': 'Dettagli del reclamo del cliente',
          'ja-JP': '顧客の苦情の詳細',
          'nl-NL': 'Details klantklacht',
          'sp-SP': 'Detalles de la queja del cliente',
          'el-GR': 'Λεπτομέρειες παραπόνων πελατών',
          'fr-FR': 'Détails de la plainte du client',
        },
      },
      /* 历史客诉详情页 */
      {
        path: '/innerStatic/historicalComplaintsDetails/index',
        key: '/innerStatic/historicalComplaintsDetails/index',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'historicalComplaintsDetails',
        title: '',
        component: historicalComplaintsDetails,
        menuNameDic: {
          'zh-CN': '历史客诉详情',
          'en-US': 'Customer Complaint Details',
          'de-DE': 'Details zur Kundenbeschwerde',
          'it-IT': 'Dettagli del reclamo del cliente',
          'ja-JP': '顧客の苦情の詳細',
          'nl-NL': 'Details klantklacht',
          'sp-SP': 'Detalles de la queja del cliente',
          'el-GR': 'Ιστορικές λεπτομέρειες παραπόνων πελατών',
          'fr-FR': 'Détails historiques des plaintes des clients',
        },
      },
      /* 模块维护页 */
      {
        path: '/innerStatic/MoudleMaintenance/index',
        key: '/innerStatic/MoudleMaintenance/index',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'MoudleMaintenance',
        title: '',
        component: MoudleMaintenance,
        menuNameDic: {
          'zh-CN': '模块维护',
          'en-US': 'Customer Complaint Details',
          'de-DE': 'Details zur Kundenbeschwerde',
          'it-IT': 'Dettagli del reclamo del cliente',
          'ja-JP': '顧客の苦情の詳細',
          'nl-NL': 'Details klantklacht',
          'sp-SP': 'Detalles de la queja del cliente',
          'el-GR': 'Συντήρηση ενότητας',
          'fr-FR': 'Entretien des modules',
        },
      },
      /*内容维护页 */
      {
        path: '/innerStatic/ContentMaintenance/index',
        key: '/innerStatic/ContentMaintenance/index',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'ContentMaintenance',
        title: '',
        component: ContentMaintenance,
        menuNameDic: {
          'zh-CN': '内容维护',
          'en-US': 'Customer Complaint Details',
          'de-DE': 'Details zur Kundenbeschwerde',
          'it-IT': 'Dettagli del reclamo del cliente',
          'ja-JP': '顧客の苦情の詳細',
          'nl-NL': 'Details klantklacht',
          'sp-SP': 'Detalles de la queja del cliente',
          'el-GR': 'Συντήρηση περιεχομένου',
          'fr-FR': 'Maintenance du contenu',
        },
      },
      /* 系统型号分析报表 */
      {
        path: '/innerStatic/ReportCenter/SysModelAnalysisReport',
        key: '/innerStatic/ReportCenter/SysModelAnalysisReport',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'SysModelAnalysisReport',
        title: '',
        component: SysModelAnalysisReport,
        menuNameDic: {
          'zh-CN': '系统型号分析报表',
          'en-US': 'System model analysis report',
          'de-DE': 'Bericht zur Systemmodellanalyse',
          'it-IT': 'Rapporto di analisi del modello di sistema',
          'ja-JP': 'システムモデル分析レポート',
          'nl-NL': 'Systeemmodelanalyserapport',
          'sp-SP': 'Informe de análisis del modelo del sistema.',
          'el-GR': 'Έκθεση ανάλυσης μοντέλου συστήματος',
          'fr-FR': 'Rapport d’analyse du modèle de système',
        },
      },
      /* 客诉分析报表 */
      {
        path: '/innerStatic/ReportCenter/ComplaintAnalysiReport',
        key: '/innerStatic/ReportCenter/ComplaintAnalysiReport',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'ComplaintAnalysiReport',
        title: '',
        component: ComplaintAnalysiReport,
        menuNameDic: {
          'zh-CN': '客诉分析报表',
          'en-US': 'Customer complaint analysis report',
          'de-DE': 'Analysebericht zur Kundenbeschwerde',
          'it-IT': 'Report di analisi dei reclami dei clienti',
          'ja-JP': '顧客苦情分析レポート',
          'nl-NL': 'Analyserapport van klantklachten',
          'sp-SP': 'Informe de análisis de quejas de clientes',
          'el-GR': 'Έκθεση ανάλυσης παραπόνων πελατών',
          'fr-FR': 'Rapport d’analyse des réclamations clients',
        },
      },
      /* 归档分析报表 */
      {
        path: '/innerStatic/ReportCenter/ArchiveAnalysisReports',
        key: '/innerStatic/ReportCenter/ArchiveAnalysisReports',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'ArchiveAnalysisReports',
        title: '',
        component: ArchiveAnalysisReports,
        menuNameDic: {
          'zh-CN': '归档分析报表',
          'en-US': 'Archive analysis reports',
          'de-DE': 'Analyseberichte archivieren',
          'it-IT': "Rapporti di analisi dell'archivio",
          'ja-JP': '分析レポートをアーカイブする',
          'nl-NL': 'Analyserapporten archiveren',
          'sp-SP': 'Informes de análisis de archivos',
          'el-GR': 'Αναφορές ανάλυσης αρχείων',
          'fr-FR': 'Archiver les rapports d’analyse',
        },
      },
      /* 用户组度量工时报表 */
      {
        path: '/innerStatic/ReportCenter/UserGroupWorkReport',
        key: '/innerStatic/ReportCenter/UserGroupWorkReport',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'UserGroupWorkReport',
        title: '',
        component: UserGroupWorkReport,
        menuNameDic: {
          'zh-CN': '用户组度量工时报表',
          'en-US': 'Archive analysis reports',
          'de-DE': 'Analyseberichte archivieren',
          'it-IT': "Rapporti di analisi dell'archivio",
          'ja-JP': '分析レポートをアーカイブする',
          'nl-NL': 'Analyserapporten archiveren',
          'sp-SP': 'Informes de análisis de archivos',
          'el-GR': 'Έκθεση εργασίας μετρημένης ομάδας χρηστών',
          'fr-FR': 'Rapport de travail mesuré par le groupe d’utilisateurs',
        },
      },
      /* 质量客诉统计报表*/
      {
        path: '/innerStatic/reportCenter/qualityCustomerComplaintStatisticalReport',
        key: '/innerStatic/reportCenter/qualityCustomerComplaintStatisticalReport',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'qualityCustomerComplaintStatisticalReport',
        title: '',
        component: QualityCustomerComplaintStatisticalReport,
        menuNameDic: {
          'zh-CN': '质量客诉统计报表',
          'en-US': 'Archive analysis reports',
          'de-DE': 'Analyseberichte archivieren',
          'it-IT': "Rapporti di analisi dell'archivio",
          'ja-JP': '分析レポートをアーカイブする',
          'nl-NL': 'Analyserapporten archiveren',
          'sp-SP': 'Informes de análisis de archivos',
          'el-GR': 'Ποιοτική στατιστική αναφορά παραπόνων πελατών',
          'fr-FR': 'Rapport statistique sur les plaintes des clients concernant la qualité',
        },
      },
      /* 客诉关闭率报表*/
      {
        path: '/innerStatic/reportCenter/customerComplaintClosureRateReport',
        key: '/innerStatic/reportCenter/customerComplaintClosureRateReport',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'qualityCustomerComplaintStatisticalReport',
        title: '',
        component: CustomerComplaintClosureRateReport,
        menuNameDic: {
          'zh-CN': '客诉关闭率报表',
          'en-US': 'Archive analysis reports',
          'de-DE': 'Analyseberichte archivieren',
          'it-IT': "Rapporti di analisi dell'archivio",
          'ja-JP': '分析レポートをアーカイブする',
          'nl-NL': 'Analyserapporten archiveren',
          'sp-SP': 'Informes de análisis de archivos',
          'el-GR': 'Αναφορά ποσοστού κλεισίματος παραπόνων πελατών',
          'fr-FR': 'Rapport sur le taux de clôture des réclamations clients',
        },
      },
      /* 产品规则 */
      {
        path: '/innerStatic/productRuleManagement/productRule',
        key: '/innerStatic/productRuleManagement/productRule',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'productRule',
        title: '',
        component: ProductRule,
        menuNameDic: {
          'zh-CN': '产品规则',
          'en-US': 'Product rules',
          'de-DE': 'Produktregel',
          'it-IT': 'Regole del prodotto',
          'ja-JP': '製品ルール',
          'nl-NL': 'Productregels',
          'sp-SP': 'Reglas del producto',
          'el-GR': 'κανόνες προϊόντος',
          'fr-FR': 'règles du produit',
        },
      },
      /* 系统装机数量/系统天分析 */
      {
        path: '/innerStatic/ReportCenter/SysInstallNumber',
        key: '/innerStatic/ReportCenter/SysInstallNumber',
        isShow: false,
        breadcrumbName: '',
        children: [],
        menu_type: 1,
        menuname: 'SysInstallNumber',
        title: '',
        component: SysInstallNumber,
        menuNameDic: {
          'zh-CN': '系统装机数量/系统天分析',
          'en-US': 'System installation quantity/Number of system installations',
          'de-DE': 'Systeminstallationen/Anzahl der Systeminstallationen',
          'it-IT': 'Quantità di installazioni di sistema/Numero di installazioni di sistema',
          'ja-JP': 'システムのインストール数 / システムのインストール数',
          'nl-NL': 'Aantal systeeminstallaties / Aantal geïnstalleerde systemen',
          'sp-SP': 'Cantidad de instalaciones del sistema / Número de instalaciones del sistema',
          'el-GR': 'Ανάλυση εγκατεστημένης ισχύος συστήματος/ημέρα συστήματος',
          'fr-FR': 'Analyse de la capacité installée du système/jour du système',
        },
      },
    ],
  },
  /* 路径错误页 */
  {
    path: '/404',
    key: '/404',
    component: React.lazy(() => import('@/layout')),
    breadcrumbName: '',
    hideChildrenInMenu: false,
    isShow: false,
    icon: '',
    menuType: 0,
    id: '404',
    title: '',
    menuname: 'error',
    children: [
      {
        path: '/404/index',
        key: '/404/index',
        isShow: false,
        children: [],
        menuType: 1,
        component: NoPage,
        breadcrumbName: '',
        menuname: 'error404',
        title: '',
      },
    ],
  },
];
