/*
 * @Description: 客户反馈->客诉详情页-> 中试测试用例评估表
 * @Author: Wayne Hu
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2024/06/24 13:19:08
 */

import React, { memo, useEffect } from 'react';
import { Button, Modal, Form, Input, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUrlQuery } from '@/utils/utils';
import { useHistory } from 'react-router-dom';
interface ChildProps {
  isOpen: any;
  onClose: () => void;
}

const AssignBlame: React.FC<ChildProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const { TextArea } = Input;

  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    let key: string = getUrlQuery('key');
    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
    }
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      /* 获取url中的客诉id */
      let key: string = getUrlQuery('key');
      if (key) {
        document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
      }
    });
  }, [history]);



  const saveData = () => {
    searchForm.validateFields().then((values) => {
      console.log(values);
    });
  };

  return (
    <>
      <Modal
        title={t('complaintList.tip')}
        open={isOpen}
        width="30%"
        maskClosable={false}
        onCancel={onClose}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={onClose}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            <Button key="save" shape="round" type="primary" onClick={saveData}>
              {t('remoteControlMenu.saveBtn')}
            </Button>
          </Row>,
        ]}
      >
        <Form
          layout="vertical"
          form={searchForm}
          name="complaintDetail"
          autoComplete="off"
          style={{ width: '100%' }}
          className=""
          disabled
        >
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.quesstionPart')}</span>}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.questionModule')}</span>}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.visits_numLabel')}</span>}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.handleType')}</span>}
                name="handleType"
              >
                <Select
                  placeholder={t('common.pleaseChoose')}
                  options={[
                    {
                      value: 0,
                      label: t('complaintList.remotely'),
                    },
                    {
                      value: 1,
                      label: t('complaintList.doorToDoor'),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.handleType !== currentValues.handleType}
              >
                {({ getFieldValue }) =>
                  getFieldValue('handleType') === 1 ? (
                    <Form.Item
                      name="customizeGender"
                      label={t('complaintList.visitsUsertype')}
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder={t('common.pleaseChoose')}
                        options={[
                          {
                            value: 0,
                            label: t('complaintList.installer'),
                          },
                          {
                            value: 1,
                            label: t('complaintList.afterSales'),
                          },
                          {
                            value: 2,
                            label: t('complaintList.none'),
                          },
                        ]}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.questionAnalysis')}</span>}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.updateCustomerComplaintProgress')}</span>}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
              <Form.Item
                required
                label={<span className="form-item-label">{t('complaintList.dutyType')}</span>}
                name="dutyType"
              >
                <Select
                  disabled={false}
                  placeholder={t('common.pleaseChoose')}
                  options={[
                    {
                      value: 0,
                      label: t('complaintList.clientSideProblem'),
                    },
                    {
                      value: 1,
                      label: t('complaintList.technicalSideProblem'),
                    },
                    {
                      value: 2,
                      label: t('complaintList.massEndProblem'),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.dutyType !== currentValues.dutyType}
              >
                {({ getFieldValue }) =>
                  getFieldValue('dutyType') !== 0 ? (
                    <Form.Item
                      name="group"
                      label={<span className="form-item-label">{t('complaintList.group')}</span>}
                      validateTrigger="onBlur"
                      rules={[{ required: true, message: `${t('common.pleaseChoose')}` }]}
                    >
                      <Select
                        disabled={false}
                        placeholder={t('common.pleaseChoose')}
                        options={[
                          {
                            value: 0,
                            label: t('complaintList.installer'),
                          },
                          {
                            value: 1,
                            label: t('complaintList.afterSales'),
                          },
                          {
                            value: 2,
                            label: t('complaintList.none'),
                          },
                        ]}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(AssignBlame);
