/*
 * @Description: 逆变器型号
 * @Author: Summer.Chen
 * @Date: 2024-04-08 16:16:43
 * @LastEditTime: 2024-04-25 09:35:15
 * @LastEditors: Summer.Chen
 */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Row, Col, Input, Space, Button, Table, Popconfirm, Modal, Radio, RadioChangeEvent, message } from 'antd';
import { setCol } from '../../config';
import { wordLimit } from '@/constants/wordLimit';
import { useTranslation } from 'react-i18next';
import { delSNCodeRulesOrInverterModel, queryInverterAuthByDeptId, addInverterAuth } from '@/api/authorityManagement';
let inverterModel: string = '';
const InverterModel: React.FC<any> = forwardRef((props, ref): React.ReactElement => {
  const { item, open, type, activeKey, radioChange } = props;
  const { t } = useTranslation();
  const refHandler = (activeKey: string) => {
    if (activeKey === '3') {
      if (SNRulesType === 1 || SNRulesType === 3) {
        formFinish({});
      }
    }
  };

  //父组件调取子组件方法
  useImperativeHandle(ref, () => ({
    refHandler,
  }));
  // 指定表格数据
  const [tableData, setTableData] = useState([]);
  const [SNRulesType, setSNRulesType] = useState(1);

  //弹框创建表单
  const [form] = Form.useForm();
  //创建搜索表单
  const [formSearch] = Form.useForm();
  //表头及定义action
  const columns = [
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      render: (text: string, recoder: any, index: number) => {
        return (page.pageIndex - 1) * page.pageSize + (index + 1);
      },
    },
    {
      title: t('projectManagement.InverterModel'),
      dataIndex: 'authName',
      key: 'authName',
    },
    {
      title: t('common.operation'),
      dataIndex: 'username',
      key: 'username',
      render: (text: string, record: any) => {
        return (
          <Popconfirm
            placement="rightTop"
            title={t('authorityManagement.areYouSureYouWantToDeleteThisRecord')}
            onConfirm={() => {
              confirm(record);
            }}
            okText={t('common.ok')}
            cancelButtonProps={{ shape: 'round' }}
            okButtonProps={{ shape: 'round' }}
            cancelText={t('common.cancelBtn')}
          >
            <Button type="text" danger disabled={type === 1}>
              {t('common.deleteBtn')}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];
  // 删除确认回调
  const confirm = (record) => {
    delSNCodeRulesOrInverterModel(record).then((rep) => {
      if (rep && rep.code === 200) {
        message.success(t('common.operateSuccess'));
        if (tableData.length === 1 && page.pageIndex > 1) {
          setPage({
            ...page,
            pageIndex: page.pageIndex - 1,
          });
        } else {
          initData();
        }
      }
    });
  };
  //是否显示控制范围
  const [isShowCheckRadio, setIsShowCheckRadio] = useState(true);
  // 分页
  const [page, setPage] = useState({ pageIndex: 1, pageSize: 10, total: 1 });
  const [tableLoading, setTableLoading] = useState(false);
  const pageChange = (current: number) => {
    setPage({
      ...page,
      pageIndex: current,
    });
  };
  //内部弹框控制
  const [isOpen, setIsOpen] = useState(false);
  //内部弹框确认
  const handleSNOk = () => {
    form.submit();
  };
  const formFinish = (values) => {
    addInverterAuth({
      deptId: item.deptId,
      authCategory: SNRulesType,
      inverterModel: values.inverterModel ? values.inverterModel.trim() : null,
    }).then((rep) => {
      if (rep && rep.code === 200) {
        message.success(t('common.operateSuccess'));
        //重置值
        inverterModel = '';
        formSearch.setFieldValue('inverterModel', '');
        // 区分目前的在全部还是指定
        if (page.pageIndex === 1) {
          initData();
        } else {
          setPage({
            ...page,
            pageIndex: 1,
          });
        }

        handleSNCancel();
      }
    });
  };
  //内部弹框取消
  const handleSNCancel = () => {
    form.setFieldValue('inverterModel', '');
    setIsOpen(false);
  };
  //范围选择
  const radioOnChange = (event: RadioChangeEvent) => {
    setSNRulesType(event.target.value);
  };
  const initData = () => {
    setTableLoading(true);
    queryInverterAuthByDeptId({
      deptId: item.deptId,
      ...page,
      inverterModel: formSearch.getFieldValue('inverterModel'),
    })
      .then((rep: any) => {
        //初始化值
        if (rep && rep.data) {
          setIsShowCheckRadio(Boolean(rep.data.isViewChoose));
        }
        if (rep && rep.code == 200 && rep.data && rep.data.sysDeptAuthList && rep.data.sysDeptAuthList.rows) {
          setTableData((rep.data.sysDeptAuthList.rows || []).filter((item) => item.authCategory === 2));
          setSNRulesType(
            rep.data.sysDeptAuthList.rows.some((item) => item.authCategory === 2) || SNRulesType === 2 ? 2 : 1,
          );
          setPage({
            ...page,
            total: rep.data.sysDeptAuthList.total || 1,
          });
        } else {
          setTableData([]);
          setSNRulesType(1);
        }
        if (rep.data && !Boolean(rep.data.isViewChoose)) {
          setSNRulesType(2);
        } else if (
          rep.code !== 200 ||
          !rep.data.sysDeptAuthList ||
          !rep.data.sysDeptAuthList.rows ||
          !rep.data.sysDeptAuthList.rows.length
        ) {
          setSNRulesType(3);
        }

        setTableLoading(false);
      })
      .catch((error) => {
        setSNRulesType(3);
      });
  };
  useEffect(() => {
    if (activeKey !== '3') {
      return;
    }
    if (!item.deptId) {
      return;
    }
    if (!open) {
      //初始化数据
      inverterModel = '';
      formSearch.setFieldValue('inverterModel', '');
      setSNRulesType(3);
      return;
    }
    initData();
  }, [open, page.pageIndex, activeKey]);
  //设置全部还是指定
  useEffect(() => {
    if (SNRulesType === 1 || SNRulesType === 3) {
      radioChange(true);
    } else if (SNRulesType === 2) {
      radioChange(false);
    }
  }, [SNRulesType]);
  return (
    <section>
      <Modal
        title={t('authorityManagement.newInverterModelAdded')}
        open={isOpen}
        onOk={handleSNOk}
        cancelButtonProps={{ shape: 'round' }}
        okButtonProps={{ shape: 'round' }}
        onCancel={handleSNCancel}
        okText={t('common.confirmBtn')}
        cancelText={t('common.cancelBtn')}
      >
        <Form layout={'vertical'} form={form} onFinish={formFinish}>
          <Form.Item
            label={t('projectManagement.InverterModel')}
            name="inverterModel"
            rules={[
              {
                required: true,
                message: t('authorityManagement.inverterModelRequired') as string,
              },
              ({}) => ({
                validator(_, value) {
                  if (value && value.trim() === '') {
                    return Promise.reject(new Error(t('authorityManagement.inverterModelRequired') as string));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              allowClear
              placeholder={t('chargingPileConfig.pleaseInputMinv') as string}
              maxLength={wordLimit.inverterModelInput}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Row gutter={[30, 30]}>
        {isShowCheckRadio ? (
          <Col span={24}>
            <div className="ant-form-item-label" style={{ marginBottom: '13px' }}>
              {t('authorityManagement.selectionRange')}
            </div>
            <br />
            <Radio.Group onChange={radioOnChange} value={SNRulesType} disabled={type === 1}>
              <Radio value={1}>{t('common.all')}</Radio>
              <Radio value={2}>{t('authorityManagement.specify')}</Radio>
              <Radio value={3}>{t('authorityManagement.nothing')}</Radio>
            </Radio.Group>
          </Col>
        ) : null}

        {SNRulesType === 2 ? (
          <>
            <Col span={24}>
              <Form layout={'vertical'} form={formSearch}>
                <Row gutter={15}>
                  <Col {...setCol({ sm: 24, xs: 24, md: 10, lg: 10 })}>
                    <Form.Item label={t('projectManagement.InverterModel')} name="inverterModel">
                      <Input
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          inverterModel = event.target.value;
                        }}
                        allowClear
                        placeholder={t('chargingPileConfig.pleaseInputMinv') as string}
                        maxLength={wordLimit.inverterModelInput}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...setCol({ sm: 24, xs: 24, md: 6, lg: 6 })}>
                    <Form.Item label={<span>&nbsp;</span>}>
                      <Space>
                        <Button
                          shape="round"
                          type="primary"
                          onClick={() => {
                            if (page.pageIndex === 1) {
                              initData();
                            } else {
                              setPage({
                                ...page,
                                pageIndex: 1,
                              });
                            }
                          }}
                        >
                          {t('common.searchBtn')}
                        </Button>
                        <Button
                          shape="round"
                          type="primary"
                          disabled={type === 1}
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          {t('common.addBtn')}
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={24}>
              <Table
                dataSource={tableData}
                size="small"
                columns={columns}
                loading={tableLoading}
                rowKey={(record: any) => record.key}
                pagination={{
                  showQuickJumper: true,
                  defaultCurrent: 1,
                  current: page.pageIndex,
                  total: page.total,
                  pageSize: page.pageSize,
                  onChange: pageChange,
                  size: 'small',
                }}
                className="custom-table-flex"
              />
            </Col>
          </>
        ) : null}
      </Row>
    </section>
  );
});

export default InverterModel;
