// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-Second-container {
  position: relative;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
.register-Second-container .center {
  text-align: center;
}
.register-Second-container .center p {
  font-size: 20px;
  font-weight: 700;
  margin-top: 73px;
  color: rgba(0, 0, 0, 0.65);
}
.register-Second-container .center p strong {
  color: #e8aa0d;
}
@media (min-width: 0) and (max-width: 640px) {
  .register-Second-container .logo-box {
    padding: 0px 20px;
    margin-top: 15px;
  }
}
@media (min-width: 640px) {
  .register-Second-container .logo-box {
    padding: 0px 20px;
    margin-top: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/Components/NewRegisterSecond/index.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AALA;EAMI,kBAAA;AAEJ;AARA;EAQM,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,0BAAA;AAGN;AAdA;EAaQ,cAAA;AAIR;AAEA;EACE;IAEI,iBAAA;IACA,gBAAA;EADJ;AACF;AAKA;EACE;IAEI,iBAAA;IACA,gBAAA;EAJJ;AACF","sourcesContent":[".register-Second-container {\n  position: relative;\n  min-height: 100vh;\n  max-width: 100vw;\n  overflow-x: hidden;\n  .center {\n    text-align: center;\n    p {\n      font-size: 20px;\n      font-weight: 700;\n      margin-top: 73px;\n      color: rgba(0, 0, 0, 0.65);\n      strong {\n        color: #e8aa0d;\n      }\n    }\n  }\n}\n\n@media (min-width: 0) and (max-width: 640px) {\n  .register-Second-container {\n    .logo-box {\n      padding: 0px 20px;\n      margin-top: 15px;\n    }\n  }\n}\n\n@media (min-width: 640px) {\n  .register-Second-container {\n    .logo-box {\n      padding: 0px 20px;\n      margin-top: 15px;\n    }\n  }\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
