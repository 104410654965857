/*
 * @Description:报表管理
 * @Author: April
 * @Date: 2023-04-10 10:32:05
 * @LastEditors: XuAnjie
 * @LastEditTime: 2024-03-22 16:00:32
 */
declare var echarts: any;
import { Card, Col, Row } from 'antd';
import React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './index.less';
const ReportCenter: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const titleArr: any = [
    { id: 1, name: t('reportCenter.sysModelAnalysisReport') },
    { id: 2, name: t('reportCenter.complaintAnalysisReport') },
    { id: 3, name: t('reportCenter.archiveAnalysisReports') },
    { id: 4, name: t('reportCenter.UsergroupMeasureReport') },
    { id: 5, name: t('productRule.sysInstallNumOrDay') },
    {
      id: 9,
      name: t('qualityStatisticalReport.qualityStatisticalReportTitle'),
      path: '/innerStatic/reportCenter/qualityCustomerComplaintStatisticalReport',
    },
    {
      id: 10,
      name: t('closureRateReport.closureRateReportTitle'),
      path: '/innerStatic/reportCenter/customerComplaintClosureRateReport',
    },
  ];
  /**
   * @description: 初始化图表数据
   * @return {*}
   * @LastEditors: April
   */
  const goToUrl = (id) => {
    if (history.location.pathname.indexOf('/innerStatic') < 0) {
      localStorage.setItem('pathname', history.location.pathname);
    }
    switch (id) {
      case 1:
        history.push({
          pathname: '/innerStatic/ReportCenter/SysModelAnalysisReport',
        });
        break;
      case 2:
        history.push({
          pathname: '/innerStatic/ReportCenter/ComplaintAnalysiReport',
        });
        break;
      case 3:
        history.push({
          pathname: '/innerStatic/ReportCenter/ArchiveAnalysisReports',
        });
        break;
      case 4:
        history.push({
          pathname: '/innerStatic/ReportCenter/UserGroupWorkReport',
        });
        break;
      case 5:
        history.push({
          pathname: '/innerStatic/ReportCenter/SysInstallNumber',
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="custom-contant">
        <Row gutter={20}>
          {titleArr.map((item: any) => {
            return (
              <Col span={6} key={item.id}>
                <Card
                  hoverable={true}
                  onClick={() => {
                    if (!item.path) {
                      goToUrl(item.id);
                    } else if (item.path) {
                      if (history.location.pathname.indexOf('/innerStatic') < 0) {
                        localStorage.setItem('pathname', history.location.pathname);
                      }
                      history.push(item.path);
                    }
                  }}
                >
                  <h3>{item.name}</h3>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};
export default memo(ReportCenter);
