/*
 * @Description: 组织树
 * @Author: Summer.Chen
 * @Date: 2024-04-08 20:43:56
 * @LastEditTime: 2024-05-07 16:02:14
 * @LastEditors: Summer.Chen
 */
import { Input, Tree, Space, Button } from 'antd';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { treeDataType, defaultNoData } from './../../config';
import { queryOrgTree } from '@/api/authorityManagement';
import './../../index.less';
import type { DataNode } from 'antd/es/tree';
import OrgTreeActionComponent from './orgTreeActionComponent';
import { useDebounce } from '@/hooks/useDebounce';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// 组织所属的公司Id
let companyId = '';
const { Search } = Input;
const dataList: treeDataType[] = [];
const OrgTree: React.FC<{
  treeClickCallback: (ReactNode, event) => void;
  getMembers: (deptId: string, hasMemberPerms: boolean, notGetMembers?: boolean) => void;
}> = (props) => {
  const { t } = useTranslation();
  const { treeClickCallback, getMembers } = props;
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  const language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  //关联子组件
  const childRef = useRef<{ newOrg: (treeDataType) => void }>(null);
  //初始化值
  const [defaultData, setDefaultData] = useState<treeDataType[]>([]);

  // 防抖
  const queryData = useDebounce(treeClickCallback, 400);

  //转换成key,title
  const generateList = (data: treeDataType[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { deptId, deptName } = node;
      dataList.push({ ...node, key: deptId as React.Key, title: deptName as string });
      if (node.children) {
        generateList(node.children);
      }
    }
  };

  // 组织树父节点
  const getParentKey = (key: React.Key, tree: treeDataType[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.deptId === key)) {
          parentKey = node.deptId as React.Key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  // 组织树展开的节点
  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  // 搜索
  const orgSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newExpandedKeys = dataList
      .map((item) => {
        if (item && item.title && item.title.indexOf(value) > -1) {
          return getParentKey(item.key as React.Key, defaultData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    setExpandedKeys(newExpandedKeys as React.Key[]);
    setSearchValue(value);
    setAutoExpandParent(true);
  };
  const initData = (deptId: string, hasMemberPerms: boolean, isGetMembers?: boolean) => {
    queryOrgTree({ deptName: '' }).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data && rep.data.treeList && rep.data.treeList.length) {
        companyId = rep.data.treeList[0].coId;
        setDefaultData(rep.data.treeList[0].coTreeList || [{ deptId: 'All', title: t('kpiReport.noData') }]);
        generateList(rep.data.treeList[0].coTreeList || [{ deptId: 'All', title: t('kpiReport.noData') }]);
      } else {
        setDefaultData(defaultNoData(t));
        generateList(defaultNoData(t));
      }
    });
    getMembers(deptId, hasMemberPerms, isGetMembers);
  };
  // admin 添加组织
  const rootAddOrg = () => {
    if (childRef && childRef.current && childRef.current.newOrg) {
      childRef.current.newOrg({
        deptId: 'All',
        open: true,
        title: t('authorityManagement.newOrganization'),
        type: 1,
        parentId: '',
        companyId: '',
      });
    }
  };
  useEffect(() => {
    initData('', false);
  }, []);
  //语言变化重新渲染组织树
  useEffect(() => {
    if (defaultData.length === 1 && defaultData[0].deptId === 'All') {
      setDefaultData([...defaultNoData(t)]);
      generateList([...defaultNoData(t)]);
    }
  }, [language]);
  // 组织树组件数据
  const treeData = useMemo(() => {
    const loop = (data: treeDataType[]): treeDataType[] =>
      data.map((item) => {
        const strTitle = item.deptName as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return { ...item, title, key: item.deptId as React.Key, children: loop(item.children) };
        }
        return {
          ...item,
          title,
          key: item.deptId as React.Key,
        };
      });

    return loop(defaultData);
  }, [searchValue, defaultData]);

  return (
    <div>
      <Space style={{ marginBottom: 8 }}>
        <Search placeholder={t('faultRecord.search') as string} onChange={orgSearchChange} />
        {roles.includes('admin') ? (
          <Button type="primary" shape="round" onClick={rootAddOrg}>
            {t('authorityManagement.newOrganization')}
          </Button>
        ) : null}
      </Space>

      <div>
        <h4> {t('authorityManagement.orgName')}</h4>
        {defaultData.length ? (
          <Tree
            onSelect={queryData}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            titleRender={(
              nodeData: DataNode &
                treeDataType & { initData: (deptId: string, hasAllPerms, boolean, notGetMembers?: boolean) => void },
            ) => {
              //自定义渲染title
              return (
                <OrgTreeActionComponent
                  ref={childRef}
                  {...nodeData}
                  companyId={companyId}
                  initData={(notGetMembers?: boolean) => {
                    initData(nodeData.deptId as string, nodeData.hasAllPerms as boolean, notGetMembers);
                  }}
                  getMembers={getMembers}
                />
              );
            }}
            treeData={treeData as []}
          />
        ) : null}
      </div>
    </div>
  );
};

export default OrgTree;
