/*
 * @Description:系统型号分析报表
 * @Author: April
 * @Date: 2023-04-10 10:32:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/12/10 11:32:37
 */
import { Button, Card, Col, DatePicker, Form, Row, Select, Space } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getComplaintsVolume, getMbatReplacement, getMinvReplacement, getWorkOrderVolume } from '@/api/reportCenter';
import { getCountryList } from '@/api/common';
import ChartComponent from '../BarComponent';
import '../../index.less';
import { useHistory } from 'react-router-dom';

let searchParams: any = {
  createBeginTime: '', // 客诉创建开始时间
  createEndTime: '', // 客诉创建结束时间
  archiveBeginTime: '', // 归档开始时间
  archiveEndTime: '', // 归档结束时间
  country: [], // 国家
  installBeginTime: '',
  installEndTime: '',
};

const SysModelAnalysisReport: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory(); //列表ref

  const { RangePicker } = DatePicker;
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));

  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 国家列表数据 */
  const [countryList, setCountryList] = useState<any>([]);
  let obj1 = {
    xData: [],
    seriesData1: {
      name: t('reportCenter.replaceQuantity'),
      data: [],
    },
    seriesData2: {
      name: t('reportCenter.replaceRate'),
      data: [],
    },
    title: t('reportCenter.inverterFailNum'),
  };
  let obj2 = {
    xData: [],
    seriesData1: {
      name: t('reportCenter.failQuantity'),
      data: [],
    },
    seriesData2: {
      name: t('reportCenter.failRate'),
      data: [],
    },
    title: t('reportCenter.batteryFailNum'),
  };
  let obj3 = {
    xData: [],
    seriesData1: {
      name: t('reportCenter.doorQuantity'),
      data: [],
    },
    seriesData2: {
      name: t('reportCenter.doorRate'),
      data: [],
    },
    title: t('reportCenter.systemDoorNum'),
  };
  let obj4 = {
    xData: [],
    seriesData1: {
      name: t('reportCenter.customerComplaintsQuantity'),
      data: [],
    },
    seriesData2: {
      name: t('reportCenter.customerComplaintsRate'),
      data: [],
    },
    title: t('reportCenter.customeComplaintNum'),
  };
  // 逆变器故障量/率
  const [data1, setData1] = useState<any>(obj1);
  // 电池故障量/率
  const [data2, setData2] = useState<any>(obj2);
  // 系统上门量/率
  const [data3, setData3] = useState<any>(obj3);
  // 系统型号客诉量/率
  const [data4, setData4] = useState<any>(obj4);

  // 监听语言重新渲染
  useEffect(() => {
    setData1((prevData) => ({
      ...prevData,
      seriesData1: {
        ...prevData.seriesData1,
        name: t('reportCenter.replaceQuantity'),
      },
      seriesData2: {
        ...prevData.seriesData2,
        name: t('reportCenter.replaceRate'),
      },
      title: t('reportCenter.inverterFailNum'),
    }));
    setData2((prevData) => ({
      ...prevData,
      seriesData1: {
        ...prevData.seriesData1,
        name: t('reportCenter.failQuantity'),
      },
      seriesData2: {
        ...prevData.seriesData2,
        name: t('reportCenter.failRate'),
      },
      title: t('reportCenter.batteryFailNum'),
    }));
    setData3((prevData) => ({
      ...prevData,
      seriesData1: {
        ...prevData.seriesData1,
        name: t('reportCenter.doorQuantity'),
      },
      seriesData2: {
        ...prevData.seriesData2,
        name: t('reportCenter.doorRate'),
      },
      title: t('reportCenter.systemDoorNum'),
    }));
    setData4((prevData) => ({
      ...prevData,
      seriesData1: {
        ...prevData.seriesData1,
        name: t('reportCenter.customerComplaintsQuantity'),
      },
      seriesData2: {
        ...prevData.seriesData2,
        name: t('reportCenter.customerComplaintsRate'),
      },
      title: t('reportCenter.customeComplaintNum'),
    }));
  }, [language]);

  // 初始化图表数据
  useEffect(() => {
    getCountrysList();
    getMinvData();
    getBatteryData();
    getWorkOrderData();
    getComplaintsData();
  }, []);

  /* 获取逆变器故障量/率 */
  const getMinvData = () => {
    let obj = { ...obj1 };
    getMinvReplacement(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          obj.xData = rep.data.minv;
          obj.seriesData1.data = rep.data.replacementVolume;
          obj.seriesData2.data = rep.data.replacementRate;
          setData1(obj);
        } else {
          setData1(obj);
        }
      })
      .catch((err) => {
        setData1(obj);
      });
  };
  /* 获取电池故障量/率 */
  const getBatteryData = () => {
    let obj = { ...obj2 };
    getMbatReplacement(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          obj.xData = rep.data.mbat;
          obj.seriesData1.data = rep.data.replacementVolume;
          obj.seriesData2.data = rep.data.replacementRate;
          setData2(obj);
        } else {
          setData2(obj);
        }
      })
      .catch((err) => {
        setData2(obj);
      });
  };
  /* 获取系统上门量/率 */
  const getWorkOrderData = () => {
    let obj = { ...obj3 };
    getWorkOrderVolume(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          obj.xData = rep.data.minv;
          obj.seriesData1.data = rep.data.workOrderVolume;
          obj.seriesData2.data = rep.data.workOrderRate;
          setData3(obj);
        } else {
          setData3(obj);
        }
      })
      .catch((err) => {
        setData3(obj);
      });
  };
  /* 获取系统型号客诉量/率 */
  const getComplaintsData = () => {
    let obj = { ...obj4 };
    getComplaintsVolume(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          obj.xData = rep.data.minv;
          obj.seriesData1.data = rep.data.complaintsVolume;
          obj.seriesData2.data = rep.data.complaintsRate;
          setData4(obj);
        } else {
          setData4(obj);
        }
      })
      .catch((err) => {
        setData4(obj);
      });
  };
  /* 查询图表 */
  const search = (values: any) => {
    const setFormattedDate = (value: any, param: string) => {
      return value ? moment(value).format('YYYY-MM-DD') : '';
    };
    const { dateRange, archiveDateRange, installRange, tempCountryList } = values;
    searchParams.createBeginTime = setFormattedDate(dateRange?.[0], 'YYYY-MM-DD');
    searchParams.createEndTime = setFormattedDate(dateRange?.[1], 'YYYY-MM-DD');
    searchParams.archiveBeginTime = setFormattedDate(archiveDateRange?.[0], 'YYYY-MM-DD');
    searchParams.archiveEndTime = setFormattedDate(archiveDateRange?.[1], 'YYYY-MM-DD');
    searchParams.installBeginTime = setFormattedDate(installRange?.[0], 'YYYY-MM-DD');
    searchParams.installEndTime = setFormattedDate(installRange?.[1], 'YYYY-MM-DD');
    searchParams.country = tempCountryList ? [...tempCountryList] : [];
    getMinvData();
    getBatteryData();
    getWorkOrderData();
    getComplaintsData();
  };
  /* 获取国家列表 */
  const getCountrysList = () => {
    getCountryList()
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          setCountryList([...res.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch(() => {
        setCountryList([]);
      });
  };

  /**
   * @description: 返回报表中心
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const goBackReport = () => {
    history.goBack();
  };
  return (
    <>
      <Card>
        <Form
          form={searchForm}
          name="searchForm"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12}>
            {/* 客诉创建时间 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="dateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('complaintList.complaintCreateBegin'), t('complaintList.complaintCreateEnd')]}
                  allowClear
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            {/* 归档时间 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="archiveDateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('reportCenter.archiveBeginPla'), t('reportCenter.archiveEndPla')]}
                  allowClear
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            {/* 安装时间 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="installRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('reportCenter.installStart'), t('reportCenter.installEnd')]}
                  allowClear
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              {/* 国家 */}
              <Form.Item name="tempCountryList">
                <Select
                  placeholder={`${t('createComplaint.country')}`}
                  maxTagCount="responsive"
                  allowClear
                  mode="multiple"
                  options={countryList?.map((it) => ({
                    value: it.areaEnglishName,
                    label: `${it.areaEnglishName}[${it.areaFirstName}]`,
                  }))}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* 操作按钮 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item>
                <Space size="middle" wrap>
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                </Space>
                {/* 返回 */}
                <Button style={{ float: 'right' }} type="primary" key="search" shape="round" onClick={goBackReport}>
                  {t('common.goback')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <ChartComponent data={data1} />
        <ChartComponent data={data2} />
        <ChartComponent data={data3} />
        <ChartComponent data={data4} />
      </Card>
    </>
  );
};
export default memo(SysModelAnalysisReport);
