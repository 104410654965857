/*
 * @Description: 质量客诉统计报表
 * @Author: Summer.Chen
 * @Date: 2024-03-13 15:46:31
 * @LastEditTime: 2024/12/10 13:16:43
 * @LastEditors: Please set LastEditors
 */
import React, { useState, useEffect, memo } from 'react';
import { Card, Button, Form, Select, Row, Col, DatePicker, Space, Table } from 'antd';
import { RootStateOrAny, useSelector } from 'react-redux';
import { colGroup } from './../../config';
import { getQualityColumns } from './../columns';
import { getQualityStatisticsReport, exportComplaintReportByGroup } from '@/api/reportCenter';
import { formatDateParams } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { blobDownloadFile } from '@/constants/common';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const initPageParams = {
  pageIndex: 1,
  pageSize: 10,
  total: 1,
};

const QualityCustomerComplaintStatisticalReport: React.FC = (): React.ReactElement => {
  let countryList = useSelector((state: RootStateOrAny) => state.get('app').get('countryList'));
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [paginationParams, setPaginationParams] = useState(initPageParams);
  const [params, setParams] = useState({
    archiveBegin: '',
    archiveEnd: '',
    countryCode: [],
    createBegin: '',
    createEnd: '',
    timeStamp: Date.now(),
    businessLine: 10,
  });
  const [form] = Form.useForm();
  // 条件查询
  const searchData = () => {
    let formData = form.getFieldsValue(true);
    setParams({
      countryCode: formData.country,
      archiveBegin: formData?.archiveDate?.[0],
      archiveEnd: formData?.archiveDate?.[1],
      createBegin: formData?.createDate?.[0],
      createEnd: formData?.createDate?.[1],
      timeStamp: Date.now(),
      businessLine: formData.businessLine,
    });
    setPaginationParams({
      ...paginationParams,
      pageIndex: 1,
    });
  };
  // 控制表格loading
  const [tableLoading, setTableLoading] = useState(false);
  // 数据变化获取数据
  useEffect(() => {
    setTableLoading(true);
    getQualityStatisticsReport({ ...formatDateParams(params), ...paginationParams })
      .then((rep: any) => {
        if (rep && rep.data && rep.code == 200 && rep.data.rows) {
          setTableData(rep.data.rows);
          setPaginationParams({
            ...paginationParams,
            total: rep.data.total || 1,
          });
        }
        setTableLoading(false);
      })
      .catch((error) => {
        setTableLoading(false);
      });
  }, [params, paginationParams.pageIndex]);
  //导出excel
  const exportReport = () => {
    //按钮loading 开启
    setDisabledBtn(true);
    const formData = form.getFieldsValue(true);
    const tempParams = {
      countryCode: formData.country,
      archiveBegin: formData?.archiveDate?.[0],
      archiveEnd: formData?.archiveDate?.[1],
      createBegin: formData?.createDate?.[0],
      createEnd: formData?.createDate?.[1],
      exportSource: 1,
    };
    exportComplaintReportByGroup(formatDateParams(tempParams, 'YYYY-MM-DD'))
      .then((res) => {
        //下载文件
        blobDownloadFile(res);
        setDisabledBtn(false);
      })
      .catch((error) => {
        console.error(error);
        setDisabledBtn(false);
      });
  };
  return (
    <Card
      title={t('qualityStatisticalReport.qualityStatisticalReportTitle')}
      extra={
        <Button
          shape={'round'}
          type={'primary'}
          onClick={() => {
            history.go(-1);
          }}
        >
          {t('common.goback')}
        </Button>
      }
    >
      {/* 搜索条件 */}
      <Form form={form}>
        <Row gutter={20}>
          <Col {...colGroup()}>
            {/* 国家 */}
            <Form.Item name="country">
              <Select
                mode="multiple"
                allowClear
                maxTagCount="responsive"
                placeholder={t('common.pleaseSelectCountry_Region')}
                options={countryList.map((item) => {
                  let tempObj = { label: '', value: '' };
                  tempObj.label = item.areaEnglishName + '(' + item.areaFirstName + ')';
                  tempObj.value = item.areaEnglishName;
                  return tempObj;
                })}
              ></Select>
            </Form.Item>
          </Col>
          <Col {...colGroup()}>
            {/* 创建时间范围 */}
            <Form.Item name="createDate">
              <RangePicker
                allowClear
                className="width-100-percent"
                placeholder={[t('complaintList.complaintCreateBegin'), t('complaintList.complaintCreateEnd')]}
                format={dateFormat}
              />
            </Form.Item>
          </Col>
          <Col {...colGroup()}>
            {/* 归档时间 */}
            <Form.Item name="archiveDate">
              <RangePicker
                allowClear
                className="width-100-percent"
                placeholder={[
                  t('qualityStatisticalReport.customerComplaintFilingTimeStart'),
                  t('qualityStatisticalReport.customerComplaintFilingTimeEnd'),
                ]}
                format={dateFormat}
              />
            </Form.Item>
          </Col>
          {/* 产品线 */}
          <Col xs={24} sm={8} md={8} lg={6} xl={6}>
            <Form.Item name="businessLine" initialValue={10}>
              <Select
                placeholder={`${t('archivalMaintenance.productLine')}`}
                options={[
                  { value: 10, label: t('archivalMaintenance.householdUse') },
                  { value: 20, label: t('createComplaint.consumerBusiness') },
                  { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                  { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* 按钮 */}
        <Space>
          <Button type="primary" shape={'round'} onClick={searchData}>
            {' '}
            {t('common.searchBtn')}{' '}
          </Button>
          <Button loading={disabledBtn} type="primary" shape={'round'} onClick={exportReport}>
            {' '}
            {t('common.export')}{' '}
          </Button>
        </Space>
      </Form>
      {/* 数据展示 */}
      <Table
        scroll={{ y: window.innerHeight - 400 }}
        sticky={true}
        loading={tableLoading}
        dataSource={tableData}
        columns={getQualityColumns(paginationParams)}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          ...paginationParams,
          current: paginationParams.pageIndex,
          onChange: (page: number) => {
            setPaginationParams({
              ...paginationParams,
              pageIndex: page,
            });
          },
        }}
      />
    </Card>
  );
};
export default memo(QualityCustomerComplaintStatisticalReport);
