/*
 * @Description: 关联分销商
 * @Author: Summer.Chen
 * @Date: 2024-04-08 16:16:43
 * @LastEditTime: 2024-04-24 17:34:15
 * @LastEditors: Summer.Chen
 */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { queryRelateReseller, editRelateReseller } from '@/api/authorityManagement';
import { useTranslation } from 'react-i18next';

// 定义数据类型
interface RecordType {
  key: string;
  title: string;
}
import { Radio, Transfer, Row, Col, Form, message } from 'antd';

const AssociatedDistributors: React.FC<any> = forwardRef((props, ref): React.ReactElement => {
  //控制选择范围

  const [showCheckRadio, setShowCheckRadio] = useState<boolean>(true);

  const [form] = Form.useForm();
  // 接受父级的参数
  let { item, open, type, activeKey } = props;
  const { t } = useTranslation();
  // 穿梭款左边的数据
  const [transferData, setTransferData] = useState<RecordType[]>([]);
  // 穿梭框右边的数据
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const onChange = (nextTargetKeys: string[]) => {
    form.setFieldValue('authNameIds', nextTargetKeys);
    setTargetKeys(nextTargetKeys);
  };
  //父组件调取子组件方法
  useImperativeHandle(ref, () => ({
    refHandler,
  }));
  const refHandler = (activeKey: string) => {
    if (activeKey === '4') {
      form.submit();
    }
  };
  // 提交表单
  const sumbitForm = (values) => {
    const authCategory = form.getFieldValue('authCategory');
    let resellerInfo: { id: string; name: string }[] = [];
    if (authCategory === 2) {
      resellerInfo = transferData
        .filter((item) => values.authNameIds.includes(item.key))
        .map((mapItem) => {
          return { id: mapItem.key, name: mapItem.title };
        });
    }
    editRelateReseller({ authCategory, resellerInfo, deptId: item.deptId }).then((rep) => {
      if (rep && rep.code == 200) {
        message.success(t('common.operateSuccess'));
      }
    });
  };
  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  // 穿梭框搜索
  const filterOption = (inputValue: string, option: RecordType) => option.title.indexOf(inputValue) > -1;
  // 弹框打开获取数据
  useEffect(() => {
    // 调取当前组件
    if (activeKey !== '4') {
      return;
    }
    if (!item.deptId) {
      return;
    }
    if (!open) {
      return;
    }
    queryRelateReseller({ deptId: item.deptId })
      .then((rep: any) => {
        if (rep && rep.code == 200 && rep.data) {
          // 过滤掉脏数据
          const targetArr = (rep.data.selfAuth ?? []).filter((item) => item.authCategory === 2);
          let data = [...rep.data.chooseArea, ...targetArr].map((item) => ({
            key: item.authNameId,
            title: item.authName,
          }));
          setTransferData(data);
          setTargetKeys(targetArr.map((item) => item.authNameId));
          if (rep.data.selfAuth) {
            // 回显checkRadio
            form.setFieldValue(
              'authNameIds',
              targetArr.map((selfAreaItem) => selfAreaItem.authNameId),
            );
            form.setFieldValue(
              'authCategory',
              rep.data.selfAuth.some((selfAreaItem) => selfAreaItem.authCategory === 2) ? 2 : 1,
            );
          } else if (rep.data.isSetting) {
            form.setFieldValue('authCategory', 1);
          }
        } else {
          // init Data
          setTransferData([]);
          setTargetKeys([]);
          form.setFieldValue('authCategory', 1);
        }
        // 重置authCategory
        if (rep.data && !rep.data.isViewChoose) {
          setShowCheckRadio(Boolean(rep.data.isViewChoose));
          form.setFieldValue('authCategory', 2);
        } else if (rep.code !== 200 || !rep.data.selfAuth || !rep.data.selfAuth.length) {
          form.setFieldValue('authCategory', 3);
        }
      })
      .catch((error) => {
        form.setFieldValue('authCategory', 3);
      });
  }, [open, activeKey]);
  return (
    <section>
      <Row gutter={[30, 30]}>
        <Form form={form} onFinish={sumbitForm} layout={'vertical'}>
          {/* all 还是 指定 */}
          {showCheckRadio ? (
            <Col span={24}>
              <Form.Item name="authCategory" label={t('authorityManagement.selectionRange')}>
                <Radio.Group disabled={type === 1}>
                  <Radio value={1}>{t('common.all')}</Radio>
                  <Radio value={2}>{t('authorityManagement.specify')}</Radio>
                  <Radio value={3}>{t('authorityManagement.nothing')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          ) : null}

          <Col span={24}>
            {/* 穿梭款 */}
            <Form.Item
              noStyle
              label={t('authorityManagement.chooseDistributor')}
              shouldUpdate={(prevValues, curValues) => prevValues.authCategory !== curValues.authCategory}
            >
              {({ getFieldValue }) => {
                const authCategory: number = getFieldValue('authCategory');

                return authCategory === 2 ? (
                  <Form.Item
                    name="authNameIds"
                    label=""
                    rules={[
                      {
                        required: true,
                        message: t('authorityManagement.chooseDistributor') as string,
                      },
                    ]}
                  >
                    <Transfer
                      showSearch
                      listStyle={{
                        width: 250,
                        height: 300,
                      }}
                      disabled={type === 1}
                      dataSource={transferData}
                      filterOption={filterOption}
                      targetKeys={targetKeys}
                      selectedKeys={selectedKeys}
                      onChange={onChange}
                      onSelectChange={onSelectChange}
                      render={(item) => item.title}
                      oneWay={true}
                    />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    </section>
  );
});

export default AssociatedDistributors;
