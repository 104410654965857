/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/09/06 17:12:28
 * @LastEditTime: 2023/09/08 17:09:49
 */

import { combineReducers } from 'redux-immutable';
import App from './app';
import User from './user';
export default combineReducers({
  app: App,
  user: User,
});
