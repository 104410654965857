import punctuation from '@/utils/punctuationFormatting';
import { unifiedDividingLine } from '@/utils/utils';
declare var echarts: any;
const colors = ['#5b9bd5', '#ed7d31', '#434348', '#90ed7d', '#666666', '#7baa2d'];
// 系统型号分析报表
export function sysOption(t: any, data: any, country: string) {
  return {
    title: {
      text: data.title, // 使用传入的标题
      left: 'center',
    },
    legend: {
      data: [data.seriesData1.name, data.seriesData2.name], // 使用传入的图例
      bottom: 5,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    xAxis: [
      {
        type: 'category',
        data: data.xData,
        axisPointer: {
          type: 'shadow',
        },
        axisLabel: {
          interval: 0, // 横轴信息全部显示
          rotate: 30, // -30度角倾斜显示
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
      },
      {
        type: 'value',
        min: 0,
        axisLabel: {
          formatter: '{value} %',
        },
      },
    ],
    series: [
      {
        name: data.seriesData1.name,
        type: 'bar',
        data: data.seriesData1.data,
        yAxisIndex: 0, // 指定使用第一个 Y 轴
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: colors[0], // 设置柱状图的颜色
        },
      },
      {
        name: data.seriesData2.name,
        type: 'line',
        data: data.seriesData2.data,
        yAxisIndex: 1, // 指定使用第二个 Y 轴
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: colors[1], // 设置折线图的颜色
        },
      },
    ],
  };
}
// 客诉分析报表
export function comOption(t: any, data: any, country: string) {
  return {
    title: {
      text: data.title, // 使用传入的标题
      left: 'center',
    },
    legend: {
      data: [data.seriesData.name], // 使用传入的图例
      bottom: 5,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '1%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: data.yData,
    },
    series: [
      {
        name: 'Num',
        type: 'bar',
        data: data.seriesData.data,
        itemStyle: {
          color: colors[0], // 设置柱状图的颜色
        },
      },
    ],
  };
}
// 归档分析报表
export function archOption(t: any, data: any, country: string) {
  return {
    title: {
      text: data.title,
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        let str = '';
        str = `<span>
          ${params.name},&nbsp;
          ${params.value},&nbsp;
          ${punctuation.punctuationFormatting(params.percent, country)}%
          </span>`;
        return str;
      },
    },
    legend: {
      bottom: 'bottom',
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: data.seriesData,
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          formatter: function (params) {
            return `${params.name}, ${params.value}, ${punctuation.punctuationFormatting(params.percent, country)}%`;
          },
        },
      },
    ],
  };
}
// 用户组度量工时报表折线图
export function userGroupOptionLine(t: any, data: any, country: string) {
  return {
    title: {
      text: data.title, // 使用传入的标题
      left: 'center',
    },
    legend: {
      data: [data.seriesData.name, data.seriesDataHandleTime.name, data.seriesDataAverageTime.name], // 使用传入的图例
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    xAxis: [
      {
        type: 'category',
        data: data.xData,
        axisPointer: {
          type: 'shadow',
        },
        axisLabel: {
          interval: 0, // 横轴信息全部显示
          rotate: 20, // -30度角倾斜显示
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: unifiedDividingLine(data.seriesData.data, data.seriesDataHandleTime.data, data.seriesDataAverageTime.data),
      },
      {
        type: 'value',
        min: 0,
        position: 'right',
        max: unifiedDividingLine(data.seriesData.data, data.seriesDataHandleTime.data, data.seriesDataAverageTime.data),
      },
    ],
    series: [
      {
        name: data.seriesData.name,
        type: 'line',
        data: data.seriesData.data,
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: colors[0], // 设置折线图的颜色
        },
      },
      {
        name: data.seriesDataHandleTime.name,
        type: 'line',
        data: data.seriesDataHandleTime.data,
        yAxisIndex: 1, // 指定使用右侧y轴
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: colors[2], // 设置折线图的颜色
        },
      },
      {
        name: data.seriesDataAverageTime.name,
        type: 'line',
        data: data.seriesDataAverageTime.data,
        yAxisIndex: 1, // 指定使用右侧y轴
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: colors[3], // 设置折线图的颜色
        },
      },
    ],
  };
}
// 用户组度量工时报表柱形图
export function userGroupOptionBar(t: any, data: any, country: string) {
  return {
    title: {
      text: data.title, // 使用传入的标题
      left: 'center',
    },
    legend: {
      data: [data.seriesData.name, data.seriesDataHandleTime.name, data.seriesDataAverageTime.name], // 使用传入的图例
      bottom: 0,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    xAxis: [
      {
        type: 'category',
        data: data.xData,
        axisPointer: {
          type: 'shadow',
        },
        axisLabel: {
          interval: 0, // 横轴信息全部显示
          rotate: 20, // -30度角倾斜显示
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: unifiedDividingLine(data.seriesData.data, data.seriesDataHandleTime.data, data.seriesDataAverageTime.data),
      },
      {
        type: 'value',
        min: 0,
        position: 'right',
        max: unifiedDividingLine(data.seriesData.data, data.seriesDataHandleTime.data, data.seriesDataAverageTime.data),
      },
    ],
    series: [
      {
        name: data.seriesData.name,
        type: 'bar',
        data: data.seriesData.data,
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: colors[0], // 设置折线图的颜色
        },
      },
      {
        name: data.seriesDataHandleTime.name,
        type: 'bar',
        data: data.seriesDataHandleTime.data,
        yAxisIndex: 1, // 指定使用右侧y轴
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: colors[2], // 设置折线图的颜色
        },
      },
      {
        name: data.seriesDataAverageTime.name,
        type: 'bar',
        data: data.seriesDataAverageTime.data,
        yAxisIndex: 1, // 指定使用右侧y轴
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: colors[3], // 设置折线图的颜色
        },
      },
    ],
  };
}
// 系统装机数量/系统天分析报表折线图
export function SysInstallNumberLine(t: any, data: any, country: string, color: string) {
  return {
    title: {
      text: data.title, // 使用传入的标题
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    xAxis: [
      {
        type: 'category',
        data: data.xData,
        axisPointer: {
          type: 'shadow',
        },
        axisLabel: {
          interval: 0, // 横轴信息全部显示
          rotate: 20, // -30度角倾斜显示
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
      },
    ],
    series: [
      {
        // name: data.seriesData.name,
        type: 'line',
        data: data.seriesData.data,
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: color, // 设置折线图的颜色
        },
      },
    ],
  };
}
// 系统装机数量/系统天分析报表柱形图
export function SysInstallNumberBar(t: any, data: any, country: string, color: string) {
  return {
    title: {
      text: data.title, // 使用传入的标题
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    xAxis: [
      {
        type: 'category',
        data: data.xData,
        axisPointer: {
          type: 'shadow',
        },
        axisLabel: {
          interval: 0, // 横轴信息全部显示
          rotate: 20, // -30度角倾斜显示
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
      },
    ],
    series: [
      {
        name: data.seriesData.name,
        type: 'bar',
        data: data.seriesData.data,
        tooltip: {
          valueFormatter: function (value) {
            return punctuation.punctuationFormatting(value, country);
          },
        },
        itemStyle: {
          color: color, // 设置折线图的颜色
        },
      },
    ],
  };
}
