/*
 * @Description:数据权限编辑弹框
 * @Author: Summer.Chen
 * @Date: 2024-04-08 13:34:32
 * @LastEditTime: 2024-04-22 11:10:15
 * @LastEditors: Summer.Chen
 */

import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Radio, Select, message } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { setCol } from './../../config';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { queryAdminList } from '@/api/authorityManagement';
import { RootStateOrAny, useSelector } from 'react-redux';
import { wordLimit } from '@/constants/wordLimit';
import { config } from '@/constants/config';
import { emailReg } from '@/constants/regRules';
import { modalParamsType, getinitialValues } from './../../config';
import { addMember, editMember } from '@/api/authorityManagement';
import { encrypt, decrypt } from '@/constants/common';
import { useTranslation } from 'react-i18next';
const EditMembComponentModal: React.FC<ModalProps & modalParamsType> = (props): React.ReactElement => {
  const { type, initData, deptId, open, deptName, onCancel, record, hasMemberPerms } = props;
  const initialValues = getinitialValues(1, deptName as string);
  const { t } = useTranslation();
  //创建表单
  const [form] = Form.useForm();
  const menuId: string[] = [];
  //获取国家列表
  const countryList = useSelector((state: RootStateOrAny) => state.get('app').get('countryList'));
  // 设置布局
  const getCol = setCol({ sm: 24, xs: 12, md: 12, lg: 12 });
  //管理员下拉列表
  const [memberList, setAdminList] = useState<{ id: string; name: string }[]>([]);
  // 提交表单
  const submitForm = (values: any) => {
    let fun: (any) => Promise<Object>;
    let params = {};
    if (type === 1) {
      fun = addMember;
      params = { ...values, deptId, userpwd: encrypt(values?.userpwd?.trim(), values?.username?.trim()) };
    } else if (type === 2) {
      fun = editMember;
      params = {
        ...values,
        userId: record?.userId,
        deptId,
        userpwd: encrypt(values?.userpwd?.trim(), values?.username?.trim()),
      };
    } else {
      return;
    }
    fun(params).then((rep: any) => {
      if (rep && rep.code === 200) {
        message.success(t('common.operateSuccess'));
        initData(deptId, hasMemberPerms);
        onCancel();
      }
    });
  };
  // 递归获取默认选中的table的MenuId
  const recursionGetMenuId = (list: any[], keys: string[]) => {
    list.map((item) => {
      if (item.isSelected) {
        keys.push(item.menuId);
      }
      if (item.children) {
        recursionGetMenuId(item.children, keys);
      }
    });
    return keys;
  };
  useEffect(() => {
    if (!deptId) {
      return;
    }
    if (!open) {
      return;
    }
    queryAdminList({}).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        setAdminList(rep.data);
      } else {
        setAdminList([]);
      }
    });
    if (type === 1) {
      form.setFieldsValue(initialValues);
    } else {
      form.setFieldsValue({
        ...record,
        userpwd: decrypt(record?.userpwd?.trim() ?? '', record?.username?.trim() ?? ''),
      });
    }
  }, [props.open]);
  return (
    <>
      <Modal
        {...props}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          layout={'vertical'}
          onFinish={submitForm}
          form={form}
          disabled={type === 3}
          className="ant-form-item-mb-8"
        >
          <Row
            gutter={25}
            onClick={(event) => {
              event.stopPropagation(); // 阻止事件冒泡
            }}
          >
            {/* 组织名称 */}
            <Col {...getCol}>
              <Form.Item
                name="deptName"
                label={t('authorityManagement.orgName') as string}
                rules={[
                  {
                    required: true,
                    message: t('authorityManagement.pleaseEnterOrganizationName') as string,
                  },
                ]}
              >
                <Input allowClear disabled maxLength={wordLimit.nameInput} />
              </Form.Item>
            </Col>
            {/* 设置组员 */}
            <Col {...getCol}>
              {type === 1 ? (
                <Form.Item
                  name="addUserFlag"
                  label={t('authorityManagement.setTeamMembers') as string}
                  rules={[
                    {
                      required: true,
                      message: t('authorityManagement.pleaseSetTeamMembers') as string,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={1}>{t('authorityManagement.createTeamMemberAccount')}</Radio>
                    <Radio value={0}>{t('authorityManagement.selectTeamMembers')}</Radio>
                  </Radio.Group>
                </Form.Item>
              ) : (
                <span>&nbsp;</span>
              )}
            </Col>
            {/* 组员账号 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('authorityManagement.groupMemberAccount')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');

                  return userType === 1 || type === 2 || type === 3 ? (
                    <Form.Item
                      name="username"
                      label={t('authorityManagement.groupMemberAccount')}
                      rules={[
                        {
                          required: true,
                          message: t('authorityManagement.pleaseEnterTeamMemberAccount') as string,
                        },
                      ]}
                    >
                      <Input allowClear disabled={type === 2 || type === 3} maxLength={wordLimit.nameInput} />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item
                noStyle
                label={t('authorityManagement.groupMemberAccount')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 0 ? (
                    <Form.Item
                      name="userId"
                      label={t('authorityManagement.groupMemberAccount')}
                      rules={[
                        {
                          required: true,
                          message: t('authorityManagement.pleaseSelectMemberAccount') as string,
                        },
                      ]}
                    >
                      <Select
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        allowClear
                        showSearch
                        placeholder={t('authorityManagement.pleaseSelectMemberAccount') as string}
                        options={memberList.map((item) => {
                          return { label: item.name, value: item.id };
                        })}
                      ></Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 选择角色 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('complaintList.roleName')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || type === 2 || type === 3 ? (
                    <Form.Item
                      name="userType"
                      label={t('complaintList.roleName')}
                      rules={[
                        {
                          required: true,
                          message: t('register.userRoleRequired') as string,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder={t('register.userRoleRequired') as string}
                        options={[
                          { label: 'dasw', value: 'dasw' },
                          { label: 'servicer', value: 'servicer' },
                          { label: 'dev', value: 'dev' },
                        ]}
                      ></Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 密码 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('yhgl.password')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || type === 2 || type === 3 ? (
                    <Form.Item
                      name="userpwd"
                      label={t('yhgl.password')}
                      rules={[
                        {
                          required: true,
                          message: t('userGroupManage.pleaseEnterPassword') as string,
                        },
                      ]}
                    >
                      <Input.Password
                        allowClear
                        maxLength={wordLimit.nameInput}
                        placeholder={t('userGroupManage.pleaseEnterPassword') as string}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 邮箱 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('userGroupManage.pleaseEnterPassword') as string}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || type === 2 || type === 3 ? (
                    <Form.Item
                      name="email"
                      label={t('common.email') as string}
                      rules={[
                        {
                          required: true,
                          message: t('licenseManagement.emailRequired') as string,
                        },
                        {
                          pattern: emailReg,
                          message: t('authorityManagement.enterCorrectFormatYourEmailAddress') as string,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        maxLength={wordLimit.nameInput}
                        placeholder={t('register.pleaseEnterEmailAddress') as string}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 国家 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('common.area')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || type === 2 || type === 3 ? (
                    <Form.Item
                      name="countryId"
                      label={t('common.area')}
                      rules={[
                        {
                          required: true,
                          message: t('common.pleaseSelectCountry_Region') as string,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder={t('common.pleaseSelectCountry_Region') as string}
                        options={countryList.map((item) => {
                          let obj = { label: '', value: '' };
                          obj.label = item.areaEnglishName + '(' + item.areaFirstName + ')';
                          obj.value = item.areaId;
                          return obj;
                        })}
                      ></Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 语言 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('yhxxsz.language')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || type === 2 || type === 3 ? (
                    <Form.Item
                      name="languagecode"
                      label={t('yhxxsz.language')}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder={t('common.pleaseSelectLanguage')}
                        allowClear
                        options={config.languageList}
                      ></Select>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 售后账号工号 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('authorityManagement.afterSalesAccountNumber')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || type === 2 || type === 3 ? (
                    <Form.Item
                      name="additionalFields"
                      label={t('authorityManagement.afterSalesAccountNumber')}
                      rules={[
                        {
                          required: true,
                          message: t('authorityManagement.afterSalesAccountNumberRequired') as string,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        maxLength={wordLimit.nameInput}
                        placeholder={t('authorityManagement.pleaseEnterAfterSalesAccountNumber') as string}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            {/* 售后账号姓名 */}
            <Col {...getCol}>
              <Form.Item
                noStyle
                label={t('authorityManagement.afterSalesAccountNumber')}
                shouldUpdate={(prevValues, curValues) => prevValues.addUserFlag !== curValues.addUserFlag}
              >
                {({ getFieldValue }) => {
                  const userType: number = getFieldValue('addUserFlag');
                  return userType === 1 || type === 2 || type === 3 ? (
                    <Form.Item
                      name="additionalFields2"
                      label={t('authorityManagement.afterSalesAccountName')}
                      rules={[
                        {
                          required: true,
                          message: t('authorityManagement.afterSalesAccountNameRequired') as string,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        maxLength={wordLimit.nameInput}
                        placeholder={t('authorityManagement.pleaseEnterAfterSalesAccountName') as string}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EditMembComponentModal;
