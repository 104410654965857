/*
 * @Description:忘记密码提交成功后的页面
 * @Author: YuKi
 * @Date: 2022/11/03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/09/13 15:38:05
 */
import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useSelector, RootStateOrAny } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../Header';
import './index.less';
const ForgetUserNameSecond: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 城市 */
  const [email, setEmail] = useState<any>('');

  /**
   * @Author: YuKi
   * @description: 初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    if (location && location.state) {
      let state: any = location.state;
      setEmail(state.email);
    }
  }, []);

  /**
   * @Author: YuKi
   * @description: 返回按钮
   * @return {*}
   */
  const goBackButton = () => {
    window.location.href = '/login';
  };

  return (
    /*注册*/
    <>
      <div className="forget-username-Second-container">
        <Header flag="forget-username-Second" />
        {/* 公司logo */}
        <div className="logo-box">
          {language === 'zh' ? (
            <img src="/common/loginImages/new_logo.png" width="110" />
          ) : (
            <img src="/common/loginImages/new_logo_en.png" width="110" />
          )}
        </div>
        <div className="center">
          <p>
            <img src="/common/loginImages/email-send.svg" />
          </p>
          <p>
            {t('loginLang.usernameHasBeenSent')}:<strong>{email}</strong>
          </p>
        </div>
        <div className="center" style={{ marginTop: '10px' }}>
          <Button type="primary" key="goback" shape="round" onClick={goBackButton}>
            {t('register.goLogin')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(ForgetUserNameSecond);
