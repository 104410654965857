/*
 * @Description: 创建客诉页面
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/02/20 10:34:13
 * @LastEditTime: 2025/01/21
 */

import React, { memo, useState, useEffect, useRef } from 'react';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Space,
  message,
  Select,
  Tooltip,
  DatePicker,
  Checkbox,
  Spin,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { ExclamationCircleFilled, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { createComplaint, getSnBasicData, getSnList, apiGetCustSummary } from '@/api/clientFeedback/createComplaint';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FileUploadNew from '@/components/fileUploadNew';
import { getCountryList } from '@/api/common';
import moment from 'moment';
import './index.less';
import { config } from '@/constants/config';
const { Option } = Select;
/* 智能客服跳转过来，点击跳转提示,如果点击此处，那么表单就被在接受接口返回的值 */
let refreshPromptClick = false;
const CreateComplaint: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  /* 当前用户的角色 */
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let userInfo = useSelector((state: RootStateOrAny) => state.get('user').get('userInfo'));
  if (typeof userInfo === 'string') {
    userInfo = JSON.parse(userInfo);
  }
  /* loading */
  const [loading, setLoading] = useState<any>(false);
  /* 用户是否勾选隐私政策 */
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  /* 提交按钮的禁用 */
  const [submitDisable, setSubmitDisable] = useState<boolean>(false);
  /* 国家列表源数据 */
  const [countryList, setCountryList] = useState<any>([]);
  /* sn列表源数据 */
  const [snList, setSnList] = useState<any>([]);
  /* 是否展示问题发生时间 */
  const [issueFlag, setIssueFlag] = useState<any>(false);
  /* 逆变器型号 */
  const [minv, setMinv] = useState<any>('');
  /* 获取sn信息 */
  const [snData, setSnData] = useState<any>({});
  /* 智能客服跳转过来，点击跳转提示 */
  const [refreshPromptModal, setRefreshPromptModal] = useState<boolean>(false);
  /* 创建客诉的表单*/
  const [createComplaintForm] = Form.useForm();
  /* 获取子组件的实例和方法 */
  let childRef = useRef<any>(null);
  let uploadData: any = {
    action: '/ams/fileOperation/fileUpload',
    size: 80,
    limit: 6,
    fileList: [],
  };
  /** 控制敏感词提示弹窗是否打开 */
  const [open, setOpen] = useState<boolean>(false);

  const receiveAlphaCloudMessage = (event: any) => {
    if (config.alphaCloudSSO[location.origin] == event.origin && event.data && event.data.sessionId) {
      getCustSummary(event.data.sessionId);
    }
  };

  useEffect(() => {
    getCountryLists(); /* 获取国家列表 */
    getSnLists();
    window.addEventListener('message', receiveAlphaCloudMessage, false);
    return () => {
      window.removeEventListener('message', receiveAlphaCloudMessage);
    };
  }, []);

  //智能客服跳转过来的客诉总结
  const getCustSummary = (sessionId) => {
    setLoading(true);
    setRefreshPromptModal(true);
    apiGetCustSummary({ sessionId: sessionId, contentSize: 1260, titleSize: 64 })
      .then((res: any) => {
        if (res && res.code === 200 && res.data && !refreshPromptClick) {
          createComplaintForm.setFieldsValue({
            title: res.data.title,
            description: res.data.summary,
          });
        }
        setRefreshPromptModal(false);
        setLoading(false);
      })
      .catch(() => {
        setRefreshPromptModal(false);
        setLoading(false);
      });
  };

  /* 根据SN获取基本信息 */
  const getSnData = (event, type) => {
    let sysSn = '';
    type == 1 ? (sysSn = event) : (sysSn = event.target.value);
    getSnBasicData({ sysSn })
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          createComplaintForm.setFieldsValue({
            customerEmail: res.data.setEmail,
            country: res.data.countryCode,
            address: res.data.address,
            city: res.data.cityCode,
            postcode: res.data.postcode,
            stateProvince: res.data.stateCode,
            caseContactPerson: res.data.linkman,
            customerPhone: res.data.cellPhone,
          });
          setMinv(res.data.minv);
          setSnData({
            customerEmail: res.data.postcode,
            country: res.data.countryCode,
            address: res.data.address,
            city: res.data.cityCode,
            postcode: res.data.postcode,
            stateProvince: res.data.stateCode,
            caseContactPerson: res.data.linkman,
            customerPhone: res.data.cellPhone,
            systemSource: res.data.systemSource,
          });
        } else {
          createComplaintForm.setFieldsValue({});
          setMinv('');
          setSnData({});
          setSnData({});
        }
      })
      .catch(() => {
        createComplaintForm.setFieldsValue({});
        setMinv('');
      });
  };

  /* 创建客诉时，表单提交 */
  const submitCreateComplaint = (values) => {
    if (values) {
      values.isSueoccurrencTime = values.isSueoccurrencTime
        ? moment(values.isSueoccurrencTime).format('YYYY-MM-DD HH:mm:ss')
        : values.isSueoccurrencTime;
      let fileListTemp: any = [];
      fileListTemp = childRef.current.fileList;
      if (fileListTemp && fileListTemp.length > 0) {
        if (fileListTemp.length > 0) {
          for (var j = 0; j < fileListTemp.length; j++) {
            if (!fileListTemp[j].fileSaveName) {
              message.error(t('customercomplaint.fileIsNotUpload'));
              return;
            }
          }
        }
      }
      let fileList: any = [];
      fileListTemp.map((item) => {
        fileList.push({
          blobContainerName: item.blobContainerName,
          fileFullPath: item.fileSaveName,
          fileName: item.fileName,
          fileSize: item.File.size,
        });
      });
      values.amsFileList = fileList;
      values.minv = minv;
      values.systemSource = snData.systemSource;
      setSubmitDisable(true);
      createComplaint(values)
        .then((res: any) => {
          setSubmitDisable(false);
          if (res && res.code === 200) {
            message.success(t('createComplaint.complaintHasSubmit'));
            createComplaintForm.resetFields();
            setPrivacyPolicy(false);
            setIssueFlag(false);
          }
          if (res && res.code === 6234) {
            setOpen(true);
          }
        })
        .catch(() => {
          setSubmitDisable(false);
          setIssueFlag(false);
        });
    }
  };

  /* 将upload组件的event值转为字段值 */
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  /* 跳转至客诉列表页面 */
  const backToComplaintList = () => {
    history.push('/clientList/index');
  };
  /* 获取国家列表数据 */
  const getCountryLists = () => {
    getCountryList()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setCountryList([...rep.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch((err) => {
        setCountryList([]);
      });
  };
  /* 获取终端用户绑定的sn列表 */
  const getSnLists = () => {
    getSnList()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setSnList([...rep.data]);
        } else {
          setSnList([]);
        }
      })
      .catch((err) => {
        setSnList([]);
      });
  };
  /* 是否展示问题发生时间 */
  const handleIssueChange = (value) => {
    value == '10' || value == '20' ? setIssueFlag(true) : setIssueFlag(false);
  };

  /* 处理隐私政策的选中状态 */
  const checkBoxChange = (event) => {
    setPrivacyPolicy(event.target.checked);
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="custom-contant">
          {/* 创建客诉的表单 */}
          <Form
            form={createComplaintForm}
            name="createComplaintForm"
            layout="vertical"
            autoComplete="off"
            onFinish={submitCreateComplaint}
            className="custom-search"
          >
            <Row gutter={8}>
              {/* 客诉标题 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('createComplaint.title')}
                  name="title"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: `${t('createComplaint.titleRequired')}`,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input allowClear maxLength={128}></Input>
                </Form.Item>
              </Col>
              {/* sn */}
              {roles && roles.includes('customer') ? (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={t('createComplaint.sn')}
                    name="systemSn"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: `${t('createComplaint.snRequired')}`,
                      },
                    ]}
                  >
                    <Select
                      onChange={(e) => {
                        getSnData(e, 1);
                      }}
                      allowClear
                      placeholder={`${t('common.pleaseChoose')}`}
                    >
                      {snList &&
                        snList.length > 0 &&
                        snList.map((item: any, index) => {
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={t('createComplaint.sn')}
                    name="systemSn"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: `${t('createComplaint.snRequired')}`,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input allowClear maxLength={64} onBlur={(e) => getSnData(e, 2)}></Input>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={8}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                {/* 问题类型 */}
                <Form.Item
                  label={t('createComplaint.issueTypes')}
                  name="caseCategory"
                  validateTrigger="onChange"
                  rules={[{ required: true, message: `${t('createComplaint.complaintsTypeRequired')}` }]}
                >
                  <Select allowClear onChange={handleIssueChange}>
                    <Option value={10}>{t('complaintList.systemError')}</Option>
                    <Option value={20}>{t('complaintList.monitoringDataAnomaly')}</Option>
                    <Option value={30}>{t('complaintList.technicalConsulting')}</Option>
                    <Option value={40}>{t('complaintList.complaintTypeoTher')}</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* 问题发生时间 */}
              {issueFlag ? (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={t('createComplaint.problemOccurredTime')}
                    name="isSueoccurrencTime"
                    rules={[{ required: true, message: `${t('createComplaint.problemOccurredTimeRequired')}` }]}
                  >
                    <DatePicker showTime style={{ width: '100%' }} allowClear={true} />
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            <Row gutter={8}>
              {/* 描述 */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label={t('createComplaint.description')}
                  name="description"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: `${t('createComplaint.descriptionRequired')}`,
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea allowClear rows={4} maxLength={1024}></TextArea>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              {/* 邮箱 */}
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('createComplaint.email')}
                  name="customerEmail"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: `${t('createComplaint.emailrequired')}`,
                      whitespace: true,
                    },
                    {
                      pattern: new RegExp(/^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z0-9_\-\+]{2,8})$/),
                      message: `${t('createComplaint.emailStyleIsError')}`,
                    },
                  ]}
                >
                  <Input maxLength={64} allowClear></Input>
                </Form.Item>
              </Col>
              {/* 国家 */}
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="country"
                  label={t('createComplaint.country')}
                  initialValue={undefined}
                  rules={[{ required: true, message: `${t('createComplaint.pleaseChooseCountry')}` }]}
                >
                  <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                    {countryList &&
                      countryList.length > 0 &&
                      countryList.map((item: any, index) => {
                        return (
                          <Option value={item.areaEnglishName} key={index}>
                            {`${item.areaEnglishName}[${item.areaFirstName}]`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              {/* 详细地址 */}
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('createComplaint.detailAddress')}
                  name="address"
                  validateTrigger="onBlur"
                  // rules={[{ required: true, message: `${t('createComplaint.detailAddressRequired')}` }]}
                >
                  <Input maxLength={128} allowClear></Input>
                </Form.Item>
              </Col>
              {/* 城市 */}
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('createComplaint.city')}
                  name="city"
                  labelCol={{ span: 12 }}
                  // rules={[{ required: true, message: `${t('createComplaint.cityRequired')}` }]}
                >
                  <Input maxLength={64} allowClear></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              {/* 邮编 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('createComplaint.postcode')}
                  name="postcode"
                  labelCol={{ span: 12 }}
                  validateTrigger="onBlur"
                  rules={[
                    {
                      // required: true,
                      validator: (rule, value) => {
                        if (value !== '' && value !== null && value !== undefined) {
                          if (value.trim().length > 16 || value.trim().length < 4) {
                            return Promise.reject(t('createComplaint.postcodeStyleIsError'));
                          } else {
                            return Promise.resolve();
                          }
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input maxLength={16}></Input>
                </Form.Item>
              </Col>
              {/* 省/州 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('createComplaint.province')}
                  name="stateProvince"
                  labelCol={{ span: 12 }}
                  // rules={[{ required: true, message: `${t('createComplaint.provinceRequired')}` }]}
                >
                  <Input maxLength={64} allowClear></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              {/* 联系人 */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('createComplaint.linkman')}
                  name="caseContactPerson"
                  labelCol={{ span: 12 }}
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: `${t('createComplaint.linkmanIsRequired')}`, whitespace: true }]}
                >
                  <Input maxLength={64}></Input>
                </Form.Item>
              </Col>
              {/* 电话 */}
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={t('createComplaint.contactNumber')}
                  name="customerPhone"
                  rules={[
                    { required: false },
                    {
                      min: 6,
                      max: 20,
                      message: `${t('createComplaint.cellPhoneStyleIsError')}`,
                    },
                  ]}
                >
                  <Input allowClear maxLength={128}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={
                <Tooltip title={t('createComplaint.warn')}>
                  <span>
                    {t('createComplaint.Attachment')} <InfoCircleOutlined />
                  </span>
                </Tooltip>
              }
              name="amsFileList"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <FileUploadNew uploadParams={uploadData} ref={childRef}></FileUploadNew>
            </Form.Item>
            <Row>
              <span>
                {t('createComplaint.createComplaintDesc')}
                <a
                  onClick={() => {
                    window.open(config.privacyPolicy + '?lang=' + language + '&mode=light&platform=web', '_blank');
                  }}
                >
                  {' ' + t('createComplaint.here')}
                </a>
              </span>
            </Row>
            <br />
            <Row>
              {/* 隐私政策的勾选框 */}
              {roles.includes('customer') === false ? (
                <Checkbox checked={privacyPolicy} onChange={checkBoxChange}>
                  {t('createComplaint.tip')}
                </Checkbox>
              ) : (
                <Checkbox checked={privacyPolicy} onChange={checkBoxChange}>
                  {t('createComplaint.tipCustomer')}
                </Checkbox>
              )}
            </Row>
            <br />

            <Form.Item>
              <Space wrap={true}>
                {userInfo.icdcId !== 1 && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    disabled={!privacyPolicy}
                    loading={submitDisable}
                  >
                    {' ' + t('createComplaint.submit')}
                  </Button>
                )}

                {/* <Button type="primary" shape="round" onClick={backToComplaintList}>
                {t('createComplaint.back')}
              </Button> */}
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Spin>
      <Modal
        title={
          <>
            <ExclamationCircleOutlined style={{ color: '#FABF00' }} />
            &nbsp;&nbsp;{t('common.tip')}
          </>
        }
        open={refreshPromptModal}
        onCancel={() => setRefreshPromptModal(false)}
        maskClosable={false}
        footer={false}
      >
        <span>{t('createComplaint.refreshPrompt')}</span>
        <a
          onClick={() => {
            refreshPromptClick = true;
            setRefreshPromptModal(false);
            setLoading(false);
          }}
        >
          <strong>{t('createComplaint.jumpHere')}</strong>
        </a>
      </Modal>
      <Modal
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <ExclamationCircleFilled style={{ color: '#FABF10', fontSize: '22px' }} />
            {t('common.tip')}
          </div>
        }
        open={open}
        maskClosable={false}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        okText={t('common.confirmBtn')}
        cancelText={t('common.cancelBtn')}
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
      >
        {t('createComplaint.sensitiveWordTips')}
      </Modal>
    </>
  );
};

export default memo(CreateComplaint);
