/*
 * @Description:重置密码
 * @Author: YuKi
 * @Date: 2022/11/03
 * @LastEditors: XuAnjie
 * @LastEditTime: 2023-08-04 17:51:47
 */
import React, { memo, useRef, useState, useEffect } from 'react';
import { Button, Form, Input, Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { forgetPassword, confirmCodeForForgetPassword } from '@/api/login';
import { inputValueLimit } from '@/constants/inputValueLimit';
import { useSelector, RootStateOrAny } from 'react-redux';
import Header from '../Header';
import './index.less';
const ForgetPassword: React.FC = () => {
  const { t } = useTranslation();
  let time = useRef<any>(null);
  const history = useHistory(); //列表ref
  /* 忘记密码表单 */
  const [forgetPassForm] = Form.useForm();

  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 获取验证码->时间倒计时 */
  const [verificationCodeText, setVerificationCodeText] = useState<any>(-1);
  /* 邮箱发送成功提示 */
  const [showTips, setShowTips] = useState<any>(false);
  /* 发送邮箱 */
  const [sendEmail, setSendEmail] = useState<any>('');
  let [isUseEffectFirst, setIsUseEffectFirst] = useState(true);
  /* 当用户名大于64位时禁用获取验证码 */
  const [verificationCodeFlag, setVerificationCodeFlag] = useState<any>(false);

  /**
   * @Author: YuKi
   * @description: 根据语言切换动态切换样式
   * @return {*}
   */
  useEffect(() => {
    if (isUseEffectFirst) {
      setIsUseEffectFirst(false);
      return;
    }
    let tempError = forgetPassForm.getFieldsError();
    for (let index = 0; index < tempError.length; index++) {
      const element = tempError[index];
      if (element.errors && element.errors.length > 0) {
        forgetPassForm.validateFields(element.name);
      }
    }
  }, [language]);

  /**
   * @Author: YuKi
   * @description: 忘记密码表单提交
   * @param {ILoginSubmit} values
   * @return {*}
   */
  const onFinish = (values: any) => {
    let params = {
      userName: values.userName,
      code: values.verificationCode,
    };
    confirmCodeForForgetPassword(params)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          history.push({
            pathname: '/resetPassword',
            state: {
              hour: rep.data.hour,
              userId: rep.data.userId,
              userName: values.userName,
            },
          });
        }
      })
      .catch((err) => {});
  };

  /**
   * @Author: YuKi
   * @description: 获取验证码
   * @return {*}
   */
  const getCode = () => {
    if (forgetPassForm.getFieldValue('userName') && forgetPassForm.getFieldValue('userName').trim()) {
      forgetPassword({ userName: forgetPassForm.getFieldValue('userName') })
        .then((rep: any) => {
          if (rep && rep.code === 200 && rep.data) {
            setVerificationCodeText(59);
            setShowTips(true);
            setSendEmail(rep.data.email);
            message.success(t('forgetPwd.emailSend'));
          } else {
            setShowTips(false);
          }
        })
        .catch((err) => {
          setShowTips(false);
        });
    } else {
      forgetPassForm.validateFields(['userName']);
    }
  };

  useEffect(() => {
    if (verificationCodeText === 59) {
      let index = 59;
      time.current && clearInterval(time.current);
      time.current = setInterval(() => {
        if (index > 0) {
          index--;
          setVerificationCodeText(index);
        } else {
          if (time.current) {
            clearInterval(time.current);
          }
        }
      }, 1000);
    }
  }, [verificationCodeText]);

  useEffect(() => {
    return () => {
      time.current && clearInterval(time.current);
    };
  }, []);

  /**
   * @Author: YuKi
   * @description: 返回按钮
   * @return {*}
   */
  const goBackButton = () => {
    window.location.href = '/login';
  };

  /**
   * @Author: YuKi
   * @description: 用户名长度超过64位时，禁用获取验证密码
   * @return {*}
   */
  const userNameUpdate = (e: any) => {
    if (e.target.value && e.target.value.trim().length > 64) {
      setVerificationCodeFlag(true);
    } else {
      setVerificationCodeFlag(false);
    }
  };

  return (
    <>
      <div className="forget-password-container">
        <Header flag="forget-password" />
        {/* 公司logo */}
        <div className="logo-box">
          {language === 'zh' ? (
            <img src="/common/loginImages/new_logo.png" width="110" />
          ) : (
            <img src="/common/loginImages/new_logo_en.png" width="110" />
          )}
        </div>
        <div className="forget-password-form">
          <Form
            name="forget-password-from"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            size="large"
            form={forgetPassForm}
          >
            <Row gutter={8}>
              <h2 className="username-forget-password">{t('forgetPwd.forgotpassword')}</h2>
            </Row>

            <Row>
              {/* 用户名 */}
              <Col span={24}>
                <Form.Item
                  label={t('forgetPwd.username')}
                  name="userName"
                  rules={[
                    { required: true, message: `${t('forgetPwd.userNameIsRequired')}`, whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (value && value.trim() && value.trim().length > inputValueLimit.maxEmail) {
                          return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxEmail }));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input onChange={(e) => userNameUpdate(e)} placeholder={`${t('forgetPwd.emailPlaceholder')}`} />
                </Form.Item>
              </Col>
            </Row>

            {/* 校验码 */}
            <Row gutter={8} align="bottom">
              <Col span={24} xs={24} sm={24} md={15} lg={16} xl={16}>
                <Form.Item
                  className="verCode-form-item"
                  label={t('forgetPwd.verificationCode')}
                  name="verificationCode"
                  rules={[
                    { required: true, message: `${t('forgetPwd.inputVerificationCode')}`, whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (value && value.trim() && value.trim().length > inputValueLimit.maxBindpwd) {
                          return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxBindpwd }));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder={`${t('forgetPwd.inputVerificationCode')}`} />
                </Form.Item>
              </Col>
              <Col span={24} xs={24} sm={24} md={9} lg={8} xl={8}>
                <Button
                  key="getVerificationCode"
                  style={{ minWidth: '100%', marginBottom: '20px' }}
                  shape="round"
                  disabled={verificationCodeText > 0 || verificationCodeFlag}
                  onClick={getCode}
                >
                  {verificationCodeText > 0 ? verificationCodeText + ' s' : t('forgetPwd.getVerificationCode')}
                </Button>
              </Col>
            </Row>

            {/* 验证码已发送至邮箱 */}
            {showTips ? (
              <Row gutter={8}>
                <Col span={24}>
                  <p style={{ color: '#fbd533', marginBottom: '10px' }}>
                    {t('forgetPwd.emailSuccess', { email: sendEmail })}
                  </p>
                </Col>
              </Row>
            ) : null}

            {/* 按钮 */}
            <Row gutter={18} style={{ justifyContent: 'space-around' }}>
              {/* 返回 */}
              <Col span={8}>
                <Form.Item>
                  <Button className="go-back-style" key="goback" shape="round" htmlType="button" onClick={goBackButton}>
                    {t('common.goback')}
                  </Button>
                </Form.Item>
              </Col>
              {/* 下一步 */}
              <Col span={8}>
                <Form.Item>
                  <Button style={{ width: '100%' }} key="submitBtn" type="primary" shape="round" htmlType="submit">
                    {t('forgetPwd.nextStep')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {/* 联系我们 和 隐私政策 */}
            <Row gutter={8}>
              <Col span={24} className="more-message">
                <a href="https://www.alphaess.com/contact-us" target="_blank">
                  {t('forgetPwd.imprint')}
                </a>
                &nbsp;&nbsp;
                <a href="https://www.alphaess.com/enPrivacyPolicy.html" target="_blank">
                  {t('forgetPwd.privacypolicy')}
                </a>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default memo(ForgetPassword);
