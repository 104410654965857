export default {
  menu: [
    {
      parentId: '123123',
      id: 'dadas',
      authorityName: 'admin',
      lgCode: 'zh-CN',
      menuType: 0,
      menuTitle: '项目管理',
      menuName: '项目管理',
      menuUrl: '/projectManagement',
      menuUrlName: 'projectManagement',
      componentUrl: '',
      icon: 'users',
      isDisplay: 0,
      alwaysShow: 0,
      sort: 14,
      isDefault: 0,
      children: [
        {
          parentId: '123123',
          id: '123123',
          authorityName: 'admin',
          lgCode: 'zh-CN',
          menuType: 1,
          menuTitle: '项目管理',
          menuName: '项目管理',
          menuUrl: '/projectManagement/index',
          menuUrlName: 'projectManagement',
          componentUrl: '@/pages/ProjectManagement/index',
          icon: 'home',
          isDisplay: 1,
          tooltip: null,
          sort: 1,
          isDefault: 0,
          children: [],
          permission: null,
          userMenuType: null,
          menuNameDic: {
            'zh-CN': '项目管理',
            'en-US': 'Project Management',
            'de-DE': 'Projektmanagement',
            'it-IT': 'gestione del progetto',
            'nl-NL': 'project management',
            'sp-SP': 'gestión de proyectos',
            'ja-JP': 'プロジェクト管理',
            'el-GR': 'διαχείριση έργου',
            'fr-FR': 'gestion de projet',
          },
        },
      ],
      permission: 'dasw',
      userMenuType: null,
      menuNameDic: {
        'zh-CN': '项目管理',
        'en-US': 'Project Management',
        'de-DE': 'Projektmanagement',
        'it-IT': 'gestione del progetto',
        'nl-NL': 'project management',
        'sp-SP': 'gestión de proyectos',
        'ja-JP': 'プロジェクト管理',
        'el-GR': 'διαχείριση έργου',
        'fr-FR': 'gestion de projet',
      },
    },
    {
      parentId: 'ddqwd',
      id: 'sgweda',
      authorityName: 'admin',
      lgCode: 'zh-CN',
      menuType: 0,
      menuTitle: '创建客诉',
      menuName: '创建客诉',
      menuUrl: '/createComplaint',
      menuUrlName: 'createComplaint',
      componentUrl: '',
      icon: 'users',
      isDisplay: 0,
      alwaysShow: 0,
      sort: 14,
      isDefault: 0,
      children: [
        {
          parentId: 'ddqwd',
          id: 'sgweda',
          authorityName: 'admin',
          lgCode: 'zh-CN',
          menuType: 1,
          menuTitle: '创建客诉',
          menuName: '创建客诉',
          menuUrl: '/createComplaint/index',
          menuUrlName: 'createComplaint',
          componentUrl: '@/pages/ClientList/CreateComplaint',
          icon: 'users',
          isDisplay: 1,
          tooltip: null,
          sort: 2,
          isDefault: 0,
          children: [],
          permission: null,
          userMenuType: null,
          menuNameDic: {
            'zh-CN': '创建客诉',
            'en-US': 'Create customer complaint',
            'de-DE': 'Beschwerde erstellen',
            'it-IT': 'Creare reclamo del cliente',
            'nl-NL': 'Klacht creëren',
            'sp-SP': 'Crear queja del cliente',
            'ja-JP': '顧客の苦情を作成する',
            'el-GR': 'Δημιουργήστε ένα παράπονο πελάτη',
            'fr-FR': 'Créer une réclamation client',
          },
        },
      ],
      permission: null,
      userMenuType: null,
      menuNameDic: {
        'zh-CN': '创建客诉',
        'en-US': 'Create customer complaint',
        'de-DE': 'Beschwerde erstellen',
        'it-IT': 'Creare reclamo del cliente',
        'nl-NL': 'Klacht creëren',
        'sp-SP': 'Crear queja del cliente',
        'ja-JP': '顧客の苦情を作成する',
        'el-GR': 'Δημιουργήστε ένα παράπονο πελάτη',
        'fr-FR': 'Créer une réclamation client',
      },
    },
    {
      parentId: 'hbsdf',
      id: '12edsacs',
      authorityName: 'admin',
      lgCode: 'zh-CN',
      menuType: 0,
      menuTitle: '客诉列表',
      menuName: '客诉列表',
      menuUrl: '/clientList',
      menuUrlName: 'clientList',
      componentUrl: '',
      icon: 'sitemap',
      isDisplay: 0,
      alwaysShow: 0,
      sort: 14,
      isDefault: 0,
      children: [
        {
          parentId: '004001',
          id: '00402',
          authorityName: 'admin',
          lgCode: 'zh-CN',
          menuType: 1,
          menuTitle: '客诉列表',
          menuName: '客诉列表',
          menuUrl: '/clientList/index',
          menuUrlName: 'clientList',
          componentUrl: '@/pages/ClientList/Client',
          icon: 'sitemap',
          isDisplay: 1,
          tooltip: null,
          sort: 3,
          isDefault: 0,
          children: [],
          permission: null,
          userMenuType: null,
          menuNameDic: {
            'zh-CN': '客诉列表',
            'en-US': 'Customer complaint list',
            'de-DE': 'Kundenbeschwerdeliste',
            'it-IT': 'Elenco delle lamentele dei clienti',
            'nl-NL': 'Klachtenlijst van klanten',
            'sp-SP': 'Lista de quejas de clientes',
            'ja-JP': '顧客の苦情リスト',
            'el-GR': 'Λίστα παραπόνων πελατών',
            'fr-FR': 'Liste des réclamations clients',
          },
        },
      ],
      permission: null,
      userMenuType: null,
      menuNameDic: {
        'zh-CN': '客诉列表',
        'en-US': 'Customer complaint list',
        'de-DE': 'Kundenbeschwerdeliste',
        'it-IT': 'Elenco delle lamentele dei clienti',
        'nl-NL': 'Klachtenlijst van klanten',
        'sp-SP': 'Lista de quejas de clientes',
        'ja-JP': '顧客の苦情リスト',
        'el-GR': 'Λίστα παραπόνων πελατών',
        'fr-FR': 'Liste des réclamations clients',
      },
    },
    {
      parentId: 'efsdcwes',
      id: 'dqwdasx',
      authorityName: 'admin',
      lgCode: 'zh-CN',
      menuType: 0,
      menuTitle: '历史客诉',
      menuName: '历史客诉',
      menuUrl: '/historicalComplaints',
      menuUrlName: 'historicalComplaints',
      componentUrl: '',
      icon: 'clipboard',
      isDisplay: 0,
      alwaysShow: 0,
      sort: 14,
      isDefault: 0,
      children: [
        {
          parentId: 'sdfwgv',
          id: 'fascx',
          authorityName: 'admin',
          lgCode: 'zh-CN',
          menuType: 1,
          menuTitle: '历史客诉',
          menuName: '历史客诉',
          menuUrl: '/historicalComplaints/index',
          menuUrlName: 'historicalComplaints',
          componentUrl: '@/pages/ClientList/HistoricalComplaints',
          icon: 'clipboard',
          isDisplay: 1,
          tooltip: null,
          sort: 4,
          isDefault: 0,
          children: [],
          permission: null,
          userMenuType: null,
          menuNameDic: {
            'zh-CN': '历史客诉',
            'en-US': 'Historical customer complaints',
            'de-DE': 'Historische Kundenbeschwerden',
            'it-IT': 'Reclami storici dei clienti',
            'nl-NL': 'Historische klachten van klanten',
            'sp-SP': 'Quejas históricas de clientes',
            'ja-JP': '過去の顧客の苦情',
            'el-GR': 'Ιστορικά παράπονα πελατών',
            'fr-FR': 'Historique des plaintes des clients',
          },
        },
      ],
      permission: null,
      userMenuType: null,
      menuNameDic: {
        'zh-CN': '历史客诉',
        'en-US': 'Historical customer complaints',
        'de-DE': 'Historische Kundenbeschwerden',
        'it-IT': 'Reclami storici dei clienti',
        'nl-NL': 'Historische klachten van klanten',
        'sp-SP': 'Quejas históricas de clientes',
        'ja-JP': '過去の顧客の苦情',
        'el-GR': 'Ιστορικά παράπονα πελατών',
        'fr-FR': 'Historique des plaintes des clients',
      },
    },
    {
      parentId: 'efsdcwes',
      id: 'dqwdasx',
      authorityName: 'admin',
      lgCode: 'zh-CN',
      menuType: 0,
      menuTitle: '归档维护',
      menuName: '归档维护',
      menuUrl: '/archivalMaintenance',
      menuUrlName: 'archivalMaintenance',
      componentUrl: '',
      icon: 'oneClickUpdate',
      isDisplay: 0,
      alwaysShow: 0,
      sort: 14,
      isDefault: 0,
      children: [
        {
          parentId: 'sdfwgv',
          id: 'fascx',
          authorityName: 'admin',
          lgCode: 'zh-CN',
          menuType: 1,
          menuTitle: '归档维护',
          menuName: '归档维护',
          menuUrl: '/maintenanceManagement/archivalMaintenance',
          menuUrlName: 'archivalMaintenance',
          componentUrl: '@/pages/ArchivalMaintenance',
          icon: 'oneClickUpdate',
          isDisplay: 1,
          tooltip: null,
          sort: 4,
          isDefault: 0,
          children: [],
          permission: null,
          userMenuType: null,
          menuNameDic: {
            'zh-CN': '归档维护',
            'en-US': 'Archive maintenance',
            'de-DE': 'Archivpflege',
            'it-IT': "Manutenzione dell'archivio",
            'nl-NL': 'Onderhoud archief',
            'sp-SP': 'Mantenimiento de archivos',
            'ja-JP': 'アーカイブのメンテナンス',
            'el-GR': 'Συντήρηση αρχείου',
            'fr-FR': 'Maintenance des archives',
          },
        },
      ],
      userMenuType: null,
      permission: 'dasw',
      menuNameDic: {
        'zh-CN': '归档维护',
        'en-US': 'Archive maintenance',
        'de-DE': 'Archivpflege',
        'it-IT': "Manutenzione dell'archivio",
        'nl-NL': 'Onderhoud archief',
        'sp-SP': 'Mantenimiento de archivos',
        'ja-JP': 'アーカイブのメンテナンス',
        'el-GR': 'Συντήρηση αρχείου',
        'fr-FR': 'Maintenance des archives',
      },
    },
    {
      parentId: 'dsfdbgf',
      id: 'hyrgdfs',
      authorityName: 'admin',
      lgCode: 'zh-CN',
      menuType: 0,
      menuTitle: '同步结果',
      menuName: '同步结果',
      menuUrl: '/SyncResult',
      menuUrlName: 'SyncResult',
      componentUrl: '',
      icon: 'battery',
      isDisplay: 0,
      alwaysShow: 0,
      sort: 14,
      isDefault: 0,
      children: [
        {
          parentId: 'asdf',
          id: 'asdfdg',
          authorityName: 'admin',
          lgCode: 'zh-CN',
          menuType: 1,
          menuTitle: '同步结果',
          menuName: '同步结果',
          menuUrl: '/SyncResult/index',
          menuUrlName: 'SyncResult',
          componentUrl: '@/pages/SyncResult',
          icon: 'battery',
          isDisplay: 1,
          tooltip: null,
          sort: 4,
          isDefault: 0,
          children: [],
          permission: null,
          userMenuType: null,
          menuNameDic: {
            'zh-CN': '同步结果',
            'en-US': 'Synchronization results',
            'de-DE': 'Synchronisierungsergebnisse',
            'it-IT': 'Risultati della sincronizzazione',
            'nl-NL': 'Synchronisatieresultaten',
            'sp-SP': 'Resultados de sincronización',
            'ja-JP': '同期結果',
            'el-GR': 'Αποτελέσματα συγχρονισμού',
            'fr-FR': 'Résultats de la synchronisation',
          },
        },
      ],
      userMenuType: null,
      permission: 'dasw',
      menuNameDic: {
        'zh-CN': '同步结果',
        'en-US': 'Synchronization results',
        'de-DE': 'Synchronisierungsergebnisse',
        'it-IT': 'Risultati della sincronizzazione',
        'nl-NL': 'Synchronisatieresultaten',
        'sp-SP': 'Resultados de sincronización',
        'ja-JP': '同期結果',
        'el-GR': 'Αποτελέσματα συγχρονισμού',
        'fr-FR': 'Résultats de la synchronisation',
      },
    },
    {
      parentId: 'yhrfgvuj',
      id: 'yhrfgvuj',
      authorityName: 'admin',
      lgCode: 'zh-CN',
      menuType: 0,
      menuTitle: '报表中心',
      menuName: '报表中心',
      menuUrl: '/reportCenter',
      menuUrlName: 'reportCenter',
      componentUrl: '',
      icon: 'battery',
      isDisplay: 0,
      alwaysShow: 0,
      sort: 14,
      isDefault: 0,
      children: [
        {
          parentId: 'sdfwgv',
          id: 'fascx',
          authorityName: 'admin',
          lgCode: 'zh-CN',
          menuType: 1,
          menuTitle: '报表中心',
          menuName: '报表中心',
          menuUrl: '/ReportCenter/index',
          menuUrlName: 'ReportCenter',
          componentUrl: '@/pages/ReportCenter',
          icon: 'report',
          isDisplay: 1,
          tooltip: null,
          sort: 4,
          isDefault: 0,
          children: [],
          permission: 'dasw',
          userMenuType: null,
          menuNameDic: {
            'zh-CN': '报表中心',
            'en-US': 'Report Center',
            'de-DE': 'Berichtszentrum',
            'it-IT': 'Centro rapporti',
            'nl-NL': 'Rapportcentrum',
            'sp-SP': 'Centro de informes',
            'ja-JP': 'レポートセンター',
            'el-GR': 'Κέντρο Αναφορών',
            'fr-FR': 'Centre de rapports',
          },
        },
      ],
      permission: 'dasw',
      userMenuType: null,
      menuNameDic: {
        'zh-CN': '报表中心',
        'en-US': 'Report Center',
        'de-DE': 'Berichtszentrum',
        'it-IT': 'Centro rapporti',
        'nl-NL': 'Rapportcentrum',
        'sp-SP': 'Centro de informes',
        'ja-JP': 'レポートセンター',
        'el-GR': 'Κέντρο Αναφορών',
        'fr-FR': 'Centre de rapports',
      },
    },
  ],
  permission: [],
};
