/*
 * @Description:数据权限编辑弹框
 * @Author: Summer.Chen
 * @Date: 2024-04-08 14:13:27
 * @LastEditTime: 2024-04-14 16:30:27
 * @LastEditors: Summer.Chen
 */
import React, { useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Modal, Tabs, message } from 'antd';
import CountryAreaAndDistributors from './countryArea'; // 国家/地区；
import SNCodingrules from './SNCodingrules';
import InverterModel from './inverterModel';
import AssociatedDistributors from './associatedDistributors';
import { useTranslation } from 'react-i18next';
const OrganizationRightsEditModal: React.FC<any> = (props): React.ReactElement => {
  const iSetting = useSelector((state: RootStateOrAny) => state.get('app').get('iSetting'));
  const { title, type, open } = props;
  const [activeKey, setActiveKey] = useState('1');
  const { t } = useTranslation();
  // 国家区域子组件 +关联分销商
  const childCountryAreaRef = useRef<null | { refHandler: (activeKey: string) => void }>(null);
  //SN编码规则
  const SNCodeRulesRef = useRef<null | { refHandler: (activeKey: string) => void }>(null);
  //逆变器型号
  const inverterModelRef = useRef<null | { refHandler: (activeKey: string) => void }>(null);
  //分销商
  const associatedDistributorsRef = useRef<null | { refHandler: (activeKey: string) => void }>(null);
  const submitForm = () => {
    switch (activeKey) {
      case '1':
        childCountryAreaRef.current?.refHandler(activeKey);
        break;
      case '2':
        SNCodeRulesRef.current?.refHandler(activeKey);
        break;
      case '3':
        inverterModelRef.current?.refHandler(activeKey);
        break;
      case '4':
        associatedDistributorsRef.current?.refHandler(activeKey);
        break;
    }
  };
  // 弹框确定按钮的禁用
  const [disabledOKBtn, setDisabledOKBtn] = useState(false);
  //SN编码规则全部还是指定
  const [isAll, setIsAll] = useState(true);
  useEffect(() => {
    //分销商区分
    if (open) {
      setActiveKey(type === 3 ? '4' : '1');
    }
  }, [open]);
  // SN编码规则  逆变器型号指定和全部切换 弹框确定按钮的动态的变化
  const checkRadioChange = (value) => {
    setIsAll(value);
  };
  useEffect(() => {
    if (activeKey === '3' || activeKey === '2') {
      setDisabledOKBtn(true);
    } else {
      setDisabledOKBtn(false);
    }
  }, [activeKey]);
  return (
    <>
      <Modal
        title={title}
        {...props}
        okButtonProps={{ ...props.okButtonProps, disabled: disabledOKBtn && !isAll }}
        onOk={() => {
          submitForm();
        }}
      >
        <Tabs
          activeKey={activeKey}
          onChange={(key: string) => {
            if (!iSetting) {
              message.error(t('authorityManagement.settingCountryAndAreaFirst'));
              return;
            }
            setActiveKey(key);
          }}
          items={[
            {
              label: t('common.area'),
              key: '1',
              children: <CountryAreaAndDistributors {...props} activeKey={activeKey} ref={childCountryAreaRef} />,
            },
            {
              label: t('upsModeConfig.snCodingRules'),
              key: '2',
              children: (
                <SNCodingrules {...props} radioChange={checkRadioChange} activeKey={activeKey} ref={SNCodeRulesRef} />
              ),
            },
            {
              label: t('projectManagement.InverterModel'),
              key: '3',
              children: (
                <InverterModel {...props} radioChange={checkRadioChange} activeKey={activeKey} ref={inverterModelRef} />
              ),
            },
            {
              label: t('authorityManagement.associatedDistributors'),
              key: '4',
              children: <AssociatedDistributors activeKey={activeKey} {...props} ref={associatedDistributorsRef} />,
            },
          ].slice(type === 3 ? 3 : 0, type === 3 || type === 1 ? 4 : 3)}
        />
      </Modal>
    </>
  );
};

export default OrganizationRightsEditModal;
