/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Summer.Chen
 * @Date: 2023/07/28 11:27:09
 * @LastEditTime: 2024-03-13 17:58:18
 */

import { app } from '@/constants/actionType';
import { Map } from 'immutable';
import { getRouters, initLangage } from '@/utils/index';
import { getLocalSn, getRightOption } from '@/utils/localStoreUtil';
let getCollapsed: any = localStorage.getItem('collapsed');
getCollapsed = JSON.parse(getCollapsed);

let defaultState = Map({
  language: initLangage(),
  token: localStorage.getItem('token'),
  jwt_token: localStorage.getItem('jwt_token'),
  leftSn: getLocalSn(),
  chargingPile: { show: false, timeStamp: new Date().getTime() },
  collapsed: Boolean(getCollapsed),
  rightOption: getRightOption(),
  routers: getRouters(),
  messages: 0,
  windowWidth: window.innerWidth,
  iSetting: false, // 数据权限国家是否已经设置过
  AlphaCloudSSO: '0', //1 表示对接 6.0
  countryList: JSON.parse(localStorage.getItem('countryList') as '[]') || [],
});

const App = (state = defaultState, action) => {
  switch (action.type) {
    case app.UPDATE_ROUTERS:
      return state.set('routers', action.routers);
    case app.UPDATE_LANGUAGE:
      return state.set('language', action.language);
    case app.UPDATE_TOKEN:
      return state.set('token', action.token);
    case app.UPDATE_JWT_TOKEN:
      return state.set('jwt_token', action.jwt_token);
    case app.UPDATE_CHARGING_PILE:
      return state.set('chargingPile', action.chargingPile);
    case app.UPDATE_COLLASED:
      return state.set('collapsed', action.collapsed);
    case app.UPDATE_RIGHT_OPTION:
      return state.set('rightOption', action.rightOption);
    case app.UPDATE_MESSAGES:
      return state.set('messages', action.messages);
    case app.UPDATE_WINDOWN_WIDTH:
      return state.set('windowWidth', action.windowWidth);
    case app.UPDATE_ALPHAClOUD_SSO:
      return state.set('AlphaCloudSSO', action.AlphaCloudSSO);
    case app.UPDATE_COUNTRY_LIST:
      return state.set('countryList', action.countryList);
    case app.UPDATE_IS_SETTING:
      return state.set('iSetting', action.iSetting);
    default:
      return state;
  }
};
export default App;
