/*
 * @Description: 客户反馈->客诉详情页-> 中试测试用例评估表
 * @Author: Wayne Hu
 * @LastEditors: XuAnjie
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2024-04-17 10:03:32
 */

import React, { memo, useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, Row, Col, message, Rate, List, Table, Upload, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import './index.less'
import { saveBackOperate, getHandleForm } from '@/api/clientFeedback/complaintsList';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getUrlQuery } from '@/utils/utils';
import { Editor } from '@tinymce/tinymce-react';
import { tinymceFormatLanguage } from '@/utils';
import { config } from '@/constants/config';
import { fileUpload } from '@/api/common';

const { TextArea } = Input;
const { Option } = Select;

/* 富文本内容 */
let tinyContent: any = '';

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
}

const CenterEvaluationTestCases: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  id,
  extendParams,
  nodeData,
}) => {
  const { t } = useTranslation();
  const evaluateForm = useRef<any>();
  const history = useHistory();
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));

  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const { TextArea } = Input;
  const { confirm } = Modal;
  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    let key: string = getUrlQuery('key');
    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
    }
    if (nodeData) {
      getHandleForms(nodeData.data.id);
    }
    return () => {
      tinyContent = '';
    };
  }, []);

  /**
   * @description: 获取去处理表单
   * @param {*} id
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getHandleForms = (id) => {
    getHandleForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        searchForm.setFieldsValue(res.data);
      }
    });
  };

  /**
   * @description: 退回表单保存
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    searchForm.validateFields().then((values) => {
      if (!tinyContent) {
        return message.error(t('complaintList.reasonRequired'));
      }
      saveBackOperate({
        complaintsId: id,
        reason: tinyContent,
        ...originData,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHanle();
        }
      });
    });
  };

  /**
   * @description: 取消操作
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const cancelHanle = () => {
    searchForm.resetFields();
    onClose();
  };

  /**
   * @description: 监听富文本变化
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const handleEditorChange = (content: any, editor: any) => {
    tinyContent = content;
  };

  /**
   * @description: 富文本上传
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const tinymceUploadImage = (blobInfo, succFun, failFun) => {
    const formData = new FormData();
    // 服务端接收文件的参数名，文件数据，文件名
    formData.append('blobContainerName', 'FILES');
    formData.append('fileList', blobInfo.blob(), blobInfo.filename());
    fileUpload(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          let uploadUrl = '';
          if (window.location.origin.indexOf('localhost') != -1) {
            uploadUrl = config.downUrl + 'files/';
          } else {
            uploadUrl = config.downUrl + 'files/';
          }
          succFun(uploadUrl + res.data[0].fileSaveName);
        } else {
          failFun(t('createComplaint.fileUploadError'));
        }
      })
      .catch(() => {
        failFun(t('createComplaint.fileUploadError'));
      });
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData.data.currentStatusShowName}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHanle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHanle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form
          layout="vertical"
          form={searchForm}
          name="complaintDetail"
          autoComplete="off"
          style={{ width: '100%' }}
          className=""
          disabled={nodeData ? true : false}
        >
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.reason')}</span>}
                name="reason"
                required
              >
                <Editor
                  apiKey={config.tinyKey}
                  tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                  disabled={nodeData}
                  scriptLoading={{ async: true }} // 异步加载
                  onEditorChange={handleEditorChange}
                  init={{
                    min_height: 200,
                    language: tinymceFormatLanguage(language),
                    menubar: false, // 顶部菜单栏
                    resize: false, // 右下角调整大小
                    statusbar: false, // 底部状态栏
                    object_resizing: false, // 禁止设置媒体大小
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    default_link_target: '_blank',
                    plugins:
                      'autoresize print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave',
                    toolbar:
                      'undo redo bold italic  image | formatselect |  alignleft aligncenter alignright alignjustify outdent indent ',
                    images_upload_handler: (blobInfo, succFun, failFun) => {
                      return tinymceUploadImage(blobInfo, succFun, failFun);
                    },
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(CenterEvaluationTestCases);
