// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.rectangle {
  font-size: 12px;
  min-width: 160px;
  min-height: 80px;
  flex-direction: column;
  background-color: #d9edf7;
  margin: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
}
.line {
  position: relative;
  width: 50px;
  height: 2px;
  background-color: black;
  margin: 0 5px;
}
.line::after {
  content: '';
  position: absolute;
  top: -4px;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid black;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClientList/CustomerComplaintDetails/components/CustomerClientStatus/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;AACF;AAEA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,sBAAA;EACA,yBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AAAF;AAGA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;AADF;AAIA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,iCAAA;EACA,oCAAA;EACA,6BAAA;AAFF","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-start;\n  margin-bottom: 10px;\n}\n\n.rectangle {\n  font-size: 12px;\n  min-width: 160px;\n  min-height: 80px;\n  flex-direction: column;\n  background-color: #d9edf7;\n  margin: 5px 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  padding: 5px;\n}\n\n.line {\n  position: relative;\n  width: 50px;\n  height: 2px;\n  background-color: black;\n  margin: 0 5px;\n}\n\n.line::after {\n  content: '';\n  position: absolute;\n  top: -4px;\n  right: -10px;\n  width: 0;\n  height: 0;\n  border-top: 5px solid transparent;\n  border-bottom: 5px solid transparent;\n  border-left: 10px solid black;\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
