/*
 * @Description:重置密码
 * @Author: YuKi
 * @Date: 2022/11/03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/09/13 15:36:02
 */
import React, { memo, useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { resetPassword } from '@/api/login';
import { useSelector, RootStateOrAny } from 'react-redux';
import Header from '../Header';
import { encrypt } from '@/constants/common';
import './index.less';
const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory(); //列表ref
  /* 忘记密码表单 */
  const [resetPwdForm] = Form.useForm();
  const location = useLocation();
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let [isUseEffectFirst, setIsUseEffectFirst] = useState(true);

  /**
   * @Author: YuKi
   * @description: 根据语言切换动态切换样式
   * @return {*}
   */
  useEffect(() => {
    if (isUseEffectFirst) {
      setIsUseEffectFirst(false);
      return;
    }
    let tempError = resetPwdForm.getFieldsError();
    for (let index = 0; index < tempError.length; index++) {
      const element = tempError[index];
      if (element.errors && element.errors.length > 0) {
        resetPwdForm.validateFields(element.name);
      }
    }
  }, [language]);

  /**
   * @Author: YuKi
   * @description: 忘记密码表单提交
   * @param {ILoginSubmit} values
   * @return {*}
   */
  const onFinish = (values: any) => {
    if (location.state && location.state.hour && location.state.userId && location.state.userName) {
      let params = {
        confirmPassword: encrypt(values.confirmPassword.trim(), location.state.userName.trim()),
        password: encrypt(values.password.trim(), location.state.userName.trim()),
        userId: location.state.userId,
        hour: location.state.hour,
        username: location.state.userName,
      };
      resetPassword(params)
        .then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('resetPwd.resetSuccess'));
            window.location.href = '/login';
          } else {
            history.push({
              pathname: '/forgetPassword',
            });
          }
        })
        .catch((err) => {
          history.push({
            pathname: '/forgetPassword',
          });
        });
    }
  };

  /**
   * @Author: YuKi
   * @description: 返回按钮
   * @return {*}
   */
  const goBackButton = () => {
    window.location.href = '/login';
  };

  return (
    /*注册*/
    <>
      <div className="reset-password-container">
        <Header flag="reset-password" />
        {/* 公司logo */}
        <div className="logo-box">
          {language === 'zh' ? (
            <img src="/common/loginImages/new_logo.png" width="110" />
          ) : (
            <img src="/common/loginImages/new_logo_en.png" width="110" />
          )}
        </div>
        <div className="reset-password-form">
          <Form
            name="reset-password-from"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            size="large"
            form={resetPwdForm}
          >
            <Row gutter={8}>
              <h2 className="username-reset-password">{t('resetPwd.resetPwd')}</h2>
            </Row>

            <Row>
              {/* 新密码 */}
              <Col span={24}>
                <Form.Item
                  label={t('resetPwd.newPassword')}
                  name="password"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: `${t('resetPwd.newPwdIsRequired')}`, whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (value && value.trim() && (value.trim().length < 4 || value.trim().length > 20)) {
                          return Promise.reject(t('register.pwdLengthLimit'));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password allowClear />
                </Form.Item>
              </Col>
              {/* 确认密码 */}
              <Col span={24}>
                <Form.Item
                  label={t('resetPwd.confirmNewPassword')}
                  name="confirmPassword"
                  validateTrigger="onBlur"
                  dependencies={['password']}
                  rules={[
                    { required: true, message: `${t('resetPwd.confirmPwdIsRequired')}`, whitespace: true },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.trim()) {
                          if (
                            !getFieldValue('password') ||
                            !getFieldValue('password').trim() ||
                            getFieldValue('password').trim() !== value.trim()
                          ) {
                            return Promise.reject(t('resetPwd.confirmPwdIsError'));
                          } else if (
                            (value.trim().length < 4 || value.trim().length > 20) &&
                            getFieldValue('password') &&
                            getFieldValue('password').trim()
                          ) {
                            return Promise.reject(t('register.pwdLengthLimit'));
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password allowClear />
                </Form.Item>
              </Col>
            </Row>

            {/* 按钮 */}
            <Row gutter={18} style={{ justifyContent: 'space-around' }}>
              <Col span={8}>
                {/* 提交 */}
                <Form.Item>
                  <Button
                    size="middle"
                    style={{ width: '100%' }}
                    key="submitBtn"
                    type="primary"
                    shape="round"
                    htmlType="submit"
                  >
                    {t('common.submitBtn')}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={8}>
                {/* 登录 */}
                <Form.Item>
                  <Button
                    size="middle"
                    style={{ width: '100%' }}
                    key="goback"
                    shape="round"
                    htmlType="button"
                    onClick={goBackButton}
                  >
                    {t('resetPwd.login')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {/* 联系我们 和 隐私政策 */}
            <Row gutter={8}>
              <Col span={24} className="more-message">
                <a href="https://www.alphaess.com/contact-us" target="_blank">
                  {t('forgetPwd.imprint')}
                </a>
                &nbsp;&nbsp;
                <a href="https://www.alphaess.com/enPrivacyPolicy.html" target="_blank">
                  {t('forgetPwd.privacypolicy')}
                </a>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default memo(ResetPassword);
