// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-column {
  display: flex;
  flex-direction: column;
}
.ant-upload-list-item-name {
  cursor: pointer;
}
.ant-upload-list-item-name:hover {
  color: #FABF00;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClientList/CustomerComplaintDetails/index.less"],"names":[],"mappings":"AAKA;EACE,aAAA;EACA,sBAAA;AAJF;AAMA;EACE,eAAA;AAJF;AAKE;EACE,cAAA;AAHJ","sourcesContent":["// .complaint-detail-label {\n//   .ant-form-item-row {\n//     height: 45px !important;\n//   }\n// }\n.flex-column {\n  display: flex;\n  flex-direction: column;\n}\n.ant-upload-list-item-name {\n  cursor: pointer;\n  &:hover {\n    color: #FABF00;\n  }\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
