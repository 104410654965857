/*
 * @Description: Col 响应式配置信息
 * @Author: Summer.Chen
 * @Date: 2024-03-14 13:41:26
 * @LastEditTime: 2024-03-14 13:57:34
 * @LastEditors: Summer.Chen
 */
export const colGroup = (xs: number = 24, sm: number = 24, md: number = 6, lg: number = 8, xl: number = 8) => {
  return {
    xs,
    sm,
    md,
    lg,
    xl,
  };
};
