/*
 * @Description:维护管理->责任方维护
 * @Author: YuKi
 * @Date: 2024/11/09
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message, Select } from 'antd';
import {
  getResponsiblePartyPage,
  editResponsibleParty,
  delResponsibleParty,
} from '@/api/maintenanceManagement/responsiblePartyMaintenance';
import { ExclamationCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { RootStateOrAny, useSelector } from 'react-redux';
/* 列表字段类型定义 */
type GithubIssueItem = {
  id: number;
  departmentList: string[] /* 责任部门 */;
  responsibleParty: string /* 责任归属方 */;
  createAccount: string /* 创建人 */;
  createDatetime: string /* 创建时间 */;
};

/* 列表查询参数 */
let searchParams = {
  /** 每页显示条数 */
  pageSize: 10,
  /** 当前页 */
  pageIndex: 1,
  /** 责任归属部门 */
  responsibleDepartment: '',
  /** 责任归属方 */
  responsibleParty: '',
  /** 所属产品线 */
  businessLine: 10,
};
let editId = '';
const ResponsiblePartyMaintenance: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  /* 分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 列表源数据 */
  const [dataList, setDataList] = useState<GithubIssueItem[]>([]);
  /* table的loading */
  const [tableLoading, setTableLoading] = useState(false);
  /* 新增编辑弹窗打开关闭flag */
  const [isAEOpen, setIsAEOpen] = useState(false);
  /* 编辑弹窗的ref */
  const [aEForm] = Form.useForm();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /** 新增/编辑标识 */
  const [aEFlag, setAEFlag] = useState<string>('add');

  /* 列表字段 */
  const columns: ColumnsType<GithubIssueItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      width: language === 'zh' || language === 'de' || language === 'ja' ? 90 : 180,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 所属产品线 */
    {
      title: t('archivalMaintenance.productLine'),
      dataIndex: 'businessLine',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (value) => {
        switch (value) {
          case 10:
            return t('archivalMaintenance.householdUse');
          case 20:
            return t('createComplaint.consumerBusiness');
          case 30:
            return t('archivalMaintenance.industryAndCommerce');
          case 40:
            return t('archivalMaintenance.largeEnergyStorage');
          default:
            return '';
        }
      },
    },
    /* 责任归属方 */
    {
      title: t('responsiblePartyMaintenance.responsibleParty'),
      dataIndex: 'responsibleParty',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 责任归属部门 */
    {
      title: t('responsiblePartyMaintenance.departmentList'),
      dataIndex: 'departmentList',
      width: 300,
      align: 'center',
      ellipsis: true,
      render: (departmentList) => (departmentList ?? []).join('、'),
    },
    /* 创建人 */
    {
      title: t('common.creator'),
      dataIndex: 'createAccount',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 创建时间 */
    {
      title: t('common.createTime'),
      dataIndex: 'createDatetime',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      align: 'center',
      width: 240,
      render: (_, record) => [
        <Space size="middle" wrap>
          {/* 编辑 */}
          <a key="edit" onClick={() => aEButton('edit', record)}>
            {t('common.editBtn')}
          </a>
          {/* 删除 */}
          <a key="delete" className="delete-text" onClick={() => deleteClick(record.id)}>
            {t('common.deleteBtn')}
          </a>
        </Space>,
      ],
    },
  ];

  /**
   * @Author: YuKi
   * @description: 获取列表数据
   */
  const getListInformation = () => {
    if (searchForm) {
      searchParams.responsibleDepartment = searchForm.getFieldValue('responsibleDepartment');
      searchParams.responsibleParty = searchForm.getFieldValue('responsibleParty');
      searchParams.businessLine = searchForm.getFieldValue('businessLine');
    }
    setTableLoading(true);
    getResponsiblePartyPage(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data && rep.data.rows) {
          setDataList([...rep.data.rows]);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
        } else {
          setDataList([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pagingInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pagingInit });
        setTableLoading(false);
      });
  };

  /**
   * @Author: YuKi
   * @description: 根据分页，初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    searchParams = {
      /** 每页显示条数 */
      pageSize: 10,
      /** 当前页 */
      pageIndex: 1,
      /** 责任归属部门 */
      responsibleDepartment: '',
      /** 责任归属方 */
      responsibleParty: '',
      /** 所属产品线 */
      businessLine: 10,
    };
    pageChange(pagingRecord.pageIndex, pagingRecord.pageSize);
  }, []);

  /**
   * @Author: YuKi
   * @description: 分页的回调函数
   * @param {Number} currentPage 当前页
   * @param {Number} currentSize 页的总条数
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getListInformation();
  }, []);

  /**
   * @Author: YuKi
   * @description:查询列表
   */
  const search = useCallback(() => {
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    getListInformation();
  }, []);

  /**
   * @Author: YuKi
   * @description: 删除
   * @param {string} id 序号
   */
  const deleteClick = (id) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteData'),
      onOk() {
        delResponsibleParty(id).then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('common.operateSuccess')); /* 操作成功 */
            getListInformation();
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  /**
   * @Author: YuKi
   * @description: 打开新增/编辑弹窗
   * @param {String} flag 弹窗类型 add-新增 edit-编辑
   * @param {*} record 编辑的行数据
   */
  const aEButton = (flag, record?) => {
    setAEFlag(flag);
    if (flag === 'edit') {
      aEForm.setFieldsValue({
        id: record.id,
        businessLine: record.businessLine ?? 10,
        departmentList: record.departmentList ?? [undefined],
        responsibleParty: record.responsibleParty ?? undefined,
      });
    } else {
      aEForm && aEForm.resetFields();
    }
    setIsAEOpen(true);
  };

  /**
   * @Author: Wayne Hu
   * @description: 弹窗表单提交
   */
  const handleAEOk = () => {
    aEForm.validateFields().then((values) => {
      if (aEFlag === 'add') {
        editResponsibleParty(values).then((rep: any) => {
          if (rep && rep.code === 200) {
            setIsAEOpen(false);
            aEForm.resetFields();
            searchParams.pageIndex = 1;
            searchParams.pageSize = 10;
            getListInformation();
            message.success(t('common.addSuccess'));
          }
        });
      }
      if (aEFlag === 'edit') {
        editResponsibleParty({ ...values }).then((rep: any) => {
          if (rep && rep.code === 200) {
            setIsAEOpen(false);
            aEForm.resetFields();
            getListInformation();
            message.success(t('common.saveSuccess'));
          }
        });
      }
    });
  };

  /**
   * @Author: YuKi
   * @description: 关闭弹窗
   */
  const handleAECancel = () => {
    aEForm && aEForm.resetFields();
    setIsAEOpen(false);
  };

  return (
    <>
      <div className="custom-contant">
        <Form
          form={searchForm}
          name="search_form"
          layout="vertical"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12} align={'bottom'}>
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item label={t('archivalMaintenance.productLine')} name="businessLine" initialValue={10}>
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  options={[
                    { value: 10, label: t('archivalMaintenance.householdUse') },
                    { value: 20, label: t('createComplaint.consumerBusiness') },
                    { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                    { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* 责任归属方 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item name="responsibleParty" label={t('responsiblePartyMaintenance.responsibleParty')}>
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 责任归属部门 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item name="responsibleDepartment" label={t('responsiblePartyMaintenance.departmentList')}>
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item>
                <Space size="middle">
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {/* 新增 */}
                  <Button type="primary" key="add" shape="round" onClick={() => aEButton('add')}>
                    {t('common.addBtn')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          loading={tableLoading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 1180, y: 'calc(100vh - 350px)' }}
          className="custom-table-flex auto-table"
        />
      </div>

      {/* 新增/编辑弹窗 */}
      <Modal
        title={aEFlag === 'add' ? t('common.addBtn') : t('common.editBtn')}
        open={isAEOpen}
        onCancel={handleAECancel}
        maskClosable={false}
        footer={[
          /* 取消 */
          <Button shape="round" onClick={handleAECancel}>
            {t('common.cancelBtn')}
          </Button>,
          /* 保存 */
          <Button shape="round" type="primary" onClick={handleAEOk}>
            {t('common.saveBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form
          name="aEForm"
          autoComplete="off"
          layout="vertical"
          form={aEForm}
          initialValues={{ departmentList: [undefined] }}
        >
          {/* 所属产品线 */}
          <Form.Item label={t('archivalMaintenance.productLine')} name="businessLine" initialValue={10}>
            <Select
              placeholder={`${t('common.pleaseChoose')}`}
              options={[
                { value: 10, label: t('archivalMaintenance.householdUse') },
                { value: 20, label: t('createComplaint.consumerBusiness') },
                { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
              ]}
            />
          </Form.Item>
          {/* 责任归属方 */}
          <Form.Item
            name="responsibleParty"
            validateTrigger="onBlur"
            label={t('responsiblePartyMaintenance.responsibleParty')}
            rules={[
              {
                required: true,
                message: `${t('responsiblePartyMaintenance.responsiblePartyRequire')}`,
                whitespace: true,
              },
              {
                validator: (rule, value) => {
                  if (value && value.trim() && value.trim().length > 64) {
                    return Promise.reject(t('common.maxlengLimit', { maxLength: 64 }));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          {/* 责任归部门 */}
          <Form.List name="departmentList">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key} style={{ display: 'flex', gap: '8px', alignItems: 'flex-end' }}>
                    <Form.Item
                      {...field}
                      label={index == 0 ? t('responsiblePartyMaintenance.departmentList') : ''}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: `${t('responsiblePartyMaintenance.departmentListRequire')}`,
                          whitespace: true,
                        },
                        {
                          validator: (rule, value) => {
                            if (value && value.trim() && value.trim().length > 64) {
                              return Promise.reject(t('common.maxlengLimit', { maxLength: 64 }));
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                      style={{ width: 'calc(100% - 50px)' }}
                    >
                      <Input allowClear />
                    </Form.Item>
                    {index === 0 && (
                      <Form.Item>
                        <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={() => add()}></Button>
                      </Form.Item>
                    )}
                    {index > 0 && (
                      <Form.Item>
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<MinusOutlined />}
                          onClick={() => remove(field.name)}
                        ></Button>
                      </Form.Item>
                    )}
                  </div>
                ))}
              </>
            )}
          </Form.List>
          {/* id */}
          {aEFlag === 'edit' ? (
            <Form.Item name="id" hidden>
              <Input disabled />
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
    </>
  );
};

export default memo(ResponsiblePartyMaintenance);
