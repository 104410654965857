/*
 * @Description: 重点客诉详情
 * @Author: YuKi
 * @Date: 2025/02/10
 */

import React, { memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Dropdown } from 'antd';
import { fileDownload } from '@/api/common';
import { useTranslation } from 'react-i18next';
import { blobDownloadFile } from '@/constants/common';
import {
  getAmsComplaintOperateList,
  getAmsComplaintProcessList,
  getEmphasisDetail,
} from '@/api/clientFeedback/complaintsList';
import { useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { getUrlQuery } from '@/utils/utils';
import AttackHandleModal from './components/AttackHandleModal';
import MermaidChart from './components/MermaidChart';
import BackModal from './components/BackModal';
import ExpertHandleModal from './components/ExpertHandleModal';
import EmphasisProgressModal from './components/EmphasisProgressModal';
import ImportKeyCusComModal from '@/pages/ClientList/CustomerComplaintDetails/components/ImportKeyCusComModal';

/* 客诉详情字段 */
type ComplaintDetail = {
  id: number; // 客诉单号
  amount: number; // 涉及金额
  amsFileList: {
    blobContainerName: string;
    createAccount: string;
    createDatetime: string;
    fileFullPath: string;
    fileName: string;
    fileSize: number;
  }[]; // 附件列表
  background: string; // 背景描述
  batchQuantity: number; // 批次个数
  complaintsId: number; // 客诉id
  isEditEightReport: boolean; // 是否可以编辑8d报告
  expectResolveTime: string; // 预计解决时间
  expertGroupType: string; // 专家组类型
  grade: string; // 重点客诉类型
  impactArea: string; // 影响区域范围
  minv: string; // 逆变器型号
  processInstanceId: string; // 流程实例id
  processorGroupId: string; // 处理组id
  publicOpinion: string; // 舆情/其他
  question: string; // 问题详情
  sysSn: string; // 涉及SN
  taskInstanceId: string; // 任务实例id
  ticketNumber: string; // 客诉单号
  title: string; // 客诉标题
  createAccount: string; // 创建人
  createServerDatetime: string; // 创建时间
};

let key: string;
let complaintsId: string;
let isEditEightReport: boolean = false;
let defaultOperate = [
  {
    key: 'EmphasisProgress',
    operationNameShow: '客诉进度更新',
    formType: 'EmphasisProgress',
  },
];
const Details: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 客诉详情是数据 */
  const [complaintDetail, setComplaintDetail] = useState<ComplaintDetail>();
  const [fileImgWidth, setFileImgWidth] = useState<number>(300);
  const [fileImg, setFileImg] = useState<string>('');
  const [viewImg, setViewImg] = useState<boolean>(false);

  /* 控制客诉状态流程图的重新加载 */
  const [showProcessStatus, setShowProcessStatus] = useState(false);
  /* 客诉状态数据  */
  const [complaintStatusData, setComplaintStatusData] = useState<any>([]);
  const [operateType, setOperateType] = useState<any>();
  /* 操作类型 */
  const [operateFormType, setOperateFormType] = useState<any>();
  const [ImgDown, setImgDown] = useState<any>({
    rep: {},
    name: '',
  });
  const [operateOpen, setOperateOpen] = useState<boolean>(false);
  const [isOperate, setIsOperate] = useState<boolean>(false);
  /* 操作列表 */
  const [operateList, setOperateList] = useState<any>(defaultOperate);
  const [extendParams, setExtendParams] = useState<any>({});
  /* 客诉状态节点数据 */
  const [nodeData, setNodeData] = useState<any>();
  /* 处理弹窗的详情ID */
  const [processingId, setProcessingId] = useState<any>();

  /**
   * @description: 获取客诉操作列表
   * @param {*} params
   * @return {*}
   * @LastEditors: YuKi
   */
  const getOperationLists = (params) => {
    getAmsComplaintOperateList({
      complaintId: params.complaintsId,
      processInstanceId: params.processInstanceId,
      processorGroupId: params.processorGroupId,
      taskInstanceId: params.taskInstanceId,
    })
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          const operateListTemp = [...defaultOperate, ...res.data];
          setOperateList(operateListTemp);
        } else {
          setOperateList([...defaultOperate]);
        }
      })
      .catch(() => {
        setOperateList([...defaultOperate]);
      });
  };

  /**
   * @description: 获取客诉流程状态
   * @param {*} id
   * @return {*}
   * @LastEditors: YuKi
   */
  const getAmsComplaintProcess = (id) => {
    getAmsComplaintProcessList({ ro: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        // 找到currentStatus为ImportEmphasisCC的对象
        const targetObject = res.data.find((item) => item.currentStatus === 'ImportEmphasisCC');

        if (targetObject) {
          setShowProcessStatus(true);
          setComplaintStatusData(targetObject);
        }
      }
    });
  };

  /**
   * @description: 获取客诉详情和操作列表
   * @param {*} id
   * @return {*}
   * @LastEditors: YuKi
   */
  const getCustomerComplaintDeatils = (id) => {
    getEmphasisDetail({ id: id })
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          setComplaintDetail({ ...res.data });
          setExtendParams({ ...res.data });
          isEditEightReport = res.data.isEditEightReport;
          complaintsId = res.data.complaintsId;
          setProcessingId(res.data.processingId);
          if (res.data.isEdit) {
            defaultOperate = [
              {
                key: 'EmphasisProgress',
                operationNameShow: '客诉进度更新',
                formType: 'EmphasisProgress',
              },
              {
                key: 'Edit',
                operationNameShow: '编辑',
                formType: 'Edit',
              },
            ];
            setOperateList([...defaultOperate]);
          }
          if (complaintsId) {
            getAmsComplaintProcess(complaintsId);
          }
          if (
            res.data.taskInstanceId &&
            res.data.complaintsId &&
            res.data.processInstanceId &&
            res.data.processorGroupId
          ) {
            getOperationLists(res.data);
          } else {
            setOperateList([...defaultOperate]);
          }
        }
      })
      .catch(() => {
        setOperateList([...defaultOperate]);
        complaintsId = '';
        isEditEightReport = false;
      });
  };
  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    key = getUrlQuery('key');

    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
      /* 获取当前客诉详情 */
      getCustomerComplaintDeatils(key);
    }

    return () => {
      key = '';
      complaintsId = '';
      isEditEightReport = false;
      defaultOperate = [
        {
          key: 'EmphasisProgress',
          operationNameShow: '客诉进度更新',
          formType: 'EmphasisProgress',
        },
      ];
    };
  }, []);

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载或预览客诉附件
   * @param {string} fileName
   */
  const downloadOrPreviewAttachments = (fileName: string | undefined) => {
    let params = {
      blobContainerName: 'FILES',
      fileBlobName: fileName,
    };
    fileDownload(params).then((res: any) => {
      let imgType = '.bmp.jpg.jpeg.png.gif'; // 可供预览的文件格式
      let indexNum: any = fileName?.lastIndexOf('.');
      let fileSuffix: string = fileName?.substring(indexNum + 1, fileName.length).toLowerCase() || ''; // 获取文件后缀名
      let fileInfo = {
        rep: res,
        name: fileName,
      };
      setImgDown({ ...fileInfo });
      if (imgType.indexOf(fileSuffix) > -1) {
        let src = window.URL.createObjectURL(res.data);
        let img = new Image();
        img.src = src;
        img.onload = function () {
          setFileImgWidth(500);
          setFileImg(src);
          setViewImg(true);
        };
      } else {
        blobDownloadFile(res, fileName);
      }
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 关闭图片预览窗口
   */
  const closePreview = () => {
    setViewImg(false);
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  /* 下载预览图片 */
  const downLoadFile = () => {
    blobDownloadFile(ImgDown.rep, ImgDown.name);
    setViewImg(false);
    setFileImg('');
    setImgDown({
      rep: {},
      name: '',
    });
  };

  /* 催促处理客诉 */
  const urgeHandleComplaint = (val: any) => {
    setIsOperate(true);
    setOperateType(val);
    setOperateFormType(val.formType);
    setOperateOpen(true);
  };

  /* 从客诉详情跳转至客诉列表页面 */
  const backToComplaintList = () => {
    const params = new URLSearchParams(document.location.search);
    const result: any = {};
    params &&
      params.forEach((value, key) => {
        if (value) {
          result[key] = value;
        }
      });
    if (result.countryList) {
      result.countryList = result.countryList.split(',');
    }

    history.push({
      pathname: '/clientListAfterSales/emphasisComplaintList',
      state: result,
    });
  };

  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: April
   */
  const handleCanceOperate = () => {
    setIsOperate(false);
    setOperateOpen(false);
    getCustomerComplaintDeatils(key);
    getAmsComplaintProcess(complaintsId);
    setNodeData('');
    setOperateFormType('');
  };
  /**
   * @description: 回显对应的表单
   * @param {*} type
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const handleOnOpen = (data) => {
    if (data.data.currentStatus !== 'ImportEmphasisCC' && data.data.currentStatus !== 'Finish') {
      setIsOperate(false);
      setNodeData(data);
      setOperateType('');
      setOperateFormType(data.data.formType);
      setOperateOpen(true);
    }
  };

  const handleGrade = (grade?: string) => {
    switch (grade) {
      case 'S':
        return t('complaintList.grade_S');
      case 'A':
        return t('complaintList.grade_A');
      case 'B':
        return t('complaintList.grade_B');
      default:
        return '';
    }
  };

  return (
    <>
      <Form layout="vertical" form={searchForm} name="complaintDetail" autoComplete="off" style={{ width: '100%' }}>
        <Row style={{ float: 'right' }}>
          {/* 返回客诉列表页 */}
          <a onClick={backToComplaintList} className="color-label">
            {t('complaintList.back')}
          </a>
        </Row>
        <Row gutter={8} className="row-border">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.title')}</span>}
            >
              <div>
                <span>{complaintDetail?.title}</span>
                <br />
                <p>
                  <span>
                    {t('complaintList.key_customer_complaint_number')}: {complaintDetail?.ticketNumber}
                  </span>
                  &nbsp;&nbsp;
                  <span>
                    {t('complaintList.creator')}: {complaintDetail?.createAccount}
                  </span>
                  &nbsp;&nbsp;
                  <span>
                    {t('complaintList.createTime')}: {complaintDetail?.createServerDatetime}
                  </span>
                </p>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 背景描述 */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.background_description')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{complaintDetail?.background}</div>
            </Form.Item>
          </Col>
          {/* 问题详情 */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.question_details')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{complaintDetail?.question}</div>
            </Form.Item>
          </Col>
        </Row>
        {/* 客诉分类维度 */}
        <Row gutter={8} className="row-border">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={
                <span className="form-item-label">
                  {t('complaintList.customer_complaint_classification_dimension')}
                </span>
              }
            >
              <div>
                <p>
                  {/* 涉及金额 */}
                  {(Boolean(complaintDetail?.amount) || complaintDetail?.amount === 0) && (
                    <>
                      <span>
                        {t('complaintList.amount_involved_yuan')}: {complaintDetail?.amount}
                      </span>
                      &nbsp;&nbsp;
                    </>
                  )}
                  {/* 批次数量 */}
                  {(Boolean(complaintDetail?.batchQuantity) || complaintDetail?.batchQuantity === 0) && (
                    <>
                      <span>
                        {t('complaintList.batch_quantity_piece')}: {complaintDetail?.batchQuantity}
                      </span>
                      &nbsp;&nbsp;
                    </>
                  )}
                  {/* 舆论影响/其他 */}
                  {Boolean(complaintDetail?.publicOpinion) && (
                    <span>
                      {t('complaintList.public_opinion_influence_other')}: {complaintDetail?.publicOpinion}
                    </span>
                  )}
                </p>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 影响区域范围 */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.influence_area_range')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{complaintDetail?.impactArea}</div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 涉及产品型号(多型号以;分割)  */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.product_model_involved')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{complaintDetail?.minv}</div>
            </Form.Item>
          </Col>
          {/* 涉及产品SN(多个SN以;分割) */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.involves_product_sn')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{complaintDetail?.sysSn}</div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 涉及专家组 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.involved_expert_group')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{complaintDetail?.expertGroupType}</div>
            </Form.Item>
          </Col>
          {/* 客诉类型 */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.complaint_type')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{handleGrade(complaintDetail?.grade)}</div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className="row-border">
          {/* 期望解决时间 */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.expected_solution_time')}</span>}
            >
              <div style={{ wordWrap: 'break-word', padding: '4px' }}>{complaintDetail?.expectResolveTime}</div>
            </Form.Item>
          </Col>
        </Row>
        {/* 客诉状态 */}
        <Row gutter={8} className="row-border">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              className="complaint-detail-label"
              label={<span className="form-item-label">{t('complaintList.complaintStatus')}</span>}
            >
              {showProcessStatus ? <MermaidChart data={complaintStatusData} onOpen={handleOnOpen} /> : null}
            </Form.Item>
          </Col>
        </Row>

        {/* 附件列表 */}
        {complaintDetail?.amsFileList && complaintDetail?.amsFileList.length > 0 ? (
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {complaintDetail?.amsFileList.map((item, index) => {
                return (
                  <div key={index}>
                    <a onClick={() => downloadOrPreviewAttachments(item.fileFullPath)}>{item.fileName}</a>
                  </div>
                );
              })}
            </Col>
          </Row>
        ) : null}
        <br />
        <Row gutter={2}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <ul className="operation-btns">
              {/* 操作列表 */}
              {operateList && operateList.length > 0 ? (
                <li>
                  <Dropdown
                    menu={{
                      items: operateList.map((it) => ({
                        key: it.key,
                        label: it.operationNameShow,
                        onClick: () => urgeHandleComplaint(it),
                        icon: <RightOutlined />,
                      })),
                    }}
                    placement="top"
                    trigger={['click']}
                  >
                    <Button key="operate" shape="round" style={{ width: '100%' }}>
                      {t('complaintList.operate')}
                    </Button>
                  </Dropdown>
                </li>
              ) : null}
            </ul>
          </Col>
        </Row>
      </Form>

      {/* 客诉附件 图片预览和下载 */}
      <Modal
        title={t('complaintList.preview')}
        open={viewImg}
        onCancel={closePreview}
        width="60%"
        maskClosable={false}
        destroyOnClose
        forceRender
        footer={[
          <Button key="download" shape="round" onClick={downLoadFile}>
            {t('complaintList.download')}
          </Button>,
          <Button key="close" shape="round" type="primary" onClick={closePreview}>
            {t('complaintList.close')}
          </Button>,
        ]}
      >
        <div style={{ width: '100%', overflow: 'auto' }}>
          <img src={fileImg} width={fileImgWidth} alt="加载失败" style={{ margin: 'auto', display: 'block' }} />
        </div>
      </Modal>

      {/* 点击操作列表 */}
      <div style={{ margin: '5px auto' }}>
        {/* 处理中 ToHandle（攻坚组去处理） NoForm（tsc用户确认） */}
        {operateFormType == 'ToHandle' || operateFormType == 'NoForm' ? (
          <AttackHandleModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            operateFormType={operateFormType}
            id={complaintsId}
            processingId={processingId}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 处理中 ToHandleWithFile(专家组去处理)  */}
        {operateFormType == 'ToHandleWithFile' ||
        operateFormType === 'ToHandleWithEight' ||
        operateFormType == 'NoFormFinish' ? (
          <ExpertHandleModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            operateFormType={operateFormType}
            id={complaintsId}
            processingId={processingId}
            extendParams={extendParams}
            nodeData={nodeData}
            isOperate={isOperate}
            isEditEightReport={isEditEightReport}
          />
        ) : null}
        {/* 退回 */}
        {operateFormType == 'Back' ? (
          <BackModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            originData={operateType}
            id={complaintsId}
            extendParams={extendParams}
            nodeData={nodeData}
          />
        ) : null}
        {/* 客诉进度更新弹窗 */}
        {operateFormType == 'EmphasisProgress' ? (
          <EmphasisProgressModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            id={key}
            isShowForm={Boolean(extendParams.taskInstanceId)}
          />
        ) : null}
        {/* 编辑 */}
        {operateFormType == 'Edit' ? (
          <ImportKeyCusComModal
            isOpen={operateOpen}
            onClose={handleCanceOperate}
            id={key}
            operateFormType="Edit"
            EditContent={complaintDetail}
          />
        ) : null}
      </div>
    </>
  );
};

export default memo(Details);
