/*
 * @Description: 客户反馈->客诉详情页-> 转研发页面组件
 * @Author: Wayne Hu
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2024/06/24 11:34:05
 */

import React, { memo, useEffect } from 'react';
import { Button, Modal, Form, Input, Row, Col, Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getUrlQuery } from '@/utils/utils';
import FileUpload from '../FileUpload';

interface ChildProps {
  isOpen: any;
  onClose: () => void;
}

const CenterEvaluationTestCases: React.FC<ChildProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const { TextArea } = Input;



  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    let key: string = getUrlQuery('key');
    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
    }
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      /* 获取url中的客诉id */
      let key: string = getUrlQuery('key');
      if (key) {
        document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
      }
    });
  }, [history]);

  const saveData = () => {
    searchForm.validateFields().then((values) => {
      console.log(values);
    });
  };

  return (
    <>
      <Modal
        title={t('complaintList.tip')}
        open={isOpen}
        width="30%"
        maskClosable={false}
        onCancel={onClose}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={onClose}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            <Button key="save" shape="round" type="primary" onClick={saveData}>
              {t('remoteControlMenu.saveBtn')}
            </Button>
          </Row>,
        ]}
      >
        <Form
          layout="vertical"
          form={searchForm}
          name="complaintDetail"
          autoComplete="off"
          style={{ width: '100%' }}
          className=""
        >
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.reason')}</span>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                name="reason"
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.transferTo')}</span>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                name="transferTo"
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="priority"
                label={<span className="form-item-label">{t('complaintList.priority')}</span>}
                initialValue={1}
              >
                <Select
                  placeholder={t('common.pleaseChoose')}
                  options={[
                    {
                      value: 0,
                      label: t('complaintList.high'),
                    },
                    {
                      value: 1,
                      label: t('createComplaint.normal'),
                    },
                    {
                      value: 1,
                      label: t('complaintList.low'),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'left' }}>
              <Form.Item
                name="updateCustomerComplaintProgress"
                label={<span className="form-item-label">{t('complaintList.updateCustomerComplaintProgress')}</span>}
                initialValue={1}
              >
                <Radio.Group>
                  <Radio value={0}> {t('complaintList.Yes')} </Radio>
                  <Radio value={1}> {t('complaintList.No')} </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <p className="tips">
            <strong>{t('complaintList.tips')}</strong>
          </p>
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
              <Form.Item name="attachment1" valuePropName="fileList">
                <FileUpload maxNum={5} onFileListChange={undefined} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(CenterEvaluationTestCases);
