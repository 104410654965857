/*
 * @Description: 客户反馈->客诉列表页->待归档
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/06/06 16:52:38
 * @LastEditTime: 2025/02/07
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Form, Input, Table, Row, Col, Space, Select, Tooltip, Tag, Modal, message } from 'antd';
import { fileDownload } from '@/api/common';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { blobDownloadFile } from '@/constants/common';
import { exportComplaintList, getCustomerComplaintsList } from '@/api/clientFeedback/complaintsList';
import { getComponentList, getModuleList, getContentList, batchArchive } from '@/api/clientFeedback/complaintsList';
import { useHistory, useLocation } from 'react-router-dom';
import { goCustomerComplaintDetails } from './../../util';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ICountryList, SearchParams } from '../../types';
import { ifClient } from '@/utils/utils';
/* 客诉列表table 列定义 */
type ComplaintListItem = {
  id: number; // 客诉单号
  area?: string; // 客诉所属区域
  processingPriority?: any;
  attachment?: string; // 附件1 名称
  attachment2?: string; // 附件2 名称
  attachment3?: string; // 附件3 名称
  attachment4?: string; // 附件4 名称
  attachment5?: string; // 附件4 名称
  attachment6?: string; // 附件4 名称
  complaintsType?: string; // 客诉问题类型
  createServerDatetime?: any; // 创建时间
  creator?: string; // 创建人
  key: string; //
  mbattime?: string; // 电池性能标准到期时间
  minvtime?: string; // 逆变器标准质保到期时间
  postCode?: string; // 邮编
  processingRate?: string; // 客诉进度
  processorGroup?: string; // 当前处理人所在部门/处理方组
  recipient?: string; // 接受处理人/处理方
  currentProcessor?: string;
  status?: number; // 客诉状态
  sysSn: string; // 系统SN
  title?: string; // 标题
  updatetime?: any; // 最后更新时间
  warrantyStatus?: any; // 质保状态
  isCreator: boolean; //是否当前人自己创建的客诉，如果是，则有删除权限
  ticketNumber: string;
  workOrderId: string;
};

/* 客诉列表查询参数 */
let searchParams: SearchParams = {
  type: 2,
  title: '', // 关键字
  pageIndex: 1, // 当前查询页
  pageSize: 10, // 每页显示的数据条数
  ticketNumber: '',
  systemSn: '',
  hasWorkOrder: '', // 是否有工单
  caseStatusList: [], // 客诉状态
  keyword: '', // 关键字
  countryList: [], // 国家列表
};

const { Option } = Select;

const CustomeComplaintBeArchived: React.FC<ICountryList> = ({ countryList }) => {
  const { confirm } = Modal;
  const { t } = useTranslation();
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let userIcdcId = useSelector((state: RootStateOrAny) => state.get('user').get('userIcdcId'));
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  const AlphaCloudSSO = useSelector((state: RootStateOrAny) => state.get('app').get('AlphaCloudSSO'));
  const history = useHistory();
  /* 客诉列表的分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  /* 列表源数据,默认为空 */
  const [dataList, setDataList] = useState<ComplaintListItem[]>([]);
  /* 表格数据加载动画 */
  const [tableLoading, setTableLoading] = useState(false);
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 查询表单的ref */
  const [archiveForm] = Form.useForm();
  /* 导出按钮的loading */
  const [exportLoading, setExportLoading] = useState(false);
  const [selectComplaits, setSelectComplaits] = useState([]);
  /* 获取部件列表 */
  const [componentsList, setComponentsList] = useState<any>([]);
  /* 获取模块列表 */
  const [ModuleList, setModuleList] = useState([]);
  /* 获取问题内容列表 */
  const [contentList, setContentList] = useState([]);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  /* 列表字段 */
  const columns: ColumnsType<ComplaintListItem> = [
    /* 序号 */
    {
      title: t('complaintList.serialNumber2'),
      key: 'serialNumber',
      width: language === 'zh' || language === 'de' || language === 'ja' ? 90 : 180,
      align: 'center',
      fixed: 'left',
      render: (text: any, record: any, index: any) => {
        return (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + index + 1;
      },
    },
    /* 客诉单号 */
    {
      title: t('complaintList.number'),
      key: 'ticketNumber',
      dataIndex: 'ticketNumber',
      width: language === 'sp' || language === 'it' || language === 'en' ? 260 : 230,
      align: 'center',
      fixed: 'left',
      render: (_, record) => [
        <Space key="id">
          {record.ticketNumber ? (
            <Tooltip title={record.ticketNumber}>
              <a
                key="complaintTitle"
                className="customer-comp-list-ellipsis"
                onClick={(event) => jumpToCustomerComplaintDetails(event, record)}
              >
                {record.processingPriority == '-1' ? <Tag color="default">{t('complaintList.low')}</Tag> : null}
                {record.processingPriority == '0' ? <Tag color="warning">{t('complaintList.normal')}</Tag> : null}
                {record.processingPriority == '1' ? <Tag color="error">{t('complaintList.high')}</Tag> : null}
                {record.ticketNumber}
              </a>
            </Tooltip>
          ) : null}
        </Space>,
      ],
    },
    /* 系统SN */
    {
      title: t('complaintList.sysSn'),
      key: 'systemSn',
      dataIndex: 'systemSn',
      width: 200,
      align: 'center',
    },
    /* 标题 */
    {
      title: t('complaintList.title'),
      key: 'title',
      dataIndex: 'title',
      width: 140,
      align: 'center',
      ellipsis: true,
      render: (_, record) => {
        return (
          <a
            key="complaintTitle"
            className="customer-comp-list-ellipsis"
            onClick={(event) => jumpToCustomerComplaintDetails(event, record)}
          >
            {record.title}
          </a>
        );
      },
    },
    /* 国家/地区 */
    {
      title: t('complaintList.area'),
      key: 'country',
      dataIndex: 'country',
      width: 160,
      align: 'center',
    },
    /* 创建时间 */
    {
      title: t('complaintList.createtime'),
      key: 'createServerDatetime',
      dataIndex: 'createServerDatetime',
      width: 200,
      align: 'center',
    },
    /* 创建人 */
    {
      title: t('complaintList.creator'),
      key: 'createAccount',
      dataIndex: 'createAccount',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /** 角色 */
    {
      title: t('complaintList.roleName'),
      key: 'userType',
      dataIndex: 'userType',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /* 客诉状态 */
    {
      title: t('complaintList.complaintStatus'),
      key: 'caseStatus',
      dataIndex: 'caseStatus',
      width: language === 'zh' || language === 'it' ? 150 : 260,
      align: 'center',
      render: (_, record: any) => [
        <Space key="status">
          {record.caseStatus == 10 ? <span>{t('complaintList.created')}</span> : null}
          {record.caseStatus == 20 ? <span>{t('complaintList.customerComplaintProcessing')}</span> : null}
          {record.caseStatus == 30 ? <span>{t('complaintList.closed')}</span> : null}
          {record.caseStatus == 40 ? <span>{t('complaintList.haveDoorToDoorProcessing')}</span> : null}
          {record.caseStatus == 50 ? <span>{t('complaintList.hasBeenArranged')}</span> : null}
          {record.caseStatus == 60 ? <span>{t('complaintList.lab_to_shipped')}</span> : null}
          {record.caseStatus == 70 ? <span>{t('complaintList.lab_has_Shipped')}</span> : null}
          {record.caseStatus == 80 ? <span>{t('complaintList.makeAnAppointment')}</span> : null}
        </Space>,
      ],
    },
    /* 是否是标品 */
    {
      title: t('complaintList.isStandardProduct'),
      key: 'isStandardProducts',
      dataIndex: 'isStandardProducts',
      width: language === 'zh' || language === 'it' ? 150 : 260,
      align: 'center',
      render: (_, record: any) => [
        <Space key="biaopin">
          {record.isStandardProducts == 10 ? (
            <span className="newCreate statusClass">{t('complaintList.isStand')}</span>
          ) : null}
          {record.isStandardProducts == 20 ? (
            <span className="processing statusClass">{t('complaintList.noStand')}</span>
          ) : null}
        </Space>,
      ],
    },
    /* 客诉类型 */
    {
      title: t('complaintList.customerComplaintType'),
      key: 'caseCategory',
      dataIndex: 'caseCategory',
      width: 180,
      align: 'center',
      render: (_, record: any) => [
        <Space key="cumType">
          {record.caseCategory == 10 ? <span>{t('complaintList.systemError')}</span> : null}
          {record.caseCategory == 20 ? <span>{t('complaintList.monitoringDataAnomaly')}</span> : null}
          {record.caseCategory == 30 ? <span>{t('complaintList.technicalConsulting')}</span> : null}
          {record.caseCategory == 40 ? <span>{t('complaintList.complaintTypeoTher')}</span> : null}
        </Space>,
      ],
    },
    /* 客诉进度 */
    {
      title: t('complaintList.processingRate'),
      key: 'handleStatusName',
      dataIndex: 'handleStatusName',
      width: language === 'zh' || language === 'ja' ? 160 : 300,
      align: 'center',
    },

    /* 接受处理人/处理方 */
    {
      title: t('complaintList.recipient'),
      key: 'handleUser',
      dataIndex: 'handleUser',
      width: 150,
      align: 'center',
    },
    /* 问题发生时间	 */
    {
      title: t('complaintList.problemOccurredTime'),
      key: 'isSueoccurrencTime',
      dataIndex: 'isSueoccurrencTime',
      width: 250,
      align: 'center',
    },

    /* 最后更新时间 */
    {
      title: t('complaintList.lastUpdatetime'),
      key: 'lastupdateServerDatetime',
      dataIndex: 'lastupdateServerDatetime',
      width: 250,
      align: 'center',
    },
    /* 附件 */
    {
      title: t('complaintList.attachment'),
      key: 'option',
      align: 'center',
      width: 350,
      render: (_, record: any) => [
        <Space wrap key="attach">
          {record.amsFileList
            ? record.amsFileList.map((item, index) => {
                let num = Number(index) + 1;
                return (
                  <a key={index} onClick={() => downloadAttachment(item.fileFullPath)}>
                    {t('complaintList.attachment') + num}
                  </a>
                );
              })
            : null}
        </Space>,
      ],
    },
  ];

  useEffect(() => {
    document.addEventListener('keydown', searchListBtn, false);
    return () => {
      window.removeEventListener('keydown', searchListBtn, false);
    };
  }, []);

  const searchListBtn = () => {
    let key: any = window.event ? window.event.keyCode : '';
    if (key == 13) {
      getComplaintList();
    }
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载客诉附件
   * @param {*} fileName
   * @return {*}
   */
  const downloadAttachment = (fileName?: string) => {
    let params = {
      blobContainerName: 'FILES',
      fileBlobName: fileName,
    };
    fileDownload(params).then((res: any) => {
      blobDownloadFile(res, fileName);
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 获取当前用户的客诉列表
   * @return {*}
   */
  const getComplaintList = () => {
    setTableLoading(true);
    getCustomerComplaintsList(searchParams)
      .then((res: any) => {
        setTableLoading(false);
        if (res && res.code === 200 && res.data && res.data.rows && res.data.rows.length > 0) {
          setDataList([...res.data.rows]);
          let pageInfo = {
            pageIndex: res.data.pageIndex,
            pageSize: res.data.pageSize,
            total: res.data.total,
          };
          setPagingRecord({ ...pageInfo });
        } else {
          setDataList([]);
          let pageInfo = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pageInfo });
        }
      })
      .catch((err) => {
        setTableLoading(false);
        setDataList([]);
        let pageInfo = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pageInfo });
      });
  };

  /* 客诉列表分页查询 */
  const pageChange = useCallback((currentPage, currentSize) => {
    let params = searchForm.getFieldsValue();
    searchParams = { ...searchParams, ...paramsTrim(params) };
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getComplaintList();
  }, []);

  /* 修改每页显示的数据量 */
  const sizeChange = (current, pageSize) => {
    searchParams.pageSize = pageSize;
    getComplaintList();
  };

  /* 页面挂载的时候，先判断url中是否有参数传入，如果有参数传入则说明是从其他页面跳转过来的，需要请求对应查询条件的数据，如果没有参数传入，则默认请求客诉列表的第一页数据 */
  useEffect(() => {
    // 监听页面刷新事件
    window.addEventListener('beforeunload', handleBeforeUnload);
    if (location.state) {
      searchParams = { ...searchParams, ...(location.state as any) };
      searchForm.setFieldsValue(location.state);
    }
    getComplaintList();
    return () => {
      searchParams = {
        type: 2,
        title: '', // 关键字
        pageIndex: 1, // 当前查询页
        pageSize: 10, // 每页显示的数据条数
        ticketNumber: '',
        systemSn: '',
        hasWorkOrder: '', // 是否有工单
        caseStatusList: [], // 客诉状态
        keyword: '', // 关键字
        countryList: [], // 国家列表
      };
      searchForm.resetFields();
    };
  }, []);

  const handleBeforeUnload = () => {
    let state = '';
    history.replace({ ...history.location, state });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 按钮查询客诉列表
   * @param {*} useCallback
   * @return {*}
   */
  const search = useCallback((values: any) => {
    searchParams.pageIndex = 1;
    paramsTrim(values);
    searchParams = { ...searchParams, ...paramsTrim(values) };
    getComplaintList();
  }, []);

  /**
   * @description: 去除查询参数的前后空格
   * @param {*} values
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const paramsTrim = (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] = typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});

    return trimmedValues;
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 列表数据导出
   * @return {*}
   */
  const exportList = () => {
    setExportLoading(true);
    let params = searchForm.getFieldsValue();
    searchParams = { ...searchParams, ...paramsTrim(params) };
    exportComplaintList(searchParams, roles)
      .then((res) => {
        blobDownloadFile(res);
        setExportLoading(false);
      })
      .catch((error) => {
        setExportLoading(false);
      });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 跳转至创建客诉页面
   * @return {*}
   */
  const jumpToCreateComplaint = () => {
    if (history.location.pathname.indexOf('/innerStatic') < 0) {
      localStorage.setItem('pathname', history.location.pathname);
    }
    history.push({
      pathname: '/createComplaint/index',
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 点击客诉列表中的某一条数据，跳转至客诉的详情页
   * @return {*}
   */
  const jumpToCustomerComplaintDetails = (event, record: any) => {
    goCustomerComplaintDetails(searchParams, record, history);
  };

  /**
   * @description: 已选择的角色
   * @param {*} val
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getUnBindSNchoosed = (val) => {
    setSelectComplaits(val);
  };

  /**
   * @description: 打开批量归档弹窗
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const openArchiveModal = () => {
    if (selectComplaits.length <= 0) {
      message.info(t('archivalMaintenance.pleaseChooseComplaints'));
    } else {
      setIsOpen(true);
    }
  };

  /**
   * @description: 关闭批量归档弹窗
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const cancelHanle = () => {
    archiveForm.resetFields();
    setSelectComplaits([]);
    setIsOpen(false);
  };

  /**
   * @description: 批量归档
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    archiveForm.validateFields().then((values) => {
      let complaintList: any = [];
      selectComplaits &&
        selectComplaits.forEach((item) => {
          complaintList.push({
            complaintId: item,
          });
        });
      batchArchive({ complaintList: complaintList, ...values }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHanle();
          getComplaintList();
        }
      });
    });
  };

  /**
   * @description: 获取部件列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getComponentLists = (e) => {
    getComponentList({ businessLine: e }).then((res: any) => {
      if (res.code == 200 && res.data) {
        setComponentsList(res.data);
      }
    });
  };

  /**
   * @description: 获取模块列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getModuleLists = (val) => {
    if (val) {
      getModuleList({ id: val }).then((res: any) => {
        if (res.code == 200 && res.data) {
          setModuleList(res.data);
        }
      });
    }
    archiveForm.setFieldValue('moduleId', undefined);
    archiveForm.setFieldValue('contentId', undefined);
  };

  /**
   * @description: 获取内容列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getContentLists = (val) => {
    if (val) {
      getContentList({ id: val }).then((res: any) => {
        if (res.code == 200 && res.data) {
          setContentList(res.data);
        }
      });
    }
    archiveForm.setFieldValue('contentId', undefined);
  };

  /**
   * @description: 根据产品线获取部件名称
   * @param {*} e
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const handleComponentLists = (e) => {
    archiveForm.resetFields(['componentId']);
    getComponentLists(e);
  };

  return (
    /* 客诉列表 */
    <>
      <div className="custom-contant">
        <Form
          form={searchForm}
          name="searchForm"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12}>
            {/* 关键词 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="keyword">
                <Input allowClear maxLength={128} placeholder={`${t('complaintList.keyword')}`} />
              </Form.Item>
            </Col>
            {/* 客诉标题 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="title">
                <Input allowClear maxLength={128} placeholder={`${t('complaintList.title')}`} />
              </Form.Item>
            </Col>

            {/* 客诉单号 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="ticketNumber">
                <Input allowClear maxLength={128} placeholder={`${t('complaintList.number')}`} />
              </Form.Item>
            </Col>

            {/* 系统SN */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="systemSn">
                <Input allowClear maxLength={128} placeholder={`${t('complaintList.sysSn')}`} />
              </Form.Item>
            </Col>
            {/* 国家 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="countryList">
                <Select
                  allowClear
                  mode="multiple"
                  maxTagCount={1}
                  placeholder={t('kpiReport.country')}
                  options={countryList?.map((it) => ({
                    value: it.areaEnglishName,
                    label: `${it.areaEnglishName}[${it.areaFirstName}]`,
                  }))}
                />
              </Form.Item>
            </Col>

            {/* 是否有工单 */}
            {!ifClient(roles) ? (
              <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
                <Form.Item name="hasWorkOrder">
                  <Select placeholder={t('complaintList.workOrder')} allowClear>
                    <Option value={true}>{t('common.yes')}</Option>
                    <Option value={false}>{t('common.no')}</Option>
                  </Select>
                </Form.Item>
              </Col>
            ) : null}

            {/* 客诉状态 */}
            <Col xs={24} sm={12} md={4} lg={4} xl={4} className="padding-tope-style">
              <Form.Item name="caseStatusList">
                <Select placeholder={t('complaintList.complaintStatus')} allowClear mode="multiple" maxTagCount={1}>
                  <Option value={10}>{t('complaintList.created')}</Option>
                  <Option value={20}>{t('complaintList.customerComplaintProcessing')}</Option>
                  <Option value={30}>{t('complaintList.closed')}</Option>
                  <Option value={40}>{t('complaintList.haveDoorToDoorProcessing')}</Option>
                  <Option value={50}>{t('complaintList.hasBeenArranged')}</Option>
                  <Option value={60}>{t('complaintList.lab_to_shipped')}</Option>
                  <Option value={70}>{t('complaintList.lab_has_Shipped')}</Option>
                  <Option value={80}>{t('complaintList.makeAnAppointment')}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="padding-tope-style">
              <Form.Item>
                <Space size="middle" wrap key="operaBtn">
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {userIcdcId != 1 ? (
                    <>
                      {/* 新增 */}
                      <Button type="primary" key="add" shape="round" onClick={jumpToCreateComplaint}>
                        {t('common.addBtn')}
                      </Button>
                      {/* 导出 */}
                      <Button
                        type="primary"
                        key="export1"
                        shape="round"
                        htmlType="button"
                        onClick={exportList}
                        loading={exportLoading}
                      >
                        {t('common.export')}
                      </Button>
                      {/* 批量归档 */}
                      <Button
                        type="primary"
                        key="export"
                        shape="round"
                        htmlType="button"
                        onClick={openArchiveModal}
                        loading={exportLoading}
                      >
                        {t('complaintList.batchArchiving')}
                      </Button>
                    </>
                  ) : null}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          key="archive"
          rowSelection={{ type: 'checkbox', onChange: getUnBindSNchoosed, selectedRowKeys: selectComplaits }}
          sortDirections={['ascend', 'descend']}
          columns={columns}
          loading={tableLoading}
          rowKey={(record) => record.id}
          pagination={{
            showQuickJumper: true,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            showSizeChanger: true,
            onShowSizeChange: sizeChange,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 3220, y: window.innerHeight - (AlphaCloudSSO == '1' ? 232 : 350) }}
          className="custom-table-flex"
        />

        <Modal
          title={t('complaintList.batchArchiving')}
          open={isOpen}
          maskClosable={false}
          onCancel={cancelHanle}
          className="customer-details-style"
          destroyOnClose={true}
          footer={[
            <Row gutter={12} justify="end">
              {/* 取消 */}
              <Button key="cancelModal" shape="round" onClick={cancelHanle}>
                {t('common.cancelBtn')}
              </Button>
              {/* 立即执行 */}
              <Button key="saveModal" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            </Row>,
          ]}
        >
          <Form
            layout="vertical"
            form={archiveForm}
            name="archiveForm"
            autoComplete="off"
            style={{ width: '100%' }}
            className=""
          >
            <Row gutter={8} className="row-border">
              {/* 产品线 */}
              <Col span={24}>
                <Form.Item
                  label={t('archivalMaintenance.productLine')}
                  name="businessLine"
                  rules={[
                    {
                      required: true,
                      message: `${t('archivalMaintenance.productLine')}${t('archivalMaintenance.parmasRequired')}`,
                    },
                  ]}
                >
                  <Select
                    placeholder={`${t('common.pleaseChoose')}`}
                    onChange={handleComponentLists}
                    allowClear
                    options={[
                      { value: 10, label: t('archivalMaintenance.householdUse') },
                      { value: 20, label: t('createComplaint.consumerBusiness') },
                      { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                      { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name={'componentId'}
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: `${t('archivalMaintenance.component')}${t('archivalMaintenance.parmasRequired')}`,
                    },
                  ]}
                  label={<span className="form-item-label">{t('archivalMaintenance.component')}</span>}
                >
                  <Select allowClear placeholder={`${t('common.pleaseChoose')}`} onChange={getModuleLists}>
                    {componentsList &&
                      componentsList.length > 0 &&
                      componentsList.map((item: any, index) => {
                        return (
                          <Option value={item.id} key={index}>
                            {item.component}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.componentId !== currentValues.componentId}
                >
                  {({ getFieldValue }) =>
                    getFieldValue('componentId') !== undefined ? (
                      <Form.Item
                        name="moduleId"
                        label={<span className="form-item-label">{t('archivalMaintenance.module')}</span>}
                        rules={[
                          {
                            required: true,
                            message: `${t('archivalMaintenance.module')}${t('archivalMaintenance.parmasRequired')}`,
                          },
                        ]}
                      >
                        <Select allowClear placeholder={`${t('common.pleaseChoose')}`} onChange={getContentLists}>
                          {ModuleList &&
                            ModuleList.length > 0 &&
                            ModuleList.map((item: any, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {item.module}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.moduleId !== currentValues.moduleId}
                >
                  {({ getFieldValue }) =>
                    getFieldValue('moduleId') !== undefined ? (
                      <Form.Item
                        name="contentId"
                        label={<span className="form-item-label">{t('archivalMaintenance.content')}</span>}
                        rules={[
                          {
                            required: true,
                            message: `${t('archivalMaintenance.content')}${t('archivalMaintenance.parmasRequired')}`,
                          },
                        ]}
                      >
                        <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                          {contentList &&
                            contentList.length > 0 &&
                            contentList.map((item: any, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {item.content}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default memo(CustomeComplaintBeArchived);
