/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 用户登录页面
 * @return {*}
 */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Carousel, Form, Input, Button, Row, Col, Spin, message } from 'antd';
import { ILoginSubmit } from '@/models/login';
import { loginSubmit, getUserInfo, getUserMenu } from '@/api/login';
import './index.less';
import { setToken, setUserType, setLoginCoId, getToken } from '@/utils/localStoreUtil';
import { useDispatch } from 'react-redux';
import { updateUserName, updateUserInfo, updateRoles, updateCountry } from '@/actions/user';
import { updateRouters, updateToken } from '@/actions/app';
import { useTranslation } from 'react-i18next';
import Header from './Components/Header';
import { inputValueLimit } from '@/constants/inputValueLimit';
import { useSelector, RootStateOrAny } from 'react-redux';
import { encrypt, decrypt, languageSwitch } from '@/constants/common';
import { handleMenuTree, innerRouters, searchFirstLeafNode, searchMainMenu, getRouters } from '@/utils';
import { updateLanguage, updateCollapsed, updateAlphaCloudSSO } from '@/actions/app';
import { config } from '@/constants/config.js';
import menu from './menu';
import { getUrlSearchParams } from '@/utils/utils';
import MFAVerify from './Components/MFAVerify';

let backImgSrc = [
  {
    src: '/common/loginImages/slider_1.jpg',
  },
  {
    src: '/common/loginImages/slider_2.jpg',
  },
  {
    src: '/common/loginImages/slider_3.jpg',
  },
];

/* 外部静态菜单的路径 */
const whiteNameList: any = [
  '/login', // 登录
  '/register', // 注册
  '/newRegisterSecond', // 注册第二步
  '/shareRegister', // 分享用户注册
  '/forgetPassword', // 忘记密码
  '/resetPassword', // 重置密码
  '/forgetUserName', // 忘记用户名
  '/RegisterActive', // 注册成功激活
  '/RegisterStep', // 注册成功激活
  '/forgetUserNameSecond', // 忘记用户名
  '/UpdateRemoteUpgrade', // 开启远程升级
];

const Login: React.FC<any> = (props): React.ReactElement => {
  /* 登录表单 */
  const [loginForm] = Form.useForm();
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  const currentRoutes = useSelector((state: RootStateOrAny) => state.get('app').get('routers'));
  const AlphaCloudSSO = useSelector((state: RootStateOrAny) => state.get('app').get('AlphaCloudSSO'));
  const [spinLoading, setSpinLoading] = useState(AlphaCloudSSO == '1' ? true : false);
  const [loginData, setLoginData] = useState<any>({});
  const [showMFA, setShowMFA] = useState<any>(false);

  //老版本售后跳转登录页面loading
  const [loading, setLoading] = useState<any>(false);
  /* 过滤路由表，只保留外部静态菜单 */
  const handleRoutes = (routes: any) => {
    let routesReturn: any = [];
    for (let i = 0; i < routes.length; i++) {
      if (whiteNameList.includes(routes[i].path)) {
        routesReturn.push(routes[i]);
      }
    }
    return routesReturn;
  };
  const oldRouters = handleRoutes(currentRoutes);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let [isUseEffectFirst, setIsUseEffectFirst] = useState(true);

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 提交登录表单
   * @param {ILoginSubmit} values
   * @return {*}
   */
  const onFinish = (values: ILoginSubmit | any): any => {
    let params = {
      username: values.username,
      password: encrypt(values.password.trim(), values.username.trim()),
    };
    loginSubmits(params);
  };

  /**
   * @Author: YuKi
   * @description: 调用登录接口
   * @param {*} params
   * @return {*}
   */
  const loginSubmits = async (params: any, token?: string) => {
    let currentToken: string;
    currentToken = '';
    if (token) {
      currentToken = token;
    } else {
      const rep: any = await loginSubmit({ ...params });
      if (rep && rep.code === 200 && rep.data) {
        setLoginData(rep.data);
        setShowMFA(rep.data.needMfa);
        if (!rep.data.needMfa) {
          currentToken = rep.data.token;
        }
      }
    }
    getUserInfoPage(currentToken);
  };

  const getUserInfoPage = async (currentToken: string, mainPage?: string, userInfo?: any, language?: string) => {
    if (!currentToken) {
      return;
    }
    setToken(currentToken);
    dispatch(updateToken(currentToken));
    let userInfoData: any;
    if (userInfo && AlphaCloudSSO === '1') {
      userInfoData = { code: 200, data: JSON.parse(userInfo) };
    } else if (AlphaCloudSSO !== '1') {
      userInfoData = await getUserInfo();
    } else {
      return;
    }

    try {
      if (userInfoData.code == 200 && userInfoData.data) {
        dispatch(updateUserName(userInfoData.data.username));
        dispatch(updateCountry(userInfoData.data.country));
        dispatch(updateUserInfo(userInfoData.data));
        dispatch(updateRoles(userInfoData.data.roles));
        let languageCode: any = '';
        languageCode = languageSwitch(language ? language : userInfoData.data.languageCode);
        i18n.changeLanguage(languageCode);
        dispatch(updateLanguage(languageCode));
        dispatch(updateCollapsed(true));
        localStorage.setItem('language', languageCode);

        /* 获取当前用户的左侧菜单 */
        let backMenu: any = [];
        getUserMenu().then((res: any) => {
          if (res && res.code === 200 && res.data && res.data.menu) {
            if (res.data.menu.length === 0) {
              message.error(t('register.contactoAdministrador'));
              return;
            }
            localStorage.setItem('routers', JSON.stringify(res.data.menu));
            backMenu = handleMenuTree(res.data.menu, 1, '');

            /* 如果是从邮件跳转至售后系统，登录成功之后跳转至指定页面 */
            if (getUrlSearchParams('redirect')) {
              let path: any = getUrlSearchParams('redirect');
              let key: any = getUrlSearchParams('key');
              if (key) {
                history.push(path + '?key=' + key);
              } else {
                history.push(path);
              }
            } else if (mainPage) {
              localStorage.setItem('mainPage', mainPage);
              history.push(mainPage);
            } else {
              if (menu.mainMenu && menu.mainMenu.menuUrl) {
                /* 在树形菜单中查找默认主菜单，如果找到，则将当前默认主页面路径放入localStorage，如果没有找到，则将树形菜单的第一个菜单作为默认主页面放入localStorage */
                let mainMenuFlag: any = searchMainMenu(backMenu, menu.mainMenu.menuUrl);
                if (mainMenuFlag) {
                  localStorage.setItem('mainPage', menu.mainMenu.menuUrl);
                } else {
                  let mainPageUrl: string = searchFirstLeafNode(backMenu);
                  localStorage.setItem('mainPage', mainPageUrl);
                }
              } else {
                /* 如果没有设置主菜单，则将树形菜单的第一个菜单作为默认主菜单放入localStorage */
                let mainPageUrl: string = searchFirstLeafNode(backMenu);
                localStorage.setItem('mainPage', mainPageUrl);
              }
            }
            let newRouters = [...oldRouters, ...backMenu, ...innerRouters];
            dispatch(updateRouters(newRouters));
          }
        });
        setSpinLoading(false);
        setLoading(false);
      } else {
        setSpinLoading(false);
        setLoading(false);
      }
    } catch (error) {
      setSpinLoading(false);
      setLoading(false);
    }
  };
  const receiveAlphaCloudToken = (event: any) => {
    if (config.alphaCloudSSO[location.origin] == event.origin) {
      // event.data.dataType == 3 接受来自云平台的token
      if (event.data && event.data.dataType == 3 && event.data.token && event.data.toPage) {
        getUserInfoPage(event.data.token, event.data.toPage, event.data.userInfo);

        dispatch(updateAlphaCloudSSO(event.data.AlphaCloudSSO));
      }
    }
  };

  useEffect(() => {
    // isOld为1代表从老版本客诉跳转过来
    if (getUrlSearchParams('isOld') == '1') {
      setLoading(true);
      let params = {
        username: decrypt(decodeURIComponent(getUrlSearchParams('username') || ''), 'jToewewerrewr@233221'),
        password: decrypt(decodeURIComponent(getUrlSearchParams('pwd') || ''), 'jToewewerrewr@233221'),
      };
      loginSubmits(params);
    }
    if (getUrlSearchParams('token') && getUrlSearchParams('isAlphaCloud') == '1') {
      setLoading(true);
      loginSubmits({}, getUrlSearchParams('token') || '');
    }
    window.addEventListener('message', receiveAlphaCloudToken, false);
    return () => {
      // window.removeEventListener('message',receiveAlphaCloudToken)
    };
  }, []);
  /**
   * @Author: YuKi
   * @description: 根据语言切换动态切换样式
   * @return {*}
   */
  useEffect(() => {
    window.addEventListener('message', receiveAlphaCloudToken, false);
    if (isUseEffectFirst) {
      setIsUseEffectFirst(false);
      return;
    }
    let tempError = loginForm.getFieldsError();
    for (let index = 0; index < tempError.length; index++) {
      const element = tempError[index];
      if (element.errors && element.errors.length > 0) {
        loginForm.validateFields(element.name);
      }
    }
  }, [language]);

  useEffect(() => {
    let queryString = window.location.search;
    let urlParams: any = '';
    if (new URLSearchParams(queryString)) {
      urlParams = new URLSearchParams(queryString).get('isNewVersion');
    }
    if (urlParams) {
      localStorage.setItem('isNewVersion', urlParams);
    }
    let isNewVersion = localStorage.getItem('isNewVersion');
    if (isNewVersion && isNewVersion === '0') {
      window.location.href = config.oldVersionUrl;
    }
    return () => {
      setSpinLoading(false);
      setLoading(false);
    };
  }, []);
  const handleMFA = () => {
    setShowMFA(false);
  };
  return (
    <>
      <Spin spinning={spinLoading || loading} className="login-suspense-spin-loading">
        <div className="login-contant" id="login-contant">
          <Header flag="Login" />
          {showMFA ? (
            <MFAVerify data={loginData} getUserInfoPage={getUserInfoPage} handleMFA={handleMFA} />
          ) : (
            <div className="login-container">
              <Form
                name="login-from"
                className="login-from"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
                size="large"
                form={loginForm}
              >
                {/* logo */}
                <Row>
                  {language === 'zh' ? (
                    <img src="/common/loginImages/new_logo.png" width="110" />
                  ) : (
                    <img src="/common/loginImages/new_logo_en.png" width="110" />
                  )}
                </Row>
                <Row>
                  {/* 账号 */}
                  <Col span={24}>
                    <Form.Item
                      label={t('loginLang.account')}
                      name="username"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || !value.trim()) {
                              return Promise.reject(t('loginLang.usernameIsRequired'));
                            } else if (value && value.trim() && value.trim().length > inputValueLimit.maxEmail) {
                              return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxEmail }));
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input placeholder={`${t('loginLang.pleaseEnterUserNameOrEmail')}`} />
                    </Form.Item>
                  </Col>

                  {/* 密码 */}
                  <Col span={24}>
                    <Form.Item
                      label={t('loginLang.password')}
                      name="password"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || !value.trim()) {
                              return Promise.reject(t('loginLang.passwordIsRequired'));
                            } else if (value && value.trim() && value.trim().length > inputValueLimit.maxPassword) {
                              return Promise.reject(
                                t('common.maxlengLimit', { maxLength: inputValueLimit.maxPassword }),
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder={`${t('loginLang.pleaseEnterPassword')}`} />
                    </Form.Item>
                  </Col>
                  {/* 登录 */}
                  <Col span={24}>
                    <Form.Item className="login-submit-btn">
                      <Button style={{ width: '100%' }} type="primary" shape="round" htmlType="submit">
                        {t('loginLang.login')}
                      </Button>
                    </Form.Item>
                  </Col>
                  {/* 版权信息 */}
                  <Col span={24} className="login-footer">
                    <div>All rights reserved © Alpha ESS Co., Ltd.</div>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
          <div className="login-background">
            <Carousel autoplay>
              {backImgSrc.map((element, index) => {
                return (
                  <div key={index}>
                    <img className="login-bg-img" src={element.src} alt="" />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default memo(Login);
