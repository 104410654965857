import React, { memo, useState, useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { encrypt, getRequestValue } from '@/constants/common';
import './index.less';
import { inputValueLimit } from '@/constants/inputValueLimit';
import { checkShareLink, shareRegister } from '@/api/login';
import { useDispatch } from 'react-redux';
import { updateLanguage } from '@/actions/app';
import { useHistory } from 'react-router-dom';
let days = getRequestValue('Days');
let sharer = getRequestValue('Sharer');
let hour = getRequestValue('Hour');
let sn = getRequestValue('sn');
let language = getRequestValue('Language');
const ShareRegister: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [shareRegisterForm] = Form.useForm();
  const [formData, setFormData] = useState<any>({
    userName: '',
    password: '',
    confirmPassword: '',
    hour: '',
    days: '',
    day: '',
    language: '',
    sn: '',
    Name: '',
    email: '',
  });
  useEffect(() => {
    setFormData((formData) => {
      return {
        ...formData,
        Sharer: getRequestValue('Sharer'),
        hour: getRequestValue('Hour'),
        days: getRequestValue('Days'),
        sn: getRequestValue('sn'),
        language: getRequestValue('Language'),
      };
    });
    if (language) {
      let code = language.split('-')[0].toLowerCase();
      i18n.changeLanguage(code);
      dispatch(updateLanguage(code));
      localStorage.setItem('language', code);
    } else {
      localStorage.setItem('language', 'en');
    }
    checkAccount();
  }, []);
  /**
   * @description: 注册分享用户连接是否可靠
   * @return {*}
   * @LastEditors: April
   */
  const checkAccount = () => {
    checkShareLink({
      days,
      sharer,
      hour,
      sn,
    })
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setFormData((formData) => {
            return {
              ...formData,
              Name: rep.data.name,
              day: rep.data.days,
            };
          });
        } else {
          history.push({ pathname: '/login' });
        }
      })
      .catch((error) => {
        history.push({ pathname: '/login' });
      });
  };
  /**
   * @description: 立即注册
   * @return {*}
   * @LastEditors: April
   */
  const submitForm = () => {
    shareRegisterForm.validateFields().then((params) => {
      setFormData((formData) => {
        return {
          ...formData,
          userName: params.email,
          password: encrypt(params.password.trim(), params.email.trim()),
          confirmPassword: encrypt(params.confirmPassword.trim(), params.email.trim()),
        };
      });
      let paramsObj: any = {};
      (paramsObj.userName = params.email),
        (paramsObj.password = encrypt(params.password.trim(), params.email.trim())),
        (paramsObj.confirmPassword = encrypt(params.confirmPassword.trim(), params.email.trim())),
        (paramsObj.days = formData.days),
        (paramsObj.sn = formData.sn),
        (paramsObj.sharer = formData.Sharer),
        (paramsObj.hour = formData.hour),
        shareRegister(paramsObj).then((rep: any) => {
          if (rep && rep.code == 200) {
            message.success(t('shareRegister.registerSuccess'));
            history.push({ pathname: '/login' });
            shareRegisterForm.resetFields();
          }
        });
    });
  };
  return (
    <>
      <div style={{ padding: '80px 30% 0px', height: '100%', width: '100%', backgroundColor: '#f0f2f5' }}>
        <Form
          form={shareRegisterForm}
          name="shareRegisterForm"
          layout={'vertical'}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#fff' }}
        >
          <Form.Item style={{ textAlign: 'center' }}>
            <strong style={{ fontSize: '20px' }}>{t('shareRegister.register')}</strong>
          </Form.Item>
          <Form.Item label={t('shareRegister.shareUser')} name="shareUser">
            <span>{formData.Name}</span>
          </Form.Item>
          <Form.Item label={t('shareRegister.expired')} name="expired">
            <span>{formData.day}</span>
          </Form.Item>
          {/* 用户名（邮箱） */}
          <Form.Item
            name="email"
            validateTrigger="onBlur"
            label={t('shareRegister.username') + '(' + t('shareRegister.registeredEmail') + ')'}
            rules={[
              {
                required: true,
                validator: (rule, value, callback) => {
                  let emailRegExp = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z0-9_\-\+]{2,8})$/;
                  if (!value) {
                    return Promise.reject(t('shareRegister.emailRequired'));
                  } else if (!emailRegExp.test(value)) {
                    return Promise.reject(t('shareRegister.emailError'));
                  } else if (value && value.length > inputValueLimit.maxEmail) {
                    return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxEmail }));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input placeholder={`${t('shareRegister.enterRegisteredEmail')}`} allowClear></Input>
          </Form.Item>
          {/* 密码 */}
          <Form.Item
            name="password"
            validateTrigger="onBlur"
            label={t('shareRegister.password')}
            rules={[
              { required: true, message: `${t('shareRegister.passwordRequired')}` },
              {
                validator: (rule, value) => {
                  if (value && value.length > inputValueLimit.masSharePwd) {
                    return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.masSharePwd }));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input allowClear placeholder={`${t('shareRegister.enterPIN')}`} type="password"></Input>
          </Form.Item>
          {/* 确认密码 */}
          <Form.Item
            name="confirmPassword"
            validateTrigger="onBlur"
            label={t('shareRegister.password2')}
            rules={[
              { required: true, message: `${t('shareRegister.password2Required')}` },
              {
                validator: (rule, value) => {
                  if (value && value.length > inputValueLimit.masShareConfirmPwd) {
                    return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.masShareConfirmPwd }));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input allowClear placeholder={`${t('shareRegister.confirmPassword')}`} type="password"></Input>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                submitForm();
              }}
            >
              {t('shareRegister.activeRegister')}
            </Button>
            <p className="more-message">
              <a href="https://www.alphaess.com/contact-us" target="_blank">
                {t('shareRegister.ContactInfo')}
              </a>
              <a href="https://www.alphaess.com/enPrivacyPolicy.html" target="_blank">
                {t('shareRegister.enPrivacyPolicy')}
              </a>
            </p>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default memo(ShareRegister);
