import React, { useEffect } from 'react';
import { fileUploadARMS } from '@/api/common';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Upload, UploadFile, message } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UploadFileExt extends UploadFile {
  /** 文件容器名称 */
  blobContainerName?: string;
  /** 文件路径 */
  fileFullPath?: string;
  /** 文件名 */
  fileName?: string;
  /** 文件大小 */
  fileSize?: number;
}
/** 文件个数限制 */
const FILE_COUNT_LIMIT = 3;
/** 文件大小限制 */
const FILE_SIZE_LIMIT = 80 * 1024 * 1024;
interface IProps {
  disabled?: boolean;
  amsFileList?: UploadFileExt[];
}

const UploadFileComponent = (props: IProps) => {
  const { disabled, amsFileList } = props;
  const { t } = useTranslation();
  const [uploadedFileNum, setUploadedFileNum] = useState(0);

  /**
   * @Author: Stephen
   * @description: 将upload组件的event值转为字段值
   * @param {any} e
   */
  const normFile = (e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  /**
   * @Author: Stephen
   * @description: 验证上传文件
   * @param {RcFile} file
   */
  const beforeUpload = (file: RcFile) => {
    // 限制上传exe文件
    if (['application/x-msdownload', 'application/vnd.microsoft.portable-executable'].includes(file.type)) {
      message.error(t('not_allowed_to_upload_exe_file'));
      return Upload.LIST_IGNORE;
    }
    // 限制上传文件大小
    if (file.size > FILE_SIZE_LIMIT) {
      return false;
    }
    return true;
  };

  /**
   * @Author: Stephen
   * @description: 上传文件
   * @param {UploadRequestOption} options
   */
  const handleUpload = async (options: UploadRequestOption) => {
    const { file, onSuccess } = options;
    const customFile = file as UploadFileExt;
    const formData = new FormData();
    formData.append('fileList', file);
    formData.append('blobContainerName', 'FILES');

    const data: any = await fileUploadARMS(formData);
    if (data) {
      customFile.blobContainerName = data.data[0].blobContainerName;
      customFile.fileFullPath = data.data[0].fileSaveName;
      customFile.fileName = data.data[0].fileName;
      customFile.fileSize = data.data[0].fileSize;
    }
    onSuccess && onSuccess('ok');
  };

  return (
    <Form.Item
      label={<span className="form-item-label">{t('complaintList.upload_attachments')}</span>}
      name="amsFileList"
      valuePropName="fileList"
      getValueFromEvent={normFile}
    >
      <div></div>
      <Upload
        beforeUpload={beforeUpload}
        customRequest={handleUpload}
        maxCount={FILE_COUNT_LIMIT}
        showUploadList={{
          showDownloadIcon: true,
          downloadIcon: <DownloadOutlined />,
        }}
      >
        <Button shape="round" disabled={disabled}>
          {t('complaintList.upload_attachments')}
        </Button>
      </Upload>
    </Form.Item>
  );
};

export default UploadFileComponent;
