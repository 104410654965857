/*
 * @Description: VPP用户关联系统
 * @Author: Wayne.Hu
 * @LastEditors: Summer.Chen
 * @Date: 2023/06/05 14:20:09
 * @LastEditTime: 2023/06/06 14:56:53
 */
import React, { memo, useState, useEffect } from 'react';
import { Button, Checkbox, Form, Input, Row, Col, Modal, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getUserAgreement, VppAssociateSystem } from '@/api/login';
import { inputValueLimit } from '@/constants/inputValueLimit';
import { emailReg } from '@/constants/regRules';
import { useSelector, RootStateOrAny } from 'react-redux';
import Header from '../Header';
import './index.less';

/* Vpp用户关联系统 */
const VppAssociatedSystem: React.FC = () => {
  /* 引入翻译 */
  const { t } = useTranslation();
  /* 引入history */
  const history = useHistory();
  /* 获取当前系统的语言 */
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* Vpp用户注册表单的ref */
  const [associateSnForm] = Form.useForm();
  /* 服务条款展示标识 */
  const [isProvicyOpen, setIsProvicyOpen] = useState(false);
  /* 服务条款标题*/
  const [provicyTitle, setProvicyTitle] = useState<any>('');
  /* 服务条款内容*/
  const [provicyContent, setProvicyContent] = useState<any>('');

  /* 根据语言动态切换样式 */
  useEffect(() => {
    let tempError = associateSnForm.getFieldsError();
    for (let index = 0; index < tempError.length; index++) {
      const element = tempError[index];
      if (element.errors && element.errors.length > 0) {
        associateSnForm.validateFields(element.name);
      }
    }
  }, [language]);

  /* 获取服务条款 */
  const getProvicyItem = () => {
    getUserAgreement().then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        let description = rep.data;
        setProvicyTitle(description.split('\n')[0]);
        setProvicyContent(description);
      } else {
        setProvicyTitle('');
        setProvicyContent('');
      }
    });
  };
  /* 关闭服务条款 */
  const handleProvicyCancel = () => {
    setIsProvicyOpen(false);
  };
  /* 初始化下拉数据源 */
  useEffect(() => {
    getProvicyItem(); /* 获取用户协议 */
    if (associateSnForm) {
      associateSnForm.setFieldValue('vppId', getUrlQuery('vppId'));
      associateSnForm.setFieldValue('userName', getUrlQuery('userName'));
      associateSnForm.setFieldValue('sysSn', getUrlQuery('sysSn'));
    }
  }, []);

  // 获取url中的参数值
  const getUrlQuery = (params) => {
    let regExp = new RegExp('(^|&)' + params + '=([^&]*)(&|$)');
    let r = decodeURIComponent(document.location.search.substring(1)).match(regExp);
    if (r && r[2]) {
      return r[2];
    }
  };

  /* 返回登录页 */
  const goBackButton = () => {
    history.push({ pathname: '/login' });
  };

  /* 提交绑定系统的表单 */
  async function onFinish(values: any) {
    let dataList = {
      ...values,
      allowAutoUpdate: values.allowAutoUpdate ? 1 : 0,
    };

    VppAssociateSystem(dataList).then((rep: any) => {
      if (rep && rep.code === 200) {
        message.success(t('register.associateSuccess'));
        history.push({
          pathname: '/login',
        });
      }
    });
  }

  return (
    <div className="register-container">
      <Header flag="register" />
      {/* 公司logo */}
      <div className="logo-box">
        {language === 'zh' ? (
          <img src="/common/loginImages/new_logo.png" width="110" />
        ) : (
          <img src="/common/loginImages/new_logo_en.png" width="110" />
        )}
      </div>
      <div className="regist-form">
        <Form
          form={associateSnForm}
          name="associateSnForm"
          layout={'vertical'}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ lgCode: 'zh-CN', allowAutoUpdate: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={8}>
            <h2 className="username-register">{t('register.vppUserAssociateSystem')}</h2>
          </Row>
          <Row gutter={8}>
            {/* 用户名 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('register.username')}
                name="userName"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('register.usernameRequired'));
                      } else if (
                        value &&
                        value.trim() &&
                        (value.trim().length < inputValueLimit.minEmail ||
                          value.trim().length > inputValueLimit.maxEmail)
                      ) {
                        return Promise.reject(
                          t('common.lengthLimit', {
                            maxLength: inputValueLimit.maxEmail,
                            minLength: inputValueLimit.minEmail,
                          }),
                        );
                      } else if (value && value.trim() && !emailReg.test(value.trim())) {
                        return Promise.reject(t('common.emailStyleError'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input disabled allowClear placeholder="someone@example.com" />
              </Form.Item>
            </Col>
            {/* 系统SN */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="sysSn"
                label={t('common.SNKey')}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('common.snIsRequired'));
                      } else if (value && value.trim() && value.trim().length > inputValueLimit.maxSn) {
                        return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxSn }));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input disabled allowClear placeholder={`${t('common.pleaseEntertheEystemSN')}`} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* SN校验码*/}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="checkCode"
                label={t('register.snCheckCode')}
                tooltip={{
                  title: (
                    <div
                      style={{ width: '300px', overflowY: 'scroll' }}
                      dangerouslySetInnerHTML={{
                        __html: `
                        <span>${t('register.checkCodeDetail')}</span>
                        <br />
                        <img src="/common/img/checkcode.png" width="280px"/>`,
                      }}
                    ></div>
                  ),
                  icon: <InfoCircleOutlined />,
                  placement: 'right',
                }}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('register.snCheckRequired'));
                      } else if (value && value.trim() && value.trim().length > inputValueLimit.maxSnCheckRequired) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxSnCheckRequired }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear placeholder={`${t('register.snCheckRequired')}`} />
              </Form.Item>
            </Col>

            {/* vppId */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item hidden={true} name="vppId" label="VppID">
                <Input disabled></Input>
              </Form.Item>
            </Col>

            {/* 是否允许自动更新 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name="allowAutoUpdate" valuePropName="checked">
                <Checkbox className="checked-style">{t('register.VppAutoupload')}</Checkbox>
              </Form.Item>
            </Col>

            {/* 隐私条款 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="primaryPolicy"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error(`${t('register.pleaseCheckPrivacyPolicy')}`)),
                  },
                ]}
              >
                <Checkbox className="checked-style">
                  <div>
                    {t('register.AgreeTerms')}
                    <a onClick={() => setIsProvicyOpen(true)}>{t('register.TermsOfService')}</a>
                    {t('register.and')}
                    <a href="https://www.alphaess.com/enPrivacyPolicy.html" target="_blank">
                      《{t('register.PrivacyPolicy')}》
                    </a>
                  </div>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item>
                {/* 返回 */}
                <Button style={{ width: '100%' }} key="goback" shape="round" htmlType="button" onClick={goBackButton}>
                  {t('common.goback')}
                </Button>
              </Form.Item>
            </Col>
            {/* 提交 */}
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <Form.Item>
                <Button style={{ width: '100%' }} type="primary" key="submit" htmlType="submit" shape="round">
                  {t('common.submitBtn')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Modal
        title={provicyTitle}
        open={isProvicyOpen}
        onOk={handleProvicyCancel}
        onCancel={handleProvicyCancel}
        style={{ width: '50%', minWidth: '200px', top: '20%' }}
        maskClosable={false}
        footer={[
          <Button key="confirmBtn" shape="round" type="primary" onClick={handleProvicyCancel}>
            {t('common.confirmBtn')}
          </Button>,
        ]}
      >
        <div className="provicy-style">
          <pre>{provicyContent}</pre>
        </div>
      </Modal>
    </div>
  );
};

export default memo(VppAssociatedSystem);
