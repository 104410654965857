/*
 * @Description: 客户反馈->客诉详情页-> 转质控
 * @Author: Wayne Hu
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2024/06/24 13:21:20
 */

import React, { memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Row, Col, message, Select, DatePicker, InputNumber, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { saveTransferQuality, getQualityForm, getFormInfo } from '@/api/clientFeedback/complaintsList';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { tinymceFormatLanguage } from '@/utils';
import FileUpload from '../FileUpload';
import { config } from '@/constants/config.js';
import { fileUpload } from '@/api/common';
import PreViewModal from '../PreViewModal';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';

const tinyKey = '0990dde4fa3e0f907ddaba1e7cf78810421a434e14f1bcc56d46fcc009e711f2';
const { Option } = Select;

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
}
/* 富文本内容 */
let tinyContent: any = '';

const QualityCustomerComplaints: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  id,
  extendParams,
  nodeData,
}) => {
  const { t } = useTranslation();
  const [editorNow, setEditorNow] = useState<any>('');

  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* 附件列表 */
  const [fileLists, setFileLists] = useState([]);
  /* 预览弹窗的开关 */
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  /* 点击文件 */
  const [fileItem, setFileItem] = useState<any>();

  /* 查询表单的ref */
  const [searchForm] = Form.useForm();

  useEffect(() => {
    if (nodeData) {
      getQualityForms(nodeData.data.id);
    } else {
      getFormInfos();
    }
  }, []);

  /**
   * @description: 获取表单初始内容
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getFormInfos = () => {
    getFormInfo({ key: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        res.data.feedbackDate = moment(res.data.feedbackDate);
        searchForm.setFieldsValue(res.data);
      }
    });
  };

  /**
   * @description: 获取表单内容
   * @param {*} id
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getQualityForms = (id) => {
    getQualityForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        res.data.feedbackDate = moment(res.data.feedbackDate);
        searchForm.setFieldsValue(res.data);
        setFileLists(res.data.amsFileList);
      }
    });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 下载或预览客诉附件
   * @param {string} fileName
   * @return {*}
   */
  const downloadOrPreviewAttachments = (fileItem: any) => {
    setFileItem(fileItem);
    setViewOpen(true);
  };

  /**
   * @description: 关闭提示权限窗口
   * @return {*}
   * @LastEditors: April
   */
  const handleCancePreview = () => {
    setViewOpen(false);
  };

  /**
   * @description: 监听富文本变化
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const handleEditorChange = (content: any, editor: any) => {
    tinyContent = content;
  };

  /**
   * @description: 保存转质控表单
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    searchForm.validateFields().then((values) => {
      if (!tinyContent) {
        return message.error(
          t('energyStorage.operationLog.repairSuggestions') + t('archivalMaintenance.parmasRequired'),
        );
      }
      let amsFileLists: any = [];
      values.amsFileList?.forEach((item) => {
        amsFileLists.push({
          blobContainerName: item.response[0].blobContainerName,
          fileFullPath: item.response[0].fileSaveName,
          fileName: item.response[0].fileName,
          fileSize: item.response[0].size,
        });
      });
      let transferParams = [];
      if (values.taskAuthData && originData.nextOperateAuthList.length > 1) {
        transferParams = originData.nextOperateAuthList.filter((item) => {
          return item.id == values.taskAuthData;
        });
      } else {
        transferParams = originData.nextOperateAuthList;
      }
      saveTransferQuality({
        complaintsId: id,
        ...values,
        description: tinyContent,
        amsFileList: amsFileLists,
        ...originData,
        taskAuthData: transferParams ? transferParams[0] : null,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHandle();
        }
      });
    });
  };

  /**
   * @description: 回显FileUpload组件上传的文件
   * @param {*} newFileList
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const handleFileListChange = (newFileList) => {
    searchForm.setFieldValue('amsFileList', newFileList);
    searchForm.validateFields(['amsFileList']);
  };

  /**
   * @description: 取消回调
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const cancelHandle = () => {
    searchForm.resetFields();
    tinyContent = '';
    onClose();
  };

  /**
   * @description: 富文本上传
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const tinymceUploadImage = (blobInfo, succFun, failFun) => {
    const formData = new FormData();
    // 服务端接收文件的参数名，文件数据，文件名
    formData.append('blobContainerName', 'FILES');
    formData.append('fileList', blobInfo.blob(), blobInfo.filename());
    fileUpload(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          let uploadUrl = '';
          if (window.location.origin.indexOf('localhost') != -1) {
            uploadUrl = config.downUrl + 'files/';
          } else {
            uploadUrl = config.downUrl + 'files/';
          }
          succFun(uploadUrl + res.data[0].fileSaveName);
        } else {
          failFun(t('createComplaint.fileUploadError'));
        }
      })
      .catch(() => {
        failFun(t('createComplaint.fileUploadError'));
      });
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData.data.currentStatusShowName}
        open={isOpen}
        width="50%"
        style={{ zIndex: 100 }}
        maskClosable={false}
        onCancel={cancelHandle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHandle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form
          layout="vertical"
          form={searchForm}
          name="complaintDetail"
          autoComplete="off"
          style={{ width: '100%' }}
          className=""
          disabled={nodeData ? true : false}
        >
          <Row gutter={8} className="row-border">
            {/* 客诉所属地 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.customerLocation')}</span>}
                name="customerLocation"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('complaintList.customerLocationRequired')}`, whitespace: true },
                ]}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 产品名称 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.productSpecifications')}</span>}
                name="minv"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('complaintList.productSpecificationsRequired')}`, whitespace: true },
                ]}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 不良数量 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.defectQuantity')}</span>}
                name="defectQuantity"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.defectQuantityRequired')}` }]}
              >
                <InputNumber max={100000} style={{ width: '100%' }} placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            {/* 项目(客户) 名称 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.customerName')}</span>}
                name="customerName"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.customerNameRequired')}`, whitespace: true }]}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 产品SN */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.productSN')}</span>}
                name="systemSn"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.productSNRequired')}`, whitespace: true }]}
              >
                <Input placeholder={`${t('common.pleaseEnter')}`} />
              </Form.Item>
            </Col>
            {/* 处理人 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="handler"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}`, whitespace: true }]}
                label={<span className="form-item-label">{t('complaintList.complaintListHandler')}</span>}
              >
                <Input placeholder={`${t('complaintList.complaintListHandlerRequired')}`} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            {/* 客户反馈日期 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                // className="complaint-detail-label"
                name="feedbackDate"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.feedbackDateRequired')}` }]}
                label={
                  <span className="form-item-label" style={{ whiteSpace: 'pre-wrap' }}>
                    {t('complaintList.feedbackDate')}
                  </span>
                }
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            {/* 客诉编号 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                // className="complaint-detail-label"
                name="ticketNumber"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}`, whitespace: true }]}
                label={<span className="form-item-label">{t('complaintList.complaintNumber')}</span>}
              >
                <Input placeholder={`${t('complaintList.complaintNumberRequired')}`} />
              </Form.Item>
            </Col>
            {/* 是否安装 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                // className="complaint-detail-label"
                name="isInstalled"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.install(Yes/No)Required')}` }]}
                label={<span className="form-item-label">{t('complaintList.install(Yes/No)')}</span>}
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  style={{ width: '100%' }}
                  options={[
                    { value: 1, label: t('complaintList.Yes') },
                    { value: 0, label: t('complaintList.No') },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="row-border">
            {/* 运行时长 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="runTime"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                label={<span className="form-item-label">{t('complaintList.runTime')}</span>}
              >
                <InputNumber
                  max={100000}
                  style={{ width: '100%' }}
                  placeholder={`${t('complaintList.runTimeRequired')}`}
                />
              </Form.Item>
            </Col>
            {/* 投诉区域 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="complaintArea"
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('complaintList.complaintAreaRequired')}` }]}
                label={
                  <span className="form-item-label" style={{ whiteSpace: 'pre-wrap' }}>
                    {t('complaintList.complaintArea')}
                  </span>
                }
              >
                <Select
                  placeholder={`${t('common.pleaseChoose')}`}
                  style={{ width: '100%' }}
                  options={[
                    { value: '0', label: t('complaintList.priorToWarehouseShipment') },
                    { value: '1', label: t('complaintList.afterwarehouseShipment') },
                    { value: '2', label: t('complaintList.beforeInstallation') },
                    { value: '3', label: t('complaintList.afterInstallation') },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          {originData && originData.transferType && originData.nextOperateAuthList.length > 1 ? (
            <Row gutter={8} className="row-border">
              {/* 转交给 */}
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  // className="complaint-detail-label"
                  name="taskAuthData"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                  label={
                    <span className="form-item-label" style={{ whiteSpace: 'pre-wrap' }}>
                      {t('complaintList.transferTo')}
                    </span>
                  }
                >
                  <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                    {originData &&
                      originData.nextOperateAuthList &&
                      originData.nextOperateAuthList.length > 0 &&
                      originData.nextOperateAuthList.map((item: any, index) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={t('energyStorage.operationLog.repairSuggestions')} required name="description">
                <Editor
                  apiKey={tinyKey}
                  tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                  onInit={(content, editor) => {
                    setEditorNow(editor);
                  }}
                  disabled={nodeData}
                  scriptLoading={{ async: true }} // 异步加载
                  onEditorChange={handleEditorChange}
                  init={{
                    min_height: 300,
                    language: tinymceFormatLanguage(language),
                    menubar: false, // 顶部菜单栏
                    resize: false, // 右下角调整大小
                    statusbar: false, // 底部状态栏
                    object_resizing: false, // 禁止设置媒体大小
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    default_link_target: '_blank',
                    plugins:
                      'autoresize print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave',
                    toolbar:
                      'undo redo bold italic  image | formatselect |  alignleft aligncenter alignright alignjustify outdent indent ',
                    images_upload_handler: (blobInfo, succFun, failFun) => {
                      return tinymceUploadImage(blobInfo, succFun, failFun);
                    },
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {fileLists && fileLists.length > 0 ? null : (
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
                <Form.Item
                  label={
                    <Tooltip title={t('createComplaint.warnThree')}>
                      <span>
                        {t('complaintList.workingConditionsOnSite')} <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  name="amsFileList"
                  valuePropName="fileList"
                >
                  <FileUpload onFileListChange={handleFileListChange} maxNum={3} />
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* 附件列表 */}
          {fileLists && fileLists.length > 0 ? (
            <Row gutter={8} className="row-border">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {fileLists.map((item: any, index) => {
                  return (
                    <div>
                      <a onClick={() => downloadOrPreviewAttachments(item)}>{item.fileName}</a>
                    </div>
                  );
                })}
              </Col>
            </Row>
          ) : null}

          <Row gutter={8}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
              <p>{t('complaintList.explanation')}</p>
              <p>{t('complaintList.explanation1')}</p>
              <p>{t('complaintList.explanation2')}</p>
              <p>{t('complaintList.explanation3')}</p>
              <p>{t('complaintList.explanation4')}</p>
            </Col>
          </Row>
        </Form>
        {viewOpen ? <PreViewModal onClosePreview={handleCancePreview} fileData={fileItem} /> : null}
      </Modal>
    </>
  );
};

export default memo(QualityCustomerComplaints);
