/*
 * @Description: 用户信息
 * @Author: Wayne Hu
 * @LastEditors: Summer.Chen
 * @Date: 2023/09/06 17:12:28
 * @LastEditTime: 2023-12-19 13:25:04
 */

import { user } from '@/constants/actionType';
import { Map } from 'immutable';
import {
  getUserName,
  getCountry,
  getUserInfo,
  getUserId,
  getSnLoading,
  getLoginCoId,
  getUserType,
  getUserRoles,
} from '@/utils/localStoreUtil';

let defaultState = Map({
  loginCold: getLoginCoId(),
  userType: getUserType(),
  userName: getUserName(),
  country: getCountry(),
  userInfo: getUserInfo(),
  userId: getUserId(),
  snLoading: getSnLoading(),
  roles: getUserRoles() || [],
});
const User = (state = defaultState, action: any) => {
  switch (action.type) {
    case user.UPDATE_USERNAME:
      return state.set('userName', action.userName);
    case user.UPDATE_COUNTRY:
      return state.set('country', action.country);
    case user.UPDATE_USER_INFO:
      return state.set('userInfo', action.userInfo);
    case user.UPDATE_ICDCID:
      return state.set('userIcdcId', action.userIcdcId);
    case user.UPDATE_SNLOADING:
      return state.set('snLoading', action.snLoading);
    case user.UPDATE_LOGIN_COID:
      return state.set('loginCoId', action.loginCoId);
    case user.UPDATE_USER_TYPE:
      return state.set('userType', action.userType);
    case user.UPDATE_ROLES:
      return state.set('roles', action.roles);
    default:
      return state;
  }
};
export default User;
