/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/09/06 17:12:28
 * @LastEditTime: 2024-09-04 15:11:01
 */

import request from './request';
import axios from 'axios';
import { getToken } from '@/utils/localStoreUtil';
import { IRequest } from '@/utils/request.type';
import requestChengdu from './requestChengdu';

const http = {
  request: (config: any) => {
    return new Promise((resolve) => {
      request(config)
        .then((res) => resolve(res))
        .catch((e) => console.error(e));
    });
  },

  get: ({ url, params }: IRequest<any>): Promise<any> => {
    return request({
      url,
      method: 'get',
      params,
    });
  },

  put: ({ url, data, params }: IRequest<any>): Promise<any> => {
    return request({
      url,
      method: 'put',
      data,
      params,
    });
  },

  delete: ({ url, data, params }: IRequest<any>): Promise<any> => {
    return request({
      url,
      method: 'delete',
      data,
      params,
    });
  },

  post: ({ url, data, params }: IRequest<any>): Promise<any> => {
    return request({
      url,
      method: 'post',
      data,
      params,
    });
  },

  exportImageTemp({ url, data, params }: IRequest<any>) {
    return request({
      url,
      method: 'get',
      data,
      params,
      responseType: 'arraybuffer',
    });
  },

  exportExelTempGet: ({ url, params }: IRequest<any>): Promise<any> => {
    return request({
      url,
      method: 'get',
      params,
      responseType: 'blob',
    });
  },

  exportExelTemp: ({ url, data, params }: IRequest<any>): Promise<any> => {
    return request({
      url,
      method: 'post',
      data,
      params,
      responseType: 'blob',
      timeout: 1000 * 60 * 3,
    });
  },

  exportFileTemp: ({ url, params }: IRequest<any>): Promise<any> => {
    return request({
      url,
      method: 'get',
      params,
      headers: { Accept: 'x-www-form-urlencoded' },
      responseType: 'blob',
    });
  },

  resetExportExelTemp: (url, data) => {
    axios({
      url,
      method: 'post',
      data,
      headers: {
        Authorization: getToken(),
      },
      responseType: 'blob',
    })
      .then((res) => {
        if (res.status === 200) {
          // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
          let fileName = decodeURI(res.headers['content-disposition'].split('=')[1].substr(7));
          // 将`blob`对象转化成一个可访问的`url`
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((data) => {});
  },
  formSubmit: (url, data, rateParams?): Promise<any> => {
    return request({
      url,
      method: 'post',
      data,
      timeout: 1000 * 60 * 60 * 5,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      onUploadProgress: function (e) {
        if (rateParams && e.lengthComputable) {
          rateParams.event = e;
          let handlerRate = ((e.loaded / e.total) * 100).toFixed(2) + '%';
          let rate = e.loaded / e.total; //已上传的比例
          if (rate < 1) {
            rateParams.rate = handlerRate;
          }
        }
      },
    });
  },
};
 export const chengduHttp = {
  post: ({ url, data, params }: IRequest<any>): Promise<any> => {
    return requestChengdu({
      url,
      method: 'post',
      data,
      params,
    });
  },
};

export default http;
