/*
 * @Description: VPP用户注册页面
 * @Author: Wayne.Hu
 * @LastEditors: YuKi
 * @Date: 2023/06/05 14:20:09
 * @LastEditTime: 2023/07/10
 */
import React, { memo, useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Tooltip,
  Form,
  Input,
  Row,
  Col,
  Select,
  Modal,
  DatePicker,
  Spin,
  Space,
  message,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { findWindows } from 'windows-iana';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getLanguageTypeList, getCountryList } from '@/api/common';
import {
  getTimeZones,
  getInstallTimeBySysSn,
  getUserAgreement,
  getAbstractTimeZoneByAddress,
  VppRegistr,
} from '@/api/login';
import { inputValueLimit } from '@/constants/inputValueLimit';
import { emailReg } from '@/constants/regRules';
import { useSelector, RootStateOrAny } from 'react-redux';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import Header from '../Header';
import { encrypt } from '@/constants/common';
import './index.less';
let timeInterval: any = null;
const { Option } = Select;

/* Vpp用户注册组件 */
const VppRegister: React.FC = () => {
  /* 引入翻译 */
  const { t } = useTranslation();
  /* 引入history */
  const history = useHistory();
  /* 获取当前系统的语言 */
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  /* Vpp用户注册表单的ref */
  const [VppRegisterForm] = Form.useForm();
  /* 语言列表源数据 */
  const [langTypeList, setLangTypeList] = useState<any>([]);
  /* 国家列表源数据 */
  const [countryList, setCountryList] = useState<any>([]);
  /* 时区列表源数据 */
  const [timeZonesList, setTimeZonesList] = useState<any>([]);
  /* 服务条款展示标识 */
  const [isProvicyOpen, setIsProvicyOpen] = useState(false);
  /* 服务条款标题*/
  const [provicyTitle, setProvicyTitle] = useState<any>('');
  /* 服务条款内容*/
  const [provicyContent, setProvicyContent] = useState<any>('');

  /* 安装日期flag:
  1 代表初始状态禁用安装日期组件，且不提示，
  2 代表安装商已经注册sn,安装日期禁用且提示，
  3 代表sn没有被注册过 */
  const [insDateFlag, setInsDateFlag] = useState<any>(1);
  // 时区
  const [timeZone, setTimeZone] = useState({ countryName: '', proviceName: '', cityName: '' });
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  /* 加载时区 */
  const [isLoadingTimeZone, setIsLoadingTimeZone] = useState(false);

  /* 国家变化时，获取时区 */
  useEffect(() => {
    if (isFirstLoading) {
      setIsFirstLoading(false);
      return;
    }
    // 防抖
    if (timeInterval) {
      clearTimeout(timeInterval);
      timeInterval = null;
    }
    timeInterval = setTimeout(() => {
      setIsLoadingTimeZone(true);
      getAbstractTimeZoneByAddress(timeZone.cityName + ',' + timeZone.proviceName + ',' + timeZone.countryName)
        .then((rep: any) => {
          if (rep && rep.data) {
            let microsoftTimeZone = findWindows(rep.data.timezone_location);
            VppRegisterForm.setFieldValue('timezone', microsoftTimeZone.length ? microsoftTimeZone[0] : '');
          }
          setIsLoadingTimeZone(false);
        })
        .catch((error) => {
          setIsLoadingTimeZone(false);
        });
    }, 1000);
  }, [timeZone]);

  const handleAreaChange = (value: string) => {
    countryList.forEach((item: any) => {
      if (item.areaId == value) {
        setTimeZone({
          ...timeZone,
          countryName: item.areaEnglishName,
        });
      }
    });
  };

  /* 根据语言动态切换样式 */
  useEffect(() => {
    let tempError = VppRegisterForm.getFieldsError();
    for (let index = 0; index < tempError.length; index++) {
      const element = tempError[index];
      if (element.errors && element.errors.length > 0) {
        VppRegisterForm.validateFields(element.name);
      }
    }
  }, [language]);

  /* 获取语言下拉数据源 */
  const getLanguageTypeLists = () => {
    getLanguageTypeList()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setLangTypeList([...rep.data]);
        } else {
          setLangTypeList([]);
        }
      })
      .catch((err) => {
        setLangTypeList([]);
      });
  };

  /* 获取国家下拉数据源 */
  const getCountryLists = () => {
    getCountryList()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setCountryList([...rep.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch((err) => {
        setCountryList([]);
      });
  };

  /* 获取时区下拉数据源 */
  const getTimeZone = () => {
    getTimeZones()
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setTimeZonesList([...rep.data]);
        } else {
          setTimeZonesList([]);
        }
      })
      .catch((err) => {
        setTimeZonesList([]);
      });
  };

  /* 获取服务条款 */
  const getProvicyItem = () => {
    getUserAgreement().then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        let description = rep.data;
        setProvicyTitle(description.split('\n')[0]);
        setProvicyContent(description);
      } else {
        setProvicyTitle('');
        setProvicyContent('');
      }
    });
  };

  /* 关闭服务条款 */
  const handleProvicyCancel = () => {
    setIsProvicyOpen(false);
  };

  /* 初始化下拉数据源 */
  useEffect(() => {
    getLanguageTypeLists(); /*获取语言列表数据  */
    getCountryLists(); /* 获取国家列表 */
    getTimeZone(); /* 获取时区列表 */
    getProvicyItem(); /* 获取用户协议 */
    if (VppRegisterForm) {
      VppRegisterForm.setFieldValue('vppId', getUrlQuery('vppId'));
      VppRegisterForm.setFieldValue('userName', getUrlQuery('userName'));
      VppRegisterForm.setFieldValue('sysSn', getUrlQuery('sysSn'));
    }
    if (getUrlQuery('sysSn')) {
      syncUpdate();
    }
  }, []);

  // 获取url中的参数值
  const getUrlQuery = (params) => {
    let regExp = new RegExp('(^|&)' + params + '=([^&]*)(&|$)');
    let r = decodeURIComponent(document.location.search.substring(1)).match(regExp);
    if (r && r[2]) {
      return r[2];
    }
  };

  /* 安装日期时间限制 */
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current.valueOf() > Date.now() ||
        current.valueOf() < moment(moment(new Date()).add(-5, 'years'), 'YYYY-MM-DD').valueOf())
    );
  };

  /* 返回登录页 */
  const goBackButton = () => {
    history.push({ pathname: '/login' });
  };

  /* 注册时根据SN获取安装时间 */
  const syncUpdate = () => {
    if (VppRegisterForm.getFieldValue('sysSn') && VppRegisterForm.getFieldValue('sysSn').trim()) {
      getInstallTimeBySysSn({ sysSn: VppRegisterForm.getFieldValue('sysSn') })
        .then((rep: any) => {
          if (rep && rep.code === 200) {
            if (rep.data) {
              setInsDateFlag(2);
              VppRegisterForm.setFieldValue('installDate', moment(rep.data, 'YYYY-MM-DD'));
            } else {
              setInsDateFlag(3);
              VppRegisterForm.setFieldValue('installDate', '');
            }
          }
        })
        .catch((err) => {});
    }
  };

  /* 提交注册表单 */
  async function onFinish(values: any) {
    let dataList = {
      ...values,
      countryId: values.countryCode,
      allowAutoUpdate: values.allowAutoUpdate ? 1 : 0,
      password: encrypt(values.password.trim(), values.userName.trim()),
      confirmPassword: encrypt(values.confirmPassword.trim(), values.userName.trim()),
    };

    VppRegistr(dataList).then((rep: any) => {
      if (rep && rep.code === 200) {
        message.success(t('common.registerSuccess'));
        history.push({
          pathname: '/login',
        });
      }
    });
  }

  return (
    <div className="register-container">
      <Header flag="register" />
      {/* 公司logo */}
      <div className="logo-box">
        {language === 'zh' ? (
          <img src="/common/loginImages/new_logo.png" width="110" />
        ) : (
          <img src="/common/loginImages/new_logo_en.png" width="110" />
        )}
      </div>
      <div className="regist-form">
        <Form
          form={VppRegisterForm}
          name="VppRegisterForm"
          layout={'vertical'}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ lgCode: 'zh-CN', allowAutoUpdate: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={8}>
            <h2 className="username-register">{t('register.vppUserRegister')}</h2>
          </Row>
          <Row gutter={8}>
            {/* 系统SN */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="sysSn"
                label={t('common.SNKey')}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('common.snIsRequired'));
                      } else if (value && value.trim() && value.trim().length > inputValueLimit.maxSn) {
                        return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxSn }));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input disabled allowClear placeholder={`${t('common.pleaseEntertheEystemSN')}`} />
              </Form.Item>
            </Col>

            {/* SN校验码*/}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="checkCode"
                label={t('register.snCheckCode')}
                tooltip={{
                  title: (
                    <div
                      style={{ width: '300px', overflowY: 'scroll' }}
                      dangerouslySetInnerHTML={{
                        __html: `
                        <span>${t('register.checkCodeDetail')}</span>
                        <br />
                        <img src="/common/img/checkcode.png" width="280px"/>`,
                      }}
                    ></div>
                  ),
                  icon: <InfoCircleOutlined />,
                  placement: 'right',
                }}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('register.snCheckRequired'));
                      } else if (value && value.trim() && value.trim().length > inputValueLimit.maxSnCheckRequired) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxSnCheckRequired }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear placeholder={`${t('register.snCheckRequired')}`} />
              </Form.Item>
            </Col>

            {/* 用户名 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('register.username')}
                name="userName"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('register.usernameRequired'));
                      } else if (
                        value &&
                        value.trim() &&
                        (value.trim().length < inputValueLimit.minEmail ||
                          value.trim().length > inputValueLimit.maxEmail)
                      ) {
                        return Promise.reject(
                          t('common.lengthLimit', {
                            maxLength: inputValueLimit.maxEmail,
                            minLength: inputValueLimit.minEmail,
                          }),
                        );
                      } else if (value && value.trim() && !emailReg.test(value.trim())) {
                        return Promise.reject(t('common.emailStyleError'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input disabled allowClear placeholder="someone@example.com" />
              </Form.Item>
            </Col>
            {/* 邮编 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('common.postCode')}
                name="postCode"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('common.postcodeIsRequired')}`, whitespace: true },
                  {
                    validator: (rule, value) => {
                      if (
                        value &&
                        value.trim() &&
                        (value.trim().length < inputValueLimit.minPostcode ||
                          value.trim().length > inputValueLimit.maxPostcode)
                      ) {
                        return Promise.reject(
                          t('common.lengthLimit', {
                            maxLength: inputValueLimit.maxPostcode,
                            minLength: inputValueLimit.minPostcode,
                          }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear placeholder={`${t('common.pleaseEnterZipCode')}`} />
              </Form.Item>
            </Col>

            {/* 密码 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('register.password')}
                name="password"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('register.pwdIsRequired'));
                      } else if (
                        value &&
                        value.trim() &&
                        (value.trim().length < inputValueLimit.minPassword ||
                          value.trim().length > inputValueLimit.maxPassword)
                      ) {
                        return Promise.reject(
                          t('common.lengthLimit', {
                            maxLength: inputValueLimit.maxPassword,
                            minLength: inputValueLimit.minPassword,
                          }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input.Password allowClear placeholder={`${t('register.pleaseEnterPassword')}`} />
              </Form.Item>
            </Col>

            {/* 确认密码 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('register.confirmpassword')}
                name="confirmPassword"
                validateTrigger="onBlur"
                dependencies={['password']}
                rules={[
                  { required: true, message: `${t('resetPwd.confirmPwdIsRequired')}`, whitespace: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value.trim()) {
                        if (
                          !getFieldValue('password') ||
                          !getFieldValue('password').trim() ||
                          getFieldValue('password').trim() !== value.trim()
                        ) {
                          return Promise.reject(t('resetPwd.confirmPwdIsError'));
                        } else if (
                          (value.trim().length < inputValueLimit.minPassword ||
                            value.trim().length > inputValueLimit.maxPassword) &&
                          getFieldValue('password') &&
                          getFieldValue('password').trim()
                        ) {
                          return Promise.reject(
                            t('common.lengthLimit', {
                              maxLength: inputValueLimit.maxPassword,
                              minLength: inputValueLimit.minPassword,
                            }),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password allowClear placeholder={`${t('register.pleaseEnterConfirmPassword')}`} />
              </Form.Item>
            </Col>

            {/* 语言 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="lgCode" label={t('common.language')}>
                <Select allowClear>
                  {langTypeList &&
                    langTypeList.length > 0 &&
                    langTypeList.map((item: any, index) => {
                      return (
                        <Option value={item.lgCode} key={index}>
                          {item.lgName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* 联系人 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t('register.linkman')}
                name="linkman"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('register.linkmanIsRequired'));
                      } else if (value && value.trim() && value.trim().length > inputValueLimit.maxContactName) {
                        return Promise.reject(t('common.maxlengLimit', { maxLength: inputValueLimit.maxContactName }));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear placeholder={`${t('register.pleaseEnterLinkMan')}`} />
              </Form.Item>
            </Col>

            {/* 国家/地区 */}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="countryCode"
                label={t('common.area')}
                validateTrigger="onChange"
                rules={[{ required: true, message: `${t('register.countryIsRequired')}` }]}
              >
                <Select
                  allowClear
                  showSearch
                  onChange={handleAreaChange}
                  placeholder={`${t('common.pleaseSelectCountry_Region')}`}
                  filterOption={(input, option) =>
                    (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countryList &&
                    countryList.length > 0 &&
                    countryList.map((item: any, index) => {
                      return (
                        <Option value={item.areaId} key={index}>
                          {`${item.areaEnglishName}[${item.areaFirstName}]`}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            {/* 省/州 */}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="stateCode"
                label={t('register.province')}
                validateTrigger="onBlur"
                rules={[{ required: true, whitespace: true, message: `${t('register.pleaseInputProvince')}` }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 城市 */}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="cityCode"
                label={t('register.cityVpp')}
                validateTrigger="onBlur"
                rules={[{ required: true, whitespace: true, message: `${t('register.pleaseInputCityVpp')}` }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            {/* 详细地址 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={
                  <Space wrap>
                    {t('common.address')}
                    <Tooltip title={t('register.addressTips')}>
                      <span>
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </Space>
                }
                name="address"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('register.pleaseEnterYourAddress'));
                      } else if (value && value.trim() && value.trim().length > inputValueLimit.maxAddressDetail) {
                        return Promise.reject(
                          t('common.maxlengLimit', { maxLength: inputValueLimit.maxAddressDetail }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear placeholder={`${t('register.pleaseEnterYourAddress')}`} />
              </Form.Item>
            </Col>
            {/* 电话 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={
                  <Space wrap>
                    {t('common.cellPhone')}
                    <Tooltip title={t('register.cellPhoneTips')}>
                      <span>
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </Space>
                }
                name="cellPhone"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(t('common.phoneNumberIsRequired'));
                      } else if (
                        value &&
                        value.trim() &&
                        (value.trim().length < inputValueLimit.minTelephone ||
                          value.trim().length > inputValueLimit.maxTelephone)
                      ) {
                        return Promise.reject(
                          t('common.lengthLimit', {
                            maxLength: inputValueLimit.maxTelephone,
                            minLength: inputValueLimit.minTelephone,
                          }),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear placeholder={`${t('common.pleaseEnterYourPhoneNumber')}`} />
              </Form.Item>
            </Col>

            {/* 时区 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.timezone !== currentValues.timezone}
              >
                <Spin spinning={isLoadingTimeZone} size="small">
                  <Form.Item
                    name="timezone"
                    label={t('register.systimezone')}
                    rules={[{ required: true, message: `${t('register.timezoneIsRequired')}` }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      disabled={isLoadingTimeZone}
                      placeholder={`${t('register.pleaseSelectTimeZone')}`}
                      filterOption={(input, option) =>
                        (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {timeZonesList &&
                        timeZonesList.length > 0 &&
                        timeZonesList.map((item: any, index) => {
                          return (
                            <Option value={item.standardName} key={index}>
                              {item.displayName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Spin>
              </Form.Item>
            </Col>
            {/* 安装日期 */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="installDate"
                label={
                  <Space wrap>
                    {t('register.installationDate')}
                    {insDateFlag === 2 ? (
                      <Tooltip title={t('register.installationDateTip')}>
                        <span>
                          <InfoCircleOutlined />
                        </span>
                      </Tooltip>
                    ) : null}
                  </Space>
                }
                rules={[{ required: true, message: `${t('register.install_dateRequired')}` }]}
              >
                <DatePicker
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`${t('register.selectDate')}`}
                  disabledDate={disabledDate}
                  disabled={insDateFlag === 1 || insDateFlag === 2}
                />
              </Form.Item>
            </Col>

            {/* vppId */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item hidden={true} name="vppId" label="VppID">
                <Input disabled></Input>
              </Form.Item>
            </Col>

            {/* 是否允许自动更新 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name="allowAutoUpdate" valuePropName="checked">
                <Checkbox className="checked-style">{t('register.VppAutoupload')}</Checkbox>
              </Form.Item>
            </Col>

            {/* 隐私条款 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="primaryPolicy"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error(`${t('register.pleaseCheckPrivacyPolicy')}`)),
                  },
                ]}
              >
                <Checkbox className="checked-style">
                  <div>
                    {t('register.AgreeTerms')}
                    <a onClick={() => setIsProvicyOpen(true)}>{t('register.TermsOfService')}</a>
                    {t('register.and')}
                    <a href="https://www.alphaess.com/enPrivacyPolicy.html" target="_blank">
                      《{t('register.PrivacyPolicy')}》
                    </a>
                  </div>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item>
                {/* 返回 */}
                <Button style={{ width: '100%' }} key="goback" shape="round" htmlType="button" onClick={goBackButton}>
                  {t('common.goback')}
                </Button>
              </Form.Item>
            </Col>
            {/* 提交 */}
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <Form.Item>
                <Button style={{ width: '100%' }} type="primary" key="submit" htmlType="submit" shape="round">
                  {t('common.submitBtn')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Modal
        title={provicyTitle}
        open={isProvicyOpen}
        onOk={handleProvicyCancel}
        onCancel={handleProvicyCancel}
        style={{ width: '50%', minWidth: '200px', top: '20%' }}
        maskClosable={false}
        footer={[
          <Button key="confirmBtn" shape="round" type="primary" onClick={handleProvicyCancel}>
            {t('common.confirmBtn')}
          </Button>,
        ]}
      >
        <div className="provicy-style">
          <pre>{provicyContent}</pre>
        </div>
      </Modal>
    </div>
  );
};

export default memo(VppRegister);
