/*
 * @Description: 配置信息
 * @Author: Summer.Chen
 * @Date: 2024-04-07 16:35:37
 * @LastEditTime: 2024/06/21 16:44:23
 * @LastEditors: Please set LastEditors
 */
import moment from 'moment';
import React from 'react';
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
export const setCol = ({ xs = 24, sm = 24, md = 7, lg = 7 }) => {
  return {
    xs,
    sm,
    md,
    lg,
  };
};
// 下拉按钮
export const dropdownButtonList = (t: (key: string) => string, record) => [
  {
    label: t('common.viewBtn'),
    key: '1',
    record,
  },
  {
    label: t('common.editBtn'),
    key: '2',
    record,
  },
  {
    label: t('common.deleteBtn'),
    key: '3',
    record,
  },
  {
    label: t('authorityManagement.editMenuPermissions'),
    key: '4',
    record,
  },
  {
    label: t('authorityManagement.bindUserGroup'),
    key: '5',
    record,
  },
];
// 组织权限管理
// table 数据类型
interface DataType {
  title: string;
  dataIndex?: string;
  key?: string;
  render?: any;
}
//分页 page
export interface pageDataRight {
  total: number;
  current: number;
  pageSize: number;
}
//用户列表table
export const userInfoListColumns = (t: (key: string) => string, page: any): DataType[] => {
  return [
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      render: (text: string, _, index: number) => {
        return (page.pageIndex - 1) * page.pageSize + (index + 1);
      },
    },
    {
      title: t('authorityManagement.orgName'),
      dataIndex: 'deptName',
      key: 'deptName',
    },
    {
      title: t('common.realPower.userName'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('authorityManagement.userType'),
      dataIndex: 'isDeptAdmin',
      key: 'isDeptAdmin',
      render: (text: number) => {
        return text === 1 ? t('userGroupManage.admin') : t('authorityManagement.teamMembers');
      },
    },
    {
      title: t('common.createTime'),
      dataIndex: 'createDatetime',
      key: 'createDatetime',
    },
  ];
};

//数据权限table Columns
export const dataRightTableColumns = (t: (key: string) => string, page: pageDataRight): DataType[] => {
  return [
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      render: (text: string, recoder: any, index: number) => {
        return (page.current - 1) * page.pageSize + index + 1;
      },
    },
    {
      title: t('authorityManagement.userGroupName'),
      dataIndex: 'deptName',
      key: 'deptName',
    },
    {
      title: t('common.createTime'),
      dataIndex: 'createDateTime',
      key: 'createDateTime',
      render: (text: string) => {
        return text ? moment(text).format(dateFormat) : '--';
      },
    },
  ];
};

//组织权限----树数据类型
export interface treeDataType {
  children?: treeDataType[] | null; //子组织
  deptId: string | null; //部门id
  deptName: string | null; //部门名称
  hasAllPerms: boolean | null; //是否拥有所有权限
  hasMemberPerms: boolean | null; //	是否有组员管理权限
  hasOrgOperate: number | null; //组织操作权限：0-都没有 1-新增 2-编辑、删除 3-都有
  hasSubDept: boolean | null; //是否有子组织
  parentId: string; //父级id
  title?: any;
  key?: React.Key;
}
//组织 组员TS
export interface teamMembers {
  additionalFields: string;
  additionalFields2: string;
  countryId: string | null;
  deptId: string;
  deptName: string;
  email: string;
  languagecode: string | null;
  userId: string | null;
  userType: string | null;
  username: string;
  userpwd: string;
  createDatetime: string;
}
//组员 entity
export const teamMembersEntity: teamMembers = {
  additionalFields: '',
  additionalFields2: '',
  countryId: null,
  createDatetime: '',
  deptId: '',
  deptName: '',
  email: '',
  languagecode: null,
  userId: null,
  userType: null,
  username: '',
  userpwd: '',
};
// 弹框组件传递参数 数据TS
export interface modalParamsType {
  deptId: string;
  type: number;
  initData: (deptId?: string, hasMemberPerms?: boolean) => void;
  parentId: string | null;
  deptName?: string | null;
  companyId?: string;
  record?: teamMembers;
  hasMemberPerms?: boolean;
}
// 初始化表单数据
export const getinitialValues = (addUserFlag: number | null, deptName = '') => {
  return {
    addUserFlag: addUserFlag,
    userType: 'dasw',
    languagecode: null,
    additionalFields: '',
    additionalFields2: '',
    companyId: '',
    countryId: null,
    deptName: deptName,
    email: '',
    menuIds: [],
    parentId: '',
    remark: '',
    userId: null,
    username: '',
    userpwd: '',
  };
};
// 权限树没有数据时数组

export const defaultNoData = (t: (key: string) => string) => {
  return [
    {
      deptId: 'All',
      title: t('kpiReport.noData'),
      deptName: t('kpiReport.noData'),
      hasAllPerms: null,
      hasMemberPerms: null,
      hasOrgOperate: null,
      hasSubDept: null,
      parentId: '',
    },
  ];
};

export interface IBindUserState {
  open: boolean;
  userType: string;
  id: string;
}
