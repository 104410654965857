/*
 * @Description: 角色管理页面
 * @Author: Wayne Hu
 * @LastEditors: YuKi
 * @Date: 2023/06/26 19:52:20
 * @LastEditTime: 2023/07/28
 */
import React, { memo, useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message, InputNumber, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import {
  addRole,
  deleteRole,
  editRoleInfo,
  getRoleList,
  getRoleMenuPermission,
  getRoleMenuTreeData,
  saveRoleHome,
  setRoleMenuPermission,
} from '@/api/menuPermissions/roleManage';
import { useForm } from 'antd/lib/form/Form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;

/* 角色列表中的字段信息 */
type RoleListItem = {
  roleId?: string; // 角色主键
  rolename?: string; // 角色名称
  roledesc?: string; // 角色描述
  rolelevel?: string; // 角色等级
  createDatetime?: string; // 创建时间
  lastupdateDatetime?: string; // 最后更新时间
};

/* 配置角色菜单列表中的字段信息 */
type ConfigListItem = {
  id: string; // id
  isSelected?: boolean; // 是否被角色选中
  menuTitle?: string; // 菜单标题
  parentId?: string; // 父级id
  children?: ConfigListItem[];
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 角色管理页的组件
 * @return {*}
 */
const RoleManage: React.FC = (): any => {
  const [pageIndex, setPageIndex] = useState<number>(1); // 查询的页码 默认第一页
  const [pageSize, setPageSize] = useState<number>(10); // 每页显示的数据条数 默认10条
  const [tableLoading, setTableLoading] = useState<boolean>(false); // 是否展示表格加载动画
  const [dataTotal, setDataTotal] = useState<number>(0); // 表格中的数据总条数 默认为0条
  const [roleName, setRoleName] = useState<string>(''); // 查询角色列表数据时使用的角色名称
  const [addOrEditDialog, setAddOrEditDialog] = useState<boolean>(false); // 显示或隐藏新增或编辑窗口
  const [configMenuDialog, setConfigMenuDialog] = useState<boolean>(false); // 显示或隐藏配置角色菜单窗口
  const [homePageDialog, setHomePageDialog] = useState<boolean>(false); // 显示或隐藏配置角色首页菜单
  const [editType, setEditType] = useState<number>(1); // 编辑或新增 1-新增 2-编辑
  /* 已经被角色选中的菜单id */
  const [selectedId, setSelectedId] = useState<string[]>([]);
  /* 角色列表 */
  const [roleList, setRoleList] = useState<RoleListItem[]>([]);
  /* 树形下拉数据源 */
  const [treeList, setTreeList] = useState<any>([]);
  /* 菜单树形列表 */
  const [menuTreeList, setMenuTreeList] = useState<ConfigListItem[]>([]);
  /* 角色信息表单 */
  const [roleForm] = useForm();
  /* 角色配置菜单表单 */
  const [roleConfigForm] = useForm();
  /* 配置角色首页菜单的表单 */
  const [setHomePageForm] = useForm();
  const treeSelect = useRef<any>();
  const { t } = useTranslation();
  const { confirm } = Modal;

  /* 进入页面时默认请求角色列表的第一页数据 */
  useEffect(() => {
    buttonSearchRoleList();
  }, []);

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 点击按钮时查询角色列表
   * @return {*}
   */
  const buttonSearchRoleList = (): any => {
    let params: any = {
      pageIndex: 1,
      pageSize: pageSize,
      roleName: roleName,
    };
    searchRoleList(params);
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 查询角色列表
   * @param {object} params
   * @return {*}
   */
  const searchRoleList = (params: object): any => {
    setTableLoading(true);
    getRoleList(params)
      .then((res: any) => {
        setTableLoading(false);
        if (res && res.code === 200 && res.data && res.data.rows && res.data.rows.length > 0) {
          setPageIndex(res.data.pageIndex);
          setDataTotal(res.data.total);
          setRoleList([...res.data.rows]);
        } else {
          setPageIndex(1);
          setDataTotal(0);
          setRoleList([]);
        }
      })
      .catch(() => {
        setTableLoading(false);
        setPageIndex(1);
        setDataTotal(0);
        setRoleList([]);
      });
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 切换查询页码时请求角色列表
   * @param {any} e
   * @return {*}
   */
  const rolePageChange = (e: any) => {
    let params: any = {
      pageIndex: e,
      pageSize: pageSize,
      roleName: roleName,
    };
    searchRoleList(params);
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 处理角色输入框内容修改
   * @param {*} e
   * @return {*}
   */
  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 取消新增或编辑角色信息
   * @return {*}
   */
  const handleCancel = () => {
    setEditType(1);
    setAddOrEditDialog(false);
    if (roleForm) {
      roleForm.resetFields();
    }
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 保存编辑或新增的角色信息
   * @return {*}
   */
  const saveRoleInfo = () => {
    if (roleForm) {
      roleForm.validateFields().then((values: any) => {
        if (editType === 1) {
          /* 新增角色 */
          addRole(values).then((res: any) => {
            if (res && res.code === 200) {
              message.success(t('roleManage.operateSuccess'));
              handleCancel();
              buttonSearchRoleList();
            }
          });
        } else {
          /* 编辑角色 */
          editRoleInfo(values).then((res: any) => {
            if (res && res.code === 200) {
              message.success(t('roleManage.operateSuccess'));
              handleCancel();
              let params: any = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                roleName: roleName,
              };
              searchRoleList(params);
            }
          });
        }
      });
    }
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 删除当前的角色
   * @param {any} roleId
   * @return {*}
   */
  const deleteCurrRole = (roleId: any) => {
    if (roleId) {
      confirm({
        closable: true,
        title: t('roleManage.warning'),
        icon: <ExclamationCircleOutlined />,
        okText: t('common.confirmBtn'),
        cancelText: t('common.cancelBtn'),
        content: t('roleManage.confirmDeleteRole'),
        onOk() {
          deleteRole({ roleId: roleId }).then((res: any) => {
            if (res && res.code === 200) {
              message.success(t('roleManage.operateSuccess'));
              let params: any = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                roleName: roleName,
              };
              searchRoleList(params);
              // buttonSearchRoleList()
            }
          });
        },
        onCancel() {
          message.info(t('roleManage.operateCancel'));
        },
      });
    }
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 显示编辑或者新增弹窗
   * @param {number} type
   * @return {*}
   */
  const showAddOrEditDialog = (type: number, record?: any): any => {
    setEditType(type);
    setAddOrEditDialog(true);
    if (record && roleForm) {
      roleForm.setFieldsValue({
        roleId: record.roleId,
        roledesc: record.roledesc || '',
        rolelevel: record.rolelevel || 99,
        rolename: record.rolename || '',
      });
    }
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 显示配置角色菜单的窗口
   * @param {any} record
   * @return {*}
   */
  const showConfigMenuDialog = (record: any) => {
    setConfigMenuDialog(true);
    roleConfigForm &&
      roleConfigForm.setFieldsValue({
        rolename: record.rolename,
        roledesc: record.roledesc,
        roleId: record.roleId,
      });

    getRoleMenuPermission({ roleId: record.roleId })
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          let menuList = handleTreeList(res.data, []);
          setMenuTreeList([...menuList.tempArr]);
          setSelectedId([...menuList.isChecked]);
        } else {
          setMenuTreeList([]);
          setSelectedId([]);
        }
      })
      .catch(() => {
        setMenuTreeList([]);
        setSelectedId([]);
      });
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 显示设置角色首页的弹窗
   * @param {any} record
   * @return {*}
   */
  const showSetHome = (record: any) => {
    setHomePageDialog(true);
    setHomePageForm &&
      setHomePageForm.setFieldsValue({
        rolename: record.rolename || '',
        roleId: record.roleId,
        roledesc: record.roledesc || '',
      });

    getRoleMenuTreeData({ roleId: record.roleId })
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          let treeDataTemp: any = handleTreeSelectData(res.data);
          setTreeList([...treeDataTemp]);
        } else {
          setTreeList([]);
        }
      })
      .catch(() => {
        setTreeList([]);
      });
  };

  /* 处理首页菜单变化时的叶子菜单 */
  const handleSelectTree = (e, node) => {
    if (node.children && node.children.length > 0) {
      setHomePageForm && setHomePageForm.setFieldValue('menuId', '');
      message.error(t('roleManage.pleaseSelectTheLeafMenu'));
    }
  };

  /* 取消保存角色首页菜单 */
  const handleCancelSetHome = () => {
    setHomePageDialog(false);
    setHomePageForm && setHomePageForm.resetFields();
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 保存角色首页菜单
   * @return {*}
   */
  const saveHome = () => {
    setHomePageForm &&
      setHomePageForm.validateFields().then((values: any) => {
        let dataObj: any = {
          roleId: values.roleId,
          menuId: values.menuId,
        };
        saveRoleHome(dataObj).then((res: any) => {
          if (res && res.code === 200) {
            message.success(t('roleManage.operateSuccess'));
            handleCancelSetHome();
            buttonSearchRoleList();
          }
        });
      });
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 处理树形下拉的数据结构
   * @param {*} treeList
   * @return {*}
   */
  const handleTreeSelectData = (treeList) => {
    let tempList: any = [];
    for (let i = 0; i < treeList.length; i++) {
      if (treeList[i].isDefault === 1) {
        setHomePageForm && setHomePageForm.setFieldValue('menuId', treeList[i].id);
      }
      let item: any = {
        title: treeList[i].menuTitle,
        value: treeList[i].id,
        children:
          treeList[i].childrenMenu && treeList[i].childrenMenu.length > 0
            ? handleTreeSelectData(treeList[i].childrenMenu)
            : [],
      };
      tempList.push(item);
    }
    return tempList;
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 递归处理后台返回的数据，使处理后的数据结构满足树形多选列表
   * @param {any} arr
   * @return {*}
   */
  const handleTreeList = (arr: any, isSelected: any) => {
    let tempArr: any = [];
    let isChecked: any = isSelected;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].isSelected === true) {
        isChecked.push(arr[i].id);
      }
      let item: any = {
        id: arr[i].id,
        isSelected: arr[i].isSelected,
        menuTitle: arr[i].menuTitle,
        parentId: arr[i].parentId,
        children:
          arr[i].childrenMenu && arr[i].childrenMenu.length > 0
            ? handleTreeList(arr[i].childrenMenu, isChecked).tempArr
            : undefined,
      };
      tempArr.push(item);
    }
    return {
      tempArr: tempArr,
      isChecked: isChecked,
    };
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 取消配置角色菜单
   * @return {*}
   */
  const handleCancelConfigMenu = () => {
    setConfigMenuDialog(false);
    setSelectedId([]);
    setMenuTreeList([]);
    roleConfigForm && roleConfigForm.resetFields();
  };

  /* 用户手动勾选或取消勾选菜单时 */
  const handleSelect = (record, selected, selectedRows) => {
    let arrList = handleChildNode(record.id, selected, menuTreeList);
    let selectIds = handleSelectedIds(arrList, []);
    let arrList2: any = arrList;
    let parents: any = [];
    if (record.parentId && record.parentId !== '3KeUCSJA3293') {
      parents = handleParentNode(arrList2, record.parentId, selected);
    }
    if (selected) {
      parents.forEach((element) => {
        if (!selectIds.includes(element.id)) {
          selectIds.push(element.id);
        }
      });
    } else {
      for (let i = 0; i < parents.length; i++) {
        parents[i].isSelected = false;
      }
      for (let k = 0; k < parents.length; k++) {
        let isCheck: any = false;
        parents[k].children.forEach((item: any) => {
          if (item.isSelected === true) {
            isCheck = true;
          }
        });

        if (isCheck) {
          if (!selectIds.includes(parents[k].id)) {
            selectIds.push(parents[k].id);
          }
        } else {
          if (selectIds.includes(parents[k].id)) {
            selectIds = selectIds.filter((item) => {
              return item != parents[k].id;
            });
          }
        }
      }
    }
    setSelectedId([...selectIds]);
    let newMenuList = handleMenuList(menuTreeList, selectIds);
    setMenuTreeList([...newMenuList]);
  };

  /* 处理已经勾选的菜单项 */
  const handleMenuList = (menuTreeList, selectIds) => {
    let menuList: any = [...menuTreeList];
    for (let i = 0; i < menuList.length; i++) {
      if (selectIds.includes(menuList[i].id)) {
        menuList[i].isSelected = true;
        if (menuList[i].children && menuList[i].children.length > 0) {
          menuList[i].children = handleMenuList(menuList[i].children, selectIds);
        }
      }
    }
    return menuList;
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 处理当前节点的祖先节点的选中状态
   * @param {*} dataSource
   * @param {*} parentId
   * @param {*} selected
   * @return {*}
   */
  const handleParentNode = (dataSource, parentId, selected) => {
    let parents: any = [];
    let fn = (arr, parentId) => {
      for (let item of arr) {
        if (item.id === parentId) {
          parents.push(item);
          if (item.parentId && item.parentId !== '3KeUCSJA3293') {
            fn(dataSource, item.parentId);
          }
          break;
        }
        if (item.children && item.children.length > 0) {
          fn(item.children, parentId);
        }
      }
    };
    fn(dataSource, parentId);
    return parents;
  };

  const handleSelectedIds = (menuList, selectedIds) => {
    let tempArr: any = selectedIds;
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].isSelected === true) {
        tempArr.push(menuList[i].id);
      }
      if (menuList[i].children && menuList[i].children.length > 0) {
        tempArr = handleSelectedIds(menuList[i].children, tempArr);
      }
    }
    return tempArr;
  };

  /* 保存角色菜单 */
  const saveRoleMenu = () => {
    let dataObj: any = {
      roleId: roleConfigForm.getFieldValue('roleId'),
      menuId: [...selectedId],
    };
    setRoleMenuPermission(dataObj).then((res: any) => {
      if (res && res.code === 200) {
        message.success(t('roleManage.operateSuccess'));
        handleCancelConfigMenu();
        buttonSearchRoleList();
      }
    });
  };
  /* 处理当前节点及其子节点的选中状态 */
  const handleChildNode = (menuId, checkedStatus, menuTreeListArr) => {
    let menuList: any = [...menuTreeListArr]; // 最新状态的菜单副本
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].id === menuId) {
        menuList[i].isSelected = checkedStatus;
        if (menuList[i].children && menuList[i].children.length > 0) {
          menuList[i].children.forEach((item) => {
            menuList[i].children = handleChildNode(item.id, checkedStatus, menuList[i].children);
          });
        } else {
          menuList[i].children = null;
          break;
        }
      } else {
        if (menuList[i].children && menuList[i].children.length > 0) {
          menuList[i].children = handleChildNode(menuId, checkedStatus, menuList[i].children);
        }
      }
    }
    return menuList;
  };
  /* 角色列表列定义 */
  const RoleListColumns: ColumnsType<RoleListItem> = [
    /* 列表序号 */
    {
      title: t('roleManage.serialNumber'),
      width: 130,
      dataIndex: 'index',
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pageIndex - 1) * pageSize + 1;
      },
    },
    /* 角色名称 */
    {
      title: t('roleManage.roleName'),
      width: 150,
      dataIndex: 'rolename',
      align: 'center',
      ellipsis: true,
    },
    /* 角色描述 */
    {
      title: t('roleManage.roledesc'),
      dataIndex: 'roledesc',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /* 角色等级 */
    {
      title: t('roleManage.rolelevel'),
      dataIndex: 'rolelevel',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    /* 创建时间 */
    {
      title: t('roleManage.createDatetime'),
      dataIndex: 'createDatetime',
      align: 'center',
      ellipsis: true,
    },
    /* 最后更新时间 */
    {
      title: t('roleManage.lastupdateDatetime'),
      dataIndex: 'lastupdateDatetime',
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('roleManage.operate'),
      align: 'center',
      width: 440,
      ellipsis: true,
      render: (text: any, record: any, index: any) => {
        return (
          <Space>
            <span onClick={() => deleteCurrRole(record.roleId)} style={{ color: '#ff0000', cursor: 'pointer' }}>
              {t('roleManage.delete')}
            </span>
            <span onClick={() => showAddOrEditDialog(2, record)} style={{ color: '#E8AA0D', cursor: 'pointer' }}>
              {t('roleManage.edit')}
            </span>
            <span style={{ color: '#E8AA0D', cursor: 'pointer' }} onClick={() => showSetHome(record)}>
              {t('roleManage.setHome')}
            </span>
            <span style={{ color: '#E8AA0D', cursor: 'pointer' }} onClick={() => showConfigMenuDialog(record)}>
              {t('roleManage.configRoleMenu')}
            </span>
          </Space>
        );
      },
    },
  ];
  /* 角色树形菜单回显列表 列定义 */
  const menuTreecolumns: ColumnsType<ConfigListItem> = [
    /* 菜单名称 */
    {
      title: t('roleManage.menuName'),
      dataIndex: 'menuTitle',
      align: 'left',
    },
    /* 序号 */
    {
      title: t('roleManage.serialNumber'),
      width: 140,
      dataIndex: 'index',
      align: 'left',
      render: (text: any, record: any, index: any) => {
        return index + 1;
      },
    },
  ];

  return (
    <>
      <Row gutter={8}>
        <Col style={{ margin: '4px' }} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Input
            defaultValue={roleName}
            onChange={handleRoleNameChange}
            placeholder={`${t('roleManage.roleName')}`}
          ></Input>
        </Col>
        <Col style={{ margin: '4px' }} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Space>
            <Button shape="round" type="primary" disabled={tableLoading} onClick={buttonSearchRoleList}>
              {t('common.searchBtn')}
            </Button>
            <Button shape="round" type="primary" onClick={() => showAddOrEditDialog(1)}>
              {t('common.addBtn')}
            </Button>
          </Space>
        </Col>
      </Row>
      <br />

      <Table
        rowKey="roleId"
        scroll={{ x: 1400, y: window.innerHeight - 330 }}
        columns={RoleListColumns}
        loading={tableLoading}
        dataSource={roleList}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          defaultCurrent: 1,
          current: pageIndex,
          total: dataTotal,
          onChange: rolePageChange,
        }}
      ></Table>

      {/* 角色新增/编辑弹窗 */}
      <Modal
        maskClosable={false}
        title={editType === 1 ? t('common.addBtn') : t('common.editBtn')}
        open={addOrEditDialog}
        onCancel={handleCancel}
        width="50%"
        footer={[
          <Button key="back" shape="round" onClick={handleCancel}>
            {t('common.cancelBtn')}
          </Button>,
          <Button key="submit" shape="round" type="primary" onClick={saveRoleInfo}>
            {t('common.submitBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form name="roleForm" autoComplete="off" layout="vertical" form={roleForm}>
          {/* 角色名称 */}
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                label={`${t('roleManage.roleName')}`}
                name="rolename"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('roleManage.pleaseInputRoleName')}` },
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > 64) {
                        return Promise.reject(t('common.maxlengLimit', { maxLength: 64 }));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input allowClear maxLength={64} showCount />
              </Form.Item>
            </Col>
          </Row>
          {/* 角色等级 */}
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                label={`${t('roleManage.rolelevel')}`}
                name="rolelevel"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: `${t('roleManage.pleaseInputRoleLevel')}` },
                  {
                    validator: (rule, value) => {
                      if (value > 100000 || value < 1) {
                        return Promise.reject(t('roleManage.pleaseEnterIntegerBetweenOneAndthous'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={1} max={100000} />
              </Form.Item>
            </Col>
          </Row>
          {/* 角色描述 */}
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                label={`${t('roleManage.roledesc')}`}
                name="roledesc"
                validateTrigger="onBlur"
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.trim() && value.trim().length > 64) {
                        return Promise.reject(t('common.maxlengLimit', { maxLength: 64 }));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <TextArea allowClear rows={3} showCount maxLength={64} />
              </Form.Item>
            </Col>
          </Row>
          {/* 角色ID */}
          {editType === 2 ? (
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item hidden={true} label={`${t('roleManage.roleId')}`} name="roleId">
                  <Input disabled={true}></Input>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </Form>
      </Modal>

      {/* 配置角色菜单 */}
      <Modal
        maskClosable={false}
        title={`${t('roleManage.configRoleMenu')}`}
        open={configMenuDialog}
        onCancel={handleCancelConfigMenu}
        width="50%"
        footer={[
          <Button key="back" shape="round" onClick={handleCancelConfigMenu}>
            {t('common.cancelBtn')}
          </Button>,
          <Button key="submit" shape="round" type="primary" onClick={saveRoleMenu}>
            {t('common.submitBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form name="roleConfigForm" autoComplete="off" layout="vertical" form={roleConfigForm}>
          {/* 角色名称 */}
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item label={`${t('roleManage.roleName')}`} name="rolename">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* 角色描述 */}
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item label={`${t('roleManage.roledesc')}`} name="roledesc">
                <TextArea disabled rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item hidden={true} label={`${t('roleManage.roleId')}`} name="roleId">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {/* 角色树形列表展示 */}
        <Table
          rowKey="id"
          columns={menuTreecolumns}
          rowSelection={{
            checkStrictly: true,
            hideSelectAll: true,
            selectedRowKeys: selectedId,
            onSelect: handleSelect,
          }}
          dataSource={menuTreeList}
          pagination={false}
          scroll={{ y: 500 }}
        ></Table>
      </Modal>

      {/* 设置角色首页 */}
      <Modal
        maskClosable={false}
        title={`${t('roleManage.setRoleHome')}`}
        open={homePageDialog}
        onCancel={handleCancelSetHome}
        width="50%"
        footer={[
          <Button key="back" shape="round" onClick={handleCancelSetHome}>
            {t('common.cancelBtn')}
          </Button>,
          <Button key="submit" shape="round" type="primary" onClick={saveHome}>
            {t('common.submitBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form name="setHomePageForm" autoComplete="off" layout="vertical" form={setHomePageForm}>
          {/* 角色名称 */}
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item label={`${t('roleManage.roleName')}`} name="rolename">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* 角色描述 */}
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item label={`${t('roleManage.roledesc')}`} name="roledesc">
                <TextArea disabled rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item hidden={true} label={`${t('roleManage.roleId')}`} name="roleId">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                label={`${t('roleManage.homeMenu')}`}
                name="menuId"
                validateTrigger="onChange"
                rules={[{ required: true, message: `${t('roleManage.pleaseSelectHomeMenu')}` }]}
              >
                <TreeSelect
                  ref={treeSelect}
                  placeholder={`${t('roleManage.pleaseSelectHomeMenu')}`}
                  treeData={treeList}
                  allowClear={true}
                  onSelect={handleSelectTree}
                ></TreeSelect>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(RoleManage);
