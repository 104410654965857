/*
 * @Description:客诉列表->批量归档
 * @Author: April
 * @Date: 2023-04-10 10:32:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/12/31
 */

import { Button, Col, Form, Input, Modal, Row, Select, Space, Table, TableColumnsType, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getBatchFlag,
  getCustomerComplaintsList,
  getComponentList,
  getModuleList,
  getContentList,
  batchArchive,
} from '@/api/clientFeedback/complaintsList';
import './index.less';

const { Option } = Select;
// 列表字段类型定义
interface DataType {
  id: string;
  apiType: string;
  params: string;
  requestError: string;
  requestErrorFull: string;
  requestHeadParms: number;
  requestMethod: string;
  requestStatus: string;
  requestUrl: string;
  responseParams: string;
  ticketNumber: string;
  title: string;
  processingPriority: string;
}

/* 列表查询参数 */
let searchParams: any = {
  pageSize: 10 /* 每页显示条数 */,
  pageIndex: 1 /* 当前页 */,
  batchingFlagId: '' /* 接口类型 */,
};

const sysDataMigration: React.FC = () => {
  const { t } = useTranslation();
  /* table的loading */
  const [tableLoading, setTableLoading] = useState(false);
  const [detailTableLoading, setDetailTableLoading] = useState(false);
  /* 列表源数据 */
  const [dataList, setDataList] = useState<DataType[]>();
  /* 嵌套子表格的数据源 */
  const [subDataList, setSubDataList] = useState<DataType[]>();
  /* 提交表单的ref */
  const [searchForm] = Form.useForm();
  /* 查询表单的ref */
  const [archiveForm] = Form.useForm();
  /* 分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  const [expandedRowKeys, setExpandedRowKeys] = useState(['']);
  /** 已选择id  */
  const [selectComplaits, setSelectComplaits] = useState([]);
  /* 获取部件列表 */
  const [componentsList, setComponentsList] = useState<any>([]);
  /* 获取模块列表 */
  const [ModuleList, setModuleList] = useState([]);
  /* 获取问题内容列表 */
  const [contentList, setContentList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  /* 主表格列 */
  const columns: ColumnsType<DataType> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      key: 'index',
      width: 120,
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 标识 */
    {
      title: t('complaintList.identification'),
      dataIndex: 'batchingFlagId',
      ellipsis: true,
      width: 200,
      align: 'left',
    },
    /* 处理人 */
    {
      title: t('complaintList.complaintListHandler'),
      dataIndex: 'caseFollowupBy',
      ellipsis: true,
      width: 200,
      align: 'left',
    },

    /* 处理时间 */
    {
      title: t('productRule.processingTime'),
      dataIndex: 'lastupdateServerDatetime',
      ellipsis: true,
      width: 200,
      align: 'left',
    },
  ];
  /* 嵌套子表格列 */
  const expandedRowRender = (expanded) => {
    const detailColumns: TableColumnsType<DataType> = [
      /* 客诉单号 */
      {
        title: t('complaintList.number'),
        key: 'ticketNumber',
        dataIndex: 'ticketNumber',
        width: 230,
        align: 'center',
        fixed: 'left',
      },
      /* 系统SN */
      {
        title: t('complaintList.sysSn'),
        key: 'systemSn',
        dataIndex: 'systemSn',
        width: 200,
        align: 'center',
      },
      /* 标题 */
      {
        title: t('complaintList.title'),
        key: 'title',
        dataIndex: 'title',
        width: 140,
        align: 'center',
        ellipsis: true,
      },
      /* 国家/地区 */
      {
        title: t('complaintList.area'),
        key: 'country',
        dataIndex: 'country',
        width: 160,
        align: 'center',
      },
      /* 创建时间 */
      {
        title: t('complaintList.createtime'),
        key: 'createServerDatetime',
        dataIndex: 'createServerDatetime',
        width: 200,
        align: 'center',
      },
      /* 创建人 */
      {
        title: t('complaintList.creator'),
        key: 'createAccount',
        dataIndex: 'createAccount',
        width: 150,
        align: 'center',
        ellipsis: true,
      },
    ];

    /**
     * @description: 已选择的角色
     * @param {*} val
     * @LastEditors: XuAnjie
     */
    const getUnBindSNchoosed = (val) => {
      setSelectComplaits(val);
    };

    return (
      <Table
        rowSelection={{ type: 'checkbox', onChange: getUnBindSNchoosed, selectedRowKeys: selectComplaits }}
        columns={detailColumns}
        dataSource={subDataList}
        pagination={false}
        className="detail-table-flex"
        scroll={{ x: 1300 }}
        loading={detailTableLoading}
        rowKey="id"
      />
    );
  };
  /**
   * @description: 根据分页，初始化列表数据
   * @return {*}
   * @LastEditors: April
   */
  useEffect(() => {
    pageChange(pagingRecord.pageIndex, pagingRecord.pageSize);
  }, []);

  /**
   * @description: 分页的回调函数
   * @param {*} useCallback
   * @return {*}
   * @LastEditors: April
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    let params: any = searchForm.getFieldsValue();
    searchParams = { ...searchParams, ...params };
    setExpandedRowKeys([]);
    setSelectComplaits([]);
    getTableData();
  }, []);
  /**
   * @description: 获取列表数据
   * @return {*}
   * @LastEditors: April
   */
  const getTableData = () => {
    setTableLoading(true);
    getBatchFlag(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          setDataList(rep.data.rows);
          let pageInit = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...pageInit });
        } else {
          setDataList([]);
          let pageInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pageInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pageInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pageInit });
        setTableLoading(false);
      });
  };

  /**
   * @description: 查询列表
   * @param {*} useCallback
   * @return {*}
   * @LastEditors: April
   */
  const search = () => {
    let paging = {
      pageIndex: 1,
      pageSize: 10,
      total: pagingRecord.total,
    };
    setPagingRecord({ ...paging });
    searchParams.batchingFlagId = searchForm.getFieldsValue().batchingFlagId || '';
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    getTableData();
  };

  /**
   * @description: 点击+号调用获取数据
   * @param {*} val
   * @param {*} record
   * @LastEditors: XuAnjie
   */
  const handleExpand = (val: boolean, record) => {
    if (val) {
      getComplaintsListByBatchFlag(record.batchingFlagId);
    } else {
      setSelectComplaits([]);
      setSubDataList([]);
    }
  };
  /**
   * @description: 展开行时:点击新的子表格收起原展开的子表格
   * @param {*} rows
   * @LastEditors: April
   */
  const expandRows = (rows) => {
    const arrTemp = [''];
    setExpandedRowKeys(arrTemp.concat(rows[rows.length - 1]));
  };
  /**
   * @description: 获取嵌套子表格内容
   * @param {*} key
   * @LastEditors: XuAnjie
   */
  const getComplaintsListByBatchFlag = (key: string) => {
    getCustomerComplaintsList({
      type: 2,
      pageIndex: 1, // 当前查询页
      pageSize: 1000, // 每页显示的数据条数
      batchingFlagId: key,
    })
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          setSubDataList([...res.data.rows]);
        } else {
          setSubDataList([]);
        }
        setDetailTableLoading(false);
      })
      .catch(() => {
        setSubDataList([]);
        setDetailTableLoading(false);
      });
  };

  /**
   * @description: 打开批量归档弹窗
   * @LastEditors: XuAnjie
   */
  const openArchiveModal = () => {
    if (selectComplaits.length <= 0) {
      message.info(t('archivalMaintenance.pleaseChooseComplaints'));
    } else {
      setIsOpen(true);
    }
  };

  /**
   * @description: 获取部件列表
   * @LastEditors: XuAnjie
   */
  const getComponentLists = (e) => {
    getComponentList({ businessLine: e }).then((res: any) => {
      if (res.code == 200 && res.data) {
        setComponentsList(res.data);
      }
    });
  };

  /**
   * @description: 获取模块列表
   * @LastEditors: XuAnjie
   */
  const getModuleLists = (val) => {
    if (val) {
      getModuleList({ id: val }).then((res: any) => {
        if (res.code == 200 && res.data) {
          setModuleList(res.data);
        }
      });
    }
    archiveForm.setFieldValue('moduleId', undefined);
    archiveForm.setFieldValue('contentId', undefined);
  };

  /**
   * @description: 获取内容列表
   * @LastEditors: XuAnjie
   */
  const getContentLists = (val) => {
    if (val) {
      getContentList({ id: val }).then((res: any) => {
        if (res.code == 200 && res.data) {
          setContentList(res.data);
        }
      });
    }
    archiveForm.setFieldValue('contentId', undefined);
  };

  /**
   * @description: 关闭批量归档弹窗
   * @LastEditors: XuAnjie
   */
  const cancelHanle = () => {
    archiveForm.resetFields();
    setSelectComplaits([]);
    setIsOpen(false);
  };

  /**
   * @description: 批量归档
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    archiveForm.validateFields().then((values) => {
      let complaintList: any = [];
      selectComplaits &&
        selectComplaits.forEach((item) => {
          complaintList.push({
            complaintId: item,
          });
        });
      batchArchive({ complaintList: complaintList, ...values }).then((res: any) => {
        if (res.code == 200) {
          cancelHanle();
          message.success(t('complaintList.operateSuccess'));
          setExpandedRowKeys(['']);
          searchParams.pageIndex = 1;
          searchParams.pageSize = 10;
          getTableData();
        }
      });
    });
  };

  /**
   * @description: 根据产品线获取部件名称
   * @param {*} e
   * @LastEditors: XuAnjie
   */
  const handleComponentLists = (e) => {
    archiveForm.resetFields(['componentId']);
    getComponentLists(e);
  };

  return (
    <>
      <div className="custom-contant sysData-migration">
        <Form
          form={searchForm}
          name="searchForm"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search"
        >
          <Row gutter={12}>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item name="batchingFlagId">
                <Input allowClear placeholder={`${t('complaintList.identification')}`} />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Form.Item>
                <Space>
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {/* 批量归档 */}
                  <Button
                    disabled={!(selectComplaits.length > 0)}
                    type="primary"
                    key="export"
                    shape="round"
                    htmlType="button"
                    onClick={openArchiveModal}
                  >
                    {t('complaintList.batchArchiving')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          className="custom-table-flex auto-table"
          columns={columns}
          loading={tableLoading}
          dataSource={dataList}
          expandable={{
            expandedRowRender: expandedRowRender,
            onExpand: handleExpand,
            onExpandedRowsChange: (expandedRows) => expandRows(expandedRows),
            expandedRowKeys: expandedRowKeys,
          }}
          rowKey={(record: any) => record.batchingFlagId}
          scroll={{ x: 1300, y: 'calc(100vh - 250px)' }}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
        ></Table>
        <Modal
          title={t('complaintList.batchArchiving')}
          open={isOpen}
          width="30%"
          maskClosable={false}
          onCancel={cancelHanle}
          className="customer-details-style"
          destroyOnClose={true}
          footer={[
            <Row gutter={12} justify="end">
              {/* 取消 */}
              <Button key="cancelModal" shape="round" onClick={cancelHanle}>
                {t('common.cancelBtn')}
              </Button>
              {/* 立即执行 */}
              <Button key="saveModal" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            </Row>,
          ]}
        >
          <Form
            layout="vertical"
            form={archiveForm}
            name="archiveForm"
            autoComplete="off"
            style={{ width: '100%' }}
            className=""
          >
            <Row gutter={8} className="row-border">
              {/* 产品线 */}
              <Col span={24}>
                <Form.Item
                  label={t('archivalMaintenance.productLine')}
                  name="businessLine"
                  rules={[
                    {
                      required: true,
                      message: `${t('archivalMaintenance.productLine')}${t('archivalMaintenance.parmasRequired')}`,
                    },
                  ]}
                >
                  <Select
                    placeholder={`${t('common.pleaseChoose')}`}
                    allowClear
                    onChange={handleComponentLists}
                    options={[
                      { value: 10, label: t('archivalMaintenance.householdUse') },
                      { value: 20, label: t('createComplaint.consumerBusiness') },
                      { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                      { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name={'componentId'}
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: `${t('archivalMaintenance.component')}${t('archivalMaintenance.parmasRequired')}`,
                    },
                  ]}
                  label={<span className="form-item-label">{t('archivalMaintenance.component')}</span>}
                >
                  <Select allowClear placeholder={`${t('common.pleaseChoose')}`} onChange={getModuleLists}>
                    {componentsList &&
                      componentsList.length > 0 &&
                      componentsList.map((item: any, index) => {
                        return (
                          <Option value={item.id} key={index}>
                            {item.component}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.componentId !== currentValues.componentId}
                >
                  {({ getFieldValue }) =>
                    getFieldValue('componentId') !== undefined ? (
                      <Form.Item
                        name="moduleId"
                        label={<span className="form-item-label">{t('archivalMaintenance.module')}</span>}
                        rules={[
                          {
                            required: true,
                            message: `${t('archivalMaintenance.module')}${t('archivalMaintenance.parmasRequired')}`,
                          },
                        ]}
                      >
                        <Select allowClear placeholder={`${t('common.pleaseChoose')}`} onChange={getContentLists}>
                          {ModuleList &&
                            ModuleList.length > 0 &&
                            ModuleList.map((item: any, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {item.module}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.moduleId !== currentValues.moduleId}
                >
                  {({ getFieldValue }) =>
                    getFieldValue('moduleId') !== undefined ? (
                      <Form.Item
                        name="contentId"
                        label={<span className="form-item-label">{t('archivalMaintenance.content')}</span>}
                        rules={[
                          {
                            required: true,
                            message: `${t('archivalMaintenance.content')}${t('archivalMaintenance.parmasRequired')}`,
                          },
                        ]}
                      >
                        <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                          {contentList &&
                            contentList.length > 0 &&
                            contentList.map((item: any, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {item.content}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};
export default memo(sysDataMigration);
