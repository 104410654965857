/*
 * @Description: 上传文件封装
 * @Author: XuAnjie
 * @Date: 2023-10-07 16:45:01
 * @LastEditTime: 2025/02/06
 */
import React, { useState } from 'react';
import { Upload, Button, message, UploadFile } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { fileDownload, fileUploadARMS } from '@/api/common';
import { config } from '@/constants/config.js';
import { useTranslation } from 'react-i18next';
import { blobDownloadFile } from '@/constants/common';
import type { UploadProps } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';

const props: UploadProps = {
  showUploadList: {
    showDownloadIcon: true,
    downloadIcon: <DownloadOutlined />,
  },
};
interface IBtnProps {
  shape: 'default' | 'circle' | 'round' | undefined;
  hasIcon: boolean;
  text: string;
}
interface IProps {
  onFileListChange?: (fileList: UploadFile[]) => void;
  maxNum: number;
  btnProps?: IBtnProps;
}

const CustomUpload = (props: IProps) => {
  const { onFileListChange, maxNum, btnProps } = props;
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  /**
   * @Author: YuKi
   * @description: 附件导出
   */
  const exportAttachment = (e) => {
    let obj = {
      blobContainerName: 'FILES',
      fileBlobName: e.response[0].fileSaveName,
    };
    fileDownload(obj).then((rep: any) => {
      blobDownloadFile(rep, e.response[0].fileSaveName);
    });
  };

  /* 处理附件上传 */
  const handleUpload = (options: any) => {
    const { onSuccess, onError } = options;
    const formData = new FormData();
    formData.append('blobContainerName', 'FILES');
    formData.append('fileList', options.file);
    let fileList: any = [{}];
    fileUploadARMS(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          fileList[0].status = 'done';
          fileList[0].url = config.downUrl + `${res.data[0].blobContainerName}/${res.data[0].fileSaveName}`;
          fileList[0].fileName = res.data[0].fileSaveName;
          fileList[0].fileSaveName = res.data[0].fileSaveName;
          fileList[0].blobContainerName = res.data[0].blobContainerName;
          fileList[0].size = res.data[0].fileSize;

          onSuccess(fileList);
        } else {
          onError(message.success(t('complaintList.fileUploadFailed')));
        }
      })
      .catch(() => {
        onError(message.success(t('complaintList.fileUploadFailed')));
      });
  };

  /* 处理上传文件变动 */
  const handleUploadChange = (info) => {
    // 从info中获取文件列表
    const { fileList } = info;

    // 调用父组件传递的回调函数，将文件列表返回给父组件
    onFileListChange && onFileListChange(fileList);

    setFileList(fileList);
  };

  /**
   * @Author: YuKi
   * @description: 新增/编辑弹窗->上传文件前的勾子
   * @param {any} option
   * @return {*}
   */
  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / Math.pow(1024, 2) < 80;
    if (!isLt2M) {
      message.error(t('prodSuggestion.addProdSuggestion.fileSizeLimit'));
      return Upload.LIST_IGNORE;
    }
    return isLt2M;
  };

  return (
    <Upload
      {...props}
      onDownload={exportAttachment}
      customRequest={(option) => handleUpload(option)}
      onChange={handleUploadChange}
      beforeUpload={beforeUpload}
      maxCount={maxNum}
    >
      <Button
        icon={btnProps?.hasIcon && <UploadOutlined />}
        disabled={fileList.length >= maxNum}
        shape={btnProps?.shape}
      >
        {btnProps?.text ? t(btnProps?.text) : t('complaintList.chooseFile')}
      </Button>
    </Upload>
  );
};

export default CustomUpload;
