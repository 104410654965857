/*
 * @Description: 产品规则
 * @Author: XuAnjie
 * @Date: 2023/09/25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/09/13 15:36:32
 */
import React, { useCallback, memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { useHistory, useLocation } from 'react-router-dom';
import reportMainService from '@/api/reportMaintenance';
import { inputValueLimit } from '@/constants/inputValueLimit';
/* 列表字段类型定义 */
type GithubIssueItem = {
  id: number;
  authCategory: number /* 数据权限类别 */;
  authType: number /* 权限类型（1：逆变器型号；2：sn编码规则；3：国家区域；） */;
  groupName: string /* 用户组名称 */;
  roleName: string /* 角色 */;
  matchCondition: number;
  productLine: number;
  displayModel: string;
};

/* 列表查询参数 */
let searchParams = {
  pageSize: 10 /* 每页显示条数 */,
  pageIndex: 1 /* 当前页 */,
  productLine: null /* 项目名称 */,
  displayModel: null,
};
const { Option } = Select;

const ReportMaintenance: React.FC = () => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const history = useHistory(); //列表ref
  const location = useLocation();
  /* 分页参数*/
  const [pagingRecord, setPagingRecord] = useState({ pageSize: 10, pageIndex: 1, total: 0 });
  /* 列表源数据 */
  const [dataList, setDataList] = useState<GithubIssueItem[]>([]);
  /* table的loading */
  const [tableLoading, setTableLoading] = useState<any>(false);
  /* 新增或编辑 */
  const [addOrEdit, setAddOrEdit] = useState<number>(1);
  /* 新增弹窗 */
  const [isModalOpen, setIsModalOpen] = useState(false);
  /* 编辑弹窗的ref */
  const [projectForm] = Form.useForm();
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 列表字段 */
  const columns: ColumnsType<GithubIssueItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      key: 'serialNumber',
      width: 160,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pagingRecord.pageIndex - 1) * pagingRecord.pageSize + 1;
      },
    },
    /* 产品，1-逆变器 2-电池	 */
    {
      title: t('productRule.productLine'),
      dataIndex: 'productLine',
      width: 180,
      align: 'center',
      render: (_, record) => {
        if (record.productLine === 1) {
          return <span>{t('createComplaint.Inverter')}</span>;
        } else if (record.productLine === 2) {
          return <span>{t('createComplaint.Battery')}</span>;
        }
      },
    },
    /* 显示型号	*/
    {
      title: t('productRule.displayModel'),
      dataIndex: 'displayModel',
      width: 180,
      align: 'center',
      ellipsis: true,
    },
    /* 匹配条件，1-SN 2-型号		 */
    {
      title: t('productRule.matchCondition'),
      dataIndex: 'matchCondition',
      width: 180,
      align: 'center',
      render: (_, record) => {
        if (record.matchCondition === 1) {
          return <span>{t('createComplaint.sn')}</span>;
        } else if (record.matchCondition === 2) {
          return <span>{t('productRule.type')}</span>;
        }
      },
    },
    /* 创建人 */
    {
      title: t('common.creator'),
      dataIndex: 'createAccount',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 创建时间 */
    {
      title: t('common.createTime'),
      dataIndex: 'createServerDatetime',
      width: 160,
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      key: 'option',
      align: 'center',
      width: 250,
      render: (_, record) => {
        const params = {
          ...searchParams,
          productId: record.id,
          matchRule: record.displayModel,
        };
        return (
          <Space key="option" size="middle" wrap className="sn-management-space">
            {/* 编辑 */}
            <a key="edit" onClick={() => displayAddOrEditDialog(2, record)}>
              {t('common.editBtn')}
            </a>
            {/* 模块维护 */}
            <a
              key="associate"
              onClick={() => {
                history.push({
                  pathname: '/innerStatic/productRuleManagement/productRule',
                  state: params,
                });
              }}
            >
              {t('productRule.productRule')}
            </a>
            {/* 删除 */}
            <a
              className="delete-text"
              key="delete"
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              {t('common.deleteBtn')}
            </a>
          </Space>
        );
      },
    },
  ];

  /**
   * @Author: XuAnjie
   * @description: 分页获取产品
   * @return {*}
   */
  const getProductPage = () => {
    setTableLoading(true);
    reportMainService
      .getProductPage(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data && rep.data.rows) {
          setDataList([...rep.data.rows]);
          let paging = {
            pageIndex: rep.data.pageIndex,
            pageSize: rep.data.pageSize,
            total: rep.data.total,
          };
          setPagingRecord({ ...paging });
        } else {
          setDataList([]);
          let pagingInit = {
            pageIndex: 1,
            pageSize: 10,
            total: 0,
          };
          setPagingRecord({ ...pagingInit });
        }
        setTableLoading(false);
      })
      .catch((err) => {
        setDataList([]);
        let pagingInit = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
        };
        setPagingRecord({ ...pagingInit });
        setTableLoading(false);
      });
  };

  /**
   * @description: 新增或编辑部件
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const addOrEditComponents = () => {
    projectForm.validateFields().then(({ ...values }) => {
      reportMainService.editProduct(values).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          getProductPage();
          handleCancel();
        }
      });
    });
  };

  /**
   * @Author: XuAnjie
   * @description: 根据分页，初始化列表数据
   * @return {*}
   */
  useEffect(() => {
    if (location.state) {
      searchParams = location.state;
      searchForm.setFieldsValue(location.state);
    }
    getProductPage();
    return () => {
      searchParams = {
        pageSize: 10 /* 每页显示条数 */,
        pageIndex: 1 /* 当前页 */,
        productLine: null /* 产品 */,
        displayModel: null /* 显示型号 */,
      };
    };
  }, []);

  /**
   * @Author: XuAnjie
   * @description: 分页的回调函数
   * @param {Number} currentPage 当前页
   * @param {Number} currentSize 页的总条数
   * @return {*}
   */
  const pageChange = useCallback((currentPage, currentSize) => {
    searchParams = { ...searchParams, ...searchForm.getFieldsValue() };
    searchParams.pageIndex = currentPage === undefined ? searchParams.pageIndex : currentPage;
    searchParams.pageSize = currentSize === undefined ? searchParams.pageSize : currentSize;
    getProductPage();
  }, []);

  /**
   * @Author: XuAnjie
   * @description:查询列表
   * @param {Object} form 表单
   * @return {*}
   */
  const search = () => {
    searchParams = { ...searchParams, ...searchForm.getFieldsValue() };
    searchParams.pageIndex = 1;
    searchParams.pageSize = 10;
    getProductPage();
  };

  /**
   * @Author: XuAnjie
   * @description: 删除
   * @param {string} id
   * @return {*}
   */
  const handleDelete = (id) => {
    confirm({
      title: t('common.tip'),
      closable: true,
      icon: <ExclamationCircleOutlined />,
      content: t('common.deleteData'),
      okText: t('common.confirmBtn'),
      cancelText: t('common.cancelBtn'),
      onOk() {
        reportMainService.delProduct(id).then((rep: any) => {
          if (rep && rep.code === 200) {
            message.success(t('common.deleteSuccess')); /* 操作成功 */
            getProductPage();
          }
        });
      },
      onCancel() {
        message.info(t('common.operateCancel'));
      },
    });
  };

  /**
   * @description: ：新增或编辑的对话框
   * @param {number} type
   * @param {any} record
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const displayAddOrEditDialog = (type: number, record?: any) => {
    setIsModalOpen(true);
    if (type === 1) {
      setAddOrEdit(1);
      projectForm &&
        projectForm.setFieldsValue({
          project: '',
          projectStatus: '',
          invCode: '',
          attachmentName: '',
        });
    } else {
      setAddOrEdit(2);
      projectForm && projectForm.setFieldsValue(record);
    }
  };
  /**
   * @Author: XuAnjie
   * @description: 编辑弹窗取消
   * @return {*}
   */
  const handleCancel = () => {
    if (projectForm) {
      projectForm.resetFields();
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="custom-contant project-management">
        <Form
          form={searchForm}
          name="search_form"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
          layout="vertical"
        >
          <Row gutter={12} align={'bottom'}>
            {/* 显示型号 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item label={t('productRule.displayModel')} name="displayModel">
                <Input
                  placeholder={`${t('common.pleaseEnter')}`}
                  allowClear
                  maxLength={inputValueLimit.maxCustomerName}
                />
              </Form.Item>
            </Col>
            {/* 产品 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item label={t('productRule.productLine')} name="productLine">
                <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                  <Option value={1}>{t('createComplaint.Inverter')}</Option>
                  <Option value={2}>{t('createComplaint.Battery')}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item>
                <Space size="middle">
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                  {/* 新增 */}
                  <Button type="primary" key="add" shape="round" onClick={() => displayAddOrEditDialog(1)}>
                    {t('common.addBtn')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          loading={tableLoading}
          rowKey={(record) => record.id}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pagingRecord.pageIndex,
            total: pagingRecord.total,
            pageSize: pagingRecord.pageSize,
            onChange: pageChange,
          }}
          dataSource={dataList}
          scroll={{ x: 600 }}
          className="custom-table-flex"
        />
      </div>
      {/* 新增编辑弹窗 */}
      <Modal
        maskClosable={false}
        title={addOrEdit === 1 ? t('common.addBtn') : t('common.editBtn')}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          /* 取消 */
          <Button key="back" shape="round" onClick={handleCancel}>
            {t('common.cancelBtn')}
          </Button>,
          /* 保存 */
          <Button key="submit" shape="round" type="primary" onClick={addOrEditComponents}>
            {t('common.saveBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form autoComplete="off" layout="vertical" form={projectForm}>
          {/* 产品 */}
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item name="id" hidden></Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('productRule.productLine')}
                name="productLine"
                rules={[
                  {
                    required: true,
                    message: `${t('productRule.productLine')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
              >
                <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                  <Option value={1}>{t('createComplaint.Inverter')}</Option>
                  <Option value={2}>{t('createComplaint.Battery')}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('productRule.displayModel')}
                name="displayModel"
                rules={[
                  {
                    required: true,
                    message: `${t('productRule.displayModel')}${t('archivalMaintenance.parmasRequired')}`,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder={`${t('common.pleaseEnter')}`}
                  maxLength={inputValueLimit.maxCustomerName}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('productRule.matchCondition')}
                name="matchCondition"
                rules={[
                  {
                    required: true,
                    message: `${t('productRule.matchCondition')}${t('archivalMaintenance.parmasRequired')}`,
                  },
                ]}
              >
                <Select allowClear placeholder={`${t('common.pleaseChoose')}`}>
                  <Option value={1}>{t('createComplaint.sn')}</Option>
                  <Option value={2}>{t('productRule.type')}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ReportMaintenance);
