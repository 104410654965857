/*
 * @Description: 客户反馈->客诉详情页-> 转移
 * @Author: XuAnjie
 * @LastEditors: Please set LastEditors
 * @Date: 2023/02/27 16:12:40
 * @LastEditTime: 2025/03/24
 */

import React, { memo, useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, Row, Col, message, Rate, List, Table, Upload, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import './index.less'
import { saveTransferOperate, getHandleForm } from '@/api/clientFeedback/complaintsList';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getUrlQuery } from '@/utils/utils';
import { config } from '@/constants/config';
import { fileUpload, fileUploadARMS } from '@/api/common';
import CustomUpload from '../FileUpload';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import UploadFileComponent from './UploadFile';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import PreViewModal from '../PreViewModal';

const { TextArea } = Input;
const { Option } = Select;
/* 富文本内容 */
let tinyContent: any = '';

interface ChildProps {
  isOpen: any;
  onClose: () => void;
  originData: any;
  id: string;
  extendParams: any;
  nodeData: any;
  detailData: any;
}
/** 文件个数限制 */
const FILE_COUNT_LIMIT = 3;
/** 文件大小限制 */
const FILE_SIZE_LIMIT = 80 * 1024 * 1024;
interface UploadFileExt extends UploadFile {
  /** 文件容器名称 */
  blobContainerName?: string;
  /** 文件路径 */
  fileFullPath?: string;
  /** 文件名 */
  fileName?: string;
  /** 文件大小 */
  fileSize?: number;
}

const CenterEvaluationTestCases: React.FC<ChildProps> = ({
  isOpen,
  onClose,
  originData,
  id,
  extendParams,
  nodeData,
  detailData,
}) => {
  const { t } = useTranslation();
  const evaluateForm = useRef<any>();
  const history = useHistory();
  const roles = useSelector((state: RootStateOrAny) => state.get('user').get('roles'));
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  const [subData, setSubData] = useState([]);
  /* 预览弹窗的开关 */
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  /* 点击文件 */
  const [fileItem, setFileItem] = useState<any>();
  /** 上传文件数量 */
  const [fileCount, setFileCount] = useState(0);

  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  const { TextArea } = Input;
  const { confirm } = Modal;
  /* 页面加载的时候根据url中的参数来获取当前客诉的详情信息 */
  useEffect(() => {
    /* 获取url中的客诉id */
    let key: string = getUrlQuery('key');
    if (key) {
      document.body.scrollIntoView(); // 将当前页面的滚动条滚动至顶部
    }
    if (nodeData) {
      getHandleForms(nodeData.data.id);
    }
    return () => {
      tinyContent = '';
    };
  }, []);

  /**
   * @description: 获取去处理带附件表单
   * @param {*} id
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getHandleForms = (id) => {
    getHandleForm({ id: id }).then((res: any) => {
      if (res.code == 200 && res.data) {
        let amsFileList: any = [];
        if (res.data.amsFileList && res.data.amsFileList.length > 0) {
          amsFileList = res.data.amsFileList.map((item) => {
            return {
              uid: item.fileFullPath,
              name: item.fileName,
              status: 'done',
              response: [
                {
                  blobContainerName: item.blobContainerName,
                  fileFullPath: item.fileFullPath,
                  fileName: item.fileName,
                  fileSize: item.size,
                },
              ],
            };
          });
        }
        searchForm.setFieldsValue({ ...res.data, amsFileList });
      }
    });
  };

  /**
   * @description: 保存转移操作表单内容
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const saveData = () => {
    searchForm.validateFields().then((values) => {
      let transferParams: any = [];
      if (values.transferData) {
        transferParams = originData.transferObjectList.filter((item) => {
          return item.id == values.transferData;
        });
      }
      let arr = transferParams[0];
      let fileList: any = [];
      values.amsFileList.forEach((item) => {
        fileList.push({
          blobContainerName: item.response[0].blobContainerName,
          fileFullPath: item.response[0].fileSaveName,
          fileName: item.response[0].fileName,
          fileSize: item.response[0].size,
          createAccount: detailData.createAccount,
          createDatetime: detailData.createDatetime,
        });
      });
      saveTransferOperate({
        complaintsId: id,
        ...originData,
        transferData: transferParams[0],
        transferTaskAuthData: transferParams[0].nextOperateAuthList ? transferParams[0].nextOperateAuthList[0] : null,
        processInstanceId: extendParams.processInstanceId,
        taskInstanceId: extendParams.taskInstanceId,
        reason: values.reason,
        authType:
          originData.transferType == 1
            ? transferParams[0].nextOperateAuthList[0].extendedFields3
            : transferParams[0].extendedFields3,
        amsFileList: fileList,
      }).then((res: any) => {
        if (res.code == 200) {
          message.success(t('complaintList.operateSuccess'));
          cancelHanle();
        }
      });
    });
  };

  /**
   * @description: 关闭弹窗
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const cancelHanle = () => {
    searchForm.resetFields();
    setSubData([]);
    onClose();
  };

  /**
   * @description: 监听富文本变化
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const handleEditorChange = (content: any, editor: any) => {
    tinyContent = content;
  };

  /**
   * @description: 富文本上传
   * @return {*}
   * @LastEditors: Xuanjie
   */
  const tinymceUploadImage = (blobInfo, succFun, failFun) => {
    const formData = new FormData();
    // 服务端接收文件的参数名，文件数据，文件名
    formData.append('blobContainerName', 'FILES');
    formData.append('fileList', blobInfo.blob(), blobInfo.filename());
    fileUpload(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          let uploadUrl = '';
          if (window.location.origin.indexOf('localhost') != -1) {
            uploadUrl = config.downUrl + 'files/';
          } else {
            uploadUrl = config.downUrl + 'files/';
          }
          succFun(uploadUrl + res.data[0].fileSaveName);
        } else {
          failFun(t('createComplaint.fileUploadError'));
        }
      })
      .catch(() => {
        failFun(t('createComplaint.fileUploadError'));
      });
  };

  /**
   * @description: 节点下拉选择时获取已选择的数据
   * @param {*} val
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const transferChange = (val) => {
    originData.transferObjectList.forEach((item) => {
      if (item.id == val) {
        setSubData(item.nextOperateAuthList);
      }
    });
  };

  /**
   * @Author: Stephen
   * @description: 将upload组件的event值转为字段值
   * @param {any} e
   */
  const normFile = (e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  /**
   * @Author: Stephen
   * @description: 验证上传文件
   * @param {RcFile} file
   */
  const beforeUpload = (file: RcFile) => {
    // 限制上传exe文件
    if (['application/x-msdownload', 'application/vnd.microsoft.portable-executable'].includes(file.type)) {
      message.error(t('complaintList.not_allowed_to_upload_exe_file'));
      return Upload.LIST_IGNORE;
    }
    // 限制上传文件大小
    if (file.size > FILE_SIZE_LIMIT) {
      return false;
    }
    // 限制上传文件数量
    if (fileCount >= FILE_COUNT_LIMIT) {
      message.error(t('complaintList.upload_max_files', { num: FILE_COUNT_LIMIT }));
      return false;
    }
    return true;
  };

  /**
   * @Author: Stephen
   * @description: 上传文件
   * @param {UploadRequestOption} options
   */
  const handleUpload = async (options: any) => {
    const { file, onSuccess, onError } = options;
    const customFile = file as UploadFileExt;
    const formData = new FormData();
    formData.append('fileList', file);
    formData.append('blobContainerName', 'FILES');

    let fileList: any = [{}];
    fileUploadARMS(formData)
      .then((res: any) => {
        if (res && res.code === 200 && res.data) {
          fileList[0].status = 'done';
          fileList[0].url = config.downUrl + `${res.data[0].blobContainerName}/${res.data[0].fileSaveName}`;
          fileList[0].fileName = res.data[0].fileSaveName;
          fileList[0].fileSaveName = res.data[0].fileSaveName;
          fileList[0].blobContainerName = res.data[0].blobContainerName;
          fileList[0].size = res.data[0].fileSize;
          setFileCount(fileCount + 1);
          onSuccess(fileList);
        } else {
          onError('上传失败');
        }
      })
      .catch((error) => {
        onError('上传失败');
      });
  };

  const downloadOrPreviewAttachments = (fileItem: any) => {
    setFileItem(fileItem);
    setViewOpen(true);
  };

  return (
    <>
      <Modal
        title={(originData && originData.operationNameShow) || nodeData?.data.currentStatusShowName}
        open={isOpen}
        width="50%"
        maskClosable={false}
        onCancel={cancelHanle}
        className="customer-details-style"
        destroyOnClose={true}
        footer={[
          <Row gutter={12} justify="end">
            {/* 取消 */}
            <Button key="cancel" shape="round" onClick={cancelHanle}>
              {t('common.cancelBtn')}
            </Button>
            {/* 立即执行 */}
            {!nodeData ? (
              <Button key="save" shape="round" type="primary" onClick={saveData}>
                {t('remoteControlMenu.saveBtn')}
              </Button>
            ) : null}
          </Row>,
        ]}
      >
        <Form
          layout="vertical"
          form={searchForm}
          name="complaintDetail"
          autoComplete="off"
          style={{ width: '100%' }}
          className=""
        >
          {originData && originData.transferType == 1 ? (
            <>
              <Row gutter={8} className="row-border">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="transferData"
                    label={<span className="form-item-label">{t('complaintList.node')}</span>}
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                  >
                    <Select
                      disabled={nodeData ? true : false}
                      allowClear
                      placeholder={`${t('common.pleaseChoose')}`}
                      onChange={transferChange}
                    >
                      {originData.transferObjectList &&
                        originData.transferObjectList.length > 0 &&
                        originData.transferObjectList.map((item: any, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8} className="row-border">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="transferTaskAuthData"
                    label={<span className="form-item-label">{t('complaintList.transferTo')}</span>}
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                  >
                    <Select disabled={nodeData ? true : false} allowClear placeholder={`${t('common.pleaseChoose')}`}>
                      {subData &&
                        subData.length > 0 &&
                        subData.map((item: any, index) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}

          {originData && originData.transferType == 2 ? (
            <Row gutter={8} className="row-border">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="transferData"
                  label={<span className="form-item-label">{t('complaintList.transferTo')}</span>}
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                >
                  <Select disabled={nodeData ? true : false} allowClear placeholder={`${t('common.pleaseChoose')}`}>
                    {originData.transferObjectList &&
                      originData.transferObjectList.length > 0 &&
                      originData.transferObjectList.map((item: any, index) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          {nodeData ? (
            <>
              <Row gutter={8} className="row-border">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="transferReceiveGroupName"
                    label={<span className="form-item-label">{t('complaintList.transferTo')}</span>}
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
                  >
                    <Input disabled={nodeData ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}
          {/* 备注 */}
          <Row gutter={8} className="row-border">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                // className="complaint-detail-label"
                name="reason"
                label={<span className="form-item-label">{t('complaintList.reason')}</span>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: `${t('common.pleaseEnter')}` }]}
              >
                <TextArea disabled={nodeData ? true : false} />
              </Form.Item>
            </Col>
          </Row>
          {/* 上传附件 */}
          <Row gutter={8} className="row-border">
            <Col span={24}>
              {/* <UploadFileComponent disabled={nodeData ? true : false} /> */}
              <Form.Item
                label={<span className="form-item-label">{t('complaintList.upload_attachments')}</span>}
                name="amsFileList"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  beforeUpload={beforeUpload}
                  customRequest={handleUpload}
                  maxCount={FILE_COUNT_LIMIT}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: true,
                    removeIcon: <DeleteOutlined onClick={() => setFileCount(fileCount - 1)} />,
                  }}
                  onPreview={(file: any) => downloadOrPreviewAttachments(file.response[0])}
                >
                  <Button shape="round" disabled={nodeData ? true : false}>
                    {t('complaintList.upload_attachments')}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {viewOpen ? <PreViewModal onClosePreview={() => setViewOpen(false)} fileData={fileItem} /> : null}
    </>
  );
};

export default memo(CenterEvaluationTestCases);
