/*
 * @Description: 重点客诉列表
 * @Author: YuKi
 * @Date: 2025/02/08
 */

import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import CustomerComplaintList from './components/CustomerComplaintList';

const EmphasisComplaintList: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  /** 1：待处理 2：处理中 3：已关闭 4:全部 */
  const [updateLogType, setUpdateLogType] = useState((location.state as any)?.type || '1');

  /**
   * @description: 改变Tab
   * @param {string} key
   * @return {*}
   * @LastEditors: YuKi
   */
  const onChange = (key: string) => {
    history.replace({ ...history.location, state: '' });
    setUpdateLogType(key);
  };

  const items = [
    {
      //待处理
      label: t('complaintList.waitingHandler'),
      key: '1',
      children: <CustomerComplaintList key="Pedding" type={1} />,
    },
    {
      //处理中
      label: t('complaintList.processing'),
      key: '2',
      children: <CustomerComplaintList key="processing" type={2} />,
    },
    {
      //已关闭
      label: t('complaintList.closed'),
      key: '3',
      children: <CustomerComplaintList key="closed" type={3} />,
    },
    {
      //全部
      label: t('complaintList.allError'),
      key: '4',
      children: <CustomerComplaintList key="all" type={4} />,
    },
  ];
  return (
    <>
      <Tabs activeKey={updateLogType} type="card" onChange={onChange} items={items} destroyInactiveTabPane />
    </>
  );
};
export default memo(EmphasisComplaintList);
