/*
 * @Description: 系统装机数量/系统天分析
 * @Author: April
 * @Date: 2023-04-10 10:32:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/12/10 11:32:05
 */
declare var echarts: any;
import { Button, Card, Col, DatePicker, Form, Row, Select, Space, Radio, Table, Tabs } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import reportService from '@/api/reportMaintenance';
import { SysInstallNumberBar, SysInstallNumberLine } from '../chartOption';
import { getCountryList } from '@/api/common';
import { debounce } from '@/utils/utils';
import { useHistory } from 'react-router-dom';
import { blobDownloadFile } from '@/constants/common';
import '../../index.less';

/* 列表字段类型定义 */
interface DataType {
  id: string;
  averageProcessingTime: number; // 客诉平均处理时间
  processor: string; // 处理组名称
  totalComplains: number; // 客诉总量
  totalProcessingTime: string; // 客诉总处理时间
}

interface DataDaysType {
  /** 机型 */
  minv: string;
  /** 装机数量 */
  installedCapacity: string;
  /** 系统天数 */
  minvDays: string;
}
interface DataBatType {
  /** 电池型号 */
  mbat: string;
  /** 电池数量 */
  installedCapacity: string;
}

let searchParams = {
  activeTimeBegin: '', // 归档开始时间
  activeTimeEnd: '', // 归档结束时间
  countryCode: [], // 国家
};

interface SysIntallTable {
  id: string;
}

/** 装机数量/使用天数 */
const SysIntallNumberTable = memo((props: any) => {
  const { t } = useTranslation();
  const { dataSource, loading, onExport } = props;
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));

  const columns: ColumnsType<DataDaysType> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      width: language === 'zh' || language === 'de' || language === 'ja' ? 90 : 180,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + 1;
      },
    },
    /* 机型	 */
    {
      title: t('projectManagement.InverterModel'),
      dataIndex: 'minv',
      align: 'center',
      width: 240,
    },
    /* 装机数量	 */
    {
      title: t('productRule.installNum'),
      dataIndex: 'installedCapacity',
      align: 'center',
      width: 240,
    },
    /* 系统天数	 */
    {
      title: t('productRule.sysDays'),
      dataIndex: 'minvDays',
      align: 'center',
      width: 240,
    },
  ];

  return (
    <div>
      {/* 导出 */}
      <Button className="export-position" type="primary" shape="round" onClick={() => onExport()}>
        {t('common.export')}
      </Button>
      <Table
        className="custom-table-flex auto-table"
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        rowKey={(record) => record.minv}
        scroll={{ x: 1000, y: 300 }}
        pagination={false}
      ></Table>
    </div>
  );
});

/** 电池数量 */
const BatteryNumberTable = memo((props: any) => {
  const { dataSource, loading, onExport } = props;
  const { t } = useTranslation();
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  const columns: ColumnsType<DataBatType> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      dataIndex: 'index',
      width: language === 'zh' || language === 'de' || language === 'ja' ? 90 : 180,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + 1;
      },
    },

    /* 电池型号	 */
    {
      title: t('complaintList.batteryModel'),
      dataIndex: 'mbat',
      align: 'center',
      width: 240,
    },
    /* 电池数量	 */
    {
      title: t('doorMailingForm.number'),
      dataIndex: 'installedCapacity',
      align: 'center',
      width: 240,
    },
  ];

  return (
    <div>
      {/* 导出 */}
      <Button className="export-position" type="primary" shape="round" onClick={() => onExport()}>
        {t('common.export')}
      </Button>
      <Table
        className="custom-table-flex auto-table"
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        rowKey={(record) => record.mbat}
        scroll={{ x: 1000, y: 300 }}
        pagination={false}
      ></Table>
    </div>
  );
});

const UserGroupWorkReport: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory(); //列表ref
  const { RangePicker } = DatePicker;
  const colors = ['#5b9bd5', '#666666', '#7baa2d'];
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let collapsed = useSelector((state: RootStateOrAny) => state.get('app').get('collapsed'));
  let country = useSelector((state: RootStateOrAny) => state.get('user').get('country'));
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /** 装机使用天数分析报表 */
  let lineChart1Ref = useRef(null);
  /** 装机数量分析报表 */
  let lineChartSysInstallRef = useRef(null);
  /** 电池数量分析报表 */
  let lineChartBatteryRef = useRef(null);
  /* 装机使用天数 */
  const [currentLineChart, setCurrentLineChart] = useState<any>(null);
  /* 装机数量分析报表 */
  const [currentLineChartSys, setCurrentLineChartSys] = useState<any>(null);
  /* 电池数量分析报表 */
  const [currentLineChartBattery, setCurrentLineChartBattery] = useState<any>(null);
  const [radioValue, setRadioValue] = useState(1);
  const [countryList, setCountryList] = useState<any>([]);
  const [dates, setDates] = useState<Moment[]>([]);
  // 装机使用天数分析报表
  const sysInstallDays = {
    xData: [],
    yData: [],
    seriesData: {
      data: [],
    },
    title: t('productRule.sysInstallDays'),
  };
  // 装机数量分析报表
  const sysInstallNumber = {
    xData: [],
    yData: [],
    seriesData: {
      data: [],
    },
    title: t('productRule.installNumber'),
  };
  // 电池数量分析报表
  const batteryNumber = {
    xData: [],
    yData: [],
    seriesData: {
      data: [],
    },
    title: t('productRule.batteryNumber'),
  };
  const [chartData, setChartData] = useState<any>(sysInstallDays);
  const [chartDataSys, setChartDataSys] = useState<any>(sysInstallNumber);
  const [chartDataBattery, setChartDataBattery] = useState<any>(batteryNumber);
  const [dataMinvList, setDataMinvList] = useState<DataType[]>([]);
  const [dataBatList, setDataBatList] = useState<DataType[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [batLoading, setBatLoading] = useState<boolean>(true);
  // 监听菜单的缩放
  useEffect(() => {
    if (currentLineChart) {
      setTimeout(() => {
        currentLineChart.resize();
      }, 100);
    }
  }, [collapsed]);

  // 监听浏览器页面的缩放
  useEffect(() => {
    const __resizeHandler = debounce(
      () => {
        if (currentLineChart) {
          currentLineChart.resize();
        }
        if (currentLineChartSys) {
          currentLineChartSys.resize();
        }
        if (currentLineChartBattery) {
          currentLineChartBattery.resize();
        }
      },
      10,
      false,
    );
    window.addEventListener('resize', __resizeHandler);
    return () => {
      if (currentLineChart) {
        currentLineChart.dispose();
        setCurrentLineChart(null);
      }
      if (currentLineChartSys) {
        currentLineChartSys.dispose();
        setCurrentLineChartSys(null);
      }
      if (currentLineChartBattery) {
        currentLineChartBattery.dispose();
        setCurrentLineChartBattery(null);
      }
      window.removeEventListener('resize', __resizeHandler);
    };
  }, [currentLineChart, currentLineChartSys, currentLineChartBattery]);

  /**
   * @description: 获取数据之后更新图表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  useEffect(() => {
    if (currentLineChart) {
      redrawSpecifyChart(radioValue, currentLineChart, chartData, 0);
    }
    if (currentLineChartSys) {
      redrawSpecifyChart(radioValue, currentLineChartSys, chartDataSys, 1);
    }
    if (currentLineChartBattery) {
      redrawSpecifyChart(radioValue, currentLineChartBattery, chartDataBattery, 2);
    }
  }, [chartData, chartDataSys, chartDataBattery]);

  /**
   * @description: 切换语言更新图表翻译
   * @return {*}
   * @LastEditors: XuAnjie
   */
  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      title: t('productRule.sysInstallDays'),
    }));
    setChartDataSys((prevData) => ({
      ...prevData,
      title: t('productRule.installNumber'),
    }));
    setChartDataBattery((prevData) => ({
      ...prevData,
      title: t('productRule.batteryNumber'),
    }));
  }, [language]);
  /**
   * @description: 重绘报表
   * @param {*} chartRef
   * @param {*} data
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const redrawChart = (type) => {
    let options: any = null;
    let optionsSys: any = null;
    let optionsBattery: any = null;
    if (type == 1) {
      options = SysInstallNumberBar(t, chartData, country, colors[0]);
      currentLineChart.setOption(options);
      optionsSys = SysInstallNumberBar(t, chartDataSys, country, colors[1]);
      currentLineChartSys.setOption(optionsSys);
      optionsBattery = SysInstallNumberBar(t, chartDataBattery, country, colors[2]);
      currentLineChartBattery.setOption(optionsBattery);
    } else {
      options = SysInstallNumberLine(t, chartData, country, colors[0]);
      currentLineChart.setOption(options);
      optionsSys = SysInstallNumberLine(t, chartDataSys, country, colors[1]);
      currentLineChartSys.setOption(optionsSys);
      optionsBattery = SysInstallNumberLine(t, chartDataBattery, country, colors[2]);
      currentLineChartBattery.setOption(optionsBattery);
    }
  };
  /**
   * @description: 重绘指定报表
   * @param {*} chartRef
   * @param {*} data
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const redrawSpecifyChart = (type, chart, data, colorNumber) => {
    let options: any = null;
    if (type == 1) {
      options = SysInstallNumberBar(t, data, country, colors[colorNumber]);
      chart.setOption(options);
    } else {
      options = SysInstallNumberLine(t, data, country, colors[colorNumber]);
      chart.setOption(options);
    }
  };
  // 初始化图表数据
  useEffect(() => {
    initData(lineChart1Ref, lineChartSysInstallRef, lineChartBatteryRef);
    getCountrysList();
    getMinvInstalledCapacityReport();
    getMbatInstalledCapacityReport();
    getMinvInstalledNumTable();
    getMbatInstalledNumTable();
    return () => {
      searchParams = {
        activeTimeBegin: '', // 归档开始时间
        activeTimeEnd: '', // 归档结束时间
        countryCode: [], // 国家
      };
    };
  }, []);

  /**
   * @description: 初始化报表
   * @param {*} chartRef
   * @param {*} lineChartSysInstallRef
   * @param {*} lineChartBatteryRef
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const initData = (chartRef, lineChartSysInstallRef, lineChartBatteryRef) => {
    /* 装机使用天数分析报表 */
    if (currentLineChart) {
      currentLineChart.dispose();
      setCurrentLineChart(null);
    }
    const chartInstanceDays = echarts.init(chartRef.current);
    const optionsDays = SysInstallNumberBar(t, sysInstallDays, country, colors[0]);
    chartInstanceDays.setOption(optionsDays);
    setCurrentLineChart(chartInstanceDays);

    /* 装机数量分析报表 */
    if (currentLineChartSys) {
      currentLineChartSys.dispose();
      setCurrentLineChartSys(null);
    }
    const chartInstanceSys = echarts.init(lineChartSysInstallRef.current);
    const optionsSys = SysInstallNumberBar(t, sysInstallNumber, country, colors[1]);
    chartInstanceSys.setOption(optionsSys);
    setCurrentLineChartSys(chartInstanceSys);

    /* 电池数量分析报表 */
    if (currentLineChartBattery) {
      currentLineChartBattery.dispose();
      setCurrentLineChartBattery(null);
    }
    const chartInstanceBattery = echarts.init(lineChartBatteryRef.current);
    const optionsBattery = SysInstallNumberBar(t, batteryNumber, country, colors[2]);
    chartInstanceBattery.setOption(optionsBattery);
    setCurrentLineChartBattery(chartInstanceBattery);
  };

  /**
   * @description: 获取逆变器装机量
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getMinvInstalledCapacityReport = () => {
    reportService.getMinvInstalledCapacity({ ...searchParams }).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        sysInstallDays.xData = rep.data.minv;
        sysInstallDays.seriesData.data = rep.data.minvDays;
        setChartData({ ...sysInstallDays });
        sysInstallNumber.xData = rep.data.minv;
        sysInstallNumber.seriesData.data = rep.data.installedCapacity;
        setChartDataSys({ ...sysInstallNumber });
      } else {
        setChartData(sysInstallDays);
        setChartDataSys(sysInstallNumber);
      }
    });
  };
  /**
   * @description: 获取电池装机量
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getMbatInstalledCapacityReport = () => {
    reportService.getMbatInstalledCapacity({ ...searchParams }).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        batteryNumber.xData = rep.data.mbat;
        batteryNumber.seriesData.data = rep.data.installedCapacity;
        setChartDataBattery({ ...batteryNumber });
      } else {
        setChartDataBattery(batteryNumber);
      }
    });
  };
  /**
   * @description: 获取逆变器装机量列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getMinvInstalledNumTable = () => {
    reportService.getMinvInstalledNum({ ...searchParams }).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        setDataMinvList(rep.data);
        setTableLoading(false);
      } else {
        setDataMinvList([]);
        setTableLoading(false);
      }
    });
  };

  /**
   * @description: 获取电池量列表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const getMbatInstalledNumTable = () => {
    reportService.getMbatInstalledNum({ ...searchParams }).then((rep: any) => {
      if (rep && rep.code === 200 && rep.data) {
        setBatLoading(false);
        setDataBatList(rep.data);
      } else {
        setBatLoading(false);
        setDataBatList([]);
      }
    });
  };

  /**
   * @description: 查询图表
   *
   * @param {*} useCallback
   * @return {*}
   * @LastEditors: April
   */
  const search = (values) => {
    const { dateRange, tempCountryList } = values;
    searchParams.activeTimeBegin = dateRange?.[0] ? moment(dateRange[0]).format('YYYY-MM-DD') : '';
    searchParams.activeTimeEnd = dateRange?.[1] ? moment(dateRange[1]).format('YYYY-MM-DD') : '';
    searchParams.countryCode = tempCountryList || [];
    getMinvInstalledCapacityReport();
    getMbatInstalledCapacityReport();
    getMinvInstalledNumTable();
    getMbatInstalledNumTable();
  };

  /**
   * @description: 切换报表类型
   * @param {*} e
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const onChange = (e) => {
    setRadioValue(e.target.value);
    if (e.target.value == 1) {
      redrawChart(1);
    } else {
      redrawChart(2);
    }
  };

  /* 获取国家列表 */
  const getCountrysList = () => {
    getCountryList()
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          setCountryList([...res.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch(() => {
        setCountryList([]);
      });
  };
  /**
   * @description: 返回报表中心
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const goBackReport = () => {
    history.goBack();
  };

  /**
   * @description: 导出报表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const exportExcelMinv = () => {
    reportService
      .exportMinvInstalledCapacity({
        countryCode: searchForm.getFieldValue('tempCountryList'),
        createBegin: dates?.[0] ? moment(dates[0]).format('YYYY-MM-DD') : '',
        createEnd: dates?.[1] ? moment(dates[1]).format('YYYY-MM-DD') : '',
      })
      .then((res: any) => {
        blobDownloadFile(res);
      });
  };

  /**
   * @description: 导出报表
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const exportExcelBat = () => {
    reportService
      .exportMbatInstalledCapacity({
        countryCode: searchForm.getFieldValue('tempCountryList'),
        createBegin: dates?.[0] ? moment(dates[0]).format('YYYY-MM-DD') : '',
        createEnd: dates?.[1] ? moment(dates[1]).format('YYYY-MM-DD') : '',
      })
      .then((res: any) => {
        blobDownloadFile(res);
      });
  };

  /**
   * @description: 限制创建时间选择器时间不超过一年
   * @param {Moment} current
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const disabledDate = (current: Moment) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'years') >= 1;
    const tooEarly = dates[1] && dates[1].diff(current, 'years') >= 1;
    return tooEarly || tooLate;
  };

  const handleDateChange = (dates: Moment[]) => {
    setDates(dates);
  };

  return (
    <>
      <Card className="user-group-card">
        <Form
          form={searchForm}
          name="searchForm"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12}>
            {/* 国家 */}
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.Item name="tempCountryList">
                <Select
                  placeholder={`${t('createComplaint.country')}`}
                  maxTagCount="responsive"
                  allowClear
                  mode="multiple"
                  options={countryList}
                  fieldNames={{ label: 'areaEnglishName', value: 'areaEnglishName' }}
                ></Select>
              </Form.Item>
            </Col>
            {/* 客诉创建时间 */}
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.Item name="dateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('productRule.sysInstallTimeStart'), t('productRule.sysInstallTimeEnd')]}
                  allowClear
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                  onChange={(date) => handleDateChange(date as moment.Moment[])}
                />
              </Form.Item>
            </Col>
            {/* 操作按钮 */}
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item>
                <Space size="middle" wrap>
                  {/* 查询 */}
                  <Button type="primary" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                </Space>
                {/* 返回 */}
                <Button style={{ float: 'right' }} type="primary" shape="round" onClick={goBackReport}>
                  {t('common.goback')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card className="user-group-card">
        <div className="flex-style">
          <div>
            <span>{t('reportCenter.chartType')}：</span>
            <Radio.Group onChange={onChange} value={radioValue}>
              <Radio value={1}>{t('reportCenter.chartColumn')}</Radio>
              <Radio value={2}>{t('reportCenter.chartLine')}</Radio>
            </Radio.Group>
          </div>
        </div>

        <div ref={lineChart1Ref} className="chart-container"></div>
        <div ref={lineChartSysInstallRef} className="chart-container"></div>
        <div ref={lineChartBatteryRef} className="chart-container"></div>
      </Card>
      <Card className="user-group-card">
        <Tabs
          defaultActiveKey="sysInstallNumber"
          type="card"
          items={[
            {
              label: t('productRule.sysInstallNumber'),
              key: 'sysInstallNumber',
              children: (
                <SysIntallNumberTable dataSource={dataMinvList} loading={tableLoading} onExport={exportExcelMinv} />
              ),
            },
            {
              label: t('productRule.BatteryNumber'),
              key: 'batteryNumber',
              children: <BatteryNumberTable dataSource={dataBatList} loading={batLoading} onExport={exportExcelBat} />,
            },
          ]}
        />
      </Card>
    </>
  );
};
export default memo(UserGroupWorkReport);
