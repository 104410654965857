/*
 * @Description:数据权限管理
 * @Author: Summer.Chen
 * @Date: 2024-04-07 11:14:20
 * @LastEditTime: 2024-04-25 09:22:38
 * @LastEditors: Summer.Chen
 */
import React, { useEffect, useState } from 'react';
import { Card, Input, Form, Button, Space, Table, Select, Row, Col } from 'antd';
import { dataRightTableColumns, setCol, pageDataRight } from './../config';
import { useTranslation } from 'react-i18next';
import OrganizationRightsEditModal from './components/editModal';
import { queryUserGroupData } from '@/api/authorityManagement';

const DataRightsManagement: React.FC = (): React.ReactElement => {
  const [form] = Form.useForm();
  //表格数据
  const [tableData, setTableData] = useState([]);

  // 翻译
  const { t } = useTranslation();

  // table Loading
  const [tableLoading, setTableLoading] = useState(false);

  // 弹框的title及按钮类型 1查看  2编辑权限  3关联分销商

  const [modalParams, setModalParams] = useState({
    open: false,
    item: {},
    title: t('authorityManagement.permissionsEdit'),
    type: 1,
  });
  const [page, setPage] = useState<pageDataRight>({
    pageSize: 10,
    current: 1,
    total: 1,
  });
  // 表格的操作
  const tableAction = () => {
    return [
      {
        title: t('common.operation'),
        key: 'Action',
        render: (text: string, record: any) => {
          return (
            <>
              <Space>
                {record.authType === 2 || record.authType === 0 || record.authType === 1 ? (
                  //  查看
                  <Button
                    type="text"
                    onClick={() => {
                      setModalParams({ open: true, item: record, title: t('common.viewBtn'), type: 1 });
                    }}
                  >
                    {t('common.viewBtn')}
                  </Button>
                ) : null}
                {/* 权限编辑 */}
                {record.authType === 0 || record.authType === 1 ? (
                  <Button
                    style={{ color: '#37D09D' }}
                    type="text"
                    onClick={() => {
                      setModalParams({
                        open: true,
                        item: record,
                        title: t('authorityManagement.permissionsEdit'),
                        type: 2,
                      });
                    }}
                  >
                    {t('authorityManagement.permissionsEdit')}
                  </Button>
                ) : null}
                {/* 关联分销商 */}
                {record.authType === 0 ? (
                  <Button
                    type="text"
                    style={{ color: 'var(--primary)' }}
                    onClick={() => {
                      setModalParams({
                        open: true,
                        item: record,
                        title: t('authorityManagement.associatedDistributors'),
                        type: 3,
                      });
                    }}
                  >
                    {t('authorityManagement.associatedDistributors')}
                  </Button>
                ) : null}
              </Space>
            </>
          );
        },
      },
    ];
  };
  // 模态框参数
  const modalProps = {
    ...modalParams,
    width: '45%',
    destroyOnClose: true,
    cancelButtonProps: { shape: 'round' },
    okButtonProps: { shape: 'round', disabled: modalParams.type === 1 },
    onCancel: () => {
      setModalParams({
        ...modalParams,
        open: false,
      });
    },
    okText: t('common.confirmBtn'),
    cancelText: t('common.cancelBtn'),
  };
  // 获取数据
  const getUserGroups = () => {
    setTableLoading(true);
    const formData = form.getFieldsValue(true);
    queryUserGroupData(formData).then((rep: any) => {
      if (rep && rep.code == 200) {
        setTableData(rep.data || []);
        setPage({ ...page, current: 1, total: (rep.data || []).length });
      }
      setTableLoading(false);
    });
  };
  useEffect(() => {
    getUserGroups();
  }, []);
  return (
    <>
      <Card title="">
        {/* 组织权限下弹框 */}
        <OrganizationRightsEditModal {...modalProps} />
        {/* 表单搜索条件 */}
        <Form form={form} layout={'vertical'} initialValues={{ selectArea: 0 }}>
          <Row gutter={15}>
            <Col {...setCol({ sm: 24, xs: 24, md: 5, lg: 5 })}>
              <Form.Item label={t('authorityManagement.userGroupName')} name="groupName">
                <Input placeholder={t('authorityManagement.pleaseEnterUserGroupName') as string} allowClear />
              </Form.Item>
            </Col>
            <Col {...setCol({ sm: 24, xs: 24, md: 4, lg: 4 })}>
              <Form.Item label={t('authorityManagement.responsibleArea')} name="selectArea">
                <Select
                  options={[
                    { label: t('common.all'), value: 0 },
                    { label: t('authorityManagement.myResponsible'), value: 1 },
                  ]}
                ></Select>
              </Form.Item>
            </Col>
            <Col {...setCol({ sm: 24, xs: 24, md: 4, lg: 4 })}>
              <Form.Item label={<span>&nbsp;</span>}>
                <Button
                  type="primary"
                  shape={'round'}
                  onClick={() => {
                    getUserGroups();
                  }}
                >
                  {t('common.searchBtn')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/* 展示数据 */}
        <Table
          dataSource={tableData}
          columns={dataRightTableColumns(t, page).concat(tableAction())}
          loading={tableLoading}
          rowKey={(record: any) => record.key}
          size={'small'}
          pagination={{
            ...page,
            showQuickJumper: true,
            onChange: (current: number) => {
              setPage({ ...page, current });
            },
          }}
          className="custom-table-flex"
        />
      </Card>
    </>
  );
};
export default DataRightsManagement;
