// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacyPolicy {
  color: red;
  cursor: pointer;
  font-weight: 700;
}
.privacyPolicy:hover {
  color: blue;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClientList/CreateComplaint/index.less"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,eAAA;EACA,gBAAA;AACF;AAEA;EACE,WAAA;AAAF","sourcesContent":[".privacyPolicy {\n  color: red;\n  cursor: pointer;\n  font-weight: 700;\n}\n\n.privacyPolicy:hover {\n  color: blue;\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
