/*
 * @Description: 用户菜单配置
 * @Author: Wayne Hu
 * @LastEditors: YuKi
 * @Date: 2023/04/24 20:34:09
 * @LastEditTime: 2023/07/28
 */

import React, { memo, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Table, Row, Col, Space, message } from 'antd';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/lib/form/Form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  deleteSysUser,
  getMenuNotRelateUserList,
  getMenuRelateUserList,
  getUserMenuTreeData,
  setUserMenuPermission,
} from '@/api/menuPermissions/userPermission';

/* 已关联/未关联菜单的用户列表中的字段信息 */
type UserListItem = {
  createAccount?: string; // 创建人账号名称
  createDatetime?: string; // 创建时间
  roleId?: string; // 角色ID
  userId?: string; // 用户ID
  username?: string; // 用户名
};

/* 配置用户菜单列表中的字段信息 */
type TreeMenuListItem = {
  id: string; // id
  order?: number;
  isRoleSelected?: boolean; // 是否被角色选中
  isUserSelected?: boolean; // 是否被用户选中
  menuTitle?: string; // 菜单标题
  parentId?: string; // 父级id
  children?: TreeMenuListItem[];
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 用户菜单管理页
 * @return {*}
 */
const UserMenuManage: React.FC = (): any => {
  const [pageIndex, setPageIndex] = useState<number>(1); // 查询的页码 默认第一页
  const [pageIndex2, setPageIndex2] = useState<number>(1); // 查询的页码 默认第一页,未绑定菜单的用户列表查询页码
  const [pageSize, setPageSize] = useState<number>(10); // 每页显示的数据条数 默认10条
  const [tableLoading, setTableLoading] = useState<boolean>(false); // 是否展示表格加载动画
  const [tableLoading2, setTableLoading2] = useState<boolean>(false); // 是否展示表格加载动画（未绑定菜单用户）
  const [dataTotal, setDataTotal] = useState<number>(0); // 表格中的数据总条数 默认为0条
  const [dataTotal2, setDataTotal2] = useState<number>(0); // 表格中的数据总条数 默认为0条（未绑定菜单）
  const [keyword, setKeyword] = useState<string>(''); // 查询已关联菜单的用户列表的关键字
  const [userAccountList, setUserAccountList] = useState<boolean>(false); // 显示或隐藏未绑定菜单的用户列表窗口
  const [configMenuDialog, setConfigMenuDialog] = useState<boolean>(false); // 显示或隐藏配置用户菜单窗口
  const [editType, setEditType] = useState<number>(1); // 编辑或新增用户菜单 1-新增 2-编辑
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  /* 被用户或者角色选中的菜单ID */
  const [selectedId, setSelectedId] = useState<string[]>([]);
  /* 已关联菜单用户列表 */
  const [hasMenuUserList, setHasMenuUserList] = useState<UserListItem[]>([]);
  /* 未关联菜单用户列表 */
  const [hasNoMenuUserList, setHasNoMenuUserList] = useState<UserListItem[]>([]);
  /* 用户菜单树形列表 */
  const [menuTreeList, setMenuTreeList] = useState<TreeMenuListItem[]>([]);

  /* 用户列表查询表单 */
  const [userForm] = useForm();
  /* 角色配置菜单表单 */
  const [roleConfigForm] = useForm();
  /* 配置角色首页菜单的表单 */
  const [setHomePageForm] = useForm();
  /* 获取用户菜单树形结构时使用的表单 */
  const [getUserMenuForm] = useForm();

  const { t } = useTranslation();
  const { confirm } = Modal;

  /* 进入页面的时候查询已经关联菜单的用户列表 */
  useEffect(() => {
    btnSearchUserListAssociatedMenu();
  }, []);

  /* 点击按钮查询已经关联菜单的用户列表 */
  const btnSearchUserListAssociatedMenu = (): any => {
    let params: any = {
      pageIndex: 1,
      pageSize: pageSize,
      keyword: keyword,
    };
    searchUserListAssociatedMenu(params);
  };
  /* 点击按钮查询未关联菜单的用户列表 */
  const btnSearchUserListNoMenu = (): any => {
    let params: any = {
      pageIndex: 1,
      pageSize: pageSize,
      keyword: (userForm && userForm.getFieldValue('keyword')) || '',
    };
    searchUserListNoMenu(params);
  };
  /* 查询已关联菜单的用户列表 */
  const searchUserListAssociatedMenu = (params: object): any => {
    setTableLoading(true);
    getMenuRelateUserList(params)
      .then((res: any) => {
        setTableLoading(false);
        if (res && res.code === 200 && res.data && res.data.rows && res.data.rows.length > 0) {
          setPageIndex(res.data.pageIndex);
          setDataTotal(res.data.total);
          setHasMenuUserList([...res.data.rows]);
        } else {
          setPageIndex(1);
          setDataTotal(0);
          setHasMenuUserList([]);
        }
      })
      .catch(() => {
        setTableLoading(false);
        setPageIndex(1);
        setDataTotal(0);
        setHasMenuUserList([]);
      });
  };

  /* 切换查询页码时请求已关联菜单的用户列表 */
  const userPageChange = (e) => {
    let params: any = {
      pageIndex: e,
      pageSize: pageSize,
      keyword: keyword,
    };
    searchUserListAssociatedMenu(params);
  };
  /* 未绑定菜单的用户列表 */
  const userPageChange2 = (e) => {
    let params: any = {
      pageIndex: e,
      pageSize: pageSize,
      keyword: userForm.getFieldValue('keyword') || '',
    };
    searchUserListNoMenu(params);
  };
  /* 查询未绑定菜单的用户列表 */
  const searchUserListNoMenu = (params: object): any => {
    setTableLoading2(true);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    getMenuNotRelateUserList(params)
      .then((res: any) => {
        setTableLoading2(false);
        if (res && res.code === 200 && res.data && res.data.rows && res.data.rows.length > 0) {
          setPageIndex2(res.data.pageIndex);
          setDataTotal2(res.data.total);
          setHasNoMenuUserList([...res.data.rows]);
        } else {
          setPageIndex2(1);
          setDataTotal2(0);
          setHasNoMenuUserList([]);
        }
      })
      .catch(() => {
        setTableLoading2(false);
        setPageIndex2(1);
        setDataTotal2(0);
        setHasNoMenuUserList([]);
      });
  };

  /* 处理用户账户查询值修改 */
  const handleAccountChange = (e) => {
    setKeyword(e.target.value);
  };

  /* 关闭未绑定菜单的用户列表窗口 */
  const closeNoMenuUserList = () => {
    setUserAccountList(false);
    userForm && userForm.resetFields();
    setHasNoMenuUserList([]);
    if (selectedRows.length > 0) {
      getUserMenuForm &&
        getUserMenuForm.setFieldsValue({
          username: selectedRows[0].username || '',
          userId: selectedRows[0].userId || '',
        });
      let paramsObj: any = {
        userId: selectedRows[0].userId || '',
      };
      getUserMenuTreeData(paramsObj)
        .then((res: any) => {
          if (res && res.code === 200 && res.data && res.data.length > 0) {
            let menuList = handleTreeList(res.data, []);
            setMenuTreeList([...menuList.tempArr]);
            setSelectedId([...menuList.isChecked]);
          } else {
            setMenuTreeList([]);
            setSelectedId([]);
          }
        })
        .catch(() => {
          setMenuTreeList([]);
          setSelectedId([]);
        });
    } else {
      getUserMenuForm &&
        getUserMenuForm.setFieldsValue({
          username: '',
          userId: '',
        });
    }
  };
  /* 提交选中的用户信息，然后请求用户的菜单树形结构 */
  const submitUserInfo = () => {
    if (selectedRowKeys.length === 0) {
      message.error(t('userMenuManagement.selectUser')); /* 请选择一个用户 */
      return;
    } else {
      closeNoMenuUserList();
    }
  };
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 删除当前用户
   * @param {any} userId
   * @return {*}
   */
  const deleteCurrentUser = (userId: any) => {
    if (userId) {
      confirm({
        title: t('common.warning'),
        closable: true,
        icon: <ExclamationCircleOutlined />,
        okText: t('common.confirmBtn'),
        cancelText: t('common.cancelBtn'),
        content: t('common.deleteData'),
        onOk() {
          deleteSysUser({ key: userId }).then((res: any) => {
            if (res && res.code === 200) {
              message.success(t('common.deleteSuccess'));
              // btnSearchUserListAssociatedMenu()
              let params: any = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                keyword: keyword,
              };
              searchUserListAssociatedMenu(params);
            }
          });
        },
        onCancel() {
          message.info(t('userMenuManagement.operateCancel'));
        },
      });
    }
  };

  /* 显示新增或编辑用户菜单的窗口 */
  const showAddOrEditDialog = (type: number, record?: any): any => {
    setConfigMenuDialog(true);
    setEditType(type);

    /* 编辑用户菜单 */
    if (type === 2) {
      let paramsObj: any = {
        userId: record.userId,
      };
      getUserMenuForm &&
        getUserMenuForm.setFieldsValue({
          username: record.username || '',
          userId: record.userId || '',
        });
      getUserMenuTreeData(paramsObj)
        .then((res: any) => {
          if (res && res.code === 200 && res.data && res.data.length > 0) {
            let menuList = handleTreeList(res.data, []);
            setMenuTreeList([...menuList.tempArr]);
            setSelectedId([...menuList.isChecked]);
          } else {
            setMenuTreeList([]);
            setSelectedId([]);
          }
        })
        .catch(() => {
          setMenuTreeList([]);
          setSelectedId([]);
        });
    } else {
      /* 新增用户菜单 */
      getUserMenuForm &&
        getUserMenuForm.setFieldsValue({
          username: '',
          userId: '',
        });
    }
  };

  /* 显示未绑定菜单的用户分页列表数据 */
  const showHaveNotMenuUserList = () => {
    setUserAccountList(true);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    btnSearchUserListNoMenu();
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 处理树形下拉的数据结构
   * @param {*} treeList
   * @return {*}
   */
  const handleTreeSelectData = (treeList) => {
    let tempList: any = [];
    for (let i = 0; i < treeList.length; i++) {
      if (treeList[i].isDefault === 1) {
        setHomePageForm && setHomePageForm.setFieldValue('menuId', treeList[i].id);
      }
      let item: any = {
        title: treeList[i].menuTitle,
        value: treeList[i].id,
        children:
          treeList[i].childrenMenu && treeList[i].childrenMenu.length > 0
            ? handleTreeSelectData(treeList[i].childrenMenu)
            : [],
      };
      tempList.push(item);
    }
    return tempList;
  };

  /* 递归处理后台返回的树形菜单数据，使处理后的数据结构满足树形多选列表 */
  const handleTreeList = (arr: any, isSelected: any) => {
    let tempArr: any = [];
    let isChecked: any = isSelected;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].isRoleSelected === true || arr[i].isUserSelected === true) {
        isChecked.push(arr[i].id);
      }
      let item: any = {
        id: arr[i].id,
        isRoleSelected: arr[i].isRoleSelected,
        isUserSelected: arr[i].isUserSelected,
        menuTitle: arr[i].menuTitle,
        parentId: arr[i].parentId,
        order: arr[i].order,
        children:
          arr[i].childrenMenu && arr[i].childrenMenu.length > 0
            ? handleTreeList(arr[i].childrenMenu, isChecked).tempArr
            : undefined,
      };
      tempArr.push(item);
    }
    return {
      tempArr: tempArr,
      isChecked: isChecked,
    };
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 取消配置角色菜单
   * @return {*}
   */
  const handleCancelConfigMenu = () => {
    setConfigMenuDialog(false);
    setSelectedId([]);
    setMenuTreeList([]);
    getUserMenuForm && getUserMenuForm.resetFields();
    roleConfigForm && roleConfigForm.resetFields();
  };

  /* 用户手动勾选或取消勾选菜单时 */
  const handleSelect = (record, selected, selectedRows) => {
    let arrList = handleChildNode(record.id, selected, menuTreeList);
    let selectIds = handleSelectedIds(arrList, []);
    let arrList2: any = arrList;
    let parents: any = [];
    if (record.parentId && record.parentId !== '3KeUCSJA3293') {
      parents = handleParentNode(arrList2, record.parentId, selected);
    }
    if (selected) {
      parents.forEach((element) => {
        if (!selectIds.includes(element.id)) {
          selectIds.push(element.id);
        }
      });
    } else {
      for (let i = 0; i < parents.length; i++) {
        parents[i].isUserSelected = false;
      }
      for (let k = 0; k < parents.length; k++) {
        let isCheck: any = false;
        parents[k].children.forEach((item: any) => {
          if (item.isUserSelected === true || item.isRoleSelected === true) {
            isCheck = true;
          }
        });

        if (isCheck) {
          if (!selectIds.includes(parents[k].id)) {
            selectIds.push(parents[k].id);
          }
        } else {
          if (selectIds.includes(parents[k].id)) {
            selectIds = selectIds.filter((item) => {
              return item != parents[k].id;
            });
          }
        }
      }
    }
    setSelectedId([...selectIds]);
    let newMenuList = handleMenuList(menuTreeList, selectIds);
    setMenuTreeList([...newMenuList]);
  };

  /* 处理已经勾选的菜单项 */
  const handleMenuList = (menuTreeList, selectIds) => {
    let menuList: any = [...menuTreeList];
    for (let i = 0; i < menuList.length; i++) {
      if (selectIds.includes(menuList[i].id) && menuList[i].isRoleSelected !== true) {
        menuList[i].isUserSelected = true;
        if (menuList[i].children && menuList[i].children.length > 0) {
          menuList[i].children = handleMenuList(menuList[i].children, selectIds);
        }
      }
    }
    return menuList;
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 处理当前节点的祖先节点的选中状态
   * @param {*} dataSource
   * @param {*} parentId
   * @param {*} selected
   * @return {*}
   */
  const handleParentNode = (dataSource, parentId, selected) => {
    let parents: any = [];
    let fn = (arr, parentId) => {
      for (let item of arr) {
        if (item.id === parentId) {
          parents.push(item);
          if (item.parentId && item.parentId !== '3KeUCSJA3293') {
            fn(dataSource, item.parentId);
          }
          break;
        }
        if (item.children && item.children.length > 0) {
          fn(item.children, parentId);
        }
      }
    };
    fn(dataSource, parentId);
    return parents;
  };

  /* 处理已经选中的菜单ID */
  const handleSelectedIds = (menuList, selectedIds) => {
    let tempArr: any = selectedIds;
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].isRoleSelected === true || menuList[i].isUserSelected === true) {
        tempArr.push(menuList[i].id);
      }
      if (menuList[i].children && menuList[i].children.length > 0) {
        tempArr = handleSelectedIds(menuList[i].children, tempArr);
      }
    }
    return tempArr;
  };

  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 过滤菜单中配置给用户的菜单id数据集合
   * @param {*} menuList
   * @param {*} userMenuIdArr
   * @return {*}
   */
  const filterUserMenu = (menuList, userMenuIdArr) => {
    let userMenuIds: any = [...userMenuIdArr];
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].isUserSelected === true) {
        userMenuIds.push(menuList[i].id);
      }
      if (menuList[i].children && menuList[i].children.length > 0) {
        userMenuIds = filterUserMenu(menuList[i].children, userMenuIds);
      }
    }
    return userMenuIds;
  };

  /* 保存用户勾选的菜单 */
  const saveUserMenu = () => {
    let userMenuIds = filterUserMenu(menuTreeList, []);
    let dataObj: any = {
      userId: undefined,
      menuId: [...userMenuIds],
    };
    if (
      getUserMenuForm.getFieldValue('username') !== null &&
      getUserMenuForm.getFieldValue('username') !== undefined &&
      getUserMenuForm.getFieldValue('username') !== ''
    ) {
      dataObj.userId = getUserMenuForm.getFieldValue('userId');
    }
    if (dataObj.menuId.length === 0) {
      message.warning(t('userMenuManagement.pleaseSelectMenu'));
      return;
    }
    if (!dataObj.userId) {
      return;
    } else {
      setUserMenuPermission(dataObj).then((res: any) => {
        if (res && res.code === 200) {
          message.success(t('common.operateSuccess'));
          getUserMenuForm && getUserMenuForm.resetFields();
          setConfigMenuDialog(false);
          setMenuTreeList([]);
          setSelectedId([]);
          if (editType === 1) {
            btnSearchUserListAssociatedMenu();
          } else {
            let params: any = {
              pageIndex: pageIndex,
              pageSize: pageSize,
              keyword: keyword,
            };
            searchUserListAssociatedMenu(params);
          }
        }
      });
    }
  };

  /* 处理当前节点及其子节点的选中状态 */
  const handleChildNode = (menuId, checkedStatus, menuTreeListArr) => {
    let menuList: any = [...menuTreeListArr]; // 最新状态的菜单副本
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].id === menuId) {
        menuList[i].isUserSelected = checkedStatus;
        if (menuList[i].children && menuList[i].children.length > 0) {
          menuList[i].children.forEach((item) => {
            menuList[i].children = handleChildNode(item.id, checkedStatus, menuList[i].children);
          });
        } else {
          menuList[i].children = null;
          break;
        }
      } else {
        if (menuList[i].children && menuList[i].children.length > 0) {
          menuList[i].children = handleChildNode(menuId, checkedStatus, menuList[i].children);
        }
      }
    }
    return menuList;
  };

  /* 用户列表列定义（未绑定菜单） */
  const UserListColumns2: ColumnsType<UserListItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      width: 130,
      dataIndex: 'index',
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pageIndex2 - 1) * pageSize + 1;
      },
    },
    /* 用户账户 */
    {
      title: t('userMenuManagement.userAccount'),
      dataIndex: 'username',
      align: 'center',
      ellipsis: true,
    },
    /* 创建日期 */
    {
      title: t('common.createDatetime'),
      dataIndex: 'createDatetime',
      align: 'center',
      ellipsis: true,
    },
    /* 创建人 */
    {
      title: t('common.creator'),
      dataIndex: 'createAccount',
      align: 'center',
      ellipsis: true,
    },
  ];
  /* 用户列表列定义（已绑定菜单） */
  const UserListColumns: ColumnsType<UserListItem> = [
    /* 序号 */
    {
      title: t('common.serialNumber'),
      width: 130,
      dataIndex: 'index',
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return index + (pageIndex - 1) * pageSize + 1;
      },
    },
    /* 用户账户 */
    {
      title: t('userMenuManagement.userAccount'),
      dataIndex: 'username',
      align: 'center',
      ellipsis: true,
    },
    /* 创建日期 */
    {
      title: t('common.createDatetime'),
      dataIndex: 'createDatetime',
      align: 'center',
      ellipsis: true,
    },
    /* 创建人 */
    {
      title: t('common.creator'),
      dataIndex: 'createAccount',
      align: 'center',
      ellipsis: true,
    },
    /* 操作 */
    {
      title: t('common.operation'),
      align: 'center',
      ellipsis: true,
      render: (text: any, record: any, index: any) => {
        return (
          <Space>
            <span onClick={() => showAddOrEditDialog(2, record)} style={{ color: '#E8AA0D', cursor: 'pointer' }}>
              {t('common.editBtn')}
            </span>
            <span onClick={() => deleteCurrentUser(record.userId)} style={{ color: '#ff0000', cursor: 'pointer' }}>
              {t('common.deleteBtn')}
            </span>
          </Space>
        );
      },
    },
  ];

  /* 用户树形菜单回显列表 列定义 */
  const menuTreecolumns: ColumnsType<TreeMenuListItem> = [
    {
      title: t('userMenuManagement.menuName'),
      dataIndex: 'menuTitle',
      width: 250,
      align: 'left',
    },
    {
      title: t('userMenuManagement.type'),
      dataIndex: 'isRoleSelected',
      align: 'center',
      render: (text: any, record: any, index: any) => {
        let message: any = '';
        if (record.isRoleSelected) {
          message = t('userMenuManagement.roleName');
        }
        if (record.isUserSelected) {
          message = t('userMenuManagement.account');
        }
        return <span>{message}</span>;
      },
    },
    {
      title: t('common.serialNumber'),
      width: 140,
      dataIndex: 'order',
      align: 'center',
    },
  ];

  return (
    <>
      <Row gutter={8}>
        <Col style={{ margin: '4px' }} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Input
            defaultValue={keyword}
            onChange={handleAccountChange}
            placeholder={`${t('userMenuManagement.userAccount')}`}
          ></Input>
        </Col>
        <Col style={{ margin: '4px' }} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Space>
            <Button shape="round" type="primary" disabled={tableLoading} onClick={btnSearchUserListAssociatedMenu}>
              {t('common.searchBtn')}
            </Button>
            <Button shape="round" type="primary" onClick={() => showAddOrEditDialog(1)}>
              {t('common.addBtn')}
            </Button>
          </Space>
        </Col>
      </Row>
      <br />

      <Table
        rowKey="userId"
        scroll={{ x: 1300, y: window.innerHeight - 320 }}
        columns={UserListColumns}
        loading={tableLoading}
        dataSource={hasMenuUserList}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          defaultCurrent: 1,
          current: pageIndex,
          total: dataTotal,
          onChange: userPageChange,
        }}
      ></Table>

      {/* 未绑定菜单的用户列表 */}
      <Modal
        zIndex={1005}
        maskClosable={false}
        title={t('userMenuManagement.userAccountList')}
        open={userAccountList}
        onCancel={closeNoMenuUserList}
        width="55%"
        footer={[
          <Button key="back" shape="round" onClick={closeNoMenuUserList}>
            {t('common.cancelBtn')}
          </Button>,
          <Button key="submit" shape="round" type="primary" onClick={submitUserInfo}>
            {t('common.submitBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form name="userForm" autoComplete="off" layout="vertical" form={userForm}>
          {/* 用户名 */}
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label={t('userMenuManagement.username')} name="keyword">
                <Input maxLength={128} allowClear />
              </Form.Item>
            </Col>
            <Button style={{ marginTop: '30px' }} shape="round" type="primary" onClick={btnSearchUserListNoMenu}>
              {t('common.searchBtn')}
            </Button>
          </Row>
        </Form>
        <Table
          rowKey="userId"
          scroll={{ x: 1200, y: 400 }}
          columns={UserListColumns2}
          loading={tableLoading2}
          dataSource={hasNoMenuUserList}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys: any, selectedRows: any) => {
              setSelectedRowKeys(selectedRowKeys);
              setSelectedRows([...selectedRows]);
            },
          }}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pageIndex2,
            total: dataTotal2,
            onChange: userPageChange2,
          }}
        ></Table>
      </Modal>

      {/* 配置用户菜单 */}
      <Modal
        maskClosable={false}
        title={editType === 2 ? t('common.editBtn') : t('common.addBtn')}
        open={configMenuDialog}
        onCancel={handleCancelConfigMenu}
        width="50%"
        footer={[
          <Button key="back" shape="round" onClick={handleCancelConfigMenu}>
            {t('common.cancelBtn')}
          </Button>,
          <Button key="submit" shape="round" type="primary" onClick={saveUserMenu}>
            {t('common.submitBtn')}
          </Button>,
        ]}
        forceRender
      >
        <Form name="getUserMenuForm" autoComplete="off" layout="vertical" form={getUserMenuForm}>
          {/* 用户账户 */}
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={t('userMenuManagement.userAccount')}
                name="username"
                validateTrigger="onChange"
                rules={[{ required: true, message: `${t('userMenuManagement.chooseUserAccount')}` }]}
              >
                <Input onClick={showHaveNotMenuUserList} allowClear disabled={editType === 2} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item hidden={true} label={t('userMenuManagement.userID')} name="userId">
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <br />

        {/* 用户树形菜单列表展示 */}
        {menuTreeList.length > 0 ? (
          <Table
            rowKey="id"
            columns={menuTreecolumns}
            expandable={{
              defaultExpandAllRows: true,
            }}
            rowSelection={{
              checkStrictly: true,
              hideSelectAll: true,
              selectedRowKeys: selectedId,
              getCheckboxProps: (record) => {
                return {
                  disabled: record.isRoleSelected ? true : false,
                };
              },
              onSelect: handleSelect,
            }}
            dataSource={menuTreeList}
            pagination={false}
            scroll={{ y: 500 }}
          ></Table>
        ) : null}

        {menuTreeList.length === 0 ? (
          <Table rowKey="id" columns={menuTreecolumns} dataSource={[]} pagination={false} scroll={{ y: 500 }}></Table>
        ) : null}
      </Modal>
    </>
  );
};

export default memo(UserMenuManage);
