/*
 * @Author: Summer.Chen
 * @Date:   2022-11-03 14:37:38
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2024-03-29 13:42:31
 */
import 'antd/dist/antd.less';
import { message } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { Spin } from 'antd';
import './i18n/i18n';

message.config({
  maxCount: 1,
});

ReactDOM.render(
  <Provider store={store}>
    <React.Suspense fallback={<Spin className="suspense-spin-loading" tip="Loading..."></Spin>}>
      <App />
    </React.Suspense>
  </Provider>,
  document.querySelector('#root'),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
