/*
 * @Description:归档分析报表
 * @Author: April
 * @Date: 2023-04-10 10:32:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/12/10 13:13:53
 */
declare var echarts: any;
import { Button, Card, Col, DatePicker, Form, Row, Select, Space } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import '../../index.less';
import {
  getProblemComponent,
  getQualityClassification,
  getResponsibilityEnd,
  getTechnicalClassification,
  getDepartmentClassification,
} from '@/api/reportCenter';
import PieComponent from '../PieComponent';
import { getCountryList } from '@/api/common';
import { useHistory } from 'react-router-dom';

let searchParams: any = {
  createBeginTime: '', // 客诉创建开始时间
  createEndTime: '', // 客诉创建结束时间
  archiveBeginTime: '', // 归档开始时间
  archiveEndTime: '', // 归档结束时间
  country: [], // 国家
  businessLine: 10,
};
const ArchiveAnalysisReports: React.FC = () => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const history = useHistory(); //列表ref
  /* 查询表单的ref */
  const [searchForm] = Form.useForm();
  /* 国家列表数据 */
  const [countryList, setCountryList] = useState<any>([]);
  // 周归档责任分类
  let obj1: any = {
    seriesData: [],
    title: t('reportCenter.weekfillResponseClass'),
  };
  // 周归档技术端分类
  let obj2: any = {
    seriesData: [],
    title: t('reportCenter.WeekTechnicalClass'),
  };
  // 周归档质量端分类
  let obj3: any = {
    seriesData: [],
    title: t('reportCenter.weekQualityClass'),
  };
  // 归档客诉问题部件量/占比
  let obj4: any = {
    seriesData: [],
    title: t('reportCenter.archivedComplaintsClass'),
  };

  // 获取部门客诉分类
  let obj5: any = {
    seriesData: [],
    title: t('reportCenter.departmentClassification'),
  };

  const [data1, setData1] = useState<any>(obj1);
  const [data2, setData2] = useState<any>(obj2);
  const [data3, setData3] = useState<any>(obj3);
  const [data4, setData4] = useState<any>(obj4);
  const [data5, setData5] = useState<any>(obj5);
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));

  // 监听语言重新渲染
  useEffect(() => {
    setData1((prevData) => ({
      ...prevData,
      title: t('reportCenter.weekfillResponseClass'),
    }));
    setData2((prevData) => ({
      ...prevData,
      title: t('reportCenter.WeekTechnicalClass'),
    }));
    setData3((prevData) => ({
      ...prevData,
      title: t('reportCenter.weekQualityClass'),
    }));
    setData4((prevData) => ({
      ...prevData,
      title: t('reportCenter.archivedComplaintsClass'),
    }));
    setData5((prevData) => ({
      ...prevData,
      title: t('reportCenter.departmentClassification'),
    }));
  }, [language]);
  /**
   * @description: 初始化图表数据
   * @return {*}
   * @LastEditors: April
   */
  useEffect(() => {
    getCountrysList();
    getResponsibilityData();
    getTechnicalData();
    getQualityData();
    getProblemData();
    getDepartmentClassifications();
  }, []);

  /* 获取归档责任端客诉分类 */
  const getResponsibilityData = () => {
    let obj = { ...obj1, seriesData: [] };
    getResponsibilityEnd(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          rep.data.map((item) => {
            obj.seriesData.push({ value: item.classificationQuantity, name: item.classificationName });
          });
          setData1(obj);
        } else {
          setData1(obj);
        }
      })
      .catch((err) => {
        setData1(obj);
      });
  };
  /* 获取技术端客诉分类 */
  const getTechnicalData = () => {
    let obj = { ...obj2, seriesData: [] };
    getTechnicalClassification(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          rep.data.map((item) => {
            obj.seriesData.push({ value: item.classificationQuantity, name: item.classificationName });
          });
          setData2(obj);
        } else {
          setData2(obj);
        }
      })
      .catch((err) => {
        setData2(obj);
      });
  };
  /* 获取质量端客诉分类 */
  const getQualityData = () => {
    let obj = { ...obj3, seriesData: [] };
    getQualityClassification(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          rep.data.map((item) => {
            obj.seriesData.push({ value: item.classificationQuantity, name: item.classificationName });
          });
          setData3(obj);
        } else {
          setData3(obj);
        }
      })
      .catch((err) => {
        setData3(obj);
      });
  };
  /* 获取质量端客诉分类 */
  const getProblemData = () => {
    let obj = { ...obj4, seriesData: [] };
    getProblemComponent(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          rep.data.map((item) => {
            obj.seriesData.push({ value: item.classificationQuantity, name: item.classificationName });
          });
          setData4(obj);
        } else {
          setData4(obj);
        }
      })
      .catch((err) => {
        setData4(obj);
      });
  };
  /* 获取部门客诉分类 */
  const getDepartmentClassifications = () => {
    let obj = { ...obj5, seriesData: [] };
    getDepartmentClassification(searchParams)
      .then((rep: any) => {
        if (rep && rep.code === 200 && rep.data) {
          rep.data.map((item) => {
            obj.seriesData.push({ value: item.classificationQuantity, name: item.classificationName });
          });
          setData5(obj);
        } else {
          setData5(obj);
        }
      })
      .catch((err) => {
        setData5(obj);
      });
  };
  /**
   * @description: 查询图表
   * @param {*} useCallback
   * @return {*}
   * @LastEditors: April
   */
  const search = useCallback((values: any) => {
    searchParams.createBeginTime =
      values.dateRange && values.dateRange[0] ? moment(values.dateRange[0]).format('YYYY-MM-DD') : '';
    searchParams.createEndTime =
      values.dateRange && values.dateRange[1] ? moment(values.dateRange[1]).format('YYYY-MM-DD') : '';
    searchParams.archiveBeginTime =
      values.archiveDateRange && values.archiveDateRange[0]
        ? moment(values.archiveDateRange[0]).format('YYYY-MM-DD')
        : '';
    searchParams.archiveEndTime =
      values.archiveDateRange && values.archiveDateRange[1]
        ? moment(values.archiveDateRange[1]).format('YYYY-MM-DD')
        : '';
    let countryArr: any = [];
    if (values.tempCountryList && values.tempCountryList.length > 0) {
      for (let i = 0; i < values.tempCountryList.length; i++) {
        countryArr.push(values.tempCountryList[i]);
      }
    } else {
      countryArr = [];
    }
    searchParams.country = [...countryArr];
    searchParams.businessLine = values.businessLine;
    getResponsibilityData();
    getTechnicalData();
    getQualityData();
    getProblemData();
    getDepartmentClassifications();
  }, []);
  /* 获取国家列表 */
  const getCountrysList = () => {
    getCountryList()
      .then((res: any) => {
        if (res && res.code === 200 && res.data && res.data.length > 0) {
          setCountryList([...res.data]);
        } else {
          setCountryList([]);
        }
      })
      .catch(() => {
        setCountryList([]);
      });
  };
  /* 处理国家列表数据 */
  const handleCountryList = () => {
    if (countryList.length > 0) {
      let tempList: any = [];
      for (let i = 0; i < countryList.length; i++) {
        tempList.push({
          label: countryList[i].areaEnglishName + '[' + countryList[i].areaFirstName + ']',
          value: countryList[i].areaEnglishName,
        });
      }
      return tempList;
    } else {
      return [];
    }
  };

  /**
   * @description: 返回报表中心
   * @return {*}
   * @LastEditors: XuAnjie
   */
  const goBackReport = () => {
    history.goBack();
  };

  return (
    <>
      <Card>
        <Form
          form={searchForm}
          name="searchForm"
          autoComplete="off"
          style={{ width: '100%' }}
          onFinish={search}
          className="custom-search in-line-style"
        >
          <Row gutter={12}>
            {/* 客诉创建时间 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="dateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('complaintList.complaintCreateBegin'), t('complaintList.complaintCreateEnd')]}
                  allowClear
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            {/* 归档时间 */}
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Item name="archiveDateRange">
                <RangePicker
                  allowEmpty={[true, true]}
                  placeholder={[t('reportCenter.archiveBeginPla'), t('reportCenter.archiveEndPla')]}
                  allowClear
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              {/* 国家 */}
              <Form.Item name="tempCountryList">
                <Select
                  placeholder={`${t('createComplaint.country')}`}
                  maxTagCount="responsive"
                  allowClear
                  mode="multiple"
                  options={handleCountryList()}
                ></Select>
              </Form.Item>
            </Col>
            {/* 产品线 */}
            <Col xs={24} sm={8} md={8} lg={6} xl={6}>
              <Form.Item name="businessLine" initialValue={10}>
                <Select
                  placeholder={`${t('archivalMaintenance.productLine')}`}
                  options={[
                    { value: 10, label: t('archivalMaintenance.householdUse') },
                    { value: 20, label: t('createComplaint.consumerBusiness') },
                    { value: 30, label: t('archivalMaintenance.industryAndCommerce') },
                    { value: 40, label: t('archivalMaintenance.largeEnergyStorage') },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* 操作按钮 */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item>
                <Space size="middle" wrap>
                  {/* 查询 */}
                  <Button type="primary" key="search" shape="round" htmlType="submit">
                    {t('common.searchBtn')}
                  </Button>
                </Space>
                {/* 返回 */}
                <Button style={{ float: 'right' }} type="primary" key="search" shape="round" onClick={goBackReport}>
                  {t('common.goback')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <PieComponent data={data1} />
        <PieComponent data={data2} />
        <PieComponent data={data3} />
        <PieComponent data={data4} />
        <PieComponent data={data5} />
      </Card>
    </>
  );
};
export default memo(ArchiveAnalysisReports);
