/*
 * @Description: 报表组件
 * @Author: April
 * @Date: 2023-12-15 09:21:04
 * @LastEditors: April
 * @LastEditTime: 2023-12-15 11:48:44
 */
declare var echarts: any;

import React, { useEffect, useRef, useState } from 'react';
import { sysOption } from '../chartOption';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import { debounce } from '@/utils/utils';
import '../../index.less';

const ChartComponent = (props) => {
  const chartRef = useRef(null);
  const { t } = useTranslation();
  let country = useSelector((state: RootStateOrAny) => state.get('user').get('country'));
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  let collapsed = useSelector((state: RootStateOrAny) => state.get('app').get('collapsed'));

  let [currentLineChart, setCurrentLineChart] = useState<any>(null);

  // 监听菜单的缩放
  useEffect(() => {
    if (currentLineChart) {
      setTimeout(() => {
        currentLineChart.resize();
      }, 100);
    }
  }, [collapsed]);

  // 监听语言切换
  useEffect(() => {
    if (currentLineChart) {
      redrawChart();
    }
  }, [props.data, language]);

  // 监听浏览器缩放
  useEffect(() => {
    const __resizeHandler = debounce(
      () => {
        if (currentLineChart) {
          currentLineChart.resize();
        }
      },
      10,
      false,
    );
    window.addEventListener('resize', __resizeHandler);
    return () => {
      if (currentLineChart) {
        currentLineChart.dispose();
        setCurrentLineChart(null);
      }

      window.removeEventListener('resize', __resizeHandler);
    };
  }, [currentLineChart]);

  useEffect(() => {
    initData(chartRef, props.data);
  }, []);

  // 初始化图表
  const initData = (chartRef, data) => {
    if (currentLineChart) {
      currentLineChart.dispose();
      setCurrentLineChart(null);
    }
    const chartInstance = echarts.init(chartRef.current);
    const options = sysOption(t, data, country);
    chartInstance.setOption(options);
    setCurrentLineChart(chartInstance);
  };
  const redrawChart = () => {
    const options = sysOption(t, props.data, country);
    currentLineChart.setOption(options);
  };

  return <div ref={chartRef} className="chart-style" />;
};

export default ChartComponent;
