// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menTranslation .site-tree-search-value {
  color: #e8aa0d !important;
}
.menTranslation .search-tree .ant-input-group-addon {
  display: none;
}
.menTranslation .menu-translation-tree {
  overflow: auto;
  max-height: calc(100vh - 280px);
}
`, "",{"version":3,"sources":["webpack://./src/pages/MenuPermissions/MenuTranslation/index.less"],"names":[],"mappings":"AAAA;EAEI,yBAAA;AAAJ;AAFA;EAMM,aAAA;AADN;AALA;EAUI,cAAA;EACA,+BAAA;AAFJ","sourcesContent":[".menTranslation {\n  .site-tree-search-value {\n    color: #e8aa0d !important;\n  }\n  .search-tree {\n    .ant-input-group-addon {\n      display: none;\n    }\n  }\n  .menu-translation-tree {\n    overflow: auto;\n    max-height: calc(100vh - 280px);\n  }\n}\n\n@primary-color: #FABF00;@input-placeholder-color: #868686;@btn-primary-color: #454545;@checkbox-check-color: #454545;@checkbox-color: #E8AA0D;@radio-button-bg: #E8AA0D;@radio-button-checked-bg: #E8AA0D;@radio-button-color: #454545;@radio-button-active-color: #454545;@radio-solid-checked-color: #454545;@font-family: 'WenQuanYi Micro Hei', 'Micro Hei','Noto Sans CJK','Source Han Sans','Montserrat','Roboto','Lato','sans-serif';@btn-text-shadow: 0 0 0 rgba(0, 0, 0, 0);"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
